import styled, {css} from "styled-components";

import {ContentRow} from "./ContentRow.styles";

type Props = {
  padded: boolean;
};
export const Container = styled.div<Props>`
  flex: 1 0 auto;
  height: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;

  ${(props) =>
    props.padded &&
    css`
      padding: 0 ${(props) => props.theme.dimensions.hMargin + props.theme.dimensions.hPagePadding}rem 0
        ${(props) => props.theme.dimensions.hPagePadding}rem;

      // HACK fix firefox not respecting padding bottom with overflow:auto and scrolling.
      // https://bugzilla.mozilla.org/show_bug.cgi?id=748518
      > ${ContentRow}:last-of-type {
        padding-bottom: ${(props) => props.theme.dimensions.vMargin}rem;
      }
    `}
`;
