import type {ObjectValues} from "../types.js";

export const VelocityOption = {
  MILLIMETRES_PER_SECOND: "mm/s",
  INCHES_PER_SECOND: "in/s",
} as const;

export const VelocityRatio = {
  [VelocityOption.MILLIMETRES_PER_SECOND]: 1,
  [VelocityOption.INCHES_PER_SECOND]: 0.039370079,
};

export type Velocity = ObjectValues<typeof VelocityOption>;
