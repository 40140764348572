import {Children, useLayoutEffect, createRef, useState, useCallback, cloneElement, useRef, isValidElement} from "react";
import type {PropsWithChildren} from "react";

import * as Styled from "./ContentRow.styles";

type Props = PropsWithChildren<{
  grow?: boolean;
  center?: boolean;
  ignoreChildrenHeight?: boolean;
}>;
function ContentRow({children, grow = false, center = false, ignoreChildrenHeight = false, ...otherProps}: Props) {
  const ref = createRef<HTMLDivElement>();
  const [expandedStates, setExpandedStates] = useState<boolean[]>([]);
  const currentExpandedStates = useRef(expandedStates);

  // Checks the offset state of each child column
  const checkOffsetStatus = useCallback(() => {
    if (!ref.current) {
      return;
    }
    let newExpandedStates: boolean[] = [];
    let needsUpdate = false;
    const childrenLength = ref.current.childNodes.length;
    for (let idx = 0; idx < childrenLength; idx++) {
      const node = ref.current.childNodes[idx] as HTMLDivElement;
      if (node.offsetTop !== 0) {
        newExpandedStates[idx] = true;
      }

      // Grow the first column if there are multiple columns and the first is on it's own row
      const nextNode = ref.current.childNodes[idx + 1] as HTMLDivElement;
      if (idx === 0 && childrenLength > 1 && nextNode.offsetTop !== 0) {
        newExpandedStates[idx] = true;
      }

      if (!needsUpdate && newExpandedStates[idx] !== currentExpandedStates.current[idx]) {
        needsUpdate = true;
      }
    }

    if (needsUpdate) {
      setExpandedStates(newExpandedStates);
      currentExpandedStates.current = newExpandedStates;
    }
  }, [ref]);

  // Add children listener
  useLayoutEffect(() => {
    checkOffsetStatus();
  }, [children, checkOffsetStatus]);

  // Add window resize listener
  useLayoutEffect(() => {
    window.addEventListener("resize", checkOffsetStatus);
    return () => {
      window.removeEventListener("resize", checkOffsetStatus);
    };
  }, [checkOffsetStatus]);

  return (
    <Styled.ContentRow
      grow={grow}
      center={center}
      ignoreChildrenHeight={ignoreChildrenHeight}
      {...otherProps}
      ref={ref}
    >
      {Children.map(children, (child, index) => {
        if (!isValidElement<{grow: boolean}>(child)) {
          return null;
        }
        return cloneElement(child, {
          grow: !!(child.props.grow || expandedStates[index]),
        });
      })}
    </Styled.ContentRow>
  );
}

export default ContentRow;
