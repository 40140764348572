module.exports = {
  theme: {
    colors: {
      primary: "var(--colour-primary)",
      "primary-hover": "var(--colour-primary-hover)",
      "primary-highlight": "var(--colour-primary-highlight)",
      "primary-bg": "var(--colour-primary-bg)",
      good: "var(--colour-good)",
      "good-hover": "var(--colour-good-hover)",
      "good-bg": "var(--colour-good-bg)",
      "good-border": "var(--colour-good-border)",
      warning: "var(--colour-warning)",
      "warning-hover": "var(--colour-warning-hover)",
      "warning-muted": "var(--colour-warning-muted)",
      "warning-bg": "var(--colour-warning-bg)",
      "warning-border": "var(--colour-warning-border)",
      bad: "var(--colour-bad)",
      "bad-hover": "var(--colour-bad-hover)",
      "bad-muted": "var(--colour-bad-muted)",
      "bad-bg": "var(--colour-bad-bg)",
      "bad-border": "var(--colour-bad-border)",
      info: "var(--colour-info)",
      "info-hover": "var(--colour-info-hover)",
      "info-bg": "var(--colour-info-bg)",
      "info-border": "var(--colour-info-border)",
      offline: "var(--colour-offline)",
      "offline-hover": "var(--colour-offline-hover)",
      "offline-bg": "var(--colour-offline-bg)",
      "offline-border": "var(--colour-offline-border)",
      content: "var(--colour-content)",
      "content-hover": "var(--colour-content-hover)",
      "content-light": "var(--colour-content-light)",
      "content-light-hover": "var(--colour-content-light-hover)",
      "content-placeholder": "var(--colour-content-placeholder)",
      "content-background": "var(--colour-content-bg)",
      "content-background-alt": "var(--colour-content-bg-alt)",
      "content-main-menu-bg": "var(--colour-main-menu-bg)",
      "content-main-menu-text": "var(--colour-main-menu-text)",
      background: "var(--colour-background)",
      border: "var(--colour-border)",
      "input-background": "var(--colour-input-bg)",
      "separator-light": "var(--colour-separator-light)",

      "loading-animation": "hsl(var(--hue-primary), 60%, 60%)",
      accent: "#fb002b",
      "sensoteq-red": "#fb002b",

      "topbar-bg": "var(--colour-topbar-bg)",
      "map-bg": "var(--colour-map-bg)",
      "chart-1": "var(--chart-1)",
      "chart-2": "var(--chart-2)",
      "chart-3": "var(--chart-3)",
      "chart-4": "var(--chart-4)",
      "chart-5": "var(--chart-5)",
      card: "var(--color-card)",
      "card-foreground": "var(--color-card-foreground)",
      popover: "var(--color-popover)",
      "popover-foreground": "var(--color-popover-foreground)",
      "primary-foreground": "var(--color-primary-foreground)",
      secondary: "var(--color-secondary)",
      "secondary-foreground": "var(--color-secondary-foreground)",
      muted: "var(--color-muted)",
      "muted-foreground": "var(--color-muted-foreground)",
      "accent-foreground": "var(--color-accent-foreground)",
      destructive: "var(--color-destructive)",
      "destructuve-foreground": "var(--color-destructive-foreground)",
      foreground: "var(--color-foreground)",
      input: "var(--color-input)",
      ring: "var(--color-ring)",
    },
    spacing: {},
    fontFamily: {},
    fontSize: {
      huge: "3.2rem",
      veryLarge: "2.2rem",
      large: "1.9rem",
      slightlyLarge: "1.6rem",
      medium: "1.4rem",
      small: "1.3rem",
      verySmall: "1.2rem",
      tiny: "1.1rem",
    },
    fontWeights: {},
    borderRadius: {
      lg: "var(--radius)",
      md: "calc(var(--radius) - 2px)",
      sm: "calc(var(--radius) - 4px)",
    },
    keyframes: {
      loading: {
        "0%, 80%, 100%": {transform: "scale(0)"},
        "40%": {transform: "scale(1.0)"},
      },
      "accordion-down": {
        from: {height: "0"},
        to: {height: "var(--radix-accordion-content-height)"},
      },
      "accordion-up": {
        from: {height: "var(--radix-accordion-content-height)"},
        to: {height: "0"},
      },
    },
    animation: {
      loading: "loading 1.2s infinite ease-in-out both",
      "accordion-down": "accordion-down 0.2s ease-out",
      "accordion-up": "accordion-up 0.2s ease-out",
    },
    transitionDuration: {
      5: "5ms",
      10: "10ms",
    },
  },
};
