let enums = require("../sensoteq-core/enumerations");
let dbEnums = require("../sensoteq-core/cloud/db-enums");
let AlarmTableInfo = dbEnums.AlarmTableInfo;
let utils = require("../sensoteq-core/utils");
const AlarmProperties = enums.AlarmProperties;
const AlarmGroups = enums.AlarmGroups;

module.exports.TRUE_PROP_MAP = {};

module.exports.initPropMap = () => {
  let fullProps = utils.mapToArray(enums.AlarmProperties);
  for (let prop of fullProps) {
    module.exports.TRUE_PROP_MAP[prop] = getPropertyInfoInternal(prop);
  }
};

module.exports.getPropMap = () => {
  if (Object.keys(module.exports.TRUE_PROP_MAP).length === 0) {
    module.exports.initPropMap();
  }
  return module.exports.TRUE_PROP_MAP;
};

function isInGroup(group, property) {
  return group.indexOf(property) !== -1;
}

module.exports.isSensorAxisProperty = (alarm) => {
  return enums.checkAlarmInGroup(alarm, AlarmGroups.SENSOR_AXIS_DATA);
};

module.exports.isSensorRMSProperty = (alarm) => {
  return enums.checkAlarmInGroup(alarm, AlarmGroups.AXIS_RMS);
};

module.exports.isEnvironmentalProperty = (alarm) => {
  return enums.checkAlarmInGroup(alarm, AlarmGroups.ENVIRONMENT);
};

module.exports.isDiagnosticProperty = (alarm) => {
  return enums.checkAlarmInGroup(alarm, AlarmGroups.DIAGNOSTIC);
};

module.exports.isForcePeakToPeakProperty = (alarm) => {
  return enums.checkAlarmInGroup(alarm, AlarmGroups.PEAK_FORCE);
};

module.exports.isScreenProperty = (alarm) => {
  return enums.checkAlarmInGroup(alarm, AlarmGroups.SCREEN_DATA);
};

module.exports.isVibTrendingProperty = (alarm) => {
  return enums.checkAlarmInGroup(alarm, AlarmGroups.SPECTRUM_BASED);
};

module.exports.isThermalProperty = (alarm) => {
  return enums.checkAlarmInGroup(alarm, AlarmGroups.THERMAL_DATA);
};

module.exports.isVibPowerBandProperty = (alarm) => {
  return enums.checkAlarmInGroup(alarm, AlarmGroups.VIB_POWER);
};

module.exports.isTrendingCalsProperty = (alarm) => {
  return enums.checkAlarmInGroup(alarm, AlarmGroups.TD_CALCS);
};

function getPropertyInfoInternal(alarmProperty) {
  let axis = null,
    resolution = null,
    name = null;
  if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.X_AXIS)) {
    axis = enums.AxisTypes.AXIS_X;
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.Y_AXIS)) {
    axis = enums.AxisTypes.AXIS_Y;
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.Z_AXIS)) {
    axis = enums.AxisTypes.AXIS_Z;
  }

  if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.LOW_RES)) {
    resolution = enums.TimeDomainResolution.LOW;
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.HIGH_RES)) {
    resolution = enums.TimeDomainResolution.HIGH;
  }

  // large scale cases (3-6 properties in each)
  if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.AXIS_RMS)) {
    name = "sensor acceleration RMS";
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.AXIS_VEL_RMS)) {
    name = "sensor velocity RMS";
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.AXIS_PEAK)) {
    name = "sensor peak to peak";
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.VIB_POWER)) {
    name = `vibration power (${resolution} res)`;
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.PEAK_FORCE)) {
    name = "peak force";
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.ACC_RMS)) {
    name = `acceleration RMS (${resolution} res)`;
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.VEL_RMS)) {
    name = `velocity RMS (${resolution} res)`;
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.DISP_RMS)) {
    name = `displacement RMS (${resolution} res)`;
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.CREST_FACTOR)) {
    name = `crest factor (${resolution} res)`;
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.PEAK_TD)) {
    name = `peak to peak (${resolution} res)`;
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.PEAK_VALUE_ACC_TD)) {
    name = `TWF acceleration peak (${resolution} res)`;
  } else if (enums.checkAlarmPropertyInGroup(alarmProperty, AlarmGroups.THERMAL_DATA)) {
    name = `thermal sensor`;
  }
  // individual cases in switch only
  else {
    switch (alarmProperty) {
      case AlarmProperties.AMBIENT_TEMPERATURE:
        name = "ambient temperature";
        break;
      case AlarmProperties.WIFI_STRENGTH:
        name = "wifi strength";
        break;
      case AlarmProperties.SIGNAL_QUALITY:
        name = "signal quality";
        break;
      case AlarmProperties.HUMIDITY:
        name = "humidity";
        break;
      case AlarmProperties.MACHINE_TEMPERATURE:
        name = "machine temperature";
        break;
      case AlarmProperties.MACHINE_MINUS_AMBIENT:
        name = "delta temperature";
        break;
      case AlarmProperties.VOLTAGE:
        name = "voltage";
        break;
      case AlarmProperties.STROKE_LENGTH:
        name = "stroke length";
        break;
      case AlarmProperties.STROKE_ANGLE:
        name = "stroke angle";
        break;
      case AlarmProperties.STROKE_RATIO:
        name = "stroke ratio";
        break;
      case AlarmProperties.RPM:
        name = "RPM";
        break;
      case AlarmProperties.X_VELOCITY:
        name = "X velocity";
        break;
      case AlarmProperties.Y_VELOCITY:
        name = "Y velocity";
        break;
      case AlarmProperties.Z_VELOCITY:
        name = "Z velocity";
        break;
      case AlarmProperties.X_DISPLACEMENT:
        name = "X displacement";
        break;
      case AlarmProperties.Y_DISPLACEMENT:
        name = "Y displacement";
        break;
      case AlarmProperties.Z_DISPLACEMENT:
        name = "Z displacement";
        break;
      case AlarmProperties.X_ACCELERATION:
        name = "X acceleration";
        break;
      case AlarmProperties.Y_ACCELERATION:
        name = "Y acceleration";
        break;
      case AlarmProperties.Z_ACCELERATION:
        name = "Z acceleration";
        break;
      case AlarmProperties.DIFF_DISPLACEMENT:
        name = "displacement difference";
        break;
      case AlarmProperties.PHASE_ANGLE:
        name = "phase angle";
        break;
      case AlarmProperties.VALIDITY:
        name = "validity";
        break;
      case AlarmProperties.ENVELOPE_HILBERT_ACC_RMS_X_L:
        name = `X envelope acceleration RMS (hilbert) (${resolution} res)`;
        break;
      case AlarmProperties.ENVELOPE_HILBERT_ACC_RMS_Y_L:
        name = `Y envelope acceleration RMS (hilbert) (${resolution} res)`;
        break;
      case AlarmProperties.ENVELOPE_HILBERT_ACC_RMS_Z_L:
        name = `Z envelope acceleration RMS (hilbert) (${resolution} res)`;
        break;
      case AlarmProperties.ENVELOPE_HILBERT_ACC_PEAK_X_L:
        name = `X envelope acceleration peak value (hilbert) (${resolution} res)`;
        break;
      case AlarmProperties.ENVELOPE_HILBERT_ACC_PEAK_Y_L:
        name = `Y envelope acceleration peak value (hilbert) (${resolution} res)`;
        break;
      case AlarmProperties.ENVELOPE_HILBERT_ACC_PEAK_Z_L:
        name = `Z envelope acceleration peak value (hilbert) (${resolution} res)`;
        break;
      case AlarmProperties.TIME_WAVEFORM_VALIDITY:
        name = "time waveform validity";
        break;
      default:
        name = null;
        break;
    }
  }

  return {axis: axis, name: name, resolution: resolution};
}
module.exports.getPropertyInfoInternal = getPropertyInfoInternal;

module.exports.getPropertyInfo = (alarm) => {
  if (alarm == null) {
    return null;
  }
  let alarmProperty = alarm[AlarmTableInfo.ALARM_DATA][enums.PresentableDataTypes.ALARM_PROPERTY];
  return getPropertyInfoInternal(alarmProperty);
};

function tdCalcNesting(nest = [], property) {
  nest.push(dbEnums.TimeDomainKeyTableInfo.TD_KEY_OTHER_CALC_OBJ);
  if (isInGroup(AlarmGroups.ACC_RMS, property)) {
    nest.push(enums.PresentableDataTypes.ACC_RMS);
  } else if (isInGroup(AlarmGroups.VEL_RMS, property)) {
    nest.push(enums.PresentableDataTypes.VEL_RMS);
  } else if (isInGroup(AlarmGroups.DISP_RMS, property)) {
    nest.push(enums.PresentableDataTypes.DISP_RMS);
  } else if (isInGroup(AlarmGroups.CREST_FACTOR, property)) {
    nest.push(enums.PresentableDataTypes.CREST_FACTOR);
  } else if (isInGroup(AlarmGroups.PEAK_TD, property)) {
    nest.push(enums.PresentableDataTypes.PEAK_TO_PEAK);
  } else if (isInGroup(AlarmGroups.PEAK_VALUE_ACC_TD, property)) {
    nest.push(enums.PresentableDataTypes.TD_ACC_PEAK);
  }
  return nest;
}

function sensorAxisDataNesting(nest = [], property) {
  let atEnd = null;
  if (isInGroup(AlarmGroups.AXIS_RMS, property)) {
    nest.push(enums.PresentableDataTypes.AXIS_RMS);
  } else if (isInGroup(AlarmGroups.AXIS_VEL_RMS, property)) {
    nest.push(enums.PresentableDataTypes.AXIS_VEL_RMS);
  } else if (isInGroup(AlarmGroups.AXIS_PEAK, property)) {
    nest.push(enums.PresentableDataTypes.AXIS_PEAK);
  } else if (isInGroup(AlarmGroups.PEAK_FORCE, property)) {
    nest.push(enums.PresentableDataTypes.AXIS_DELTAS);
    atEnd = enums.PresentableDataTypes.PEAK_DELTA;
  }
  if (isInGroup(AlarmGroups.X_AXIS, property)) {
    nest.push(enums.AxisTypes.AXIS_X);
  } else if (isInGroup(AlarmGroups.Y_AXIS, property)) {
    nest.push(enums.AxisTypes.AXIS_Y);
  } else if (isInGroup(AlarmGroups.Z_AXIS, property)) {
    nest.push(enums.AxisTypes.AXIS_Z);
  }
  if (atEnd) {
    nest.push(atEnd);
  }
  return nest;
}

function screenDataNesting(nest = [], property) {
  nest.push(enums.PresentableDataTypes.SCREEN_DATA);
  if (property === AlarmProperties.STROKE_LENGTH) {
    nest.push(enums.PresentableDataTypes.STROKE_LENGTH);
  } else if (property === AlarmProperties.STROKE_ANGLE) {
    nest.push(enums.PresentableDataTypes.STROKE_ANGLE);
  } else if (property === AlarmProperties.STROKE_RATIO) {
    nest.push(enums.PresentableDataTypes.STROKE_RATIO);
  } else if (property === AlarmProperties.RPM) {
    nest.push(enums.PresentableDataTypes.RPM);
  } else if (property === AlarmProperties.X_ACCELERATION) {
    nest.push(enums.PresentableDataTypes.X_ACCELERATION);
  } else if (property === AlarmProperties.Y_ACCELERATION) {
    nest.push(enums.PresentableDataTypes.Y_ACCELERATION);
  } else if (property === AlarmProperties.Z_ACCELERATION) {
    nest.push(enums.PresentableDataTypes.Z_ACCELERATION);
  } else if (property === AlarmProperties.X_VELOCITY) {
    nest.push(enums.PresentableDataTypes.X_VELOCITY);
  } else if (property === AlarmProperties.Y_VELOCITY) {
    nest.push(enums.PresentableDataTypes.Y_VELOCITY);
  } else if (property === AlarmProperties.Z_VELOCITY) {
    nest.push(enums.PresentableDataTypes.Z_VELOCITY);
  } else if (property === AlarmProperties.X_DISPLACEMENT) {
    nest.push(enums.PresentableDataTypes.X_DISPLACEMENT);
  } else if (property === AlarmProperties.Y_DISPLACEMENT) {
    nest.push(enums.PresentableDataTypes.Y_DISPLACEMENT);
  } else if (property === AlarmProperties.Z_DISPLACEMENT) {
    nest.push(enums.PresentableDataTypes.Z_DISPLACEMENT);
  } else if (property === AlarmProperties.PHASE_ANGLE) {
    nest.push(enums.PresentableDataTypes.PHASE_ANGLE);
  } else if (property === AlarmProperties.DIFF_DISPLACEMENT) {
    nest.push([enums.PresentableDataTypes.X_DISPLACEMENT, enums.PresentableDataTypes.Y_DISPLACEMENT]);
  }
  return nest;
}

function thermalDataNesting(nest = [], property) {
  if (property === AlarmProperties.THERMAL_ABSOLUTE) {
    nest.push(enums.PresentableDataTypes.THERMAL_TEMPERATURE);
  } else if (property === AlarmProperties.THERMAL_DELTA) {
    //TODO: This will need to be ammended.
    nest.push(enums.PresentableDataTypes.THERMAL_TEMPERATURE);
  }
  return nest;
}

function infoDataNesting(nest = [], property) {
  nest.push(enums.PresentableDataTypes.INFO);
  if (property === AlarmProperties.VOLTAGE) {
    nest.push(enums.PresentableDataTypes.VOLTAGE);
  } else if (property === AlarmProperties.VALIDITY) {
    nest.push(enums.PresentableDataTypes.VALIDITY);
  } else if (property === AlarmProperties.MACHINE_TEMPERATURE) {
    nest.push(enums.PresentableDataTypes.TEMPERATURE);
  } else if (property === AlarmProperties.MACHINE_MINUS_AMBIENT) {
    nest.push(enums.PresentableDataTypes.TEMP_DELTA);
  }
  return nest;
}

function gatewayDataNesting(nest = [], property) {
  nest.push(enums.PresentableDataTypes.GATEWAY_DATA);
  if (property === AlarmProperties.AMBIENT_TEMPERATURE) {
    nest.push(enums.PresentableDataTypes.TEMPERATURE);
  } else if (property === AlarmProperties.HUMIDITY) {
    nest.push(enums.PresentableDataTypes.HUMIDITY);
  } else if (property === AlarmProperties.WIFI_STRENGTH) {
    nest.push(enums.PresentableDataTypes.SIGNAL_STRENGTH);
  } else if (property === AlarmProperties.SIGNAL_QUALITY) {
    nest.push(enums.PresentableDataTypes.SIGNAL_QUALITY);
  }
  return nest;
}

module.exports.propertyToObjectNesting = (property) => {
  let propertyNest = [];
  // This section looks complicated, but basically it converts the alarm property to the specific property nesting
  // which the function must follow to retrieve the data from the larger blob
  if (isInGroup(AlarmGroups.TD_CALCS, property)) {
    propertyNest = tdCalcNesting(propertyNest, property);
  } else {
    // all properties from here start in the data
    propertyNest.push(AlarmTableInfo.ALARM_DATA);
    if (isInGroup(AlarmGroups.SENSOR_AXIS_DATA, property)) {
      propertyNest = sensorAxisDataNesting(propertyNest, property);
    } else if (isInGroup(AlarmGroups.SENSOR_DIAG, property) || isInGroup(AlarmGroups.SENSOR_ENVIRO, property)) {
      propertyNest = infoDataNesting(propertyNest, property);
    } else if (isInGroup(AlarmGroups.GATEWAY_DIAG, property) || isInGroup(AlarmGroups.GATEWAY_ENVIRO, property)) {
      propertyNest = gatewayDataNesting(propertyNest, property);
    } else if (isInGroup(AlarmGroups.SCREEN_DATA, property)) {
      propertyNest = screenDataNesting(propertyNest, property);
    } else if (isInGroup(AlarmGroups.THERMAL_DATA, property)) {
      propertyNest = thermalDataNesting(propertyNest, property);
    }
  }
  return propertyNest.length !== 0 ? propertyNest : null;
};
module.exports.alarmToObjectNesting = (alarm) => {
  let alarmProperty = alarm[AlarmTableInfo.ALARM_DATA][enums.PresentableDataTypes.ALARM_PROPERTY];
  return module.exports.propertyToObjectNesting(alarmProperty);
};

module.exports.getAlarmValueFromObject = (object, property) => {
  let nest = module.exports.propertyToObjectNesting(property);
  // if the nest of properties is only 1 long then it cannot go deep enough to get the value, there is no very top level
  // data in any message format
  if (nest == null || nest.length <= 1) {
    return null;
  }
  let values = [];
  for (let i = 0; i < nest.length; i++) {
    let property = nest[i];
    // reached max depth or its an array
    if (i === nest.length - 1) {
      if (object == null) {
        return null;
      }
      for (let prop of property instanceof Array ? property : [property]) {
        if (object[prop] == null) {
          return null;
        }
        values.push(object[prop]);
      }
    }
    // property isn't null, can continue
    else if (object[property] != null) {
      object = object[property];
    }
    // element is null so must stop
    else {
      values = null;
      break;
    }
  }
  return values;
};
