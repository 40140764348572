import styled, {css} from "styled-components";

export const Container = styled.div`
  flex: 0 0 ${(props) => props.theme.dimensions.mainMenuWidth}rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  position: relative;
  background: ${(props) => props.theme.colours.mainMenuBackground};
  overflow-x: hidden;
  margin-left: 0;
  transition:
    flex 0.25s ease,
    margin-left 0.25s ease;
  z-index: 2;
  box-shadow: ${(props) => props.theme.misc.mainMenuBoxShadow};

  ${(props) =>
    props.theme.mixins.desktop(css`
      ${(props) =>
        props.collapsed &&
        css`
          flex: 0 0 ${(props) => props.theme.dimensions.mainMenuCollapsedWidth}rem;
        `}
    `)}

  ${(props) =>
    props.theme.mixins.mobile(css`
      ${(props) =>
        !props.visibleMobile &&
        css`
          margin-left: -${(props) => props.theme.dimensions.mainMenuWidth}rem;
        `}
    `)}
`;

export const BrandingContainer = styled.div`
  height: ${(props) => props.theme.dimensions.topBarHeight}rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Menu = styled.div`
  flex: 1 1 0;
  position: relative;
  overflow-y: auto;
  padding: 0 0 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
`;

export const MenuBody = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const Wordmark = styled.div`
  color: white;
  font-size: ${(props) => props.theme.fontSizes.slightlyLarge};
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 1rem;
  padding-left: 2rem;
  ${(props) =>
    props.theme.mixins.mobile(css`
      display: none;
    `)}
`;
