// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.6_@swc+helpers@0.5.15__webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.6_@swc+helpers@0.5.15__webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.6_@swc+helpers@0.5.15__webpack@5.98.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/sensoteq.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/good timing bd.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"sensoteq\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Good Timing\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\ni.sensoteq-icons {\n  display: inline-block;\n  font-family: \"sensoteq\", sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  line-height: 1;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/sensoteq-font.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,2DAAwD;EACxD,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,0BAA0B;EAC1B,+DAAiE;EACjE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,mCAAmC;EACnC,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["@font-face {\n  font-family: \"sensoteq\";\n  src: url(\"../assets/fonts/sensoteq.woff\") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Good Timing\";\n  src: url(\"../assets/fonts/good timing bd.ttf\") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\ni.sensoteq-icons {\n  display: inline-block;\n  font-family: \"sensoteq\", sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  line-height: 1;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
