import styled, {css} from "styled-components";
import * as envConfig from "sensoteq-react-core/constants/environment-config";

import {Colors} from "./Branding";

const envBranding = envConfig.getKey("login_branding_values");

export const Container = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`;

export const Logo = styled.img`
  width: ${(props) => props.size * envBranding.logo_size}rem;
  flex: 0 0 auto;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: ${(props) => props.size * envBranding.text_margin_left}rem;
`;

export const Company = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: ${(props) => props.size}rem;
  font-weight: bold;
  color: black;

  ${(props) =>
    props.color !== Colors.BlackRed &&
    css`
      color: white;
    `}

  ${(props) =>
    !props.analytix &&
    css`
      font-size: ${(props) => props.size * 1.5}rem;
    `}
  
  ${(props) =>
    props.analytix &&
    css`
      margin-left: ${(props) => props.size * 0.6}rem;
    `}
`;

export const Product = styled.img`
  width: ${(props) => props.size * envBranding.product_width}rem;
  color: ${(props) => props.color ?? props.theme.colours.content};
  margin-bottom: ${(props) => props.size * -0.1}rem;

  span {
    background-color: ${(props) => props.theme.colours.good};
    padding: 0.3rem 0.4rem 0.2rem 0.4rem;
    border-radius: 0.4rem;
    color: white;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
    margin-left: 0.4rem;
    display: none;
  }
`;
