import {createRootRoute, Outlet} from "@tanstack/react-router";
import {TanStackRouterDevtools} from "@tanstack/router-devtools";
import {Page, PageContent} from "components/page";
import TopBar from "components/layout/TopBar";
import EmptyState from "components/common/EmptyState";

export const Route = createRootRoute({
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevtools />
    </>
  ),
  notFoundComponent: () => {
    return (
      <Page>
        <TopBar>
          <h1>Page not found</h1>
        </TopBar>
        <PageContent>
          <EmptyState
            icon="sentiment_dissatisfied"
            title="Sorry, we couldn't find that page"
            text="If you think you clicked a valid link, please get in touch with Sensoteq support."
          />
        </PageContent>
      </Page>
    );
  },
});
