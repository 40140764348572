import dayjs from "dayjs";

// Serialisation helpers
export const serialiseBool = (bool) => {
  return bool ? "y" : "n";
};
export const serialiseDate = (date) => {
  return date ? date.valueOf() : undefined;
};
export const parseDate = (dateString, currentValue) => {
  if (isNaN(dateString)) {
    throw "Invalid date";
  }
  const date = dayjs(parseInt(dateString));
  if (currentValue && date.isSame(currentValue)) {
    return currentValue;
  }
  if (date.isValid()) {
    return date;
  } else {
    throw "Invalid date";
  }
};
export const parseInt = (intString) => {
  const int = window.parseInt(intString);
  if (isNaN(int)) {
    throw "Invalid integer";
  }
  return int;
};
export const parseBool = (boolString) => {
  return boolString === "y";
};
export const parseOneOf = (value, options, parse = (x) => x) => {
  const parsedValue = parse(value);
  if (options.indexOf(parsedValue) === -1) {
    throw "Invalid option";
  }
  return parsedValue;
};
export const trySet = (value, fn) => {
  if (value != null && value !== "") {
    try {
      fn(value);
    } catch (error) {
      // Do nothing
    }
  }
};
