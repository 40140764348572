/*
 * This file exists purely to control the logging in the system, allowing the attached project to disable all logging
 * from within core if it wishes. It can also capture logging with callbacks if required.
 * This is separate to the cloud/logging functionality which is used by modules for actual logging, this is purely
 * for logs from within core which the user may wish to remove/stop (think CLI).
 */

// old functionality was that it was enabled by default, don't break this, used in a fair number of places
let CONSOLE_LOGGING_ENABLED = true;
let STD_CB = null;
let ERR_CB = null;

module.exports.LOG = (log) => {
  if (STD_CB != null) {
    STD_CB(log);
  }
  if (CONSOLE_LOGGING_ENABLED) {
    // eslint-disable-next-line no-console
    console.log(log);
  }
};

module.exports.ERR_LOG = (err) => {
  if (STD_CB != null) {
    ERR_CB(err);
  }
  if (CONSOLE_LOGGING_ENABLED) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

module.exports.disableConsoleLogging = () => {
  CONSOLE_LOGGING_ENABLED = false;
};

module.exports.enableConsoleLogging = () => {
  CONSOLE_LOGGING_ENABLED = true;
};

module.exports.standardLoggingCallback = (cb) => {
  if (typeof cb !== "function") {
    throw {error: "Callback must be a function."};
  }
  STD_CB = cb;
};

module.exports.errorLoggingCallback = (cb) => {
  if (typeof cb !== "function") {
    throw {error: "Callback must be a function."};
  }
  ERR_CB = cb;
};
