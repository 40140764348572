import React from "react";
import styled, {css} from "styled-components";
import autobind from "class-autobind-decorator";
import PropTypes from "prop-types";

import {asField} from "../lib/mobx-form";
import {copyToClipBoard} from "../services/utils";
import Label from "./Label";
import ReactTooltip from "react-tooltip";

@autobind
class Input extends React.Component {
  static propTypes = {
    allowAutocomplete: PropTypes.bool,
  };
  static defaultProps = {
    allowAutocomplete: false,
  };

  onChange(e) {
    const {fieldApi, onChange, type, persistType} = this.props;
    const {setValue} = fieldApi;
    let value =
      type === "file"
        ? {
            file: e.target.files[0],
            path: e.target.value,
          }
        : e.target.value;
    if (value === "") {
      value = undefined;
    }
    if ((persistType && type === "number") || type === "range") {
      setValue(+value);
    } else {
      setValue(value);
    }

    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const {
      fieldState,
      forwardedRef,
      label,
      field,
      type,
      placeholder,
      spellCheck,
      onClick,
      style,
      copyIcon,
      allowAutocomplete,
      onBlur,
      fieldInfo,
      persistType,
    } = this.props;
    const {value, disabled, error} = fieldState;

    // Decide value for input based on type
    let inputValue;
    if (type === "file") {
      inputValue = value?.path ?? "";
    } else {
      inputValue = value;
    }

    return (
      <Container>
        {label && <Label htmlFor={field}>{label}</Label>}
        {error && (
          <Label
            error
            htmlFor={field}
          >
            {error}
          </Label>
        )}
        <StyledInput
          id={field}
          type={type}
          ref={forwardedRef}
          value={inputValue}
          spellCheck={spellCheck}
          onChange={this.onChange}
          onBlur={onBlur}
          error={!!error}
          userDisabled={disabled}
          placeholder={placeholder}
          onClick={onClick}
          style={style}
          readOnly={disabled}
          copyIcon={copyIcon}
          fieldInfo={fieldInfo}
          autoComplete={allowAutocomplete ? undefined : "chrome-off"}
          persistType={!!persistType}
        />
        {copyIcon && (
          <CopyIcon
            className="material-icons"
            onClick={() => copyToClipBoard(inputValue)}
            title="Copy to clipboard"
          >
            file_copy
          </CopyIcon>
        )}
        {fieldInfo && (
          <InfoIcon
            className="material-icons"
            title={fieldInfo}
          >
            info
          </InfoIcon>
        )}
        <ReactTooltip
          delayShow={500}
          id={this.id}
          place="right"
          type="dark"
          effect="solid"
        />
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;
  position: relative;
`;

const StyledInput = styled.input`
  padding: ${(props) => props.theme.forms.padding};
  border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  border-radius: ${(props) => props.theme.forms.borderRadius};
  background-color: ${(props) => props.theme.colours.inputBackground};
  line-height: 1.5rem;

  &::placeholder {
    color: ${(props) => props.theme.colours.contentLight};
  }

  ${(props) =>
    props.userDisabled &&
    css`
      background-color: ${(props) => props.theme.colours.contentBackgroundAlt};
      color: ${(props) => props.theme.colours.contentLight};
    `}

  ${(props) =>
    !props.userDisabled &&
    css`
      &:focus {
        border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.primaryHighlight};
        outline: none;
      }
    `}

  &[type=file] {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
  }

  ${(props) =>
    props.error &&
    css`
      border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.bad} !important;
    `}

  ${(props) =>
    props.copyIcon &&
    css`
      padding-right: 5rem;
      text-overflow: ellipsis;
    `}

  ${(props) =>
    props.fieldInfo &&
    css`
      padding-right: 5rem;
      text-overflow: ellipsis;
    `}
`;

const CopyIcon = styled.i`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: ${(props) => props.theme.forms.padding};
  color: ${(props) => props.theme.colours.contentLight};
  font-size: 1.8rem !important;

  &:hover {
    color: ${(props) => props.theme.colours.primaryHighlight};
    cursor: pointer;
  }
`;

const InfoIcon = styled.i`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: ${(props) => props.theme.forms.padding};
  color: ${(props) => props.theme.colours.contentLight};
  font-size: 1.8rem !important;

  &:hover {
    color: ${(props) => props.theme.colours.primaryHighlight};
  }
`;

export default asField(Input, "");
