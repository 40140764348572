import React from "react";
import {observer, useLocalStore} from "mobx-react";
import {reaction, action} from "mobx";
import FroalaEditor from "froala-editor";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";

import {useRootStore} from "stores";
import {PageContent} from "components/page";
import {Validations, Input, Select} from "sensoteq-react-core/forms";
import Popup from "sensoteq-react-core/components/Popup";
import LoadingAnimation from "sensoteq-react-core/components/LoadingAnimation";
import EmptyState from "components/common/EmptyState";
import ReportsPageRoutes from "routes/reports/reports-routes";
import FroalaEditorComponent from "react-froala-wysiwyg";
import useSubscription from "components/hooks/useSubscription";
import * as Styled from "./ReportEditTab.styles";
import * as Enums from "constants/enums";
import {htmlToPdf, Orientations} from "services/PDFService";
import {debounce} from "lodash";

// Froala requirements
import buildMachineAlarmLog from "./components/buildMachineAlarmLog";
import buildSiteAlarmLog from "./components/buildSiteAlarmLog";
import buildGraph from "./components/buildGraph";
import buildGraphInfo from "./components/buildGraphInfo";
import buildSiteStatus from "./components/buildSiteStatus";
import {ReportSessionStorageEnum} from "components/layout/ReportDock";

const ReportsPage = (props) => {
  const rootStore = useRootStore();
  const {reportStore, routerStore, siteStore, themeStore, userStore, companyStore} = rootStore;
  const {
    routeParams,
    replaceRouteParams,
    popoverEditor = false,
    reportName,
    reportVersion,
    setSelectedReport,
    disableAutosave = false,
    createNewReport = false,
    setCreateNewReport,
  } = props;
  const ref = React.useRef({editor: null});
  const [selectedReportName, setSelectedReportName] = React.useState(popoverEditor ? reportName : routerStore?.name);
  const [selectedReportVersion, setSelectedReportVersion] = React.useState(
    popoverEditor ? reportVersion : routerStore?.version,
  );

  // Report subscription
  const subscription = useSubscription(() => {
    const sub = reportStore.subscribe("default", {recreate: true});
    sub.update({
      name: selectedReportName,
      version: selectedReportVersion,
      companyId: reportStore.companyId,
    });
    return sub;
  }, [reportStore, selectedReportName, selectedReportVersion]);

  // Keep route params up to date
  React.useEffect(() => {
    return reaction(
      () => ({
        name: subscription.sub?.params.name,
        version: subscription.sub?.params.version,
      }),
      (params) => {
        if (!subscription.sub?.active) {
          return;
        }
        if (popoverEditor) {
          // set selected report in parent
          if (setSelectedReport && params.name && params.version)
            setSelectedReport({
              name: params.name,
              version: params.version,
            });
        } else {
          replaceRouteParams({
            name: params.name,
            version: params.version,
          });
        }
      },
    );
  }, []);

  // Keep subscription up to date
  React.useEffect(() => {
    if (popoverEditor) return;
    return reaction(
      () => ({
        name: routeParams?.name,
        version: routeParams?.version,
      }),
      (params) => {
        subscription.sub.update({
          name: params.name,
          version: params.version,
          companyId: reportStore.companyId,
        });
      },
      {
        fireImmediately: true,
      },
    );
  }, []);

  const state = useLocalStore(() => ({
    editor: null,
    showSavePopup: false,
    showGraphPopup: false,
    showAlarmPopup: false,
    showSiteStatusPopup: false,
    showPDFPopup: false,
    pdfProgress: 0,
    documentReadyMode: true,
    firstRender: true,

    get siteOptions() {
      return siteStore.sites.map((x) => ({
        label: x.display_name,
        value: x.site_name,
      }));
    },
    get timeOptions() {
      return [
        {
          label: "Last 1 week",
          value: Enums.TIME_OPTION_1W,
        },
        {
          label: "Last 1 month",
          value: Enums.TIME_OPTION_1M,
        },
        {
          label: "Last 6 months",
          value: Enums.TIME_OPTION_6M,
        },
      ];
    },

    setEditor: action((editor) => {
      state.editor = editor;
    }),
    setShowSavePopup: action((show) => {
      state.showSavePopup = show;
    }),
    setShowGraphPopup: action((show) => {
      state.showGraphPopup = show;
    }),
    setShowAlarmPopup: action((show) => {
      state.showAlarmPopup = show;
    }),
    setShowPDFPopup: action((show) => {
      state.showPDFPopup = show;
    }),
    setShowSiteStatusPopup: action((show) => {
      state.showSiteStatusPopup = show;
    }),
    setDocumentReadyMode: action((active) => {
      state.documentReadyMode = active;
    }),
    setFirstRender: action((firstRender) => {
      state.firstRender = firstRender;
    }),
    setPDFProgress: action((progress) => {
      state.pdfProgress = progress;
    }),
  }));

  // Callback to save report
  const saveReport = React.useCallback(async ({name}, silent) => {
    subscription.sub.setTitle(name);
    state.editor.selection.save();
    subscription.sub.setEditorState(state.editor.html.get(true));
    await subscription.sub.save(reportStore.companyId, silent);
    state.editor.selection.restore();
    state.setShowSavePopup(false);
  }, []);

  React.useEffect(() => {
    if (createNewReport) {
      const save = async () => {
        await saveReport({name: subscription.sub.title});
        subscription.sub.setEditorState("");
        setCreateNewReport(false);
        sessionStorage.removeItem(ReportSessionStorageEnum.NAME);
        sessionStorage.removeItem(ReportSessionStorageEnum.VERSION);
        setSelectedReport(null);
        setSelectedReportName(null);
        setSelectedReportVersion(null);
      };
      save();

      return () => {
        if (createNewReport) {
          setCreateNewReport(false);
          setSelectedReport(false);
          setSelectedReportName(false);
          setSelectedReportVersion(false);
        }
      };
    }
  }, [createNewReport, saveReport, setCreateNewReport, setSelectedReport, subscription.sub]);

  // Editor initialization
  React.useEffect(() => {
    if (ref.current) {
      state.setEditor(ref.current.editor);
    }
    if (userStore.admin || userStore.isPartnerUser) {
      companyStore.loadCompanies();
    }
  }, [ref.current]);

  React.useEffect(() => {
    setSelectedReportName(reportName);
    setSelectedReportVersion(reportVersion);
    if (
      !state.firstRender &&
      (reportName === null || reportName === undefined) &&
      (reportVersion === null || reportVersion === undefined)
    ) {
      subscription.sub.setEditorState("");
    }

    return () => {
      setSelectedReportName(reportName);
      setSelectedReportVersion(reportVersion);
    };
  }, [reportName, reportVersion, state]);

  const autoSave = React.useCallback(
    debounce(async () => {
      if (!subscription.sub.title) {
        state.setShowSavePopup(true);
      } else {
        await saveReport({name: subscription.sub.title}, true);
      }
    }, 1500),
    [state, subscription?.sub],
  );

  const removeFroalaMarkers = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const markers = doc.querySelectorAll(".fr-marker");
    markers.forEach((marker) => marker.remove());
    return doc.body.innerHTML;
  };

  const compareModelContent = React.useCallback((original, updated) => {
    const parsedOriginal = removeFroalaMarkers(original);
    const parsedUpdated = removeFroalaMarkers(updated);
    return parsedOriginal === parsedUpdated;
  }, []);

  // Callback when model changes
  const onModelChange = React.useCallback(
    (model) => {
      if (compareModelContent(subscription.sub.editorState, model)) return;
      subscription.sub.setEditorState(model);
      if (popoverEditor && !disableAutosave) {
        autoSave();
      }
    },
    [compareModelContent, subscription.sub, popoverEditor, disableAutosave, autoSave],
  );

  // Callback to download report as a PDF
  const downloadPDF = React.useCallback(() => {
    state.setPDFProgress("Please wait a few seconds while your PDF is generated");
    state.setShowPDFPopup(true);

    const originalState = state.editor.html.get(true);

    //Add explicit heights to all images
    const container = document.getElementsByClassName("fr-element")[0];
    const imgs = container.getElementsByTagName("img");
    for (let i = 0; i < imgs.length; i++) {
      let img = imgs[i];

      if (!img.style.height) {
        img.style.height = `${img.clientHeight}px`;
      }
    }

    // Get markup to PDF
    const markup = `<div class="froala-print-wrapper">${container.outerHTML}</div>`;

    // Update progress on each page render and render PDF
    const progressCallback = (pageIdx, pages) => state.setPDFProgress(`Generating page ${pageIdx} of ${pages}...`);
    htmlToPdf(markup, {
      margin: 40,
      fileName: subscription.sub.title || "report.pdf",
      orientation: Orientations.PORTRAIT,
      progressCallback,
      htmlScale: themeStore.printScale,
    }).then(() => {
      // Cleanup after rendering
      state.setShowPDFPopup(false);
      const overlays = document.getElementsByClassName("html2pdf__overlay");
      if (overlays.length) {
        document.body.removeChild(overlays[0]);
      }

      state.editor.html.set(originalState);
    });
  }, [state, subscription.sub?.title, themeStore.printScale]);

  // Callback to insert a graph image
  const insertGraph = React.useCallback(
    (graph) => {
      state.editor.html.insert(buildGraph(graph));
      if (graph.additionalInfo) {
        state.editor.html.insert(buildGraphInfo(themeStore, graph.additionalInfo));
      }
      state.setShowGraphPopup(false);
    },
    [state, themeStore],
  );

  const selectCompany = React.useCallback(
    (companyId) => {
      reportStore.setCompanyId(companyId);
    },
    [reportStore],
  );

  // Callback to insert an alarm log
  const insertAlarmLog = React.useCallback(
    async (values) => {
      let alarmLog;
      if (values.machine) {
        alarmLog = await buildMachineAlarmLog(
          rootStore,
          values.machine,
          state.timeOptions.find((to) => to.value === values.timeOption),
        );
      } else {
        alarmLog = await buildSiteAlarmLog(
          rootStore,
          values.site,
          state.timeOptions.find((to) => to.value === values.timeOption),
        );
      }
      state.editor.html.insert(alarmLog);
      state.setShowAlarmPopup(false);
    },
    [rootStore, state],
  );

  // Callback to insert a site status table
  const insertSiteStatus = React.useCallback(
    async (values) => {
      const status = await buildSiteStatus(rootStore, values.site, values.timeOption);
      state.editor.html.insert(status);
      state.setShowSiteStatusPopup(false);
    },
    [rootStore, state],
  );

  // Override of Froala "image.beforeUpload" method to instead insert a base64 image
  const imageBeforeUpload = React.useCallback((files, editor) => {
    if (files.length) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const result = e.target.result;
        editor.image.insert(result, null, null, editor.image.get());
      };
      reader.readAsDataURL(files[0]);
    }
    editor.popups.hideAll();
  }, []);

  // Create custom buttons before initial render
  if (state.firstRender) {
    // Define material icons template for use with custom buttons
    FroalaEditor.DefineIconTemplate("material-icons", '<i class="material-icons">[NAME]</i>');

    // Save report button
    FroalaEditor.DefineIcon("saveReport", {NAME: "save", template: "material-icons"});
    FroalaEditor.RegisterCommand("saveReport", {
      title: "Save",
      focus: true,
      undo: false,
      refreshAfterCallback: false,
      callback: () => state.setShowSavePopup(true),
    });

    // Insert graph button
    FroalaEditor.DefineIcon("insertGraph", {NAME: "insert_chart", template: "material-icons"});
    FroalaEditor.RegisterCommand("insertGraph", {
      title: "Graph Clipboard",
      focus: true,
      undo: false,
      refreshAfterCallback: false,
      callback: () => state.setShowGraphPopup(true),
    });

    // Insert alarm log
    FroalaEditor.DefineIcon("insertAlarms", {NAME: "assignment", template: "material-icons"});
    FroalaEditor.RegisterCommand("insertAlarms", {
      title: "Insert Alarm Log",
      focus: true,
      undo: false,
      refreshAfterCallback: false,
      callback: () => state.setShowAlarmPopup(true),
    });

    // Insert site status
    FroalaEditor.DefineIcon("insertSiteStatus", {NAME: "location_city", template: "material-icons"});
    FroalaEditor.RegisterCommand("insertSiteStatus", {
      title: "Insert Site Status",
      focus: true,
      undo: false,
      refreshAfterCallback: false,
      callback: () => state.setShowSiteStatusPopup(true),
    });

    // Download PDF button
    FroalaEditor.DefineIcon("downloadPDF", {NAME: "picture_as_pdf", template: "material-icons"});
    FroalaEditor.RegisterCommand("downloadPDF", {
      title: "Download as PDF",
      focus: true,
      undo: false,
      refreshAfterCallback: false,
      callback: downloadPDF,
    });

    if (!popoverEditor) {
      // Close button
      FroalaEditor.DefineIcon("close", {NAME: "cancel", template: "material-icons"});
      FroalaEditor.RegisterCommand("close", {
        title: "Exit editor",
        focus: true,
        undo: false,
        refreshAfterCallback: false,
        callback: () => routerStore.navigate(ReportsPageRoutes.ReportOpenTab),
      });
    }
  }

  // Unset first render flag
  React.useEffect(() => {
    state.setFirstRender(false);
  }, [state]);

  // Wait for subscription initialisation
  if (subscription.sub == null) {
    return null;
  }

  return (
    <PageContent padded={false}>
      <Styled.Container popoverEditor>
        <FroalaEditorComponent
          ref={ref}
          tag="textarea"
          model={subscription.sub.editorState}
          onModelChange={onModelChange}
          config={{
            key: process.env.FROALA_EDITOR_LICENSE,
            documentReady: true,
            toolbarButtons: buttons,
            toolbarButtonsMD: buttons,
            toolbarButtonsSM: buttons,
            toolbarButtonsXS: buttons,
            imageUploadRemoteUrls: false,
            spellcheck: false,
            attribution: false,
            immediateReactModelUpdate: false,
            pluginsEnabled: [
              "align",
              "colors",
              "draggable",
              "entities",
              "fontSize",
              "fullscreen",
              "image",
              "lineBreaker",
              "lineHeight",
              "link",
              "lists",
              "paragraphFormat",
              "quote",
              "table",
              "url",
              "wordPaste",
            ],
            events: {
              "image.beforeUpload": function (files) {
                imageBeforeUpload(files, this);

                // Stop default upload chain
                return false;
              },
            },
          }}
        />
      </Styled.Container>

      {/* Save popup */}
      {state.showSavePopup && (
        <Popup
          onCancel={() => state.setShowSavePopup(false)}
          onConfirm={saveReport}
          title="Save report"
          initialValues={{name: subscription.sub.title}}
          loadingOverlay={subscription.sub.loading}
        >
          <Input
            field="name"
            label="Report name"
            validate={Validations.Required}
          />
          {(userStore.admin || userStore.isPartnerUser) && (
            <Select
              field="company"
              label="Company"
              options={companyStore.companyOptions}
              initialValue={reportStore.companyId}
              onChange={selectCompany}
            />
          )}
        </Popup>
      )}

      {/* Graph insert popup */}
      {state.showGraphPopup && (
        <Popup
          onCancel={() => state.setShowGraphPopup(false)}
          onConfirm={() => state.setShowGraphPopup(false)}
          confirmText="Close"
          hideCancelButton
          title="Insert graph"
          wide
        >
          {reportStore.images.length === 0 && (
            <EmptyState
              title="No graphs available"
              text={`Right click any graph when browsing data and choose "Add to report" to list it here.`}
              icon="image_search"
            />
          )}
          {reportStore.images.length > 0 &&
            reportStore.images.map((image, idx) => {
              const insert = () => insertGraph(image);
              return (
                <Styled.GraphImage key={idx}>
                  <div>
                    <span onClick={insert}>{image.title || "Untitled"}</span>
                    <i
                      className="material-icons"
                      onClick={() => reportStore.deleteImage(image.id)}
                      title="Delete image"
                    >
                      delete_forever
                    </i>
                  </div>
                  <img
                    src={image.data}
                    alt="graph"
                    onClick={insert}
                  />
                </Styled.GraphImage>
              );
            })}
        </Popup>
      )}

      {/* Alarm log popup */}
      {state.showAlarmPopup && (
        <Popup
          onCancel={() => state.setShowAlarmPopup(false)}
          onConfirm={insertAlarmLog}
          confirmText="Insert"
          title="Insert alarm log"
          initialValues={{
            timeOption: Enums.TIME_OPTION_1W,
          }}
        >
          {({values}) => {
            const machineOptions = siteStore.getMachinesForSite(values.site).map((x) => ({
              label: x.label,
              value: x.machine_id,
            }));
            return (
              <React.Fragment>
                <Select
                  field="site"
                  label="Site"
                  options={state.siteOptions}
                  placeholder="Choose a site..."
                  validate={Validations.Required}
                />
                {values.site && (
                  <Select
                    label="Machine"
                    field="machine"
                    options={machineOptions}
                    placeholder="All machines"
                  />
                )}
                <Select
                  field="timeOption"
                  label="Report history"
                  options={state.timeOptions}
                  validate={Validations.Required}
                />
              </React.Fragment>
            );
          }}
        </Popup>
      )}

      {/* Site status popup */}
      {state.showSiteStatusPopup && (
        <Popup
          onCancel={() => state.setShowSiteStatusPopup(false)}
          onConfirm={insertSiteStatus}
          confirmText="Insert"
          title="Insert site status"
          initialValues={{
            timeOption: Enums.TIME_OPTION_1W,
          }}
        >
          <Select
            field="site"
            label="Site"
            options={state.siteOptions}
            placeholder="Choose a site..."
            validate={Validations.Required}
          />
          <Select
            field="timeOption"
            label="Report history"
            options={state.timeOptions}
            validate={Validations.Required}
          />
        </Popup>
      )}

      {/* Loading indicator while PDF is being rendered */}
      {state.showPDFPopup && (
        <Popup
          title={null}
          hideCancelButton
          hideConfirmButton
        >
          <div>
            <Styled.PDFLoadingContainer>
              <LoadingAnimation
                showBackground={false}
                text={state.pdfProgress}
                showText={true}
              />
            </Styled.PDFLoadingContainer>
          </div>
        </Popup>
      )}

      {/* Initial loading overlay */}
      {(state.firstRender || (subscription.sub.initialLoad && subscription.sub.params.name)) && (
        <Styled.LoadingOverlay>
          <LoadingAnimation />
        </Styled.LoadingOverlay>
      )}
    </PageContent>
  );
};

export default observer(ReportsPage);

const buttons = {
  controls: {
    buttons: ["undo", "redo", "saveReport", "downloadPDF"],
    buttonsVisible: 4,
  },
  moreText: {
    buttons: [
      "paragraphFormat",
      "fontSize",
      "bold",
      "italic",
      "underline",
      "textColor",
      "backgroundColor", // Visible buttons
      "strikeThrough",
      "subscript",
      "superscript",
      "inlineClass",
      "inlineStyle",
      "clearFormatting",
    ],
    buttonsVisible: 7,
  },
  moreParagraph: {
    buttons: [
      "align",
      "formatOLSimple",
      `formatUL`, // Visible buttons
      "paragraphStyle",
      "lineHeight",
      "outdent",
      "indent",
      "quote",
    ],
    buttonsVisible: 3,
  },
  moreRich: {
    buttons: [
      "insertLink",
      "insertTable",
      "insertImage",
      "insertGraph",
      "insertAlarms",
      `insertSiteStatus`, // Visible buttons
    ],
    buttonsVisible: 6,
  },
  fullscreen: {
    buttons: [
      "fullscreen",
      "close", // Visible buttons
    ],
    buttonsVisible: 2,
    align: "right",
  },
};
