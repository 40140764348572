import {observable, computed, flow} from "mobx";

import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import ApiKeySubscription from "models/ApiKeySubscription";
import Api from "sensoteq-react-core/services/api";

export default class ApiKeyStore extends SubscribableStore {
  @observable _apiKeys;

  constructor(rootStore) {
    super(rootStore, ApiKeySubscription);
  }

  @computed get apiKeys() {
    return this._apiKeys ?? [];
  }

  loadApiKeys = flow(function* () {
    this.startLoading();
    try {
      const data = yield Api.getApiKeys();
      this._apiKeys = data.apikeys;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error loading API keys: ${error}`, "bad");
    }
    this.stopLoading();
  });

  async generateApiKey(name) {
    this.startLoading();
    try {
      const data = await Api.generateApiKey(name);
      this.rootStore.notificationStore.addNotification("API key generated successfully");
      this.loadApiKeys();
      this.stopLoading();
      return data;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error generating API key: ${error}`, "bad");
    }
    this.stopLoading();
  }

  async revokeApiKey(apiKeyId) {
    this.startLoading();
    try {
      await Api.revokeApiKey(apiKeyId);
      this.rootStore.notificationStore.addNotification("API key revoked successfully");
      this.loadApiKeys();
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error revoking API key: ${error}`, "bad");
    }
    this.stopLoading();
  }

  async revokeApiKeyForUser(username, apiKeyId) {
    this.startLoading();
    try {
      await Api.revokeUserApiKey(username, apiKeyId);
      this.rootStore.notificationStore.addNotification("API key revoked successfully");
      this.sync(username);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error revoking API key: ${error}`, "bad");
    }
    this.stopLoading();
  }
}
