import {observable, flow, computed} from "mobx";
import dayjs from "dayjs";
import {parse} from "useragent-parser-js";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";
import {sortArrayByProperty} from "sensoteq-react-core/services/utils";
import {smartRound} from "sensoteq-core/calculations";

export default class SessionSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      username: null,
      days: null,
      all: false,
    };
  }

  @observable.shallow _sessions;

  getData = flow(function* ({username, days, all}) {
    if ((!all && !username) || !days) {
      return;
    }
    this.startLoading();
    try {
      const data = yield Api.getSessionsForUser(username, dayjs().subtract(days, "day").valueOf());
      this._sessions = data.sessions;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting user sessions: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get sessions() {
    if (!this._sessions) {
      return [];
    }
    return this._sessions.map((session) => ({
      ...session,
      ua: this.parseUserAgent(session.user_agent || ""),
    }));
  }

  @computed get lastActivity() {
    let lastActivity = null;
    this.sessions.forEach((session) => {
      if (!lastActivity || session.last_access > lastActivity) {
        lastActivity = session.last_access;
      }
    });
    return lastActivity ? dayjs(lastActivity) : null;
  }

  @computed get activeSessions() {
    return this.sessions.filter((x) => !x.expired);
  }

  @computed get browsers() {
    return this.createStatProfile((x) => x.ua.browser, "browser", [{replace: "unknown", with: "Unknown"}]);
  }

  @computed get topBrowser() {
    return this.createTopStat(this.browsers, "browser");
  }

  @computed get platforms() {
    return this.createStatProfile((x) => x.ua.platform, "platform", [
      {replace: "unknown", with: "Unknown"},
      {replace: "Microsoft Windows", with: "Windows"},
    ]);
  }

  @computed get topPlatform() {
    return this.createTopStat(this.platforms, "platform");
  }

  @computed get users() {
    return this.createStatProfile((x) => x.username, "username");
  }

  @computed get activeUsersCount() {
    return new Set(this.sessions.filter((x) => !x.expired).map((x) => x.username)).size;
  }

  @computed get activity() {
    // Add initial dates with 0 sessions
    let data = [];
    for (let i = this.params.days; i >= 0; i--) {
      data.push({
        date: dayjs().subtract(i, "day").startOf("day"),
        sessions: 0,
      });
    }

    // Create lookup map for fast reference
    let lookupMap = {};
    data.forEach((entry, idx) => {
      lookupMap[entry.date.valueOf()] = idx;
    });

    // Add each session to its relevant days
    let start = dayjs().subtract(this.params.days, "day").startOf("day");
    let now = dayjs();
    this.sessions.forEach((session) => {
      let date = dayjs(session.created_at).startOf("day");
      let lastAccess = dayjs(session.last_access);
      while (date < now && date < lastAccess) {
        if (date >= start) {
          const idx = lookupMap[date.valueOf()];
          if (idx != null) {
            data[idx].sessions++;
          }
        }
        date = date.add(1, "day");
      }
    });

    return data;
  }

  @computed get averageSessionTime() {
    if (!this.sessions.length) {
      return 0;
    }

    let total = 0;
    let sessions = 0;
    this.sessions
      .filter((x) => x.time_active > 60000 && x.time_active < 1000 * 3600 * 24)
      .forEach((session) => {
        if (session.time_active) {
          total += session.time_active;
          sessions++;
        }
      });
    return sessions === 0 ? 0 : smartRound(total / (sessions * 1000 * 3600));
  }

  getUserLastAccess(username) {
    return this.lastAccessLookupMap[username];
  }

  getSessionCountForUser(username) {
    const count = this.sessionCountMap[username];
    return count == null ? 0 : count;
  }

  @computed get lastAccessLookupMap() {
    const now = new Date().valueOf();
    let map = {};
    this.sessions.forEach((session) => {
      // Ensure that client/server time sync differences never cause dates in the future
      const lastAccess = Math.min(now, session.last_access);
      if (!map[session.username]) {
        map[session.username] = lastAccess;
      } else {
        if (lastAccess > map[session.username]) {
          map[session.username] = lastAccess;
        }
      }
    });
    return map;
  }

  @computed get sessionCountMap() {
    let map = {};
    this.sessions.forEach((session) => {
      if (!map[session.username]) {
        map[session.username] = 0;
      }
      map[session.username]++;
    });
    return map;
  }

  parseUserAgent(userAgent) {
    let ua = parse(userAgent);

    // Manual overrides
    if (ua.source.startsWith("okhttp")) {
      ua.platform = "Android";
      ua.browser = "Install App";
    } else if (ua.source.startsWith("python-requests")) {
      ua.platform = "Programmatic";
      ua.browser = "Python Requests";
    }

    return ua;
  }

  createStatProfile(selectValue, keyName, replacements, limit = 5) {
    // Create map
    let map = {};
    this.sessions.forEach((session) => {
      const value = selectValue(session);
      if (value) {
        if (!map[value]) {
          map[value] = 1;
        } else {
          map[value]++;
        }
      }
    });

    // Replace values
    if (replacements) {
      replacements.forEach((replacement) => {
        if (map[replacement.replace]) {
          map[replacement.with] = map[replacement.replace];
          delete map[replacement.replace];
        }
      });
    }

    // Format
    let data = Object.entries(map).map(([key, value]) => ({
      [keyName]: key,
      sessions: value,
    }));
    return sortArrayByProperty(data, "sessions", true).slice(0, limit);
  }

  createTopStat(statProfile, keyName, fallback = "Unknown") {
    if (!statProfile.length) {
      return fallback;
    }
    return statProfile[0][keyName];
  }
}
