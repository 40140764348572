import React from "react";
import styled, {css} from "styled-components";
import dayjs from "dayjs";
import autobind from "class-autobind-decorator";
import PropTypes from "prop-types";
import {observable, action, computed} from "mobx";
import {observer} from "mobx-react";

import DayjsPicker, {DateLabel} from "./DayjsPicker";
import {asField} from "../lib/mobx-form";
import Label from "./Label";

@observer
@autobind
class DateRangePicker extends React.Component {
  static propTypes = {
    border: PropTypes.bool,
  };
  static defaultProps = {
    border: true,
  };
  @observable fromOpen = false;
  @observable toOpen = false;

  getValue() {
    let value = this.props.fieldState.value;
    if (!value.dateFrom) {
      value.dateFrom = dayjs();
    }
    if (!value.dateTo) {
      value.dateTo = dayjs();
    }
    return value;
  }

  onChange(dateFrom, dateTo) {
    const {fieldApi, onChange} = this.props;
    const {setValue} = fieldApi;

    // Sanitise dates
    let sanitisedDateFrom = dayjs(dateFrom).startOf("day");
    let sanitisedDateTo = dayjs(dateTo).endOf("day");

    // Update value
    const value = {
      dateFrom: sanitisedDateFrom,
      dateTo: sanitisedDateTo,
    };
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  }

  onChangeFrom(value) {
    const to = this.getValue().dateTo;
    this.onChange(value, value.isAfter(to) ? value : to);
  }

  onChangeTo(value) {
    const from = this.getValue().dateFrom;
    this.onChange(value.isBefore(from) ? value : from, value);
  }

  @action setFromOpen(open) {
    this.fromOpen = open;
  }

  @action setToOpen(open) {
    this.toOpen = open;
  }

  @computed get isOpen() {
    return this.fromOpen || this.toOpen;
  }

  render() {
    const {label, field, fieldState, border, style} = this.props;
    const {error, disabled} = fieldState;
    const {dateFrom, dateTo} = this.getValue();
    return (
      <Wrapper style={style}>
        {label && <Label htmlFor={field}>{label}</Label>}
        {error && (
          <Label
            error
            htmlFor={field}
          >
            {error}
          </Label>
        )}
        <Container
          open={this.isOpen}
          border={border}
        >
          <DatePickerWrapper open={this.fromOpen}>
            <DayjsPicker
              onChange={this.onChangeFrom}
              selected={dateFrom}
              disabled={disabled}
              onOpen={() => this.setFromOpen(true)}
              onClose={() => this.setFromOpen(false)}
              controlled
              allowNull={false}
            />
          </DatePickerWrapper>
          <Icon className="material-icons">arrow_forward</Icon>
          <DatePickerWrapper open={this.toOpen}>
            <DayjsPicker
              onChange={this.onChangeTo}
              selected={dateTo}
              disabled={disabled}
              onOpen={() => this.setToOpen(true)}
              onClose={() => this.setToOpen(false)}
              controlled
              allowNull={false}
            />
          </DatePickerWrapper>
        </Container>
      </Wrapper>
    );
  }
}
export default asField(DateRangePicker, {});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${(props) => props.theme.colours.inputBackground};
  border-radius: ${(props) => props.theme.forms.borderRadius};
  border: ${(props) => props.theme.forms.borderWidth} solid transparent;

  ${DateLabel} {
    border: none !important;
  }

  ${(props) =>
    props.border &&
    css`
      border-color: ${(props) => props.theme.colours.border};
    `}

  ${(props) =>
    !props.border &&
    css`
      box-shadow: ${(props) => props.theme.forms.boxShadow};
      background-color: ${(props) => props.theme.colours.contentBackground};

      ${DateLabel} {
        background-color: ${(props) => props.theme.colours.contentBackground};
      }
    `}
  
  ${(props) =>
    props.open &&
    css`
      border-color: ${(props) => props.theme.colours.primaryHighlight};
    `}
`;

const DatePickerWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  ${(props) =>
    props.open &&
    css`
      ${DateLabel} {
        color: ${(props) => props.theme.colours.primaryHighlight};
      }
    `}
`;

const Icon = styled.i`
  font-size: 1.4rem !important;
  font-weight: bold;
`;
