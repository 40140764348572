import React from "react";
import {createRoot} from "react-dom/client";
import {initialize as configureGA} from "react-ga";
import {configure as configureMobx} from "mobx";

import configureDayjsPlugins from "./dayjs-plugins";
import StoreWrapper from "./StoreWrapper";
import ThemeWrapper from "./ThemeWrapper";
import ErrorBoundary from "components/layout/ErrorBoundary";
import {StoreContext} from "stores";
import {Router} from "sensoteq-react-core/lib/mobx-state-router";
import App from "routes/App";
import {IS_PRODUCTION} from "constants/enums";
import "./styles/tailwind.css";
import "@sensoteq/styles/dist/sensoteq-tokens.css";
import {setupMockServer} from "../tests/mocks/setupMockServer";

// Import required styles that aren't CSS-in-JS
import "normalize.css/normalize.css";
import "./styles/reset.css";
import "./styles/sensoteq-font.css";

const toggleMocking = async () => {
  if (process.env.TEST_ENV === "mock") {
    const IS_MOCK = process.env.TEST_ENV === "mock";
    const IS_CI = process.env.CI === "true";
    await setupMockServer(IS_MOCK, IS_CI);
  }
  return Promise.resolve();
};
// Enforce mobx strict mode
configureMobx({
  enforceActions: "always",
});

// Configure dayjs with plugins
configureDayjsPlugins();

// Enable google analytics in production
if (IS_PRODUCTION) {
  configureGA("UA-102210257-1", {
    gaOptions: {
      cookieFlags: "secure;samesite=none",
    },
  });
}

// Render app to DOM
let hmi = (
  <StoreWrapper>
    <ThemeWrapper>
      <ErrorBoundary>
        <StoreContext.Consumer>
          {(rootStore) => (
            <Router routerStore={rootStore.routerStore}>
              <App />
            </Router>
          )}
        </StoreContext.Consumer>
      </ErrorBoundary>
    </ThemeWrapper>
  </StoreWrapper>
);
const useStrictMode = false;
if (useStrictMode) {
  hmi = <React.StrictMode>{hmi}</React.StrictMode>;
}

const root = createRoot(document.getElementById("root"));
toggleMocking().then(() => root.render(hmi));
