import {observer} from "mobx-react";

import * as Styled from "./MainMenuLink.styles";
import {useRootStore} from "stores";
import Icon from "components/common/Icon";

const WhatsNewMenuLink = () => {
  const {uiStore} = useRootStore();

  // NOTE new website does not expose same endpoints for fetching updates
  // May be able to resurrect in future but for now disable and show static link
  //
  //useEffect(() => {
  //   try {
  //     WPStore.loadPosts();
  //   } catch (error) {
  //     // This action happens in background and is non-critical, don't allow
  //     // errors to interfere with Analytix app
  //   }
  // }, [WPStore]);

  // const onClick = useCallback(() => {
  //   preferenceStore.updateReleasePostDate(WPStore.latestPostDate);
  // }, [preferenceStore, WPStore.latestPostDate]);
  // const showHighlight = Boolean(WPStore.latestPostDate) && WPStore.latestPostDate > preferenceStore.releasePostDate;
  //
  // if (!WPStore.latestPostLink) {
  //   return null;
  // }
  const showHighlight = false;

  return (
    <Styled.Highlight
      active={false}
      highlight={showHighlight}
      collapsed={uiStore.mainMenuCollapsed}
      target="_blank"
      href="https://www.sensoteq.com/post/new-features-for-analytix"
    >
      <Styled.IconContainer collapsed={uiStore.mainMenuCollapsed}>
        <Icon
          library={Icon.Libraries.FontAwesome}
          name="star"
        />
      </Styled.IconContainer>
      {!uiStore.mainMenuCollapsed && <span>What&lsquo;s New</span>}
    </Styled.Highlight>
  );
};

export default observer(WhatsNewMenuLink);
