const d3peaks = require("./libs/d3-peaks/index");
const dsp = require("./libs/dsp");
const FFTWindowing = require("./libs/windowing");
const enumerations = require("./enumerations");
const utils = require("./utils");
const PolynomialRegression = require("./libs/polynomial-regression");
const {getPowerBandsInOrder, getPowerBandFreqRanges} = require("./power-bands/power-bands");

/**
 * Enums used as parameters for various functions.
 */
const WindowingOptions = {
  NONE: "NONE",
  SQUARE_WINDOW: "SQUARE_WINDOW",
  HANN_WINDOW: "HANN_WINDOW",
  SQUARE_AND_HANN_WINDOW: "SQUARE_AND_HANN_WINDOW",
  KAISER_WINDOW: "KAISER_WINDOW",
};

const FilteringOptions = {
  LOW_PASS: dsp.DSP.LOWPASS,
  HIGH_PASS: dsp.DSP.HIGHPASS,
  BAND_PASS: dsp.DSP.BANDPASS,
};

const NyquistOptions = {
  NYQUIST_256: "nyquist256",
  NYQUIST_2: "nyquist2",
};
const UnitConversionOptions = {
  MM_TO_INCHES: "MM_TO_INCHES",
  INCHES_TO_MM: "INCHES_TO_MM",
  MG_TO_INCHES_SQUARED: "MG_TO_INCHES_SQUARED",
  INCHES_SQUARED_TO_MG: "INCHES_SQUARED_TO_MG",
  G_TO_INCHES_SQUARED: "G_TO_INCHES_SQUARED",
  INCHES_SQUARED_TO_G: "INCHES_SQUARED_TO_G",
  G_TO_M_SQUARED: "G_TO_M_SQUARED",
  M_SQUARED_TO_G: "M_SQUARED_TO_G",
  HZ_TO_CPM: "HZ_TO_CPM",
  CPM_TO_HZ: "CPM_TO_HZ",
  CELSIUS_TO_FAHRENHEIT: "CELSIUS_TO_FAHRENHEIT",
  FAHRENHEIT_TO_CELSIUS: "FAHRENHEIT_TO_CELSIUS",
  DELTA_FAHRENHEIT_TO_DELTA_CELSIUS: "DELTA_FAHRENHEIT_TO_DELTA_CELSIUS",
  DELTA_CELSIUS_TO_DELTA_FAHRENHEIT: "DELTA_CELSIUS_TO_DELTA_FAHRENHEIT",
  DEGREES_TO_RADIANS: "DEGREES_TO_RADIANS",
  RADIANS_TO_DEGREES: "RADIANS_TO_DEGREES",
  MICRONS_TO_MILS: "MICRONS_TO_MILS",
  MILS_TO_MICRONS: "MILS_TO_MICRONS",
  MM_TO_MILS: "MM_TO_MILS",
  MILS_TO_MM: "MILS_TO_MM",
  MG_TO_G: "MG_TO_G",
  G_TO_MG: "G_TO_MG",
  LB_TO_GRAM: "LB_TO_GRAM",
  GRAM_TO_LB: "GRAM_TO_LB",
};
const DomainConversionOptions = {
  VELOCITY_DOMAIN: "VELOCITY_DOMAIN",
  DISPLACEMENT_DOMAIN: "DISPLACEMENT_DOMAIN",
};
const VibrationDomains = {
  ACCELERATION: "ACCELERATION",
  VELOCITY: "VELOCITY",
  DISPLACEMENT: "DISPLACEMENT",
};

/**
 * Default params for FFT calculation.
 */
const DEFAULT_WINDOW = WindowingOptions.HANN_WINDOW;
const DEFAULT_COUNT = enumerations.NEW_TD_ENTRY_COUNT;
const DEFAULT_NYQUIST = NyquistOptions.NYQUIST_2;
const DEFAULT_F_MIN = 10;

const RMS_FACTOR = Math.sqrt(2) / 2;
/**
 * Helper function used to check if a frequency falls within the configured notch filters.
 * Used when calculation power bands as notch filters remove frequencies which should not be included.
 *
 * @deprecated not currently used as notch filters were removed from the front end
 * @param frequency {number} the frequency to check
 * @param notchFilter {array} the notch filter configuration object
 * @returns {boolean} whether or not the frequency falls within a notch filter bracket
 */
function checkIsInFilter(frequency, notchFilter) {
  if (notchFilter == null || notchFilter.length === 0) {
    return false;
  }

  for (let filterKey in notchFilter) {
    if (notchFilter.hasOwnProperty(filterKey)) {
      let filterWidth = notchFilter[filterKey][enumerations.PresentableDataTypes.NOTCH_WIDTH];
      let filterFreq = notchFilter[filterKey][enumerations.PresentableDataTypes.NOTCH_FREQUENCY];

      // if it is in between the start and end of the filter band then it is to be removed
      if (frequency < filterFreq - filterWidth) return false;
      // modulus
      let rem = frequency % filterFreq;
      if (rem <= filterWidth || (rem > filterFreq / 2 && filterFreq - rem <= filterWidth)) {
        return true;
      }
    }
  }

  // didn't find a matching band, frequency is fine
  return false;
}

module.exports = {
  DEFAULT_F_MIN,
  RMS_FACTOR,
  WindowingOptions,
  FilteringOptions,
  DomainConversionOptions,
  UnitConversionOptions,
  NyquistOptions,
  VibrationDomains,

  /**
   * Rounds a number to an appropriate number of decimal places, depending on the size of the number.
   * Heavily used for displaying numerical values.
   *
   * @param number {number} the n
   * @returns {number} the rounded number
   */
  smartRound(number) {
    if (isNaN(number) || number === Infinity || number == null) {
      return 0;
    }
    let abs = Math.abs(number);
    if (abs < 1) {
      return parseFloat(number.toPrecision(3));
    } else if (abs < 10) {
      return Math.round(number * 100) / 100;
    } else if (abs < 100) {
      return Math.round(number * 10) / 10;
    } else {
      return Math.round(number);
    }
  },

  /**
   * Converts a numerical value from one unit into another.
   * Heavily used across all parts of the application.
   *
   * @param value {number} the value to convert
   * @param unitConversionOption {string} one of the unit conversion option enums
   * @returns {number|*} the converted value
   */
  convertUnits(value, unitConversionOption) {
    if (value == null || isNaN(value)) {
      return value;
    }

    switch (unitConversionOption) {
      case UnitConversionOptions.MM_TO_INCHES: {
        return value / 25.4;
      }
      case UnitConversionOptions.INCHES_TO_MM: {
        return value * 25.4;
      }
      case UnitConversionOptions.MICRONS_TO_MILS: {
        return value / 25.4;
      }
      case UnitConversionOptions.MILS_TO_MICRONS: {
        return value * 25.4;
      }
      case UnitConversionOptions.MM_TO_MILS: {
        return module.exports.convertUnits(value * 1000, UnitConversionOptions.MICRONS_TO_MILS);
      }
      case UnitConversionOptions.MILS_TO_MM: {
        return module.exports.convertUnits(value, UnitConversionOptions.MILS_TO_MICRONS) / 1000;
      }
      case UnitConversionOptions.MG_TO_INCHES_SQUARED: {
        return value * 0.38609;
      }
      case UnitConversionOptions.INCHES_SQUARED_TO_MG: {
        return value / 0.38609;
      }
      case UnitConversionOptions.G_TO_INCHES_SQUARED: {
        return module.exports.convertUnits(value * 1000, UnitConversionOptions.MG_TO_INCHES_SQUARED);
      }
      case UnitConversionOptions.INCHES_SQUARED_TO_G: {
        return module.exports.convertUnits(value, UnitConversionOptions.INCHES_SQUARED_TO_MG) / 1000;
      }
      case UnitConversionOptions.G_TO_M_SQUARED: {
        return value * enumerations.GRAVITY;
      }
      case UnitConversionOptions.M_SQUARED_TO_G: {
        return value / enumerations.GRAVITY;
      }
      case UnitConversionOptions.HZ_TO_CPM: {
        return value * 60;
      }
      case UnitConversionOptions.CPM_TO_HZ: {
        return value / 60;
      }
      case UnitConversionOptions.CELSIUS_TO_FAHRENHEIT: {
        return value * 1.8 + 32;
      }
      case UnitConversionOptions.FAHRENHEIT_TO_CELSIUS: {
        return (value - 32) / 1.8;
      }
      case UnitConversionOptions.DELTA_CELSIUS_TO_DELTA_FAHRENHEIT: {
        return value * 1.8;
      }
      case UnitConversionOptions.DELTA_FAHRENHEIT_TO_DELTA_CELSIUS: {
        return value / 1.8;
      }
      case UnitConversionOptions.DEGREES_TO_RADIANS: {
        return value * (Math.PI / 180);
      }
      case UnitConversionOptions.RADIANS_TO_DEGREES: {
        return value * (180 / Math.PI);
      }
      case UnitConversionOptions.MG_TO_G: {
        return value / 1000;
      }
      case UnitConversionOptions.G_TO_MG: {
        return value * 1000;
      }
      case UnitConversionOptions.GRAM_TO_LB: {
        return value / 453.5924;
      }
      case UnitConversionOptions.LB_TO_GRAM: {
        return value * 453.5924;
      }
      default: {
        return value;
      }
    }
  },

  /**
   * Inverts a unit conversion, the exact opposite of the convertUnits function. This allows a conversion to be
   * toggled on or off using the same enum.
   * Used by graphs to allow toggling of unit conversions.
   *
   * @param value {number} the value to invert
   * @param unitConversionOption {string} the enum representing the conversion to undo
   * @returns {number|*} the inverted value
   */
  invertUnits(value, unitConversionOption) {
    if (value == null || isNaN(value)) {
      return value;
    }

    switch (unitConversionOption) {
      case UnitConversionOptions.MM_TO_INCHES: {
        return module.exports.convertUnits(value, UnitConversionOptions.INCHES_TO_MM);
      }
      case UnitConversionOptions.INCHES_TO_MM: {
        return module.exports.convertUnits(value, UnitConversionOptions.MM_TO_INCHES);
      }
      case UnitConversionOptions.MICRONS_TO_MILS: {
        return module.exports.convertUnits(value, UnitConversionOptions.MILS_TO_MICRONS);
      }
      case UnitConversionOptions.MILS_TO_MICRONS: {
        return module.exports.convertUnits(value, UnitConversionOptions.MICRONS_TO_MILS);
      }
      case UnitConversionOptions.MG_TO_INCHES_SQUARED: {
        return module.exports.convertUnits(value, UnitConversionOptions.INCHES_SQUARED_TO_MG);
      }
      case UnitConversionOptions.INCHES_SQUARED_TO_MG: {
        return module.exports.convertUnits(value, UnitConversionOptions.MG_TO_INCHES_SQUARED);
      }
      case UnitConversionOptions.G_TO_INCHES_SQUARED: {
        return module.exports.convertUnits(value, UnitConversionOptions.INCHES_SQUARED_TO_G);
      }
      case UnitConversionOptions.INCHES_SQUARED_TO_G: {
        return module.exports.convertUnits(value, UnitConversionOptions.G_TO_INCHES_SQUARED);
      }
      case UnitConversionOptions.G_TO_M_SQUARED: {
        return module.exports.convertUnits(value, UnitConversionOptions.M_SQUARED_TO_G);
      }
      case UnitConversionOptions.M_SQUARED_TO_G: {
        return module.exports.convertUnits(value, UnitConversionOptions.G_TO_M_SQUARED);
      }
      case UnitConversionOptions.HZ_TO_CPM: {
        return module.exports.convertUnits(value, UnitConversionOptions.CPM_TO_HZ);
      }
      case UnitConversionOptions.CPM_TO_HZ: {
        return module.exports.convertUnits(value, UnitConversionOptions.HZ_TO_CPM);
      }
      case UnitConversionOptions.CELSIUS_TO_FAHRENHEIT: {
        return module.exports.convertUnits(value, UnitConversionOptions.FAHRENHEIT_TO_CELSIUS);
      }
      case UnitConversionOptions.FAHRENHEIT_TO_CELSIUS: {
        return module.exports.convertUnits(value, UnitConversionOptions.CELSIUS_TO_FAHRENHEIT);
      }
      case UnitConversionOptions.DELTA_CELSIUS_TO_DELTA_FAHRENHEIT: {
        return module.exports.convertUnits(value, UnitConversionOptions.DELTA_FAHRENHEIT_TO_DELTA_CELSIUS);
      }
      case UnitConversionOptions.DELTA_FAHRENHEIT_TO_DELTA_CELSIUS: {
        return module.exports.convertUnits(value, UnitConversionOptions.DELTA_CELSIUS_TO_DELTA_FAHRENHEIT);
      }
      case UnitConversionOptions.DEGREES_TO_RADIANS: {
        return module.exports.convertUnits(value, UnitConversionOptions.RADIANS_TO_DEGREES);
      }
      case UnitConversionOptions.RADIANS_TO_DEGREES: {
        return module.exports.convertUnits(value, UnitConversionOptions.DEGREES_TO_RADIANS);
      }
      case UnitConversionOptions.MG_TO_G: {
        return module.exports.convertUnits(value, UnitConversionOptions.G_TO_MG);
      }
      case UnitConversionOptions.G_TO_MG: {
        return module.exports.convertUnits(value, UnitConversionOptions.MG_TO_G);
      }
      case UnitConversionOptions.GRAM_TO_LB: {
        return module.exports.convertUnits(value, UnitConversionOptions.LB_TO_GRAM);
      }
      case UnitConversionOptions.LB_TO_GRAM: {
        return module.exports.convertUnits(value, UnitConversionOptions.GRAM_TO_LB);
      }
      default: {
        return value;
      }
    }
  },

  /**
   * Calculates an acceleration FFT from a raw array of accelerometer values.
   * Note: the input is expected in mg (as the sensor sends), but the output is in g (as typically displayed).
   * Output frequency is in Hz.
   * Sensible defaults are used for most parameters, so only the data and sampling frequency are required.
   *
   * @param data {array} array of raw acceleration values in mg
   * @param samplingFrequency {number} the sampling frequency used to collect the data
   * @param windowingOption {string} the enum option for what windowing to perform
   * @param count {number} the expected number of samples in the input array
   * @param fMin {number} the high pass filter cutoff (fMin)
   * @param nyquistOption {string} the enum option for what nyquist factor to use
   * @param customFmax {number} optional fMax cutoff
   * @param hannCorrection {boolean} whether or not to correct for hann window (if hann window was used)
   * @returns {array} an acceleration array of shape { frequency, value }
   */
  calculateFFT(
    data,
    samplingFrequency,
    windowingOption = DEFAULT_WINDOW,
    count = DEFAULT_COUNT,
    fMin = DEFAULT_F_MIN,
    nyquistOption = DEFAULT_NYQUIST,
    customFmax,
    hannCorrection = false,
  ) {
    // Trim or expand in the input data length to always be a power of 2
    if (data.length > count) {
      data = data.slice(0, count);
    } else if (data.length < count) {
      let padding = [];
      for (let i = data.length; i < count; i++) {
        padding.push(0);
      }
      data = data.concat(padding);
    }

    // Convert data from mg to g
    let mgData = data.map((mg) => mg / 1000);

    // Perform windowing
    // Square window
    if (
      windowingOption === WindowingOptions.SQUARE_WINDOW ||
      windowingOption === WindowingOptions.SQUARE_AND_HANN_WINDOW
    ) {
      let squareLength = 8192;
      let squareData = [].concat(mgData);
      for (let i = mgData.length; i < squareLength; i++) {
        squareData[i] = 0;
      }
      mgData = squareData;
    }
    // Hann window
    if (
      windowingOption === WindowingOptions.HANN_WINDOW ||
      windowingOption === WindowingOptions.SQUARE_AND_HANN_WINDOW
    ) {
      mgData = FFTWindowing.hann(mgData);
    }
    // Kaiser window
    if (windowingOption === WindowingOptions.KAISER_WINDOW) {
      mgData = FFTWindowing.kaiser(mgData);
    }

    // Compute FFT
    let fft = new dsp.FFT(mgData.length, samplingFrequency);
    fft.forward(mgData);

    // Create acceleration data with frequencies for each FFT point
    let accelerationData = [];

    for (let idx = 0; idx < fft.spectrum.length; idx++) {
      let element = fft.spectrum[idx];
      let frequency = idx * (samplingFrequency / mgData.length);

      // Skip first point (DC offset) and first 10Hz
      if (idx === 0 || frequency <= fMin) {
        continue;
      }

      if (customFmax && frequency >= customFmax) {
        break;
      }

      // if using Nyquist 2.56 instead of 2 we must stop once we reach the fmax
      if (nyquistOption === NyquistOptions.NYQUIST_256 && frequency >= samplingFrequency / 2.56) {
        break;
      }

      accelerationData.push({
        value: element,
        frequency: frequency,
      });
    }

    // Optionally correct for hann window
    if (
      hannCorrection &&
      [WindowingOptions.HANN_WINDOW, WindowingOptions.SQUARE_AND_HANN_WINDOW].includes(windowingOption)
    ) {
      return module.exports.applyEnergyHannCorrection(accelerationData);
    }

    return accelerationData;
  },

  /**
   * Converts an acceleration FFT into another domain - either velocity or displacement.
   * The input FFT is expected to be in G (as is produced by calculateFFT).
   * The output FFT in the velocity domain is in mm/s.
   * The output FFT in the displacement domain is in microns.
   * Input and output frequency is in Hz.
   *
   * @param fft {array} the acceleration FFT, for example the output of calculateFFT above
   * @param fftConversionOption {string} the enum option of which domain to convert to
   * @returns {array} the converted FFT in the same shape as was inputted
   */
  convertFFT(fft, fftConversionOption) {
    return fft.map((point) => module.exports.convertFFTPoint(point, fftConversionOption));
  },

  /**
   * Helper function which converts a single FFT bin from one domain to another. This is the actual implementation used
   * by convert FFT above. This is a combination of simple integration and converting to account for desired units.
   * See the convertFFT documentation above for expected units.
   *
   * @param bin {object} the FFT bin to be converted, of shape { frequency, value }
   * @param fftConversionOption {string} the enum option of which domain to convert to
   * @returns {object} the converted bin in the same shape as was inputted
   */
  convertFFTPoint(bin, fftConversionOption) {
    const {value, frequency} = bin;
    switch (fftConversionOption) {
      // Integrate once for velocity
      case DomainConversionOptions.VELOCITY_DOMAIN: {
        return {
          value: (1000 * enumerations.GRAVITY * value) / (2 * Math.PI * frequency),
          frequency,
        };
      }

      // Integrate twice for displacement
      case DomainConversionOptions.DISPLACEMENT_DOMAIN: {
        return {
          value: (1000000 * enumerations.GRAVITY * value) / Math.pow(2 * Math.PI * frequency, 2),
          frequency,
        };
      }
      default: {
        return {value, frequency};
      }
    }
  },

  /**
   * Calculates the mean value of an array of data.
   *
   * @param data {array} the data to calculate the mean of
   * @param selectValue {function} an optional function to extract the value to be averaged from each array element
   * @returns {number} the mean
   */
  calculateMean(data, selectValue = (x) => x) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return 0;
    }

    let total = 0;
    data.forEach((element) => {
      total += selectValue(element);
    });

    return total / data.length;
  },

  /**
   * Calculates the trimmed mean value of an array of data
   * @param data {array} the data to calculate the mean of
   * @param trimPercentage {number} the percentage value to trim dataset
   * @returns {number} the trimmed mean
   */
  calculateTrimmedMean(data = [], trimPercentage = 0) {
    if (!Array.isArray(data) || data.length === 0 || trimPercentage < 0) return 0;
    const trimCount = Math.floor((trimPercentage / 100) * data.length);
    const sortedData = data.slice().sort();
    let sum = 0;
    for (let i = trimCount; i < sortedData.length - trimCount; i++) {
      sum += sortedData[i];
    }

    return sum / (sortedData.length - 2 * trimCount);
  },

  /**
   * Calculates the median value of an array of data.
   *
   * @param data {array} the data to calculate the median of
   * @param selectValue {function} an optional function to extract the value to be averaged from each array element
   * @returns {number} the median value
   */
  calculateMedian(data, sort = (a, b) => a - b) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return 0;
    }

    let sorted = data.sort(sort);
    return sorted[Math.round(sorted.length / 2)];
  },

  /**
   * Calculates the variance of a data set.
   *
   * @param data {array} the input data set
   * @param population {boolean} whether to treat the set as a population or not
   * @param selectValue {function} optional function to extract the value to be used from each array element
   * @returns {number} the variance
   */
  calculateVariance(data, population = false, selectValue = (x) => x) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return 0;
    }
    let mean = module.exports.calculateMean(data, selectValue);
    let total = 0;
    data.forEach((element) => {
      total += Math.pow(selectValue(element) - mean, 2);
    });
    return total / (population ? data.length : data.length - 1);
  },

  /**
   * Calculates the standard deviation of a data set.
   *
   * @param data {array} the input data set
   * @param population {boolean} whether to treat the set as a population or not
   * @param selectValue {function} optional function to extract the value to be used from each array element
   * @returns {number} the standard deviation
   */
  calculateStandardDeviation(data, population = false, selectValue = (x) => x) {
    return Math.sqrt(module.exports.calculateVariance(data, population, selectValue));
  },

  /**
   * Calculates the true RMS (root mean square) of an array of data.
   * This can be used to calculate an RMS of a time waveform.
   *
   * @param data {array} in the input data
   * @param selectValue {function} an optional function to extract the value to be used from each array element
   * @returns {number} the RMS
   */
  calculateTimeDomainRMS(data, selectValue = (x) => x) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return 0;
    }

    let totalSquared = 0;
    data.forEach((element) => {
      let value = selectValue(element);
      totalSquared += value * value;
    });

    return Math.sqrt(totalSquared / data.length);
  },

  /**
   * Calculates the RSS (root sum square) of an array of data.
   * In the vibration world, this is still usually referred to as an "RMS" or "OA" value. In reality, an "RMS" value is
   * only a true RMS when working in the time domain, but when working in the frequency domain such as a FFT it means
   * a RSS instead.
   * This can be used to calculate an "RMS" (RSS), or "OA" of a spectrum.
   *
   * @param data {array} in the input data
   * @param selectValue {function} an optional function to extract the value to be used from each array element
   * @returns {number} the RSS
   */
  calculateRMS(data, selectValue = (x) => x) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return 0;
    }

    // Use half of first value
    let totalSquared = 0;
    let firstValue = selectValue(data[0]);
    totalSquared = (firstValue * firstValue) / 2;

    for (let i = 1; i < data.length - 1; i++) {
      let value = selectValue(data[i]);
      totalSquared += value * value;
    }

    // And half of second value
    let lastValue = selectValue(data[data.length - 1]);
    totalSquared += (lastValue * lastValue) / 2;

    return Math.sqrt(totalSquared);
  },

  /**
   * Calculates the crest factor of a time waveform.
   *
   * @param data {array} the input array
   * @param rms {number} the rms of the array
   * @param selectValue {function} optional function to extract values from each array element
   * @returns {number} the crest factor
   */
  calculateCrestFactor(data, rms, selectValue = (x) => x) {
    if (!data || !Array.isArray(data) || data.length === 0 || rms === 0) {
      return 0;
    }

    let peakAmplitude = Number.MIN_SAFE_INTEGER;
    data.forEach((element) => {
      const abs = Math.abs(selectValue(element));
      if (abs > peakAmplitude) {
        peakAmplitude = abs;
      }
    });

    return peakAmplitude / rms;
  },

  /**
   * Calculates the peak-peak value of a time waveform. This is the difference between the largest and smallest values.
   *
   * @param data {array} the input data
   * @param selectValue {function} optional function to extract values from each array element
   * @returns {number} the peak-peak value
   */
  calculatePeakToPeak(data, selectValue = (x) => x) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return 0;
    }

    let max = Number.MIN_SAFE_INTEGER,
      min = Number.MAX_SAFE_INTEGER;
    for (let element of data) {
      const value = selectValue(element);
      max = Math.max(max, value);
      min = Math.min(min, value);
    }
    return max - min;
  },

  /**
   * Remove zeroes and gravity component from acceleration data using the mean so that it can be used
   * in further time domain calculations
   *
   * @param data {number[]} the input raw acceleration values
   * @returns {number[]} the altered acceleration data array
   */
  prepareAccelerationData(data) {
    let sum = 0;
    let nonZeroCount = 0;

    // Calculate the sum of non-zero values and count them
    for (const value of data) {
      if (value !== 0) {
        sum += value;
        nonZeroCount++;
      }
    }

    if (nonZeroCount === 0) return [];
    const mean = sum / nonZeroCount;

    // Subtract the mean from each value and filter out zeros
    const result = [];
    for (const value of data) {
      const adjustedValue = value - mean;
      if (adjustedValue !== 0) {
        result.push(adjustedValue);
      }
    }

    return result;
  },

  /**
   * Creates a time domain structure from a raw array of acceleration values and the frequency at which they were
   * sampled.
   * The generated time domain array has elements with timestamps in milliseconds.
   * This also subtracts the DC offset from the waveform, removing skews do to gravity. The resulting waveform should
   * have a mean of approximately 0.
   * The input is expected to be in mg (as sent by the sensors), and is outputted as g (as typically displayed).
   *
   * @param data {array} the input raw acceleration values
   * @param samplingFrequency {number} the sampling frequency used to collect the data in Hz
   * @param applyMean {boolean} whether to apply the overall mean value to each value which removes the DC offset
   * @returns {array} the time domain data array which each element of shape { value, timestamp }
   */
  calculateTimeDomainData(data, samplingFrequency, applyMean = true) {
    if (!data || !samplingFrequency) {
      return [];
    }
    const mean = applyMean ? module.exports.calculateMean(data) : 0;
    return data.map((value, idx) => {
      return {
        value: (value === 0 ? value : value - mean) / 1000,
        timestamp: idx * (1000 / samplingFrequency),
      };
    });
  },

  /**
   * Converts a time domain data structure from acceleration into either velocity or displacement.
   * The input units should be in g (as output by calculateTimeDomainData), and the outputs are mm/s and microns for
   * velocity and displacement respectively.
   * The DC offset is always subtracted, and de-trending is performed by subtracting a rolling average.
   *
   * @param data {array} the input time domain structure array, such as the output of calculateTimeDomainData
   * @param domainConversionOption {string} the enum option representing which domain to convert to
   * @param detrendingFunc {Function} the function to use to de-trend the data. Providing null applies no de-trending
   * @param detrendingArgs {Array} any additional parameters that the de-trending function may need
   * @returns {array} the converted time domain structure in the exact same shape
   */
  convertTimeDomainData(
    data,
    domainConversionOption = DomainConversionOptions.VELOCITY_DOMAIN,
    detrendingFunc = module.exports.rollingAverageDetrend,
    detrendingArgs = [50],
  ) {
    if (!data) {
      return [];
    }
    const integrate = (data, conversionFn = (x) => x) => {
      let integratedData = [
        {
          value: 0,
          timestamp: 0,
        },
      ];
      for (let i = 1; i < data.length; i++) {
        let x1 = conversionFn(data[i - 1].value);
        let x2 = conversionFn(data[i].value);
        let offset = ((x2 + x1) / 2) * data[1].timestamp;
        let value = integratedData[i - 1].value + offset;

        integratedData.push({
          value: value,
          timestamp: data[i].timestamp,
        });
      }

      // Remove DC offset
      const mean = module.exports.calculateMean(integratedData, (x) => x.value);
      for (let i = 0; i < integratedData.length; i++) {
        integratedData[i].value -= mean;
      }

      if (detrendingFunc == null) {
        return integratedData;
      }

      return detrendingFunc(integratedData, ...detrendingArgs);
    };

    // Integrate once for velocity
    const velocityData = integrate(data, (value) =>
      module.exports.convertUnits(value, UnitConversionOptions.G_TO_M_SQUARED),
    );
    if (domainConversionOption === DomainConversionOptions.VELOCITY_DOMAIN) {
      return velocityData;
    }

    // Integrate again for displacement
    return integrate(velocityData);
  },

  /**
   * Helper function which calls through to calculatePowerBandsFromFFT to calculate power bands. The input for this
   * function is expected to be a raw acceleration sample.
   *
   * @param data {array} the raw input acceleration sample
   * @param rpm {number} the rpm of the machine
   * @param samplingFreq {number} the frequency the data was sampled at
   * @param notchFilter {array} any configured notch filters (not currently used)
   * @param windowOption {string} the enum option representing any windowing to be performed when calculating the FFT
   * @param nyquistOption {string} the enum option representing the nyquist factor to uie when calculating the FFT
   * @param fullSet {boolean} whether every band should be returned in the return object even if they didn't have any energy
   * @returns {object} an object with keys of power bands names and values of the power total in each band
   */
  calculatePowerBands(
    data,
    rpm,
    samplingFreq,
    notchFilter,
    windowOption = DEFAULT_WINDOW,
    nyquistOption = DEFAULT_NYQUIST,
    fullSet = true,
  ) {
    let fftLength = data != null ? utils.getPreviousPowerOf2(data.length) : DEFAULT_COUNT;
    let fft = module.exports.calculateFFT(data, samplingFreq, windowOption, fftLength, DEFAULT_F_MIN, nyquistOption);
    let velocityFFT = module.exports.convertFFT(fft, DomainConversionOptions.VELOCITY_DOMAIN);
    return module.exports.calculatePowerBandsFromFFT(velocityFFT, rpm, notchFilter, fullSet);
  },

  /**
   * Calculates power band values from a velocity FFT.
   *
   * @param fft {array} the input velocity FFT, such as outputted by convertFFT
   * @param rpm {number} the running speed of the machine
   * @param notchFilter {array} any configured notch filters (not currently used)
   * @param fullSet {boolean} whether every band should be returned in the return object even if they didn't have any energy
   * @returns {object} an object with keys of power bands names and values of the power total in each band
   */
  calculatePowerBandsFromFFT(fft, rpm, notchFilter, fullSet = true) {
    let maxFreq = fft[fft.length - 1].frequency;
    let bandPowers = {};

    const powerBandFreqRanges = getPowerBandFreqRanges(rpm, maxFreq);
    const frequencyRangesLength = powerBandFreqRanges.length;
    const fftEntriesLength = fft.length;

    let fftFrequencyLastIndex = 0;
    for (let i = 0; i < frequencyRangesLength; i++) {
      const {start, end, bandName} = powerBandFreqRanges[i];

      for (let x = fftFrequencyLastIndex; x < fftEntriesLength; x++) {
        // Keep track of the last frequency index that was processed to avoid
        // extra iterations
        fftFrequencyLastIndex = x;
        const {frequency, value: velocity} = fft[x];

        if (checkIsInFilter(frequency, notchFilter)) continue;
        // Frequency is outside this bands frequency range.
        // Stop iterating through frequencies for this power band and move to the next.
        if (frequency < start || frequency > end) break;

        // Init the band if it hasn't been used before
        if (bandPowers[bandName] == null) {
          bandPowers[bandName] = 0;
        }
        bandPowers[bandName] += velocity * velocity;
      }
    }

    const powerBands = getPowerBandsInOrder();
    for (let band of powerBands) {
      let bandName = band.name;
      if (bandPowers[bandName] != null) {
        bandPowers[bandName] = Math.sqrt(bandPowers[bandName]) / Math.sqrt(1.5);
      } else if (fullSet) {
        bandPowers[bandName] = 0;
      }
    }

    return bandPowers;
  },

  /**
   * Calculates all ellipse data including coordinates for a stroke plot. The input to this function is what is sent by
   * the sensor - top peaks in each axis and phase data. A JS implementation for calculation phase data is provided in
   * calculatePhaseData.
   * The X, Y and Z max peaks are expected to be from a velocity FFT, with amplitude in mm/s and frequency in Hz.
   * The X and Y max peaks are only used to determine frequency / running speed.
   * The Z peaks are used to calculate deflection/Z Velocity (which is simply the amplitude of the largest Z peak).
   * The core part of this is the generation of ellipse coordinates, which depends on the formula for calculating the
   * phase angle. The magic formula for this was invented by Idir.
   *
   * @param xPeaks {array} the top velocity peaks in the X axis of shape { frequency, amplitude }
   * @param yPeaks {array} the top velocity peaks in the Y axis of shape { frequency, amplitude }
   * @param zPeaks {array} the top velocity peaks in the Z axis of shape { frequency, amplitude }
   * @param phaseData {object} a phaseData object, better described below in calculatePhaseData
   * @param adjustForRotation {boolean} whether or not to correct values for the rotation of the sensor
   * @param axisStrategy {string} enum option representing whether X is up or Y is up, as different versions of the sensor have different accelerometer orientations
   * @param domain {string} enum option representing whether the output should be in the acceleration domain (main G etx) or displacement domain (stroke length etc)
   * @param flip {boolean} whether or not to horizontally flip stroke plots, as desired by certain customers
   * @returns {object} the ellipse data
   */
  calculateEllipseData(
    xPeaks,
    yPeaks,
    zPeaks,
    phaseData,
    adjustForRotation = true,
    axisStrategy = enumerations.AxisStrategies.X_UP,
    domain = VibrationDomains.DISPLACEMENT,
    flip = false,
  ) {
    // Invalid if no peaks
    if (!xPeaks || !xPeaks.length || !yPeaks || !yPeaks.length || !zPeaks || !zPeaks.length) {
      return null;
    }

    // Find max peaks
    let maxXPeak = null,
      maxYPeak = null,
      maxZPeak = null;
    for (let i = 0; i < xPeaks.length; i++) {
      if (xPeaks[i] && (!maxXPeak || xPeaks[i].amplitude > maxXPeak.amplitude)) {
        maxXPeak = xPeaks[i];
      }
      if (yPeaks[i] && (!maxYPeak || yPeaks[i].amplitude > maxYPeak.amplitude)) {
        maxYPeak = yPeaks[i];
      }
      if (zPeaks[i] && (!maxZPeak || zPeaks[i].amplitude > maxZPeak.amplitude)) {
        maxZPeak = zPeaks[i];
      }
    }

    // Invalid if frequency is 0
    if (!maxXPeak.frequency || !maxYPeak.frequency || !maxZPeak.frequency) {
      return null;
    }

    // Calculate rotation
    if (!phaseData || !phaseData.x_samples || !phaseData.y_samples) {
      return null;
    }
    let minX = phaseData.x_samples.min;
    let avgX = phaseData.x_samples.avg;
    let maxX = phaseData.x_samples.max;
    let minY = phaseData.y_samples.min;
    let avgY = phaseData.y_samples.avg;
    let maxY = phaseData.y_samples.max;
    let rotation =
      axisStrategy === enumerations.AxisStrategies.Y_UP
        ? module.exports.convertUnits(Math.atan2(avgX, avgY), UnitConversionOptions.RADIANS_TO_DEGREES)
        : module.exports.convertUnits(Math.atan2(avgY, avgX), UnitConversionOptions.RADIANS_TO_DEGREES);

    // Calculate peak values to plot coordinates with
    const frequency = Math.min(maxXPeak.frequency, maxYPeak.frequency);
    let deltaX = maxX - minX;
    let deltaY = maxY - minY;
    // Calculate deflection ( Z velocity)
    let deflection = maxZPeak.amplitude;
    let deltaZ = deflection;
    if (domain === VibrationDomains.ACCELERATION) {
      // Divide by additional 2 to transform to pk value
      deltaX /= 2000;
      deltaY /= 2000;
      deltaZ = utils.velocityToAcceleration(deflection, maxZPeak.frequency);
    } else if (domain === VibrationDomains.VELOCITY) {
      // Divide by additional 2 to transform to pk value
      deltaX = (enumerations.GRAVITY * (maxX - minX)) / (2 * Math.PI * frequency) / 2;
      deltaY = (enumerations.GRAVITY * (maxY - minY)) / (2 * Math.PI * frequency) / 2;
      // deltaZ already at correct value (deflection)
    } else if (domain === VibrationDomains.DISPLACEMENT) {
      // Don't divide by additional 2 as this is pk-pk
      deltaX = (enumerations.GRAVITY * (maxX - minX)) / (4 * Math.PI * Math.PI * frequency * frequency);
      deltaY = (enumerations.GRAVITY * (maxY - minY)) / (4 * Math.PI * Math.PI * frequency * frequency);
      deltaZ = utils.velocityToDisplacement(deflection, maxZPeak.frequency);
    }

    // Phase angle
    // This is the magic part. Please never change it.
    const phase = phaseData.phase;
    const a = phase - avgY;
    const b = (maxY - minY) / 2;
    let c = a / b;
    if (c > 1 || c < -1) {
      c = b / a;
    }
    const phaseAngle = module.exports.convertUnits(Math.asin(c), UnitConversionOptions.RADIANS_TO_DEGREES) + 180;

    // Invalid if phase angle isnt a number
    if (isNaN(phaseAngle)) {
      return null;
    }

    // Calculate coordinates
    let coordinates = [];
    const interval = 1 / (frequency * 100);
    const limit = 1 / frequency;
    let maxRadX = 0;
    let maxRadY = 0;
    let maxRadSquared = 0;
    let maxXCoordinate = 0;
    let maxYCoordinate = 0;
    for (let t = 0; t <= limit * 1.2; t += interval) {
      let x = (deltaX / 2) * Math.sin(2 * Math.PI * frequency * t);
      let y =
        (deltaY / 2) *
        Math.sin(
          2 * Math.PI * frequency * t +
            module.exports.convertUnits(90 - phaseAngle, UnitConversionOptions.DEGREES_TO_RADIANS),
        );

      // Adjust for strategy
      if (axisStrategy === enumerations.AxisStrategies.X_UP) {
        const existingAngle = Math.atan2(x, y);
        const radius = Math.sqrt(x * x + y * y);
        const newAngle = existingAngle + (90 / 180) * Math.PI;
        x = radius * Math.sin(newAngle);
        y = radius * Math.cos(newAngle);
      }

      // Transform points if required
      if (adjustForRotation) {
        const existingAngle = Math.atan2(x, y);
        const radius = Math.sqrt(x * x + y * y);
        const correction =
          axisStrategy === enumerations.AxisStrategies.Y_UP ? (rotation / 180) * Math.PI : (rotation / -180) * Math.PI;
        const newAngle = existingAngle + correction;
        x = radius * Math.sin(newAngle);
        y = radius * Math.cos(newAngle);
      }

      // Flip if required
      if (flip) {
        x *= -1;
      }

      coordinates.push({x, y});
      let radSquared = x * x + y * y;
      if (radSquared > maxRadSquared) {
        maxRadSquared = radSquared;
        maxRadX = x;
        maxRadY = y;
      }
      if (x > maxXCoordinate) {
        maxXCoordinate = x;
      }
      if (y > maxYCoordinate) {
        maxYCoordinate = y;
      }
    }

    // Calculate stroke angle and length
    let strokeAngle = module.exports.convertUnits(
      Math.atan2(maxRadY, maxRadX),
      UnitConversionOptions.RADIANS_TO_DEGREES,
    );
    if (strokeAngle <= -90) {
      strokeAngle += 180;
    } else if (strokeAngle > 90) {
      strokeAngle -= 180;
    }
    const strokeLength = Math.sqrt(maxRadSquared) * 2;

    // Valid ellipse data
    return {
      deltaX: maxXCoordinate * 2,
      deltaY: maxYCoordinate * 2,
      deltaZ: deltaZ,
      rotation,
      frequency,
      phaseAngle,
      // For the velocity domain, this will be the same as deltaZ, refactor in future could remove this duplication
      deflection,
      coordinates,
      strokeAngle,
      strokeLength,
    };
  },

  /**
   * Finds the ratio as a decimal of an ellipse using A / B, calculating how circular the ellipse becomes
   * A ratio of 1 is fully circular
   *
   * @param {array} coordinates the {x, y} plot points of the ellipse
   * @param {number} strokeLength the ellipse stroke length
   * @param {number} strokeAngle the ellipse stroke angle
   * @returns {number} the ellipse stroke ratio
   */
  calculateEllipseStrokeRatio(coordinates = [], strokeLength = 0, strokeAngle = 0) {
    const strokeLengthRadiusInches = module.exports.convertUnits(strokeLength, UnitConversionOptions.MM_TO_INCHES) / 2;

    // (Stroke Angle needs to be in Radians to solve)
    // As per initial requirements, provided calculation is based on
    // stroke angle as provided in Analytix, rounded to 1 decimal place
    const strokeAngleRads = module.exports.convertUnits(
      strokeAngle.toFixed(1),
      UnitConversionOptions.DEGREES_TO_RADIANS,
    );
    const strokeAngleCosine = Math.cos(strokeAngleRads);
    const strokeAngleSine = Math.sin(strokeAngleRads);

    let strokeWidthRadiusSum = 0;
    let strokeWidthRadiusCount = 0;
    for (let i = 0; i < coordinates.length; i++) {
      const x = module.exports.convertUnits(coordinates[i].x, UnitConversionOptions.MM_TO_INCHES);
      const y = module.exports.convertUnits(coordinates[i].y, UnitConversionOptions.MM_TO_INCHES);

      // Calculate Ellipse Stroke Ratio
      const strokeWidthRadius = Math.sqrt(
        Math.abs(
          (1 / (1 - Math.pow(x * strokeAngleCosine + y * strokeAngleSine, 2) / Math.pow(strokeLengthRadiusInches, 2))) *
            Math.pow(x * strokeAngleSine - y * strokeAngleCosine, 2),
        ),
      );

      // Edge case handler for divide by zero that removes NaN entries
      if (!Number.isNaN(strokeWidthRadius)) {
        strokeWidthRadiusSum += strokeWidthRadius;
        strokeWidthRadiusCount++;
      }
    }

    const trueAverage = strokeWidthRadiusSum / strokeWidthRadiusCount;
    const strokeRatioTrueAverage = strokeLengthRadiusInches / trueAverage;

    return strokeRatioTrueAverage;
  },

  /**
   * Syntron Phase 2
   * Takes liner & trough weight & returns calculated liner & trough weight, liner weight lost & % liner wear
   *
   * @param {number} originalTroughWeight original trough weight from machine config
   * @param {number} originalLinerWeight original liner weight from machine config
   * @param {number} originalExciterWeight original exciter weight from machine config
   * @param {number} originalExciterStrokeLength original exciter stroke length from machine config
   * @param {number} newTroughStrokeLength stroke length calculated from telemetry
   * @param {number} newExciterStrokeLength stroke length calculated from telemetry
   * @param {number} correctionFactor manual correction factor from machine config
   *
   */
  calculateLinerWearData({
    originalTroughWeight = 0,
    originalLinerWeight = 0,
    originalExciterWeight = 0,
    originalExciterStrokeLength = 0,
    newTroughStrokeLength = 0,
    newExciterStrokeLength = 0,
    correctionFactor = 0,
  }) {
    if (
      !originalTroughWeight ||
      !originalLinerWeight ||
      !originalExciterWeight ||
      !originalExciterStrokeLength ||
      !newTroughStrokeLength ||
      !newExciterStrokeLength
    )
      return null;

    const percentExciterStrokeChange =
      (newExciterStrokeLength - originalExciterStrokeLength) / originalExciterStrokeLength;
    if (isNaN(percentExciterStrokeChange)) return null;

    const _correctionFactor = correctionFactor
      ? correctionFactor
      : 4.73974 * Math.pow(percentExciterStrokeChange, 2) + 2.747 * percentExciterStrokeChange + 1.64836;

    const newTroughWeight =
      (newTroughStrokeLength / newExciterStrokeLength) * _correctionFactor * originalExciterWeight;
    if (isNaN(newTroughWeight)) return null;
    const newLinerWeight = originalLinerWeight - (originalTroughWeight - newTroughWeight);

    const linerWeightLost = originalLinerWeight - newLinerWeight;

    const percentLinerWear = ((newLinerWeight - originalLinerWeight) / originalLinerWeight) * 100;
    if (isNaN(percentLinerWear)) return null;

    return {newTroughWeight, newLinerWeight, linerWeightLost, percentLinerWear};
  },

  /**
   *  Finds the largest peak in a FFT. This can be used to help generate some of the inputs to calculateEllipseData.
   *
   * @param fftData {array} the input FFT
   * @returns {object} the max peak of shape { frequency, value }
   */
  findMaxPeak(fftData) {
    let max = {
      frequency: 0,
      value: Number.MIN_SAFE_INTEGER,
    };
    fftData.forEach((point) => {
      if (point.value > max.value) {
        max.value = point.value;
        max.frequency = point.frequency;
      }
    });
    return max;
  },

  /**
   * Calculates the phase data required for the calculateEllipseData function. This is normally performed on the sensor,
   * but for bluetooth sensors we require a JS implementation.
   * No one really remembers the origins of this procedure, other than it seems to work.
   * This basically breaks time waveforms into multiple small batches and finds the max, min and average of each, and
   * then averages those 3 values across all the batches to get a better "average" of the max, min and average.
   * The "phase" part of the output is a number representing the phase different between the X and Y axis, i.e. how in
   * sync or out of the sync the time waveforms are.
   *
   * @param xData {array} the input X axis time waveform
   * @param yData {array} the input Y axis time waveform
   * @returns {object} the phase data, of shape { phase, x_samples: { max, min, avg }, y_samples: { max, min, avg }}
   */
  calculatePhaseData(xData, yData) {
    // Sanity checks
    if (!xData || !yData || xData.length !== yData.length) {
      return null;
    }

    const batchSize = 256;
    const batches = xData.length / batchSize;
    let xMinTotal = 0;
    let yMinTotal = 0;
    let xAvgTotal = 0;
    let yAvgTotal = 0;
    let xMaxTotal = 0;
    let yMaxTotal = 0;
    let phaseTotal = 0;

    for (let i = 0; i < batches; i++) {
      let maxX = Number.MIN_SAFE_INTEGER;
      let maxY = Number.MIN_SAFE_INTEGER;
      let minX = Number.MAX_SAFE_INTEGER;
      let minY = Number.MAX_SAFE_INTEGER;
      let phase = 0;
      for (let idx = i * batchSize; idx < (i + 1) * batchSize; idx++) {
        const x = xData[idx];
        const y = yData[idx];
        xAvgTotal += x;
        yAvgTotal += y;
        if (x > maxX) {
          maxX = x;
          phase = y;
        } else if (x < minX) {
          minX = x;
        }
        if (y > maxY) {
          maxY = y;
        } else if (y < minY) {
          minY = y;
        }
      }
      xMinTotal += minX;
      yMinTotal += minY;
      xMaxTotal += maxX;
      yMaxTotal += maxY;
      phaseTotal += phase;
    }

    return {
      x_samples: {
        min: xMinTotal / batches,
        avg: xAvgTotal / xData.length,
        max: xMaxTotal / batches,
      },
      y_samples: {
        min: yMinTotal / batches,
        avg: yAvgTotal / yData.length,
        max: yMaxTotal / batches,
      },
      phase: phaseTotal / batches,
    };
  },

  /**
   * Calculates a rolling average over a data array. The output array is the same length as the input array, with each
   * element being a {count} width average around the current index.
   *
   * @param data {array} the input array
   * @param count {number} the width of the average to calculate
   * @returns {array} the rolling average array
   */
  rollingAverage(data, count = 5) {
    const halfWidth = Math.floor(count / 2);
    const defaultWindowSize = halfWidth * 2 + 1;
    let averagedSamples = [];
    let runningTotal = 0;
    let lastSample = 0;

    // Get initial running total
    for (let i = 0; i < halfWidth && i < data.length; i++) {
      runningTotal += data[i];
    }

    // Single pass through data
    for (let i = 0; i < data.length; i++) {
      let windowSize = defaultWindowSize;

      if (i - halfWidth < 0) {
        windowSize += i - halfWidth;
      } else {
        runningTotal -= lastSample;
        lastSample = data[i - halfWidth];
      }

      if (i + halfWidth >= data.length) {
        windowSize -= i + halfWidth - data.length + 1;
      } else {
        runningTotal += data[i + halfWidth];
      }

      averagedSamples.push(runningTotal / windowSize);
    }

    return averagedSamples;
  },

  /**
   * Subtracts the mean from a data array. Calculates the mean, then subtracts it from each element.
   * Used to remove DC offsets from time waveforms.
   *
   * @param samples {array} the input array
   * @returns {array} an array of values the same length as the input array, with the mean removed from each point
   */
  subtractMean(samples) {
    const output = samples.slice();

    // Subtract mean
    let total = 0;
    for (let i = 0; i < output.length; i++) {
      total += output[i];
    }
    const mean = total / output.length;
    for (let i = 0; i < output.length; i++) {
      output[i] -= mean;
    }

    return output;
  },

  /**
   * Process an acceleration sample for hammer tests.
   * This helper function is used when processing hammer data sets captured in the Chi app.
   * This extracts the relevant time waveform sample to use for the hammer test, calculates an FFT and extracts
   * the top peaks from the FFT.
   *
   * @param samples {array} raw acceleration sample
   * @param triggerIndex {number} the index in the sample of the hammer strike
   * @param samplingFrequency {number} the frequency at which the data was sampled
   * @param pointsToRemove {number} the number of points to remove after the hammer strike
   * @param sampleSize {number} the number of points to use as the hammer sample
   * @param fftSize {number} the number of points to square window the hammer samples up to before FFT calculation
   * @param fMin {number} the high pass filter cutoff
   * @param fMax {number} the low pass filter cutoff
   * @param snr {number} the signal to noise ratio to consider for peak detection
   * @returns {object} hammer data of shape { fft, peakScores, maxPeaks }
   */
  calculateHammerAxisData(
    samples,
    triggerIndex,
    samplingFrequency,
    pointsToRemove,
    sampleSize,
    fftSize,
    fMin,
    fMax,
    snr,
  ) {
    // Extract hammer samples
    const startIdx = triggerIndex + pointsToRemove;
    const endIdx = startIdx + sampleSize;
    let hammerSamples = samples.slice(startIdx, endIdx);

    // Square window
    let squareWindowSamples = new Array(fftSize).fill(0);
    for (let i = 0; i < hammerSamples.length; i++) {
      squareWindowSamples[i] = hammerSamples[i];
    }
    hammerSamples = squareWindowSamples;

    // Process FFT
    let fft = module.exports.calculateFFT(hammerSamples, samplingFrequency, WindowingOptions.KAISER_WINDOW, fftSize, 5);
    fft = fft.filter((x) => x.frequency >= fMin && x.frequency < fMax);

    // Find peaks in fft
    const findPeaks = d3peaks
      .findPeaks()
      .kernel(d3peaks.ricker)
      .gapThreshold(1)
      .minLineLength(2)
      .minSNR(snr)
      .widths([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const maxPeaks = findPeaks(fft.map((x) => x.value))
      .map((x) => ({...fft[x.index], bin: x.index}))
      .sort((a, b) => {
        if (a.value === b.value) {
          return 0;
        }
        return a.value > b.value ? -1 : 1;
      })
      .slice(0, 5);

    // Create peak score map
    let peakScores = {};
    let peakIndex = 0;
    maxPeaks.forEach((peak) => {
      if (peakScores[peak.bin] == null) {
        peakScores[peak.bin] = 5 - peakIndex++;
        peakScores[peak.bin] *= peakScores[peak.bin];
      }
    });

    return {
      fft,
      maxPeaks,
      peakScores,
    };
  },

  /**
   * This is an algorithm to estimate the natural frequency of a screen using tri-axial data.
   * X, Y and Z acceleration samples are processed using calculateHammerAxisData above, then the max peaks are scored
   * and processed to estimate what the running speed is.
   * This returns the estimated running speed peak, a confidence factor for how confident the algorithm is that
   * this is the correct running speed, tri-axial FFT data and tri-axial time domain data.
   * Both the FFT data and time domain data outputs have amplitudes in mg.
   *
   * @param xSamples {array} the raw X accelerometer sample in mg
   * @param ySamples {array} the raw Y accelerometer sample in mg
   * @param zSamples {array} the raw Z accelerometer sample in mg
   * @param trigger {number} the trigger threshold in mg for the hammer strike
   * @param samplingFrequency {number} the frequency at which the samples were captured in Hz
   * @param pointsToRemove {number} the amount of points to remove after the hammer strike
   * @param sampleSize {number} the amount of samples to use after the hammer strike
   * @param fftSize {number} the amount of samples to square window up to before computing the FFT
   * @param fMin {number} the high pass filter cutoff in Hz
   * @param fMax {number} the low pass filter cutoff in Hz
   * @param snr {number} the signal to noise ratio to use for peak detection
   * @returns {object} hammer test data of shape { maxPeak, confidence, fftData, timeDomainData }
   */
  calculateTriAxialHammerData(
    xSamples,
    ySamples,
    zSamples,
    trigger,
    samplingFrequency,
    pointsToRemove,
    sampleSize,
    fftSize,
    fMin,
    fMax,
    snr,
  ) {
    // Extract sample sets
    xSamples = module.exports.subtractMean(xSamples);
    ySamples = module.exports.subtractMean(ySamples);
    zSamples = module.exports.subtractMean(zSamples);

    // Find trigger point
    let triggered = false;
    let triggerIndex = 0;
    for (let i = 0; i < zSamples.length; i++) {
      if (zSamples[i] >= trigger || zSamples[i] <= -1 * trigger) {
        triggerIndex = i;
        triggered = true;
      }
      if (triggered) {
        break;
      }
    }
    if (!triggered) {
      return null;
    }

    // Extract FFT sets
    let xData = module.exports.calculateHammerAxisData(
      xSamples,
      triggerIndex,
      samplingFrequency,
      pointsToRemove,
      sampleSize,
      fftSize,
      fMin,
      fMax,
      snr,
    );
    let yData = module.exports.calculateHammerAxisData(
      ySamples,
      triggerIndex,
      samplingFrequency,
      pointsToRemove,
      sampleSize,
      fftSize,
      fMin,
      fMax,
      snr,
    );
    let zData = module.exports.calculateHammerAxisData(
      zSamples,
      triggerIndex,
      samplingFrequency,
      pointsToRemove,
      sampleSize,
      fftSize,
      fMin,
      fMax,
      snr,
    );

    // Generate overall bin weights
    let binWeights = {};
    for (let i = 0; i < fftSize / 2; i++) {
      let appearances = 0;
      binWeights[i] = 1;

      [xData, yData, zData].forEach((set) => {
        if (set.peakScores[i]) {
          appearances++;
        }
      });

      [xData, yData, zData].forEach((set) => {
        if (set.peakScores[i]) {
          binWeights[i] += Math.pow(set.peakScores[i], appearances);
        }
      });
    }

    // Guess confidence
    // Create hammer FFT with average
    // let total = 0;
    // let hammerFFT = [];
    // for (let i = 0; i < xData.fft.length; i++) {
    //   const weight = binWeights[i];
    //   const value = (xData.fft[i].value + yData.fft[i].value + zData.fft[i].value) * weight;
    //   total += value;
    //   hammerFFT.push({
    //     frequency: xData.fft[i].frequency,
    //     value
    //   });
    // }
    // const average = total / xData.fft.length;
    // const confidenceFactor = maxPeak.value / average;
    // const confidence = Math.min(99, Math.round(confidenceFactor / 2));

    // Peak detection
    const maxPeaks = module.exports.findMaxPeaks(zData.fft, 10, snr);

    // Return max peak frequency
    const period = 1000 / samplingFrequency;
    return {
      maxPeaks,
      // confidence,
      fftData: [
        {
          data: xData.fft.map((x) => ({
            frequency: x.frequency,
            value: x.value * 1000,
          })),
          title: "X",
        },
        {
          data: yData.fft.map((x) => ({
            frequency: x.frequency,
            value: x.value * 1000,
          })),
          title: "Y",
        },
        {
          data: zData.fft.map((x) => ({
            frequency: x.frequency,
            value: x.value * 1000,
          })),
          title: "Z",
        },
      ],
      timeDomainData: [
        {
          data: xSamples.map((x, idx) => ({
            value: x,
            time: idx * period,
          })),
          title: "X",
        },
        {
          data: ySamples.map((x, idx) => ({
            value: x,
            time: idx * period,
          })),
          title: "Y",
        },
        {
          data: zSamples.map((x, idx) => ({
            value: x,
            time: idx * period,
          })),
          title: "Z",
        },
      ],
    };
  },

  /**
   * Find the top peaks in a spectrum using a smart peak detection algorithm.
   *
   * @param fft {array} the input spectrum
   * @param peaks {number} the amount of peaks to find
   * @param snr {number} the signal to noise ratio to use for peak detection
   * @returns {array} the top peaks of shape { frequency, value }
   */
  findMaxPeaks(fft, peaks = 10, snr = 1.0) {
    const findPeaks = d3peaks
      .findPeaks()
      .kernel(d3peaks.ricker)
      .gapThreshold(2)
      .minLineLength(4)
      .minSNR(snr)
      .widths([1, 2, 3, 4, 5]);
    const maxPeaks = findPeaks(fft.map((x) => x.value))
      .map((x) => fft[x.index])
      .sort((a, b) => {
        if (a.value === b.value) {
          return 0;
        }
        return a.value > b.value ? -1 : 1;
      })
      .slice(0, peaks)
      .sort((a, b) => {
        if (a.frequency === b.frequency) {
          return 0;
        }
        return a.frequency > b.frequency ? 1 : -1;
      });

    // Filter duplicates
    let uniquePeaks = [];
    maxPeaks.forEach((peak) => {
      if (uniquePeaks.findIndex((x) => x.frequency === peak.frequency) === -1) {
        uniquePeaks.push(peak);
      }
    });
    return uniquePeaks;
  },

  /**
   * This extracts and processes accelerometer data (typically Z-axis) and finds the max peaks.
   * Both the FFT data and time domain data outputs have amplitudes in mg.
   * This is the current implementation used in the Chi app.
   *
   * @param zSamples {array} the raw Z accelerometer sample in mg
   * @param trigger {number} the trigger threshold in mg for the hammer strike
   * @param samplingFrequency {number} the frequency at which the samples were captured in Hz
   * @param pointsToRemove {number} the amount of points to remove after the hammer strike
   * @param sampleSize {number} the amount of samples to use after the hammer strike
   * @param fftSize {number} the amount of samples to square window up to before computing the FFT
   * @param fMin {number} the high pass filter cutoff in Hz
   * @param fMax {number} the low pass filter cutoff in Hz
   * @param snr {number} the signal to noise ratio to use for peak detection
   * @returns {object} hammer test data of shape { maxPeaks, fftData, timeDomainData }
   */
  calculateSimpleHammerData(
    zSamples,
    trigger,
    samplingFrequency,
    pointsToRemove,
    sampleSize,
    fftSize,
    fMin,
    fMax,
    snr,
  ) {
    // Extract sample sets
    zSamples = module.exports.subtractMean(zSamples);

    // Find trigger point
    let triggered = false;
    let triggerIndex = 0;
    for (let i = 0; i < zSamples.length; i++) {
      if (zSamples[i] >= trigger || zSamples[i] <= -1 * trigger) {
        triggerIndex = i;
        triggered = true;
      }
      if (triggered) {
        break;
      }
    }
    if (!triggered) {
      return null;
    }

    // Extract FFT sets
    let zData = module.exports.calculateHammerAxisData(
      zSamples,
      triggerIndex,
      samplingFrequency,
      pointsToRemove,
      sampleSize,
      fftSize,
      fMin,
      fMax,
      snr,
    );

    // Peak detection
    const maxPeaks = module.exports.findMaxPeaks(zData.fft, 10, snr);

    // Return max peak frequency
    const period = 1000 / samplingFrequency;
    return {
      maxPeaks,
      fftData: [
        {
          data: zData.fft.map((x) => ({
            frequency: x.frequency,
            value: x.value * 1000,
          })),
        },
      ],
      timeDomainData: [
        {
          data: zSamples.map((x, idx) => ({
            value: x / 1000,
            time: idx * period,
          })),
        },
      ],
    };
  },

  /**
   * This calculates the skewness of a time waveform.
   * This is not currently used anywhere.
   *
   * @param samples {array} the input samples
   * @param selectValue {function} an optional function to select values from array elements
   * @returns {number} the skewness
   */
  calculateSkewness(samples, selectValue = (x) => x) {
    if (samples == null || samples.length <= 0) {
      return null;
    }
    let avg = module.exports.calculateMean(samples, selectValue);
    let n = samples.length;
    let top = 0;
    let bottom = 0;
    for (let el of samples) {
      let diff = selectValue(el) - avg;
      top += Math.pow(diff, 3);
      bottom += Math.pow(diff, 2);
    }
    return top / n / Math.pow(bottom / (n - 1), 1.5);
  },

  /**
   * This calculates the kurtosis of a time waveform.
   * This is not currently used anywhere.
   *
   * @param samples {array} the input samples
   * @param selectValue {function} an optional function to select values from array elements
   * @returns {number} the kurtosis
   */
  calculateKurtosis(samples, selectValue = (x) => x) {
    if (samples == null || samples.length <= 0) {
      return null;
    }
    let avg = module.exports.calculateMean(samples, selectValue);
    let n = samples.length;
    let top = 0;
    let bottom = 0;
    // sum for top and bottom
    for (let el of samples) {
      let diff = selectValue(el) - avg;
      top += Math.pow(diff, 4);
      bottom += Math.pow(diff, 2);
    }
    return top / n / Math.pow(bottom / n, 2);
  },

  /**
   * De-trends a time waveform using polynomial regression.
   * This will calculate and de-trend for every order up to and including the {orders} parameter.
   * This is not currently used as rolling average is preferred.
   *
   * @param data {array} the input data of shape { timestamp, value }
   * @param orders {number} the number of orders to progressively de-trend
   * @returns {array} the de-trended data
   */
  polynomialDetrend(data, orders = 5) {
    let detrendedData = data.map((datum, idx) => ({x: idx, y: datum.value}));
    for (let i = 1; i <= orders; i++) {
      const model = PolynomialRegression.read(detrendedData, i);
      const terms = model.getTerms();
      detrendedData = detrendedData.map((datum, idx) => ({
        x: datum.x,
        y: datum.y - model.predictY(terms, idx),
      }));
    }
    return detrendedData.map((datum, idx) => ({
      value: datum.y,
      timestamp: data[idx].timestamp,
    }));
  },

  /**
   * De-trends a time waveform using a rolling average.
   * This calculates and de-trends by subtracting a rolling average.
   *
   * @param data {array} the input data of shape { timestamp, value }
   * @param count {number} the width of the average to calculate
   * @returns {array} the de-trended data
   */
  rollingAverageDetrend(data, count = 50) {
    const rollingAverage = module.exports.rollingAverage(
      data.map((x) => x.value),
      count,
    );
    let detrendedData = [];
    for (let i = 0; i < data.length; i++) {
      const value = data[i].value - rollingAverage[i];
      detrendedData.push({
        timestamp: data[i].timestamp,
        value,
      });
    }

    return detrendedData;
  },

  /**
   * This performs auto-correlation on a time waveform.
   * The output is roughly half the length of the input.
   *
   * @param data {array} the input time waveform
   * @param xKey {string} the name of the property representing the X axis
   * @param yKey {string} the name of the property representing the Y axis
   * @returns {array} the auto-correlated data in the same shape as entered
   */
  autoCorrelate(data, xKey, yKey) {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      total += data[i][yKey];
    }
    const mean = total / data.length;

    const autocorrelated = [];
    for (let t = 0; t < data.length / 2; t++) {
      let n = 0,
        d = 0;
      for (let i = 0; i < data.length; i++) {
        const xim = data[i][yKey] - mean;
        n += xim * (data[(i + t) % data.length][yKey] - mean);
        d += xim * xim;
      }
      autocorrelated[t] = {
        [yKey]: n / d,
        [xKey]: data[t][xKey],
      };
    }
    return autocorrelated.slice(1);
  },

  /**
   * Performs either high pass or low pass filtering on a time waveform.
   *
   * @param type {number} an enum representing which type of filter to use
   * @param signal {array} the raw acceleration signal
   * @param cutoff {number} the cutoff for the filter
   * @param samplingFrequency {number} the frequency at which the signal was sampled
   * @returns {array} the filtered signal
   */
  filterSignal(type, signal, cutoff, samplingFrequency) {
    let filteredSignal = signal.slice();
    const filter = new dsp.IIRFilter2(type, cutoff, 0, samplingFrequency);
    filter.process(filteredSignal);
    return filteredSignal;
  },

  /**
   * Performs either high pass or low pass filtering on a time waveform.
   *
   * @param type {number} an enum representing which type of filter to use
   * @param signal {array} the raw acceleration signal
   * @param cutOffLow {number} the low cutoff for the filter
   * @param cutOffHigh {number} the high cutoff for the filter
   * @param samplingFrequency {number} the frequency at which the signal was sampled
   * @returns {array} the filtered signal
   */
  filterBandSignal(signal, cutOffLow, cutOffHigh, samplingFrequency) {
    let filteredSignal = signal.slice();
    const filterLow = new dsp.IIRFilter2(FilteringOptions.LOW_PASS, cutOffLow, 0, samplingFrequency);
    filterLow.process(filteredSignal);
    const filterHigh = new dsp.IIRFilter2(FilteringOptions.HIGH_PASS, cutOffHigh, 0, samplingFrequency);
    filterHigh.process(filteredSignal);
    return filteredSignal;
  },

  /**
   * Corrects FFT losses due to Hann windowing. This corrects the energy in FFT rather than the amplitudes.
   *
   * @param data {array} the data to correct
   * @returns {array} the corrected data
   */
  applyEnergyHannCorrection(data) {
    if (!data || !Array.isArray(data)) {
      return data;
    }
    const correctionFactor = 1.63;
    return data.map((point) => ({
      ...point,
      value: point.value * correctionFactor,
    }));
  },

  /**
   * Corrects FFT losses due to Hann windowing. This corrects the amplitudes in FFT rather than the energy.
   *
   * @param data {array} the data to correct
   * @param dataIsEnergyCorrected {boolean} whether the data has already been energy corrected
   * @returns {array} the corrected data
   */
  applyAmplitudeHannCorrection(data, dataIsEnergyCorrected = false) {
    if (!data || !Array.isArray(data)) {
      return data;
    }
    const correctionFactor = dataIsEnergyCorrected ? 2 / 1.63 : 2;
    return data.map((point) => ({
      ...point,
      value: point.value * correctionFactor,
    }));
  },

  /**
   *  Finds the amplitude value at a specified frequency from an FFT data set.
   *
   * @param fft {array} the input FFT
   * @param frequency {number} the frequency in the FFT to search for
   * @returns {number} the amplitude at the specified frequency
   */
  findFrequencyValue(fft, frequency) {
    const maxPoints = fft.length;
    for (let i = 0; i < maxPoints; i++) {
      const point = fft[i];
      if (point.frequency === frequency) {
        return point.value;
      }
    }

    return null;
  },

  /**
   * Finds max number in number array.
   *
   * @param input {array} the number array
   * @returns {number} the max value from the array
   */
  max(input) {
    if (input.length < 1) return null;

    let max = input[0];
    const len = input.length;
    for (let i = 1; i < len; i++) {
      if (input[i] > max) {
        max = input[i];
      }
    }
    return max;
  },

  /**
   * Removes duplicate pairs of value that are side by side to each other in a time waveform data set
   * One of the duplicate values is removed and the values are shifted up, meaning TWF is shorter
   *
   * @param twf {array} the input TWF
   * @returns {array} the new TWF array with duplicates removed
   */
  removeTimeWaveformDuplicates(twf) {
    const correctedTWF = [];
    const twfLength = twf.length;
    for (let i = 0; i < twfLength; i++) {
      const currentValue = twf[i];
      const nextIndex = i + 1;
      const nextValue = nextIndex >= twfLength ? null : twf[nextIndex];

      if (nextValue == null) {
        correctedTWF.push(currentValue);
        continue;
      }

      if (currentValue === nextValue) {
        continue;
      }

      correctedTWF.push(currentValue);
    }

    return correctedTWF;
  },
};
