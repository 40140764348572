import React from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";

import {Form as MobxForm} from "../lib/mobx-form";
import Column from "./Column";

function Form(props) {
  const {grow, children, ...otherProps} = props;
  return (
    <MobxForm {...otherProps}>
      {({onNativeSubmit, render}) => (
        <StyledForm
          onSubmit={onNativeSubmit}
          grow={grow}
        >
          {render(children)}
        </StyledForm>
      )}
    </MobxForm>
  );
}

Form.propTypes = {
  ...MobxForm.propTypes,
  grow: PropTypes.bool,
};

export default observer(Form);

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  > *,
  ${Column} > * {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.grow &&
    css`
      flex: 1 1 auto;
    `}
`;
