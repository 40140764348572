import {createContext, useContext} from "react";
import {runInAction, reaction, observable, computed} from "mobx";
import hoistNonReactStatics from "hoist-non-react-statics";

import {IS_PRODUCTION} from "constants/enums";
import UserStore from "./UserStore";
import LocalStore from "./LocalStore";
import UIStore from "./UIStore";
import NotificationStore from "./NotificationStore";
import AlarmStore from "./AlarmStore";
import AlarmEventStore from "./AlarmEventStore";
import SiteStore from "./SiteStore";
import MachineStore from "./MachineStore";
import MachineTypeStore from "./MachineTypeStore";
import SensorStore from "./SensorStore";
import PointStore from "./PointStore";
import PointDataStore from "./PointDataStore";
import EventStore from "./EventStore";
import TimeDomainDataStore from "./TimeDomainDataStore";
import WaterfallDataStore from "./WaterfallDataStore";
import ReportStore from "./ReportStore";
import GatewayFilterListStore from "./GatewayFilterListStore";
import GatewayHardwareFilterListStore from "./GatewayHardwareFilterListStore";
import GatewayDiagnosticsStore from "./GatewayDiagnosticsStore";
import AdminSensorStore from "./AdminSensorStore";
import PreferenceStore from "./PreferenceStore";
import TimeDomainKeyStore from "./TimeDomainKeyStore";
import TimePlotDomainKeyStore from "./TimePlotDomainKeyStore";
import ThemeStore from "./ThemeStore";
import SessionStore from "./SessionStore";
import BluetoothDataStore from "./BluetoothDataStore";
import ImageStore from "./ImageStore";
import MachineDataStore from "./MachineDataStore";
import GatewayStore from "./GatewayStore";
import MultiPointDataStore from "./MultiPointDataStore";
import SinglePointMultiKeyDataStore from "./SinglePointMultiKeyDataStore";
import AnalysisGroupStore from "./AnalysisGroupStore";
import CompanyStore from "./CompanyStore";
import MultiTimeDomainDataStore from "./MultiTimeDomainDataStore";
import MultiTimeDomainKeyStore from "./MultiTimeDomainKeyStore";
import CriticalityStore from "./CriticalityStore";
import AlarmReportStore from "./AlarmReportStore";
import ApiDocsStore from "./ApiDocsStore";
import NeighbourhoodWatchStore from "./NeighbourhoodWatchStore";
import MultiCriticalityStore from "./MultiCriticalityStore";
import MultiAlarmEventStore from "./MultiAlarmEventStore";
import CDETestsStore from "./CDETestsStore";
import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import ScreenDataStore from "./ScreenDataStore";
import ApiKeyStore from "./ApiKeyStore";
import ThermalImageStore from "./ThermalImageStore";
import AdminUserStore from "./AdminUserStore";
import MachineEventStore from "./MachineEventStore";
import PointEventStore from "./PointEventStore";
import ChiDataStore from "./ChiDataStore";
import ChiDataSetStore from "./ChiDataSetStore";
import MultiChiDataSetStore from "./MultiChiDataSetStore";
import ThermalImageZonesStore from "./ThermalImageZonesStore";
import ConfigStore from "./ConfigStore";
import PartnerStore from "./PartnerStore";
import WPStore from "./WPStore";
import MachineUptimeEventStore from "./MachineUptimeEventDataStore";

export class RootStore {
  @observable lastRefreshTime = Date.now();
  // Record last time a periodic refresh happened
  @observable periodicRefreshTime = Date.now();

  // Mobx subscription disposers which need invoked when the store is destroyed
  mobxDisposers = [];

  // Stores
  localStore = new LocalStore(this);
  userStore = new UserStore(this);
  preferenceStore = new PreferenceStore(this);
  uiStore = new UIStore(this);
  alarmStore = new AlarmStore(this);
  alarmEventStore = new AlarmEventStore(this);
  machineStore = new MachineStore(this);
  machineTypeStore = new MachineTypeStore(this);
  sensorStore = new SensorStore(this);
  siteStore = new SiteStore(this);
  pointStore = new PointStore(this);
  pointDataStore = new PointDataStore(this);
  eventStore = new EventStore(this);
  timeDomainDataStore = new TimeDomainDataStore(this);
  waterfallDataStore = new WaterfallDataStore(this);
  machineUptimeEventStore = new MachineUptimeEventStore(this);
  reportStore = new ReportStore(this);
  gatewayFilterListStore = new GatewayFilterListStore(this);
  gatewayHardwareFilterListStore = new GatewayHardwareFilterListStore(this);
  gatewayDiagnosticsStore = new GatewayDiagnosticsStore(this);
  adminSensorStore = new AdminSensorStore(this);
  timeDomainKeyStore = new TimeDomainKeyStore(this);
  timePlotDomainKeyStore = new TimePlotDomainKeyStore(this);
  themeStore = new ThemeStore(this);
  sessionStore = new SessionStore(this);
  bluetoothDataStore = new BluetoothDataStore(this);
  imageStore = new ImageStore(this);
  machineDataStore = new MachineDataStore(this);
  gatewayStore = new GatewayStore(this);
  notificationStore = new NotificationStore(this);
  multiPointDataStore = new MultiPointDataStore(this);
  singlePointMultiKeyDataStore = new SinglePointMultiKeyDataStore(this);
  analysisGroupStore = new AnalysisGroupStore(this);
  companyStore = new CompanyStore(this);
  multiTimeDomainDataStore = new MultiTimeDomainDataStore(this);
  multiTimeDomainKeyStore = new MultiTimeDomainKeyStore(this);
  criticalityStore = new CriticalityStore(this);
  alarmReportStore = new AlarmReportStore(this);
  apiDocsStore = new ApiDocsStore(this);
  neighbourhoodWatchStore = new NeighbourhoodWatchStore(this);
  multiCriticalityStore = new MultiCriticalityStore(this);
  multiAlarmEventStore = new MultiAlarmEventStore(this);
  cdeTestsStore = new CDETestsStore(this);
  screenDataStore = new ScreenDataStore(this);
  apiKeyStore = new ApiKeyStore(this);
  thermalImageStore = new ThermalImageStore(this);
  adminUserStore = new AdminUserStore(this);
  machineEventStore = new MachineEventStore(this);
  pointEventStore = new PointEventStore(this);
  chiDataStore = new ChiDataStore(this);
  chiDataSetStore = new ChiDataSetStore(this);
  multiChiDataSetStore = new MultiChiDataSetStore(this);
  thermalImageZonesStore = new ThermalImageZonesStore(this);
  configStore = new ConfigStore(this);
  partnerStore = new PartnerStore(this);
  WPStore = new WPStore(this);

  refresh(showLoading = true) {
    runInAction(() => {
      if (!this.siteStore.fetching) {
        this.siteStore.loadSites(showLoading);
      }
      if (!this.machineStore.fetching) {
        // Machines response for admin users is very large so avoid refreshing
        // Only want to refresh machines if they have not already loaded
        if (!this.userStore.admin || this.machineStore.initialLoad) {
          this.machineStore.loadMachines(showLoading);
        }
      }

      // We only won't the alarm event notifications to refresh at most once a minute
      // Avoid refreshing on other conditions e.g. on tab change
      const isRefreshPeriod = Date.now() - this.periodicRefreshTime >= 60000;
      if (isRefreshPeriod) {
        if (!this.alarmEventStore.loading && this.preferenceStore.showAlarmNotifications) {
          this.alarmEventStore.notifyRealTimeEvents();
        }
        this.periodicRefreshTime = Date.now();
      }
      this.lastRefreshTime = Date.now();
    });
  }

  @computed get initialLoad() {
    return this.siteStore.initialLoad || this.machineStore.initialLoad || this.userStore.initialLoad;
  }

  // Cancels all child mobx disposers
  destructor() {
    this.mobxDisposers.forEach((disposer) => disposer());
  }

  // Utility wrapper to dispose of store reactions when the root store is destroyed
  disposeWithRootStore(reaction) {
    this.mobxDisposers.push(reaction);
  }

  debugSubscriptions = false;
  // eslint-disable-next-line
  checker = !(!IS_PRODUCTION && this.debugSubscriptions)
    ? null
    : reaction(
        () => {
          let total = 0;
          let str = "";
          Object.entries(this).forEach(([storeName, store]) => {
            if (store instanceof SubscribableStore) {
              const subs = store.subscriptions?.filter((x) => x.active).length ?? 0;
              total += subs;
              if (subs > 0) {
                str += `\t${subs} - ${storeName}\n`;
              }
            }
          });
          return `${total} active sub${total === 1 ? "" : "s"}\n${str}`;
        },
        (str) => {
          // eslint-disable-next-line
          console.log(str);
        },
      );
}

// Create context exports
export const StoreContext = createContext(null);

// Hook context consumer
export const useRootStore = () => {
  return useContext(StoreContext);
};

// Pre-hook context consumer HOC
export const withRootStore = (Component) => {
  const wrappedComponent = (props) => {
    return (
      <StoreContext.Consumer>
        {(rootStore) => {
          return (
            <Component
              {...props}
              rootStore={rootStore}
            />
          );
        }}
      </StoreContext.Consumer>
    );
  };
  hoistNonReactStatics(wrappedComponent, Component);
  wrappedComponent.propTypes = Component.propTypes;
  wrappedComponent.defaultProps = Component.defaultProps;
  return wrappedComponent;
};
