import {lazy, Suspense} from "react";
import type {PropsWithChildren} from "react";
import {ErrorBoundary as ReactErrorBoundary} from "react-error-boundary";
import type {ErrorBoundaryProps} from "react-error-boundary";

import LoadingAnimation from "sensoteq-react-core/components/LoadingAnimation";

const EmptyState = lazy(() => import("components/common/EmptyState"));
const Page = lazy(() => import("components/page/Page"));

const ErrorBoundary = (props: PropsWithChildren) => {
  const ErrorFallback = () => {
    return (
      <Suspense fallback={<LoadingAnimation />}>
        <Page>
          <EmptyState
            icon="sentiment_dissatisfied"
            title="Uh oh! Something went wrong."
            text="Press the button below to go back home."
            buttonText="Go home"
            buttonIcon="home"
            buttonOnClick={() => (window.location.href = window.location.origin)}
          />
        </Page>
      </Suspense>
    );
  };

  const ErrorHandler: ErrorBoundaryProps["onError"] = (error) => {
    // If this is an error loading a chunk then reload the page
    // as a new version is available
    if (error.toString().includes("chunk") || error.toString().includes("Unexpected token <")) {
      window.location.reload();
      return;
    }
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={ErrorHandler}
    >
      {props.children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
