import {computed} from "mobx";

import MultiPointSubscription from "./MultiPointSubscription";

export default class MultiTimeDomainKeySubscription extends MultiPointSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      getSubscribableStore: (x) => x.timeDomainKeyStore,
    };
  }

  extractData(extractFn) {
    let data = [];
    this.subscriptions.forEach((sub) => {
      let subData = extractFn(sub);
      if (subData != null && subData.length) {
        data.push({
          data: subData[0].data,
        });
      }
    });
    return this.enrichTitles(data);
  }

  extractKeys(extractFn) {
    let keys = [];
    this.subscriptions.forEach((sub) => {
      let subKeys = extractFn(sub);
      if (subKeys != null && subKeys.length) {
        keys.push(Array.from(subKeys.keys()));
      }
    });
    return keys;
  }

  @computed get keys() {
    return this.extractKeys((x) => x.keys);
  }

  @computed get velocityRmsData() {
    return this.extractData((x) => x.velocityRmsData);
  }

  @computed get accelerationRmsData() {
    return this.extractData((x) => x.accelerationRmsData);
  }

  @computed get envelopeRMSData() {
    return this.extractData((x) => x.envelopeRMSData);
  }

  @computed get envelopeTWFPeakData() {
    return this.extractData((x) => x.envelopeTWFPeakData);
  }

  @computed get peakToPeakData() {
    return this.extractData((x) => x.peakToPeakData);
  }

  @computed get tdAccelerationPeak() {
    return this.extractData((x) => x.tdAccelerationPeak);
  }

  @computed get measuredRPMData() {
    return this.extractData((x) => x.measuredRPMData);
  }

  @computed get crestFactorData() {
    return this.extractData((x) => x.crestFactorData);
  }

  @computed get powerData() {
    return this.extractData((x) => x.powerData);
  }
}
