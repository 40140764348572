import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colours.accent};
  font-size: ${(props) => props.theme.fontSizes.small};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  z-index: 999;

  * {
    color: white;
  }
`;

export const Link = styled.div`
  text-decoration: underline;
  margin-left: 0.8rem;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;
