import {observable, computed, flow} from "mobx";
import Api from "sensoteq-react-core/services/api";

export default class ConfigStore {
  rootStore;
  @observable serverConfig;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  loadServerConfig = flow(function* () {
    try {
      const config = yield Api.getServerConfiguration();
      this.serverConfig = config;
    } catch (error) {
      throw error;
    }
  });

  @computed get isMfaForced() {
    return this.serverConfig?.forced_mfa;
  }

  @computed get isLayoutV2Available() {
    return this.serverConfig?.layout_v2;
  }
}
