import React from "react";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

import {useFormState} from "../lib/mobx-form/context";

function Row(props) {
  const {buttons, children, ...otherProps} = props;
  const formState = useFormState();

  // Hide button rows if disabled
  if (buttons && formState?.disabled) {
    return null;
  }

  return (
    <StyledRow
      buttons={buttons}
      {...otherProps}
    >
      {children}
    </StyledRow>
  );
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  > * {
    min-width: 0;
    flex: 1 1 0;
    margin-left: 1.5rem;

    &:first-child {
      margin-left: 0;
    }
  }

  ${(props) =>
    props.auto &&
    css`
      > * {
        flex: 1 1 auto;
      }
    `}

  ${(props) =>
    props.buttons &&
    css`
      justify-content: flex-end;

      > * {
        flex: 0 1 auto;
        margin-left: 0.8rem;

        &:first-child {
          margin-left: 0;
        }
      }
    `}
  
  ${(props) =>
    props.left &&
    css`
      justify-content: flex-start;
    `}
  
  ${(props) =>
    props.responsive &&
    props.theme.mixins.mobile(css`
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      > * {
        flex: 0 0 auto;
        margin-left: 0;
        margin-bottom: 0.8rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    `)}
`;

Row.propTypes = {
  buttons: PropTypes.bool,
  responsive: PropTypes.bool,
  left: PropTypes.bool,
  auto: PropTypes.bool,
  children: PropTypes.node,
};
Row.defaultProps = {
  buttons: false,
  responsive: true,
  left: false,
};

export default Row;
