import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {useRouterStore} from "./index";

const onMouseUp = (e) => {
  if (e.button !== 0) {
    return;
  }
  e.stopPropagation();
  e.preventDefault();
};

const Link = React.forwardRef((props, ref) => {
  const {external, shallow, onClick, routeParams, queryParams, to, ...otherProps} = props;
  const routerStore = useRouterStore();

  if (shallow) {
    let shallowProps = {};
    if (external) {
      shallowProps = {
        href: to,
        target: "_blank",
        rel: "noreferrer noopener",
        onMouseUp,
      };
    } else {
      const url = typeof to === "object" ? routerStore.createUrl(to, routeParams, queryParams) : to;
      shallowProps = {
        href: url,
        onMouseUp,
        onClick: (e) => {
          e.stopPropagation();
          if (!e.ctrlKey) {
            e.preventDefault();
            if (onClick) {
              onClick();
            }
            routerStore.push(url);
          }
        },
      };
    }
    return otherProps.children != null ? React.cloneElement(otherProps.children, shallowProps) : null;
  }

  const url = !external && typeof to === "object" ? routerStore.createUrl(to, routeParams, queryParams) : to;
  const onLinkClick = (e) => {
    if (!e.ctrlKey) {
      e.preventDefault();
      if (onClick) {
        onClick();
      }
      url && routerStore.push(url);
    }
  };
  return external ? (
    <StyledLink
      ref={ref}
      onMouseUp={onMouseUp}
      target="_blank"
      rel="noreferrer noopener"
      href={url}
      {...otherProps}
    />
  ) : (
    <StyledLink
      ref={ref}
      onMouseUp={onMouseUp}
      href={url}
      onClick={onLinkClick}
      {...otherProps}
    />
  );
});

Link.propTypes = {
  external: PropTypes.bool,
  to: PropTypes.any,
  routeParams: PropTypes.object,
  queryParams: PropTypes.object,
  noDecoration: PropTypes.bool,
  shallow: PropTypes.bool,
};
Link.defaultProps = {
  external: false,
  noDecoration: false,
  shallow: false,
};

export default Link;

const StyledLink = styled.a`
  color: ${(props) => props.theme.colours.primary};
  outline: none;
  text-decoration: ${(props) => (props.noDecoration ? "none" : "underline solid")};
  text-decoration-skip: ink;
  display: inline-block;

  * {
    color: ${(props) => props.theme.colours.primary};
  }

  &:hover {
    color: ${(props) => props.theme.colours.primaryHighlight};
    cursor: pointer;

    * {
      color: ${(props) => props.theme.colours.primaryHighlight};
    }
  }
`;
