import styled, {css} from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  ${(props) =>
    props.theme.mixins.desktop(css`
      position: relative;
    `)}
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.2rem ${(props) => props.theme.dimensions.hPadding}rem;
  flex: 0 0 auto;

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colours.contentBackgroundAlt};
    border-top: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
    border-bottom: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  }

  &:last-child {
    padding-bottom: 2rem;
    border-bottom: none;
  }

  i {
    margin-right: 1rem;
    content: "check";
  }

  > div {
    flex: 1 1 auto;

    &:last-child {
      margin-left: 1rem;
      flex: 0 0 auto;
      font-weight: bold;
      font-size: ${(props) => props.theme.fontSizes.small};
      color: ${(props) => props.theme.colours.contentLight};
    }
  }

  ${(props) =>
    props.type === "good" &&
    css`
      i {
        color: ${(props) => props.theme.colours.good} !important;
      }
    `}

  ${(props) =>
    props.type === "warning" &&
    css`
      i {
        color: ${(props) => props.theme.colours.warning} !important;
      }
    `}
    
  ${(props) =>
    props.type === "bad" &&
    css`
      i {
        color: ${(props) => props.theme.colours.bad} !important;
      }
    `}
  
  ${(props) =>
    props.type === "info" &&
    css`
      i {
        color: ${(props) => props.theme.colours.info} !important;
      }
    `}
`;
