import {action} from "mobx";

import MultiEventSubscription from "./MultiEventSubscription";

export default class PointEventSubscription extends MultiEventSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      pointId: null,
    };
  }

  @action update(params = {}, replaceAllParams = false) {
    let newIdentifiers = this.params.identifiers;
    if (this.params.pointId !== params.pointId) {
      const point = this.rootStore.pointStore.getTestPoint(params.pointId);
      if (point == null) {
        newIdentifiers = [];
      } else {
        if (point.disabled) {
          newIdentifiers = [point.machine_id, point.point_id];
        } else {
          newIdentifiers = [point.device_name, point.machine_id, point.point_id];
        }
      }
    }
    super.update(
      {
        ...params,
        identifiers: newIdentifiers,
      },
      replaceAllParams,
    );
  }
}
