import * as Enums from "./enums";
import {
  AlarmOperators,
  AlarmProperties,
  AlarmTargetTypes,
  AlarmTypes,
  ServiceAppModes,
  MotorLineFrequencies,
  MotorPoles,
  MotorSCRTypes,
  MotorTypes,
  MountingTypes,
  PowerUnits,
  CouplingTypes,
  MeasuringPointPositions,
  BearingConstructionTypes,
  MeasuringPointSteps,
  MeasuringPointScreenHeights,
  ScreenMotionTypes,
  DeckCounts,
  SensorTypes,
  TimeWindows,
  MeasuringPointTypes,
  AxisTypes,
} from "../../sensoteq-core/enumerations";
import {WindowingOptions, FilteringOptions} from "../../sensoteq-core/calculations";
import {ENVELOPE_METHOD, ENVELOPE_BAND_PASS_LABELS} from "../../sensoteq-core/signal-processing/constants-envelope";
import {getAlarmablePowerBandsInOrder} from "../../sensoteq-core/power-bands/power-bands";
import {MeasurementDomain} from "@sensoteq/units";

export const TimeOptions = [
  {
    label: "latest",
    value: Enums.TIME_OPTION_LATEST,
  },
  {
    label: "1d",
    value: Enums.TIME_OPTION_1D,
  },
  {
    label: "1w",
    value: Enums.TIME_OPTION_1W,
  },
  {
    label: "1m",
    value: Enums.TIME_OPTION_1M,
  },
  {
    label: "1y",
    value: Enums.TIME_OPTION_1Y,
  },
  {
    label: "5y",
    value: Enums.TIME_OPTION_5Y,
  },
  {
    label: "custom",
    value: Enums.TIME_OPTION_CUSTOM,
  },
];

export const TrendingTimeOptions = [
  {
    label: "1w",
    value: Enums.TIME_OPTION_1W,
  },
  {
    label: "1m",
    value: Enums.TIME_OPTION_1M,
  },
  {
    label: "1y",
    value: Enums.TIME_OPTION_1Y,
  },
  {
    label: "5y",
    value: Enums.TIME_OPTION_5Y,
  },
  {
    label: "custom",
    value: Enums.TIME_OPTION_CUSTOM,
  },
];

export const MultiTimeOptions = [
  {
    label: "1w",
    value: Enums.TIME_OPTION_1W,
  },
  {
    label: "1m",
    value: Enums.TIME_OPTION_1M,
  },
  {
    label: "1y",
    value: Enums.TIME_OPTION_1Y,
  },
  {
    label: "5y",
    value: Enums.TIME_OPTION_5Y,
  },
  {
    label: "range",
    value: Enums.TIME_OPTION_RANGE,
  },
  {
    label: "multi",
    value: Enums.TIME_OPTION_MULTI,
  },
];

export const MachineTimeOptions = [
  {
    label: "1d",
    value: Enums.TIME_OPTION_1D,
  },
  {
    label: "1w",
    value: Enums.TIME_OPTION_1W,
  },
  {
    label: "1m",
    value: Enums.TIME_OPTION_1M,
  },
];

export const SpecificTimeOptions = [
  {
    label: "latest",
    value: Enums.TIME_OPTION_LATEST,
  },
  {
    label: "custom",
    value: Enums.TIME_OPTION_CUSTOM,
  },
];

export const AverageTypeOptions = [
  {
    label: "min",
    value: Enums.AVERAGE_OPTION_MIN,
  },
  {
    label: "avg",
    value: Enums.AVERAGE_OPTION_AVG,
  },
  {
    label: "max",
    value: Enums.AVERAGE_OPTION_MAX,
  },
];

export const AxisOptions = [
  {
    label: "Horiz.",
    value: Enums.HORIZONTAL_AXIS,
  },
  {
    label: "Vert.",
    value: Enums.VERTICAL_AXIS,
  },
  {
    label: "Axial",
    value: Enums.AXIAL_AXIS,
  },
];

export const RawAxisOptions = [
  {
    label: "X Axis",
    value: AxisTypes.AXIS_X,
  },
  {
    label: "Y Axis",
    value: AxisTypes.AXIS_Y,
  },
  {
    label: "Z Axis",
    value: AxisTypes.AXIS_Z,
  },
];

export const ResolutionOptions = [
  {
    label: "Full spectrum",
    value: Enums.LOW_RES,
    description: "Full Fmax spectrum",
  },
  {
    label: "High res.",
    value: Enums.HIGH_RES,
    description: "Limited Fmax spectrum with high resolution",
  },
];

export const CADTooltipOptions = [
  {
    label: "Hover",
    value: Enums.HOVER,
    description: "Enable Hover Options",
  },
  {
    label: "Show All",
    value: Enums.ALL,
    description: "Enable Show All",
  },
];

export const DimensionOptions = [
  {
    label: "2D",
    value: Enums.TWO_DIMENSION,
    description: "Apply 2D",
  },
  {
    label: "3D",
    value: Enums.THREE_DIMENSION,
    description: "Apply 3D",
  },
];

export const DomainOptions = [
  {
    label: "Acc.",
    value: Enums.ACCELERATION_DOMAIN,
    description: "Acceleration",
  },
  {
    label: "Vel.",
    value: Enums.VELOCITY_DOMAIN,
    description: "Velocity",
  },
  {
    label: "Disp.",
    value: Enums.DISPLACEMENT_DOMAIN,
    description: "Displacement",
  },
];

export const AlarmOperatorOptions = [
  {
    label: "Less than",
    value: AlarmOperators.LESS_THAN,
  },
  {
    label: "Greater than",
    value: AlarmOperators.GREATER_THAN,
  },
  {
    label: "Between",
    value: AlarmOperators.BETWEEN,
  },
  {
    label: "Outside",
    value: AlarmOperators.OUTSIDE,
  },
  {
    label: "Equals",
    value: AlarmOperators.EQUAL,
  },
];

export const AlarmTypeOptions = [
  {
    label: "Online Status",
    value: AlarmTypes.ONLINE_STATUS,
  },
  {
    label: "Warning",
    value: AlarmTypes.WARNING,
  },
  {
    label: "Critical",
    value: AlarmTypes.CRITICAL,
  },
];

export const WindowOptions = [
  {
    value: WindowingOptions.NONE,
    label: "None",
    description: "No windowing applied to waveform",
  },
  {
    value: WindowingOptions.HANN_WINDOW,
    label: "Hann window",
    description: "Hann window applied to waveform (not visually shown)",
  },
];

export const FilterOptions = [
  {
    value: null,
    label: "None",
  },
  {
    value: FilteringOptions.LOW_PASS,
    label: "Low pass",
  },
  {
    value: FilteringOptions.HIGH_PASS,
    label: "High pass",
  },
  {
    value: FilteringOptions.BAND_PASS,
    label: "Band pass",
  },
];

export const AlarmPropertyOptions = [
  {
    label: "Ambient Temperature",
    value: AlarmProperties.AMBIENT_TEMPERATURE,
    domain: MeasurementDomain.TEMPERATURE,
  },
  {
    label: "Humidity",
    value: AlarmProperties.HUMIDITY,
    units: ["%"],
  },
  {
    label: "WiFi Strength",
    value: AlarmProperties.WIFI_STRENGTH,
    units: ["%"],
  },
  {
    label: "Signal Quality",
    value: AlarmProperties.SIGNAL_QUALITY,
    units: ["%"],
  },
  {
    label: "Machine Temperature",
    value: AlarmProperties.MACHINE_TEMPERATURE,
    domain: MeasurementDomain.TEMPERATURE,
  },
  {
    label: "Machine minus Ambient Temperature",
    value: AlarmProperties.MACHINE_MINUS_AMBIENT,
    domain: MeasurementDomain.TEMPERATURE,
  },
  {
    label: "Voltage",
    value: AlarmProperties.VOLTAGE,
    units: ["mV"],
  },
  {
    label: "X-axis g-force",
    value: AlarmProperties.PEAK_TO_PEAK_X,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Y-axis g-force",
    value: AlarmProperties.PEAK_TO_PEAK_Y,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Z-axis g-force",
    value: AlarmProperties.PEAK_TO_PEAK_Z,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "X-axis Vib. Power (high-res)",
    value: AlarmProperties.VIB_POWER_X_H,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Y-axis Vib. Power (high-res)",
    value: AlarmProperties.VIB_POWER_Y_H,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Z-axis Vib. Power (high-res)",
    value: AlarmProperties.VIB_POWER_Z_H,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "X-axis Vib. Power (low-res)",
    value: AlarmProperties.VIB_POWER_X_L,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Y-axis Vib. Power (low-res)",
    value: AlarmProperties.VIB_POWER_Y_L,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Z-axis Vib. Power (low-res)",
    value: AlarmProperties.VIB_POWER_Z_L,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "X-axis RMS",
    value: AlarmProperties.AXIS_RMS_X,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Y-axis RMS",
    value: AlarmProperties.AXIS_RMS_Y,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Z-axis RMS",
    value: AlarmProperties.AXIS_RMS_Z,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "X-axis Velocity RMS",
    value: AlarmProperties.AXIS_VEL_RMS_X,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Y-axis Velocity RMS",
    value: AlarmProperties.AXIS_VEL_RMS_Y,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Z-axis Velocity RMS",
    value: AlarmProperties.AXIS_VEL_RMS_Z,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "X-axis Peak To Peak",
    value: AlarmProperties.AXIS_PEAK_X,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Y-axis Peak To Peak",
    value: AlarmProperties.AXIS_PEAK_Y,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Z-axis Peak To Peak",
    value: AlarmProperties.AXIS_PEAK_Z,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Stroke Angle",
    value: AlarmProperties.STROKE_ANGLE,
    domain: MeasurementDomain.ANGLE,
  },
  {
    label: "Stroke Length",
    value: AlarmProperties.STROKE_LENGTH,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  {
    label: "Stroke Ratio",
    value: AlarmProperties.STROKE_RATIO,
    units: [],
  },
  {
    label: "RPM",
    value: AlarmProperties.RPM,
    domain: MeasurementDomain.FREQUENCY,
  },
  {
    label: "X Velocity",
    value: AlarmProperties.X_VELOCITY,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Y Velocity",
    value: AlarmProperties.Y_VELOCITY,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Z Velocity",
    value: AlarmProperties.Z_VELOCITY,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "X Displacement",
    value: AlarmProperties.X_DISPLACEMENT,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  {
    label: "Y Displacement",
    value: AlarmProperties.Y_DISPLACEMENT,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  {
    label: "Z Displacement",
    value: AlarmProperties.Z_DISPLACEMENT,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  {
    label: "X Acceleration",
    value: AlarmProperties.X_ACCELERATION,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Y Acceleration",
    value: AlarmProperties.Y_ACCELERATION,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Z Acceleration",
    value: AlarmProperties.Z_ACCELERATION,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Calc. X-Axis RMS (acc.) (high-res)",
    value: AlarmProperties.ACC_RMS_X_H,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Calc. Y-Axis RMS (acc.) (high-res)",
    value: AlarmProperties.ACC_RMS_Y_H,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Calc. Z-Axis RMS (acc.) (high-res)",
    value: AlarmProperties.ACC_RMS_Z_H,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Calc. X-Axis RMS (acc.) (low-res)",
    value: AlarmProperties.ACC_RMS_X_L,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Calc. Y-Axis RMS (acc.) (low-res)",
    value: AlarmProperties.ACC_RMS_Y_L,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Calc. Z-Axis RMS (acc.) (low-res)",
    value: AlarmProperties.ACC_RMS_Z_L,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Calc. X-Axis RMS (vel.) (high-res)",
    value: AlarmProperties.VEL_RMS_X_H,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Calc. Y-Axis RMS (vel.) (high-res)",
    value: AlarmProperties.VEL_RMS_Y_H,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Calc. Z-Axis RMS (vel.) (high-res)",
    value: AlarmProperties.VEL_RMS_Z_H,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Calc. X-Axis RMS (vel.) (low-res)",
    value: AlarmProperties.VEL_RMS_X_L,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Calc. Y-Axis RMS (vel.) (low-res)",
    value: AlarmProperties.VEL_RMS_Y_L,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Calc. Z-Axis RMS (vel.) (low-res)",
    value: AlarmProperties.VEL_RMS_Z_L,
    domain: MeasurementDomain.VELOCITY,
  },
  {
    label: "Calc. X-Axis RMS (disp.) (high-res)",
    value: AlarmProperties.DISP_RMS_X_H,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  {
    label: "Calc. Y-Axis RMS (disp.) (high-res)",
    value: AlarmProperties.DISP_RMS_Y_H,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  {
    label: "Calc. Z-Axis RMS (disp.) (high-res)",
    value: AlarmProperties.DISP_RMS_Z_H,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  {
    label: "Calc. X-Axis RMS (disp.) (low-res)",
    value: AlarmProperties.DISP_RMS_X_L,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  {
    label: "Calc. Y-Axis RMS (disp.) (low-res)",
    value: AlarmProperties.DISP_RMS_Y_L,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  {
    label: "Calc. Z-Axis RMS (disp.) (low-res)",
    value: AlarmProperties.DISP_RMS_Z_L,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  {
    label: "Calc. X-Axis Crest Factor (high-res)",
    value: AlarmProperties.CREST_FACTOR_X_H,
  },
  {
    label: "Calc. Y-Axis Crest Factor (high-res)",
    value: AlarmProperties.CREST_FACTOR_Y_H,
  },
  {
    label: "Calc. Z-Axis Crest Factor (high-res)",
    value: AlarmProperties.CREST_FACTOR_Z_H,
  },
  {
    label: "Calc. X-Axis Crest Factor (low-res)",
    value: AlarmProperties.CREST_FACTOR_X_L,
  },
  {
    label: "Calc. Y-Axis Crest Factor (low-res)",
    value: AlarmProperties.CREST_FACTOR_Y_L,
  },
  {
    label: "Calc. Z-Axis Crest Factor (low-res)",
    value: AlarmProperties.CREST_FACTOR_Z_L,
  },
  {
    label: "X-axis Peak-to-Peak (high-res)",
    value: AlarmProperties.PEAK_TD_X_H,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Y-axis Peak-to-Peak (high-res)",
    value: AlarmProperties.PEAK_TD_Y_H,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Z-axis Peak-to-Peak (high-res)",
    value: AlarmProperties.PEAK_TD_Z_H,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "X-axis Peak-to-Peak (low-res)",
    value: AlarmProperties.PEAK_TD_X_L,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Y-axis Peak-to-Peak (low-res)",
    value: AlarmProperties.PEAK_TD_Y_L,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Z-axis Peak-to-Peak (low-res)",
    value: AlarmProperties.PEAK_TD_Z_L,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "X-axis TWF peak acceleration (high-res)",
    value: AlarmProperties.PEAK_VALUE_ACC_TD_X_H,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Y-axis TWF peak acceleration (high-res)",
    value: AlarmProperties.PEAK_VALUE_ACC_TD_Y_H,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Z-axis TWF peak acceleration (high-res)",
    value: AlarmProperties.PEAK_VALUE_ACC_TD_Z_H,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "X-axis TWF peak acceleration (full spectrum)",
    value: AlarmProperties.PEAK_VALUE_ACC_TD_X_L,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Y-axis TWF peak acceleration (full spectrum)",
    value: AlarmProperties.PEAK_VALUE_ACC_TD_Y_L,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "Z-axis TWF peak acceleration (full spectrum)",
    value: AlarmProperties.PEAK_VALUE_ACC_TD_Z_L,
    domain: MeasurementDomain.ACCELERATION,
  },
  {
    label: "X-axis Envelope Acceleration RMS (low-res)",
    value: AlarmProperties.ENVELOPE_HILBERT_ACC_RMS_X_L,
  },
  {
    label: "Y-axis Envelope Acceleration RMS (low-res)",
    value: AlarmProperties.ENVELOPE_HILBERT_ACC_RMS_Y_L,
  },
  {
    label: "Z-axis Envelope Acceleration RMS (low-res)",
    value: AlarmProperties.ENVELOPE_HILBERT_ACC_RMS_Z_L,
  },
  {
    label: "X-axis Envelope Peak Acceleration (low-res)",
    value: AlarmProperties.ENVELOPE_HILBERT_ACC_PEAK_X_L,
  },
  {
    label: "Y-axis Envelope Peak Acceleration (low-res)",
    value: AlarmProperties.ENVELOPE_HILBERT_ACC_PEAK_Y_L,
  },
  {
    label: "Z-axis Envelope Peak Acceleration (low-res)",
    value: AlarmProperties.ENVELOPE_HILBERT_ACC_PEAK_Z_L,
  },
  {
    label: "Time Waveform Validity",
    value: AlarmProperties.TIME_WAVEFORM_VALIDITY,
  },
  {
    label: "Phase Angle",
    value: AlarmProperties.PHASE_ANGLE,
    domain: MeasurementDomain.ANGLE,
  },
];

export const AlarmTargetTypeOptions = [
  {
    label: "Point",
    value: AlarmTargetTypes.MEASURING_POINT,
  },
  {
    label: "Gateway",
    value: AlarmTargetTypes.DEVICE,
  },
];

export const ServiceAppModeOptions = [
  {
    label: "Vibration Analysis",
    value: ServiceAppModes.VIBRATION_ANALYSIS,
  },
  {
    label: "Stroke Analysis",
    value: ServiceAppModes.STROKE_ANALYSIS,
  },
  {
    label: "Hammer Test",
    value: ServiceAppModes.HAMMER_TEST,
  },
];

export const SensorTypeOptions = [
  {
    label: "PMES",
    value: SensorTypes.LOW_G_HIGH_MOTOR,
  },
  {
    label: "PMES-AUTO",
    value: SensorTypes.LOW_G_AUTO_MOTOR,
  },
  {
    label: "Kappa X",
    value: SensorTypes.KPX,
  },
  {
    label: "SCES",
    value: SensorTypes.HIGH_G_SCREEN,
  },
  {
    label: "SCES-FFT",
    value: SensorTypes.LOW_G_SCREEN,
  },
  {
    label: "SCES-TWF",
    value: SensorTypes.HIGH_G_SCREEN_TWF,
  },
  {
    label: "FORCE",
    value: SensorTypes.HIGH_G_MOTOR,
  },
  {
    label: "Legacy - Old PMES",
    value: SensorTypes.LOW_G_MOTOR,
  },
  {
    label: "Legacy - Old PMES (RMS)",
    value: SensorTypes.LOW_G_RMS_MOTOR,
  },
  {
    label: "WMVM",
    value: SensorTypes.VULCO_MONITORING_SENSOR,
  },
];

export const PrivilegeOptions = [
  {
    label: "User",
    value: Enums.PRIVILEGE_LEVEL_USER,
    description: "Standard privileges for viewing and analysing data",
  },
  {
    label: "Installer",
    value: Enums.PRIVILEGE_LEVEL_INSTALLER,
    description: "Enhanced privileges for installing and editing settings",
  },
  {
    label: "Partner",
    value: Enums.PRIVILEGE_LEVEL_PARTNER,
    description: "Enhanced privileges for partner settings",
  },
  {
    label: "Administrator",
    value: Enums.PRIVILEGE_LEVEL_ADMIN,
    description: "Full permissions across the site",
  },
];

export const PrivilegeOptionsForPartners = [
  {
    label: "User",
    value: Enums.PRIVILEGE_LEVEL_USER,
    description: "Standard privileges for viewing and analysing data",
  },
  {
    label: "Installer",
    value: Enums.PRIVILEGE_LEVEL_INSTALLER,
    description: "Enhanced privileges for installing and editing settings",
  },
];

export const ExtendedInstallerPrivileges = [
  "alarm.read",
  "alarm.create",
  "alarm.update",
  "alarm.delete",
  "bulk_alarm.read",
  "bulk_alarm.create",
  "bulk_alarm.update",
  "bulk_alarm.delete",
  "alarm_template.read",
  "alarm_template.create",
  "alarm_template.update",
  "alarm_template.delete",
];

export const MotorTypeOptions = [
  {
    label: "AC induction",
    value: MotorTypes.AC_INDUCTION,
    description: "An induction motor is a single excited machine and will always run less than the synchronous speed",
  },
  {
    label: "AC synchronous",
    value: MotorTypes.AC_SYNCHRONOUS,
    descripton: "A three-phase synchronous motor is a doubly excited machine that speed is independent of load",
  },
  {
    label: "DC",
    value: MotorTypes.DC,
    descrition: "Direct current motor",
  },
];

export const MountingOptions = [
  {
    label: "Fixed",
    value: MountingTypes.FIXED,
    description: "Securely attached to a fixed structure",
  },
  {
    label: "Isolated",
    value: MountingTypes.ISOLATED,
    description: "Mounted via a method of dampening, springs or rubber etc",
  },
];

export const LineFrequencyOptions = [
  {
    label: "50 Hz",
    value: MotorLineFrequencies.FIFTY_HZ,
  },
  {
    label: "60 Hz",
    value: MotorLineFrequencies.SIXTY_HZ,
  },
];

export const MotorSCROptions = [
  {
    label: "6 AC",
    value: MotorSCRTypes.SIX_AC,
  },
  {
    label: "3 DC",
    value: MotorSCRTypes.THREE_DC,
  },
];

export const MotorPoleOptions = Object.values(MotorPoles).map((pole) => ({label: pole, value: pole}));

export const PowerUnitOptions = [
  {
    label: "KW",
    value: PowerUnits.KW,
  },
  {
    label: "HP",
    value: PowerUnits.HP,
  },
];

export const CouplingTypeOptions = [
  {
    label: "Close coupled",
    value: CouplingTypes.CLOSE_COUPLED,
    description: "A short drive shaft with a single coupling",
  },
  {
    label: "Long drive shaft",
    value: CouplingTypes.LONG_DRIVE_SHAFT,
    description: "A long drive shaft typically with two or more couplings",
  },
  {
    label: "Belt",
    value: CouplingTypes.BELT,
    description: "The motor is connected to the application via a belt",
  },
  {
    label: "Gearbox",
    value: CouplingTypes.GEARBOX,
    description: "The motor is connected to the application via a gearbox",
  },
  {
    label: "Gear",
    value: CouplingTypes.GEAR,
    description: "The motor is connected to the application via a single gear set",
  },
];

export const MeasuringPointScreenHeightOptions = [
  {
    label: "Bottom",
    value: MeasuringPointScreenHeights.BOTTOM,
  },
  {
    label: "Middle",
    value: MeasuringPointScreenHeights.MIDDLE,
  },
  {
    label: "Top",
    value: MeasuringPointScreenHeights.TOP,
  },
];

export const BearingConstructionTypeOptions = [
  {
    label: "Roller",
    value: BearingConstructionTypes.ROLLER,
  },
  {
    label: "Sleeve",
    value: BearingConstructionTypes.SLEEVE,
  },
];

export const MeasuringPointStepOptions = [
  {
    label: "Motor",
    value: MeasuringPointSteps.MOTOR,
  },
  {
    label: "Intermediary",
    value: MeasuringPointSteps.INTERMEDIARY,
  },
  {
    label: "Application",
    value: MeasuringPointSteps.APPLICATION,
  },
  {
    label: "Other",
    value: MeasuringPointSteps.OTHER,
  },
];

export const MeasuringPointPositionOptions = [
  {
    label: "DE / IB",
    value: MeasuringPointPositions.DRIVE_END,
  },
  {
    label: "NDE / ODE / OB",
    value: MeasuringPointPositions.NON_DRIVE_END,
  },
  {
    label: "Housing",
    value: MeasuringPointPositions.HOUSING,
  },
  {
    label: "Support",
    value: MeasuringPointPositions.SUPPORT,
  },
  {
    label: "Other",
    value: MeasuringPointPositions.OTHER,
  },
];

export const MeasuringPointScreenPositionOptions = [
  {
    label: "Left feed",
    value: MeasuringPointPositions.LEFT_FEED,
  },
  {
    label: "Left feed middle",
    value: MeasuringPointPositions.LEFT_FEED_MIDDLE,
  },
  {
    label: "Left middle",
    value: MeasuringPointPositions.LEFT_MIDDLE,
  },
  {
    label: "Left discharge middle",
    value: MeasuringPointPositions.LEFT_DISCHARGE_MIDDLE,
  },
  {
    label: "Left discharge",
    value: MeasuringPointPositions.LEFT_DISCHARGE,
  },
  {
    label: "Right feed",
    value: MeasuringPointPositions.RIGHT_FEED,
  },
  {
    label: "Right feed middle",
    value: MeasuringPointPositions.RIGHT_FEED_MIDDLE,
  },
  {
    label: "Right middle",
    value: MeasuringPointPositions.RIGHT_MIDDLE,
  },
  {
    label: "Right discharge middle",
    value: MeasuringPointPositions.RIGHT_DISCHARGE_MIDDLE,
  },
  {
    label: "Right discharge",
    value: MeasuringPointPositions.RIGHT_DISCHARGE,
  },
  {
    label: "Upper bearing",
    value: MeasuringPointPositions.UPPER_BEARING,
  },
  {
    label: "Lower bearing",
    value: MeasuringPointPositions.LOWER_BEARING,
  },
  {
    label: "Right bearing",
    value: MeasuringPointPositions.RIGHT_BEARING,
  },
  {
    label: "Left bearing",
    value: MeasuringPointPositions.LEFT_BEARING,
  },
  {
    label: "Other",
    value: MeasuringPointPositions.OTHER,
  },
];

export const MeasuringPointIntermediaryPositionOptions = [
  {
    label: "Support",
    value: MeasuringPointPositions.SUPPORT,
  },
  {
    label: "Shaft 1 - Drive end",
    value: MeasuringPointPositions.SHAFT_1_DRIVE_END,
  },
  {
    label: "Shaft 1 - Non-drive end",
    value: MeasuringPointPositions.SHAFT_1_NON_DRIVE_END,
  },
  {
    label: "Shaft 2 - Drive end",
    value: MeasuringPointPositions.SHAFT_2_DRIVE_END,
  },
  {
    label: "Shaft 2 - Non-drive end",
    value: MeasuringPointPositions.SHAFT_2_NON_DRIVE_END,
  },
  {
    label: "Shaft 3 - Drive end",
    value: MeasuringPointPositions.SHAFT_3_DRIVE_END,
  },
  {
    label: "Shaft 3 - Non-drive end",
    value: MeasuringPointPositions.SHAFT_3_NON_DRIVE_END,
  },
  {
    label: "Shaft 4 - Drive end",
    value: MeasuringPointPositions.SHAFT_4_DRIVE_END,
  },
  {
    label: "Shaft 4 - Non-drive end",
    value: MeasuringPointPositions.SHAFT_4_NON_DRIVE_END,
  },
  {
    label: "Shaft 5 - Drive end",
    value: MeasuringPointPositions.SHAFT_5_DRIVE_END,
  },
  {
    label: "Shaft 5 - Non-drive end",
    value: MeasuringPointPositions.SHAFT_5_NON_DRIVE_END,
  },
  {
    label: "Shaft 6 - Drive end",
    value: MeasuringPointPositions.SHAFT_6_DRIVE_END,
  },
  {
    label: "Shaft 6 - Non-drive end",
    value: MeasuringPointPositions.SHAFT_6_NON_DRIVE_END,
  },
  {
    label: "Shaft 7 - Drive end",
    value: MeasuringPointPositions.SHAFT_7_DRIVE_END,
  },
  {
    label: "Shaft 7 - Non-drive end",
    value: MeasuringPointPositions.SHAFT_7_NON_DRIVE_END,
  },
  {
    label: "Shaft 8 - Drive end",
    value: MeasuringPointPositions.SHAFT_8_DRIVE_END,
  },
  {
    label: "Shaft 8 - Non-drive end",
    value: MeasuringPointPositions.SHAFT_8_NON_DRIVE_END,
  },
  {
    label: "Other",
    value: MeasuringPointPositions.OTHER,
  },
];

export const ScreenMotionOptions = [
  {
    label: "Elliptical",
    value: ScreenMotionTypes.ELLIPTICAL,
  },
  {
    label: "Circular",
    value: ScreenMotionTypes.CIRCULAR,
  },
  {
    label: "Linear",
    value: ScreenMotionTypes.LINEAR,
  },
];

export const DeckCountOptions = [
  {
    label: "1",
    value: DeckCounts.ONE,
  },
  {
    label: "2",
    value: DeckCounts.TWO,
  },
  {
    label: "3",
    value: DeckCounts.THREE,
  },
  {
    label: "4",
    value: DeckCounts.FOUR,
  },
  {
    label: "5",
    value: DeckCounts.FIVE,
  },
];

export const PowerBandOptions = getAlarmablePowerBandsInOrder().map((band) => {
  return {
    label: band.label,
    value: band.name,
  };
});

export const BaselineWindowOptions = [
  {
    label: "1 hour",
    value: TimeWindows.HOUR,
  },
  {
    label: "1 day",
    value: TimeWindows.DAY,
  },
  {
    label: "1 week",
    value: TimeWindows.WEEK,
  },
  {
    label: "1 month",
    value: TimeWindows.MONTH,
  },
];

export const MeasuringPointTypeOptions = [
  {
    label: "Vibration",
    value: MeasuringPointTypes.VIBRATION,
  },
  {
    label: "Thermal",
    value: MeasuringPointTypes.THERMAL,
  },
  {
    label: "Wired Node",
    value: MeasuringPointTypes.WIRED_NODE,
  },
  /*  not yet implemented so don't want to display this option
  {
  label: "Wireless Node",
  value: MeasuringPointTypes.WIRELESS_NODE
}
*/
];

export const RotationOptions = [
  {
    label: "Adjust for rotation",
    value: true,
  },
  {
    label: "Don't adjust",
    value: false,
  },
];

export const WaterfallCountOptions = [10, 20].map((x) => ({
  label: x,
  value: x,
}));

export const EnvelopeOptions = [
  {
    label: "Hilbert Transform",
    value: ENVELOPE_METHOD.HILBERT,
  },
];

export const AbsoluteOptions = Array.from({length: 100}, (_, i) => i + 1).map((x) => ({
  label: x + "°C (" + ((x * 9) / 5 + 32) + "°F)",
  value: x,
}));

export const DeltaOptions = Array.from({length: 20}, (_, i) => i + 1).map((x) => ({
  label: x + "°C (" + ((x * 9) / 5 + 32) + "°F)",
  value: x,
}));

export const LostMeasuringOptions = [
  {
    label: "5 mins",
    value: 5,
  },
  {
    label: "20 mins",
    value: 20,
  },
  {
    label: "1 hour",
    value: 60,
  },
  {
    label: "6 hours",
    value: 360,
  },
  {
    label: "24 hours",
    value: 1440,
  },
  {
    label: "1 week",
    value: 10080,
  },
];

export const LostSystemOptions = [
  {
    label: "20 mins",
    value: 20,
  },
  {
    label: "1 hour",
    value: 60,
  },
  {
    label: "6 hours",
    value: 360,
  },
  {
    label: "24 hours",
    value: 1440,
  },
  {
    label: "1 week",
    value: 10080,
  },
];

function generateValueToLabelDictionary(options) {
  let dictionary = {};
  options.forEach((option) => {
    dictionary[option.value] = option.label;
  });
  return dictionary;
}

export const MachinePositionDictionary = generateValueToLabelDictionary([
  ...MeasuringPointPositionOptions,
  ...MeasuringPointScreenPositionOptions,
  ...MeasuringPointIntermediaryPositionOptions,
]);
export const MachineStepDictionary = generateValueToLabelDictionary(MeasuringPointStepOptions);

export const EnvelopeBandPassOptions = Object.values(ENVELOPE_BAND_PASS_LABELS);
