import QueryString from "query-string";
import "whatwg-fetch";

import * as Endpoints from "../constants/endpoints";
import {WelcomeEmailTypes} from "../../sensoteq-core/enumerations";
const buildUrl = Endpoints.buildUrl;

// Configure API auth token
let apiAuthToken = null;
export function setAuthToken(token) {
  apiAuthToken = token;
}

// Enums
const AuthToken = {
  API: "api",
  None: "none",
};
const ParseStrategy = {
  JSON: "json",
  Image: "image",
  Text: "text",
};

// Configure logout
let onLogOut = () => {};
export function setOnLogOut(fn) {
  onLogOut = fn;
}

// Block notification functionality
let blockNotifications = () => {};
export function setBlockNotifications(fn) {
  blockNotifications = fn;
}

const Api = {
  logIn(username, password) {
    return post(buildUrl(Endpoints.LOGIN), {username, password}, AuthToken.None);
  },

  logOut() {
    return post(buildUrl(Endpoints.LOGOUT));
  },

  getServerConfiguration() {
    return get(buildUrl(Endpoints.SERVER_CONFIGURATION), null, AuthToken.None);
  },

  getDevice(device_name) {
    return get(buildUrl(Endpoints.GET_DEVICE), {device_name});
  },

  getDevices() {
    return get(buildUrl(Endpoints.GET_DEVICES));
  },

  getSensorsForDevice(device_name) {
    return get(buildUrl(Endpoints.SENSORS_FOR_DEVICE), {device_name});
  },

  getPointData(point_id, go_back = 100) {
    return get(buildUrl(Endpoints.GET_POINT_DATA), {point_id, go_back});
  },

  getUserList() {
    return get(buildUrl(Endpoints.USER_LIST));
  },

  getUserDetails(username) {
    return get(buildUrl(Endpoints.USER_DETAILS), {username});
  },

  updateUser(user) {
    return post(buildUrl(Endpoints.UPDATE_USER), user);
  },

  createUser(user) {
    return post(buildUrl(Endpoints.CREATE_USER), user);
  },

  deleteUser(username) {
    return post(buildUrl(Endpoints.DELETE_USER), {username});
  },

  unlockUser(accountID) {
    return post(buildUrl(Endpoints.UNLOCK_USER), {account_id: accountID});
  },

  getHistoricalPointData(point_id, timestamp, timestamp_end, approx_count = 100) {
    return get(buildUrl(Endpoints.GET_HISTORICAL_POINT_DATA), {point_id, timestamp, timestamp_end, approx_count});
  },

  getHistoricalPointDataSeries(point_id, timestamp, timestamp_end, approx_count = 100, time_range) {
    return get(buildUrl(Endpoints.GET_HISTORICAL_POINT_DATA_SERIES), {
      point_id,
      timestamp,
      timestamp_end,
      approx_count,
      time_range,
    });
  },

  generateSensorId(description, type) {
    return post(buildUrl(Endpoints.GENERATE_SENSOR_ID), {description, type});
  },

  getAlarms(site_name, target, alarm_id) {
    return get(buildUrl(Endpoints.GET_ALARMS), {site_name, target, alarm_id});
  },

  getAlarmHistory(target, target_type, timestamp, timestamp_end) {
    return get(buildUrl(Endpoints.GET_ALARM_HISTORY), {target, target_type, timestamp, timestamp_end});
  },

  updateAlarmNotification(alarm_id, send_email, send_sms, usernames_to_remove, usernames_to_add, site_name) {
    return post(buildUrl(Endpoints.UPDATE_ALARM_NOTIFICATION), {
      alarm_id,
      send_email,
      send_sms,
      usernames_to_remove,
      usernames_to_add,
      site_name,
    });
  },

  createAlarm(
    type,
    site_name,
    target,
    send_email,
    send_sms,
    usernames,
    property,
    operator,
    trigger_strategy,
    values,
    target_type,
  ) {
    return post(buildUrl(Endpoints.CREATE_ALARM), {
      type,
      site_name,
      target,
      send_email,
      send_sms,
      usernames,
      property,
      operator,
      trigger_strategy,
      values,
      target_type,
    });
  },

  deleteAlarm(alarm_id, site_name) {
    return post(buildUrl(Endpoints.DELETE_ALARM), {alarm_id, site_name});
  },

  updateAlarm(alarm_id, name, operator, trigger_strategy, values, site_name) {
    return post(buildUrl(Endpoints.UPDATE_ALARM), {alarm_id, name, operator, trigger_strategy, values, site_name});
  },

  getCompanies() {
    return get(buildUrl(Endpoints.COMPANY_LIST));
  },

  getFilterList(device_name) {
    return get(buildUrl(Endpoints.GET_FILTER_LIST), {device_name});
  },

  updateFilterList(device_name, sensors) {
    return post(buildUrl(Endpoints.UPDATE_FILTER_LIST), {device_name, sensors});
  },

  repairFilterList(device_name) {
    return post(buildUrl(Endpoints.REPAIR_FILTER_LIST), {device_name});
  },

  getAllSensorIDs() {
    return get(buildUrl(Endpoints.GET_ALL_SENSOR_IDS));
  },

  getSiteMap(site) {
    return get(buildUrl(Endpoints.GET_SITE_MAP.replace(":site", site)), null, AuthToken.API, ParseStrategy.Image);
  },

  setSensorOrientation(sensor_id, orientation) {
    return post(buildUrl(Endpoints.SET_SENSOR_ORIENTATION), {sensor_id, orientation});
  },

  getSites(site_name) {
    return get(buildUrl(Endpoints.GET_SITES), {site_name});
  },

  uploadSiteMap(site, formData) {
    return upload(buildUrl(Endpoints.UPLOAD_SITE_MAP.replace(":siteName", site)), formData);
  },

  createSite(coordinates, address, display_name) {
    return post(buildUrl(Endpoints.CREATE_SITE), {coordinates, address, display_name});
  },

  updateSite(site_name, coordinates, address, display_name, notes = null) {
    return post(buildUrl(Endpoints.UPDATE_SITE), {site_name, coordinates, address, display_name, notes});
  },

  deleteSite(site_name) {
    return post(buildUrl(Endpoints.DELETE_SITE), {site_name});
  },

  updateSiteLocations(site_name, locations) {
    return post(buildUrl(Endpoints.UPDATE_SITE_LOCATIONS), {site_name, locations});
  },

  updateOwnerGatewayOwnership(gateways_to_allocate, owner_id, owner_type, gateways_to_unallocate) {
    return post(buildUrl(Endpoints.UPDATE_GATEWAY_OWNERSHIP), {
      gateways_to_allocate,
      owner_id,
      owner_type,
      gateways_to_unallocate,
    });
  },

  updateGateway(values) {
    return post(buildUrl(Endpoints.UPDATE_GATEWAY), {
      ...values,
    });
  },

  setSensorRPM(sensor_id, rpm) {
    return post(buildUrl(Endpoints.SET_SENSOR_RPM), {sensor_id, rpm});
  },

  updateUserPreferences(usernames, preferences) {
    return post(buildUrl(Endpoints.UPDATE_USER_PREFERENCES), {usernames, preferences});
  },

  setPowerBands(sensor_id, bands) {
    return post(buildUrl(Endpoints.SET_POWER_BANDS), {sensor_id, bands});
  },

  getPowerBands(sensor_id) {
    return get(buildUrl(Endpoints.GET_POWER_BANDS), {sensor_id});
  },

  getEvents(target, timestamp, timestamp_end) {
    return get(buildUrl(Endpoints.GET_EVENTS), {timestamp, timestamp_end, target});
  },

  getEvent(eventId) {
    return get(buildUrl(Endpoints.GET_EVENT.replace(":eventId", eventId)));
  },

  createEvent(name, description, timestamp, target, metadata) {
    return post(buildUrl(Endpoints.CREATE_EVENT), {name, description, timestamp, target, metadata});
  },

  updateEvent(event_id, name, description, metadata) {
    return post(buildUrl(Endpoints.UPDATE_EVENT), {event_id, name, description, metadata});
  },

  deleteEvent(event_id) {
    return post(buildUrl(Endpoints.DELETE_EVENT), {event_id});
  },

  deleteReport(name, version, company) {
    return post(buildUrl(Endpoints.DELETE_REPORT), {name, version, company});
  },

  getReports(company) {
    return get(buildUrl(Endpoints.GET_REPORT_LIST), {company});
  },

  getReport(name, version, company) {
    return get(buildUrl(Endpoints.GET_REPORT), {name, version, company});
  },

  saveReport(name, content, version, company) {
    return post(buildUrl(Endpoints.SAVE_REPORT), {name, content, version, company});
  },

  getMachine(site_name, machine_id) {
    return get(buildUrl(Endpoints.GET_MACHINES), {site_name, machine_id});
  },

  createMachine(name, site_name, machine_type_id, machine_subtype_id, machine_subtype_diagram_id) {
    return post(buildUrl(Endpoints.CREATE_MACHINE), {
      name,
      site_name,
      machine_type_id,
      machine_subtype_id,
      machine_subtype_diagram_id,
    });
  },

  deleteMachine(site_name, machine_id) {
    return post(buildUrl(Endpoints.DELETE_MACHINE), {machine_id, site_name});
  },

  updateMachine(
    site_name,
    machine_id,
    name,
    machine_type_id,
    machine_subtype_id,
    machine_subtype_diagram_id,
    update_bands,
    notes,
  ) {
    return post(buildUrl(Endpoints.UPDATE_MACHINE), {
      machine_id,
      site_name,
      name,
      machine_type_id,
      machine_subtype_id,
      machine_subtype_diagram_id,
      update_bands,
      notes,
    });
  },

  getMachines(site_name) {
    return get(buildUrl(Endpoints.GET_MACHINES), {site_name});
  },

  setSensorNotchFilter(sensor_id, notch_filter) {
    return post(buildUrl(Endpoints.SET_SENSOR_NOTCH_FILTER), {sensor_id, notch_filter});
  },

  updateSelf(password, new_password, email, phone, preferences) {
    return post(buildUrl(Endpoints.UPDATE_SELF), {password, new_password, email, phone, preferences});
  },

  sendPasswordResetEmail(email) {
    return post(buildUrl(Endpoints.SEND_PASSWORD_RESET_EMAIL), {email}, AuthToken.None);
  },

  resetPassword(token, password) {
    return post(buildUrl(Endpoints.RESET_PASSWORD), {token, password}, AuthToken.None);
  },

  uploadCompanyLogo(company, formData) {
    return upload(buildUrl(Endpoints.UPLOAD_COMPANY_LOGO.replace(":company", company)), formData);
  },

  uploadImage(site, name, width, height, formData) {
    return upload(buildUrl(Endpoints.UPLOAD_IMAGE.replace(":siteName", site)), formData, {name, width, height});
  },

  getImageList(site_name, name) {
    return get(buildUrl(Endpoints.GET_IMAGE_LIST), {site_name, name});
  },

  deleteImage(site, name, image_id) {
    return post(buildUrl(Endpoints.DELETE_IMAGE.replace(":siteName", site)), {name, image_id});
  },

  getHardwareFilterList(device_name) {
    return get(buildUrl(Endpoints.GET_HARDWARE_FILTER_LIST), {device_name});
  },

  updateHardwareFilterList(device_name) {
    return post(buildUrl(Endpoints.UPDATE_HARDWARE_FILTER_LIST), {device_name});
  },

  updateHardwareFilterListWithList(device_name, sensors) {
    return post(buildUrl(Endpoints.UPDATE_HARDWARE_FILTER_LIST_WITH_LIST), {device_name, sensors});
  },

  clearHardwareFilterList(device_name) {
    return post(buildUrl(Endpoints.CLEAR_HARDWARE_FILTER_LIST), {device_name});
  },

  updateSensorInfo(sensor_id, type, description) {
    return post(buildUrl(Endpoints.UPDATE_SENSOR_INFO), {sensor_id, type, description});
  },

  getDeviceDiagnostics(device_name) {
    return get(buildUrl(Endpoints.GET_DEVICE_DIAGNOSTICS), {device_name});
  },

  clearDeviceDiagnostics(device_name) {
    return post(buildUrl(Endpoints.CLEAR_DEVICE_DIAGNOSTICS), {device_name});
  },

  getTimeDomainKeyList(point_id, timestamp, timestamp_end, resolution, axis) {
    return get(buildUrl(Endpoints.GET_TIME_DOMAIN_KEY_LIST), {point_id, timestamp, timestamp_end, resolution, axis});
  },

  getLatestTimeDomainKey(point_id, resolution, axis) {
    return get(buildUrl(Endpoints.GET_LATEST_TIME_DOMAIN_KEY), {point_id, resolution, axis});
  },

  getTimeDomainSet(entry_id) {
    return get(buildUrl(Endpoints.GET_TIME_DOMAIN_SET), {entry_id});
  },

  getTimeDomainSetV2(entry_id, max_blocks) {
    return get(buildUrl(Endpoints.GET_TIME_DOMAIN_SET_V2), {entry_id, max_blocks});
  },

  getWaterfallMulti(point_id, timestamps, resolution, axis, count, rms) {
    return post(buildUrl(Endpoints.GET_WATERFALL_MULTI), {point_id, timestamps, resolution, axis, count, rms});
  },

  getWaterfall(point_id, timestamp, timestamp_end, resolution, axis, count, rms) {
    return get(buildUrl(Endpoints.GET_WATERFALL), {point_id, timestamp, timestamp_end, resolution, axis, count, rms});
  },

  updatePreferences(preferences) {
    return post(buildUrl(Endpoints.UPDATE_PREFERENCES), {preferences});
  },

  createMeasuringPoint(machine_id, site_name, type, name, device_name, sensor_id, step, position, channels) {
    return post(buildUrl(Endpoints.CREATE_MEASURING_POINT), {
      machine_id,
      site_name,
      type,
      name,
      device_name,
      sensor_id,
      step,
      position,
      channels,
    });
  },

  updateMeasuringPoint(
    point_id,
    machine_id,
    site_name,
    type,
    name,
    device_name,
    sensor_id,
    step,
    position,
    channels,
    notes,
  ) {
    return post(buildUrl(Endpoints.UPDATE_MEASURING_POINT), {
      point_id,
      machine_id,
      site_name,
      type,
      name,
      device_name,
      sensor_id,
      step,
      position,
      channels,
      notes,
    });
  },

  deleteMeasuringPoint(point_id) {
    return post(buildUrl(Endpoints.DELETE_MEASURING_POINT), {point_id});
  },

  updateMeasuringPointState(point_id, disabled) {
    return post(buildUrl(Endpoints.UPDATE_MEASURING_POINT_STATE), {point_id, disabled});
  },

  animateDeviceLED(device_name) {
    return post(buildUrl(Endpoints.ANIMATE_DEVICE_LED), {device_name});
  },

  getSessions() {
    return get(buildUrl(Endpoints.GET_ACTIVE_SESSIONS));
  },

  expireSession(token) {
    return post(buildUrl(Endpoints.EXPIRE_SESSION), {token});
  },

  getSessionsForUser(username, timestamp) {
    return get(buildUrl(Endpoints.GET_USER_SESSIONS), {username, timestamp});
  },

  expireSessionForUser(token) {
    return post(buildUrl(Endpoints.EXPIRE_USER_SESSION), {token});
  },

  getAllActiveSessions() {
    return get(buildUrl(Endpoints.GET_ALL_ACTIVE_SESSIONS));
  },

  getCurrentUser() {
    return get(buildUrl(Endpoints.GET_CURRENT_USER));
  },

  getDebugPoints() {
    return get(buildUrl(Endpoints.GET_DEBUG_POINTS));
  },

  setDebugPoints(point_ids) {
    return post(buildUrl(Endpoints.SET_DEBUG_POINTS), {point_ids});
  },

  replaceSensor(point_id, sensor_id, device_name, update_sw_filter, update_hw_filter, persist_empty) {
    return post(buildUrl(Endpoints.REPLACE_SENSOR), {
      point_id,
      sensor_id,
      device_name,
      update_sw_filter,
      update_hw_filter,
      persist_empty,
    });
  },

  getTemplates(type, company, template_id) {
    return get(buildUrl(Endpoints.GET_TEMPLATES), {type, company, template_id});
  },

  createTemplate(type, name, template, company, global) {
    return post(buildUrl(Endpoints.CREATE_TEMPLATE), {type, name, template, company, global});
  },

  deleteTemplate(type, template_id) {
    return post(buildUrl(Endpoints.DELETE_TEMPLATE), {type, template_id});
  },

  runTemplate(type, template) {
    return post(buildUrl(Endpoints.RUN_TEMPLATE), {type, template});
  },

  getTemplateSchema(type, full) {
    return get(buildUrl(Endpoints.GET_TEMPLATE_SCHEMA), {type, full});
  },

  updateTemplate(type, template_id, name, template) {
    return post(buildUrl(Endpoints.UPDATE_TEMPLATE), {type, template_id, name, template});
  },

  updateMachineMetadata(machine_id, metadata) {
    return post(buildUrl(Endpoints.UPDATE_MACHINE_METADATA), {machine_id, metadata});
  },

  updateMeasuringPointMetadata(point_id, metadata) {
    return post(buildUrl(Endpoints.UPDATE_MEASURING_POINT_METADATA), {point_id, metadata});
  },

  updateMachineLocations(machine_id, locations) {
    return post(buildUrl(Endpoints.UPDATE_MACHINE_LOCATIONS), {machine_id, locations});
  },

  getLatestCriticalityData(target) {
    return get(buildUrl(Endpoints.GET_LATEST_CRITICALITY_DATA), {target});
  },

  getHistoricalCriticalityData(target, timestamp, timestamp_end) {
    return get(buildUrl(Endpoints.GET_HISTORICAL_CRITICALITY_DATA), {target, timestamp, timestamp_end});
  },

  updateDeviceFrequency(device_name, frequency) {
    return post(buildUrl(Endpoints.UPDATE_DEVICE_FREQUENCY), {device_name, frequency});
  },

  updateGatewayRFProtocol(gateway_id, rf_protocol) {
    return post(buildUrl(Endpoints.GATEWAY_RF_PROTOCOL), {gateway_id, rf_protocol});
  },

  requestGatewayRFProtocol(gateway_id) {
    return get(buildUrl(Endpoints.GATEWAY_RF_PROTOCOL), {gateway_id});
  },

  restartDevice(device_name) {
    return post(buildUrl(Endpoints.RESTART_DEVICE), {device_name});
  },

  bulkUpdateAlarmLevels(site_name, targets, properties, percentage) {
    return post(buildUrl(Endpoints.BULK_UPDATE_ALARM_LEVELS), {site_name, targets, properties, percentage});
  },

  bulkUpdateAlarmNotifications(site_name, targets, user_add, user_remove, send_email, send_sms, properties) {
    return post(buildUrl(Endpoints.BULK_UPDATE_ALARM_NOTIFICATIONS), {
      site_name,
      targets,
      user_add,
      user_remove,
      send_email,
      send_sms,
      properties,
    });
  },

  bulkDeleteAlarms(site_name, targets, properties) {
    return post(buildUrl(Endpoints.BULK_DELETE_ALARMS), {site_name, targets, properties});
  },

  getLatestSiteCriticalities() {
    return get(buildUrl(Endpoints.GET_LATEST_SITE_CRITICALITIES));
  },

  getLatestSiteAlarmReports() {
    return get(buildUrl(Endpoints.GET_LATEST_SITE_ALARM_REPORTS));
  },

  getLatestAlarmReport(target, events) {
    return get(buildUrl(Endpoints.GET_LATEST_ALARM_REPORT), {target, events});
  },

  getAlarmReports(target, timestamp, timestamp_end, events) {
    return get(buildUrl(Endpoints.GET_ALARM_REPORTS), {target, timestamp, timestamp_end, events});
  },

  getAPIDocsData() {
    return get(buildUrl(Endpoints.GET_API_DOCS_DATA), null, AuthToken.API, ParseStrategy.Text);
  },

  resetMachinePowerBands(machine_id) {
    return post(buildUrl(Endpoints.RESET_MACHINE_POWER_BANDS), {machine_id});
  },

  createBearing(manufacturer, number, type, bpfi, bpfo, bsf, ftf, creator) {
    return post(buildUrl(Endpoints.CREATE_BEARING), {manufacturer, number, type, bpfi, bpfo, bsf, ftf, creator});
  },

  updateBearing(bearing_id, manufacturer, number, type, bpfi, bpfo, bsf, ftf) {
    return post(buildUrl(Endpoints.UPDATE_BEARING), {bearing_id, manufacturer, number, type, bpfi, bpfo, bsf, ftf});
  },

  deleteBearing(bearing_id) {
    return post(buildUrl(Endpoints.DELETE_BEARING), {bearing_id});
  },

  getBearing(bearing_id) {
    return get(buildUrl(Endpoints.GET_BEARING), {bearing_id});
  },

  getBearingList(number) {
    return get(buildUrl(Endpoints.SEARCH_BEARINGS_LIST), {number});
  },

  getApiKeys() {
    return get(buildUrl(Endpoints.GET_API_KEYS));
  },

  generateApiKey(name) {
    return post(buildUrl(Endpoints.GENERATE_API_KEY), {name});
  },

  revokeApiKey(api_key_id) {
    return post(buildUrl(Endpoints.REVOKE_API_KEY), {api_key_id});
  },

  getUserApiKeys(username) {
    return get(buildUrl(Endpoints.GET_USER_API_KEYS), {username});
  },

  revokeUserApiKey(username, api_key_id) {
    return post(buildUrl(Endpoints.REVOKE_USER_API_KEY), {username, api_key_id});
  },

  getLatestThermalImage(point_id) {
    return get(buildUrl(Endpoints.GET_LATEST_THERMAL_IMAGE), {point_id});
  },

  getHistoricalThermalImage(point_id, timestamp) {
    return get(buildUrl(Endpoints.GET_HISTORICAL_THERMAL_IMAGE), {point_id, timestamp});
  },

  getMachineUptimeEvents(machine_id, timestamp, timestamp_end) {
    return get(buildUrl(Endpoints.GET_MACHINE_UPTIME_EVENTS), {machine_id, timestamp, timestamp_end});
  },

  getMachinesPointThermal(point_id) {
    return get(buildUrl(Endpoints.GET_MACHINES_POINT_THERMAL), {point_id});
  },

  updateMachinesPointThermalBoxes(sensor_id, point_id, boxes) {
    return post(buildUrl(Endpoints.UPDATE_MACHINES_POINT_THERMAL_BOXES), {sensor_id, point_id, boxes});
  },

  sendWelcomeEmail(username, welcome_email_type = WelcomeEmailTypes.WELCOME_EMAIL) {
    return post(buildUrl(Endpoints.SEND_WELCOME_EMAIL), {username, welcome_email_type});
  },

  getCompanyList() {
    return get(buildUrl(Endpoints.COMPANY_LIST), {full: true});
  },

  createCompany(display_name, site_names) {
    return post(buildUrl(Endpoints.CREATE_COMPANY), {display_name, site_names});
  },

  updateCompany(company_id, display_name, site_names, chi_viewing_access, gateway_names) {
    return post(buildUrl(Endpoints.UPDATE_COMPANY), {
      company_id,
      display_name,
      site_names,
      chi_viewing_access,
      gateway_names,
    });
  },

  deleteCompany(company_id) {
    return post(buildUrl(Endpoints.DELETE_COMPANY), {company_id});
  },

  getPartnerList() {
    return get(buildUrl(Endpoints.GET_PARTNERS));
  },

  createPartner(sites, primary_company, companies, display_name) {
    return post(buildUrl(Endpoints.CREATE_PARTNER), {sites, primary_company, companies, display_name});
  },

  updatePartner(partner_group, sites, companies, display_name) {
    return post(buildUrl(Endpoints.UPDATE_PARTNER), {partner_group, sites, companies, display_name});
  },

  deletePartner(partner_group) {
    return post(buildUrl(Endpoints.DELETE_PARTNER), {partner_group});
  },

  createAssetLabel(asset_label) {
    return post(buildUrl(Endpoints.CREATE_ASSET_LABEL), {asset_label});
  },

  createChiEvent(asset_label, timestamp, name, metadata) {
    return post(buildUrl(Endpoints.CREATE_CHI_EVENT), {asset_label, timestamp, name, metadata});
  },

  uploadChiData(event_id, data, sensor_id, timestamp, sampling_frequency, fft_size, scale, position) {
    return post(buildUrl(Endpoints.UPLOAD_CHI_DATA), {
      event_id,
      sensor_id,
      timestamp,
      position,
      sampling_frequency,
      fft_size,
      scale,
      data,
    });
  },

  getLatestChiSessions(company_id) {
    return get(buildUrl(Endpoints.GET_LATEST_CHI_SESSIONS), {company_id, count: 100});
  },

  getLatestChiSessionsForAsset(asset_label, company_id) {
    return get(buildUrl(Endpoints.GET_LATEST_CHI_SESSIONS_FOR_ASSET), {asset_label, company_id, count: 100});
  },

  getChiSessions(timestamp, timestamp_end, company_id) {
    return get(buildUrl(Endpoints.GET_CHI_SESSIONS), {company_id, timestamp, timestamp_end});
  },

  getChiSessionsForAsset(asset_label, timestamp, timestamp_end, company_id) {
    return get(buildUrl(Endpoints.GET_CHI_SESSIONS_FOR_ASSET), {asset_label, company_id, timestamp, timestamp_end});
  },

  getChiDataList(event_id) {
    return get(buildUrl(Endpoints.GET_CHI_DATA_LIST), {event_id});
  },

  getChiDataSet(data_id) {
    return get(buildUrl(Endpoints.GET_CHI_DATA_SET), {data_id});
  },

  updateChiSession(event_id, name) {
    return post(buildUrl(Endpoints.UPDATE_CHI_SESSION), {event_id, name});
  },

  deleteChiSession(event_id) {
    return post(buildUrl(Endpoints.DELETE_CHI_SESSION), {event_id});
  },

  verifyMfa(mfa) {
    return post(buildUrl(Endpoints.VERIFY_MFA), {mfa});
  },

  getMfaQrCode() {
    return get(buildUrl(Endpoints.GET_MFA_CODE));
  },

  disableMFA() {
    return post(buildUrl(Endpoints.DISABLE_MFA));
  },

  getAllMachineTypes() {
    return get(buildUrl(Endpoints.GET_ALL_MACHINE_TYPES));
  },

  getMachineType(machine_type_id) {
    return get(buildUrl(Endpoints.GET_MACHINE_TYPE), {machine_type_id});
  },

  getMachineTypesByCategory(category, parent_id) {
    return get(buildUrl(Endpoints.GET_MACHINE_TYPES_BY_CATEGORY), {category, parent_id});
  },

  createMachineType(display_name, description, machine_base) {
    return post(buildUrl(Endpoints.CREATE_MACHINE_TYPE), {display_name, description, machine_base});
  },

  createMachineSubtype(parent_id, display_name, description, oa_levels) {
    return post(buildUrl(Endpoints.CREATE_MACHINE_SUBTYPE), {parent_id, display_name, description, oa_levels});
  },

  createMachineSubtypeDiagram(parent_id, display_name, description, coordinates) {
    return post(buildUrl(Endpoints.CREATE_MACHINE_SUBTYPE_DIAGRAM), {
      parent_id,
      display_name,
      description,
      coordinates,
    });
  },

  updateMachineType(machine_type_id, display_name, description, machine_base, oa_levels, coordinates) {
    return post(buildUrl(Endpoints.UPDATE_MACHINE_TYPE), {
      machine_type_id,
      display_name,
      description,
      machine_base,
      oa_levels,
      coordinates,
    });
  },

  deleteMachineType(machine_type_id) {
    return post(buildUrl(Endpoints.DELETE_MACHINE_TYPE), {machine_type_id});
  },

  getMachineTypeDiagram(machine_type_id) {
    return get(buildUrl(Endpoints.GET_MACHINE_TYPE_DIAGRAM), {machine_type_id});
  },

  uploadMachineTypeDiagram(machine_type_id, formData) {
    return upload(buildUrl(Endpoints.UPLOAD_MACHINE_TYPE_DIAGRAM.replace(":machineTypeId", machine_type_id)), formData);
  },
};

function addAuthToken(headers = {}, token) {
  if (!token) {
    throw "No auth token has been configured";
  } else {
    headers["Authorization"] = `Bearer ${token}`;
  }
}

function addHeaders(headers, authToken) {
  if (authToken === AuthToken.API) {
    addAuthToken(headers, apiAuthToken);
  }
}

function parseJSON(response) {
  return response.json();
}

function parseText(response) {
  return response.text();
}

function parseImage(response) {
  return response.blob().then((blob) => {
    return blob.size === 0 ? null : URL.createObjectURL(blob);
  });
}

function get(url, data, authToken = AuthToken.API, parseStrategy = ParseStrategy.JSON) {
  if (data) {
    url += `?${QueryString.stringify(data)}`;
  }
  let headers = {};
  addHeaders(headers, authToken);
  return handleRequest(
    fetch(url, {
      headers,
    }),
    parseStrategy,
  );
}

function post(url, data, authToken = AuthToken.API) {
  let headers = {
    "Content-Type": "application/json",
  };
  addHeaders(headers, authToken);
  return handleRequest(
    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }),
  );
}

function upload(url, data, params, authToken = AuthToken.API) {
  if (params) {
    url += `?${QueryString.stringify(params)}`;
  }
  let headers = {};
  addHeaders(headers, authToken);
  return handleRequest(
    fetch(url, {
      method: "POST",
      headers,
      body: data,
    }),
  );
}

async function handleRequest(request, parseStrategy = ParseStrategy.JSON) {
  let response;
  try {
    response = await request;
  } catch (error) {
    // Any error during communication
    throw "Something went wrong trying to contact the server";
  }

  // Handle bad HTTP error codes
  const valid = response.status >= 200 && response.status < 300;
  if (!valid) {
    // Handle generic 400 or 500
    if (response.status === 500) {
      throw "There was a server error";
    }

    // Handle 401 logout
    if (response.status === 401) {
      onLogOut("Your session has expired, please log in again");

      // Block notifications for other pending requests and to hide errors
      blockNotifications();
      return;
    }

    // Unauthorised responses
    // Parse response
    const body = await parseJSON(response);

    // Force log out if required
    if (body.force_logout) {
      onLogOut("You were logged out, please log in again");

      // Block notifications for other pending requests
      blockNotifications();
      throw null;
    } else if (body.code) {
      throw body.message;
    } else {
      throw body.error;
    }
  }

  // Valid response, parse response
  if (parseStrategy === ParseStrategy.JSON) {
    return await parseJSON(response);
  } else if (parseStrategy === ParseStrategy.Image) {
    return await parseImage(response);
  } else if (parseStrategy === ParseStrategy.Text) {
    return await parseText(response);
  } else {
    throw "A valid API response parsing strategy must be specified.";
  }
}

export default Api;
