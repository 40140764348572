import {observer} from "mobx-react";
import {useEffect, useMemo, useRef, useState} from "react";
import ReportEditTab from "routes/reports/edit-tab/ReportEditTab";
import {Button, Form, Select} from "sensoteq-react-core/forms";
import {useRootStore} from "stores";
import ReportsPageRoutes from "routes/reports/reports-routes";
import * as Enums from "constants/enums";
import ReportStore from "stores/ReportStore";
import UIStore from "stores/UIStore";
import CompanyStore from "stores/CompanyStore";
import UserStore from "stores/UserStore";
import RouterStore from "stores/RouterStore";

export const ReportSessionStorageEnum = {
  NAME: "dockedReportName",
  VERSION: "dockedReportVersion",
};

type FormApi = {
  setValue: (key: string, value: string | null) => void;
};
const ReportDock = observer(() => {
  const {companyStore, reportStore, routerStore, uiStore, userStore} = useRootStore() as unknown as {
    companyStore: CompanyStore;
    reportStore: ReportStore;
    routerStore: RouterStore;
    uiStore: UIStore;
    userStore: UserStore;
  };
  const reportDockRef = useRef<HTMLDivElement | null>(null);
  const formAPI = useRef<FormApi | null>();
  const [selectedReport, setSelectedReport] = useState(() => {
    const name = sessionStorage.getItem(ReportSessionStorageEnum.NAME);
    const version = sessionStorage.getItem(ReportSessionStorageEnum.VERSION);
    if (!name || !version) return null;
    return {
      name,
      version,
    };
  });
  const [disableAutosave, setDisableAutosave] = useState(false);
  const [createNewReport, setCreateNewReport] = useState(false);

  useEffect(() => {
    // Update ARIA attributes to reflect panel's state
    reportDockRef.current?.setAttribute("aria-hidden", `${!uiStore.showDockedReportEditor}`);

    if (uiStore.showDockedReportEditor) {
      reportDockRef.current?.classList.replace("w-0", "w-[max(80%,410px)]");
      reportDockRef.current?.classList.replace("invisible", "visible");
      reportDockRef.current?.classList.replace("min-w-0", "min-w-[410px]");
    } else {
      reportDockRef.current?.classList.replace("w-[max(80%,410px)]", "w-0");
      reportDockRef.current?.classList.replace("visible", "invisible");
      reportDockRef.current?.classList.replace("min-w-[410px]", "min-w-0");
    }
  }, [uiStore.showDockedReportEditor]);

  useEffect(() => {
    if (userStore.admin || userStore.userRole === Enums.USER_ROLE_PARTNER) {
      companyStore.loadCompanies();
    }

    reportStore.getReports(reportStore.companyId);
  }, [companyStore, reportStore, selectedReport, userStore.admin, userStore.userRole]);

  useEffect(() => {
    if (selectedReport) {
      sessionStorage.setItem(ReportSessionStorageEnum.NAME, selectedReport.name);
      sessionStorage.setItem(ReportSessionStorageEnum.VERSION, selectedReport.version);
    }
  }, [selectedReport]);

  const reports = useMemo(() => {
    return reportStore.reports
      .toSorted((a, b) => b.last_updated - a.last_updated)
      .map((report) => ({
        label: report.display_name,
        value: JSON.stringify({name: report.name, version: report.version}),
      }));
  }, [reportStore.reports]);

  useEffect(() => {
    if (!formAPI.current) return;
    if (!selectedReport) formAPI.current.setValue("report", null);
    formAPI.current.setValue(
      "report",
      selectedReport ? JSON.stringify({name: selectedReport.name, version: selectedReport.version}) : null,
    );
  }, [selectedReport]);

  const selectReport = (reportDetails: string) => {
    if (!reportDetails) return;
    const _reportDetails = JSON.parse(reportDetails) as {name: string; version: string};
    const report = reportStore.reports.find((report) => report.name === _reportDetails.name);
    if (!report) return;
    setSelectedReport(report);
  };

  const onCreateNewReport = () => {
    setCreateNewReport(true);
  };

  const deleteReport = () => {
    if (selectedReport?.name && selectedReport.version) {
      setDisableAutosave(true);
      reportStore.deleteReport(selectedReport.name, selectedReport.version, reportStore.companyId);
      setDisableAutosave(false);
      setSelectedReport(null);
    }
  };

  const openInReportEditor = () => {
    if (selectedReport?.name && selectedReport.version) {
      routerStore.push(
        routerStore.createUrl(
          ReportsPageRoutes.ReportEditTab,
          {name: selectedReport.name, version: selectedReport.version},
          {companyId: reportStore.companyId},
        ),
      );
    }
  };

  return (
    <div
      ref={reportDockRef}
      style={{transition: "width ease 0.5s, visibility 0.5s ease-out 0.5s, visibility 0s ease-in 0s"}}
      className={`
        relative
        flex
        flex-col
        flex-nowrap
        h-full
        !border-t-2
        !border-l-2
        border-t-content-background-alt
        border-l-content-background-alt
        bg-content-background
        invisible
        min-w-0
        w-0
        z-[1000]
      `}
      aria-hidden="true"
    >
      <div className="flex flex-row flex-nowrap p-2">
        <div className="flex-auto max-w-[50%] flex flex-row flex-nowrap [&>:first-child]:w-auto">
          <Form
            initialValues={{
              report: selectedReport
                ? JSON.stringify({name: selectedReport.name, version: selectedReport.version})
                : null,
            }}
            getApi={(api: FormApi) => (formAPI.current = api)}
            className="w-full"
            style={{width: "100%"}}
          >
            <Select
              field="report"
              options={reports}
              onChange={selectReport}
              placeholder="Select a report to open..."
            />
          </Form>
        </div>
        <div className="flex-auto justify-end flex flex-row flex-nowrap [&>*:not(:last-child)]:mr-2">
          <Button
            theme={Button.Themes.Good}
            icon="note_add"
            tooltip="Create New Report"
            confirmation="Are you sure you want to create a new report?"
            onClick={onCreateNewReport}
            disabled={!selectedReport}
          />
          <Button
            theme={Button.Themes.Bad}
            icon="delete_forever"
            tooltip="Delete Current Report"
            confirmation="Are you sure you want to delete this report?"
            onClick={deleteReport}
            disabled={!selectedReport}
          />
          <Button
            theme={Button.Themes.Info}
            icon="open_in_new"
            tooltip="Open in Reports"
            disabled={!selectedReport}
            onClick={openInReportEditor}
          />
        </div>
      </div>
      <ReportEditTab
        popoverEditor
        reportName={selectedReport?.name}
        reportVersion={selectedReport?.version}
        setSelectedReport={setSelectedReport}
        disableAutosave={disableAutosave}
        createNewReport={createNewReport}
        setCreateNewReport={setCreateNewReport}
      />
    </div>
  );
});

export {ReportDock};
