import {observable, flow, computed} from "mobx";

import LoadableStore from "sensoteq-react-core/models/LoadableStore";
import Api from "sensoteq-react-core/services/api";
import {sortArrayByProperty} from "sensoteq-react-core/services/utils";
import {MachineTypeCategory} from "sensoteq-core/machines/machine-types";
import {validateStepPositions} from "sensoteq-core/measuring-points/measuring-point-positions";
import {MachineTypeOptions} from "../constants/enums";
import {capitalise} from "../services/Utils";

export default class MachineTypeStore extends LoadableStore {
  @observable.shallow _machineTypes;

  constructor(rootStore) {
    super(rootStore);
  }

  @computed get allMachineTypes() {
    if (!this._machineTypes) return [];
    return sortArrayByProperty(this._machineTypes.slice(), "display_name");
  }

  @computed get machineTypes() {
    if (!this.allMachineTypes) return [];
    return this.allMachineTypes.filter(({category}) => category === MachineTypeCategory.TYPE);
  }

  @computed get machineSubtypes() {
    if (this.allMachineTypes.length === 0) return [];
    return this.allMachineTypes.filter(({category}) => category === MachineTypeCategory.SUBTYPE);
  }

  @computed get machineSubtypeDiagrams() {
    if (this.allMachineTypes.length === 0) return [];
    return this.allMachineTypes.filter(({category}) => category === MachineTypeCategory.SUBTYPE_DIAGRAM);
  }

  @computed get typeToSubtypesMap() {
    const typeToSubtypesMap = new Map();
    for (const subtype of this.machineSubtypes) {
      const typeMapEntry = typeToSubtypesMap.get(subtype.parent_id) ?? [];
      typeToSubtypesMap.set(subtype.parent_id, [...typeMapEntry, subtype]);
    }

    return Object.fromEntries(typeToSubtypesMap);
  }

  @computed get subtypeToSubtypeDiagramsMap() {
    const subtypeToSubtypeDiagramsMap = new Map();
    for (const subtypeDiagram of this.machineSubtypeDiagrams) {
      const subtypeMapEntry = subtypeToSubtypeDiagramsMap.get(subtypeDiagram.parent_id) ?? [];
      subtypeToSubtypeDiagramsMap.set(subtypeDiagram.parent_id, [...subtypeMapEntry, subtypeDiagram]);
    }

    return Object.fromEntries(subtypeToSubtypeDiagramsMap);
  }

  @computed get _reverseLookupMap() {
    const lookupMap = new Map();
    for (const machineType of this.allMachineTypes) {
      lookupMap.set(machineType.machine_type_id, machineType);
    }

    return Object.fromEntries(lookupMap);
  }

  getMachineType(machineTypeID) {
    return this._reverseLookupMap[machineTypeID];
  }

  loadAllMachineTypes = flow(async function* () {
    this.startLoading();
    try {
      this._machineTypes = yield Api.getAllMachineTypes();
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting machine types: ${error}`, "bad");
    } finally {
      this.stopLoading();
    }
  });

  loadMachineTypes = flow(async function* () {
    this.startLoading();
    try {
      const machineTypes = yield Api.getMachineTypesByCategory(MachineTypeCategory.TYPE);
      const currentMachineTypes = this._machineTypes ?? [];

      for (const machineType of machineTypes) {
        const foundAtIndex = currentMachineTypes.findIndex(
          (type) => type.machine_type_id === machineType.machine_type_id,
        );

        if (foundAtIndex === -1) {
          currentMachineTypes.push(machineType);
        } else {
          currentMachineTypes.splice(foundAtIndex, 1, machineType);
        }
      }

      this._machineTypes = currentMachineTypes;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting machine types: ${error}`, "bad");
    } finally {
      this.stopLoading();
    }
  });

  getMachineTypeByID = flow(async function* (machineTypeID, noCache = false) {
    this.startLoading();
    try {
      if (noCache === false) {
        const machineType = this._reverseLookupMap[machineTypeID];
        if (machineType != null) return machineType;
      }

      const machineType = yield Api.getMachineType(machineTypeID);
      const currentMachineTypes = this._machineTypes ?? [];
      const foundAtIndex = currentMachineTypes.findIndex(
        (type) => type.machine_type_id === machineType.machine_type_id,
      );

      if (foundAtIndex === -1) {
        currentMachineTypes.push(machineType);
      } else {
        currentMachineTypes.splice(foundAtIndex, 1, machineType);
      }

      this._machineTypes = currentMachineTypes;
      return machineType;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting machine type: ${error}`, "bad");
    } finally {
      this.stopLoading();
    }
  });

  createMachineType = flow(function* (typeOption, name, description, machine_base, parent_id, oaLevels, coordinates) {
    this.startLoading();
    try {
      let response;
      switch (typeOption) {
        case MachineTypeOptions.TYPE:
          response = yield Api.createMachineType(name, description, machine_base);
          break;
        case MachineTypeOptions.SUBTYPE:
          response = yield Api.createMachineSubtype(parent_id, name, description, oaLevels);
          break;
        case MachineTypeOptions.SUBTYPE_DIAGRAM:
          response = yield Api.createMachineSubtypeDiagram(parent_id, name, description, coordinates);
          break;
      }
      this.loadAllMachineTypes();
      this.rootStore.notificationStore.addNotification(`${capitalise(typeOption)} created successfully`, "good");
      return response.result.machine_type_id;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error creating ${typeOption ?? "type"}: ${error}`, "bad");
    } finally {
      this.stopLoading();
    }
  });

  updateMachineType = flow(function* (id, name, description, machine_base, oaLevels, coordinates) {
    this.startLoading();
    try {
      yield Api.updateMachineType(id, name, description, machine_base, oaLevels, coordinates);
      this.loadAllMachineTypes();
      this.rootStore.notificationStore.addNotification("Machine type updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating machine type: ${error}`, "bad");
    }
    this.stopLoading();
  });

  deleteMachineType = flow(function* (id) {
    this.startLoading();
    try {
      yield Api.deleteMachineType(id);
      this.loadAllMachineTypes();
      this.rootStore.notificationStore.addNotification("Machine type deleted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting machine type: ${error}`, "bad");
    }
    this.stopLoading();
  });

  getMachineTypeDiagram = flow(function* (id) {
    this.startLoading();
    try {
      return yield Api.getMachineTypeDiagram(id);
    } catch (error) {
      return null;
    } finally {
      this.stopLoading();
    }
  });

  uploadMachineTypeDiagram = flow(function* (id, file) {
    try {
      const imageFile = new File([file], `${id}`);
      const formData = new FormData();
      formData.append("image", imageFile);
      yield Api.uploadMachineTypeDiagram(id, formData);

      this.rootStore.notificationStore.addNotification("Image uploaded successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error uploading image: ${error}`, "bad");
    }
  });

  getMachineTypeIDHierarchy(machineTypeID) {
    const hierarchy = {
      machineTypeID: null,
      machineSubtypeID: null,
      machineSubtypeDiagramID: null,
    };
    return this.getParentMachineTypeID(machineTypeID, hierarchy);
  }

  getParentMachineTypeID(
    machineTypeID,
    ids = {machineTypeID: null, machineSubtypeID: null, machineSubtypeDiagramID: null},
  ) {
    const machineType = this._reverseLookupMap[machineTypeID];
    const {machine_type_id, parent_id} = machineType;

    if (parent_id == null) {
      ids.machineTypeID = machine_type_id;
      return ids;
    }

    if (machineType.category === MachineTypeCategory.SUBTYPE) ids.machineSubtypeID = machine_type_id;
    if (machineType.category === MachineTypeCategory.SUBTYPE_DIAGRAM) ids.machineSubtypeDiagramID = machine_type_id;
    return this.getParentMachineTypeID(parent_id, ids);
  }

  getMachineTypeBase(typeID) {
    const {machineTypeID} = this.getMachineTypeIDHierarchy(typeID);
    const {machine_base} = this.getMachineType(machineTypeID);
    return machine_base;
  }

  validateAllSubtypeDiagramPositions(parentMachineTypeID, machineBase) {
    const machineType = this.getMachineType(parentMachineTypeID);
    if (machineType == null || machineType.category != MachineTypeCategory.TYPE) {
      throw new Error(
        `Error validating subtype diagram positions. Supplied ID could not be found or is not a machine type`,
      );
    }

    const childSubtypes = this.typeToSubtypesMap[parentMachineTypeID];
    if (childSubtypes == null) return [];

    return childSubtypes.reduce((invalidPositionEntries, subtype) => {
      const subtypeDiagrams = this.subtypeToSubtypeDiagramsMap[subtype.machine_type_id];
      if (subtypeDiagrams == null) return invalidPositionEntries;

      for (const subtypeDiagram of subtypeDiagrams) {
        if (subtypeDiagram.coordinates == null) continue;

        for (const [step, positions] of Object.entries(subtypeDiagram.coordinates)) {
          const {valid, invalidPositions} = validateStepPositions(machineBase, step, Object.keys(positions));
          if (valid) continue;

          for (const invalidPosition of invalidPositions) {
            invalidPositionEntries.push({
              displayName: subtypeDiagram.display_name,
              step,
              invalidPosition,
            });
          }
        }
      }

      return invalidPositionEntries;
    }, []);
  }
}
