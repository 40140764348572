import {observable, computed, flow} from "mobx";

import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import UserDataSubscription from "models/UserDataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class AdminUserStore extends SubscribableStore {
  @observable.shallow _users;

  constructor(rootStore) {
    super(rootStore, UserDataSubscription);
  }

  @computed get loading() {
    return !!this._loadingCount || this.rootStore.companyStore.loading;
  }

  @computed get initialLoad() {
    return this._initialLoad || this.rootStore.companyStore.initialLoad;
  }

  @computed get users() {
    if (this.rootStore.companyStore.initialLoad) {
      return [];
    }
    return (this._users || [])
      .map((x) => ({
        ...x,
        company: x.company ? this.rootStore.companyStore.getCompany(x?.company) : {},
      }))
      .sort((a, b) => {
        if (a.company?.company_id === b.company?.company_id) {
          if (a.username === b.username) {
            return 0;
          }
          return a.username > b.username ? 1 : -1;
        }
        return a.company?.company_id > b.company?.company_id ? 1 : -1;
      });
  }

  @computed get usernames() {
    return this.users.map((x) => x.username);
  }

  retrieve = flow(function* (username) {
    const data = yield Api.getUserDetails(username);
    const index = this._users.findIndex((x) => x.username === username);
    if (index !== -1) {
      this._users[index] = data.user;
    }
    return data.user;
  });

  loadUsers = flow(function* () {
    this.startLoading();
    try {
      const users = yield Api.getUserList();
      this._users = users.users;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting users: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateUser = flow(function* (user) {
    this.startLoading();
    try {
      yield Api.updateUser(user);
      yield this.sync(user.username);
      if (user.username === this.rootStore.userStore.username) {
        yield this.rootStore.userStore.refreshSelf();
      }
      this.rootStore.notificationStore.addNotification("User updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating user: ${error}`, "bad");
    }
    this.stopLoading();
  });

  createUser = flow(function* (user) {
    this.startLoading();
    try {
      yield Api.createUser(user);
      const data = yield Api.getUserDetails(user.username);
      this._users.push(data.user);
      this.rootStore.notificationStore.addNotification("User created successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error creating user: ${error}`, "bad");
    }
    this.stopLoading();
  });

  deleteUser = flow(function* (username) {
    this.startLoading();
    try {
      yield Api.deleteUser(username);
      const index = this._users.findIndex((x) => x.username === username);
      this._users.splice(index, 1);
      this.rootStore.notificationStore.addNotification("User deleted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting user: ${error}`, "bad");
    }
    this.stopLoading();
  });

  unlockUser = flow(function* (username) {
    this.startLoading();
    try {
      yield Api.unlockUser(username);
      this.rootStore.notificationStore.addNotification("User account unlocked", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error unlocking user account: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateUserPreferences = flow(function* (username, preferences) {
    this.startLoading();
    try {
      yield Api.updateUserPreferences([username], preferences);
      yield this.sync(username);
      if (username === this.rootStore.userStore.username) {
        yield this.rootStore.userStore.refreshSelf();
      }
      this.rootStore.notificationStore.addNotification("Preferences updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating preferences: ${error}`, "bad");
    }
    this.stopLoading();
  });

  sendWelcomeEmail = flow(function* (username, welcomeEmailType) {
    this.startLoading();
    try {
      yield Api.sendWelcomeEmail(username, welcomeEmailType);
      this.rootStore.notificationStore.addNotification("Welcome email sent successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error sending welcome email: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
