import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import StatusPopup from "../../../apps/analytix/src/components/common/StatusPopup";
import Label from "../forms/Label";

function ItemList(props) {
  const {items, label, noItemsText, onDelete, allowDeletion, style} = props;

  return (
    <div>
      {label && <Label>{label}</Label>}
      {(!items || items.length === 0) && noItemsText && (
        <StatusPopup theme={StatusPopup.Themes.INFO}>{noItemsText}</StatusPopup>
      )}
      {items && items.length > 0 && (
        <ItemContainer style={style}>
          {items.map((item, idx) => {
            return (
              <Item key={idx}>
                <ItemText>{item}</ItemText>
                {allowDeletion && (
                  <i
                    onClick={() => onDelete(item, idx)}
                    className="material-icons"
                  >
                    close
                  </i>
                )}
              </Item>
            );
          })}
        </ItemContainer>
      )}
    </div>
  );
}

ItemList.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
  noItemsText: PropTypes.string,
  onDelete: PropTypes.func,
  allowDeletion: PropTypes.bool,
};
ItemList.defaultProps = {
  noItemsText: "No items found!",
  onDelete: () => {},
  allowDeletion: false,
};

export default ItemList;

const ItemContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  border-radius: ${(props) => props.theme.forms.borderRadius};
  overflow: hidden;
  border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
`;

const Item = styled.li`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: ${(props) => props.theme.forms.padding};

  i {
    flex: 0 1 auto;
    font-size: 2rem !important;
    margin-left: 0.8rem;
    margin-top: -0.4rem;
    margin-bottom: -0.4rem;

    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colours.primaryHighlight};
    }
  }

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colours.contentBackgroundAlt};
  }
`;

const ItemText = styled.div`
  flex: 1 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 0;
`;
