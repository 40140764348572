import {computed} from "mobx";

import {displacementToAcceleration} from "services/MathService";
import MultiPointSubscription from "./MultiPointSubscription";

export default class MultiPointDataSubscription extends MultiPointSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      getSubscribableStore: (x) => x.pointDataStore,
      getAverageType: () => this.rootStore.uiStore.continuousDataConfig.averageType,
    };
  }

  @computed get dateDisplayFn() {
    const validSub = this.subscriptions.find((x) => x != null);
    return validSub ? validSub.dateDisplayFn : (x) => x;
  }

  extractData(getData, transformValue, isLogging) {
    let data = [];
    this.subscriptions.forEach((sub, idx) => {
      const subData = getData(sub);
      if (subData != null) {
        let set = (sub.dataAveraged ? subData[this.params.getAverageType()] : subData[0])?.data.slice() ?? [];
        if (transformValue) {
          set = set.map((x) => transformValue(x, idx));
        }
        data.push({
          title: sub._pointId,
          position: "Unknown",
          data: set,
        });
      }
    });
    const enrichedTitleData = this.enrichTitles(data);
    return this.enrichPositions(enrichedTitleData);
  }

  @computed get dataAveraged() {
    return this.subscriptions.some((x) => x.dataAveraged);
  }

  @computed get temperatureData() {
    return this.extractData((x) => x.temperatureData);
  }

  @computed get deltaTemperatureData() {
    return this.extractData((x) => x.deltaTemperatureData);
  }

  @computed get humidityData() {
    return this.extractData((x) => x.humidityData);
  }

  @computed get ambientTemperatureData() {
    return this.extractData((x) => x.ambientTemperatureData);
  }

  @computed get voltageData() {
    return this.extractData((x) => x.voltageData);
  }

  @computed get validityData() {
    return this.extractData((x) => x.validityData);
  }

  @computed get rssiData() {
    return this.extractData((x) => x.rssiData);
  }

  @computed get tickCountData() {
    return this.extractData((x) => x.tickCountData);
  }

  @computed get accelerationRMSData() {
    return this.extractData((x) => x.accelerationRMSData);
  }

  @computed get velocityRMSData() {
    return this.extractData((x) => x.velocityRMSData);
  }

  @computed get totalAccelerationRMSData() {
    return this.extractData((x) => x.totalAccelerationRMSData);
  }

  @computed get ellipseRpmData() {
    return this.extractData((x) => x.ellipseRpmData, null, true);
  }

  @computed get ellipseStrokeLengthData() {
    return this.extractData((x) => x.ellipseStrokeLengthData);
  }

  @computed get ellipseStrokeLengthAndRPMData() {
    return this.extractData((x) => x.ellipseStrokeLengthAndRPMData);
  }

  @computed get ellipseStrokeAngleData() {
    return this.extractData((x) => x.ellipseStrokeAngleData);
  }

  @computed get ellipseMainGData() {
    let data = [];
    for (let i = 0; i < this.ellipseRpmData.length; i++) {
      const rpmSet = this.ellipseRpmData[i];
      const amplitudeSet = this.ellipseStrokeLengthData[i];
      let newSet = [];
      for (let x = 0; x < rpmSet.data.length; x++) {
        newSet.push({
          date: rpmSet.data[x].date,
          value: displacementToAcceleration(amplitudeSet?.data[x]?.value, rpmSet?.data[x]?.value / 60),
        });
      }
      data.push({
        title: rpmSet.title,
        data: newSet,
      });
    }
    return data;
  }

  @computed get ellipseXDisplacementData() {
    return this.extractData((x) => x.ellipseXDisplacementData);
  }
  @computed get ellipseXVelocityData() {
    return this.extractData((x) => x.ellipseXVelocityData);
  }
  @computed get ellipseXAccelerationData() {
    return this.extractData((x) => x.ellipseXAccelerationData);
  }
  @computed get ellipseYDisplacementData() {
    return this.extractData((x) => x.ellipseYDisplacementData);
  }
  @computed get ellipseYVelocityData() {
    return this.extractData((x) => x.ellipseYVelocityData);
  }
  @computed get ellipseYAccelerationData() {
    return this.extractData((x) => x.ellipseYAccelerationData);
  }
  @computed get ellipseZDisplacementData() {
    return this.extractData((x) => x.ellipseZDisplacementData);
  }
  @computed get ellipseZVelocityData() {
    return this.extractData((x) => x.ellipseZVelocityData);
  }
  @computed get ellipseZAccelerationData() {
    return this.extractData((x) => x.ellipseZAccelerationData);
  }
  @computed get peakToPeakData() {
    return this.extractData((x) => x.peakToPeakData);
  }
}
