import type {ReactNode} from "react";
import {Switch} from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter((c) => c).join(" ");
}

export interface ToggleProps {
  label: string | ReactNode;
  isOn: boolean;
  onChange: (state: boolean) => void;
  title?: string | undefined;
  disabled?: boolean;
}
export const Toggle = ({label, isOn, onChange, title, disabled = false}: ToggleProps) => {
  return (
    <Switch.Group
      as="div"
      className="flex items-center"
      title={title}
    >
      <Switch
        checked={isOn}
        onChange={disabled ? () => {} : onChange}
        className={classNames(
          "relative inline-flex p-0 h-[1.4rem] w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-good focus:ring-offset-2",
          isOn ? "!bg-good" : "!bg-gray-200",
          disabled ? "!opacity-80" : "",
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            "pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            isOn ? "translate-x-[13px]" : "translate-x-0",
            disabled ? "opacity-80" : "",
          )}
        />
      </Switch>
      <Switch.Label
        as="span"
        className={classNames("ml-3 text-[1.2rem] uppercase", disabled ? "opacity-80" : "cursor-pointer")}
      >
        <span className="font-medium text-content">{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
};
