import React from "react";
import {observable} from "mobx";

import StoreContext from "./store-context";
import RouteContext from "./route-context";

export const useRouterStore = () => {
  return React.useContext(StoreContext);
};

export const useRouteInfo = () => {
  return React.useContext(RouteContext);
};

export const createRoute = (route) => {
  const {path, component} = route;
  if (component == null) {
    throw `A component was not specified for the route "${path}"`;
  }

  // Extract route params names
  let routeParams = {};
  const split = path.split(":");
  if (split.length > 1) {
    for (let i = 1; i < split.length; i++) {
      const paramName = split[i].split("?")[0].split("/")[0];
      routeParams[paramName] = null;
    }
  }

  return {
    ...route,
    params: routeParams,
    state: observable({
      params: routeParams,
    }),
  };
};

export * from "./serialization-helpers";
export {default as Router} from "./Router";
export {default as Switch} from "./Switch";
export {default as Link} from "./Link";
export {default as Route} from "./Route";
export {default as Redirect} from "./Redirect";
export {default as RouterStore} from "./RouterStore";
