export const SensorVersions = {
  "5DC5F81": "433.0",
  "11F496F": "433.4",
  "6BCA119": "433.4",
  E41E4CC: "433.0",
  "97E5457": "433.4",
  B26281A: "433.0",
  "476E2C7": "433.0",
  A7FA644: "433.4",
  "975C12A": "433.0",
  D3F7C3C: "433.0",
  "3018D69": "433.4",
  DD83820: "433.0",
  C39B7B0: "433.0",
  BC0315F: "433.4",
  "5F7FB74": "433.0",
  1092704: "433.4",
  "819190E": "433.4",
  "8A4524E": "433.0",
  2348645: "433.0",
  EDC4470: "433.0",
  E9F59C3: "433.4",
};
export const GatewayVersions = {
  A739583: "433.0",
  "07D771E": "433.4",
  CAF59FB: "433.4",
  E40C300: "433.0",
  "9D4F5DC": "433.4",
  "536FF6F": "433.4",
};
