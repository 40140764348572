import {parseDate, parseInt, parseOneOf, serialiseDate, trySet} from "sensoteq-react-core/lib/mobx-state-router";
import * as Enums from "constants/enums";
import {MachinePositionDictionary, MachineStepDictionary} from "sensoteq-react-core/constants/options";

// Continuous data config
export const mapContinuousDataConfigStateToQuery = (rootStore) => {
  const {
    timeOption,
    timeFrom,
    timeTo,
    timeMulti,
    axis,
    resolution,
    averageType,
    view,
    domain,
    pointIds,
    pointView,
    screenSensorView,
    analysisPoint,
  } = rootStore.uiStore.continuousDataConfig;

  let timeMultiArray = [];
  if (timeMulti?.length > 0) {
    timeMultiArray = timeMulti.map((time) => {
      return serialiseDate(time.from);
    });
  }

  let query = {
    view: view,
    to: timeOption,
    tf: serialiseDate(timeFrom),
    tt: serialiseDate(timeTo),
    tm: timeMultiArray.join(","),
    axis,
    res: resolution,
    avg: averageType,
  };
  if (domain) query.domain = domain;
  if (pointIds) query.pointIds = pointIds.join(",");
  if (pointView) query.pv = pointView;
  if (screenSensorView) query.ssv = screenSensorView;
  if (analysisPoint) query.ap = analysisPoint;
  return query;
};
export const mapContinuousDataConfigQueryToState = (rootStore, queryParams) => {
  const {view, domain, pointIds, pv, to, tf, tt, axis, res, avg, ssv, ap} = queryParams;
  const {uiStore} = rootStore;
  const config = uiStore.continuousDataConfig;
  trySet(ssv, (x) =>
    uiStore.setContinuousDataScreenSensorView(
      parseOneOf(x, [Enums.SCREEN_SENSOR_BEARING_VIEW, Enums.SCREEN_SENSOR_STRUCTURE_VIEW]),
    ),
  ),
    trySet(view, (x) =>
      uiStore.setContinuousDataView(
        parseOneOf(x, [
          Enums.MACHINE_OVERVIEW_TAB,
          Enums.MACHINE_OVERVIEW_DATA_TAB,
          Enums.MACHINE_OVERVIEW_ANALYSIS_TAB,
        ]),
      ),
    );
  trySet(ap, (x) => uiStore.setContinuousDataAnalysisPoint(x));
  trySet(domain, (x) => uiStore.setContinuousDataDomain(parseOneOf(x, [Enums.MACHINE_DATA_VIEWS])));
  trySet(pointIds, (x) => uiStore.setContinuousDataPointIds(x.split(",")));
  trySet(pv, (x) => uiStore.setContinuousPointViewType(parseOneOf(x, ["single", "group"])));
  trySet(to, (x) => uiStore.setContinuousDataTimeOption(parseOneOf(x, Enums.TIME_OPTIONS)));
  trySet(tf, (x) => uiStore.setContinuousDataTimeFrom(parseDate(x, config.timeFrom)));
  trySet(tt, (x) => uiStore.setContinuousDataTimeTo(parseDate(x, config.timeTo)));
  trySet(axis, (x) => uiStore.setContinuousDataAxis(parseOneOf(x, Enums.AXES)));
  trySet(res, (x) => uiStore.setContinuousDataResolution(parseOneOf(x, Enums.RESOLUTIONS)));
  trySet(avg, (x) => uiStore.setContinuousDataAverageType(parseOneOf(x, Enums.AVERAGE_OPTIONS, parseInt)));
};

// Time domain config
export const mapTimeDomainDataConfigStateToQuery = (rootStore) => {
  const {timeDomainDataConfig: config} = rootStore.uiStore;
  return {
    to: config.timeOption,
    time: serialiseDate(config.time),
    axis: config.axis,
    res: config.resolution,
    wo: config.windowOption.toLowerCase(),
    idx: config.keyIndex,
    sd: config.spectrumDomain,
    wd: config.waveformDomain,
    ampl: config.amplitudeOption,
  };
};
export const mapTimeDomainDataQueryToState = (rootStore, queryParams) => {
  const {to, time, axis, res, wo, sd, wd, ampl} = queryParams;
  const {uiStore} = rootStore;
  const config = uiStore.timeDomainDataConfig;
  trySet(to, (x) =>
    uiStore.setTimeDomainDataTimeOption(parseOneOf(x, [Enums.TIME_OPTION_LATEST, Enums.TIME_OPTION_CUSTOM])),
  );
  trySet(time, (x) => uiStore.setTimeDomainDataTime(parseDate(x, config.time)));
  trySet(axis, (x) => uiStore.setTimeDomainDataAxis(parseOneOf(x, Enums.AXES)));
  trySet(res, (x) => uiStore.setTimeDomainDataResolution(parseOneOf(x, Enums.RESOLUTIONS)));
  trySet(wo, (x) => uiStore.setTimeDomainDataWindowOption(parseOneOf(x, Enums.WINDOWS, (x) => x.toUpperCase())));
  trySet(sd, (x) => uiStore.setTimeDomainDataSpectrumDomain(parseOneOf(x, Enums.DOMAINS)));
  trySet(wd, (x) => uiStore.setTimeDomainDataWaveformDomain(parseOneOf(x, Enums.DOMAINS)));
  trySet(ampl, (x) => uiStore.setTimeDomainDataAmplitudeOption(parseOneOf(x, Object.values(Enums.AmplitudeOption))));
};

// Utils to prefix query params to avoid conflicts with names
export const prefixMapStateToQuery = (prefix, rootStore, mapStateToQuery) => {
  const queryParams = mapStateToQuery(rootStore);
  let newQueryParams = {};
  Object.keys(queryParams).forEach((param) => {
    newQueryParams[`${prefix}-${param}`] = queryParams[param];
  });
  return newQueryParams;
};
export const prefixMapQueryToState = (prefix, rootStore, queryParams, mapQueryToState) => {
  let newQueryParams = {};
  Object.keys(queryParams).forEach((param) => {
    if (param.startsWith(`${prefix}-`)) {
      newQueryParams[param.substr(prefix.length + 1)] = queryParams[param];
    }
  });
  mapQueryToState(rootStore, newQueryParams);
};

export const positionsToMachineTypeCoordinates = (positionsArray) => {
  let coordinates = {};
  positionsArray.forEach((coordinate) => {
    if (!coordinates[coordinate.step]) {
      coordinates[coordinate.step] = {};
    }
    coordinates[coordinate.step][coordinate.position] = coordinate.coordinates;
  });
  return coordinates;
};

export const coordsToMachineDiagramPositions = (coordinates) => {
  let coordinatesArr = [];
  Object.entries(coordinates).forEach(([stepKey, stepPositions]) => {
    Object.entries(stepPositions).forEach(([positionKey, positionValue]) => {
      coordinatesArr.push({
        step: stepKey,
        position: positionKey,
        coordinates: positionValue,
        online: true,
        extra: `${MachineStepDictionary[stepKey]} ${MachinePositionDictionary[positionKey]}`,
        type: "device",
      });
    });
  });
  return coordinatesArr;
};

export const getColor = (percentage) => {
  const green = [64, 191, 68];
  const yellow = [255, 209, 26];
  const red = [221, 60, 60];
  let r, g, b;

  // Fade from green to yellow
  if (percentage <= 50) {
    const fadePercentage = percentage * 2;
    r = green[0] + ((yellow[0] - green[0]) * fadePercentage) / 100;
    g = green[1] + ((yellow[1] - green[1]) * fadePercentage) / 100;
    b = green[2] + ((yellow[2] - green[2]) * fadePercentage) / 100;
  }
  // Fade from yellow to red
  else {
    const fadePercentage = 200 - 2 * percentage;
    r = red[0] + ((red[0] - yellow[0]) * fadePercentage) / 100;
    g = red[1] + ((red[1] - yellow[1]) * fadePercentage) / 100;
    b = red[2] + ((red[2] - yellow[2]) * fadePercentage) / 100;
  }
  return `rgb(${r}, ${g}, ${b})`;
};
