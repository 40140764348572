import React from "react";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

import {asField} from "../lib/mobx-form";
import Label from "./Label";

class Select extends React.Component {
  static propTypes = {
    border: PropTypes.bool,
    options: PropTypes.array,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    icon: PropTypes.node,
  };
  static defaultProps = {
    border: true,
    icon: null,
  };
  desiredValue;

  componentDidUpdate() {
    const {options, fieldState, fieldApi} = this.props;
    const {setValue} = fieldApi;
    const {value} = fieldState;

    // Find previous and current position of selected value
    let currentIndex = -1;
    if (options) {
      currentIndex = options.findIndex((x) => (x instanceof Object ? x.value : x) === value);
    }

    // Unset value if an invalid option was initially given
    if (value !== "" && currentIndex === -1) {
      // Only set desired value when clearing because of lack of option
      this.desiredValue = value;
      setValue(null);
    }

    // Set value again if an option appeared which matched the desired value
    else if (this.desiredValue != null && value === "") {
      let desiredIndex = -1;
      if (options) {
        desiredIndex = options.findIndex((x) => (x instanceof Object ? x.value : x) === this.desiredValue);
      }
      if (desiredIndex !== -1) {
        // Restore value and unset desired value
        setValue(this.desiredValue);
        this.desiredValue = null;
      }
    }
  }

  render() {
    const {fieldState, fieldApi, ...props} = this.props;
    const {setValue} = fieldApi;
    const {onChange, forwardedRef, label, field, placeholder, options, border, icon} = props;
    const {value, disabled, error} = fieldState;
    return (
      <Container>
        <Row>
          {label && <Label htmlFor={field}>{label}</Label>}
          {icon && <Icon>{icon}</Icon>}
        </Row>

        {error && (
          <Label
            error
            htmlFor={field}
          >
            {error}
          </Label>
        )}
        <Wrapper>
          <StyledSelect
            id={field}
            ref={forwardedRef}
            value={value}
            border={border}
            onChange={(e) => {
              let selectedIndex = e.target.selectedIndex;
              if (placeholder) {
                selectedIndex--;
              }
              let option = options[selectedIndex];
              const value = option instanceof Object ? option.value : option;
              setValue(value);
              if (onChange) {
                onChange(value);
              }
            }}
            error={!!error}
            disabled={disabled}
            userDisabled={disabled}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options &&
              options.map((x) => {
                let optionValue = x instanceof Object ? x.value : x;
                let optionLabel = x instanceof Object ? x.label : x;
                return (
                  <option
                    key={optionValue}
                    value={optionValue}
                    data-selected={value === optionValue}
                  >
                    {optionLabel}
                  </option>
                );
              })}
          </StyledSelect>
          <Arrow disabled={disabled}>
            <i className="material-icons">keyboard_arrow_down</i>
          </Arrow>
        </Wrapper>
      </Container>
    );
  }
}

export default asField(Select, "");

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.div`
  margin-bottom: 0.5rem;
`;

const StyledSelect = styled.select`
  padding: ${(props) => props.theme.forms.padding};
  border: ${(props) => props.theme.forms.borderWidth} solid transparent;
  padding-right: 3.6rem;
  border-radius: ${(props) => props.theme.forms.borderRadius};
  background-color: ${(props) => props.theme.colours.inputBackground};
  -webkit-appearance: none;
  -moz-appearance: none;
  flex: 0 0 auto;
  line-height: 1.5rem;

  ${(props) =>
    props.border &&
    css`
      border-color: ${(props) => props.theme.colours.border};
    `}

  ${(props) =>
    !props.border &&
    css`
      box-shadow: ${(props) => props.theme.forms.boxShadow};
    `}
  
  &:disabled {
    background-color: ${(props) => props.theme.colours.contentBackgroundAlt};
    color: ${(props) => props.theme.colours.contentLight};
  }

  ${(props) =>
    !props.userDisabled &&
    css`
      &:focus {
        border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.primaryHighlight};
        outline: none;
      }
    `}

  ${(props) =>
    props.error &&
    css`
      border-color: ${(props) => props.theme.colours.bad};
    `}
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const Arrow = styled.div`
  pointer-events: none;
  position: absolute;
  top: calc(50% - 1rem);
  right: 1.2rem;

  ${(props) =>
    props.disabled &&
    css`
      i {
        color: ${(props) => props.theme.colours.contentLight};
      }
    `}
`;
