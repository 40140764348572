import styled, {css} from "styled-components";

export default styled.label`
  display: block;
  color: ${(props) => props.theme.colours.contentLight};
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSizes.tiny};
  text-transform: uppercase;

  ${(props) =>
    props.error &&
    css`
      color: ${(props) => props.theme.colours.bad};
    `}
`;
