import {observable, action, computed} from "mobx";

const validTypes = ["good", "bad", "warning", "info", "alarm_warning", "alarm_critical"];
function isValidType(type) {
  return validTypes.indexOf(type) !== -1;
}

export default class NotificationStore {
  rootStore;
  @observable.shallow notifications = [];
  @observable drawerOpen = false;
  notificationsBlocked = false;
  notificationBlockerTimout;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get unreadNotifications() {
    return this.notifications.filter((x) => !x.read && !x.hidden);
  }

  @action addNotification(message, type = "good", timeout = true) {
    if (this.notificationsBlocked) {
      return;
    }
    if (!isValidType(type)) {
      throw `Notification type must be one of "${validTypes.join(" ")}"`;
    }
    this.notifications.push(new Notification(message, type, timeout));
  }

  @action showDrawer(visible) {
    this.drawerOpen = visible;
  }

  @action markNotificationRead(id) {
    const notification = this.notifications.find((x) => x.id === id);
    if (!notification) {
      return;
    }
    notification.read = true;
  }

  @action clear() {
    this.notifications = [];
    this.drawerOpen = false;
  }

  blockNotifications(timeout = 1000) {
    this.notificationsBlocked = true;
    if (this.notificationBlockerTimout) {
      clearTimeout(this.notificationBlockerTimout);
    }
    this.notificationBlockerTimout = setTimeout(() => {
      this.notificationsBlocked = false;
      this.notificationBlockerTimout = null;
    }, timeout);
  }
}

class Notification {
  @observable read = false;
  message;
  type;
  id = Math.random().toString(32).substr(2);
  timeout;
  timestamp;

  constructor(message, type, timeout) {
    this.message = message;
    this.type = type;
    this.timeout = timeout;
    this.timestamp = new Date().getTime();
  }
}
