import {flow, computed, observable} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class DeviceLookupSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      gateway: null,
    };
  }

  @observable _sensors;

  getData = flow(function* ({gateway}) {
    if (!gateway) {
      return;
    }
    this.startLoading();
    try {
      const sensors = yield Api.getSensorsForDevice(gateway);
      this._sensors = sensors.Sensors;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting sensors: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get sensors() {
    if (!this._sensors) {
      return [];
    }

    return this._sensors;
  }
}
