import React from "react";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

import {asField} from "../lib/mobx-form";
import Label from "./Label";

const ContentEditable = (props) => {
  const ref = React.createRef();
  const {fieldState, fieldApi, onChange, label, field, maxHeight, fillSpace} = props;
  const {value, error} = fieldState;
  const {setValue} = fieldApi;

  // Listen to input events
  React.useLayoutEffect(() => {
    ref.current.addEventListener("input", (e) => {
      const value = e.target.innerHTML;
      setValue(value);
      if (onChange) {
        onChange(value);
      }
    });
    ref.current.innerHTML = value;
  }, []);

  return (
    <Wrapper fillSpace={fillSpace}>
      {label && <Label htmlFor={field}>{label}</Label>}
      {error && (
        <Label
          error
          htmlFor={field}
        >
          {error}
        </Label>
      )}
      <Container fillSpace={fillSpace}>
        <StyledDiv
          fillSpace={fillSpace}
          maxHeight={maxHeight}
          ref={ref}
          error={!!fieldState.error}
          contentEditable
        />
      </Container>
    </Wrapper>
  );
};
ContentEditable.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  maxHeight: PropTypes.number,
  fillSpace: PropTypes.bool,
};
ContentEditable.defaultProps = {
  placeholder: "...",
  fillSpace: false,
};

export default asField(ContentEditable, "");

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  ${(props) =>
    props.fillSpace &&
    css`
      flex: 1 1 auto;
    `}
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  ${(props) =>
    props.fillSpace &&
    css`
      flex: 1 1 auto;
    `}
`;

const StyledDiv = styled.div`
  width: calc(100%);
  border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  border-radius: ${(props) => props.theme.forms.borderRadius};
  height: 100%;
  position: absolute;
  resize: none;
  white-space: pre-wrap;
  box-sizing: border-box;
  padding: ${(props) => props.theme.forms.padding};
  background-color: ${(props) => props.theme.colours.inputBackground};
  overflow: auto;

  ${(props) =>
    props.fillSpace &&
    css`
      flex: 1 1 auto;
      position: relative;
    `}

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${(props) => props.maxHeight}rem;
    `}

  &:focus {
    border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.primaryHighlight};
    outline: none;
  }

  ${(props) =>
    props.error &&
    css`
      border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.bad} !important;
    `}
`;
