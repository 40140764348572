import React from "react";
import PropTypes from "prop-types";

import {useRouterStore} from "./index";

const Redirect = ({routeParams, matchParams, to}) => {
  const routerStore = useRouterStore();
  React.useEffect(() => {
    let redirect;
    if (typeof to === "string") {
      redirect = to;
    } else {
      redirect = routerStore.processHooks(to.hookParams);
    }
    const url = redirect ? redirect : routerStore.createUrl(to, routeParams ?? matchParams);
    routerStore.replace(url);
  }, []);
  return null;
};

Redirect.propTypes = {
  path: PropTypes.string,
  to: PropTypes.any,
  exact: PropTypes.bool,
};

export default Redirect;
