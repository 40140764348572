import {action, flow, observable} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class ReportDataSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      name: null,
      version: null,
      companyId: null,
      parseEditorState: (x) => x,
      serialiseEditorState: (x) => x,
    };
  }

  @observable.shallow data;
  @observable editorState;
  @observable title;

  getData = flow(function* ({name, version, companyId}) {
    if (!name || !version) {
      return;
    }
    this.startLoading();
    try {
      this.data = yield Api.getReport(name, version, companyId);
      this.editorState = this.params.parseEditorState(this.data.content);
      this.title = name;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting report: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @action setEditorState(editorState) {
    this.editorState = editorState;
  }

  @action setTitle(title) {
    this.title = title;
  }

  save = flow(function* (companyId, silent = false) {
    this.startLoading();
    try {
      const content = yield this.params.serialiseEditorState(this.editorState);
      const result = yield Api.saveReport(this.title, content, this.params.version, companyId);
      this.update({
        version: result.version,
        name: this.title,
        companyId: companyId,
      });
      if (!silent) {
        this.rootStore.notificationStore.addNotification("Report saved successfully", "good");
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error saving report: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
