export default function (assign) {
  assign("hues", () => ({
    primary: 355,
  }));

  assign("colours", (theme) => ({
    // Background colours
    background: "hsl(240, 4%, 16%)",
    border: "rgba(255, 255, 255, 0.05)",
    contentBackground: "hsl(240, 4%, 20%)",
    contentBackgroundAlt: "hsl(240, 4%, 18%)",
    primary: `hsl(${theme.hues.primary}, 65%, 55%)`,
    primaryHighlight: `hsl(${theme.hues.primary}, 65%, 60%)`,
    primaryBackground: `hsla(${theme.hues.primary}, 50%, 50%, 0.2)`,
    inputBackground: `hsl(240, 4%, 18%)`,

    // Text colours
    content: "#ddd",
    contentLight: "#aaacb4",
    contentPlaceholder: "hsl(240, 4%, 32%)",

    // Good colours
    good: `hsl(${theme.hues.good}, 55%, 42%)`,
    goodBg: `hsl(${theme.hues.good}, 18%, 24%)`,
    goodBorder: `hsl(${theme.hues.good}, 26%, 32%)`,

    // Bad colours
    bad: `hsl(${theme.hues.bad}, 70%, 55%)`,
    badBg: `hsl(${theme.hues.bad}, 18%, 24%)`,
    badBorder: `hsl(${theme.hues.bad}, 26%, 32%)`,

    // Warning colours
    warning: `hsl(${theme.hues.warning}, 100%, 42%)`,
    warningBg: `hsl(${theme.hues.warning}, 18%, 24%)`,
    warningBorder: `hsl(${theme.hues.warning}, 26%, 32%)`,

    // Info colours
    info: `hsl(${theme.hues.info}, 50%, 50%)`,
    infoBg: `hsl(${theme.hues.info}, 18%, 24%)`,
    infoBorder: `hsl(${theme.hues.info}, 26%, 32%)`,

    // Offline colours
    offline: "#888",
    offlineBg: "#3a3a3a",
    offlineBorder: "#555",

    lightSeparator: "hsl(240, 4%, 23%)",

    // HMi override colours
    mapBackground: "#222327",
    topBarBackground: "hsl(240, 4%, 20%)",
    mainMenuBackground: "hsl(240, 4%, 20%)",
    mainMenuText: "#aaacb4",
    mainMenuTextOnHover: "white",
    mainMenuTextOnActive: "white",
  }));

  assign("misc", () => ({
    tileURL:
      "https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}",
    tileFilter: "contrast(1.1)",
    mainMenuBoxShadow: "0.2rem 0 0.5rem 0 rgba(0, 0, 0, 0.2)",
  }));

  assign("graphs", () => ({
    gridLines: "hsl(240, 4%, 24%)",
    textDisabled: "#888",
  }));

  assign("forms", () => ({
    boxShadow: "0 0 0.8rem 0 rgba(0, 0, 0, 0.165)",
    heavyBoxShadow: "0 0 1.5rem 0.5rem rgba(0, 0, 0, 0.3)",

    optionColor: "#dddddd",
  }));
}
