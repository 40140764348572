import {observable, action, computed} from "mobx";
import dayjs from "dayjs";
import * as Enums from "constants/enums";
import {WindowingOptions} from "sensoteq-core/calculations";
import * as ReactCoreEnums from "sensoteq-react-core/constants/enums";
import {ServiceAppModes} from "sensoteq-core/enumerations";
import {ENVELOPE_METHOD, ENVELOPE_BAND_PASS_LABELS} from "sensoteq-core/signal-processing/constants-envelope";
import {RootStore} from "stores/index";

type BluetoothDataConfigSettings = {
  hammerMinFrequency: number;
  hammerMaxFrequency: number;
  hammerTrigger: number;
  hammerSNR: number;
  hammerRemovePointsAtStart: number;
  hammerSamples: number;
  hammerFFTSize: number;
};

type BluetoothDataConfig = {
  time: dayjs.Dayjs;
  keyIndex: number;
  mode: string;
  csvFile: unknown;
  settings: BluetoothDataConfigSettings;
};

type TimeDomainDataConfig = {
  timeOption: string;
  time: dayjs.Dayjs;
  resolution: string;
  axis: string;
  windowOption: string;
  spectrumDomain: string;
  waveformDomain: string;
  amplitudeOption: string;
  keyTime: number | null;
};

type ContinuousDataConfig = {
  view: unknown;
  screenSensorView: unknown;
  analysisPoint: unknown;
  domain: string | null;
  pointIds: string[];
  pointView: unknown;
  timeOption: unknown;
  timeFrom: dayjs.Dayjs;
  timeTo: dayjs.Dayjs;
  timeMulti: {from: dayjs.Dayjs; to: dayjs.Dayjs}[];
  axis: string;
  resolution: string;
  averageType: number;
};

type EllipseDataConfig = {
  comparisonPoint: string | null | undefined;
  time: dayjs.Dayjs;
  rotationAdjustment: boolean;
};

export default class UIStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  // Generic
  @observable mainMenuVisibleMobile = false;
  @observable mainMenuCollapsed = false;
  @observable showDockedReportEditor = false;
  @observable topMenuVisible = false;
  @observable searchString = "";

  // Alarms
  @observable alarmListFilterOptions = {};

  // Login
  @observable loginRedirect: string | undefined;
  @observable passwordResetToken: string | undefined;

  // Continuous data
  @observable continuousDataConfig: ContinuousDataConfig = {
    view: Enums.MACHINE_OVERVIEW_TAB,
    screenSensorView: Enums.SCREEN_SENSOR_BEARING_VIEW,
    analysisPoint: null,
    domain: null,
    pointIds: [],
    pointView: null,
    timeOption: ReactCoreEnums.TIME_OPTION_LATEST,
    timeFrom: dayjs().subtract(1, "day"),
    timeTo: dayjs(),
    timeMulti: [],
    axis: ReactCoreEnums.HORIZONTAL_AXIS,
    resolution: ReactCoreEnums.LOW_RES,
    averageType: ReactCoreEnums.AVERAGE_OPTION_AVG,
  };

  // Events
  @observable newEventDate: dayjs.Dayjs | undefined;

  // Time domain data
  @observable timeDomainDataConfig: TimeDomainDataConfig = {
    timeOption: ReactCoreEnums.TIME_OPTION_LATEST,
    time: dayjs().startOf("day"),
    resolution: ReactCoreEnums.LOW_RES,
    axis: ReactCoreEnums.HORIZONTAL_AXIS,
    windowOption: WindowingOptions.HANN_WINDOW,
    spectrumDomain: ReactCoreEnums.VELOCITY_DOMAIN,
    waveformDomain: ReactCoreEnums.ACCELERATION_DOMAIN,
    amplitudeOption: Enums.AmplitudeOption.RMS,
    keyTime: null,
  };

  // Waterfall data
  @observable waterfallDataConfig = {
    domain: ReactCoreEnums.VELOCITY_DOMAIN,
    count: 10,
    rms: 100,
    display: Enums.WaterfallDisplay.GRAPH,
    amplitudeOption: Enums.AmplitudeOption.RMS,
    envelope: false,
    fMax: 10000,
  };

  // Ellipse data
  @observable ellipseDataConfig: EllipseDataConfig = {
    comparisonPoint: undefined,
    time: dayjs(),
    rotationAdjustment: true,
  };

  // Site map screen mode
  @observable siteMapScreenMode = {
    showFullScreen: false,
  };

  // Point trending ellipse data
  @observable pointEllipseTrendingDataConfig = {
    xDomain: ReactCoreEnums.DISPLACEMENT_DOMAIN,
    yDomain: ReactCoreEnums.DISPLACEMENT_DOMAIN,
    zDomain: ReactCoreEnums.VELOCITY_DOMAIN,
  };

  // Analysis config
  @observable analysisDataConfig = {
    viewType: Enums.TEMPERATURE_VIEW,
  };

  // Bluetooth config
  @observable bluetoothDataConfig: BluetoothDataConfig = {
    time: dayjs().startOf("day"),
    keyIndex: 0,
    mode: ServiceAppModes.STROKE_ANALYSIS,
    csvFile: null,
    settings: {
      hammerMinFrequency: 10,
      hammerMaxFrequency: 50,
      hammerTrigger: 50,
      hammerSNR: 1,
      hammerRemovePointsAtStart: 8,
      hammerSamples: 1024,
      hammerFFTSize: 2048,
    },
  };

  // Reports
  @observable reportSearchString = "";

  // Settings
  @observable settingsSelectedUser: unknown;

  // Machine page
  @observable criticalityTimeOption = ReactCoreEnums.TIME_OPTION_1W;

  // Enveloping config
  @observable envelopeConfig = {
    enabled: false,
    method: ENVELOPE_METHOD.HILBERT,
    bandPassFilter: ENVELOPE_BAND_PASS_LABELS.RANGE_500_10000_HZ,
    lowPassFilter: 1000,
  };

  @computed get latestDataCount() {
    return 360;
  }

  @computed get historicalDataCount() {
    return this.continuousDataConfig.timeOption === ReactCoreEnums.TIME_OPTION_5Y ? 60 : 1000;
  }

  @computed get continuousDataDates() {
    let from = dayjs().subtract(12, "hour"),
      to = dayjs();

    let multi: unknown[] = [];
    if (this.continuousDataConfig.timeOption === ReactCoreEnums.TIME_OPTION_1D) {
      from = dayjs().subtract(1, "day");
    } else if (this.continuousDataConfig.timeOption === ReactCoreEnums.TIME_OPTION_1W) {
      from = dayjs().subtract(1, "week");
    } else if (this.continuousDataConfig.timeOption === ReactCoreEnums.TIME_OPTION_1M) {
      from = dayjs().subtract(1, "month");
    } else if (this.continuousDataConfig.timeOption === ReactCoreEnums.TIME_OPTION_1Y) {
      from = dayjs().subtract(1, "year");
    } else if (this.continuousDataConfig.timeOption === ReactCoreEnums.TIME_OPTION_5Y) {
      from = dayjs().subtract(5, "year");
    } else if (this.continuousDataConfig.timeOption === ReactCoreEnums.TIME_OPTION_CUSTOM) {
      from = this.continuousDataConfig.timeFrom;
      to = this.continuousDataConfig.timeTo;
    } else if (this.continuousDataConfig.timeOption === ReactCoreEnums.TIME_OPTION_RANGE) {
      from = this.continuousDataConfig.timeFrom;
      to = this.continuousDataConfig.timeTo;
    } else if (this.continuousDataConfig.timeOption === ReactCoreEnums.TIME_OPTION_MULTI) {
      multi = this.continuousDataConfig.timeMulti;
    }

    return {
      from,
      to,
      multi,
    };
  }

  @computed get continuousDataDomain() {
    if (this.continuousDataConfig.timeOption === ReactCoreEnums.TIME_OPTION_LATEST) {
      return [];
    }
    return [this.continuousDataDates.from, this.continuousDataDates.to];
  }

  @computed get criticalityDataDates() {
    const now = dayjs();
    const minutes = now.minute();
    const nextTenMinutes = Math.ceil(minutes / 10) * 10;
    // Normalise to the nearest 10 minute future interval to leverage the query cache.
    const normalisedTime = now.minute(nextTenMinutes).second(0).millisecond(0);

    let from = normalisedTime.subtract(12, "hour");
    if (this.criticalityTimeOption === ReactCoreEnums.TIME_OPTION_1W) {
      from = normalisedTime.subtract(1, "week");
    } else if (this.criticalityTimeOption === ReactCoreEnums.TIME_OPTION_1M) {
      from = normalisedTime.subtract(1, "month");
    } else if (this.criticalityTimeOption === ReactCoreEnums.TIME_OPTION_6M) {
      from = normalisedTime.subtract(6, "months");
    }
    return {
      from,
      to: normalisedTime,
    };
  }

  @computed get isEnvelopingEnabled() {
    return this.envelopeConfig.enabled;
  }

  @action setLoginRedirect(redirect: string) {
    this.loginRedirect = redirect;
  }

  @action setShowDockedReportEditor(open: boolean) {
    this.showDockedReportEditor = open;
    this.mainMenuCollapsed = open;
  }

  @action setMainMenuVisibleResponsive(visible: boolean) {
    this.mainMenuVisibleMobile = visible;
  }

  @action setMainMenuVisibleMobile(visible: boolean) {
    if (visible) {
      this.mainMenuCollapsed = false;
    }
    this.mainMenuVisibleMobile = visible;
  }

  @action setMainMenuCollapsed(collapsed: boolean) {
    this.mainMenuCollapsed = collapsed;
  }

  @action showTopMenu(visible: boolean) {
    this.topMenuVisible = visible;
  }

  @action setContinuousDataTimeOption(timeOption: string) {
    if (timeOption !== ReactCoreEnums.TIME_OPTION_MULTI) {
      this.continuousDataConfig.timeMulti = [];
    }

    this.continuousDataConfig.timeOption = timeOption;
  }

  @action setContinuousDataView(view: string) {
    this.continuousDataConfig.view = view;
  }

  @action setContinuousDataScreenSensorView(view: string) {
    this.continuousDataConfig.screenSensorView = view;
  }

  @action setContinuousDataDomain(domain: string) {
    this.continuousDataConfig.domain = domain;
  }

  @action setContinuousDataPointIds(pointIds: string[]) {
    this.continuousDataConfig.pointIds = pointIds;
  }

  @action setContinuousDataAnalysisPoint(pointId: unknown) {
    this.continuousDataConfig.analysisPoint = pointId;
  }

  @action setContinuousDataPointViewType(type: unknown) {
    this.continuousDataConfig.pointView = type;
  }

  @action setContinuousDataMachinePointView({
    type,
    pointIds,
    domain,
  }: {
    type: unknown;
    pointIds: string[];
    domain: string | null;
  }) {
    this.continuousDataConfig.pointView = type;
    this.continuousDataConfig.pointIds = pointIds;
    this.continuousDataConfig.domain = domain;
  }

  @action resetContinuousDataMachinePointView() {
    this.continuousDataConfig.pointView = null;
    this.continuousDataConfig.pointIds = [];
    this.continuousDataConfig.domain = null;
  }

  @action resetTimeMultiOption() {
    this.continuousDataConfig.timeMulti = [];
  }

  @action setContinuousDataTimeFrom(timeFrom: dayjs.Dayjs) {
    this.continuousDataConfig.timeFrom = dayjs(timeFrom);
    if (this.continuousDataConfig.timeFrom > this.continuousDataConfig.timeTo) {
      this.continuousDataConfig.timeTo = dayjs(this.continuousDataConfig.timeFrom).endOf("day");
    }
  }

  @action setContinuousDataTimeTo(timeTo: dayjs.Dayjs) {
    this.continuousDataConfig.timeTo = dayjs(timeTo);
    if (this.continuousDataConfig.timeTo < this.continuousDataConfig.timeFrom) {
      this.continuousDataConfig.timeFrom = dayjs(this.continuousDataConfig.timeTo).startOf("day");
    }
  }

  @action setContinuousDataTimeMulti(timeMulti: {from: dayjs.Dayjs; to: dayjs.Dayjs}, action: string) {
    let newTimeMulti: {from: dayjs.Dayjs; to: dayjs.Dayjs}[];
    if (action === "remove") {
      const removedTimeSelectionFromDataConfig = this.continuousDataConfig.timeMulti.filter(
        (item) => item.from.valueOf() !== timeMulti.from.valueOf(),
      );

      newTimeMulti = [...removedTimeSelectionFromDataConfig];
    } else {
      newTimeMulti = [...this.continuousDataConfig.timeMulti, timeMulti];
    }
    this.continuousDataConfig.timeMulti = newTimeMulti;
  }

  @action adjustContinuousDataRange(adjust: (date: dayjs.Dayjs) => dayjs.Dayjs) {
    const {from, to} = this.continuousDataDates;
    this.continuousDataConfig.timeFrom = adjust(from);
    this.continuousDataConfig.timeTo = adjust(to);
    this.continuousDataConfig.timeOption = ReactCoreEnums.TIME_OPTION_CUSTOM;
  }

  @action setContinuousDataAxis(axis: string) {
    this.continuousDataConfig.axis = axis;
  }

  @action setContinuousDataResolution(res: string) {
    this.continuousDataConfig.resolution = res;
  }

  @action setAlarmListFilterOptions(filterOptions: object) {
    this.alarmListFilterOptions = filterOptions;
  }

  @action setNewEventDate(date?: dayjs.Dayjs) {
    this.newEventDate = date;
  }

  @action setTimeDomainDataTimeOption(timeOption: string) {
    this.timeDomainDataConfig.timeOption = timeOption;
  }

  @action setTimeDomainDataResolution(resolution: string) {
    this.timeDomainDataConfig.resolution = resolution;
  }

  @action setTimeDomainDataAxis(axis: string) {
    this.timeDomainDataConfig.axis = axis;
  }

  @action setTimeDomainDataTime(time: dayjs.Dayjs) {
    this.timeDomainDataConfig.time = time;
  }

  @action setTimeDomainDataAmplitudeOption(opt: string) {
    this.timeDomainDataConfig.amplitudeOption = opt;
  }

  @action setTimeDomainDataWindowOption(window: string) {
    this.timeDomainDataConfig.windowOption = window;
  }

  @action setTimeDomainDataKeyTime(time: number) {
    this.timeDomainDataConfig.keyTime = time;
  }

  @action setWaterfallDataCount(count: number) {
    this.waterfallDataConfig.count = count;
  }

  @action setWaterfallDataDomain(domain: string) {
    this.waterfallDataConfig.domain = domain;
  }

  @action setWaterfallDataAmplitudeOption(opt: string) {
    this.waterfallDataConfig.amplitudeOption = opt;
  }

  @action setWaterfallDataEnvelopeOption(opt: boolean) {
    this.waterfallDataConfig.envelope = opt;
  }

  @action setWaterfallDataRms(rms: number) {
    this.waterfallDataConfig.rms = rms;
  }

  @action setWaterfallDataDisplay(display: string) {
    this.waterfallDataConfig.display = display;
  }

  @action setWaterfallDataFMax(value: number) {
    this.waterfallDataConfig.fMax = Number(value) || 0;
  }

  @action setEllipseDataComparisonPoint(point: string | null) {
    this.ellipseDataConfig.comparisonPoint = point;
  }

  @action setEllipseDataTime(time: dayjs.Dayjs) {
    this.ellipseDataConfig.time = time;
  }

  @action setSiteMapScreenMode(showFullScreen: boolean) {
    this.siteMapScreenMode.showFullScreen = showFullScreen;
  }

  @action setEllipseDataRotationAdjustment(adjust: boolean) {
    this.ellipseDataConfig.rotationAdjustment = adjust;
  }

  @action setReportSearchString(searchString: string) {
    this.reportSearchString = searchString;
  }

  @action setSettingsSelectedUser(user: unknown) {
    this.settingsSelectedUser = user;
  }

  @action setPasswordResetToken(token: string) {
    this.passwordResetToken = token;
  }

  @action setAnalysisViewType(type: string) {
    this.analysisDataConfig.viewType = type;
  }

  @action setSearchString(searchString: string) {
    this.searchString = searchString;
  }

  @action setBluetoothDataTime(time: dayjs.Dayjs) {
    this.bluetoothDataConfig.time = time;
  }

  @action setBluetoothDataKeyIndex(index: number) {
    this.bluetoothDataConfig.keyIndex = index;
  }

  @action setBluetoothDataMode(mode: string) {
    this.bluetoothDataConfig.mode = mode;
  }

  @action setBluetoothDataSettings(settings: BluetoothDataConfigSettings) {
    this.bluetoothDataConfig.settings = settings;
  }

  @action setBluetoothCSVFile(file: unknown) {
    this.bluetoothDataConfig.csvFile = file;
  }

  @action setContinuousDataAverageType(type: number) {
    this.continuousDataConfig.averageType = type;
  }

  @action setCriticalityTimeOption(option: string) {
    this.criticalityTimeOption = option;
  }

  @action setTimeDomainDataSpectrumDomain(option: string) {
    this.timeDomainDataConfig.spectrumDomain = option;
  }

  @action setTimeDomainDataWaveformDomain(option: string) {
    this.timeDomainDataConfig.waveformDomain = option;
  }

  @action setEnvelopeConfig(config: object) {
    this.envelopeConfig = {...this.envelopeConfig, ...config};
  }

  @action setPointEllipseTrendingDataXDomain(domain: string) {
    this.pointEllipseTrendingDataConfig.xDomain = domain;
  }

  @action setPointEllipseTrendingDataYDomain(domain: string) {
    this.pointEllipseTrendingDataConfig.yDomain = domain;
  }

  @action setPointEllipseTrendingDataZDomain(domain: string) {
    this.pointEllipseTrendingDataConfig.zDomain = domain;
  }
}
