import {observable, flow, computed} from "mobx";

import LoadableStore from "sensoteq-react-core/models/LoadableStore";
import Api from "sensoteq-react-core/services/api";
import {sortArrayByProperty} from "sensoteq-react-core/services/utils";

export default class CompanyStore extends LoadableStore {
  @observable _companies;

  @computed get companies() {
    if (!this._companies) {
      return [];
    }
    let companies;
    if (typeof this._companies[0] === "string") {
      companies = this._companies.map((x, idx) => ({
        company_id: x,
        display_name: this.rootStore.preferenceStore.obscureNames ? `Company ${idx}` : x,
      }));
    } else {
      companies = this._companies.map((x, idx) => ({
        ...x,
        display_name: this.rootStore.preferenceStore.obscureNames ? `Company ${idx}` : x.display_name,
      }));
    }
    sortArrayByProperty(companies, "display_name");
    return companies;
  }

  @computed get companyOptions() {
    return this.companies.map((x) => ({
      label: x.display_name,
      value: x.company_id,
    }));
  }

  @computed get _reverseLookupMap() {
    let lookupMap = [];
    this.companies.forEach((company) => {
      lookupMap[company.company_id] = company;
    });
    return lookupMap;
  }

  @computed get _reverseNameLookupMap() {
    let nameLookupMap = [];
    this.companies.forEach((company) => {
      nameLookupMap[company.display_name] = company;
    });
    return nameLookupMap;
  }

  @computed get _reverseSiteLookupMap() {
    let siteLookupMap = [];
    this.companies.forEach((company) => {
      company["site_names"].forEach((site) => {
        !siteLookupMap[site]
          ? (siteLookupMap[site] = [company.company_id])
          : siteLookupMap[site].push(company.company_id);
      });
    });
    return siteLookupMap;
  }

  getCompanyByName(companyName) {
    return this._reverseNameLookupMap[companyName];
  }

  getCompaniesForSite(siteId) {
    return this._reverseSiteLookupMap[siteId];
  }

  getCompany(companyId) {
    return this._reverseLookupMap[companyId];
  }

  loadCompanies = flow(function* () {
    this.startLoading();
    try {
      const companies = yield Api.getCompanyList();
      this._companies = companies?.companies ?? [];
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting companies: ${error}`, "bad");
    }
    this.stopLoading();
  });

  createCompany = flow(function* (name, sites) {
    this.startLoading();
    try {
      yield Api.createCompany(name, sites);
      this.loadCompanies();
      this.rootStore.notificationStore.addNotification("Company created successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error creating company: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateCompany = flow(function* (id, name, sites, chiViewingAccess, gateways) {
    this.startLoading();
    try {
      yield Api.updateCompany(id, name, sites, chiViewingAccess, gateways);
      this.loadCompanies();
      this.rootStore.notificationStore.addNotification("Company updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating company: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateCompanyGatewayOwnership = flow(function* (gateways, companyName, gatewaysToUnallocate) {
    this.startLoading();
    try {
      yield Api.updateOwnerGatewayOwnership(gateways, companyName, "company", gatewaysToUnallocate);
      this.loadCompanies();
      this.rootStore.notificationStore.addNotification("Gateway updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating gateway: ${error}`, "bad");
    }
    this.stopLoading();
  });

  deleteCompany = flow(function* (id) {
    this.startLoading();
    try {
      yield Api.deleteCompany(id);
      this.loadCompanies();
      this.rootStore.notificationStore.addNotification("Company deleted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting company: ${error}`, "bad");
    }
    this.stopLoading();
  });

  uploadCompanyLogo = flow(function* (company, formData) {
    this.startLoading();
    try {
      yield Api.uploadCompanyLogo(company, formData);
      this.rootStore.notificationStore.addNotification("Logo updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error uploading logo: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
