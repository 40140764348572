import {useEffect, useState} from "react";
import PropTypes from "prop-types";

import * as Styled from "./SearchDropdown.styles";

const SearchDropdown = ({
  initiallyOpen = false,
  text = "",
  match = false,
  canExpand = false,
  children,
  level,
  onClick,
  color,
}) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);

  useEffect(() => {
    setIsOpen(initiallyOpen);
  }, [initiallyOpen]);

  return (
    <Styled.Container level={level}>
      <Styled.Header
        match={match}
        level={level}
        color={color}
      >
        <Styled.LastBorder />
        <Styled.Spacer canExpand={canExpand} />
        {canExpand && (
          <i
            onClick={() => setIsOpen((prevState) => !prevState)}
            className="material-icons"
          >
            {isOpen ? "remove" : "add"}
          </i>
        )}
        <p onClick={onClick}>{text}</p>
      </Styled.Header>
      {isOpen && children}
    </Styled.Container>
  );
};

SearchDropdown.propTypes = {
  initiallyOpen: PropTypes.bool,
  text: PropTypes.string,
  match: PropTypes.bool,
  level: PropTypes.oneOf([0, 1, 2]).isRequired,
  canExpand: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

export default SearchDropdown;
