import React from "react";
import dayjs from "dayjs";
import autobind from "class-autobind-decorator";
import styled from "styled-components";
import PropTypes from "prop-types";

import DayjsPicker from "./DayjsPicker";
import {asField} from "../lib/mobx-form";
import Label from "./Label";

@autobind
class DateTimePicker extends React.Component {
  static propTypes = {
    showTimePicker: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    alignTop: PropTypes.bool,
    border: PropTypes.bool,
    placeholder: PropTypes.string,
    allowNull: PropTypes.bool,
  };
  static defaultProps = {
    showTimePicker: false,
    border: true,
    allowNull: false,
  };

  onChange(date) {
    const {onChange, fieldApi, showTimePicker} = this.props;
    const {setValue} = fieldApi;

    // Sanitise date
    let sanitisedDate;
    if (date) {
      sanitisedDate = dayjs(date).set("second", 0).set("millisecond", 0);
      if (!showTimePicker) {
        sanitisedDate = sanitisedDate.set("hour", 0).set("minute", 0);
      }
    }
    if (!sanitisedDate && !this.props.allowNull) {
      sanitisedDate = dayjs();
    }

    setValue(sanitisedDate);
    if (onChange) {
      onChange(sanitisedDate);
    }
  }

  render() {
    const {label, showTimePicker, field, fieldState, forwardedRef, alignTop, border, placeholder, allowNull} =
      this.props;
    const {error, disabled} = fieldState;
    let value = fieldState.value;
    if (value && !(value instanceof dayjs)) {
      value = dayjs(value);
    }
    return (
      <Wrapper>
        {label && <Label htmlFor={field}>{label}</Label>}
        {error && (
          <Label
            error
            htmlFor={field}
          >
            {error}
          </Label>
        )}
        <Container>
          <DayjsPicker
            alignTop={alignTop}
            ref={forwardedRef}
            onChange={this.onChange}
            selected={value}
            showTimePicker={showTimePicker}
            disabled={disabled}
            border={border}
            placeholder={placeholder}
            controlled
            allowNull={allowNull}
          />
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;

  > * {
    flex: 1 1 auto;
  }
`;

export default asField(DateTimePicker);
