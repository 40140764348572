import * as Enums from "constants/enums";
import {getAPI} from "services/Utils";
import dayjs from "dayjs";
import type {ManipulateType} from "dayjs";

export let apiAuthToken: string | null = null;
export let apiServer: string = getAPI();

export const DEFAULT_STALE_TIME = 60 * 60 * 1000; // 1 hour
const DEFAULT_TIMETAMP_ROUNDING = 10 * 60 * 1000; // 10 mins

const TIME_OPTIONS: Record<string, (number | ManipulateType)[]> = {
  [Enums.TIME_OPTION_LATEST]: [1, "week"],
  [Enums.TIME_OPTION_1D]: [1, "day"],
  [Enums.TIME_OPTION_1W]: [1, "week"],
  [Enums.TIME_OPTION_1M]: [1, "month"],
  [Enums.TIME_OPTION_6M]: [6, "months"],
  [Enums.TIME_OPTION_1Y]: [1, "year"],
  [Enums.TIME_OPTION_5Y]: [5, "year"],
  [Enums.TIME_OPTION_CUSTOM]: [5, "year"],
};

export const setReactQueryAPIToken = (token: string) => {
  apiAuthToken = token;
};

export const setReactQueryAPIServer = (server: string) => {
  apiServer = server;
};

export const getAPIQueryKey = (name: string, params: (string | number)[]) => {
  return [name, apiServer, apiAuthToken, ...params];
};

export const getRoundedTimestamp = (timestamp?: number | undefined, factor?: number | undefined) => {
  const roundFactor = factor || DEFAULT_TIMETAMP_ROUNDING;
  const ts = timestamp || new Date().getTime();
  return Math.floor(ts / roundFactor) * roundFactor;
};

export const getTimeOptions = (timeOption: string) => {
  const subtractPeriod = TIME_OPTIONS[timeOption] || TIME_OPTIONS[Enums.TIME_OPTION_1W];
  const [value, period] = subtractPeriod as (number | ManipulateType)[];
  const from = dayjs()
    .subtract(value as number, period as ManipulateType)
    .valueOf();

  return {
    timestamp: String(getRoundedTimestamp(from)),
    timestamp_end: String(getRoundedTimestamp() + DEFAULT_TIMETAMP_ROUNDING),
  };
};

export const fetchWithAuth =
  <T>(path: string) =>
  async () => {
    const url = `${apiServer}${path}`;
    const response = await window.fetch(url, {
      headers: {Authorization: `Bearer ${apiAuthToken}`},
    });

    return response.json() as T;
  };
