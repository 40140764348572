/**
 * See https://en.wikipedia.org/wiki/Mexican_hat_wavelet
 */
module.exports = function () {
  var sigma = 1;

  var ricker = function (t) {
    var t2 = t * t,
      variance = sigma * sigma;

    var C = 2.0 / (Math.sqrt(3 * sigma) * Math.pow(Math.PI, 0.25));
    var norm = 1.0 - t2 / variance;
    var gauss = Math.exp(-t2 / (2 * variance));

    return C * norm * gauss;
  };

  ricker.std = function (_) {
    return arguments.length ? ((sigma = _), ricker) : sigma;
  };

  /**
   * Range of points to sample from the wavelet. [-reach, reach]
   */
  ricker.reach = function () {
    return 5 * sigma;
  };

  return ricker;
};
