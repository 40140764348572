function isObject(obj) {
  return typeof obj === "object" && obj !== null;
}

function doesPropExist(obj, property) {
  const validObject = isObject(obj);
  if (validObject === false) {
    return false;
  }

  const validProperty = typeof property === "string" && property !== null;
  if (validProperty === false) {
    return false;
  }

  return Object.prototype.hasOwnProperty.call(obj, property);
}

function doesPropExistWithCondition(obj, property, condition) {
  return doesPropExist(obj, property) && condition(obj[property]);
}

function getNestedObjectFromValue(structure, nestedKey, value) {
  const objectKeys = Object.keys(structure);
  for (let objectKey of objectKeys) {
    if (!structure[objectKey].hasOwnProperty(nestedKey)) {
      continue;
    }

    if (structure[objectKey][nestedKey] === value) {
      return structure[objectKey];
    }
  }

  return null;
}

function doAllObjectValuesMeetCondition(obj, condition = (val) => !val, keyList) {
  const entries = Object.entries(obj);
  if (doesPropExist(keyList.blacklist) && Array.isArray(keyList.blacklist)) {
    entries.filter(([key]) => !keyList.blacklist.includes(key));
  }
  if (doesPropExist(keyList.whitelist) && Array.isArray(keyList.whitelist)) {
    entries.filter(([key]) => keyList.whitelist.includes(key));
  }

  return entries.every(([, value]) => condition(value));
}

function omit(obj, keys) {
  return Object.keys(obj)
    .filter((key) => keys.indexOf(key) < 0)
    .reduce((obj2, key) => ((obj2[key] = obj[key]), obj2), {});
}

function pick(obj, keys) {
  return keys.filter((key) => key in obj).reduce((obj2, key) => ((obj2[key] = obj[key]), obj2), {});
}

function generateValueLabelListFromMap(map, selectLabelFunc = (x) => x) {
  const options = [];
  map.forEach((value, key) => options.push({value: key, label: selectLabelFunc(value)}));
  return options;
}

module.exports = {
  isObject,
  doesPropExist,
  doesPropExistWithCondition,
  getNestedObjectFromValue,
  omit,
  pick,
  generateValueLabelListFromMap,
  doAllObjectValuesMeetCondition,
};
