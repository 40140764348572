import {observable, action, computed} from "mobx";

import LoadableStore from "../models/LoadableStore";

export default class SubscribableStore extends LoadableStore {
  Subscription;
  @observable.shallow _subscriptions = {};
  _defaultConfig = {
    recreate: false,
  };

  constructor(rootStore, Subscription) {
    super(rootStore);
    this.Subscription = Subscription;
  }

  @computed get subscriptions() {
    return Object.values(this._subscriptions);
  }

  refreshSubscriptions() {
    this.subscriptions.forEach((sub) => sub.refresh());
  }

  retrieve() {
    return null;
  }

  async sync(identifier) {
    try {
      const item = await this.retrieve(identifier);
      if (this.subscriptions.length) {
        if (this.subscriptions[0].sync.constructor.name === "AsyncFunction") {
          return Promise.all(this.subscriptions.map((x) => x.sync(identifier, item)));
        } else {
          this.subscriptions.forEach((subscription) => {
            subscription.sync(identifier, item);
          });
        }
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error syncing: ${error}`);
    }
  }

  @action subscribe(id = "default", config = {}) {
    config = {
      ...(this._defaultConfig || {}),
      ...config,
    };

    // Return subscriber if already exists
    if (this._subscriptions[id] != null && !config.recreate) {
      const subscription = this._subscriptions[id];
      subscription.start();
      return subscription;
    }

    // Otherwise create a new one
    const params = {recreate: !!config?.recreate, id};
    const subscription = new this.Subscription(this.rootStore, params);
    this._subscriptions[id] && this._subscriptions[id].cancel();
    this._subscriptions[id] = subscription;
    return subscription;
  }
}
