import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

import {generateId} from "services/Utils";
import * as Styled from "./ActionIcon.styles";
import Icon from "components/common/Icon";

function ActionIcon(props) {
  const {library, icon, tooltip, tooltipSide, onClick} = props;
  const id = React.useRef(generateId());
  let additionalProps = tooltip
    ? {
        "data-tip": true,
        "data-for": id.current,
      }
    : {};
  return (
    <Styled.Container>
      <Icon
        library={library}
        name={icon}
        onClick={onClick}
        {...additionalProps}
      />
      {tooltip && (
        <ReactTooltip
          delayShow={500}
          id={id.current}
          place={tooltipSide}
          type="dark"
          effect="solid"
        >
          {tooltip}
        </ReactTooltip>
      )}
    </Styled.Container>
  );
}

ActionIcon.propTypes = {
  icon: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  tooltipSide: PropTypes.string,
  library: PropTypes.string,
};
ActionIcon.defaultProps = {
  tooltipSide: "left",
};
ActionIcon.Libraries = Icon.Libraries;

export default ActionIcon;
