import React from "react";
import {observer} from "mobx-react";

import MainMenuLink from "./MainMenuLink";
import WhatsNewMenuLink from "./MainMenuLink/WhatsNewMenuLink";
import AppRoutes from "routes/app-routes";
import {useRootStore} from "stores";
import * as Styled from "./MainMenu.styles";
import Branding from "./Branding";
import CollapsedBranding from "./CollapsedBranding";
import * as envConfig from "sensoteq-react-core/constants/environment-config";
import enums from "sensoteq-core/enumerations";

function MainMenu() {
  const {userStore, uiStore, themeStore} = useRootStore();
  const {loggedIn, admin, company, allowAPIKeys, isChiOnlyUser, canLabelFaults} = userStore;
  const {mainMenuCollapsed, mainMenuVisibleMobile} = uiStore;
  const sensoteqEnv = enums.ENVIRONMENT_BRANDING === "sensoteq" || enums.ENVIRONMENT_BRANDING === "sandbox";
  const isSupportCenterEnabled = process.env.REACT_APP_SUPPORT_CENTER_ENABLED;

  const collapseMainMenu = React.useCallback(() => {
    uiStore.setMainMenuCollapsed(true);
  }, []);
  const expandMainMenu = React.useCallback(() => {
    uiStore.setMainMenuCollapsed(false);
  }, []);

  return (
    <Styled.Container
      collapsed={mainMenuCollapsed}
      visibleMobile={mainMenuVisibleMobile}
    >
      {!mainMenuCollapsed && (
        <Styled.BrandingContainer>
          <Branding size={1.3} />
        </Styled.BrandingContainer>
      )}
      {mainMenuCollapsed && <CollapsedBranding size={1.3} />}
      {loggedIn && (
        <>
          <Styled.Menu>
            <Styled.MenuBody>
              {!isChiOnlyUser && (
                <MainMenuLink
                  route={AppRoutes.MapPage}
                  otherRoutes={[
                    AppRoutes.SitePage,
                    AppRoutes.PointPage,
                    AppRoutes.MachinePage,
                    AppRoutes.ThermalPointPage,
                  ]}
                  text="Global Data"
                  iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                  icon="globe-americas"
                />
              )}
              {sensoteqEnv && (
                <MainMenuLink
                  route={AppRoutes.ChiPage}
                  text="Chi Data"
                  icon="bluetooth"
                  iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                />
              )}
              {!isChiOnlyUser && (
                <MainMenuLink
                  route={AppRoutes.AnalysisPage}
                  text="Analysis"
                  icon="chart-network"
                  iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                />
              )}
              {!admin && !isChiOnlyUser && company.company_id === "CDE Global" && (
                <MainMenuLink
                  route={AppRoutes.CDETestsPage}
                  text="Test Reports"
                  icon="perm_data_setting"
                />
              )}
              {!isChiOnlyUser && (
                <MainMenuLink
                  text="Alarms"
                  icon="siren-on"
                  iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                  route={AppRoutes.AlarmsPage}
                />
              )}
              {(admin || company.company_id === "Sensoteq") && !isChiOnlyUser && sensoteqEnv && (
                <MainMenuLink
                  route={AppRoutes.NeighbourhoodWatchPage}
                  text="Nbrhood Watch"
                  icon="user-friends"
                  iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                />
              )}
              <MainMenuLink
                route={AppRoutes.ReportsPage}
                text="Reports"
                icon="file-chart-line"
                iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
              />
              {admin && sensoteqEnv && (
                <MainMenuLink
                  route={AppRoutes.BespokePage}
                  otherRoutes={[AppRoutes.CDETestsPage]}
                  text="Bespoke"
                  icon="pencil-ruler"
                  iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                />
              )}
              {canLabelFaults && (
                <MainMenuLink
                  route={AppRoutes.FaultLabellingPage}
                  text="Fault Labelling"
                  icon={[
                    {name: "tags", style: {"--fa-secondary-opacity": 0.5}},
                    {
                      name: "exclamation-triangle",
                      transform: "shrink-9 left-1.5 up-1",
                      style: {"--fa-secondary-opacity": 1, "--fa-primary-opacity": 0},
                    },
                  ]}
                  iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                />
              )}
              <MainMenuLink
                text="Settings"
                icon="cog"
                iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                route={AppRoutes.SettingsPage}
              />
            </Styled.MenuBody>
            <Styled.Footer>
              {!mainMenuCollapsed && !themeStore.theme.branding.companyLogo && (
                <Styled.Wordmark>{envConfig.getKey("display_name")}</Styled.Wordmark>
              )}
              {sensoteqEnv && <WhatsNewMenuLink />}
              {allowAPIKeys && !isChiOnlyUser && (
                <MainMenuLink
                  route={AppRoutes.ApiDocsPage}
                  text="API Docs"
                  icon="book"
                  iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                />
              )}
              {isSupportCenterEnabled && (
                <MainMenuLink
                  route={AppRoutes.SupportCenterPage}
                  text="Support Center"
                  icon="question"
                  iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                />
              )}

              <MainMenuLink
                external
                to={isChiOnlyUser ? themeStore.theme.misc.chiUserGuideURL : themeStore.theme.misc.userGuideURL}
                text="User Guide"
                icon="book-user"
                iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
              />
              <MainMenuLink
                external
                to={envConfig.getKey("privacy_policy")}
                text="Privacy"
                icon="user-shield"
                iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
              />
              <MainMenuLink
                text="Minimize"
                icon={uiStore.mainMenuCollapsed ? "arrow-from-left" : "arrow-to-left"}
                iconLibrary={MainMenuLink.IconLibraries.FontAwesome}
                onClick={uiStore.mainMenuCollapsed ? expandMainMenu : collapseMainMenu}
                hideMobile
              />
            </Styled.Footer>
          </Styled.Menu>
        </>
      )}
    </Styled.Container>
  );
}

export default observer(MainMenu);
