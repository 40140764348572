import {observer} from "mobx-react";

import * as Styled from "./ServerChanger.styles";
import {useRootStore} from "stores";
import {getAPI} from "services/Utils";

const ServerChanger = () => {
  const {localStore} = useRootStore();
  const {server} = localStore;

  // Show nothing if the current server is set to the environment defined API
  if (server === getAPI()) {
    return null;
  }

  return (
    <Styled.Container className="flex-shrink-0 flex-grow-0 basis-auto">
      <span>
        <b>API Server: {server}</b>
      </span>
      <Styled.Link onClick={() => localStore.setServer(getAPI())}>Reset</Styled.Link>
    </Styled.Container>
  );
};

export default observer(ServerChanger);
