import {
  calculateFFT,
  calculateTimeDomainData,
  convertFFT,
  NyquistOptions,
  DomainConversionOptions,
  calculateRMS,
  calculateCrestFactor,
  calculateTimeDomainRMS,
  calculatePeakToPeak,
  WindowingOptions,
  prepareAccelerationData,
} from "sensoteq-core/calculations";
import {GRAVITY} from "sensoteq-core/enumerations";
import {calculateEnvelope} from "sensoteq-core/signal-processing/utils";
import {RMS_FACTOR} from "sensoteq-core/calculations";

// 10 as fMin matches the the used in the data processor when calculating OA values.
// Using 10 here ensures the HMI is consistent with the trended values.
const DEFAULT_OA_CALC_FMIN = 10;

export function processNewRawFFTData(data, userOptions, envelopeOptions) {
  const defaultOptions = {
    window: WindowingOptions.HANN_WINDOW,
    samplingFrequency: 6556,
    sampleLength: data.length,
    fMin: 5,
    fMax: 10000,
    applyMean: true,
    customFmax: null,
  };
  const options = {
    ...defaultOptions,
    ...(userOptions || {}),
  };

  if (!data || data.length === 0) {
    return null;
  }

  let rawData = data;
  if (envelopeOptions != null) {
    options.applyMean = false;
    const {result, config} = calculateEnvelope(data, options, envelopeOptions);
    rawData = result;

    if (config.lowPassFilter && config.lowPassFilter > 5) {
      // Allow for some leeway on the upper side, so adding 10% of the lowpass filter value.
      options.customFmax = config.lowPassFilter * 1.1;
    }
  } else {
    // When envelope mode is not active, use the user-defined fMax as the upper limit.
    if (!options.customFmax && options.fMax) {
      options.customFmax = options.fMax;
    }
  }

  // Test new calculateFFT
  let timeData = calculateTimeDomainData(rawData, options.samplingFrequency, options.applyMean);
  let accelerationData = calculateFFT(
    rawData,
    options.samplingFrequency,
    options.window,
    options.sampleLength,
    options.fMin,
    NyquistOptions.NYQUIST_256,
    options.customFmax,
  );
  let velocityData = convertFFT(accelerationData, DomainConversionOptions.VELOCITY_DOMAIN);
  let displacementData = convertFFT(accelerationData, DomainConversionOptions.DISPLACEMENT_DOMAIN);

  const nonZeroMeanAdjustedAcceleration = prepareAccelerationData(rawData);
  const accelerationTimeDomainRMS = calculateTimeDomainRMS(nonZeroMeanAdjustedAcceleration);
  const crestFactor = calculateCrestFactor(nonZeroMeanAdjustedAcceleration, accelerationTimeDomainRMS);
  const peakToPeakG = calculatePeakToPeak(nonZeroMeanAdjustedAcceleration) / 1000;
  const {accRMS, velRMS} = calculateSpectrumOA(rawData, options);

  return {
    timeData,
    accelerationData,
    velocityData,
    displacementData,
    velocityRMS: velRMS,
    crestFactor,
    accelerationRMS: accRMS,
    peakToPeak: peakToPeakG,
  };
}

export function calculateSpectrumOA(rawData, options) {
  let accelerationData = calculateFFT(
    rawData,
    options.samplingFrequency,
    options.window,
    options.sampleLength,
    DEFAULT_OA_CALC_FMIN,
    NyquistOptions.NYQUIST_256,
    options.customFmax,
  );

  if (options.window === WindowingOptions.HANN_WINDOW) {
    // Energy correct the result when a Hann Window is applied when calculating OA values.
    accelerationData = accelerationData.map(({value, frequency}) => ({value: value * 1.63, frequency}));
  }
  const velocityData = convertFFT(accelerationData, DomainConversionOptions.VELOCITY_DOMAIN);

  const accRSS = calculateRMS(accelerationData, (x) => x.value);
  const accRMS = accRSS * RMS_FACTOR;
  const velRSS = calculateRMS(velocityData, (x) => x.value);
  const velRMS = velRSS * RMS_FACTOR;

  return {
    accRSS,
    accRMS,
    velRSS,
    velRMS,
  };
}

export function displacementToVelocity(displacement, frequency) {
  return Math.PI * frequency * displacement;
}

export function displacementToAcceleration(displacement, frequency) {
  return (2 * Math.PI * Math.PI * frequency * frequency * displacement) / (GRAVITY * 1000);
}

export function velocityToDisplacement(velocity, frequency) {
  return velocity / (Math.PI * frequency);
}

export function velocityToAcceleration(velocity, frequency) {
  return (2 * Math.PI * frequency * velocity) / (GRAVITY * 1000);
}

export function accelerationToDisplacement(acceleration, frequency) {
  return (GRAVITY * acceleration * 1000) / (2 * Math.PI * Math.PI * frequency * frequency);
}

export function accelerationToVelocity(acceleration, frequency) {
  return (GRAVITY * acceleration * 1000) / (2 * Math.PI * frequency);
}

export function calculateSpectrumVelocityRSS(data, {samplingFrequency, fMax, windowOption}) {
  let accelerationData = calculateFFT(
    data,
    samplingFrequency,
    windowOption,
    data.length,
    DEFAULT_OA_CALC_FMIN,
    NyquistOptions.NYQUIST_256,
    fMax,
  );
  if (windowOption === WindowingOptions.HANN_WINDOW) {
    // Energy correct the result when a Hann Window is applied when calculating OA values.
    accelerationData = accelerationData.map(({value, frequency}) => ({value: value * 1.63, frequency}));
  }
  const velocityData = convertFFT(accelerationData, DomainConversionOptions.VELOCITY_DOMAIN);
  const velRSS = calculateRMS(velocityData, (x) => x.value);
  const velRMS = velRSS * RMS_FACTOR;
  return velRMS;
}
