import type {ObjectValues} from "../types.js";

export const DisplacementOption = {
  MICRONS: "microns",
  MILS: "mils",
  MILLIMETRE: "mm",
  INCHES: "in",
} as const;

export const DisplacementRatio = {
  [DisplacementOption.MICRONS]: 1,
  [DisplacementOption.MILS]: 0.0393700787,
  [DisplacementOption.MILLIMETRE]: 0.001,
  [DisplacementOption.INCHES]: 0.0000393700787,
};

export type Displacement = ObjectValues<typeof DisplacementOption>;
