import React from "react";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import useCloseOnClick from "../../../apps/analytix/src/components/hooks/useCloseOnClick";

function DayjsPicker(props) {
  const {controlled, format, showTimePicker, disabled, alignTop, border, placeholder, allowNull} = props;
  const dayNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const [monthOffset, setMonthOffset] = React.useState(0);
  const [alignLeft, setAlignLeft] = React.useState(true);
  const [selectedDate, setSelectedDate] = React.useState();
  const inputRef = React.createRef();
  const pickerRef = React.createRef();
  const hourContainerRef = React.createRef();
  const minuteContainerRef = React.createRef();
  const containerRef = React.useRef();

  // Selected date, either controlled or uncontrolled
  const selected = React.useMemo(() => {
    const date = controlled ? props.selected : selectedDate;
    return allowNull ? date : date || dayjs();
  }, [controlled, props.selected, selectedDate]);

  // The date currently being shown by the calendar, which can be shifted by months
  const viewDate = React.useMemo(() => {
    return (selected || dayjs()).add(monthOffset, "month");
  }, [selected, monthOffset]);

  // Memoized callback of what to do when opening picker
  const callbackOnOpen = React.useCallback(() => {
    props.onOpen && props.onOpen();
  }, [props.onOpen]);

  // Memoized callback of what to do when closing picker
  const callbackOnClose = React.useCallback(() => {
    props.onClose && props.onClose();
    setMonthOffset(0);
  }, [props.onClose]);

  // ALlow picker to be closed by clicking anywhere
  const picker = useCloseOnClick(containerRef, callbackOnOpen, callbackOnClose);

  // After every paint
  React.useLayoutEffect(() => {
    // Check we are on screen
    if (pickerRef.current) {
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      const leftOffset = inputRef.current.getBoundingClientRect().left;
      const shouldAlignLeft =
        inputRef.current.offsetWidth > pickerRef.current.offsetWidth || leftOffset < viewportWidth / 2;
      if (shouldAlignLeft !== alignLeft) {
        setAlignLeft(shouldAlignLeft);
      }
    }

    // Scroll time in to screen
    if (hourContainerRef.current) {
      const elements = hourContainerRef.current.getElementsByClassName("selected");
      if (elements.length) {
        hourContainerRef.current.scrollTop = elements[0].offsetTop;
      }
    }
    if (minuteContainerRef.current) {
      const elements = minuteContainerRef.current.getElementsByClassName("selected");
      if (elements.length) {
        minuteContainerRef.current.scrollTop = elements[0].offsetTop;
      }
    }
  });

  // Memoize computation of calendar model
  const calendarModel = React.useMemo(() => {
    const selectedDate = selected;
    let model = {
      viewDate,
      selectedDate,
      calendar: [],
    };
    for (let i = 0; i < 7; i++) {
      model.calendar.push([]);
    }

    // Find previous sunday as start of calendar
    let calendarStart = viewDate.startOf("month");
    while (calendarStart.day() !== 1) {
      calendarStart = calendarStart.subtract(1, "day");
    }
    model.calendarStart = calendarStart;

    // Find last saturday as end of calendar
    let calendarEnd = viewDate.endOf("month");
    while (calendarEnd.day() !== 0) {
      calendarEnd = calendarEnd.add(1, "day");
    }
    model.calendarEnd = calendarEnd;

    // Find length of calendar
    const calendarLength = calendarEnd.diff(calendarStart, "day");

    // Now continue until we are finished with the month and reach a saturday
    const today = dayjs();
    for (let i = 0, day = 0; i <= calendarLength; i++, day = (day + 1) % 7) {
      const currentDate = calendarStart.add(i, "day");
      model.calendar[day].push({
        date: currentDate,
        today: currentDate.isSameDay(today),
        currentMonth: currentDate.isSame(viewDate, "month"),
        selected: selectedDate && currentDate.isSameDay(selectedDate),
        offset: i,
      });
    }

    return model;
  }, [selected, viewDate]);

  // Function to change the date, either controlled or uncontrolled
  const onChange = React.useCallback(
    (date) => {
      const update = controlled ? props.onChange : setSelectedDate;
      update && update(date);
      picker.close();
    },
    [picker.close, controlled, props.onChange],
  );

  // Callback to change the day of the month
  const changeDay = React.useCallback(
    (date) => {
      let newDate = (selected || dayjs()).set("year", date.year()).set("month", date.month()).set("date", date.date());
      if (!showTimePicker) {
        newDate = newDate.startOf("day");
      }
      onChange(newDate);
    },
    [selected, onChange, showTimePicker],
  );

  // Callback to change the hour of the time
  const onChangeHour = React.useCallback(
    (hour) => {
      let newDate = (selected || dayjs()).set("hour", hour);
      onChange(newDate);
    },
    [onChange, selected],
  );

  // Callback to change the minute of the time
  const onChangeMinute = React.useCallback(
    (minute) => {
      let newDate = (selected || dayjs()).set("minute", minute);
      onChange(newDate);
    },
    [onChange, selected],
  );

  // Callback to change the month offset currently displayed by the calendar
  const changeViewMonth = React.useCallback((monthOffset) => {
    setMonthOffset((x) => x + monthOffset);
  }, []);

  // Callback to clear date
  const onClear = React.useCallback(
    (e) => {
      e.stopPropagation();
      onChange(null);
    },
    [onChange],
  );

  // Callback to set the date to today
  const setToToday = React.useCallback(
    (e) => {
      e.stopPropagation();
      onChange(dayjs());
    },
    [onChange],
  );

  // Render datepicker if open
  const datePicker = !picker.isOpen ? null : (
    <DatePicker>
      <Header>
        <Arrow
          className="material-icons"
          onClick={() => changeViewMonth(-1)}
        >
          keyboard_arrow_left
        </Arrow>
        <Title>{`${viewDate.format("MMMM")}, ${viewDate.format("YYYY")}`}</Title>
        <Arrow
          className="material-icons"
          onClick={() => changeViewMonth(1)}
        >
          keyboard_arrow_right
        </Arrow>
      </Header>
      <Calendar>
        {calendarModel.calendar.map((dayNumbers, dayIdx) => {
          return (
            <CalendarColumn key={dayIdx}>
              <CalendarDayTitle>{dayNames[dayIdx]}</CalendarDayTitle>
              {dayNumbers.map((x) => (
                <CalendarDay
                  key={x.date.valueOf()}
                  today={x.today}
                  selected={x.selected}
                  currentMonth={x.currentMonth}
                  onClick={() => changeDay(x.date)}
                >
                  {x.date.date()}
                </CalendarDay>
              ))}
            </CalendarColumn>
          );
        })}
      </Calendar>
    </DatePicker>
  );

  // Render timepicker if required
  let timePicker;
  if (showTimePicker && picker.isOpen) {
    let hourOptions = [];
    let currentHour = dayjs().hour();
    let selectedHour = selected ? selected.hour() : null;
    for (let i = 0; i < 24; i++) {
      hourOptions.push(
        <TimeOption
          key={i}
          selected={i === selectedHour}
          current={i === currentHour}
          onClick={() => onChangeHour(i)}
          className={i === selectedHour ? "selected" : ""}
        >
          {("0" + i).slice(-2)}
        </TimeOption>,
      );
    }
    let minuteOptions = [];
    let currentMinute = dayjs().minute();
    let selectedMinute = selected ? selected.minute() : null;
    for (let i = 0; i < 60; i++) {
      minuteOptions.push(
        <TimeOption
          key={i}
          selected={i === selectedMinute}
          current={i === currentMinute}
          onClick={() => onChangeMinute(i)}
          className={i === selectedMinute ? "selected" : ""}
        >
          {("0" + i).slice(-2)}
        </TimeOption>,
      );
    }
    timePicker = (
      <TimePicker>
        <Header>
          <CurrentTime>{selected ? selected.format("HH:mm") : <>&nbsp;</>}</CurrentTime>
        </Header>
        <TimeColumns>
          <TimeColumn>
            <TimeTitle>Hour</TimeTitle>
            <TimeSelect ref={hourContainerRef}>
              <TimeOptionsWrapper>{hourOptions}</TimeOptionsWrapper>
            </TimeSelect>
          </TimeColumn>
          <TimeColumn>
            <TimeTitle>Minute</TimeTitle>
            <TimeSelect ref={minuteContainerRef}>
              <TimeOptionsWrapper>{minuteOptions}</TimeOptionsWrapper>
            </TimeSelect>
          </TimeColumn>
        </TimeColumns>
      </TimePicker>
    );
  }

  return (
    <Container ref={containerRef}>
      <DateLabel
        userDisabled={disabled}
        ref={inputRef}
        onClick={disabled ? null : picker.open}
        open={picker.isOpen}
        border={border}
        className={picker.isOpen ? "open" : "closed"}
      >
        {selected && <>{selected.format(format || (showTimePicker ? "MMMM Do YYYY, HH:mm" : "MMMM Do YYYY"))}</>}
        {!selected && <Placeholder>{placeholder}</Placeholder>}
      </DateLabel>
      {picker.isOpen && (
        <PickerContainer
          ref={pickerRef}
          alignLeft={alignLeft}
          alignTop={alignTop}
        >
          <Picker>
            {datePicker}
            {timePicker}
          </Picker>
          <ControlContainer>
            <div onClick={setToToday}>Today</div>
            {selected && allowNull && <div onClick={onClear}>Clear</div>}
          </ControlContainer>
        </PickerContainer>
      )}
    </Container>
  );
}

DayjsPicker.propTypes = {
  selected: PropTypes.instanceOf(dayjs),
  onChange: PropTypes.func,
  format: PropTypes.string,
  showTimePicker: PropTypes.bool,
  disabled: PropTypes.bool,
  alignTop: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  border: PropTypes.bool,
  placeholder: PropTypes.string,
  controlled: PropTypes.bool,
  allowNull: PropTypes.bool,
};
DayjsPicker.defaultProps = {
  showTimePicker: false,
  alignTop: false,
  border: true,
  controlled: false,
  placeholder: "Choose a date",
  allowNull: true,
};

export default DayjsPicker;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const DateLabel = styled.div`
  flex: 1 1 auto;
  padding: ${(props) => props.theme.forms.padding};
  border: ${(props) => props.theme.forms.borderWidth} solid transparent;
  border-radius: ${(props) => props.theme.forms.borderRadius};
  background-color: ${(props) => props.theme.colours.inputBackground};
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  i {
    margin: -0.25rem 0;
    color: ${(props) => props.theme.colours.contentLight};

    &:hover {
      color: ${(props) => props.theme.colours.primaryHighlight};
    }
  }

  ${(props) =>
    !props.userDisabled &&
    css`
      ${(props) =>
        props.open &&
        css`
          border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.primaryHighlight} !important;
          outline: none;
        `}
    `}

  ${(props) =>
    props.userDisabled &&
    css`
      background-color: ${(props) => props.theme.colours.contentBackgroundAlt};
      color: ${(props) => props.theme.colours.contentLight};
    `}
  
  ${(props) =>
    props.border &&
    css`
      border-color: ${(props) => props.theme.colours.border};
    `}
  
  ${(props) =>
    !props.border &&
    css`
      box-shadow: ${(props) => props.theme.forms.boxShadow};
      background-color: ${(props) => props.theme.colours.contentBackground};
    `}
`;

const Placeholder = styled.div`
  color: ${(props) => props.theme.colours.contentLight};
  opacity: 0.5;
  user-select: none;
`;

const PickerContainer = styled.div`
  z-index: 999;
  position: absolute;
  right: 0;
  left: auto;
  top: calc(100% + 1.2rem);
  border-radius: ${(props) => props.theme.forms.borderRadius};
  padding: 1.5rem;
  background-color: ${(props) => props.theme.colours.contentBackground};
  box-shadow: ${(props) => props.theme.forms.heavyBoxShadow};

  ${(props) =>
    props.alignTop &&
    css`
      top: calc(0% - 0.8rem);
      transform: translateY(-100%);
    `}

  ${(props) =>
    props.alignLeft &&
    css`
      left: 0;
      right: auto;
    `}
`;

const Picker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`;

const DatePicker = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const Header = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Arrow = styled.i`
  padding: 0.8rem;
  margin: -1.5rem -0.8rem;

  &:hover {
    color: ${(props) => props.theme.colours.primaryHighlight};
    cursor: pointer;
  }
`;

const Title = styled.div`
  flex: 1 1 auto;
  text-align: center;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.veryLarge};
`;

const Calendar = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

const CalendarColumn = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.8rem;

  &:last-child {
    margin-right: 0;
  }
`;

const CalendarDayTitle = styled.div`
  color: ${(props) => props.theme.colours.contentLight};
  font-weight: bold;
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
`;

const CalendarDay = styled.div`
  color: ${(props) => props.theme.colours.contentLight};
  opacity: 0.5;
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 0.8rem;

  &:hover {
    background-color: ${(props) => props.theme.colours.primaryHighlight};
    color: white;
    cursor: pointer;
  }

  ${(props) =>
    props.currentMonth &&
    css`
      color: ${(props) => props.theme.colours.content};
      opacity: 1;
    `}

  ${(props) =>
    props.today &&
    css`
      color: ${(props) => props.theme.colours.primary};
      font-weight: bold;
    `}
  
  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colours.primary};
      color: white;
      font-weight: bold;
    `}
`;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem 0.5rem 0 0.5rem;

  > div {
    align-self: flex-end;
    color: ${(props) => props.theme.colours.contentLight};
    text-decoration: underline;

    &:hover {
      color: ${(props) => props.theme.colours.primaryHighlight};
      cursor: pointer;
    }
  }
`;

const TimePicker = styled.div`
  flex: 0 0 auto;
  border-left: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const CurrentTime = styled(Title)`
  font-size: ${(props) => props.theme.fontSizes.veryLarge};
  padding-left: 1.5rem;
`;

const TimeColumns = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`;

const TimeColumn = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const TimeTitle = styled.div`
  color: ${(props) => props.theme.colours.contentLight};
  font-weight: bold;
  height: 2.8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0 0.8rem 0;
`;

const TimeSelect = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

const TimeOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 0;
  padding: 0 2.4rem;
`;

const TimeOption = styled.div`
  flex: 0 0 auto;
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 0.8rem;

  &:hover {
    background-color: ${(props) => props.theme.colours.primary};
    color: white;
    cursor: pointer;
  }

  &:first-child {
    margin-top: 0;
  }

  ${(props) =>
    props.current &&
    css`
      color: ${(props) => props.theme.colours.primary};
      font-weight: bold;
    `}

  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colours.primary};
      color: white;
      font-weight: bold;
    `}
`;
