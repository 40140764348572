import styled from "styled-components";

export const Container = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  flex: 0 0 ${(props) => props.theme.dimensions.topBarHeight}rem;
  width: ${(props) => props.theme.dimensions.mainMenuCollapsedWidth}rem;
  align-self: flex-start;

  &:hover {
    cursor: pointer;
  }
`;

export const Logo = styled.img`
  width: ${(props) => props.size * 2.6}rem;
  flex: 0 0 auto;
`;
