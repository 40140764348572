import {observable, computed, flow} from "mobx";

import Api from "sensoteq-react-core/services/api";
import LoadableStore from "sensoteq-react-core/models/LoadableStore";

export default class SiteStore extends LoadableStore {
  @observable.shallow _sites;

  constructor(rootStore) {
    super(rootStore);
  }

  @computed get loading() {
    return !!this._loadingCount || this.rootStore.machineStore.loading;
  }

  @computed get sites() {
    if (!this._sites) {
      return [];
    }

    // Enrich sites with machines
    let siteMachineAllocations = {};
    this.rootStore.machineStore.machines.forEach((machine) => {
      if (!siteMachineAllocations[machine.site_name]) {
        siteMachineAllocations[machine.site_name] = [];
      }
      siteMachineAllocations[machine.site_name].push(machine);
    });

    return this._sites
      .map((site, idx) => {
        const devices = this.rootStore.gatewayStore.allocatedDevicesBySite(site.site_name);
        const deviceNames = devices.map((device) => device.name);

        let enrichedSite = {
          ...site,
          display_name: site.display_name ?? site.site_name,
          machines: siteMachineAllocations[site.site_name] || [],
          devices: devices || [],
          deviceNames: deviceNames,
        };
        if (this.rootStore.preferenceStore.obscureNames) {
          enrichedSite.address = "Address hidden";
          enrichedSite.display_name = `Site ${idx + 1}`;
        }

        return enrichedSite;
      })
      .sort(({display_name: nameA}, {display_name: nameB}) => {
        if (nameA === nameB) {
          return 0;
        }
        return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1;
      });
  }

  @computed get _reverseLookupMap() {
    let lookupMap = [];
    this.sites.forEach((site) => {
      lookupMap[site.site_name] = site;
    });
    return lookupMap;
  }

  @computed get _reverseGatewayLookupMap() {
    let map = {};
    this.sites.forEach((site) => {
      site.devices.forEach((device) => {
        map[device.name] = site;
      });
    });
    return map;
  }

  siteExists(siteName) {
    return this.getSite(siteName) != null;
  }

  getSite(siteName) {
    return this._reverseLookupMap[siteName];
  }

  getSiteForGateway(gateway) {
    return this._reverseGatewayLookupMap[gateway];
  }

  getMachinesForSite(site) {
    if (site == null) {
      return [];
    }
    const siteInfo = this.sites.find((x) => x.site_name === site);
    if (siteInfo) {
      return siteInfo.machines;
    }
    return [];
  }

  getGatewaysForSite(site) {
    if (site == null) {
      return [];
    }
    const siteInfo = this.sites.find((x) => x.site_name === site);
    if (siteInfo) {
      return siteInfo.devices;
    }
    return [];
  }

  getDisplayNameForSite(siteName) {
    const site = this.getSite(siteName);
    return site == null ? siteName : site.display_name;
  }

  loadSites = flow(function* (showLoading = true) {
    this.startFetching();
    if (showLoading) {
      this.startLoading();
    }
    try {
      const sites = yield Api.getSites();
      this._sites = sites.sites;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting sites: ${error}`, "bad");
    }
    if (showLoading) {
      this.stopLoading();
    }
    this.stopFetching();
  });

  refreshSite = flow(function* (siteName) {
    this.startLoading();
    try {
      const sites = yield Api.getSites(siteName);
      if (sites.sites && sites.sites.length === 1) {
        const site = sites.sites[0];
        const index = this._sites.findIndex((x) => x.site_name === siteName);
        if (index === -1) {
          this._sites.push(site);
        } else {
          this._sites[index] = site;
        }
      } else if (sites.sites && sites.sites.length === 0) {
        const index = this._sites.findIndex((x) => x.site_name === siteName);
        if (index !== -1) {
          this._sites.splice(index, 1);
        }
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting site: ${error}`, "bad");
    }
    this.stopLoading();
  });

  createSite = flow(function* (coordinates, address, displayName) {
    this.startLoading();
    try {
      const {site_id} = yield Api.createSite(coordinates, address, displayName);
      this.refreshSite(site_id);
      this.rootStore.notificationStore.addNotification("Site created successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error creating site: ${error}`, "bad");
    }
    this.stopLoading();
  });

  deleteSite = flow(function* (siteName) {
    this.startLoading();
    try {
      yield Api.deleteSite(siteName);
      const index = this._sites.findIndex((x) => x.site_name === siteName);
      if (index !== -1) {
        this._sites.splice(index, 1);
      }
      this.rootStore.notificationStore.addNotification("Site deleted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting site: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateSite = flow(function* (siteName, coordinates, address, display_name, notes = null) {
    this.startLoading();
    try {
      yield Api.updateSite(siteName, coordinates, address, display_name, notes);
      this.refreshSite(siteName);
      this.rootStore.notificationStore.addNotification("Site updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating site: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateSiteGatewayOwnership = flow(function* (gateways, siteName, gatewaysToUnallocate) {
    this.startLoading();
    try {
      yield Api.updateOwnerGatewayOwnership(gateways, siteName, "site", gatewaysToUnallocate);
      this.refreshSite(siteName);
      this.rootStore.notificationStore.addNotification("Gateway updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating gateway: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateSiteLocations = flow(function* (siteName, devices) {
    this.startLoading();
    try {
      yield Api.updateSiteLocations(siteName, devices);
      this.refreshSite(siteName);
      this.rootStore.notificationStore.addNotification("Site updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating site locations: ${error}`, "bad");
    }
    this.stopLoading();
  });

  uploadSiteMap = flow(function* (siteName, formData) {
    this.startLoading();
    try {
      yield Api.uploadSiteMap(siteName, formData);
      this.refreshSite(siteName);
      this.rootStore.notificationStore.addNotification("Site map uploaded successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error uploading site map: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
