import {flow} from "mobx";

import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import EventSubscription from "models/EventSubscription";
import Api from "sensoteq-react-core/services/api";

export default class EventStore extends SubscribableStore {
  constructor(rootStore) {
    super(rootStore, EventSubscription);
  }

  deleteEvent = flow(function* (eventId) {
    this.startLoading();
    try {
      yield Api.deleteEvent(eventId);
      this.refreshSubscriptions();
      this.rootStore.notificationStore.addNotification("Event deleted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting event: ${error}`, "bad");
    }
    this.stopLoading();
  });

  createEvent = flow(function* (name, description, timestamp, target, metadata) {
    this.startLoading();
    try {
      yield Api.createEvent(name, description, timestamp, target, metadata);
      this.refreshSubscriptions();
      this.rootStore.notificationStore.addNotification("Event created successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error creating event: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateEvent = flow(function* (eventId, name, description, metadata) {
    this.startLoading();
    try {
      yield Api.updateEvent(eventId, name, description, metadata);
      this.refreshSubscriptions();
      this.rootStore.notificationStore.addNotification("Event updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating event: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
