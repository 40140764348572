import {computed} from "mobx";

import MultiDataSubscription from "./MultiDataSubscription";
import {sortArrayByProperty} from "sensoteq-react-core/services/utils";

export default class MultiEventSubscription extends MultiDataSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      getSubscribableStore: (x) => x.eventStore,
      identifyingParam: "target",
    };
  }

  @computed get events() {
    let events = [];
    this.subscriptions.forEach((sub) => {
      events = events.concat(sub.events);
    });
    sortArrayByProperty(events, "timestamp");
    return events;
  }

  @computed get continuousDataEvents() {
    let events = [];
    this.subscriptions.forEach((sub) => {
      events = events.concat(sub.continuousDataEvents);
    });
    sortArrayByProperty(events, "timestamp");
    return events;
  }
}
