import {observable, flow, computed} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class ApiKeySubscription extends DataSubscription {
  getDefaultParams() {
    return {
      username: null,
    };
  }

  @observable _apiKeys;

  getData = flow(function* ({username}) {
    if (!username) {
      return;
    }
    this.startLoading();
    try {
      const data = yield Api.getUserApiKeys(username);
      this._apiKeys = data.apikeys;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting API keys: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get apiKeys() {
    return this._apiKeys ?? [];
  }

  sync(username) {
    if (username === this.params.username) {
      this.refresh();
    }
  }
}
