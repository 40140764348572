import {action, computed} from "mobx";
import dayjs from "dayjs";

import MultiDataSubscription from "./MultiDataSubscription";

export default class SinglePointMultiKeyDataSubscription extends MultiDataSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      keys: null,
      getSubscribableStore: (x) => x.timeDomainDataStore,
      getAverageType: () => this.rootStore.uiStore.continuousDataConfig.averageType,
      fMin: null,
    };
  }

  @action update(params = {}, replaceAllParams = false) {
    if (this.params.keys !== params.keys) {
      super.update(
        {
          ...params,
          identifiers: params.keys,
        },
        replaceAllParams,
      );
    }
  }

  @action getData(params) {
    let newSubs = [];
    const {identifiers, getSubscribableStore} = params;

    // Check points
    this.startLoading();
    if (identifiers) {
      identifiers.forEach((entry) => {
        // If we already have a sub for this point, keep it
        const sub = this.subscriptionLookupMap[entry.entry_id];
        if (sub != null && !this.config.recreate) {
          newSubs.push(sub.sub);
          this.subscriptions[sub.index] = null;
        }

        // Otherwise create a new one
        else {
          const store = getSubscribableStore(this.rootStore);
          const newSub = store.subscribe(`${this.config.id}-${entry.entry_id}`, {
            recreate: this.config.recreate,
          });
          newSub.update({
            pointId: entry.p_id,
            fMin: this.params.fMin,
            getKey: () => entry,
          });
          newSubs.push(newSub);
        }
      });
    }

    // Cancel all unused subs
    this.subscriptions.forEach((sub) => {
      if (sub != null) {
        sub.cancel();
      }
    });

    // Set new subs
    this.subscriptions = newSubs;
    this.stopLoading();
  }

  @computed get entryIds() {
    return this.identifiers;
  }

  extractData(getData) {
    let data = [];
    this.subscriptions
      .filter((sub) => sub.key)
      .forEach((sub) => {
        let subData = getData(sub);

        if (subData != null) {
          data.push({
            value: subData,
            timestamp: sub.key.timestamp,
            date: dayjs(sub.key.timestamp),
          });
        }
      });
    return [
      {
        data,
      },
    ];
  }

  @computed get displacementPeakToPeakValues() {
    return this.extractData((x) => x.displacementPeakToPeakValue);
  }

  @computed get accelerationRPMValues() {
    return this.extractData((x) => x.accelerationRPMValue);
  }
}
