import {createContext, useContext} from "react";

export const FormStoreContext = createContext(null);
export const FormApiContext = createContext(null);
export const FormStateContext = createContext(null);

export function useFormStore() {
  return useContext(FormStoreContext);
}

export function useFormApi() {
  return useContext(FormApiContext);
}

export function useFormState() {
  return useContext(FormStateContext);
}
