import {observable, computed, flow} from "mobx";
import {parse} from "useragent-parser-js";

import Api from "sensoteq-react-core/services/api";
import SessionSubscription from "models/SessionSubscription";
import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";

export default class SessionStore extends SubscribableStore {
  @observable.shallow _sessions;

  constructor(rootStore) {
    super(rootStore, SessionSubscription);
  }

  loadSessions = flow(function* () {
    this.startLoading();
    try {
      const data = yield Api.getSessions();
      this._sessions = data.sessions;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting sessions: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get sessions() {
    if (!this._sessions) {
      return [];
    }
    return this._sessions.map((session) => ({
      ...session,
      ua: this.parseUserAgent(session.user_agent || ""),
    }));
  }

  @computed get otherSessions() {
    return this.sessions.filter((x) => x.token !== this.rootStore.userStore.token);
  }

  expireSession = flow(function* (token) {
    try {
      yield Api.expireSession(token);
      yield this.loadSessions();
      this.rootStore.notificationStore.addNotification("Session expired successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error expiring session: ${error}`, "bad");
    }
  });

  expireSessionForUser = flow(function* (token) {
    try {
      yield Api.expireSessionForUser(token);
      yield this.refreshSubscriptions();
      this.rootStore.notificationStore.addNotification("Session expired successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error expiring session: ${error}`, "bad");
    }
  });

  parseUserAgent(userAgent) {
    let ua = parse(userAgent);
    if (ua.source.startsWith("okhttp")) {
      ua.platform = "Android";
      ua.browser = "Install App";
    }
    return ua;
  }
}
