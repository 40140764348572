import React from "react";
import ReactDOM from "react-dom";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

import {Form, Button, Row} from "../forms";
import LoadingAnimation from "./LoadingAnimation";
import {usePopupContext} from "./PopupContext";

export const Depth = {
  One: 1,
  Two: 2,
  Three: 3,
};

const Popup = (props) => {
  const portalId = usePopupContext();
  const mouseDownEl = React.useRef(null);
  const mouseUpEl = React.useRef(null);
  const {
    title,
    onConfirm,
    onCancel,
    confirmText,
    cancelText,
    wide,
    initialValues,
    getApi,
    children,
    additionalButtons,
    loading,
    hideCancelButton,
    allowOverflow,
    loadingOverlay,
    offset,
    hideConfirmButton,
    disableConfirmButton = false,
    titleContent,
  } = props;
  const depth = Math.max(Depth.One, Math.min(Depth.Three, props.depth));

  // Close on escape press
  const onKeyPress = React.useCallback(
    (event) => {
      if (event.which === 27 && onCancel) {
        const layer1 = document.getElementsByClassName(`${portalId}-popup1`)[0] == null ? 0 : 1;
        const layer2 = document.getElementsByClassName(`${portalId}-popup2`)[0] == null ? 0 : 2;
        const layer3 = document.getElementsByClassName(`${portalId}-popup3`)[0] == null ? 0 : 3;
        const maxDepth = Math.max(layer1, layer2, layer3);
        if (depth === maxDepth) {
          onCancel();
        }
      }
    },
    [onCancel, depth],
  );

  // Bind keypress handler and mount
  React.useEffect(() => {
    window.addEventListener("keyup", onKeyPress);
    return () => {
      window.removeEventListener("keyup", onKeyPress);
    };
  }, [onKeyPress]);

  // Check that there isn't another popup at this depth on mount
  React.useEffect(() => {
    const elements = document.getElementsByClassName(`${portalId}-popup${depth}`);
    if (elements.length > 1) {
      throw `A popup already exists at depth ${depth}`;
    }
  }, []);

  const onMouseDown = React.useCallback((el) => {
    mouseDownEl.current = el;
  }, []);

  const onMouseUp = React.useCallback((el) => {
    mouseUpEl.current = el;
  }, []);

  const onClick = React.useCallback(() => {
    if (mouseDownEl.current === "parent" && mouseUpEl.current === "parent") {
      onCancel();
    }
  }, [onCancel]);

  let content = <LoadingAnimation showText={false} />;
  if (!loading) {
    content = (
      <React.Fragment>
        <Form
          onSubmit={onConfirm}
          initialValues={initialValues}
          getApi={getApi}
        >
          {(formParams) => (
            <React.Fragment>
              {(title || titleContent) && (
                <TitleContainer>
                  <Title>{title ?? ""}</Title>
                  {titleContent && <TitleContentContainer>{titleContent}</TitleContentContainer>}
                </TitleContainer>
              )}
              {typeof children === "function" ? children(formParams) : children}
              {(!hideConfirmButton || !hideCancelButton || additionalButtons) && (
                <Row auto={wide}>
                  {additionalButtons && (
                    <Row
                      buttons={wide}
                      left
                    >
                      {additionalButtons}
                    </Row>
                  )}
                  {(!hideConfirmButton || !hideCancelButton) && (
                    <Row buttons={wide}>
                      {!hideCancelButton && (
                        <Button
                          icon="clear"
                          text={cancelText}
                          onClick={onCancel}
                        />
                      )}
                      {!hideConfirmButton && (
                        <Button
                          theme={Button.Themes.Good}
                          icon="keyboard_arrow_right"
                          iconAfterText={true}
                          text={confirmText}
                          disabled={disableConfirmButton}
                          type="submit"
                        />
                      )}
                    </Row>
                  )}
                </Row>
              )}
            </React.Fragment>
          )}
        </Form>
        {loadingOverlay && <LoadingAnimation showText={false} />}
      </React.Fragment>
    );
  }

  return ReactDOM.createPortal(
    <Container
      offset={offset}
      depth={depth}
      className={`${portalId}-popup${depth}`}
      onMouseDown={() => onMouseDown("parent")}
      onMouseUp={() => onMouseUp("parent")}
      onClick={onClick}
    >
      <ScrollWrapper>
        <ContentWrapper>
          <Content
            isLoading={loading}
            wide={wide}
            depth={depth}
            allowOverflow={allowOverflow}
            onMouseDown={(e) => {
              onMouseDown("child");
            }}
            onMouseUp={(e) => {
              onMouseUp("child");
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {content}
          </Content>
        </ContentWrapper>
      </ScrollWrapper>
    </Container>,
    document.getElementById(portalId),
  );
};

Popup.Depth = Depth;
Popup.propTypes = {
  title: PropTypes.any,
  titleContent: PropTypes.any,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  wide: PropTypes.bool,
  initialValues: PropTypes.object,
  getApi: PropTypes.func,
  depth: PropTypes.oneOf([Depth.One, Depth.Two, Depth.Three]),
  additionalButtons: PropTypes.any,
  loading: PropTypes.bool,
  loadingOverlay: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  hideConfirmButton: PropTypes.bool,
  allowOverflow: PropTypes.bool,
  offset: PropTypes.number,
  children: PropTypes.node,
};
Popup.defaultProps = {
  title: "Confirmation required",
  onConfirm: () => {},
  onCancel: () => {},
  confirmText: "Confirm",
  cancelText: "Cancel",
  wide: false,
  depth: Depth.One,
  loading: false,
  loadingOverlay: false,
  hideCancelButton: false,
  hideConfirmButton: false,
  allowOverflow: false,
  offset: 0,
};

export default Popup;

const Container = styled.div`
  position: absolute;
  width: calc(100% - ${(props) => props.offset}px);
  height: 100%;
  top: 0;
  left: ${(props) => props.offset}px;
  z-index: ${(props) => props.depth + 1000};
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);

  ${(props) =>
    props.theme.mixins.mobile(css`
      width: 100%;
      left: 0;
    `)}
`;

const ScrollWrapper = styled.div`
  flex: 1 1 auto;
  //overflow-y: auto;
  //overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  max-height: 100%;
`;

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 0;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.colours.contentBackground};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: ${(props) => props.theme.forms.borderRadius};
  padding: ${(props) => props.theme.dimensions.vPadding}rem ${(props) => props.theme.dimensions.hPadding}rem 0
    ${(props) => props.theme.dimensions.hPadding}rem;
  box-shadow: 0 0 2.4rem 1.5rem rgba(0, 0, 0, 0.15);
  position: relative;
  max-width: 36rem;
  min-width: 28rem;
  flex: 1 1 auto;
  margin: 2rem 0;

  ${(props) =>
    props.wide &&
    css`
      max-width: 100rem;

      ${(props) =>
        props.depth === Depth.Two &&
        css`
          max-width: calc(95rem);
        `}

      ${(props) =>
        props.depth === Depth.Three &&
        css`
          max-width: calc(90rem);
        `}
    `}

  ${(props) =>
    props.isLoading &&
    css`
      height: 6rem;
      padding-bottom: ${(props) => props.theme.dimensions.vPadding}rem;
    `}

  ${(props) =>
    !props.isLoading &&
    css`
      // HACK fix firefox not respecting padding bottom with overflow:auto and scrolling.
      // https://bugzilla.mozilla.org/show_bug.cgi?id=748518
      form > :last-child {
        padding-bottom: ${(props) => props.theme.dimensions.vPadding}rem;
      }
    `}

  ${(props) =>
    props.theme.mixins.mobile(css`
      width: calc(100% - 3rem);
      margin: 5rem 1.5rem;
    `)}
`;

const Title = styled.div`
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  flex: 1 1 auto;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const TitleContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  flex: 0 1 auto;
  flex-wrap: wrap;

  > * {
    margin-right: 0.8rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;
