// Time option constants
export const TIME_OPTION_LATEST = "latest";
export const TIME_OPTION_1D = "1d";
export const TIME_OPTION_1W = "1w";
export const TIME_OPTION_1M = "1m";
export const TIME_OPTION_6M = "6m";
export const TIME_OPTION_1Y = "1y";
export const TIME_OPTION_5Y = "5y";
export const TIME_OPTION_CUSTOM = "custom";
export const TIME_OPTION_RANGE = "range";
export const TIME_OPTION_MULTI = "multi";
export const TIME_OPTIONS = [
  TIME_OPTION_LATEST,
  TIME_OPTION_1D,
  TIME_OPTION_1W,
  TIME_OPTION_1M,
  TIME_OPTION_6M,
  TIME_OPTION_1Y,
  TIME_OPTION_5Y,
  TIME_OPTION_CUSTOM,
  TIME_OPTION_RANGE,
  TIME_OPTION_MULTI,
];

//Alarm Types
export const ALARM_TYPE_GOOD = "good";
export const ALARM_TYPE_WARNING = "warning";
export const ALARM_TYPE_CRITICAL = "critical";
export const ALARM_TYPE_OFFLINE = "offline";
export const ALARM_TYPE_DISABLED = "default";
export const ALARM_TYPES = [
  ALARM_TYPE_GOOD,
  ALARM_TYPE_WARNING,
  ALARM_TYPE_CRITICAL,
  ALARM_TYPE_OFFLINE,
  ALARM_TYPE_DISABLED,
];

// Axis constants
export const HORIZONTAL_AXIS = "h";
export const VERTICAL_AXIS = "v";
export const AXIAL_AXIS = "a";
export const X_AXIS = "x";
export const Y_AXIS = "y";
export const Z_AXIS = "z";
export const AXES = [HORIZONTAL_AXIS, VERTICAL_AXIS, AXIAL_AXIS];
export const RAW_AXES = [X_AXIS, Y_AXIS, Z_AXIS];

// Resolution constants
export const HIGH_RES = "high";
export const LOW_RES = "low";
export const RESOLUTIONS = [HIGH_RES, LOW_RES];

// CAD viewing options
export const HOVER = "hover";
export const ALL = "all";

// Dimension options
export const THREE_DIMENSION = "3d";
export const TWO_DIMENSION = "2d";

// Domain options
export const ACCELERATION_DOMAIN = "acc";
export const VELOCITY_DOMAIN = "vel";
export const DISPLACEMENT_DOMAIN = "dsp";
export const DOMAINS = [ACCELERATION_DOMAIN, DISPLACEMENT_DOMAIN, VELOCITY_DOMAIN];

// User privilege levels
export const PRIVILEGE_LEVEL_USER = "0";
export const PRIVILEGE_LEVEL_ADMIN = "1";
export const PRIVILEGE_LEVEL_INSTALLER = "2";
export const PRIVILEGE_LEVEL_PARTNER = "3";
export const PRIVILEGE_LEVELS = [
  PRIVILEGE_LEVEL_USER,
  PRIVILEGE_LEVEL_ADMIN,
  PRIVILEGE_LEVEL_INSTALLER,
  PRIVILEGE_LEVEL_PARTNER,
];

// Average options
export const AVERAGE_OPTION_MIN = 0;
export const AVERAGE_OPTION_AVG = 1;
export const AVERAGE_OPTION_MAX = 2;
export const AVERAGE_OPTIONS = [AVERAGE_OPTION_MIN, AVERAGE_OPTION_AVG, AVERAGE_OPTION_MAX];
