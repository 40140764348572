import {observable, computed, flow} from "mobx";

import Api from "sensoteq-react-core/services/api";
import LoadableStore from "sensoteq-react-core/models/LoadableStore";

export default class AdminSensorStore extends LoadableStore {
  @observable.shallow _sensors = [];

  constructor(rootStore) {
    super(rootStore);
  }

  @computed get sensors() {
    return (this._sensors ?? [])
      .map((x) => ({
        ...x,
        frequency: this.rootStore.sensorStore.getFrequency(x),
      }))
      .slice();
  }

  @computed get _reverseLookupMap() {
    let lookupMap = {};
    this.sensors.forEach((sensor) => {
      lookupMap[sensor.sensor_id] = sensor;
    });
    return lookupMap;
  }

  getSensor(sensorId) {
    return this._reverseLookupMap[sensorId];
  }

  loadSensors = flow(function* () {
    // Do nothing if not an admin
    if (!this.rootStore.userStore.admin) {
      return;
    }
    this.startLoading();
    try {
      const sensors = yield Api.getAllSensorIDs();
      this._sensors = sensors.sensors;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting all sensors: ${error}`, "bad");
    }
    this.stopLoading();
  });

  generateSensorId = flow(function* (description, type) {
    let result;
    this.startLoading();
    try {
      const sensorId = yield Api.generateSensorId(description, type);
      result = sensorId.sensor_id;
      this.rootStore.notificationStore.addNotification("Sensor ID generated successfully", "good");
      this.loadSensors();
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error generating sensor ID: ${error}`, "bad");
    }
    this.stopLoading();
    return result;
  });

  updateSensorInfo = flow(function* (sensorId, type, description) {
    this.startLoading();
    try {
      yield Api.updateSensorInfo(sensorId, type, description);
      this.rootStore.notificationStore.addNotification("Sensor updated successfully", "good");
      this.loadSensors();
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating sensor info: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
