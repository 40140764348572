import React, {useCallback, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import ReactTooltip from "react-tooltip";
import NotificationDropdown from "./NotificationDropdown";
import SearchBar from "./SearchBar";
import ProfileDropdown from "./ProfileDropdown";
import SettingsPageRoutes from "routes/settings/settings-routes";
import {useRootStore} from "stores";
import ActionIcon from "components/common/ActionIcon";
import * as Styled from "./TopBar.styles";
import PropTypes from "prop-types";
import ToggleV2 from "components/layout/ToggleV2";
import {debounce} from "lodash";
import {useIsMobile} from "components/hooks/useBreakpoints";
import Icon from "components/common/Icon";
import {useIsReportDockVisible} from "../../ReportWrapper";

const BUFFER = 30;

const TopBar = ({children, shadow = true, border = false, withTopMenu = false, ...otherProps}) => {
  const {userStore, uiStore, preferenceStore, routerStore, configStore} = useRootStore();
  const {topMenuVisible, mainMenuVisible} = uiStore;

  const isMobile = useIsMobile();
  const isReportDockVisible = useIsReportDockVisible();
  const [isNavbarNarrow, setIsNavbarNarrow] = useState(isMobile);

  const navbarRef = useRef(null);
  const breadcrumbRef = useRef(null);
  const iconsRef = useRef(null);

  const onToggleMainMenuMobile = () => {
    uiStore.setMainMenuVisibleMobile(!uiStore.mainMenuVisibleMobile);

    // Hide top menu if showing main menu
    if (uiStore.mainMenuVisibleMobile) {
      uiStore.showTopMenu(false);
    }
  };

  const onToggleTopMenu = () => {
    uiStore.showTopMenu(!uiStore.topMenuVisible);
  };

  const onClickOption = () => {
    uiStore.showTopMenu(false);
  };

  const toggleObscuration = () => {
    preferenceStore.updateMiscPreferences({
      ...(preferenceStore.miscPreferences || {}),
      obscureNames: !preferenceStore.obscureNames,
    });
  };

  const toggleTheme = () => {
    if (preferenceStore.themePreferences?.userTheme === "Light") {
      preferenceStore.updateThemePreferences({
        ...preferenceStore.themePreferences,
        userTheme: "Dark",
      });
    } else {
      preferenceStore.updateThemePreferences({
        ...preferenceStore.themePreferences,
        userTheme: "Light",
      });
    }
  };

  const checkOverflow = useCallback(() => {
    const breadcrumbContainer = breadcrumbRef.current;
    const iconsContainer = iconsRef.current;
    if (breadcrumbContainer && iconsContainer) {
      if (
        breadcrumbContainer.offsetWidth + iconsContainer.offsetWidth + BUFFER >
        breadcrumbContainer.parentElement.offsetWidth
      ) {
        setIsNavbarNarrow(true);
        uiStore.setMainMenuVisibleResponsive(true);
        uiStore.showTopMenu(true);
      } else {
        setIsNavbarNarrow(false);
        uiStore.setMainMenuVisibleResponsive(false);
        uiStore.showTopMenu(false);
      }
    }
  }, [
    breadcrumbRef?.current?.parentElement.offsetWidth,
    breadcrumbRef?.current?.offsetWidth,
    iconsRef?.current?.offsetWidth,
  ]);

  const debouncedCheckOverflow = useCallback(debounce(checkOverflow, 200), [checkOverflow]);

  useEffect(() => {
    if (isMobile) return;
    debouncedCheckOverflow();
    const resizeObserver = new ResizeObserver(() => {
      debouncedCheckOverflow();
    });
    if (navbarRef.current) {
      resizeObserver.observe(navbarRef.current);
    }

    return () => {
      if (navbarRef.current) {
        resizeObserver.unobserve(navbarRef.current);
      }
    };
  }, [isMobile, navbarRef.current]);

  return (
    <Styled.Container
      ref={navbarRef}
      shadow={shadow}
      border={border}
      {...otherProps}
    >
      <Styled.MenuIconContainer>
        <ReactTooltip
          id="hide-menu"
          place="right"
          type="dark"
          effect="solid"
        >
          {mainMenuVisible ? "Hide menu" : "Show menu"}
        </ReactTooltip>
        <Styled.MobileMenuToggle
          className="material-icons"
          onClick={onToggleMainMenuMobile}
        >
          menu
        </Styled.MobileMenuToggle>
      </Styled.MenuIconContainer>
      <Styled.BreadcrumbContainer
        mobileOverride={isNavbarNarrow}
        ref={breadcrumbRef}
        className="!flex-grow !flex-shrink-0 !basis-auto flex !items-center !justify-start"
      >
        {React.Children.map(children, (child) => {
          if (child && child.props && child.props.displayFn !== undefined) {
            return React.cloneElement(child, {mobileExpanded: topMenuVisible, onClickOption: onClickOption});
          } else {
            return child;
          }
        })}
      </Styled.BreadcrumbContainer>
      <Styled.Controls ref={iconsRef}>
        <SearchBar />
        {isMobile ||
          (!uiStore.mainMenuVisibleMobile && (
            <div className="flex items-center sm:hidden lg:flex space-x-4">
              <ToggleV2 />

              <ActionIcon
                tooltip="Change Theme"
                onClick={toggleTheme}
                icon={"dark_mode"}
              />
            </div>
          ))}

        {isReportDockVisible && (
          <ActionIcon
            tooltip="Show Report Editor"
            onClick={() => uiStore.setShowDockedReportEditor(!uiStore.showDockedReportEditor)}
            library={Icon.Libraries.FontAwesome}
            icon={"file-chart-line"}
          />
        )}

        {/* Notifications */}
        <NotificationDropdown />

        {/* Profile Menu Dropdown */}
        <ProfileDropdown
          dropdownItems={[
            ...(isMobile || uiStore.mainMenuVisibleMobile
              ? [
                  {
                    type: "toggle",
                    key: "toggleV2DropdownItem",
                  },
                  {
                    type: "button",
                    key: "changeThemeDropdownItem",
                    icon: "dark_mode",
                    text: "Change Theme",
                    onClick: toggleTheme,
                  },
                ]
              : []),
            ...[
              {
                type: "button",
                key: "accountSettingsDropdownItem",
                icon: "person",
                text: "Account Settings",
                onClick: () => routerStore.navigate(SettingsPageRoutes.SettingsAccountTab),
              },
              {
                type: "button",
                key: "obscureProfileDropdownItem",
                icon: preferenceStore.obscureNames ? "visibility_off" : "visibility",
                text: "Obscure",
                onClick: () => toggleObscuration(),
              },
              {
                type: "button",
                key: "logoutProfileDropdownItem",
                icon: "exit_to_app",
                text: "Logout",
                onClick: () => userStore.logOut("Logged Out successfully"),
              },
            ],
          ].filter(Boolean)}
        />

        {/* Mobile menu toggle */}
        {(isNavbarNarrow || withTopMenu) && (
          <Styled.TopMenuToggle
            mobileOverride={isNavbarNarrow}
            onClick={onToggleTopMenu}
            className="material-icons"
          >
            more_vert
          </Styled.TopMenuToggle>
        )}
      </Styled.Controls>
    </Styled.Container>
  );
};

TopBar.propTypes = {
  shadow: PropTypes.bool,
  border: PropTypes.bool,
  withTopMenu: PropTypes.bool,
};

export default observer(TopBar);
