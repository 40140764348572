import {flow} from "mobx";

import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import GatewayFilterListSubscription from "models/GatewayFilterListSubscription";
import Api from "sensoteq-react-core/services/api";

export default class GatewayFilterListStore extends SubscribableStore {
  constructor(rootStore) {
    super(rootStore, GatewayFilterListSubscription);
  }

  async retrieve(gatewayName) {
    const data = await Api.getFilterList(gatewayName);
    return data.sensors;
  }

  updateFilterList = flow(function* (gatewayName, filterList) {
    this.startLoading();
    try {
      yield Api.updateFilterList(gatewayName, filterList);
      this.sync(gatewayName);
      this.rootStore.notificationStore.addNotification("Filter list updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating filter list: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
