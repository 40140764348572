import {ThemeProvider} from "styled-components";
import {observer} from "mobx-react";

import {useRootStore} from "stores";
import * as Styled from "./ThemeWrapper.styles";

function ThemeWrapper({children}) {
  const {themeStore} = useRootStore();
  return (
    <ThemeProvider theme={themeStore.theme}>
      <>
        <Styled.GlobalStyles />
        {children}
      </>
    </ThemeProvider>
  );
}

export default observer(ThemeWrapper);
