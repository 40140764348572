import {observable, runInAction, computed} from "mobx";
import {parse, stringify} from "query-string";
const createBrowserHistory = require("history").createBrowserHistory;

import matchPath from "./matchPath";

export default class RouterStore {
  rootStore;
  navigationCallback;
  encode;
  decode;
  hooks;
  history;

  // Observable router state
  @observable state = {
    queryParams: parse(window.location.search),
    location: window.location,
  };
  @computed get url() {
    return this.state.location.pathname + this.state.location.search;
  }

  constructor(rootStore, {encode = (x) => x, decode = (x) => x, hooks = [], onNavigation: navigationCallback}) {
    this.rootStore = rootStore;
    this.encode = encode;
    this.decode = decode;
    this.hooks = hooks;
    this.navigationCallback = navigationCallback;

    // Sync history
    this.history = createBrowserHistory();
    this.history.listen((location) =>
      runInAction(() => {
        this.state.queryParams = parse(location.search);
        this.state.location = location;

        // Perform any other necessary callbacks
        if (this.navigationCallback) {
          this.navigationCallback(location);
        }
      }),
    );
  }

  /**
   * Navigates to certain URL
   *
   * @param url the URL to navigate to
   */
  push(url) {
    if (url === this.url) {
      return;
    }
    this.history.push(url);
  }

  /**
   * Replaces the current URL with a new one
   *
   * @param url the URL to replace with
   */
  replace(url) {
    if (url === this.url) {
      return;
    }
    this.history.replace(url);
  }

  /**
   * Shortcut for push(createUrl)
   *
   * @param route the route to navigate to
   * @param routeParams the routeParams to use
   * @param queryParamOverrides any additional query param overrides on top of mapStateToQuery
   */
  navigate(route, routeParams, queryParamOverrides) {
    this.push(this.createUrl(route, routeParams, queryParamOverrides));
  }

  /**
   * Shortcut for replace(createUrl)
   *
   * @param route the route to navigate to
   * @param routeParams the routeParams to use
   * @param queryParamOverrides any additional query param overrides on top of mapStateToQuery
   */
  redirect(route, routeParams, queryParamOverrides) {
    this.replace(this.createUrl(route, routeParams, queryParamOverrides));
  }

  /**
   * Generates a URL for a given route
   *
   * @param route the route to navigate to
   * @param routeParams the routeParams to use
   * @param queryParamOverrides any additional query param overrides on top of mapStateToQuery
   * @returns {string}
   */
  createUrl(route, routeParams = {}, queryParamOverrides) {
    // Generate route
    let params = route.path.split("/").slice(1);
    let url = "";
    params.forEach((param) => {
      if (param.indexOf(":") === -1) {
        url += `/${param}`;
      } else {
        const split = param.split(":");
        const paramName = split[1].split("?")[0];
        const paramValue = routeParams[paramName];
        if (paramValue != null) {
          url += `/${split[0]}${this.encode(paramValue, route, paramName, this.rootStore)}`;
        }
      }
    });

    // Append query string
    let queryParams = {};
    if (route.mapStateToQuery) {
      queryParams = route.mapStateToQuery(this.rootStore);
    }

    // Set additional manual query parameters
    if (queryParamOverrides) {
      queryParams = {
        ...queryParams,
        ...queryParamOverrides,
      };
    }

    const queryString = stringify(queryParams);
    if (queryString) {
      url += `?${queryString}`;
    }

    return url;
  }

  /**
   * Processes all hooks for a given set of hook params and determines if a redirect is required
   *
   * @param hookParams the hook params to test
   * @returns {string} a redirect URL if one is require
   */
  processHooks(hookParams = {}) {
    let redirect;
    for (let i = 0; i < this.hooks.length; i++) {
      let redirection = this.hooks[i](this.rootStore, this.state, hookParams);
      if (redirection != null) {
        redirect = redirection;
        break;
      }
    }
    return redirect;
  }

  /**
   * Checks if a given route is currently active,
   * determined by pattern matching the route's path against the current URL
   *
   * @param route the route to test
   * @param exact whether to strictly match
   * @returns {boolean} if the route is active
   */
  isRouteActive(route, exact = false) {
    const match = matchPath(this.state.location.pathname, {
      path: route.path,
      exact,
    });
    return match != null;
  }
}
