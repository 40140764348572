import type {ObjectValues} from "../types.js";

export const FrequencyOption = {
  CPM: "CPM",
  HERTZ: "Hz",
} as const;

export const FrequencyRatio = {
  [FrequencyOption.CPM]: 60,
  [FrequencyOption.HERTZ]: 1,
};

export type Frequency = ObjectValues<typeof FrequencyOption>;
