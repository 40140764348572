import {when} from "mobx";

export default function (rootStore, siteName, timeOption) {
  const {siteStore} = rootStore;
  const site = siteStore.getSite(siteName);

  return new Promise((resolve) => {
    const sub = rootStore.alarmEventStore.subscribe(siteName, {recreate: true});
    sub.update({
      siteName,
      getTimeOption: () => timeOption,
    });
    when(
      () => !sub.initialLoad,
      () => {
        const siteStatus = sub.getSiteStatus(siteName);
        const machineStatuses = site.machines
          .map((machine) => ({
            status: sub.getMachineStatus(machine.machine_id),
            machine,
          }))
          .sort((a, b) => {
            const criticalityA = a.status.criticality;
            const criticalityB = b.status.criticality;
            if (criticalityA === criticalityB) {
              return 0;
            }
            return criticalityA > criticalityB ? -1 : 1;
          });
        sub.cancel();
        resolve(renderSiteStatus(rootStore, siteName, siteStatus, machineStatuses, timeOption));
      },
    );
  });
}

export function renderSiteStatus(rootStore, siteName, siteStatus, machineStatuses, timeOption) {
  const {siteStore, themeStore} = rootStore;
  const site = siteStore.getSite(siteName);

  let colour = themeStore.theme.colours.good;
  let statusTitle = "OK";
  if (siteStatus.hasCritical) {
    colour = themeStore.theme.colours.bad;
    statusTitle = "Critical issue";
  } else if (siteStatus.hasWarning) {
    colour = themeStore.theme.colours.warning;
    statusTitle = "Anomaly detected";
  }

  return (
    `<table style="width: 100%;">` +
    `<tbody>` +
    `<tr>` +
    `<td colspan="3" style="width: 100%; background-color: ${colour};">` +
    `<strong>` +
    `<span style="color: white;">` +
    `${site.display_name} - ${statusTitle}` +
    `</span>` +
    `</strong>` +
    `</td>` +
    `</tr>` +
    `<tr>` +
    `<td style="width: 60%; background-color: #ddd;">` +
    `<strong>Machine</strong>` +
    `</td>` +
    `<td style="width: 15%; background-color: #ddd;">` +
    `<strong>${timeOption.toUpperCase()} Alarms</strong>` +
    `</td>` +
    `<td style="width: 25%; background-color: #ddd;">` +
    `<strong>Status</strong>` +
    `</td>` +
    `</tr>` +
    machineStatuses
      .map((machineStatus) => {
        colour = themeStore.theme.colours.good;
        statusTitle = "OK";
        if (machineStatus.status.hasCritical) {
          colour = themeStore.theme.colours.bad;
          statusTitle = "Critical issue";
        } else if (machineStatus.status.hasWarning) {
          colour = themeStore.theme.colours.warning;
          statusTitle = "Anomaly detected";
        }
        return (
          `<tr>` +
          `<td style="width: 60%;">` +
          `${machineStatus.machine.label}` +
          `</td>` +
          `<td style="width: 15%;">` +
          `${machineStatus.status.criticalEvents.concat(machineStatus.status.warningEvents).length}` +
          `</td>` +
          `<td style="width: 25%; background-color: ${colour};">` +
          `<strong>` +
          `<span style="color: white;">` +
          `${statusTitle}` +
          `</span>` +
          `</strong>` +
          `</td>` +
          `</tr>`
        );
      })
      .join("") +
    `</tbody>` +
    `</table>` +
    `<p></p>`
  );
}
