import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartBar} from "@fortawesome/pro-duotone-svg-icons/faChartBar";
import {faAnalytics} from "@fortawesome/pro-duotone-svg-icons/faAnalytics";
import {faSirenOn} from "@fortawesome/pro-duotone-svg-icons/faSirenOn";
import {faBluetooth} from "@fortawesome/free-brands-svg-icons/faBluetooth";
import {faFileChartLine} from "@fortawesome/pro-duotone-svg-icons/faFileChartLine";
import {faFileContract} from "@fortawesome/pro-duotone-svg-icons/faFileContract";
import {faPencilRuler} from "@fortawesome/pro-duotone-svg-icons/faPencilRuler";
import {faCog} from "@fortawesome/pro-duotone-svg-icons/faCog";
import {faStar} from "@fortawesome/pro-duotone-svg-icons/faStar";
import {faBook} from "@fortawesome/pro-duotone-svg-icons/faBook";
import {faBookUser} from "@fortawesome/pro-duotone-svg-icons/faBookUser";
import {faQuestion} from "@fortawesome/pro-duotone-svg-icons/faQuestion";
import {faUserShield} from "@fortawesome/pro-duotone-svg-icons/faUserShield";
import {faInfoCircle} from "@fortawesome/pro-duotone-svg-icons/faInfoCircle";
import {faExclamationCircle} from "@fortawesome/pro-duotone-svg-icons/faExclamationCircle";
import {faExclamationTriangle} from "@fortawesome/pro-duotone-svg-icons/faExclamationTriangle";
import {faCheckCircle} from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import {faHandshake} from "@fortawesome/pro-duotone-svg-icons/faHandshake";
import {faSnooze} from "@fortawesome/pro-regular-svg-icons/faSnooze";
import {faFan} from "@fortawesome/pro-solid-svg-icons/faFan";
import {faRouter} from "@fortawesome/pro-duotone-svg-icons/faRouter";
import {faShieldCheck} from "@fortawesome/pro-duotone-svg-icons/faShieldCheck";
import {faMapMarkerAlt} from "@fortawesome/pro-duotone-svg-icons/faMapMarkerAlt";
import {faUsers} from "@fortawesome/pro-duotone-svg-icons/faUsers";
import {faSlidersHSquare} from "@fortawesome/pro-duotone-svg-icons/faSlidersHSquare";
import {faBellPlus} from "@fortawesome/pro-duotone-svg-icons/faBellPlus";
import {faMailBulk} from "@fortawesome/pro-duotone-svg-icons/faMailBulk";
import {faUserFriends} from "@fortawesome/pro-duotone-svg-icons/faUserFriends";
import {faChartNetwork} from "@fortawesome/pro-duotone-svg-icons/faChartNetwork";
import {faGlobeAmericas} from "@fortawesome/pro-duotone-svg-icons/faGlobeAmericas";
import {faBuilding} from "@fortawesome/pro-duotone-svg-icons/faBuilding";
import {faVials} from "@fortawesome/pro-duotone-svg-icons/faVials";
import {faConveyorBeltAlt} from "@fortawesome/pro-duotone-svg-icons/faConveyorBeltAlt";
import {faArrowFromLeft} from "@fortawesome/pro-duotone-svg-icons/faArrowFromLeft";
import {faArrowToLeft} from "@fortawesome/pro-duotone-svg-icons/faArrowToLeft";
import {faCodeCommit} from "@fortawesome/pro-solid-svg-icons/faCodeCommit";
import {faSignalStream} from "@fortawesome/pro-solid-svg-icons/faSignalStream";
import {faTags} from "@fortawesome/pro-duotone-svg-icons/faTags";

import * as Styled from "./Icon.styles";
import type {IconProp} from "@fortawesome/fontawesome-svg-core";

export const Libraries = {
  MaterialIcons: "material-icons",
  SensoteqIcons: "sensoteq-icons",
  FontAwesome: "font-awesome",
};

export const SensoteqIcons = {
  SensorLogoOutline: "A",
  SensorFilled: "B",
  SensorOutline: "C",
  Logo: "D",
  MotorFilled: "E",
  MotorOutline: "F",
};

const FontAwesomeMap: Record<string, unknown> = {
  "chart-bar": faChartBar,
  bluetooth: faBluetooth,
  analysis: faAnalytics,
  "siren-on": faSirenOn,
  "file-chart-line": faFileChartLine,
  "file-contract": faFileContract,
  "pencil-ruler": faPencilRuler,
  cog: faCog,
  book: faBook,
  question: faQuestion,
  "book-user": faBookUser,
  "user-shield": faUserShield,
  "exclamation-circle": faExclamationCircle,
  "exclamation-triangle": faExclamationTriangle,
  "check-circle": faCheckCircle,
  snooze: faSnooze,
  "info-circle": faInfoCircle,
  fan: faFan,
  router: faRouter,
  "shield-check": faShieldCheck,
  "map-marker-alt": faMapMarkerAlt,
  users: faUsers,
  "sliders-h-square": faSlidersHSquare,
  "bell-plus": faBellPlus,
  "mail-bulk": faMailBulk,
  "user-friends": faUserFriends,
  "chart-network": faChartNetwork,
  "globe-americas": faGlobeAmericas,
  building: faBuilding,
  vials: faVials,
  "conveyor-belt-alt": faConveyorBeltAlt,
  "arrow-to-left": faArrowToLeft,
  "arrow-from-left": faArrowFromLeft,
  handshake: faHandshake,
  "code-commit": faCodeCommit,
  "signal-stream": faSignalStream,
  star: faStar,
  tags: faTags,
};
type IconProps = {
  [x: string]: any;
  library?: string;
  name: string;
};
const Icon = (props: IconProps) => {
  const {library, name, ...otherProps} = props;
  if (library === Libraries.FontAwesome) {
    const {transform, style, ...otherOtherProps} = otherProps as {transform?: string; style?: Record<string, string>};
    return (
      <Styled.Container {...otherOtherProps}>
        <FontAwesomeIcon
          icon={FontAwesomeMap[name] as IconProp}
          {...(style && {style})}
          {...(transform && {transform})}
        />
      </Styled.Container>
    );
  } else {
    return (
      <Styled.Icon
        {...otherProps}
        className={library}
      >
        {name}
      </Styled.Icon>
    );
  }
};

Icon.Libraries = Libraries;
Icon.SensoteqIcons = SensoteqIcons;
Icon.propTypes = {
  library: PropTypes.string,
  name: PropTypes.string,
};
Icon.defaultProps = {
  library: Libraries.MaterialIcons,
};

export default Icon;
