import styled, {css} from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.colours.topBarBackground};
  justify-content: flex-start;
  align-items: center;
  height: ${(props) => props.theme.dimensions.topBarHeight}rem;
  // flex: 0 0 ${(props) => props.theme.dimensions.topBarHeight}rem;
  flex: 0 0 auto;
  // overflow-x: hidden;
  padding: 0 ${(props) => props.theme.dimensions.hMargin + props.theme.dimensions.hPagePadding}rem;
  position: relative;
  z-index: 10;

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: ${(props) => props.theme.forms.boxShadow};
    `}

  ${(props) =>
    props.border &&
    css`
      border-bottom: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
    `}
  
  >* {
    flex: 0 0 auto;
  }
`;

export const MenuIconContainer = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 1;
  height: 100%;
  background-color: ${(props) => props.theme.colours.topBarBackground};

  i {
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colours.primaryHighlight};
    }
  }

  ${(props) =>
    props.theme.mixins.mobile(css`
      display: flex;
    `)}
`;

export const MobileMenuToggle = styled.i`
  display: none;
  color: ${(props) => props.theme.colours.content};
  background-color: ${(props) => props.theme.colours.topBarBackground};
  margin-right: 1.5rem;

  ${(props) =>
    props.theme.mixins.mobile(css`
      display: block;
    `)}
`;

export const BreadcrumbContainer = styled.div`
  flex: 0 1 auto;
  min-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1.5rem;
  overflow-y: visible;
  height: 100%;

  > * {
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }

  > h1,
  > a {
    text-transform: uppercase;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes.verySmall};
    line-height: 1.5rem;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: ${(props) => props.theme.forms.borderRadius};
    background-color: ${(props) => props.theme.colours.primaryBackground};
    padding: ${(props) => props.theme.forms.padding};
    color: ${(props) => props.theme.colours.primary};
    transition:
      background-color 0.15s ease,
      color 0.15s ease;
  }

  > a {
    &:hover {
      color: ${(props) => props.theme.colours.contentBackground};
      background-color: ${(props) => props.theme.colours.primary};
      cursor: pointer;
    }
  }

  > span {
    display: none;
  }

  ${(props) =>
    props.mobileOverride &&
    css`
      width: 0;
      margin-right: 0;

      > *:not(:last-child) {
        display: none;
      }
    `}

  ${(props) =>
    props.theme.mixins.mobile(css`
      width: 0;
      margin-right: 0;

      > *:not(:last-child) {
        display: none;
      }
    `)}
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 auto;
  align-self: stretch;

  > * {
    margin-left: 1.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const CompanyLogo = styled.div`
  img {
    border-radius: 50%;
    height: 3.2rem;
  }

  ${(props) =>
    props.theme.mixins.mobile(css`
      display: none;
    `)}
`;

export const TopMenuToggle = styled.i`
  font-size: 2.1rem !important;
  margin-left: 1.5rem;
  margin-right: -0.4rem;
  display: none;
  color: ${(props) => props.theme.colours.content};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colours.primaryHighlight};
  }

  ${(props) =>
    props.theme.mixins.mobile(css`
      display: block;
    `)}
`;
