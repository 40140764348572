export const SensorType = {
  HIGH_G_MOTOR: "high_g_motor",
  LOW_G_MOTOR: "low_g_motor",
  LOW_G_RMS_MOTOR: "low_g_rms_motor",
  LOW_G_HIGH_MOTOR: "low_g_high_res_motor",
  LOW_G_AUTO_MOTOR: "low_g_auto_motor",
  HIGH_G_SCREEN: "high_g_screen",
  HIGH_G_SCREEN_TWF: "high_g_screen_twf",
  LOW_G_SCREEN: "low_g_screen",
  THERMAL: "thermal",
  VULCO_MONITORING_SENSOR: "vulco_monitoring_sensor",
  KPX: "kappa_x",
} as const;
export type Sensor = (typeof SensorType)[keyof typeof SensorType];
