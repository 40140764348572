import {observable, flow, computed} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class ImageSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      siteName: null,
      name: null,
    };
  }

  @observable _images;

  getData = flow(function* ({siteName, name}) {
    if (!siteName || !name) {
      return;
    }
    this.startLoading();
    try {
      const result = yield Api.getImageList(siteName, name);
      this._images = result.image_list;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting image: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get images() {
    if (!this._images) {
      return [];
    }
    return this._images;
  }

  async sync({siteName, name}) {
    if (siteName === this.params.siteName && name === this.params.name) {
      return this.refresh();
    }
  }
}
