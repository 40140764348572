import {flow} from "mobx";

import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import GatewayDiagnosticsSubscription from "models/GatewayDiagnosticsSubscription";
import Api from "sensoteq-react-core/services/api";

export default class GatewayDiagnosticsStore extends SubscribableStore {
  constructor(rootStore) {
    super(rootStore, GatewayDiagnosticsSubscription);
  }

  clearDeviceDiagnostics = flow(function* (gatewayName) {
    this.startLoading();
    try {
      yield Api.clearDeviceDiagnostics(gatewayName);
      for (let i = 0, sub = this.subscriptions[i]; i < this.subscriptions.length; i++) {
        if (sub.gatewayName === gatewayName) {
          sub.refresh();
          break;
        }
      }
      this.rootStore.notificationStore.addNotification("Diagnostics cleared successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error clearing diagnostics: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
