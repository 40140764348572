import {observable, computed, flow, action} from "mobx";

import Api from "sensoteq-react-core/services/api";
import {generateId} from "services/Utils";
import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import ReportDataSubscription from "models/ReportDataSubscription";
import {ReportSessionStorageEnum} from "components/layout/ReportDock";

export default class ReportStore extends SubscribableStore {
  @observable.shallow _reports;
  @observable.shallow activeReport;
  @observable.shallow images = [];
  @observable companyId;

  constructor(rootStore) {
    super(rootStore, ReportDataSubscription);
  }

  @computed get reports() {
    if (!this._reports) {
      return [];
    }
    return this._reports.map((x, idx) => ({
      ...x,
      display_name: this.rootStore.preferenceStore.obscureNames ? `Report ${idx + 1}` : x.name,
    }));
  }

  getReports = flow(function* (companyId) {
    this.startLoading();
    try {
      const data = yield Api.getReports(companyId);
      this._reports = data.reports;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting reports: ${error}`, "bad");
    }
    this.stopLoading();
  });

  deleteReport = flow(function* (name, version, companyId) {
    try {
      const sessionStorageReport = {
        name: sessionStorage.getItem(ReportSessionStorageEnum.NAME),
        version: sessionStorage.getItem(ReportSessionStorageEnum.VERSION),
      };
      yield Api.deleteReport(name, version, companyId);
      if (sessionStorageReport.name === name && sessionStorageReport.version === version) {
        sessionStorage.removeItem(ReportSessionStorageEnum.NAME);
        sessionStorage.removeItem(ReportSessionStorageEnum.VERSION);
      }
      this.getReports(companyId);
      this.rootStore.notificationStore.addNotification("Report deleted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting report: ${error}`, "bad");
    }
  });

  @action addImage(data, title, events, additionalInfo) {
    this.images.push({
      data,
      title,
      events,
      id: generateId(),
      additionalInfo,
    });
    this.rootStore.notificationStore.addNotification("Graph added successfully");
  }

  @action deleteImage(id) {
    const index = this.images.findIndex((x) => x.id === id);
    if (index !== -1) {
      this.images.splice(index, 1);
    }
  }

  @action setCompanyId(companyId) {
    this.companyId = companyId;
  }
}
