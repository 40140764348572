import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import ThermalImageZonesSubscription from "models/ThermalImageZonesSubscription";
import {flow} from "mobx";
import Api from "sensoteq-react-core/services/api";

export default class ThermalImageZonesStore extends SubscribableStore {
  constructor(props) {
    super(props, ThermalImageZonesSubscription);
  }

  updateBoxes = flow(function* (sensorId, pointId, boxes) {
    this.startLoading();
    try {
      yield Api.updateMachinesPointThermalBoxes(sensorId, pointId, boxes);
      this.rootStore.notificationStore.addNotification("Boxes updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating boxes: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
