import {action} from "mobx";

import MultiEventSubscription from "./MultiEventSubscription";

export default class MachineEventSubscription extends MultiEventSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      machineId: null,
    };
  }

  @action update(params = {}, replaceAllParams = false) {
    let newIdentifiers = this.params.identifiers;
    if (this.params.machineId !== params.machineId) {
      // Get measuring point targets
      let identifiers = this.rootStore.machineStore.getPointsForMachine(params.machineId);
      newIdentifiers = identifiers.map((x) => x.point_id);

      // Add machine ID as target
      newIdentifiers.push(params.machineId);
    }
    super.update(
      {
        ...params,
        identifiers: newIdentifiers,
      },
      replaceAllParams,
    );
  }
}
