import styled, {css} from "styled-components";

import {StatusThemes} from "constants/enums";

export const Icon = styled.div`
  i {
    color: ${(props) => props.theme.colours.info};
    font-size: 2.2rem;

    ${(props) =>
      props.userTheme === StatusThemes.GOOD &&
      css`
        color: ${(props) => props.theme.colours.good};
      `}

    ${(props) =>
      props.userTheme === StatusThemes.WARNING &&
      css`
        color: ${(props) => props.theme.colours.warning};
      `}
    
    ${(props) =>
      (props.userTheme === StatusThemes.OFFLINE || props.userTheme === StatusThemes.SLEEPING) &&
      css`
        color: ${(props) => props.theme.colours.offline};
      `}
    
    ${(props) =>
      props.userTheme === StatusThemes.BAD &&
      css`
        color: ${(props) => props.theme.colours.bad};
      `}
  }
`;
