import * as envConfig from "./environment-config";

// Servers
export const Servers = {
  Production: envConfig.getKey("api_endpoint"),
  Staging: "https://api.staging.sensoteq.com/api/",
  Localhost: "http://127.0.0.1:6201/api/",
  Office: "https://office.sensoteq.com:6201/api/",
  Chuckles: "http://192.168.1.13:6201/api/",
  Cheeks: "http://192.168.1.2:6201/api/",
  Sirius: "https://sirius.sensoteq.com/api/",
  Development: "https://api.dev.sensoteq.com/api/",
};

// Server config
let apiServer;
export function setApiServer(server) {
  apiServer = server;
}

// ===== Non-admin endpoints =====
// Site
export const GET_SITE_MAP = "v1/site/:site/map";
export const GET_IMAGE_LIST = "v1/site/image/list";
export const GET_SITES = "v1/site/get";

// Device
export const GET_DEVICE = "v1/device/get_device";
export const SENSORS_FOR_DEVICE = "v1/device/sensors_for_device";
export const GET_FILTER_LIST = "v1/device/get_filter_list";
export const UPDATE_FILTER_LIST = "v1/device/update_filter_list";
export const SET_SENSOR_ORIENTATION = "v1/device/set_orientation";
export const SET_SENSOR_RPM = "v1/device/set_rpm";
export const SET_POWER_BANDS = "v1/device/power/set_level";
export const GET_POWER_BANDS = "v1/device/power/get_level";
export const SET_SENSOR_NOTCH_FILTER = "v1/device/power/set_notch_filter";

// Machine
export const GET_MACHINES = "v1/machine/get";
export const CREATE_MACHINE = "v1/machine/create";
export const DELETE_MACHINE = "v1/machine/delete";
export const UPDATE_MACHINE = "v1/machine/update";
export const UPDATE_MACHINE_METADATA = "v1/machine/metadata/update";
export const UPDATE_MACHINE_LOCATIONS = "v1/machine/locations/update";
export const GET_BEARINGS = "v1/machine/bearing/list";
export const GET_LATEST_THERMAL_IMAGE = "v1/thermal/image/latest";
export const GET_HISTORICAL_THERMAL_IMAGE = "v1/thermal/image/historical";

// Bearing
export const CREATE_BEARING = "v1/bearing/create";
export const UPDATE_BEARING = "v1/bearing/update";
export const DELETE_BEARING = "v1/bearing/delete";
export const GET_BEARING = "v1/bearing/get";
export const SEARCH_BEARINGS_LIST = "v1/bearing/search_list";

// Thermal points
export const GET_MACHINES_POINT_THERMAL = "v1/machine/point/thermal/get";
export const UPDATE_MACHINES_POINT_THERMAL_BOXES = "v1/machine/point/thermal/boxes/update";

// Measuring points
export const CREATE_MEASURING_POINT = "v1/machine/point/create";
export const UPDATE_MEASURING_POINT = "v1/machine/point/update";
export const DELETE_MEASURING_POINT = "v1/machine/point/delete";
export const GET_POINT_DATA = "v1/machine/point/raw_data";
export const GET_HISTORICAL_POINT_DATA = "v1/machine/point/historical_data";
export const GET_HISTORICAL_POINT_DATA_SERIES = "v1/machine/point/historical_data_series";
export const UPDATE_MEASURING_POINT_METADATA = "v1/machine/point/metadata/update";
export const RESET_MACHINE_POWER_BANDS = "v1/machine/update/bands";

// Event
export const GET_EVENTS = "v1/event/get";
export const CREATE_EVENT = "v1/event/create";
export const UPDATE_EVENT = "v1/event/update";
export const DELETE_EVENT = "v1/event/delete";
export const GET_EVENT = "v1/event/id/:eventId";

// Alarm
export const CREATE_ALARM = "v1/alarm/create_alarm";
export const GET_ALARMS = "v1/alarm/get_alarms";
export const UPDATE_ALARM_NOTIFICATION = "v1/alarm/update_notification";
export const DELETE_ALARM = "v1/alarm/delete_alarm";
export const GET_ALARM_HISTORY = "v1/alarm/get_history";
export const UPDATE_ALARM = "v1/alarm/update_operator_value";
export const GET_LATEST_CRITICALITY_DATA = "v1/alarm/criticality/latest/get";
export const GET_HISTORICAL_CRITICALITY_DATA = "v1/alarm/criticality/historical/get";
export const BULK_UPDATE_ALARM_LEVELS = "v1/alarm/bulk/update/level";
export const BULK_UPDATE_ALARM_NOTIFICATIONS = "v1/alarm/bulk/update/notification";
export const BULK_DELETE_ALARMS = "v1/alarm/bulk/delete";
export const GET_LATEST_SITE_CRITICALITIES = "v1/alarm/criticality/latest/site/all";
export const GET_LATEST_SITE_ALARM_REPORTS = "v1/alarm/reports/latest/site/all";
export const GET_LATEST_ALARM_REPORT = "v1/alarm/reports/latest/get";
export const GET_ALARM_REPORTS = "v1/alarm/reports/historical/get";

// Report
export const GET_REPORT_LIST = "v1/report/list";
export const GET_REPORT = "v1/report/get";
export const DELETE_REPORT = "v1/report/delete";
export const SAVE_REPORT = "v1/report/store";

// User
export const LOGIN = "v1/user/login";
export const LOGOUT = "v1/user/logout";
export const UPDATE_SELF = "v1/user/update";
export const SEND_PASSWORD_RESET_EMAIL = "v1/user/forgotten_email";
export const RESET_PASSWORD = "v1/user/forgotten_confirm";
export const UPDATE_PREFERENCES = "v1/user/update_preferences";
export const EXPIRE_SESSION = "v1/user/session/expire";
export const GET_ACTIVE_SESSIONS = "v1/user/session/get";
export const GET_CURRENT_USER = "v1/user/get";
export const GET_API_KEYS = "v1/user/apikey/list";
export const GENERATE_API_KEY = "v1/user/apikey/generate";
export const REVOKE_API_KEY = "v1/user/apikey/revoke";
export const VERIFY_MFA = "v1/user/verify_mfa";
export const GET_MFA_CODE = "v1/user/get_mfa_code";
export const DISABLE_MFA = "v1/user/disable_mfa";

// Install
export const UPLOAD_IMAGE = "v1/install/:siteName/image/upload";
export const DELETE_IMAGE = "v1/install/:siteName/image/delete";
export const REPLACE_SENSOR = "v1/install/replace/sensor";
export const UPDATE_MEASURING_POINT_STATE = "v1/install/point/state";
export const RUN_TEMPLATE = "v1/installer/template/run";

// New time domain
export const GET_TIME_DOMAIN_KEY_LIST = "v1/time_domain/key/list";
export const GET_TIME_DOMAIN_SET = "v1/time_domain/object/get";
export const GET_TIME_DOMAIN_SET_V2 = "v1/time_domain/object/v2/get";
export const GET_LATEST_TIME_DOMAIN_KEY = "v1/time_domain/key/latest";
export const GET_WATERFALL = "v1/time_domain/waterfall/get";
export const GET_WATERFALL_MULTI = "v1/time_domain/waterfall/multi/get";

// Template
export const GET_TEMPLATES = "v1/template/get";
export const CREATE_TEMPLATE = "v1/template/create";
export const DELETE_TEMPLATE = "v1/template/delete";
export const UPDATE_TEMPLATE = "v1/template/update";
export const GET_TEMPLATE_SCHEMA = "v1/template/schema/get";

// API docs
export const GET_API_DOCS_DATA = "v1/docs/api/data";

// Company
export const CREATE_ASSET_LABEL = "v1/company/asset_label/add";
export const GET_COMPANY = "v1/company/get";

// Chi
export const CREATE_CHI_EVENT = "v1/chi/event/create";
export const UPLOAD_CHI_DATA = "v1/chi/data/upload";
export const GET_LATEST_CHI_SESSIONS = "v1/chi/company/event/latest";
export const GET_LATEST_CHI_SESSIONS_FOR_ASSET = "v1/chi/event/latest";
export const GET_CHI_SESSIONS = "v1/chi/company/event/get";
export const GET_CHI_SESSIONS_FOR_ASSET = "v1/chi/event/get";
export const UPDATE_CHI_SESSION = "v1/chi/event/update";
export const DELETE_CHI_SESSION = "v1/chi/event/delete";
export const GET_CHI_DATA_LIST = "v1/chi/data/list";
export const GET_CHI_DATA_SET = "v1/chi/data/get";

// ===== Admin endpoints =====
// Site
export const CREATE_SITE = "v1/manager/site/create";
export const DELETE_SITE = "v1/manager/site/delete";

// Device
export const GENERATE_SENSOR_ID = "v1/admin/device/generate_sensor_id";
export const GET_ALL_SENSOR_IDS = "v1/admin/device/get_all_sensors";
export const UPDATE_SENSOR_INFO = "v1/admin/device/update_sensor_info";

// Device direct
export const UPLOAD_SITE_MAP = "v1/installer/site/:siteName/map/upload";
export const UPDATE_SITE_LOCATIONS = "v1/installer/site/map/locations/update";
export const UPDATE_SITE = "v1/installer/site/update";
export const UPDATE_GATEWAY = "v1/gateway/update";
export const GATEWAY_RF_PROTOCOL = "v1/gateway/rf-protocol";
export const UPDATE_GATEWAY_OWNERSHIP = "v1/gateway/ownership/update";
export const GET_DEVICES = "v1/device/allowed_devices";
export const GET_HARDWARE_FILTER_LIST = "v1/installer/device/direct/get_filter";
export const UPDATE_HARDWARE_FILTER_LIST = "v1/installer/device/direct/update_filter";
export const UPDATE_HARDWARE_FILTER_LIST_WITH_LIST = "v1/installer/device/direct/update_filter_by_list";
export const CLEAR_HARDWARE_FILTER_LIST = "v1/installer/device/direct/clear_filter";
export const REPAIR_FILTER_LIST = "v1/installer/device/direct/repair_filter";
export const GET_DEVICE_DIAGNOSTICS = "v1/installer/device/direct/diagnostics/get";
export const CLEAR_DEVICE_DIAGNOSTICS = "v1/installer/device/direct/diagnostics/clear";
export const ANIMATE_DEVICE_LED = "v1/installer/device/direct/animate";
export const UPDATE_DEVICE_FREQUENCY = "v1/installer/device/direct/frequency";
export const RESTART_DEVICE = "v1/installer/device/direct/restart";

// User
export const USER_LIST = "v1/manager/user/list";
export const USER_DETAILS = "v1/manager/user/get_details";
export const UPDATE_USER = "v1/manager/user/update";
export const CREATE_USER = "v1/manager/user/create";
export const DELETE_USER = "v1/manager/user/delete";
export const UPDATE_USER_PREFERENCES = "v1/manager/user/update_preferences";
export const GET_USER_SESSIONS = "v1/manager/user/session/history";
export const GET_ALL_ACTIVE_SESSIONS = "v1/manager/session/get";
export const EXPIRE_USER_SESSION = "v1/admin/user/session/expire";
export const GET_USER_API_KEYS = "v1/admin/user/apikey/list";
export const REVOKE_USER_API_KEY = "v1/admin/user/apikey/revoke";
export const SEND_WELCOME_EMAIL = "v1/user/welcome_email";
export const UNLOCK_USER = "v1/user/unlock";

// Debug endpoints
export const GET_DEBUG_POINTS = "v1/debug/machine/points/get_process";
export const SET_DEBUG_POINTS = "v1/debug/machine/points/set_process";

// Company
export const UPLOAD_COMPANY_LOGO = "v1/company/:company/logo";
export const CREATE_COMPANY = "v1/company/create";
export const DELETE_COMPANY = "v1/company/delete";
export const UPDATE_COMPANY = "v1/company/update";
export const GET_COMPANY_API_KEYS = "v1/admin/company/apikey/list";
export const COMPANY_LIST = "v1/company/list";

// Partner groups
export const GET_PARTNERS = "v1/admin/partner_group/list";
export const CREATE_PARTNER = "v1/admin/partner_group/create";
export const DELETE_PARTNER = "v1/admin/partner_group/delete";
export const UPDATE_PARTNER = "v1/admin/partner_group/update";

// Production tests
export const GET_TEST_MACHINES = "v1/admin/machine/test/get";
export const ADD_SENSORS_TO_TEST_MACHINE = "v1/admin/machine/test/add_sensors";
export const CREATE_TEST_MACHINE = "v1/admin/machine/test/setup";
export const COMPLETE_TEST_MACHINE = "v1/admin/machine/test/complete";
export const VERIFY_TEST_MACHINE = "v1/admin/machine/test/verify";

// Machine type
export const GET_ALL_MACHINE_TYPES = "v1/machine-type/all";
export const GET_MACHINE_TYPE = "v1/machine-type/get";
export const GET_MACHINE_TYPES_BY_CATEGORY = "v1/machine-type/get-by-category";
export const GET_MACHINE_TYPE_DIAGRAM = "v1/machine-type/diagram/get";
export const GET_MACHINE_UPTIME_EVENTS = "v1/machine/uptime";
export const CREATE_MACHINE_TYPE = "v1/admin/machine-type/create";
export const CREATE_MACHINE_SUBTYPE = "v1/admin/machine-type/subtype/create";
export const CREATE_MACHINE_SUBTYPE_DIAGRAM = "v1/admin/machine-type/subtype/diagram/create";
export const UPDATE_MACHINE_TYPE = "v1/admin/machine-type/update";
export const DELETE_MACHINE_TYPE = "v1/admin/machine-type/delete";
export const UPLOAD_MACHINE_TYPE_DIAGRAM = "v1/admin/machine-type/:machineTypeId/diagram/upload";

// Server configuration and branding payload
export const SERVER_CONFIGURATION = "v1/server/config";

// URL builder function
export function buildUrl(endpoint) {
  if (!apiServer) {
    throw "The API server must be set before generating URL's!";
  }
  return apiServer + endpoint;
}
