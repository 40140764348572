import {
  AccelerationRatio,
  AngleRatio,
  convertTemperture,
  DisplacementRatio,
  FrequencyRatio,
  TemperatureStubRatio,
  VelocityRatio,
  MassRatio,
} from "./conversions/index.js";
import {ConversionError} from "./errors.js";
import type {ObjectValues} from "./types.js";

export type System = ObjectValues<typeof MeasurementSystem>;
export const MeasurementSystem = {
  IMPERIAL: "imperial",
  METRIC: "metric",
} as const;

export type Domain = ObjectValues<typeof MeasurementDomain>;
export const MeasurementDomain = {
  VELOCITY: "velocity",
  ACCELERATION: "acceleration",
  DISPLACEMENT: "displacement",
  FREQUENCY: "frequency",
  ANGLE: "angle",
  TEMPERATURE: "temperature",
  MASS: "mass",
} as const;

const RatioConversionMap = {
  [MeasurementDomain.VELOCITY]: VelocityRatio,
  [MeasurementDomain.ACCELERATION]: AccelerationRatio,
  [MeasurementDomain.DISPLACEMENT]: DisplacementRatio,
  [MeasurementDomain.FREQUENCY]: FrequencyRatio,
  [MeasurementDomain.ANGLE]: AngleRatio,
  [MeasurementDomain.TEMPERATURE]: TemperatureStubRatio,
  [MeasurementDomain.MASS]: MassRatio,
} as const;

type Mutator<T> = (val: number) => T;
type RatioConversion = typeof RatioConversionMap;
export type DomainOptions = {[K in keyof RatioConversion]: keyof RatioConversion[K]};
export type Unit = DomainOptions[Domain];
export function convert<Domain extends keyof DomainOptions, From extends DomainOptions[Domain]>(
  value: number,
  domain: Domain,
  from: From,
) {
  return {
    to: <T = number>(
      to: Exclude<DomainOptions[Domain], From>,
      mutator?: Mutator<T>,
    ): T extends undefined ? number : T => {
      // Once Typescript 5.0 is released we can potentially use the const type modifier in the generic above
      // to infer the types of from and to correctly.
      if (domain === MeasurementDomain.TEMPERATURE) {
        return convertTemperture(value, from as string, to as string) as T extends undefined ? number : T;
      }

      const multiplierToBase = RatioConversionMap[domain][to];
      if (typeof multiplierToBase !== "number") throw new ConversionError(domain, from as string, to as string, value);

      const ratioFrom = RatioConversionMap[domain][from];
      if (typeof ratioFrom !== "number") throw new ConversionError(domain, from as string, to as string, value);

      const convertedValue = value * multiplierToBase * (1 / ratioFrom);
      return (mutator === undefined ? convertedValue : mutator(convertedValue)) as T extends undefined ? number : T;
    },
  };
}

export function convertLoose<T = number | undefined>(
  value: number,
  domain: Domain,
  from: Unit,
  to: Unit,
  mutator?: Mutator<T>,
): T extends undefined ? number | undefined : T | undefined {
  if (domain === MeasurementDomain.TEMPERATURE) {
    return convertTemperture(value, from, to) as T extends undefined ? number : T;
  }

  const domainMap = RatioConversionMap[domain] as Record<string, number | undefined>;
  const multiplierToBase = domainMap[to];
  if (typeof multiplierToBase !== "number") {
    return undefined as T extends undefined ? undefined : T;
  }

  const ratioFrom = domainMap[from];
  if (typeof ratioFrom !== "number") {
    return undefined as T extends undefined ? undefined : T;
  }

  const convertedValue = value * multiplierToBase * (1 / ratioFrom);
  return (mutator === undefined ? convertedValue : mutator(convertedValue)) as T extends undefined ? number : T;
}
