import styled, {css} from "styled-components";

import * as Enums from "constants/enums";

export const Container = styled.div`
  border-radius: ${(props) => props.theme.forms.borderRadius};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  background-color: ${(props) => props.theme.colours.contentBackground};

  ${(props) =>
    !props.border &&
    css`
      box-shadow: ${(props) => props.theme.forms.boxShadow};
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 auto;
  padding-left: 1rem;
  border-top-right-radius: ${(props) => props.theme.forms.borderRadius};
  border-bottom-right-radius: ${(props) => props.theme.forms.borderRadius};
  border-top: ${(props) => props.theme.forms.borderWidth} solid
    ${(props) => (props.border ? props.theme.colours.border : "transparent")};
  border-bottom: ${(props) => props.theme.forms.borderWidth} solid
    ${(props) => (props.border ? props.theme.colours.border : "transparent")};
  border-right: ${(props) => props.theme.forms.borderWidth} solid
    ${(props) => (props.border ? props.theme.colours.border : "transparent")};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ColourBlock = styled.div`
  display: block;
  height: inherit;
  background-color: ${(props) => props.theme.colours.info};
  flex: 0 0 auto;
  width: 0.8rem;

  ${(props) =>
    props.userTheme === Enums.StatusThemes.GOOD &&
    css`
      background-color: ${(props) => props.theme.colours.good};
    `}

  ${(props) =>
    props.userTheme === Enums.StatusThemes.WARNING &&
    css`
      background-color: ${(props) => props.theme.colours.warning};
    `}
  
  ${(props) =>
    props.userTheme === Enums.StatusThemes.BAD &&
    css`
      background-color: ${(props) => props.theme.colours.bad};
    `}
`;

export const Title = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  > div:first-child {
    margin: -0.4rem 0;
  }

  > i {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem;
    background-color: transparent;
    font-size: 2.2rem !important;

    ${(props) =>
      props.smallIcon &&
      css`
        font-size: 1.5rem !important;
      `}
  }

  ${(props) =>
    props.onClick != null &&
    css`
      &:hover {
        cursor: pointer;

        > i {
          color: ${(props) => props.theme.colours.primaryHighlight};
        }
      }
    `}
`;

export const Text = styled.div`
  color: ${(props) => props.theme.colours.info};
  font-weight: 500;
  flex: 1 1 auto;
  padding: ${(props) => props.theme.forms.padding};
  line-height: 1.5rem;

  ${(props) =>
    props.userTheme === Enums.StatusThemes.GOOD &&
    css`
      color: ${(props) => props.theme.colours.good};
    `}

  ${(props) =>
    props.userTheme === Enums.StatusThemes.WARNING &&
    css`
      color: ${(props) => props.theme.colours.warning};
    `}

  ${(props) =>
    props.userTheme === Enums.StatusThemes.BAD &&
    css`
      color: ${(props) => props.theme.colours.bad};
    `}
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const Item = styled.div`
  color: ${(props) => props.theme.colours.contentLight};
  padding: 0.8rem 0;
`;

export const Icon = styled.i`
  color: ${(props) => props.theme.colours.info};
  margin-right: 1rem;

  ${(props) =>
    props.userTheme === Enums.StatusThemes.GOOD &&
    css`
      color: ${(props) => props.theme.colours.good};
    `}

  ${(props) =>
    props.userTheme === Enums.StatusThemes.WARNING &&
    css`
      color: ${(props) => props.theme.colours.warning};
    `}
  
  ${(props) =>
    props.userTheme === Enums.StatusThemes.BAD &&
    css`
      color: ${(props) => props.theme.colours.bad};
    `}
`;
