import React from "react";
import PropTypes from "prop-types";
import autobind from "class-autobind-decorator";
import styled, {css} from "styled-components";

import {asField} from "../lib/mobx-form";
import Label from "./Label";

@autobind
class SelectBar extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    border: PropTypes.bool,
    shadow: PropTypes.bool,
  };
  static defaultProps = {
    border: true,
    shadow: true,
  };

  onChange(value) {
    this.props.fieldApi.setValue(value);
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    const {options, label, fieldState, border, style} = this.props;
    const {value} = fieldState;
    return (
      <Container style={style}>
        {label && <Label>{label}</Label>}
        <Options border={border}>
          {options.map((option) => {
            let optionLabel = option instanceof Object ? option.label : option;
            let optionValue = option instanceof Object ? option.value : option;

            return (
              <Option
                key={optionValue}
                selected={optionValue === value}
                onClick={() => this.onChange(optionValue)}
              >
                {optionLabel}
              </Option>
            );
          })}
        </Options>
      </Container>
    );
  }
}
export default asField(SelectBar);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
`;

const Option = styled.div`
  flex: 0 1 auto;
  padding: ${(props) => props.theme.forms.padding};
  border-right: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  color: ${(props) => props.theme.colours.primary};
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition:
    background-color 0.1s ease,
    color 0.1s ease,
    border-color 0.1s ease;
  // background-color: ${(props) => props.theme.colours.primaryBackground};
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSizes.tiny};
  line-height: 1.5rem;
  font-weight: 700;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colours.primary};
      border-color: ${(props) => props.theme.colours.primary};
      color: white;
    `}

  &:last-child {
    border-right: none;
  }

  &:hover {
    background-color: ${(props) => props.theme.colours.primaryHighlight};
    border-color: ${(props) => props.theme.colours.primaryHighlight};
    color: white;
    cursor: pointer;
  }
`;

const Options = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  overflow: hidden;
  border-radius: ${(props) => props.theme.forms.borderRadius};
  background-color: ${(props) => props.theme.colours.contentBackground};

  ${(props) =>
    props.border &&
    css`
      border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
    `}

  ${(props) =>
    !props.border &&
    css`
      box-shadow: ${(props) => props.theme.forms.boxShadow};

      ${Option} {
        border-top: ${(props) => props.theme.forms.borderWidth} solid transparent;
        border-bottom: ${(props) => props.theme.forms.borderWidth} solid transparent;
      }
    `}
`;
