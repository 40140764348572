import {observable, action, flow} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class GatewayFilterListSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      gatewayName: null,
    };
  }

  @observable filterList = [];

  getData = flow(function* ({gatewayName}) {
    if (!gatewayName) {
      return;
    }
    this.startLoading();
    try {
      const filterList = yield Api.getFilterList(gatewayName);
      this.filterList = filterList.sensors;
    } catch (error) {
      this.filterList = [];
      this.rootStore.notificationStore.addNotification(`Error getting filter list: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @action sync(gatewayName, filterList) {
    if (gatewayName === this.params.gatewayName) {
      this.filterList = filterList;
    }
  }
}
