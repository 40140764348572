import type {ObjectValues} from "../types.js";

export const MassOption = {
  LB: "lb",
  GRAM: "g",
} as const;

export const MassRatio = {
  [MassOption.GRAM]: 1,
  [MassOption.LB]: 0.002204623,
};

export type Mass = ObjectValues<typeof MassOption>;
