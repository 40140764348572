import {Colors} from "components/layout/Branding";

export default function (assign) {
  assign("fonts", () => ({
    feature: '"Montserrat", sans-serif',
    default: '"Roboto", sans-serif',
  }));

  assign("branding", () => ({
    companyLogo: false,
    companyName: false,
    companyLogoOverrideURL: null,
    analytixColor: Colors.LogoTextWhite,
  }));

  assign("colours", () => ({
    accent: "#1d2436",
    mainMenuBackground: "#1D2436",
    mainMenuHighlight: "white",
  }));

  assign("dimensions", () => ({
    mainMenuWidth: 24, // Width of main menu
  }));
}
