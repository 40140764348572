import styled from "styled-components";

export const Page = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const TopBar = styled.div`
  flex: 0 0 ${(props) => props.theme.dimensions.topBarHeight}rem;
  background-color: ${(props) => props.theme.colours.topBarBackground};
  box-shadow: ${(props) => props.theme.forms.boxShadow};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  flex: 1 1 auto;
`;
