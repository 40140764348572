import styled, {css} from "styled-components";

type Props = {
  grow?: boolean;
  center?: boolean;
  ignoreChildrenHeight?: boolean;
};
export const ContentRow = styled.div<Props>`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  flex-wrap: wrap;

  ${(props) =>
    props.grow &&
    css`
      flex: 1 0 auto;
    `}

  ${(props) =>
    props.ignoreChildrenHeight &&
    css`
      flex: 1 1 0;

      ${(props) =>
        props.theme.mixins.isIOS(css`
          flex: 1 0 auto;
        `)}
    `}
  
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}
`;
