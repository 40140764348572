const {doesPropExist} = require("../utilities/object");

const POWER_BAND_STRUCT_VERSION = {
  ONE: 1,
  CURRENT: 1,
};

const DEFAULT_WARNING_MM = 4.445;
const DEFAULT_CRITICAL_MM = 6.35;

const POWER_BAND_WARNING_KEY = "warning";
const POWER_BAND_CRITICAL_KEY = "critical";

const POWER_BAND_NAMES = {
  SUBSYNCHRONOUS: "subsynchronous",
  RPM_1X: "rpm_1x",
  RPM_1_5X_2X: "rpm_1_5x_2x",
  RPM_2_5X_3X: "rpm_2_5x_3x",
  FUND_BEARING_DEFECT: "fundamental_bearing_defect_freq",
  LOWER_HARMONIC_BEARING: "lower_harmonic_bearing_freq",
  HIGHER_HARMONIC_BEARING: "higher_harmonic_bearing_freq",
  HIGHER_HARMONIC_BEARING_2: "higher_harmonic_bearing_freq_2",
  HIGHER_HARMONIC_BEARING_3: "higher_harmonic_bearing_freq_3",
};

const POWER_BAND_MULTIPLIERS = {
  [POWER_BAND_NAMES.RPM_1X]: [0.9, 0.9],
  [POWER_BAND_NAMES.RPM_1_5X_2X]: [0.3, 0.3],
  [POWER_BAND_NAMES.RPM_2_5X_3X]: [0.25, 0.25],
  [POWER_BAND_NAMES.FUND_BEARING_DEFECT]: [0.2, 0.2],
  [POWER_BAND_NAMES.LOWER_HARMONIC_BEARING]: [0.15, 0.15],
  [POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING]: [0.1, 0.1],
  [POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING_2]: [0.05, 0.05],
  [POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING_3]: [0.025, 0.025],
};

// TODO: Make this more extensible in the future, depending on what we want to do with power bands going forward.
const POWER_BAND_SCREEN_MULTIPLIERS = {
  [POWER_BAND_NAMES.RPM_1X]: [15.748, 10.936],
  [POWER_BAND_NAMES.RPM_1_5X_2X]: [0.3, 0.3],
  [POWER_BAND_NAMES.RPM_2_5X_3X]: [0.25, 0.25],
  [POWER_BAND_NAMES.FUND_BEARING_DEFECT]: [0.2, 0.2],
  [POWER_BAND_NAMES.LOWER_HARMONIC_BEARING]: [0.15, 0.15],
  [POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING]: [0.1, 0.1],
  [POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING_2]: [0.05, 0.05],
  [POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING_3]: [0.025, 0.025],
};

function getPowerBandAlarmDefaults(powerBandName) {
  const multipliers = POWER_BAND_MULTIPLIERS[powerBandName];
  return {
    [POWER_BAND_WARNING_KEY]: Math.round(DEFAULT_WARNING_MM * multipliers[0] * 100) / 100,
    [POWER_BAND_CRITICAL_KEY]: Math.round(DEFAULT_CRITICAL_MM * multipliers[1] * 100) / 100,
  };
}

function lookupPowerBandByName(bands, powerBandName) {
  for (const powerBand of bands) {
    if (powerBand.name === powerBandName) {
      return powerBand;
    }
  }

  return null;
}

function findPowerBandByName(name) {
  const powerBands = POWER_BANDS;
  for (const band of powerBands) {
    if (band.name === name) {
      return band;
    }

    if (band.backwardsCompatibleName && band.backwardsCompatibleName === name) {
      return band;
    }
  }

  return null;
}

function filterPowerBandByName(name, comparisonFunc) {
  const powerBands = POWER_BANDS;
  for (const band of powerBands) {
    if (band.name === name) {
      const options = [band.name];
      if (band.backwardsCompatibleName) {
        options.push(band.backwardsCompatibleName);
      }
      return comparisonFunc(options);
    }
  }
}

function getPowerBandsInOrder() {
  return POWER_BANDS.sort(function (a, b) {
    return a.order - b.order;
  });
}

function getAlarmablePowerBandsInOrder() {
  return POWER_BANDS.filter((band) => band.alarmable === true).sort(function (a, b) {
    return a.order - b.order;
  });
}

const POWER_BANDS = [
  {
    order: 1,
    name: POWER_BAND_NAMES.SUBSYNCHRONOUS,
    label: "Subsynchronous",
    acronym: "Subsync.",
    start: {percent: 0},
    end: {multiplier: 0.8},
    alarmable: false,
  },
  {
    order: 2,
    name: POWER_BAND_NAMES.RPM_1X,
    label: "1X RPM",
    acronym: "1X",
    backwardsCompatibleName: "band_0",
    start: {multiplier: 0.8},
    end: {multiplier: 1.2},
    alarmable: true,
  },
  {
    order: 3,
    name: POWER_BAND_NAMES.RPM_1_5X_2X,
    label: "1.5X-2X RPM",
    acronym: "1.5-2X",
    backwardsCompatibleName: "band_1",
    start: {multiplier: 1.2},
    end: {multiplier: 2.2},
    alarmable: true,
  },
  {
    order: 4,
    name: POWER_BAND_NAMES.RPM_2_5X_3X,
    label: "2.5X-3X RPM",
    acronym: "2.5-3X",
    backwardsCompatibleName: "band_2",
    start: {multiplier: 2.2},
    end: {multiplier: 3.2},
    alarmable: true,
  },
  {
    order: 5,
    name: POWER_BAND_NAMES.FUND_BEARING_DEFECT,
    label: "Fundamental Bearing Defect Freqs.",
    acronym: "FBF",
    backwardsCompatibleName: "band_3",
    start: {multiplier: 3.2},
    end: {multiplier: 12.2},
    alarmable: true,
  },
  {
    order: 6,
    name: POWER_BAND_NAMES.LOWER_HARMONIC_BEARING,
    label: "Lower Harmonic Bearing Freqs.",
    acronym: "LBF",
    backwardsCompatibleName: "band_4",
    start: {multiplier: 12.2},
    end: {static: 1250},
    alarmable: true,
  },
  {
    order: 7,
    name: POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING,
    label: "Higher Harmonic Bearing Freqs. 1",
    acronym: "HBF 1",
    backwardsCompatibleName: "band_5",
    start: {static: 1250},
    end: {static: 2560},
    alarmable: true,
  },
  {
    order: 8,
    name: POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING_2,
    label: "Higher Harmonic Bearing Freqs. 2",
    acronym: "HBF 2",
    start: {static: 2560},
    end: {static: 5000},
    alarmable: true,
  },
  {
    order: 9,
    name: POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING_3,
    label: "Higher Harmonic Bearing Freqs. 3",
    acronym: "HBF 3",
    start: {static: 5000},
    end: {percent: 100},
    alarmable: true,
  },
];

const POWER_BAND_FRONTEND_PARAMETERS = {
  [POWER_BAND_NAMES.SUBSYNCHRONOUS]: {
    width: 100,
    lowerLimits: {[POWER_BAND_WARNING_KEY]: 0, [POWER_BAND_CRITICAL_KEY]: 0},
    upperLimits: {[POWER_BAND_WARNING_KEY]: 25, [POWER_BAND_CRITICAL_KEY]: 25},
  },
  [POWER_BAND_NAMES.RPM_1X]: {
    width: 48,
    lowerLimits: {[POWER_BAND_WARNING_KEY]: 0, [POWER_BAND_CRITICAL_KEY]: 0},
    upperLimits: {[POWER_BAND_WARNING_KEY]: 250, [POWER_BAND_CRITICAL_KEY]: 250},
  },
  [POWER_BAND_NAMES.RPM_1_5X_2X]: {
    width: 72,
    lowerLimits: {[POWER_BAND_WARNING_KEY]: 0, [POWER_BAND_CRITICAL_KEY]: 0},
    upperLimits: {[POWER_BAND_WARNING_KEY]: 25, [POWER_BAND_CRITICAL_KEY]: 25},
  },
  [POWER_BAND_NAMES.RPM_2_5X_3X]: {
    width: 73,
    lowerLimits: {[POWER_BAND_WARNING_KEY]: 0, [POWER_BAND_CRITICAL_KEY]: 0},
    upperLimits: {[POWER_BAND_WARNING_KEY]: 25, [POWER_BAND_CRITICAL_KEY]: 25},
  },
  [POWER_BAND_NAMES.FUND_BEARING_DEFECT]: {
    width: 205,
    lowerLimits: {[POWER_BAND_WARNING_KEY]: 0, [POWER_BAND_CRITICAL_KEY]: 0},
    upperLimits: {[POWER_BAND_WARNING_KEY]: 25, [POWER_BAND_CRITICAL_KEY]: 25},
  },
  [POWER_BAND_NAMES.LOWER_HARMONIC_BEARING]: {
    width: 183,
    lowerLimits: {[POWER_BAND_WARNING_KEY]: 0, [POWER_BAND_CRITICAL_KEY]: 0},
    upperLimits: {[POWER_BAND_WARNING_KEY]: 25, [POWER_BAND_CRITICAL_KEY]: 25},
  },
  [POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING]: {
    width: 195,
    lowerLimits: {[POWER_BAND_WARNING_KEY]: 0, [POWER_BAND_CRITICAL_KEY]: 0},
    upperLimits: {[POWER_BAND_WARNING_KEY]: 25, [POWER_BAND_CRITICAL_KEY]: 25},
  },
  [POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING_2]: {
    width: 195,
    lowerLimits: {[POWER_BAND_WARNING_KEY]: 0, [POWER_BAND_CRITICAL_KEY]: 0},
    upperLimits: {[POWER_BAND_WARNING_KEY]: 25, [POWER_BAND_CRITICAL_KEY]: 25},
  },
  [POWER_BAND_NAMES.HIGHER_HARMONIC_BEARING_3]: {
    width: 195,
    lowerLimits: {[POWER_BAND_WARNING_KEY]: 0, [POWER_BAND_CRITICAL_KEY]: 0},
    upperLimits: {[POWER_BAND_WARNING_KEY]: 25, [POWER_BAND_CRITICAL_KEY]: 25},
  },
};

function validatePowerBandStructure(powerBands) {
  const powerBandNames = Object.values(POWER_BAND_NAMES);
  for (const band of powerBands) {
    if (powerBandNames.indexOf(band.name) === -1) {
      const error = {error: "Band name '" + band.name + "' is invalid, no band found by that name."};
      return {valid: false, error};
    }

    if (typeof band[POWER_BAND_WARNING_KEY] !== "number" || typeof band[POWER_BAND_CRITICAL_KEY] !== "number") {
      const error = {error: "Band '" + band.name + "' is missing 'critical' or 'warning' property."};
      return {valid: false, error};
    }
  }

  return {valid: true, error: null};
}

function getPowerBandFreqRanges(rpm, fMax) {
  const powerbandsLastToFirst = getPowerBandsInOrder().reverse();
  const runningSpeedHz = rpm / 60;
  const fMaxPercent = fMax / 100;

  const powerbandFrequencyRanges = [];
  let previous = null;
  for (const band of powerbandsLastToFirst) {
    const startFrequencyOptions = [];
    if (band.start.multiplier != null) startFrequencyOptions.push(band.start.multiplier * runningSpeedHz);
    if (band.start.percent != null) startFrequencyOptions.push(band.start.percent * fMaxPercent);
    if (band.start.static != null) startFrequencyOptions.push(band.start.static);

    const endFrequencyOptions = [];
    if (band.end.multiplier != null) endFrequencyOptions.push(band.end.multiplier * runningSpeedHz);
    if (band.end.percent != null) endFrequencyOptions.push(band.end.percent * fMaxPercent);
    if (band.end.static != null) endFrequencyOptions.push(band.end.static);

    let start = Math.min(...startFrequencyOptions);
    let end = Math.min(...endFrequencyOptions);

    if (previous != null && end > previous.start) {
      end = previous.start;
    }

    if (start > end) {
      // Start cannot be after the end frequency so set the start equal to end value.
      start = end;
    }
    previous = {start, end};

    // If the start and end frequency of the range are the same then don't return this power band range
    // as it is not useful.
    if (start !== end) {
      powerbandFrequencyRanges.push({start, end, bandName: band.name});
    }
  }

  return powerbandFrequencyRanges.reverse();
}

module.exports = {
  POWER_BAND_NAMES: POWER_BAND_NAMES,
  POWER_BAND_STRUCT_VERSION: POWER_BAND_STRUCT_VERSION,
  POWER_BAND_MULTIPLIERS: POWER_BAND_MULTIPLIERS,
  POWER_BAND_SCREEN_MULTIPLIERS: POWER_BAND_SCREEN_MULTIPLIERS,
  getPowerBandAlarmDefaults: getPowerBandAlarmDefaults,
  lookupPowerBandByName: lookupPowerBandByName,
  findPowerBandByName: findPowerBandByName,
  filterPowerBandByName: filterPowerBandByName,
  getPowerBandsInOrder: getPowerBandsInOrder,
  getAlarmablePowerBandsInOrder: getAlarmablePowerBandsInOrder,
  POWER_BANDS: POWER_BANDS,
  POWER_BAND_FRONTEND_PARAMETERS: POWER_BAND_FRONTEND_PARAMETERS,
  validatePowerBandStructure: validatePowerBandStructure,
  getPowerBandFreqRanges: getPowerBandFreqRanges,
};
