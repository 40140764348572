import {computed, flow, observable, action} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class TemplateSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      templateType: null,
      companyName: null,
    };
  }

  @observable _templates = [];

  getData = flow(function* ({company, templateType}) {
    if (company == null) {
      return;
    }
    this.startLoading();
    try {
      company = company === this.rootStore.userStore.company.company_id ? undefined : company;
      const templates = yield Api.getTemplates(templateType, company);
      this._templates = templates.templates;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting templates: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @action sync(templateId, template) {
    // Deletion
    const index = this._reverseIndexLookupMap[templateId];
    if (!template) {
      if (index != null) {
        this._templates.splice(index, 1);
      }
      return;
    }

    // Check if a match
    const match = template.company === this.params.company;
    if (!match) {
      return;
    }

    // Check if update or additional
    const update = index != null;
    if (update) {
      this._templates[index] = template;
    } else {
      this._templates.push(template);
    }
  }

  @computed get templates() {
    if (!this._templates) {
      return [];
    }
    return this._templates.map((x, idx) => ({
      ...x,
      display_name: this.rootStore.preferenceStore.obscureNames ? `Template ${idx + 1}` : x.name,
    }));
  }

  @computed get _reverseLookupMap() {
    let map = {};
    this.templates.forEach((template) => {
      map[template.template_id] = template;
    });
    return map;
  }

  @computed get _reverseIndexLookupMap() {
    let map = {};
    this.templates.forEach((template, idx) => {
      map[template.template_id] = idx;
    });
    return map;
  }

  getTemplate(templateId) {
    return this._reverseLookupMap[templateId];
  }

  templateExists(templateId) {
    return this.getTemplate(templateId) != null;
  }
}
