let cachedData = {};

export default function cache(retrieve, key, ...params) {
  return new Promise((resolve) => {
    if (cachedData[key]) {
      resolve(cachedData[key]);
    } else {
      retrieve(key, ...params).then((data) => {
        cachedData[key] = data;
        resolve(data);
      });
    }
  });
}
