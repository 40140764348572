import React from "react";
import PropTypes from "prop-types";

import StoreContext from "./store-context";
import RouteContext from "./route-context";

const Router = ({children, routerStore}) => {
  return (
    <StoreContext.Provider value={routerStore}>
      <RouteContext.Provider value={{path: "/"}}>{children}</RouteContext.Provider>
    </StoreContext.Provider>
  );
};

Router.propTypes = {
  routerStore: PropTypes.any.isRequired,
};

export default Router;
