import {observer} from "mobx-react";

import * as Styled from "./CollapsedBranding.styles";
import {Link} from "sensoteq-react-core/lib/mobx-state-router";
import {HASH} from "../../hash";
import {useRootStore} from "stores";
import PropTypes from "prop-types";

function CollapsedBranding(props) {
  const {size} = props;
  const {userStore, themeStore} = useRootStore();
  const {companyLogoURL} = userStore;
  const {theme, logos, title} = themeStore;

  // Logo
  let logo = logos.primary;
  if (logos.secondary && userStore.loggedIn) {
    logo = logos.secondary;
  }
  if (theme.branding.companyLogo) {
    logo = companyLogoURL;
  }
  if (theme.branding.companyLogoOverrideURL) {
    logo = theme.branding.companyLogoOverrideURL;
  }

  return (
    <Link
      shallow
      to="/"
    >
      <Styled.Container title={`${title} #${HASH}`}>
        <Styled.Logo
          src={logo}
          alt="Company"
          size={size}
        />
      </Styled.Container>
    </Link>
  );
}

CollapsedBranding.propTypes = {
  size: PropTypes.number,
};
CollapsedBranding.defaultProps = {
  size: 2,
};

export default observer(CollapsedBranding);
