import {flow} from "mobx";

import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import ImageSubscription from "models/ImageSubscription";
import Api from "sensoteq-react-core/services/api";

export default class ImageStore extends SubscribableStore {
  constructor(rootStore) {
    super(rootStore, ImageSubscription);
  }

  uploadImage = flow(function* (siteName, name, file) {
    try {
      const {width, height} = yield new Promise((resolve) => {
        const img = new Image();
        img.onload = function () {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          resolve({width, height});
        };
        img.src = window.URL.createObjectURL(file);
      });
      const formData = new FormData();
      formData.append("image", file);
      yield Api.uploadImage(siteName, name, width, height, formData);
      yield this.sync({siteName, name});
      this.rootStore.notificationStore.addNotification("Image uploaded successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error uploading image: ${error}`, "bad");
    }
  });

  deleteImage = flow(function* (siteName, name, imageId) {
    try {
      yield Api.deleteImage(siteName, name, imageId);
      yield this.sync({siteName, name});
      this.rootStore.notificationStore.addNotification("Image deleted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting image: ${error}`, "bad");
    }
  });
}
