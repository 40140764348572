import React, {useState} from "react";
import {observer} from "mobx-react";
import dayjs from "dayjs";

import TopBarDropdown from "components/layout/TopBarDropdown";
import {useRootStore} from "stores";
import ActionIcon from "components/common/ActionIcon";
import * as Styled from "./NotificationDropdown.styles";
import EmptyState from "components/common/EmptyState";
import useCloseOnClick from "../hooks/useCloseOnClick";

function NotificationDropdown() {
  const [visible, setVisible] = useState(false);
  let iconMap = {
    good: "check",
    warning: "warning",
    bad: "error",
    info: "info",
  };

  const callbackOnClose = React.useCallback(() => {
    setVisible(false);
  }, [visible]);

  const callbackOnOpen = React.useCallback(() => {
    setVisible(true);
  }, [visible]);

  const {notificationStore} = useRootStore();
  const {notifications} = notificationStore;

  // Attribute needed for 'useCloseOnClick'.
  const containerRef = React.useRef();

  // Allow dropdown to be closed by clicking anywhere.
  const dropdown = useCloseOnClick(containerRef, callbackOnOpen, callbackOnClose);

  return (
    <Styled.Container>
      <ActionIcon
        tooltip="Show notifications"
        icon="notifications_none"
        onClick={!visible ? dropdown.open : dropdown.close}
      />
      <TopBarDropdown
        visible={visible}
        width="36rem"
        title="Recent notifications"
        actions={[{label: "Close", onClick: dropdown.close}]}
        align="right"
        padded={false}
      >
        {!notifications.length && (
          <EmptyState
            title="No notifications found"
            text="Notifications will be listed here."
            icon="alarm_off"
          />
        )}
        {notifications
          .slice()
          .reverse()
          .map((n, idx) => {
            return (
              <Styled.Notification
                key={idx}
                type={n.type}
              >
                <i className="material-icons">{iconMap[n.type]}</i>
                <div>{n.message}</div>
                <div>{dayjs(n.timestamp).format("HH:mm")}</div>
              </Styled.Notification>
            );
          })}
      </TopBarDropdown>
    </Styled.Container>
  );
}

export default observer(NotificationDropdown);
