import dayjs from "dayjs";

export default function (graph) {
  const eventColWidths = [3, 25, 52, 20];
  // Ensures that the events are in the correct chronological order.
  graph.events?.reverse();
  return (
    `<span contenteditable="false" class="fr-img-caption fr-fic fr-dib fr-draggable" style="width: 100%;" draggable="false">` +
    `<span class="fr-img-wrap">` +
    `<img src="${graph.data}" class="fr-draggable">` +
    `<span contenteditable="true" style="color: #888; font-style: italic;" class="fr-inner">` +
    `${graph.title}` +
    `</span>` +
    `</span>` +
    `</span>` +
    (!graph.events?.length
      ? ""
      : `<table class="" style="width: 100%;">` +
        `<tbody>` +
        `<tr>` +
        `<td style="width: ${eventColWidths[0]}%;"><strong><span style="font-size: 12px">#</span></strong><br></td>` +
        `<td style="width: ${eventColWidths[1]}%;"><strong><span style="font-size: 12px">Name</span></strong><br></td>` +
        `<td style="width: ${eventColWidths[2]}%;"><strong><span style="font-size: 12px">Description</span></strong><br></td>` +
        `<td style="width: ${eventColWidths[3]}%;"><strong><span style="font-size: 12px">Date</span></strong><br></td>` +
        `</tr>` +
        (graph.events || [])
          .map((event, idx) => {
            return (
              `<tr>` +
              `<td style="width: ${eventColWidths[0]}%;"><span style="font-size: 12px">&nbsp;${
                idx + 1
              }</span><br></td>` +
              `<td style="width: ${eventColWidths[1]}%;"><span style="font-size: 12px">${event.name}</span><br></td>` +
              `<td style="width: ${eventColWidths[2]}%;"><span style="font-size: 12px">${event.description}</span><br></td>` +
              `<td style="width: ${eventColWidths[3]}%;"><span style="font-size: 12px">${dayjs(event.timestamp).format(
                "MMM Do YYYY, HH:mm",
              )}</span><br></td>` +
              `</tr>`
            );
          })
          .join("") +
        `</tbody>` +
        `</table>`) +
    `<p></p>`
  );
}
