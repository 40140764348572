import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {asField} from "../lib/mobx-form";
import SearchInput from "./SearchInput";
import Label from "./Label";
import ItemList from "../components/ItemList";

function SearchList(props) {
  const {fieldState, fieldApi, field, label} = props;
  const {value, error} = fieldState;
  const [searchInputApi, setSearchInputApi] = React.useState(null);

  const onDelete = React.useCallback(
    (deleteValue) => {
      const newValue = value.filter((x) => x !== deleteValue);
      fieldApi.setValue(newValue);
    },
    [fieldApi, value],
  );

  const onSearchChange = React.useCallback(
    (newValue) => {
      if (newValue == null) {
        return;
      }
      fieldApi.setValue([...value, props.extractIdentifier(newValue)]);
      searchInputApi && searchInputApi.reset();
    },
    [field, value, fieldApi, props.extractIdentifier, searchInputApi],
  );

  return (
    <Container>
      {label && (
        <Label>
          {label} ({value.length})
        </Label>
      )}
      {error && <Label error>{error}</Label>}
      {value.length > 0 && (
        <ItemList
          items={value}
          allowDeletion={true}
          noItemsText={null}
          onDelete={onDelete}
        />
      )}
      <SearchInput
        field={`${field}_search`}
        search={props.search}
        extractResults={props.extractResults}
        extractText={props.extractText}
        extractResultText={props.extractResultText}
        extractIdentifier={props.extractIdentifier}
        placeholder={props.placeholder}
        onChange={onSearchChange}
        getApi={setSearchInputApi}
      />
    </Container>
  );
}

SearchList.propTypes = {
  search: PropTypes.func.isRequired,
  extractResults: PropTypes.func,
  extractText: PropTypes.func,
  extractResultText: PropTypes.func,
  extractIdentifier: PropTypes.func,
  label: PropTypes.string,
  field: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
SearchList.defaultProps = {
  extractResults: (x) => x,
  extractText: (x) => x,
  extractResultText: (x) => x,
  extractIdentifier: (x) => x,
};

export default asField(SearchList, []);

const Container = styled.div`
  > div {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
