import styled, {css} from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 75%;
  display: none;
  z-index: 1;

  ${(props) =>
    props.align === "left" &&
    css`
      left: 0;
    `}

  ${(props) =>
    props.align === "right" &&
    css`
      right: 0;
    `}
  
  ${(props) =>
    props.theme.mixins.desktop(css`
      ${(props) =>
        props.visible &&
        css`
          display: block;
        `}
    `)}
  
  ${(props) =>
    props.theme.mixins.mobile(css`
      left: 0;
      right: 0;
      top: ${(props) => props.theme.dimensions.topBarHeight}rem;
      padding-top: 0;
      width: auto !important;

      ${(props) =>
        props.visibleMobile &&
        css`
          display: block;
        `}
    `)}
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.colours.contentBackground};
  box-shadow: ${(props) => props.theme.forms.heavyBoxShadow};
  border-radius: ${(props) => props.theme.forms.borderRadius};
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-height: calc(100vh - ${(props) => props.theme.dimensions.topBarHeight}rem - 6rem);

  ${(props) =>
    props.theme.mixins.mobile(css`
      max-height: 50vh;
      min-width: 0;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
      box-shadow: none;
      background-color: ${(props) => props.theme.colours.topBarBackground};
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `)}
`;

export const Header = styled.div`
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${(props) => props.theme.dimensions.vPadding}rem ${(props) => props.theme.dimensions.hPadding}rem 1.2rem
    ${(props) => props.theme.dimensions.hPadding}rem;
  border-bottom: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};

  ${(props) =>
    props.theme.mixins.desktop(css`
      ${(props) =>
        props.visible &&
        css`
          display: flex;
        `}
    `)}

  ${(props) =>
    props.theme.mixins.mobile(css`
      padding: 1.2rem ${(props) => props.theme.dimensions.hMargin + props.theme.dimensions.hPagePadding}rem;

      ${(props) =>
        props.visibleMobile &&
        css`
          display: flex;
        `}
    `)}
`;

export const Title = styled.div`
  flex: 1 1 auto;
  color: ${(props) => props.theme.colours.contentLight};
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const Action = styled.div`
  flex: 0 0 auto;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.colours.contentLight};
  margin-left: 1.5rem;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colours.primaryHighlight};
  }

  ${(props) =>
    props.desktop &&
    css`
      display: flex;
    `}

  ${(props) =>
    props.theme.mixins.mobile(css`
      ${(props) =>
        props.mobile &&
        css`
          display: flex;
        `}
    `)}
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1.2rem ${(props) => props.theme.dimensions.hPadding}rem;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;

  ${(props) =>
    props.headerVisible &&
    css`
      padding-bottom: ${(props) => props.theme.dimensions.vPadding}rem;
    `}

  ${(props) =>
    props.theme.mixins.mobile(css`
      padding-bottom: 1.2rem;
      padding-left: ${(props) => props.theme.dimensions.hMargin + props.theme.dimensions.hPagePadding}rem;
      padding-right: ${(props) => props.theme.dimensions.hMargin + props.theme.dimensions.hPagePadding}rem;
    `)}
  
  ${(props) =>
    !props.padded &&
    css`
      padding: 0 !important;
    `}
`;
