import styled, {css} from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  i,
  svg {
    color: ${(props) => props.theme.colours.content};
    user-select: none;
    transition:
      background-color 0.15s ease,
      color 0.15s ease;
  }

  ${(props) =>
    props.theme.mixins.desktop(css`
      &:hover {
        cursor: pointer;

        i,
        svg {
          color: ${(props) => props.theme.colours.primaryHighlight};
        }
      }
    `)}

  ${(props) =>
    props.theme.mixins.mobile(css`
      &:hover:active {
        cursor: pointer;

        i,
        svg {
          color: ${(props) => props.theme.colours.primaryHighlight};
        }
      }
    `)}
`;
