import styled, {css} from "styled-components";
import {IconSizes} from "./EmptyState";

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.padded &&
    css`
      padding: 3.2rem;
    `}

  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${(props) => props.minHeight - 6.4}rem;
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 35rem;

  ${(props) =>
    props.background &&
    css`
      background-color: ${(props) => props.theme.colours.contentBackground};
      border-radius: ${(props) => props.theme.forms.borderRadius};
      padding: 5rem;
      box-shadow: ${(props) => props.theme.forms.boxShadow};
    `};
`;

export const Icon = styled.div`
  margin-bottom: 2.4rem;
  background-color: ${(props) => props.theme.colours.primaryBackground};
  border-radius: 50%;

  i {
    font-size: 8rem !important;
    color: ${(props) => props.theme.colours.primary};
    padding: 4rem;

    ${(props) =>
      props.iconSize === IconSizes.Small &&
      css`
        font-size: 3.2rem !important;
        padding: 2rem;
      `}
    ${(props) =>
      props.iconSize === IconSizes.Large &&
      css`
        font-size: 16rem !important;
        padding: 6rem;
      `}
  }

  ${(props) =>
    props.lessPadding &&
    props.iconSize === IconSizes.Small &&
    css`
      margin-bottom: 1.5rem;
    `}
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.slightlyLarge};
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
`;

export const Text = styled.div`
  color: ${(props) => props.theme.colours.contentLight};
  text-align: center;
  margin-top: 0.5rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 3.2rem;
`;
