import {computed, flow, observable} from "mobx";

import Api from "sensoteq-react-core/services/api";
import LoadableStore from "sensoteq-react-core/models/LoadableStore";
import {getAxisStrategy} from "sensoteq-core/utils";
import {Gen2DataType, isTimeDomainType, SensorTypes, isSensorTimeSynced} from "sensoteq-core/enumerations";
import {SensorVersions} from "constants/versions";
import {deriveAxis, deriveDirection, getFullDirection, getShortDirection} from "@sensoteq/core";

export default class SensorStore extends LoadableStore {
  @observable.shallow _sensors;
  humanReadableTypeMap = {
    // PMES
    [SensorTypes.LOW_G_MOTOR]: "FFT sensor",
    [SensorTypes.LOW_G_RMS_MOTOR]: "RMS FFT sensor",
    [SensorTypes.LOW_G_HIGH_MOTOR]: "High Res FFT Sensor",
    [SensorTypes.LOW_G_AUTO_MOTOR]: "Smart FFT sensor",
    [SensorTypes.KPX]: "Kappa X",
    [SensorTypes.HIGH_G_MOTOR]: "Force sensor",

    // SCES
    [SensorTypes.LOW_G_SCREEN]: "Screen FFT sensor",
    [SensorTypes.HIGH_G_SCREEN]: "Screen motion sensor",
    [SensorTypes.HIGH_G_SCREEN_TWF]: "Screen TWF and motion sensor",
  };
  humanReadableModeMap = {
    [Gen2DataType.OLD_AXIS_PEAKS_TOP_5_2G.key]: "Old top peaks (2G)",
    [Gen2DataType.AXIS_PEAKS_TOP_5_2G.key]: "Top peaks (2G)",
    [Gen2DataType.AXIS_PEAKS_TOP_5_4G.key]: "Top peaks (4G)",
    [Gen2DataType.AXIS_PEAKS_TOP_5_8G.key]: "Top peaks (8G)",
    [Gen2DataType.AXIS_PEAKS_TOP_5_16G.key]: "Top peaks (16G)",
    [Gen2DataType.PEAK_TO_PEAK.key]: "Peak to peak",
    [Gen2DataType.PHASE_AND_PEAKS.key]: "Phase and peaks",
    [Gen2DataType.SW_VERSION.key]: "Software Version",
    [Gen2DataType.SLEEPING.key]: "Sleeping",
  };

  constructor(rootStore) {
    super(rootStore);
  }

  @computed get loading() {
    return !!this._loadingCount || this.rootStore.machineStore.loading;
  }

  @computed get sensors() {
    let sensors = this._sensors || [];
    this.rootStore.machineStore.machines.forEach((machine) => {
      if (machine.sensors && machine.sensors.length) {
        sensors = sensors.concat(machine.sensors);
      }
    });
    return sensors.map((sensor) => {
      const strategy = getAxisStrategy(sensor.sensor_id);
      const orientation = sensor.orientation;
      return {
        ...sensor,
        getXYZAxis: (axis) => this.getXYZfromHVA(strategy, axis, orientation, sensor.type),
        getHVAAxis: (axis) => this.getHVAfromXYZ(strategy, axis, orientation, sensor.type),
        strategy: strategy,
        frequency: sensor.software_sha ? SensorVersions[sensor.software_sha.toUpperCase()] : undefined,
        timeSync: isSensorTimeSynced(sensor.type),
      };
    });
  }

  @computed get _reverseLookupMap() {
    let lookupMap = {};
    this.sensors.forEach((sensor) => {
      lookupMap[sensor.sensor_id] = sensor;
    });
    return lookupMap;
  }

  @computed get _allocationLookupMap() {
    let map = {};
    this.rootStore.pointStore.testPoints
      .filter((x) => !x.disabled)
      .forEach((point) => {
        map[point.sensor_id] = {
          pointId: point?.point_id,
          machineId: point?.machine_id,
          siteName: point?.site_name,
        };
      });
    return map;
  }

  getXYZfromHVA(strategy, direction, sensorOrientation = "side", type) {
    const fullDirection = getFullDirection(direction);
    const axis = deriveAxis({direction: fullDirection, orientation: sensorOrientation, sensorType: type, strategy});
    if (axis !== undefined) return axis;
    throw "Invalid XYZ strategy!";
  }
  getHVAfromXYZ(strategy, axis, sensorOrientation = "side", type) {
    const direction = deriveDirection({axis, orientation: sensorOrientation, sensorType: type, strategy});
    const shortDirection = getShortDirection(direction);
    if (shortDirection !== undefined) return shortDirection;
    throw "Invalid HVA strategy!";
  }

  getSensor(sensorId) {
    return this._reverseLookupMap[sensorId];
  }

  getSensorByPointId(pointId) {
    const point = this.rootStore.pointStore.getTestPoint(pointId);
    return point == null ? null : this.getSensor(point.sensor_id);
  }

  getAllocation(sensorId) {
    return this._allocationLookupMap[sensorId];
  }

  sensorExists(sensorId) {
    return this.getSensor(sensorId) != null;
  }

  resetSensorPowerBands(site, machine, sensor) {
    this.setSensorPowerBands(site, machine, sensor, {});
  }

  getHumanReadableType(sensor) {
    if (!sensor.type) {
      return "-";
    }
    return this.humanReadableTypeMap[sensor.type] || `Other (${sensor.type})`;
  }

  getHumanReadableMode(sensor) {
    if (!sensor.mode) {
      return "-";
    }
    if (sensor.mode === "sleeping") {
      return "Sleeping";
    } else {
      if (!sensor.data_type) {
        return "-";
      }
      const entries = Object.entries(Gen2DataType);
      for (let i = 0; i < entries.length; i++) {
        const typeInfo = entries[i][1];
        if (typeInfo.key === sensor.data_type) {
          if (isTimeDomainType(typeInfo.val)) {
            return `Time domain (${typeInfo.axis.toUpperCase()}, ${typeInfo.resolution} res)`;
          } else {
            return this.humanReadableModeMap[sensor.data_type] || `Other (${sensor.data_type})`;
          }
        }
      }
    }
  }

  getFrequency(sensor) {
    return sensor.software_sha ? SensorVersions[sensor.software_sha.toUpperCase()] : undefined;
  }

  setSensorOrientation = flow(function* (site, machine, sensor, orientation) {
    try {
      yield Api.setSensorOrientation(sensor, orientation);
      this.rootStore.machineStore.refreshMachine(machine);
      this.rootStore.notificationStore.addNotification("Orientation set successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error setting orientation: ${error}`, "bad");
    }
  });

  setSensorRpm = flow(function* (site, machine, sensor, rpm) {
    try {
      yield Api.setSensorRPM(sensor, rpm);
      this.rootStore.machineStore.refreshMachine(machine);
      this.rootStore.notificationStore.addNotification("RPM set successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error setting rpm: ${error}`, "bad");
    }
  });

  setSensorPowerBands = flow(function* (site, machine, sensor, bands) {
    try {
      yield Api.setPowerBands(sensor, bands);
      this.rootStore.machineStore.refreshMachine(machine);
      this.rootStore.notificationStore.addNotification("Power bands set successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error setting bands: ${error}`, "bad");
    }
  });

  setSensorNotchFilters = flow(function* (site, machine, sensor, notchFilters) {
    try {
      yield Api.setSensorNotchFilter(sensor, notchFilters);
      this.rootStore.machineStore.refreshMachine(machine);
      this.rootStore.notificationStore.addNotification("Notch filters set successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error setting notch filters: ${error}`, "bad");
    }
  });
}
