import {revertAlarmSummaryString} from "sensoteq-core/cloud/alarm-summary";
import {flow} from "mobx";
import dayjs from "dayjs";
import Api from "sensoteq-react-core/services/api";
import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import AlarmEventSubscription from "models/AlarmEventSubscription";
import {AlarmTargetTypes, AlarmTypes} from "sensoteq-core/enumerations";
import {Link} from "sensoteq-react-core/lib/mobx-state-router";

export default class AlarmEventStore extends SubscribableStore {
  constructor(rootStore) {
    super(rootStore, AlarmEventSubscription);
  }

  notifyRealTimeEvents = flow(function* () {
    let realTimeEvents = [];
    try {
      const from = dayjs().subtract(1, "minute").valueOf();
      const targetType = AlarmTargetTypes.LOGGED_IN_USER;
      const events = yield Api.getAlarmHistory(null, targetType, from, dayjs().valueOf());
      realTimeEvents = events.history;
    } catch (error) {}

    for (let i = 0; i < realTimeEvents.length; i++) {
      const event = realTimeEvents[i];
      const to = `/site/${event.site_name}/overview`;
      const parsedTarget = this.rootStore.alarmStore.parseAlarmTarget(event.target);
      const data = revertAlarmSummaryString(event.condition, parsedTarget.orientation, parsedTarget.sensorType);
      const level = data.type === AlarmTypes.CRITICAL ? "alarm_critical" : "alarm_warning";
      const site = this.rootStore.siteStore.getSite(event.site_name);
      const message = (
        <div>
          <Link
            to={to}
            noDecoration={true}
          >
            <strong style={{color: "black", textDecoration: "none"}}>{site.display_name}</strong>
            <p style={{color: "black", textDecoration: "none"}}>{event.condition}</p>
          </Link>
        </div>
      );
      this.rootStore.notificationStore.addNotification(message, level);
    }
  });
}
