import PropTypes from "prop-types";

import * as Styled from "./StatusIcon.styles";
import * as Enums from "constants/enums";
import Icon from "./Icon";

const IconMap = {
  [Enums.StatusThemes.INFO]: "info-circle",
  [Enums.StatusThemes.OFFLINE]: "cloud_off",
  [Enums.StatusThemes.GOOD]: "check-circle",
  [Enums.StatusThemes.WARNING]: "exclamation-circle",
  [Enums.StatusThemes.BAD]: "exclamation-circle",
};
const LibraryMap = {
  [Enums.StatusThemes.OFFLINE]: Icon.Libraries.MaterialIcons,
};

function StatusIcon(props) {
  const {theme} = props;
  return (
    <Styled.Icon userTheme={theme}>
      <Icon
        library={LibraryMap[theme] || Icon.Libraries.FontAwesome}
        name={IconMap[theme]}
      />
    </Styled.Icon>
  );
}

StatusIcon.Themes = Enums.StatusThemes;
StatusIcon.propTypes = {
  theme: PropTypes.string,
};
StatusIcon.defaultProps = {
  theme: Enums.StatusThemes.INFO,
};

export default StatusIcon;
