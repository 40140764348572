import React from "react";
import {action} from "mobx";
import {useLocalStore} from "mobx-react";

export default (createSub, deps = []) => {
  // Ref to keep a single reference to the changeable create sub function
  const createSubRef = React.useRef(null);
  createSubRef.current = createSub;

  // Observable state
  const state = useLocalStore(() => ({
    subscription: null,
    get loading() {
      return !!state.subscription?.loading;
    },
    get initialLoad() {
      return state.subscription == null || state.subscription.initialLoad;
    },
    get initiated() {
      return state.subscription != null && state.subscription.initiated;
    },
    get sub() {
      return state.subscription;
    },
    setSubscription: action((subscription) => (state.subscription = subscription)),
  }));

  // Create sub as needed
  React.useEffect(() => {
    const newSub = createSubRef.current();
    state.setSubscription(newSub);
    return () => {
      newSub && newSub.cancel();
    };
  }, deps);

  return state;
};
