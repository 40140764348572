import React, {useState} from "react";
import dayjs from "dayjs";
import {observer} from "mobx-react";

import MultiDayjsPicker from "./MultiDayjsPicker";
import {asField} from "../lib/mobx-form";
import Label from "./Label";

const DateMultiPicker = ({label, field, fieldState, fieldApi, onChange, border = true}) => {
  const [multiOpen, setMultiOpen] = useState(false);

  const getValue = () => {
    let value = fieldState.value;

    if (!value.dateMulti) {
      value.dateMulti = dayjs();
    }

    return value;
  };

  const onChangeDates = (dateMulti) => {
    const {setValue} = fieldApi;
    // Sanitise dates
    let sanitisedDateMulti = dayjs(dateMulti).startOf("day");
    // Update value
    const value = {
      dateMulti: sanitisedDateMulti,
    };
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };
  const {error, disabled} = fieldState;
  const {dateMulti} = getValue();

  return (
    <div className="flex flex-col justify-start items-stretch content-stretch">
      {label && <Label htmlFor={field}>{label}</Label>}
      {error && (
        <Label
          error
          htmlFor={field}
        >
          {error}
        </Label>
      )}
      <div
        className={`flex flex-row justify-center items-center relative bg-inputBackground rounded-lg border-borderWidth border-solid border-transparent
        ${border ? "border-border" : "shadow-box-shadow bg-content-background"} ${
          multiOpen ? "border-primary-highlight" : ""
        }`}
        open={multiOpen}
      >
        <div
          className={`flex flex-auto flex-row content-center items-stretch ${multiOpen ? "bg-primary-highlight" : ""}`}
          open={multiOpen}
        >
          <MultiDayjsPicker
            onChangeDates={onChangeDates}
            selectedDates={dateMulti?.length > 0 ? dateMulti.map((selectedDate) => selectedDate?.from) : []}
            disabled={disabled}
            controlled
            allowNull={false}
            placeholder="Select dates"
            format="MMMM Do YYYY"
          />
        </div>
      </div>
    </div>
  );
};

export default asField(observer(DateMultiPicker), {});
