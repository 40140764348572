import {useEffect, useState} from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "@sensoteq/styles/dist/sensoteq-tailwind-config.cjs";
const fullConfig = resolveConfig(tailwindConfig);

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      setIsMobile(window.innerWidth < parseInt(fullConfig.theme.screens.sm));
    };
    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return isMobile;
};

const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      setIsTablet(window.innerWidth < parseInt(fullConfig?.theme?.screens?.md));
    };
    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return isTablet;
};

export {useIsMobile, useIsTablet};
