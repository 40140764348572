import React, {useCallback, useMemo, useRef, useState, useEffect} from "react";
import {observer} from "mobx-react";

import SearchDropdown from "./SearchDropdown";
import TopBarDropdown from "components/layout/TopBarDropdown";
import AppRoutes from "routes/app-routes";
import PointPageRoutes from "routes/point/point-routes";
import {getColor} from "routes/utils";
import {useRootStore} from "../../stores";
import ActionIcon from "components/common/ActionIcon";
import useSubscription from "components/hooks/useSubscription";
import {AlarmTargetTypes} from "sensoteq-core/enumerations";

const SearchBar = () => {
  const {uiStore, siteStore, routerStore, userStore, preferenceStore, alarmReportStore, alarmEventStore, themeStore} =
    useRootStore();
  const [visible, setVisible] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const inputElement = useRef();
  const [blockClose, setBlockClose] = useState(false);

  const wrapperRef = useRef(null);

  // Load alarm reports on mount
  React.useEffect(() => {
    if (!alarmReportStore.loading && !alarmReportStore.initiated) {
      alarmReportStore.loadLatestSiteAlarmReports();
    }
  }, [alarmReportStore, alarmReportStore.loading, alarmReportStore.initiated]);

  const clear = useCallback(() => {
    uiStore.setSearchString("");
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [uiStore]);

  const close = useCallback(() => {
    toggleSearchBar(false);
    setBlockClose(false);
    uiStore.setSearchString("");
    setPlaceholder("");
    if (inputElement.current) {
      inputElement.current.blur();
    }
  }, [uiStore]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && visible) {
        toggleSearchBar(false);
        clear();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, visible, clear]);

  const searchString = useMemo(() => {
    return uiStore.searchString == null || uiStore.searchString === "" || uiStore.searchString.length < 2
      ? null
      : uiStore.searchString.toLowerCase();
  }, [uiStore.searchString]);

  const goToURL = useCallback(
    (url) => {
      routerStore.push(url);
      close();
    },
    [routerStore, close],
  );

  const goToMachine = useCallback(
    (machineId) => {
      goToURL(
        routerStore.createUrl(AppRoutes.MachinePage, {
          machine: machineId,
        }),
      );
    },
    [goToURL, routerStore],
  );

  const goToMeasuringPoint = useCallback(
    (point) => {
      goToURL(
        routerStore.createUrl(PointPageRoutes.PointOverViewTab, {
          point,
        }),
      );
    },
    [goToURL, routerStore],
  );

  const goToSite = useCallback(
    (site) => {
      goToURL(
        routerStore.createUrl(AppRoutes.SitePage, {
          site,
        }),
      );
    },
    [goToURL, routerStore],
  );

  const getSitesWithStatus = useCallback(() => {
    const {hideOfflineSites} = preferenceStore;
    return (siteStore.sites ?? []).map((site) => {
      const reducer = (offline, machine) => offline && !machine.online;
      const offline = site.machines.reduce(reducer, true);
      const latestAlarmReport = alarmReportStore.getLatestSiteAlarmReport(site.site_name);
      return {
        ...site,
        warning: latestAlarmReport.warning,
        critical: latestAlarmReport.critical,
        offline: hideOfflineSites ? false : offline,
      };
    });
  }, [alarmReportStore, preferenceStore, siteStore.sites]);

  const alarmEventSubscription = useSubscription(() => {
    if (!userStore.admin) {
      const sub = alarmEventStore.subscribe("search-bar-alarm-events");
      sub.update({targetType: AlarmTargetTypes.LOGGED_IN_USER});
      return sub;
    }
  }, [alarmEventStore, userStore.admin]);

  const getColorForStatus = React.useCallback(
    (status) => {
      if (status?.hasCritical) {
        return themeStore.theme.colours.bad;
      } else if (status?.hasWarning) {
        return themeStore.theme.colours.warning;
      }
      return themeStore.theme.colours.good;
    },
    [themeStore],
  );

  const searchResults = useMemo(() => {
    const sites = getSitesWithStatus();
    const validSearchString = searchString != null;

    let count = 0;
    const matches = (name) => {
      const match = !validSearchString || name?.toLowerCase().indexOf(searchString) !== -1;
      if (match) {
        count++;
      }
      return match;
    };

    const displayModel = {
      sites: sites.map((site) => {
        const machines = site.machines.map((machine) => {
          const measuringPoints = machine.test_points.map((point) => {
            const match = matches(point.label);
            return {
              label: point.label,
              match,
              point_id: point.point_id,
            };
          });
          const match = matches(machine.label);
          return {
            label: machine.label,
            measuringPoints,
            match,
            open: validSearchString && !measuringPoints.every((point) => !point.match),
            machine_id: machine.machine_id,
          };
        });
        const match = !validSearchString || matches(site.display_name);
        return {
          name: site.site_name,
          displayName: site.display_name,
          machines,
          match,
          open: validSearchString && !machines.every((machine) => !machine.open && !machine.match),
          offline: site.offline,
          critical: site.critical,
          warning: site.warning,
        };
      }),
    };

    return {
      results: displayModel.sites
        .filter((x) => x.open || x.match)
        .map((site) => {
          return (
            <SearchDropdown
              key={site.name}
              initiallyOpen={site.open}
              text={site.displayName}
              match={site.match}
              level={0}
              onClick={() => goToSite(site.name)}
              canExpand={!!site.machines.length}
              color={site.offline ? "#aaa" : site.critical ? getColor(100) : site.warning ? getColor(50) : getColor(0)}
            >
              {site.machines
                .filter((x) => x.match || x.open || site.match)
                .map((machine) => {
                  return (
                    <SearchDropdown
                      key={machine.label}
                      initiallyOpen={machine.open}
                      text={machine.label}
                      match={machine.match}
                      level={1}
                      onClick={() => goToMachine(machine.machine_id)}
                      canExpand={!!machine.measuringPoints.length}
                      color={
                        alarmEventSubscription.sub
                          ? getColorForStatus(alarmEventSubscription.sub?.getLiveMachineStatus(machine.machine_id))
                          : undefined
                      }
                    >
                      {machine.measuringPoints
                        .filter((x) => x.match || machine.match || site.match)
                        .map((point) => {
                          return (
                            <SearchDropdown
                              key={point.label}
                              text={point.label}
                              match={point.match}
                              level={2}
                              onClick={() => goToMeasuringPoint(point.point_id)}
                              color={
                                alarmEventSubscription.sub
                                  ? getColorForStatus(alarmEventSubscription.sub.getLivePointStatus(point.point_id))
                                  : undefined
                              }
                            />
                          );
                        })}
                    </SearchDropdown>
                  );
                })}
            </SearchDropdown>
          );
        }),
      count: validSearchString ? count : sites.length,
    };
  }, [searchString, goToMachine, goToMeasuringPoint, goToSite, getSitesWithStatus, alarmEventSubscription.sub]);

  const dataType = useMemo(() => {
    return searchString ? "result" : "site";
  }, [searchString]);

  const open = () => {
    if (visible) {
      blockNextClick();
    } else {
      setBlockClose(false);
    }
    toggleSearchBar(true);
  };

  const blockNextClick = () => {
    setBlockClose(true);
  };

  const onDocumentClick = () => {
    if (blockClose) {
      setBlockClose(false);
      return;
    }
    setTimeout(close, 10);
  };

  const toggleSearchBar = (value) => {
    if (!value) {
      setTimeout(() => {
        setVisible(value);
      }, 100);
    } else {
      setVisible(value);
    }
  };

  useEffect(() => {
    if (visible) {
      inputElement.current.focus();
      setPlaceholder("Search...");
    } else {
      inputElement.current.blur();
      setPlaceholder("");
    }
    return () => setPlaceholder("");
  }, [visible]);

  return (
    <div
      className={`self-start md:relative flex flex-col justify-center
      items-stretch !flex-auto md:max-w-[15rem] min-w-[5rem] ease-linear duration-150 h-full max-w-none static
      ${visible ? "md:max-w-[32rem]" : ""}`}
    >
      <div ref={wrapperRef}>
        <div className={`relative grow-0 shrink-0 basis-auto flex flex-row justify-start items-center`}>
          <input
            className={`w-0 flex-auto flex justify-start items-center
            relative outline-none py-[0.9rem] px-[1.2rem] pr-[4.4rem] whitespace-nowrap
            overflow-hidden text-ellipsis bg-transparent rounded-[0.4rem] mr-2
            ${visible ? "bg-input-background border-[0.1rem] border-primary-highlight " : ""}
            hover:cursor-text`}
            ref={inputElement}
            placeholder={placeholder}
            onClick={open}
            onChange={(e) => uiStore.setSearchString(e.target.value)}
            value={uiStore.searchString}
            onBlur={onDocumentClick}
          />
          <ActionIcon
            tooltip="Search"
            onClick={() => toggleSearchBar(!visible)}
            icon={"search"}
          />
        </div>

        {visible && (
          <TopBarDropdown
            width="100%"
            visible={visible}
            title={`${searchResults.count} ${dataType}${searchResults.count === 1 ? "" : "s"}`}
            actions={[
              {label: "Clear", onClick: clear},
              {label: "Close", onClick: close},
            ]}
            onMouseDown={blockNextClick}
            onTouchStart={blockNextClick}
            className="!top-auto"
          >
            {searchResults.count > 0 && (
              <div className={`font-medium pb-[0.2rem]`}>{userStore.company.display_name}</div>
            )}
            {searchResults.results}
            {!searchResults.results ||
              (!searchResults.results.length && (
                <div className={`font-medium whitespace-nowrap flex flex-row justify-start items-center`}>
                  No {dataType}
                  {searchResults.count === 1 ? "" : "s"} found
                </div>
              ))}
          </TopBarDropdown>
        )}
      </div>
    </div>
  );
};

export default observer(SearchBar);
