import {observable, flow, computed} from "mobx";
import dayjs from "dayjs";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import * as Enums from "constants/enums";
import Api from "sensoteq-react-core/services/api";

export default class CriticalitySubscription extends DataSubscription {
  getDefaultParams() {
    return {
      target: null,
      getTimeOption: () => this.rootStore.uiStore.criticalityTimeOption,
      getTimeRange: () => this.rootStore.uiStore.criticalityDataDates,
    };
  }
  getParsedParams(params) {
    return {
      timeOption: params.getTimeOption(),
      from: params.getTimeRange().from,
      to: params.getTimeRange().to,
    };
  }

  @observable.shallow _data;

  getData = flow(function* ({from, to, target, timeOption}) {
    if (!target) {
      return;
    }

    this.startLoading();
    try {
      if (timeOption === Enums.TIME_OPTION_LATEST) {
        const data = yield Api.getLatestCriticalityData(target);
        this._data = data.criticality;
      } else {
        const data = yield Api.getHistoricalCriticalityData(target, from.valueOf(), to.valueOf());
        this._data = data.criticality;
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting criticality data: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get criticalityData() {
    if (!this._data || this._data.length < 2) {
      return null;
    }
    return [
      {
        data: this._data.map((x) => ({
          value: x.data.trendable,
          timestamp: dayjs(x.timestamp),
        })),
      },
    ];
  }

  getAveragedData(from, to) {
    if (!this.criticalityData) {
      return 0;
    }
    const data = this.criticalityData[0].data.filter((x) => x.timestamp >= from && x.timestamp < to);
    let total = 0;
    data.forEach((x) => {
      total += x.value;
    });
    return total / (data.length || 1);
  }

  getMaximumData(from, to) {
    if (!this.criticalityData) {
      return 0;
    }
    const data = this.criticalityData[0].data.filter((x) => x.timestamp >= from && x.timestamp < to);

    return data.reduce((max, criticalityDataPoint) => {
      return criticalityDataPoint.value > max ? criticalityDataPoint.value : max;
    }, 0);
  }

  @computed get currentWeekHighestScore() {
    return this.getMaximumData(Date.now() - Enums.TIME_1_WEEK, Date.now());
  }

  @computed get currentWeekAverageScore() {
    return this.getAveragedData(Date.now() - Enums.TIME_1_WEEK, Date.now());
  }

  @computed get previousWeekAverageScore() {
    return this.getAveragedData(Date.now() - Enums.TIME_1_WEEK * 2, Date.now() - Enums.TIME_1_WEEK);
  }

  @computed get weeklyDelta() {
    return Math.round(
      ((this.currentWeekAverageScore - this.previousWeekAverageScore) / (this.previousWeekAverageScore || 1)) * 100,
    );
  }
}
