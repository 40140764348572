const enums = require("../sensoteq-core/enumerations");
const dbEnums = require("../sensoteq-core/cloud/db-enums");
const utils = require("../sensoteq-core/utils");
const alarmProps = require("../sensoteq-core/alarm-properties");
const qs = require("query-string");

const TIME_BEFORE_MS = enums.TIME_BEFORE_ALARM_HMI_MS;
const TIME_AFTER_MS = enums.TIME_AFTER_ALARM_HMI_MS;
let BASE_URL =
  enums.ENVIRONMENT_BRANDING === "haver"
    ? "https://hmi.pulsecmdata.haverniagara.com.br/"
    : "https://analytix.sensoteq.com/";

function URLOptions(timestamp = null, direction = null, resolution = null) {
  this.timestamp = timestamp;
  this.direction = direction;
  this.resolution = resolution;
}

URLOptions.prototype.hasTimeDomainParams = function () {
  return this.timestamp != null && this.direction != null;
};

URLOptions.prototype.hasDirection = function () {
  return this.direction != null && typeof this.direction === "string";
};

module.exports.setHMIUrl = (url) => {
  if (url != null) {
    BASE_URL = url;
  }
};

module.exports.URLOptions = URLOptions;

module.exports.oldGetHMIUrl = (alarm, timestamp = null, direction = null) => {
  let options = new URLOptions(timestamp, direction);
  return module.exports.getHMIUrl(alarm, options);
};

module.exports.getHMIUrl = (alarm, urlOptions = null) => {
  let url = BASE_URL;

  let target = alarm[dbEnums.AlarmTableInfo.ALARM_TARGET];
  let alarmType = alarm[dbEnums.AlarmTableInfo.ALARM_DATA][enums.PresentableDataTypes.ALARM_TYPE];
  let isStatus = alarmType === enums.AlarmTypes.ONLINE_STATUS;
  let property = alarm[dbEnums.AlarmTableInfo.ALARM_DATA][enums.PresentableDataTypes.ALARM_PROPERTY];
  let siteName = alarm[dbEnums.AlarmTableInfo.ALARM_PRIMARY_KEY].replace(/ /g, ".");
  let propertyInfo = alarmProps.getPropertyInfo(alarm);
  const resolution = propertyInfo.resolution ?? urlOptions.resolution;

  // no data to link just link to the site
  if (alarmType === enums.AlarmTypes.ONLINE_STATUS && utils.isGatewayName(target)) {
    // get the url encoded site name and add it
    url += `site/${siteName}/`;
    return url;
  }

  // point status, open the machine it is part of
  if (isStatus) {
    url += `point/${target}/details/`;
    return url;
  }

  // add receiver + sensor ID
  url += `point/${target}/`;

  // add the tab
  // peak to peak should be before isSensorAxisProperty as it is true for both but it has its own tab in HMI
  if (alarmProps.isForcePeakToPeakProperty(alarm)) {
    url += "peak-to-peak";
  } else if (alarmProps.isScreenProperty(alarm)) {
    url += "ellipse-trending";
  } else if (alarmProps.isEnvironmentalProperty(alarm)) {
    url += "environment";
  } else if (alarmProps.isSensorAxisProperty(alarm)) {
    url += "vibration";
  } else if (alarmProps.isVibPowerBandProperty(alarm)) {
    url += "vibration-spectrum";
  } else if (alarmProps.isTrendingCalsProperty(alarm)) {
    url += "vibration-spectrum-trending";
  } else if (alarmProps.isDiagnosticProperty(alarm)) {
    url += "diagnostics";
  } else if (alarmProps.isThermalProperty(alarm)) {
    let search = url.search("point/");
    url = url.substr(0, search) + `thermal/${target}`;
  } else {
    throw {error: `Cannot create HMI URL with an unknown property: ${property}`};
  }

  // now to fill out the query string params
  url += "?";
  url += qs.stringify({to: "custom"}) + "&";

  let timestamp = urlOptions.timestamp;
  if (timestamp == null) {
    timestamp = utils.getEpochMilliseconds();
  }

  if (alarmProps.isVibTrendingProperty(alarm) && !urlOptions.hasTimeDomainParams()) {
    throw {error: "Cannot generate spectrum URL without all required URL params."};
  }

  const WEEK_IN_MILLIS = enums.DAY_IN_MILLIS * 7;
  if (alarmProps.isTrendingCalsProperty(alarm)) {
    url += qs.stringify({tf: timestamp - WEEK_IN_MILLIS}) + "&";
    url += qs.stringify({tt: timestamp + WEEK_IN_MILLIS}) + "&";
    url += qs.stringify({res: resolution}) + "&";
    if (urlOptions.hasDirection()) {
      url += qs.stringify({axis: urlOptions.direction.charAt(0).toLowerCase()}) + "&";
    }
  } else if (alarmProps.isVibPowerBandProperty(alarm)) {
    // handle the special vibration power cases
    url += qs.stringify({wo: "hann_window"}) + "&";
    url += qs.stringify({time: timestamp}) + "&";
    url += qs.stringify({kt: timestamp}) + "&";
    url += qs.stringify({ampl: "peak"}) + "&";
    url += qs.stringify({res: resolution}) + "&";
    if (urlOptions.hasDirection()) {
      url += qs.stringify({axis: urlOptions.direction.charAt(0).toLowerCase()}) + "&";
    }
  }
  // handle every other case
  else {
    url += qs.stringify({tf: timestamp - TIME_BEFORE_MS}) + "&";
    url += qs.stringify({tt: timestamp + TIME_AFTER_MS}) + "&";
    if (urlOptions.hasDirection()) {
      url += qs.stringify({axis: urlOptions.direction.charAt(0).toLowerCase()}) + "&";
    }
  }

  return url;
};
