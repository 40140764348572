import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import autobind from "class-autobind-decorator";
import ReactTooltip from "react-tooltip";

import Popup from "../components/Popup";
import {useFormApi} from "../lib/mobx-form";
import {Input} from "./index";

const Colours = {
  Red: "#dd3c3c",
};

const Themes = {
  Normal: "Normal",
  Good: "Good",
  Warning: "Warning",
  Bad: "Danger",
  Info: "Info",
  Dark: "Dark",
  Filled: "Filled",
};

@autobind
class Button extends React.Component {
  id = Math.random().toString(32).substr(2);
  state = {
    showConfirmation: false,
    confirmationInputValidation: "",
  };

  onClick(e) {
    e.preventDefault();
    e.stopPropagation();
    const {onClick, confirmation, formApi, type} = this.props;
    if (type === "submit " && formApi && !formApi.isValid()) {
      return;
    }
    if (confirmation) {
      this.setState({
        showConfirmation: true,
      });
    } else {
      onClick && onClick();
    }
  }

  onConfirm() {
    this.setState({
      showConfirmation: false,
    });
    this.props.onClick && this.props.onClick();
  }

  onCancel() {
    this.setState({
      showConfirmation: false,
    });
  }

  onChange(value) {
    this.setState({
      confirmationInputValidation: value,
    });
  }

  render() {
    const {
      text,
      wide,
      disabled,
      icon,
      type,
      theme,
      iconAfterText,
      style,
      confirmation,
      confirmationInput,
      id,
      tooltip,
      tooltipSide,
      required,
    } = this.props;
    const {showConfirmation, confirmationInputValidation} = this.state;
    let additionalProps = tooltip
      ? {
          "data-tip": true,
          "data-for": this.id,
        }
      : {};

    let buttonClasses = [
      "border-solid",
      required ? "border border-red" : "border-0.5 border-primary-bg",
      "[&>*]:text-primary",
    ];
    if (theme === Themes.Normal) {
      buttonClasses = [
        ...buttonClasses,
        "bg-primary-bg",
        "hover:bg-primary",
        "hover:border-primary",
        "[&>*]:hover:!text-white",
      ];
    }
    if (theme === Themes.Filled) {
      buttonClasses = [
        ...buttonClasses,
        "bg-primary",
        "border-primary",
        "hover:bg-primary-highlight",
        "hover:border-primary-highlight",
        "[&>*]:!text-white",
      ];
    }
    if (theme === Themes.Good) {
      buttonClasses = [...buttonClasses, "bg-good", "border-good", "hover:opacity-80", "[&>*]:!text-white"];
    }
    if (theme === Themes.Warning) {
      buttonClasses = [...buttonClasses, "bg-warning", "border-warning", "hover:opacity-80", "[&>*]:!text-white"];
    }
    if (theme === Themes.Bad) {
      buttonClasses = [...buttonClasses, "bg-bad", "border-bad", "hover:opacity-80", "[&>*]:!text-white"];
    }
    if (theme === Themes.Info) {
      buttonClasses = [...buttonClasses, "bg-info", "border-info", "hover:opacity-80", "[&>*]:!text-white"];
    }
    if (theme === Themes.Dark) {
      buttonClasses = [
        ...buttonClasses,
        "bg-[#444]",
        "border-[#444]",
        "hover:bg-[#555]",
        "hover:border-[#555]",
        "[&>*]:!text-white",
      ];
    }

    if (iconAfterText) {
      buttonClasses = [...buttonClasses, "[&>i]:!ml-2", "[&>i]:!mr-1", "[&>i]:!order-3"];
    }
    return (
      <Container required={required}>
        {required && <StyledRequiredLabel>Required</StyledRequiredLabel>}
        <StyledButton
          onClick={this.onClick}
          disabled={disabled}
          required={required}
          type={type}
          wide={wide}
          userTheme={theme}
          iconAfterText={iconAfterText}
          style={style}
          id={id}
          {...additionalProps}
          className={buttonClasses.join(" ")}
        >
          {icon && <i className="material-icons">{icon}</i>}
          {text && <span>{text}</span>}
        </StyledButton>
        {showConfirmation && (
          <Popup
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
            depth={Popup.Depth.Three}
            disableConfirmButton={
              !confirmationInput ? false : !(confirmationInput && confirmationInput === confirmationInputValidation)
            }
          >
            <div>{confirmation}</div>
            {confirmationInput && (
              <div>
                <Input
                  field="confirmation"
                  onChange={this.onChange}
                />
              </div>
            )}
          </Popup>
        )}
        {tooltip && (
          <ReactTooltip
            delayShow={500}
            id={this.id}
            place={tooltipSide}
            type="dark"
            effect="solid"
          >
            {tooltip}
          </ReactTooltip>
        )}
      </Container>
    );
  }
}

// Wrapper to handle form submission based on type
const WrappedButton = (props) => {
  const formApi = useFormApi();
  let onClick = props.onClick;
  if (props.type === "submit") {
    onClick = () => {
      props.onClick && props.onClick();
      formApi.submit();
    };
  }
  return (
    <Button
      {...props}
      formApi={formApi}
      onClick={onClick}
    />
  );
};

WrappedButton.Themes = Themes;
WrappedButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  wide: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.oneOf([
    Themes.Normal,
    Themes.Good,
    Themes.Bad,
    Themes.Warning,
    Themes.Info,
    Themes.Dark,
    Themes.Filled,
  ]),
  iconAfterText: PropTypes.bool,
  confirmation: PropTypes.any,
  tooltip: PropTypes.string,
  tooltipSide: PropTypes.string,
  required: PropTypes.bool,
};
WrappedButton.defaultProps = {
  onClick: () => {},
  wide: false,
  disabled: false,
  type: "button",
  theme: Themes.Normal,
  iconAfterText: false,
  tooltipSide: "left",
};

export default WrappedButton;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRequiredLabel = styled.div`
  display: block;
  margin: 0.75rem 0 0.5rem 0;
  font-weight: 700;
  font-size: 1.1rem;
  text-transform: uppercase;
  color: ${Colours.Red};
`;

const StyledButton = styled.button`
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  outline: none;
  text-decoration: none;
  padding: ${(props) => props.theme.forms.padding};
  border-radius: ${(props) => props.theme.forms.borderRadius};
  text-shadow: none;
  transition:
    background-color 0.15s ease,
    border-color 0.15s ease,
    opacity 0.15s ease;
  background-clip: padding-box;

  span {
    font-weight: ${(props) => props.theme.forms.buttonFontWeight};
    text-transform: uppercase;
    flex: 0 1 auto;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    order: 2;
    font-size: ${(props) => props.theme.fontSizes.tiny};
    transition: color 0.15s ease;
    display: flex;
    line-height: 1.5rem;
  }

  i {
    flex: 0 0 auto;
    margin: 0 0.56rem 0 -0.24rem;
    font-size: 1.5rem !important;
    order: 1;
    color: ${(props) => props.theme.forms.buttonColour};
    transition: color 0.15s ease;
    line-height: 1.5rem;

    &:first-child:last-child {
      margin-left: -0.24rem;
      margin-right: -0.24rem;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;
