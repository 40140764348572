import * as Enums from "constants/enums";

import {getHMIUrl, URLOptions} from "sensoteq-core/url-creator";
import React from "react";
import {Servers} from "sensoteq-react-core/constants/endpoints";
import * as envConfig from "sensoteq-react-core/constants/environment-config";

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function getAxisName(axisIndex) {
  if (axisIndex === Enums.HORIZONTAL_AXIS) return "Horizontal";
  if (axisIndex === Enums.VERTICAL_AXIS) return "Vertical";
  return "Axial";
}

export function makeCancelable(promise) {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({isCanceled: true}) : resolve(val)),
      (error) => (hasCanceled_ ? reject({isCanceled: true}) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}

export function optionalClass(p1, p2) {
  if (p1 === undefined) return "";
  if (p2 === undefined) return p1 || "";
  return p1 ? p2 : "";
}

export function capitalise(string) {
  if (!string || string.length === 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function generateId() {
  return Math.random().toString(32).slice(2);
}

export function deepGet(obj, fullPath, fallback = undefined) {
  let index = 0;
  let length = fullPath.length;
  while (obj != null && index < length) {
    const pathPart = fullPath[index];

    if (!Array.isArray(pathPart)) {
      obj = obj[pathPart];
      index += 1;
      continue;
    }

    for (const possiblePath of pathPart) {
      const possibleObject = obj[possiblePath];
      if (possibleObject != null) obj = possibleObject;
    }

    if (typeof obj === "object" && Object.keys(obj).length === 0) obj = undefined;
    index += 1;
  }
  if (index && index === length) {
    return obj != null ? obj : fallback;
  }
  return fallback;
}

export function unique(array, selectProperty) {
  if (!selectProperty) {
    return Array.from(new Set(array));
  }
  let set = new Set();
  let lookupMap = {};
  array.forEach((item) => {
    const value = selectProperty(item);
    lookupMap[value] = item;
    set.add(value);
  });
  return Array.from(set).map((x) => lookupMap[x]);
}

export function getAlarmUrl(alarm, timestamp) {
  try {
    const url = getHMIUrl(
      alarm,
      new URLOptions(timestamp, alarm.characteristics?.direction, alarm.characteristics?.resolution),
    );
    return url.split(envConfig.getKey("domain"))[1];
  } catch (error) {
    console.warn(error);
    return null;
  }
}

export function sensorNameValidator(value) {
  if (value.length != (value.indexOf("sensor_") === 0 ? 13 : 6)) {
    return "Please enter a valid sensor ID";
  }
}

export function parseCSV(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (readerEvent) => {
      // Read as string
      const content = readerEvent.target.result.trim();

      // Parse as data array
      let data = [];
      let value;
      const rows = content.split("\n");
      const titles = rows[0].split(",").map((x) => x.trim());
      const cols = titles.length;
      for (let row = 1; row < rows.length; row++) {
        value = rows[row].split(",").map((x) => x.trim());
        let datum = {};
        for (let col = 0; col < cols; col++) {
          datum[titles[col]] = parseFloat(value[col]);
        }
        data.push(datum);
      }
      resolve(data);
    };
  });
}

export function flattenChildren(node) {
  return node?.type === React.Fragment ? node.props.children : node;
}

export function getAPI() {
  const API_URL = process.env.API_URL;
  if (API_URL) return `${API_URL}/api/`;

  return Servers.Development;
}

export function transformSeries(seriesInputArray, calcFunc) {
  const output = [];

  for (let i = 0; i < seriesInputArray.length; i++) {
    const series = seriesInputArray[i];
    if (series.data == null) continue;
    const computedSeriesData = new Array(series.data.length);

    for (let j = 0; j < series.data.length; j++) {
      const data = series.data[j];
      computedSeriesData[j] = {
        value: calcFunc(data.value),
        frequency: data.frequency,
      };
    }

    output.push({
      data: computedSeriesData,
      title: series.title,
    });
  }

  return output;
}
