/*************************************
 *                                   *
 *        DATABASE PROPS/INFO        *
 *                                   *
 *************************************/

const {CompositeDateAttributes, CompositeUserTraceAttributes} = require("./db-composite-enums");

const KeyTypes = {
  STRING: "S",
  NUMBER: "N",
  BOOLEAN: "B",
};

const AlarmTableInfo = {
  ALARM_TABLE_NAME: "sensoteq-mach-alarms",
  ALARM_PRIMARY_KEY: "site_name",
  ALARM_SORT_KEY: "alarm_id",
  ALARM_USERNAMES: "usernames",
  ALARM_CREATOR: "creator",
  ALARM_IS_ACTIVE: "is_active",
  ALARM_CONFIGURED_TIME: "configured_timestamp",
  ALARM_INACTIVE_TIME: "inactive_timestamp",
  ALARM_DATA: "data",
  ALARM_SEND_EMAIL: "send_email",
  ALARM_SEND_SMS: "send_sms",
  ALARM_TARGET: "target",
  ALARM_TARGET_TYPE: "target_type",
  ALARM_TRIGGER_STRATEGY: "trigger_strategy",
};

const AlarmDataProps = {
  ALARM_TYPE: "type",
  ALARM_PROPERTY: "property",
  ALARM_VALUES: "values",
  ALARM_OPERATOR: "operator",
  ALARM_NAME: "name",
};

const AlarmReportTableInfo = {
  ALARM_REPORT_TABLE_NAME: "sensoteq-alarm-reports",
  ALARM_REPORT_PRIMARY_KEY: "target",
  ALARM_REPORT_SORT_KEY: "timestamp",
  ALARM_REPORT_COUNTS: "counts",
  ALARM_REPORT_EVENTS: "events",
  ALARM_REPORT_TARGET_TYPE: "target_type",
};

const CriticalityTableInfo = {
  CRITICALITY_TABLE_NAME: "sensoteq-criticality",
  CRITICALITY_PRIMARY_KEY: "target",
  CRITICALITY_TARGET_TYPE: "target_type",
  CRITICALITY_SORT_KEY: "timestamp",
  CRITICALITY_DATA: "data",
};

const DeviceRestartTableInfo = {
  RESTART_TABLE_NAME: "sensoteq-device-restarts",
  RESTART_PRIMARY_KEY: "device_id",
  RESTART_SORT_KEY: "timestamp",
  RESTART_REASON: "restart_reason",
};

const AlarmHistoryTableInfo = {
  ALARM_HISTORY_TABLE_NAME: "sensoteq-mach-alarm-history",
  ALARM_HISTORY_PRIMARY_KEY: "alarm_id",
  ALARM_HISTORY_SORT_KEY: "timestamp",
  ALARM_HISTORY_CONDITION: "condition",
  ALARM_HISTORY_SITE_NAME: "site_name",
  ALARM_HISTORY_TARGET: "target",
  ALARM_HISTORY_VALUE: "value",
  ALARM_HISTORY_CHARACTERISTICS: "characteristics",
};

const UserTableInfo = {
  USER_TABLE_NAME: "sensoteq-users",
  USER_PRIMARY_KEY: "username",
  USER_PASSWORD: "password",
  USER_COMPANY: "company",
  USER_EMAIL: "email",
  USER_PHONE: "phone",
  USER_IS_ADMIN: "admin",
  USER_IS_INSTALLER: "installer",
  USER_SITES: "sites",
  USER_PREFERENCES: "preferences",
  USER_DEVICES: "devices",
  USER_VIEW_ONLY: "view_only",
  USER_ALLOW_API_KEY: "allow_api_key",
  FIRST_LOGIN: "first_login",
  LOGIN_ATTEMPTS: "login_attempts",
  LOCKED: "locked",
  UNLOCK_AT: "unlock_at",
  MFA_ENABLED: "mfa_enabled",
  MFA_SECRET: "mfa_secret",
  CHI_USER: "chi_user",
  JWT_TOKEN: "jwt_token",
  PARTNER_GROUP: "partner_group",
  PARTNER_TYPE: "partner_type",
  PRIVILEGES: "privileges",
  COMPANY_MANAGER: "company_manager",
  PARTNER: "partner",
};

const PartnerTableInfo = {
  PARTNER_TABLE_NAME: "sensoteq-partner",
  PARTNER_PRIMARY_KEY: "partner_group_id",
  PARTNER_DISPLAY_NAME: "display_name",
  PARTNER_PRIMARY_COMPANY: "primary_company",
  PARTNER_COMPANIES: "companies",
  PARTNER_SITES: "sites",
};

const CompanyTableInfo = {
  COMPANY_TABLE_NAME: "sensoteq-companies",
  COMPANY_PRIMARY_KEY: "company_id",
  COMPANY_DISPLAY_NAME: "display_name",
  COMPANY_LOGO_URL: "logo_url",
  COMPANY_SITE_NAMES: "site_names",
  COMPANY_ASSET_LABELS: "asset_labels",
  COMPANY_CHI_VIEWING_ACCESS: "chi_viewing_access",
  COMPANY_CHI_VIEWING_ACCESS_HISTORY: "chi_viewing_access_history",
};

const SessionTableInfo = {
  SESSION_TABLE_NAME: "sensoteq-sessions",
  SESSION_PRIMARY_KEY: "token",
  SESSION_USERNAME: "username",
  SESSION_CREATED_AT: "created_at",
  SESSION_LAST_ACCESS: "last_access",
  SESSION_EXPIRED: "expired",
  SESSION_USER_AGENT: "user_agent",
  SESSION_CREATED_IP: "created_ip",
  SESSION_LAST_IP: "last_ip",
  SESSION_EXPIRY_REASON: "expiry_reason",
  SESSION_API_KEY_ID: "api_key_id",
  SESSION_COMPANY: "company",
  SESSION_API_NAME: "name",
};

const DataTableInfo = {
  DATA_TABLE_NAME: "sensoteq-mach-data",
  DATA_PRIMARY_KEY: "p_id",
  DATA_SORT_KEY: "timestamp",
  DATA_DATA: "data",
};

const HealthCheckTableInfo = {
  HEALTH_TABLE_NAME: "sensoteq-uptime-health-check",
  HEALTH_PRIMARY_KEY: "id",
  HEALTH_SORT_KEY: "service",
  HEALTH_STATUS: "status",
  HEALTH_TIMESTAMP_KEY: "timestamp",
};

const DataProps = {
  GATEWAY_DATA: "gateway_data",
};

const HourTableInfo = {
  HOUR_TABLE_NAME: "sensoteq-mach-hours",
  HOUR_PRIMARY_KEY: "p_id",
  HOUR_SORT_KEY: "timestamp",
  HOUR_DATA: "data",
};

const DayTableInfo = {
  DAY_TABLE_NAME: "sensoteq-mach-days",
  DAY_PRIMARY_KEY: "p_id",
  DAY_SORT_KEY: "timestamp",
  DAY_DATA: "data",
};

const MonthTableInfo = {
  MONTH_TABLE_NAME: "sensoteq-mach-months",
  MONTH_PRIMARY_KEY: "p_id",
  MONTH_SORT_KEY: "timestamp",
  MONTH_DATA: "data",
};

const TimeDomainKeyTableInfo = {
  TD_KEY_TABLE_NAME: "sensoteq-mach-time-domain-keys",
  TD_KEY_PRIMARY_KEY: "p_axis_id",
  TD_KEY_SORT_KEY: "timestamp",
  TD_KEY_POWER_OBJ: "power_data",
  TD_KEY_OTHER_CALC_OBJ: "calcs_data",
  TD_KEY_ENTRY_KEY: "entry_id",
  TD_KEY_RES_KEY: "p_res_id",
  TD_KEY_P_ID: "p_id",
  TD_KEY_COMPLETE: "complete",
  TD_KEY_DATA_RATE: "data_rate",
  TD_KEY_FIRST_BLOCK_NO: "first_block_number",
  TD_KEY_LAST_BLOCK_NO: "last_block_number",
  TD_KEY_PREV_BLOCK_NO: "prev_block_number",
  TD_KEY_MAX_BLOCKS: "max_blocks",
  TD_KEY_LAST_ACCESS: "last_access",
  TD_KEY_RMS: "rms",
  TD_KEY_RPM: "rpm",
  TD_KEY_SAMPLING_FREQ: "sampling_frequency",
  TD_KEY_ACCELEROMETER_ODR: "accelerometer_odr",
  TD_KEY_SAMPLING_DURATION: "sampling_duration",
  TD_KEY_PERCENTAGE: "percent_complete",
  TD_KEY_PACKETS_RECEIVED: "packets_received",
  TD_KEY_STARTED_AT: "started_at",
  TD_KEY_NOTCH_FILTER: "notch_filter",
  TD_KEY_GROUP_IDENTIFIER: "group_identifier", // DEPRECATED: replaced by axis_sync_id
  TD_KEY_AXIS_SYNC_ID: "axis_sync_id",
  TD_KEY_GROUP_ID: "group_id",
  TD_KEY_VERSION: "version",
};

const TimeDomainKeyMonitorTableInfo = {
  TD_MONITOR_TABLE_NAME: "sensoteq-time-domain-key-monitor",
  TD_MONITOR_PRIMARY_KEY: "td_key_id",
  TD_MONITOR_SORT_KEY: "timestamp",
  TD_MONITOR_TYPE: "type",
  TD_MONITOR_CLOSE_AT_TIMESTAMP: "close_at_timestamp",
};

const TimeDomainObjectTableInfo = {
  TD_OBJ_TABLE_NAME: "sensoteq-time-domain-objects",
  TD_OBJ_PRIMARY_KEY: "entry_id",
  TD_OBJ_DATA: "data",
};

const TimeDomainDataEntriesTableInfo = {
  TD_DATA_TABLE_NAME: "sensoteq-time-domain-data-entries",
  TD_DATA_PRIMARY_KEY: "entry_id",
  TD_DATA_SORT_KEY: "packet_index",
  TD_DATA: "data",
};

const StateEventTableInfo = {
  STATE_EVENT_TABLE_NAME: "sensoteq-state-events",
  STATE_EVENT_PRIMARY_KEY: "pk",
  STATE_EVENT_SORT_KEY: "sk",
  STATE_EVENT_STATE: "state",
  STATE_EVENT_META: "meta",
};

const MachineEventTableInfo = {
  EVENT_TABLE_NAME: "sensoteq-machine-events",
  EVENT_PRIMARY_KEY: "machine_id",
  EVENT_SORT_KEY: "timestamp",
  EVENT_ID: "event_id",
  EVENT_CREATED_BY: "created_by",
  EVENT_COMPANY: "company",
  EVENT_NAME: "name",
  EVENT_DESC: "description",
  EVENT_METADATA: "metadata",
  EVENT_TYPE: "type",
  EVENT_SITE_NAME: "site_name",
};

const MachineTableInfo = {
  MACHINE_TABLE_NAME: "sensoteq-machines-2",
  MACHINE_PRIMARY_KEY: "machine_id",
  MACHINE_SITE_NAME: "site_name",
  MACHINE_NAME: "name",
  MACHINE_TYPE_ID: "machine_type_id",
  MACHINE_SUBTYPE_ID: "machine_subtype_id",
  MACHINE_SUBTYPE_DIAGRAM_ID: "machine_subtype_diagram_id",
  MACHINE_LOCATIONS: "locations",
  MACHINE_METADATA: "metadata",
  MACHINE_PURPOSE: "purpose",
  MACHINE_INDICATOR_LEVELS: "indicator_levels",
  MACHINE_NOTES: "notes",
};

const BearingTableInfo = {
  BEARING_TABLE_NAME: "sensoteq-bearing-entries",
  BEARING_PRIMARY_KEY: "bearing_id",
  BEARING_MANUFACTURER: "manufacturer",
  BEARING_NUMBER: "number",
  BEARING_TYPE: "type",
  BEARING_BPFI: "bpfi",
  BEARING_BPFO: "bpfo",
  BEARING_BSF: "bsf",
  BEARING_FTF: "ftf",
  BEARING_CREATOR: "creator",
  BEARING_EDIT_HISTORY: "edit_history",
};

const ThermalTableInfo = {
  THERMAL_POINTS_TABLE_NAME: "sensoteq-thermal-points",
  THERMAL_POINTS_PRIMARY_KEY: "point_id",
  THERMAL_POINTS_TYPE: "type",
  THERMAL_POINTS_STREAM: "camera_stream",
  THERMAL_POINTS_CSV: "csv",
  THERMAL_POINTS_DEVICE: "device_name",
  THERMAL_POINTS_LAST_SEEN: "timestamp",
  THERMAL_POINTS_BOXES: "boxes",
  THERMAL_POINTS_BOXES_DATA: "boxes_data",
  THERMAL_MAX_TEMPERATURE: "maxTemp",
  THERMAL_MIN_TEMPERATURE: "minTemp",
  THERMAL_POSITION_MAX: "positionMax",
  THERMAL_POSITION_MIN: "positionMin",
  THERMAL_ZONE: "zone_name",
};

const MachinePointTableInfo = {
  MACHINE_POINTS_TABLE_NAME: "sensoteq-machine-points",
  MACHINE_POINTS_PRIMARY_KEY: "point_id",
  MACHINE_POINTS_MACHINE_ID: "machine_id",
  MACHINE_POINTS_SITE_NAME: "site_name",
  MACHINE_POINTS_NAME: "name",
  MACHINE_POINTS_TYPE: "type",
  MACHINE_POINTS_POSITION: "position",
  MACHINE_POINTS_STEP: "step",
  MACHINE_POINTS_DEVICE: "device_name",
  MACHINE_POINTS_SENSOR: "sensor_id",
  MACHINE_POINTS_CONFIG: "config",
  MACHINE_POINTS_LAST_SEEN: "last_seen",
  MACHINE_POINTS_MODE: "mode",
  MACHINE_POINTS_LAST_MODE: "last_mode",
  MACHINE_POINTS_METADATA: "metadata",
  MACHINE_POINTS_TESTDATA: "test_data",
  MACHINE_POINTS_CHANNELS: "channels",
  MACHINE_POINTS_NOTES: "notes",
  // config elements
  MACHINE_POINT_OLD_SENSOR: "old_sensor_id",
  MACHINE_POINT_OLD_DEVICE: "old_device_name",
  MACHINE_POINT_PURPOSE: "purpose",
};

const SensorTableInfo = {
  SENSOR_INFO_TABLE_NAME: "sensoteq-sensor-info",
  SENSOR_INFO_PRIMARY_KEY: "time_id",
  SENSOR_INFO_SORT_KEY: "count",
  SENSOR_INFO_STATE_IDX: "INDEX",
  SENSOR_INFO_HW_ID: "hw_id",
  SENSOR_INFO_ID: "sensor_id",
  SENSOR_INFO_TYPE: "type",
  SENSOR_INFO_DESC: "description",
  SENSOR_INFO_LAST_SEEN: "last_seen",
  SENSOR_INFO_CREATED_AT: "created_at",
  SENSOR_INFO_MODE: "mode",
  SENSOR_INFO_CURRENT_TYPE: "current_type",
  SENSOR_INFO_SW_VERSION: "software_sha",
  SENSOR_INFO_FW_VERSION: "firmware_version",
  SENSOR_INFO_CONFIG: "config",
  SENSOR_INFO_CONFIG_HISTORY: "config_history",
  SENSOR_INFO_LAST_RESET_REASON: "last_reset_reason",
  SENSOR_INFO_SCALE: "scale",
  SENSOR_INFO_TIME_DOMAIN_SCALE: "time_domain_scale",
  // these items should move to the measuring point in the future
  SENSOR_INFO_ORIENTATION: "orientation",
  SENSOR_INFO_RPM: "rpm",
  SENSOR_INFO_NOTCH_FILTER: "notch_filter",
  SENSOR_INFO_BANDS: "bands",
};

const TemplatingTableInfo = {
  TEMPLATING_TABLE_NAME: "sensoteq-templating",
  TEMPLATING_PRIMARY_KEY: "type",
  TEMPLATING_SORT_KEY: "template_id",
  TEMPLATING_TEMPLATE: "template",
  TEMPLATING_CREATED_BY: "created_by",
  TEMPLATING_CREATED_AT: "created_at",
  TEMPLATING_COMPANY: "company",
  TEMPLATING_NAME: "name",
  TEMPLATING_GLOBAL: "global",
  TEMPLATING_PARTNER_GROUP_ID: "partner_group_id",
};

const TemplateProps = {
  TEMPLATE_SITE_NAME: "site_name",
};

const GatewayTableInfo = {
  GATEWAY_TABLE_NAME: "sensoteq-sensors",
  GATEWAY_PRIMARY_KEY: "device_id",
  GATEWAY_FILTER_LIST: "sensors",
  GATEWAY_LAST_SEEN: "last_seen",
  GATEWAY_BUILD_INFO: "build_info",
  GATEWAY_FREQUENCY: "frequency",
  GATEWAY_HW_ID: "hw_id",
  GATEWAY_DESCRIPTION: "description",
  GATEWAY_COMPANY: "company",
  GATEWAY_MAC_ADDRESS: "mac",
  GATEWAY_TYPE: "type",
  GATEWAY_CREATED_AT: "created_at",
  GATEWAY_OWNER_ID: "owner_id",
  GATEWAY_OWNER_TYPE: "owner_type",
};

const SiteTableInfo = {
  SITE_TABLE_NAME: "sensoteq-sites",
  SITE_PRIMARY_KEY: "site_name",
  SITE_COORDINATES: "coordinates",
  SITE_ADDRESS: "address",
  SITE_DEVICES: "devices",
  SITE_LOCATIONS: "locations",
  SITE_DISPLAY_NAME: "display_name",
  SITE_MAP: "sitemap",
  SITE_NOTES: "notes",
};

const SiteImageTableInfo = {
  SITE_IMAGE_TABLE_NAME: "sensoteq-site-images",
  SITE_IMAGE_PRIMARY_KEY: "image_key",
  SITE_IMAGE_SORT_KEY: "image_id",
  SITE_IMAGE_BASE_SITE: "site_name",
  SITE_IMAGE_BASE_NAME: "image_name",
  SITE_IMAGE_MIMETYPE: "mimetype",
  SITE_IMAGE_CREATED_AT: "timestamp",
  SITE_IMAGE_WIDTH: "width",
  SITE_IMAGE_HEIGHT: "height",
};

const BTKeyTableInfo = {
  BT_DATA_KEYS_TABLE_NAME: "sensoteq-bt-data-keys",
  BT_DATA_KEYS_PRIMARY_KEY: "entry_key",
  BT_DATA_KEYS_SORT_KEY: "timestamp",
  BT_DATA_KEYS_RMS: "rms",
  BT_DATA_KEYS_SAMPLING_FREQ: "sampling_frequency",
  BT_DATA_KEYS_FFT_SIZE: "fft_size",
  BT_DATA_KEYS_SCALE: "scale",
  BT_DATA_KEYS_CREST: "crest_factor",
  BT_DATA_KEYS_OBJ_KEY: "data_id",
  BT_DATA_KEYS_POSITION: "position",
  BT_DATA_KEYS_SENSOR: "sensor_id",
};

const BTObjTableInfo = {
  BT_DATA_OBJS_TABLE_NAME: "sensoteq-bt-data-objs",
  BT_DATA_OBJS_PRIMARY_KEY: "data_id",
  BT_DATA_OBJS_DATA: "data",
};

const AlterationTableInfo = {
  ALTERATION_TABLE_NAME: "sensoteq-alterations",
  ALTERATION_PRIMARY_KEY: "alter_id",
  ALTERATION_SORT_KEY: "timestamp",
  ALTERATION_TYPE: "type",
  ALTERATION_OPERATION: "operation",
  ALTERATION_DATA: "data",
  ALTERATION_FROM_TABLE: "from_table",
  ALTERATION_FROM_PRIMARY: "primary",
  ALTERATION_FROM_SORT: "sort",
  ALTERATION_VERSION: "version",
};

const AlterationTypes = {
  MACHINE: "machine",
  POINT: "point",
  SITE: "site",
  USER: "user",
};

const AlterationVersions = {
  MACHINE: 1,
  POINT: 1,
  SITE: 1,
  USER: 1,
};

const OtherHardwareTableInfo = {
  OTHER_HW_TABLE_NAME: "sensoteq-other-hardware",
  OTHER_HW_PRIMARY_KEY: "hw_type",
  OTHER_HW_SORT_KEY: "hw_id",
  OTHER_HW_COMPANY: "company",
  OTHER_HW_LICENSE_EXPIRY: "license_expiry",
  OTHER_HW_DESCRIPTION: "description",
  OTHER_HW_LAST_SEEN: "last_seen",
};

const MiscTableInfo = {
  // used instead of 'device_id;', 'device_res_id' and 'full_entry_id'
  SORT_KEY: "timestamp",
  AXIS: "axis",
  DEVICE_KEY: "device_id",
  POINT_ID_PRIMARY_KEY: "p_id",
  RES_ID_PRIMARY_KEY: "p_res_id",
  AXIS_ID_PRIMARY_KEY: "p_axis_id",
  MACH_GATEWAY_DATA: "gateway_data",
  TARGET_PRIMARY_KEY: "target",
  IS_MOCK: "is_mock",
  MACHINE: "machine",
  POINT: "point",
};

const MachineTypesTableInfo = {
  TABLE_NAME: "sensoteq-machine-types",
  PRIMARY_KEY: "machine_type_id",
  CATEGORY: "category",
  DISPLAY_NAME: "display_name",
  MACHINE_BASE: "machine_base",
  DESCRIPTION: "description",
  IS_CORE: "is_core",
  OA_LEVELS: "oa_levels",
  COORDINATES: "coordinates",
  PARENT_ID: "parent_id",
  SPECIAL_CASE: "special_case",
  ...CompositeDateAttributes,
  ...CompositeUserTraceAttributes,
};

/**********************************
 *                                *
 *        DATABASE MAPPING        *
 *                                *
 **********************************/

const DatabaseTableInfo = {
  [AlarmTableInfo.ALARM_TABLE_NAME]: {
    primary: AlarmTableInfo.ALARM_PRIMARY_KEY,
    sort: AlarmTableInfo.ALARM_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.STRING,
    props: AlarmTableInfo,
  },
  [AlarmReportTableInfo.ALARM_REPORT_TABLE_NAME]: {
    primary: AlarmReportTableInfo.ALARM_REPORT_PRIMARY_KEY,
    sort: AlarmReportTableInfo.ALARM_REPORT_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: AlarmReportTableInfo,
  },
  [CriticalityTableInfo.CRITICALITY_TABLE_NAME]: {
    primary: CriticalityTableInfo.CRITICALITY_PRIMARY_KEY,
    sort: CriticalityTableInfo.CRITICALITY_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: CriticalityTableInfo,
  },
  [DeviceRestartTableInfo.RESTART_TABLE_NAME]: {
    primary: DeviceRestartTableInfo.RESTART_PRIMARY_KEY,
    sort: DeviceRestartTableInfo.RESTART_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: DeviceRestartTableInfo,
  },
  [AlarmHistoryTableInfo.ALARM_HISTORY_TABLE_NAME]: {
    primary: AlarmHistoryTableInfo.ALARM_HISTORY_PRIMARY_KEY,
    sort: AlarmHistoryTableInfo.ALARM_HISTORY_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: AlarmHistoryTableInfo,
  },
  [UserTableInfo.USER_TABLE_NAME]: {
    primary: UserTableInfo.USER_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: UserTableInfo,
  },
  [SessionTableInfo.SESSION_TABLE_NAME]: {
    primary: SessionTableInfo.SESSION_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: SessionTableInfo,
  },
  [DataTableInfo.DATA_TABLE_NAME]: {
    primary: DataTableInfo.DATA_PRIMARY_KEY,
    sort: DataTableInfo.DATA_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: DataTableInfo,
  },
  [HealthCheckTableInfo.HEALTH_TABLE_NAME]: {
    primary: HealthCheckTableInfo.HEALTH_PRIMARY_KEY,
    sort: HealthCheckTableInfo.HEALTH_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.STRING,
    props: HealthCheckTableInfo,
  },
  [HourTableInfo.HOUR_TABLE_NAME]: {
    primary: HourTableInfo.HOUR_PRIMARY_KEY,
    sort: HourTableInfo.HOUR_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: HourTableInfo,
  },
  [DayTableInfo.DAY_TABLE_NAME]: {
    primary: DayTableInfo.DAY_PRIMARY_KEY,
    sort: DayTableInfo.DAY_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: DayTableInfo,
  },
  [MonthTableInfo.MONTH_TABLE_NAME]: {
    primary: MonthTableInfo.MONTH_PRIMARY_KEY,
    sort: MonthTableInfo.MONTH_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: MonthTableInfo,
  },
  [TimeDomainKeyTableInfo.TD_KEY_TABLE_NAME]: {
    primary: TimeDomainKeyTableInfo.TD_KEY_PRIMARY_KEY,
    sort: TimeDomainKeyTableInfo.TD_KEY_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: TimeDomainKeyTableInfo,
  },
  [TimeDomainObjectTableInfo.TD_OBJ_TABLE_NAME]: {
    primary: TimeDomainObjectTableInfo.TD_OBJ_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: TimeDomainObjectTableInfo,
  },
  [TimeDomainDataEntriesTableInfo.TD_DATA_TABLE_NAME]: {
    primary: TimeDomainDataEntriesTableInfo.TD_DATA_PRIMARY_KEY,
    sort: TimeDomainDataEntriesTableInfo.TD_DATA_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: TimeDomainDataEntriesTableInfo,
  },
  [TimeDomainKeyMonitorTableInfo.TD_MONITOR_TABLE_NAME]: {
    primary: TimeDomainKeyMonitorTableInfo.TD_MONITOR_PRIMARY_KEY,
    sort: TimeDomainKeyMonitorTableInfo.TD_MONITOR_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: TimeDomainKeyMonitorTableInfo,
  },
  [StateEventTableInfo.STATE_EVENT_TABLE_NAME]: {
    primary: StateEventTableInfo.STATE_EVENT_PRIMARY_KEY,
    sort: StateEventTableInfo.STATE_EVENT_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: StateEventTableInfo,
  },
  [MachineEventTableInfo.EVENT_TABLE_NAME]: {
    primary: MachineEventTableInfo.EVENT_PRIMARY_KEY,
    sort: MachineEventTableInfo.EVENT_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: MachineEventTableInfo,
  },
  [MachineTableInfo.MACHINE_TABLE_NAME]: {
    primary: MachineTableInfo.MACHINE_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: MachineTableInfo,
  },
  [BearingTableInfo.BEARING_TABLE_NAME]: {
    primary: BearingTableInfo.BEARING_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: BearingTableInfo,
  },
  [MachinePointTableInfo.MACHINE_POINTS_TABLE_NAME]: {
    primary: MachinePointTableInfo.MACHINE_POINTS_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: MachinePointTableInfo,
  },
  [SensorTableInfo.SENSOR_INFO_TABLE_NAME]: {
    primary: SensorTableInfo.SENSOR_INFO_PRIMARY_KEY,
    sort: SensorTableInfo.SENSOR_INFO_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: SensorTableInfo,
  },
  [TemplatingTableInfo.TEMPLATING_TABLE_NAME]: {
    primary: TemplatingTableInfo.TEMPLATING_PRIMARY_KEY,
    sort: TemplatingTableInfo.TEMPLATING_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.STRING,
    props: TemplatingTableInfo,
  },
  [GatewayTableInfo.GATEWAY_TABLE_NAME]: {
    primary: GatewayTableInfo.GATEWAY_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: GatewayTableInfo,
  },
  [SiteTableInfo.SITE_TABLE_NAME]: {
    primary: SiteTableInfo.SITE_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: SiteTableInfo,
  },
  [SiteImageTableInfo.SITE_IMAGE_TABLE_NAME]: {
    primary: SiteImageTableInfo.SITE_IMAGE_PRIMARY_KEY,
    sort: SiteImageTableInfo.SITE_IMAGE_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.STRING,
    props: SiteImageTableInfo,
  },
  [BTKeyTableInfo.BT_DATA_KEYS_TABLE_NAME]: {
    primary: BTKeyTableInfo.BT_DATA_KEYS_PRIMARY_KEY,
    sort: BTKeyTableInfo.BT_DATA_KEYS_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: BTKeyTableInfo,
  },
  [BTObjTableInfo.BT_DATA_OBJS_TABLE_NAME]: {
    primary: BTObjTableInfo.BT_DATA_OBJS_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: BTObjTableInfo,
  },
  [AlterationTableInfo.ALTERATION_TABLE_NAME]: {
    primary: AlterationTableInfo.ALTERATION_PRIMARY_KEY,
    sort: AlterationTableInfo.ALTERATION_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.NUMBER,
    props: AlterationTableInfo,
  },
  [CompanyTableInfo.COMPANY_TABLE_NAME]: {
    primary: CompanyTableInfo.COMPANY_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: CompanyTableInfo,
  },
  [PartnerTableInfo.PARTNER_TABLE_NAME]: {
    primary: PartnerTableInfo.PARTNER_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: PartnerTableInfo,
  },
  [OtherHardwareTableInfo.OTHER_HW_TABLE_NAME]: {
    primary: OtherHardwareTableInfo.OTHER_HW_PRIMARY_KEY,
    sort: OtherHardwareTableInfo.OTHER_HW_SORT_KEY,
    primaryType: KeyTypes.STRING,
    sortType: KeyTypes.STRING,
    props: OtherHardwareTableInfo,
  },
  [ThermalTableInfo.THERMAL_POINTS_TABLE_NAME]: {
    primary: ThermalTableInfo.THERMAL_POINTS_PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: ThermalTableInfo,
  },
  [MachineTypesTableInfo.TABLE_NAME]: {
    primary: MachineTypesTableInfo.PRIMARY_KEY,
    primaryType: KeyTypes.STRING,
    props: MachineTypesTableInfo,
  },
};

/**********************************
 *                                *
 *        DATABASE INDEXES        *
 *                                *
 **********************************/

const DatabaseIndexes = {
  ALARM_ALARM_ID: "alarm_id-index",
  ALARM_HIST_SITE_TIME: "site_name-timestamp-index",

  EVENT_ID_INDEX: "event_id-index",
  EVENT_COMPANY_TIME_INDEX: "company-timestamp-index",
  EVENT_GSI1_INDEX: "gsi1pk-gsi1sk-index",
  SITE_ONLY_IMAGE_INDEX: "site_name-index",
  EMAIL_INDEX: "email-index",
  COMPANY_INDEX: "company-index",
  PARTNER_GROUP_INDEX: "partner_group-index",
  OLD_MACHINE_ID_INDEX: "machine_id-index",
  MACH_SITE_INDEX: "site_name-index",

  MACH_POINT_SITE_INDEX: "site_name-index",
  MACH_POINT_DEVICE_INDEX: "device_name-sensor_id-index",
  MACH_POINT_MACHINE_INDEX: "machine_id-index",

  SESSION_EXPIRED_USER_INDEX: "expired-username-index",
  SESSION_USER_ACCESS_INDEX: "username-last_access-index",
  SESSION_EXPIRED_ACCESS_INDEX: "expired-last_access-index",
  SESSION_COMPANY_EXPIRED_INDEX: "company-expired-index",

  TD_KEY_PID_INDEX: "p_id-timestamp-index",
  TD_KEY_PRESID_INDEX: "p_res_id-timestamp-index",
  ALARM_SITE_TARGET_INDEX: "site_name-target-index",
  ALARM_TARGET_IS_ACTIVE_INDEX: "target-is_active-index",
  ALARM_HIST_TARGET_TIME_INDEX: "target-timestamp-index",
  ALARM_SITE_IS_ACTIVE_INDEX: "site_name-is_active-index",

  ALTERATION_TYPE_TIME_INDEX: "type-timestamp-index",

  SENSOR_CREATED_AT_INDEX: "INDEX-created_at-index",

  GATEWAY_TYPE_CREATED_AT_INDEX: "type-created_at-index",
  GATEWAY_LOOKUP_BY_HW_ID_INDEX: "hw_id-index",
  GATEWAY_OWNER_ID_INDEX: "owner_id-index",

  TEMPLATING_COMPANY_INDEX: "type-company-index",
  TEMPLATING_GLOBAL_INDEX: "global-index",
  TEMPLATING_GLOBAL_PARTNER_GROUP_INDEX: "type-partner_group_id-index",

  TD_KEY_MONITOR_CLOSE_INDEX: "type-close_at_timestamp-index",

  OTHER_HW_TYPE_COMPANY_INDEX: "type-company-index",

  BEARING_NUMBER_INDEX: "number-exists-index",

  MACHINE_TYPE_CATEGORY_INDEX: "machine-type-category-index",
};

// contains a list of tables, each table is an array of objects, each a definition of an index for that table
const DatabaseIndexInfo = {
  [TimeDomainKeyTableInfo.TD_KEY_TABLE_NAME]: [
    {
      name: DatabaseIndexes.TD_KEY_PID_INDEX,
      keys: [TimeDomainKeyTableInfo.TD_KEY_P_ID, TimeDomainKeyTableInfo.TD_KEY_SORT_KEY],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
    {
      name: DatabaseIndexes.TD_KEY_PRESID_INDEX,
      keys: [TimeDomainKeyTableInfo.TD_KEY_RES_KEY, TimeDomainKeyTableInfo.TD_KEY_SORT_KEY],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
  ],
  [AlarmTableInfo.ALARM_TABLE_NAME]: [
    {
      name: DatabaseIndexes.ALARM_ALARM_ID,
      keys: [AlarmTableInfo.ALARM_SORT_KEY],
      types: [KeyTypes.STRING],
    },
    {
      name: DatabaseIndexes.ALARM_TARGET_IS_ACTIVE_INDEX,
      keys: [AlarmTableInfo.ALARM_TARGET, AlarmTableInfo.ALARM_IS_ACTIVE],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
    {
      name: DatabaseIndexes.ALARM_SITE_TARGET_INDEX,
      keys: [AlarmTableInfo.ALARM_PRIMARY_KEY, AlarmTableInfo.ALARM_TARGET],
      types: [KeyTypes.STRING, KeyTypes.STRING],
      isLocal: true,
    },
    {
      name: DatabaseIndexes.ALARM_SITE_IS_ACTIVE_INDEX,
      keys: [AlarmTableInfo.ALARM_PRIMARY_KEY, AlarmTableInfo.ALARM_IS_ACTIVE],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
  ],
  [AlarmHistoryTableInfo.ALARM_HISTORY_TABLE_NAME]: [
    {
      name: DatabaseIndexes.ALARM_HIST_TARGET_TIME_INDEX,
      keys: [AlarmHistoryTableInfo.ALARM_HISTORY_TARGET, AlarmHistoryTableInfo.ALARM_HISTORY_SORT_KEY],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
    {
      name: DatabaseIndexes.ALARM_HIST_SITE_TIME,
      keys: [AlarmHistoryTableInfo.ALARM_HISTORY_SITE_NAME, AlarmHistoryTableInfo.ALARM_HISTORY_SORT_KEY],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
  ],
  [MachineEventTableInfo.EVENT_TABLE_NAME]: [
    {
      name: DatabaseIndexes.EVENT_ID_INDEX,
      keys: [MachineEventTableInfo.EVENT_ID],
      types: [KeyTypes.STRING],
    },
    {
      name: DatabaseIndexes.EVENT_COMPANY_TIME_INDEX,
      keys: [MachineEventTableInfo.EVENT_COMPANY, MachineEventTableInfo.EVENT_SORT_KEY],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
    {
      name: DatabaseIndexes.EVENT_GSI1_INDEX,
      keys: ["gsi1pk", "gsi1sk"],
      types: [KeyTypes.STRING, KeyTypes.STRING],
    },
  ],
  [SiteImageTableInfo.SITE_IMAGE_TABLE_NAME]: [
    {
      name: DatabaseIndexes.SITE_ONLY_IMAGE_INDEX,
      keys: [SiteImageTableInfo.SITE_IMAGE_BASE_SITE],
      types: [KeyTypes.STRING],
    },
  ],
  [UserTableInfo.USER_TABLE_NAME]: [
    {
      name: DatabaseIndexes.EMAIL_INDEX,
      keys: [UserTableInfo.USER_EMAIL],
      types: [KeyTypes.STRING],
    },
    {
      name: DatabaseIndexes.COMPANY_INDEX,
      keys: [UserTableInfo.USER_COMPANY],
      types: [KeyTypes.STRING],
    },
    {
      name: DatabaseIndexes.PARTNER_GROUP_INDEX,
      keys: [UserTableInfo.PARTNER_GROUP],
      types: [KeyTypes.STRING],
    },
  ],
  [MachineTableInfo.MACHINE_TABLE_NAME]: [
    {
      name: DatabaseIndexes.MACH_SITE_INDEX,
      keys: [MachineTableInfo.MACHINE_SITE_NAME],
      types: [KeyTypes.STRING],
    },
  ],
  [MachinePointTableInfo.MACHINE_POINTS_TABLE_NAME]: [
    {
      name: DatabaseIndexes.MACH_POINT_SITE_INDEX,
      keys: [MachinePointTableInfo.MACHINE_POINTS_SITE_NAME],
      types: [KeyTypes.STRING],
    },
    {
      name: DatabaseIndexes.MACH_POINT_DEVICE_INDEX,
      keys: [MachinePointTableInfo.MACHINE_POINTS_DEVICE, MachinePointTableInfo.MACHINE_POINTS_SENSOR],
      types: [KeyTypes.STRING, KeyTypes.STRING],
    },
    {
      name: DatabaseIndexes.MACH_POINT_MACHINE_INDEX,
      keys: [MachinePointTableInfo.MACHINE_POINTS_MACHINE_ID],
      types: [KeyTypes.STRING],
    },
  ],
  [SessionTableInfo.SESSION_TABLE_NAME]: [
    {
      name: DatabaseIndexes.SESSION_EXPIRED_USER_INDEX,
      keys: [SessionTableInfo.SESSION_EXPIRED, SessionTableInfo.SESSION_USERNAME],
      types: [KeyTypes.NUMBER, KeyTypes.STRING],
    },
    {
      name: DatabaseIndexes.SESSION_USER_ACCESS_INDEX,
      keys: [SessionTableInfo.SESSION_USERNAME, SessionTableInfo.SESSION_LAST_ACCESS],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
    {
      name: DatabaseIndexes.SESSION_EXPIRED_ACCESS_INDEX,
      keys: [SessionTableInfo.SESSION_EXPIRED, SessionTableInfo.SESSION_LAST_ACCESS],
      types: [KeyTypes.NUMBER, KeyTypes.NUMBER],
    },
    {
      name: DatabaseIndexes.SESSION_COMPANY_EXPIRED_INDEX,
      keys: [SessionTableInfo.SESSION_COMPANY, SessionTableInfo.SESSION_EXPIRED],
      types: [KeyTypes.STRING, KeyTypes.STRING],
    },
  ],
  [AlterationTableInfo.ALTERATION_TABLE_NAME]: [
    {
      name: DatabaseIndexes.ALTERATION_TYPE_TIME_INDEX,
      keys: [AlterationTableInfo.ALTERATION_TYPE, AlterationTableInfo.ALTERATION_SORT_KEY],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
  ],
  [SensorTableInfo.SENSOR_INFO_TABLE_NAME]: [
    {
      name: DatabaseIndexes.SENSOR_CREATED_AT_INDEX,
      keys: [SensorTableInfo.SENSOR_INFO_STATE_IDX, SensorTableInfo.SENSOR_INFO_CREATED_AT],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
  ],
  [GatewayTableInfo.GATEWAY_TABLE_NAME]: [
    {
      name: DatabaseIndexes.GATEWAY_TYPE_CREATED_AT_INDEX,
      keys: [GatewayTableInfo.GATEWAY_TYPE, GatewayTableInfo.GATEWAY_CREATED_AT],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
    {
      name: DatabaseIndexes.GATEWAY_LOOKUP_BY_HW_ID_INDEX,
      keys: [GatewayTableInfo.GATEWAY_HW_ID],
      types: [KeyTypes.STRING],
    },
    {
      name: DatabaseIndexes.GATEWAY_OWNER_ID_INDEX,
      keys: [GatewayTableInfo.GATEWAY_OWNER_ID],
      types: [KeyTypes.STRING],
    },
  ],
  [TemplatingTableInfo.TEMPLATING_TABLE_NAME]: [
    {
      name: DatabaseIndexes.TEMPLATING_COMPANY_INDEX,
      keys: [TemplatingTableInfo.TEMPLATING_PRIMARY_KEY, TemplatingTableInfo.TEMPLATING_COMPANY],
      types: [KeyTypes.STRING, KeyTypes.STRING],
      isLocal: true,
    },
    {
      name: DatabaseIndexes.TEMPLATING_GLOBAL_INDEX,
      keys: [TemplatingTableInfo.TEMPLATING_GLOBAL],
      types: [KeyTypes.NUMBER],
    },
    {
      name: DatabaseIndexes.TEMPLATING_GLOBAL_PARTNER_GROUP_INDEX,
      keys: [TemplatingTableInfo.TEMPLATING_PRIMARY_KEY, TemplatingTableInfo.TEMPLATING_PARTNER_GROUP_ID],
      types: [KeyTypes.STRING, KeyTypes.STRING],
    },
  ],
  [OtherHardwareTableInfo.OTHER_HW_TABLE_NAME]: [
    {
      name: DatabaseIndexes.OTHER_HW_TYPE_COMPANY_INDEX,
      keys: [OtherHardwareTableInfo.OTHER_HW_PRIMARY_KEY, OtherHardwareTableInfo.OTHER_HW_COMPANY],
      types: [KeyTypes.STRING, KeyTypes.STRING],
      isLocal: true,
    },
  ],
  [BearingTableInfo.BEARING_TABLE_NAME]: [
    {
      name: DatabaseIndexes.BEARING_NUMBER_INDEX,
      keys: [BearingTableInfo.BEARING_NUMBER],
      types: [KeyTypes.STRING],
    },
  ],
  [TimeDomainKeyMonitorTableInfo.TD_MONITOR_TABLE_NAME]: [
    {
      name: DatabaseIndexes.TD_KEY_MONITOR_CLOSE_INDEX,
      keys: [
        TimeDomainKeyMonitorTableInfo.TD_MONITOR_TYPE,
        TimeDomainKeyMonitorTableInfo.TD_MONITOR_CLOSE_AT_TIMESTAMP,
      ],
      types: [KeyTypes.STRING, KeyTypes.NUMBER],
    },
  ],
  [MachineTypesTableInfo.TABLE_NAME]: [
    {
      name: DatabaseIndexes.MACHINE_TYPE_CATEGORY_INDEX,
      keys: [MachineTypesTableInfo.CATEGORY],
      types: [KeyTypes.STRING],
    },
  ],
};

// this is used when a table has an index element, this will be removed by the low level but can be used for creating
// specific multi-shard indexes (like latest)
const IndexValues = {
  CREATED_INDEX: "CREATED",
};

/****************************
 *                          *
 *        DEPRECATED        *
 *                          *
 ****************************/

let merge = (...args) => {
  let final = {};
  // an array was input rather than a set of parameters
  if (args.length === 1 && args[0] instanceof Array) {
    args = args[0];
  }
  args.forEach((arg) => {
    if (typeof arg === "object") {
      final = {...final, ...arg};
    }
  });
  return final;
};
// DEPRECATED, do not use
function initDBInfo() {
  let props = [];
  for (let tableName in DatabaseTableInfo) {
    if (!DatabaseTableInfo.hasOwnProperty(tableName)) {
      continue;
    }
    props.push(DatabaseTableInfo[tableName].props);
  }
  // misc isn't a table info
  props.push(MiscTableInfo);
  return merge(props);
}
let DatabaseInfo = initDBInfo();

// DEPRECATED, do not use
const MachTimeType = {
  RAW: {name: "raw", tableName: DataTableInfo.DATA_TABLE_NAME},
  HOUR: {name: "hour", tableName: HourTableInfo.HOUR_TABLE_NAME},
  DAY: {name: "day", tableName: DayTableInfo.DAY_TABLE_NAME},
  MONTH: {name: "month", tableName: MonthTableInfo.MONTH_TABLE_NAME},
};

// DEPRECATED, do not use
const OldTableInfo = {
  OLD_TD_PRIMARY_KEY: "full_entry_id",
  OLD_POWER_TABLE_NAME: "sensoteq-power",
  OLD_EVENT_TABLE_NAME: "sensote-events",
  OLD_OTHER_CALCS_TABLE_NAME: "sensoteq-time-domain-calcs",
  OLD_TD_KEYS_TABLE_NAME: "sensoteq-time-domain-keys",
  OLD_ALARM_TABLE_NAME: "sensoteq-alarms",
  OLD_ALARM_HIST_TABLE_NAME: "sensoteq-alarm-history-2",
  OLD_DATA_TABLE_NAME: "sensoteq-data-2",
  OLD_HOURS_TABLE_NAME: "sensoteq-hours",
  OLD_DAYS_TABLE_NAME: "sensoteq-days",
  OLD_MONTHS_TABLE_NAME: "sensoteq-months",

  SENSOR_CONVERT_TABLE_NAME: "sensoteq-conversion",
  SENSOR_CONVERT_NEW_ID: "new_id",
  SENSOR_CONVERT_OLD_ID: "old_id",
};

module.exports.DatabaseKeyWords = {
  TESTCASE: "testcase",
};
module.exports.DatabaseInfo = DatabaseInfo;
module.exports.DatabaseIndexes = DatabaseIndexes;
module.exports.DatabaseIndexInfo = DatabaseIndexInfo;
module.exports.IndexValues = IndexValues;
module.exports.MachTimeType = MachTimeType;
module.exports.AlarmTableInfo = AlarmTableInfo;
module.exports.AlarmDataProps = AlarmDataProps;
module.exports.AlarmReportTableInfo = AlarmReportTableInfo;
module.exports.CriticalityTableInfo = CriticalityTableInfo;
module.exports.DeviceRestartTableInfo = DeviceRestartTableInfo;
module.exports.AlarmHistoryTableInfo = AlarmHistoryTableInfo;
module.exports.UserTableInfo = UserTableInfo;
module.exports.CompanyTableInfo = CompanyTableInfo;
module.exports.PartnerTableInfo = PartnerTableInfo;
module.exports.SessionTableInfo = SessionTableInfo;
module.exports.DataTableInfo = DataTableInfo;
module.exports.HealthCheckTableInfo = HealthCheckTableInfo;
module.exports.DataProps = DataProps;
module.exports.HourTableInfo = HourTableInfo;
module.exports.DayTableInfo = DayTableInfo;
module.exports.MonthTableInfo = MonthTableInfo;
module.exports.TimeDomainKeyTableInfo = TimeDomainKeyTableInfo;
module.exports.TimeDomainObjectTableInfo = TimeDomainObjectTableInfo;
module.exports.TimeDomainKeyMonitorTableInfo = TimeDomainKeyMonitorTableInfo;
module.exports.TimeDomainDataEntriesTableInfo = TimeDomainDataEntriesTableInfo;
module.exports.StateEventTableInfo = StateEventTableInfo;
module.exports.MachineEventTableInfo = MachineEventTableInfo;
module.exports.MachineTableInfo = MachineTableInfo;
module.exports.BearingTableInfo = BearingTableInfo;
module.exports.MachinePointTableInfo = MachinePointTableInfo;
module.exports.SensorTableInfo = SensorTableInfo;
module.exports.TemplatingTableInfo = TemplatingTableInfo;
module.exports.TemplateProps = TemplateProps;
module.exports.GatewayTableInfo = GatewayTableInfo;
module.exports.SiteTableInfo = SiteTableInfo;
module.exports.SiteImageTableInfo = SiteImageTableInfo;
module.exports.BTKeyTableInfo = BTKeyTableInfo;
module.exports.BTObjTableInfo = BTObjTableInfo;
module.exports.AlterationTableInfo = AlterationTableInfo;
module.exports.AlterationTypes = AlterationTypes;
module.exports.MiscTableInfo = MiscTableInfo;
module.exports.OtherHardwareTableInfo = OtherHardwareTableInfo;
module.exports.KeyTypes = KeyTypes;
module.exports.OldTableInfo = OldTableInfo;
module.exports.ThermalTableInfo = ThermalTableInfo;
module.exports.MachineTypesTableInfo = MachineTypesTableInfo;

module.exports.findAlterationType = (tableName) => {
  switch (tableName) {
    case MachineTableInfo.MACHINE_TABLE_NAME:
      return AlterationTypes.MACHINE;
    case MachinePointTableInfo.MACHINE_POINTS_TABLE_NAME:
      return AlterationTypes.POINT;
    case SiteTableInfo.SITE_TABLE_NAME:
      return AlterationTypes.SITE;
    case UserTableInfo.USER_TABLE_NAME:
      return AlterationTypes.USER;
    default:
      throw {error: "Unable to define alteration type."};
  }
};

module.exports.findAlterationVersion = (tableName) => {
  switch (tableName) {
    case MachineTableInfo.MACHINE_TABLE_NAME:
      return AlterationVersions.MACHINE;
    case MachinePointTableInfo.MACHINE_POINTS_TABLE_NAME:
      return AlterationVersions.POINT;
    case SiteTableInfo.SITE_TABLE_NAME:
      return AlterationVersions.SITE;
    case UserTableInfo.USER_TABLE_NAME:
      return AlterationVersions.USER;
    default:
      throw {error: "Unable to define alteration version."};
  }
};

module.exports.getTableInfo = (tableName) => {
  return DatabaseTableInfo[tableName];
};

module.exports.getTableIndexes = (tableName) => {
  return DatabaseIndexInfo[tableName];
};

module.exports.getTableIndexName = (tableName, hash, sort = null) => {
  let tableIndexes = DatabaseIndexInfo[tableName];
  if (tableIndexes == null || tableIndexes.length === 0) {
    return null;
  }
  // if an index has a sort but it hasn't been supplied here the first index which has the primary will be returned
  for (let index of tableIndexes) {
    if (index.keys[0] === hash && (sort == null || index.keys[1] === sort)) {
      return index.name;
    }
  }
  return null;
};
