import {observable, flow, computed} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class GatewayDiagnosticsSubscription extends DataSubscription {
  config = {
    ...super.config,
    autoRefresh: false,
  };
  getDefaultParams() {
    return {
      gatewayName: null,
    };
  }

  @observable.ref _diagnostics;

  getData = flow(function* ({gatewayName}) {
    if (!gatewayName) {
      return;
    }
    this.startLoading();
    try {
      this._diagnostics = yield Api.getDeviceDiagnostics(gatewayName);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting diagnostics: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get gatewayDiagnostics() {
    if (!this._diagnostics) {
      return null;
    }
    return this._diagnostics.rx_diagnostics;
  }

  @computed get sensorDiagnostics() {
    if (!this._diagnostics) {
      return null;
    }
    return this._diagnostics.tx_diagnostics.map((sensorInfo) => {
      const measuringPoint = this.rootStore.pointStore.getTestPointBySensorID(sensorInfo.sensor_id);
      const machine = measuringPoint ? this.rootStore.machineStore.getMachine(measuringPoint.machine_id) : null;
      return {
        ...sensorInfo,
        allocation: machine ? `${machine.label} - ${measuringPoint.label}` : "Unallocated",
      };
    });
  }
}
