import {
  AlarmTypeOptions,
  AlarmPropertyOptions,
  AlarmOperatorOptions,
  AlarmTargetTypeOptions,
  MeasuringPointPositionOptions,
  MeasuringPointStepOptions,
  MeasuringPointIntermediaryPositionOptions,
  MeasuringPointScreenPositionOptions,
  DomainOptions,
  AxisOptions,
  ResolutionOptions,
  ServiceAppModeOptions,
  SensorTypeOptions,
} from "./options";

// Utility to make a lookup map
export const makeLookupMap = (options) => {
  let map = {};
  options.forEach((entry) => {
    map[entry.value] = entry.label;
  });
  return map;
};

export const ResolutionLookupMap = makeLookupMap(ResolutionOptions);
export const AxisLookupMap = makeLookupMap(AxisOptions);
export const DomainLookupMap = makeLookupMap(DomainOptions);
export const AlarmTypeLookupMap = makeLookupMap(AlarmTypeOptions);
export const AlarmPropertyLookupMap = makeLookupMap(AlarmPropertyOptions);
export const AlarmOperatorLookupMap = makeLookupMap(AlarmOperatorOptions);
export const AlarmTargetTypeLookupMap = makeLookupMap(AlarmTargetTypeOptions);
export const MeasuringPointStepLookupMap = makeLookupMap(MeasuringPointStepOptions);
export const ServiceAppModeLookupMap = makeLookupMap(ServiceAppModeOptions);
export const SensorTypeLookupMap = makeLookupMap(SensorTypeOptions);
export const MeasuringPointPositionLookupMap = {
  ...makeLookupMap(MeasuringPointPositionOptions),
  ...makeLookupMap(MeasuringPointIntermediaryPositionOptions),
  ...makeLookupMap(MeasuringPointScreenPositionOptions),
};
