import {flow} from "mobx";
import Api from "sensoteq-react-core/services/api";
import * as Enums from "constants/enums";
import TimeDomainKeySubscription from "models/TimeDomainKeySubscription";

export default class TimePlotDomainKeySubscription extends TimeDomainKeySubscription {
  getData = flow(function* ({pointId, from, to, resolution, timeOption, axis}) {
    if (!pointId) {
      return;
    }
    this.startLoading();
    try {
      if (timeOption === Enums.TIME_OPTION_LATEST) {
        const data = yield Api.getLatestTimeDomainKey(pointId, resolution, axis);
        const latestKey = data.latest.current;
        if (latestKey) {
          const data = yield Api.getTimeDomainKeyList(
            pointId,
            latestKey.timestamp,
            latestKey.timestamp + 1000,
            resolution,
            axis,
          );
          this._data = data.keys;
        } else {
          this._data = [];
        }
        this.nextTimestamp = data.latest.next?.expected_completion;
      } else {
        const data = yield Api.getTimeDomainKeyList(pointId, from.valueOf(), to.valueOf(), resolution, axis);
        this._data = data.keys;
        this.nextTimestamp = undefined;
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting time domain key data: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
