import LoadingAnimation from "sensoteq-react-core/components/LoadingAnimation";
import * as Styled from "./PageContent.styles";
import type {PropsWithChildren} from "react";

type Props = PropsWithChildren<{
  loading?: boolean;
  loadingOverlay?: boolean;
  padded?: boolean;
}>;
const PageContent = ({children, loading = false, loadingOverlay = false, padded = true}: Props) => {
  return (
    <Styled.Container padded={padded}>
      {loading ? <LoadingAnimation showBackground={false} /> : children}
      {loadingOverlay && <LoadingAnimation />}
    </Styled.Container>
  );
};

export default PageContent;
