import {observable, action, computed, reaction} from "mobx";

import {setApiServer} from "sensoteq-react-core/constants/endpoints";
import {setReactQueryAPIServer} from "services/ReactQuery";
import {getAPI} from "services/Utils";
import {configureFetch} from "../data/fetch";

export default class LocalStore {
  rootStore;
  @observable hash;
  @observable preferences = {};

  constructor(rootStore) {
    this.rootStore = rootStore;

    // Keep API in line with server setting
    rootStore.disposeWithRootStore(
      reaction(
        () => this.server,
        (server) => {
          setApiServer(server);
          setReactQueryAPIServer(server);
          configureFetch({baseURL: server});
        },
        {
          fireImmediately: true,
        },
      ),
    );
  }

  @computed get server() {
    if (this.preferences.server) {
      return this.preferences.server;
    }

    return getAPI();
  }

  @action setHash(hash) {
    this.hash = hash;
  }

  @action setServer(server) {
    this.preferences = {
      ...this.preferences,
      server,
    };
    setTimeout(() => {
      this.rootStore.userStore.logOut(`Server URL changed to ${server}`);
    }, 250);
  }
}
