import type {FetchOptions} from "ofetch";
import {ofetch} from "ofetch";
import {apiAuthToken, apiServer} from "services/ReactQuery";

export function withBearer(token?: string, headers?: Record<string, string>) {
  const header = token ? `Bearer ${token}` : `Bearer ${apiAuthToken}`;
  return {
    ...headers,
    Authorization: header,
  };
}

let config: FetchOptions = {
  retry: false,
  baseURL: apiServer,
  timeout: 15000,
};
export let f = ofetch.create(config);
export function configureFetch(opts: FetchOptions) {
  config = {...config, ...opts};
  f = ofetch.create(config);
}
