let enums = require("../../sensoteq-core/enumerations");
let {AlarmTableInfo, AlarmDataProps} = require("../../sensoteq-core/cloud/db-enums");
let utils = require("../../sensoteq-core/utils");
let alarmProps = require("../../sensoteq-core/alarm-properties");
const {deriveDirection} = require("../../core/src/orientation");

const TRUE_TYPE_MAP = {
  [enums.AlarmTypes.ONLINE_STATUS]: "status",
  [enums.AlarmTypes.CRITICAL]: "critical",
  [enums.AlarmTypes.WARNING]: "warning",
};
const TRUE_OP_MAP = {
  [enums.AlarmOperators.GREATER_THAN]: "greater than",
  [enums.AlarmOperators.LESS_THAN]: "less than",
  [enums.AlarmOperators.EQUAL]: "equal to",
  [enums.AlarmOperators.BETWEEN]: "inside range",
  [enums.AlarmOperators.OUTSIDE]: "outside range",
};
const TRUE_DEFAULT_OP = "operator invalid";
const TRUE_DEFAULT_TYPE = "standard";

module.exports.getTrueAlarmType = (alarmType) => {
  let trueType = TRUE_TYPE_MAP[alarmType];
  if (trueType == null) {
    return TRUE_DEFAULT_TYPE;
  }
  return trueType;
};

module.exports.getOpValString = (alarm) => {
  // check for vibration alarms, no operator
  if (enums.checkAlarmInGroup(alarm, enums.AlarmGroups.VIB_POWER)) {
    return "exceeded band thresholds";
  }

  let operator = alarm[AlarmTableInfo.ALARM_DATA][enums.PresentableDataTypes.ALARM_OPERATOR];
  let values = alarm[AlarmTableInfo.ALARM_DATA][enums.PresentableDataTypes.ALARM_VALUES];
  let greater = null,
    lesser = null;

  if (values.length > 1) {
    greater = values[0] > values[1] ? values[0] : values[1];
    lesser = values[0] < values[1] ? values[0] : values[1];
  }
  let baseOp = TRUE_OP_MAP[operator];
  if (baseOp == null) {
    return TRUE_DEFAULT_OP;
  }

  // generate the final string from the base plus the value of alarm
  switch (operator) {
    case enums.AlarmOperators.BETWEEN:
    case enums.AlarmOperators.OUTSIDE:
      return `${baseOp} ${utils.roundToDecimal(lesser, 3)} to ${utils.roundToDecimal(greater, 3)}`;
    default:
      return `${baseOp} ${utils.roundToDecimal(values[0], 3)}`;
  }
};

module.exports.getCustomValueString = (alarm, valuesAbove) => {
  let alarmProperty = alarm[AlarmTableInfo.ALARM_DATA][enums.PresentableDataTypes.ALARM_PROPERTY];
  let propertyInfo = alarmProps.getPropertyInfo(alarm);
  let valStr = valuesAbove instanceof Array ? valuesAbove.join(", ") : valuesAbove;
  let key =
    alarmProperty == null
      ? "New state: "
      : enums.checkAlarmInGroup(alarm, enums.AlarmGroups.VIB_POWER)
        ? "Vibration power bands breached: "
        : `${utils.capitaliseSentence(propertyInfo.name)} received: `;
  return {key, value: valStr};
};

module.exports.hasVibrationDirection = (alarm) => {
  let target = alarm[AlarmTableInfo.ALARM_TARGET];
  if (target === undefined) return false;

  let propertyInfo = alarmProps.getPropertyInfo(alarm);
  if (propertyInfo.name == null) return false;

  // if it is not a gateway name then site, machines, point IDs all of them are allowed
  return !utils.isGatewayName(target)
};

module.exports.getExactConditionString = async (alarm, direction) => {
  let propertyInfo = alarmProps.getPropertyInfo(alarm);
  if (propertyInfo.name == null) {
    return "Alarm has fired!";
  }

  let operator = module.exports.getOpValString(alarm);
  let finalString = direction != null ? utils.capitaliseSentence(direction) + " " : "";
  finalString += propertyInfo.name + " " + operator;

  return finalString;
};

// async incase anything needs to be retrieved from cache
module.exports.getAlarmSummaryString = async (alarm, direction) => {
  let alarmType = alarm[AlarmTableInfo.ALARM_DATA][enums.PresentableDataTypes.ALARM_TYPE];
  let trueType = utils.capitaliseSentence(module.exports.getTrueAlarmType(alarmType));

  if (alarmType === enums.AlarmTypes.ONLINE_STATUS) {
    return trueType + " - state of connection has changed";
  }

  // format: ${type} - ${direction if applicable} - ${property}
  // get the condition string, this is async as well due to it possibly needing info about the sensor
  return (await trueType) + " - " + (await module.exports.getExactConditionString(alarm, direction));
};

// orientation is needed to conver to the direction
module.exports.revertAlarmSummaryString = (summary, orientation, sensorType) => {
  // initially lowercase the whole string, easier searching
  summary = summary.toLowerCase();
  let response = {};
  // always should have a type
  let typeStrings = utils.mapToArray(TRUE_TYPE_MAP);
  let opStrings = utils.mapToArray(enums.AlarmOperators);
  let propMap = alarmProps.getPropMap();
  // find the type
  for (let type of typeStrings) {
    if (summary.indexOf(type) !== -1) {
      response[AlarmDataProps.ALARM_TYPE] = type;
      break;
    }
  }
  // find the operator
  for (let op of opStrings) {
    if (summary.indexOf(op) !== -1) {
      response[AlarmDataProps.ALARM_OPERATOR] = op;
      break;
    }
  }
  // find the property, its components are direction (to axis, based on orientation) resolution and property name
  for (let prop in propMap) {
    if (!propMap.hasOwnProperty(prop)) continue;
    let propObj = propMap[prop];
    let lcName = propObj.name.toLowerCase();
    let lcDirection = deriveDirection({axis: propObj.axis, orientation, sensorType});
    if (
      summary.indexOf(lcName) !== -1 &&
      (propObj.axis == null || summary.indexOf(lcDirection) !== -1) &&
      (propObj.resolution == null || summary.indexOf(propObj.resolution)) !== -1
    ) {
      response[AlarmDataProps.ALARM_PROPERTY] = prop;
      break;
    }
  }
  function findNumber(startPoint) {
    let idx,
      returnVal = null;
    for (idx = startPoint; idx > 0; idx--) {
      let number = parseFloat(summary.substring(idx, startPoint + 1));
      // it is a bad value, have finished finding number
      if (isNaN(number)) {
        break;
      }
      // update with last good value each time
      returnVal = number;
    }
    if (returnVal == null) {
      throw "Couldn't find a valid number.";
    }
    return {idx, number: returnVal};
  }
  // find the value, search for the number from the end of summary
  try {
    let firstVal = findNumber(summary.length - 1);
    response[AlarmDataProps.ALARM_VALUES] = [firstVal.number];
    if (summary.substring(firstVal.idx - 1, firstVal.idx + 1) === "to") {
      // need to skip past the 'to'
      let secondVal = findNumber(firstVal.idx - 3);
      response[AlarmDataProps.ALARM_VALUES].push(secondVal.number);
    }
  } catch (err) {
    // there isn't really an error, just an escape from process
  }
  return response;
};
