import styled, {css} from "styled-components";

export const Container = styled.div`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.colours.background};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
`;

export const Body = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  z-index: 1;
  overflow: hidden;

  ${(props) =>
    props.theme.mixins.mobile(css`
      min-width: 100vw;
    `)}
`;

export const MobileOverlay = styled.div`
  position: absolute;
  display: none;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.theme.mixins.mobile(css`
      display: block;
      z-index: 999;
    `)}
`;
