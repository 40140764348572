import {observable, action, computed, flow} from "mobx";

import Api, {setAuthToken} from "sensoteq-react-core/services/api";
import LoadableStore from "sensoteq-react-core/models/LoadableStore";
import {doesPropExist} from "sensoteq-core/utilities/object";
import {PresentableDataTypes, Privileges} from "sensoteq-core/enumerations";
import * as Enums from "constants/enums";
import dayjs from "dayjs";
import {setReactQueryAPIToken} from "services/ReactQuery";

export default class UserStore extends LoadableStore {
  @observable token;
  @observable error;
  @observable.shallow user;
  @observable.shallow loginMetadata;

  _tryGetUserProp(prop, defaultValue = undefined) {
    return this.user?.[prop] ?? defaultValue;
  }

  @computed get username() {
    return this._tryGetUserProp("username");
  }

  @computed get company() {
    const companyId = this._tryGetUserProp("company");
    return this._tryGetUserProp("full_company", {
      company_id: companyId,
      display_name: companyId,
    });
  }

  @computed get companyLogoURL() {
    return this._tryGetUserProp("company_logo");
  }

  @computed get preferences() {
    return this._tryGetUserProp("preferences", {});
  }

  @computed get admin() {
    return this.user?.admin;
  }

  @computed get installer() {
    return this.user?.installer;
  }

  @computed get allowAPIKeys() {
    return this.admin || this.user?.allow_api_key;
  }

  @computed get loggedIn() {
    return this.token != null;
  }

  @computed get isLoginTimeoutActive() {
    const state = this.loginMetadata?.state;
    if (state !== "timeout") return false;

    const diff = dayjs(this.loginMetadata?.unlock_at).diff(dayjs(), "seconds");
    return diff > 0;
  }

  @computed get currentLoginAttemptInfo() {
    const state = this.loginMetadata?.state;
    if (state === "open") return null;
    if (state === "timeout") {
      const diff = dayjs(this.loginMetadata?.unlock_at).from(dayjs());
      return `Take a short break and you'll be able to try again ${diff}!`;
    }
    if (state === "locked") {
      return "If you're having trouble logging in, don't worry! You can reset your password or reach out to our support team to unlock your account.";
    }

    return null;
  }

  @computed get nextLoginAttemptInfo() {
    const state = this.loginMetadata?.next?.state;
    if (state === "open") return null;
    if (state === "timeout") {
      const timeout = this.loginMetadata?.next.timeout_seconds;
      const now = dayjs();
      const humanTimeout = now.from(now.add(timeout, "second"), true);
      return `If you can't log in next time, your account will be timed out for ${humanTimeout}. To prevent this, double-check your login details.`;
    }
    if (state === "locked") {
      return "Heads up! You have one more attempt to log in before your account is locked.";
    }

    return null;
  }

  @computed get chiViewingAccess() {
    if (this.admin) return true;
    if (this.company.chi_viewing_access === true) return true;
    for (const company of this.rootStore.companyStore.companies) {
      if (company.chi_viewing_access === true) return true;
    }

    return false;
  }

  @computed get isChiOnlyUser() {
    if (this.admin) {
      return false;
    }

    return this.user?.chi_user === true;
  }

  @computed get isMfaEnabled() {
    return this._tryGetUserProp("mfa_enabled", false);
  }

  @computed get userRole() {
    if (this._tryGetUserProp("admin", false)) {
      return Enums.USER_ROLE_ADMIN;
    }

    const partnerType = this._tryGetUserProp("partner_type", {});
    if (partnerType === PresentableDataTypes.PARTNER) {
      return Enums.USER_ROLE_PARTNER;
    }

    if (this._tryGetUserProp("installer", false)) {
      return Enums.USER_ROLE_INSTALLER;
    }

    // We will want to expand this to be able to label faults as well as holding another role
    if (this._tryGetUserProp("fault_labeller_id", false)) {
      return Enums.USER_ROLE_FAULT_LABELLER;
    }

    // More roles to come
    return Enums.USER_ROLE_USER;
  }

  @computed get userRoleTitle() {
    return Enums.USER_ROLE_TITLES[this.userRole];
  }

  @computed get privileges() {
    return this._tryGetUserProp("privileges", []);
  }

  @computed get isElevatedUser() {
    return (
      this.installer ||
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.userRole === Enums.USER_ROLE_MANAGER
    );
  }

  @computed get isPartnerUser() {
    return this.userRole === Enums.USER_ROLE_PARTNER;
  }

  @computed get canAddAlarms() {
    return (
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.userRole === Enums.USER_ROLE_INSTALLER ||
      this.privileges.includes(Privileges.ALARM_CREATE)
    );
  }

  @computed get canUpdateAlarms() {
    return (
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.userRole === Enums.USER_ROLE_INSTALLER ||
      this.privileges.includes(Privileges.ALARM_UPDATE)
    );
  }

  @computed get canDeleteAlarms() {
    return this.admin || this.userRole === Enums.USER_ROLE_PARTNER || this.privileges.includes(Privileges.ALARM_DELETE);
  }

  @computed get canReadBearings() {
    return (
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.userRole === Enums.USER_ROLE_INSTALLER ||
      this.privileges.includes(Privileges.BEARING_READ)
    );
  }

  @computed get canAddBearings() {
    return (
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.userRole === Enums.USER_ROLE_INSTALLER ||
      this.privileges.includes(Privileges.BEARING_CREATE)
    );
  }

  @computed get canUpdateBearings() {
    return (
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.userRole === Enums.USER_ROLE_INSTALLER ||
      this.privileges.includes(Privileges.BEARING_UPDATE)
    );
  }

  @computed get canDeleteBearings() {
    return (
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.userRole === Enums.USER_ROLE_INSTALLER ||
      this.privileges.includes(Privileges.BEARING_DELETE)
    );
  }

  @computed get canAccessRFValidity() {
    return (
      this.admin || this.userRole === Enums.USER_ROLE_PARTNER || this.privileges.includes(Privileges.RF_VALIDITY_READ)
    );
  }

  @computed get canReadAlarmTemplates() {
    return (
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.privileges.includes(Privileges.ALARM_TEMPLATE_READ)
    );
  }

  @computed get canAddAlarmTemplates() {
    return (
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.privileges.includes(Privileges.ALARM_TEMPLATE_CREATE)
    );
  }

  @computed get canUpdateAlarmTemplates() {
    return (
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.privileges.includes(Privileges.ALARM_TEMPLATE_UPDATE)
    );
  }

  @computed get canDeleteAlarmTemplates() {
    return (
      this.admin ||
      this.userRole === Enums.USER_ROLE_PARTNER ||
      this.privileges.includes(Privileges.ALARM_TEMPLATE_DELETE)
    );
  }

  @computed get canAddBulkAlarm() {
    return (
      this.admin || this.userRole === Enums.USER_ROLE_PARTNER || this.privileges.includes(Privileges.BULK_ALARM_CREATE)
    );
  }

  @computed get canReadBulkAlarm() {
    return (
      this.admin || this.userRole === Enums.USER_ROLE_PARTNER || this.privileges.includes(Privileges.BULK_ALARM_READ)
    );
  }

  @computed get canUpdateBulkAlarm() {
    return (
      this.admin || this.userRole === Enums.USER_ROLE_PARTNER || this.privileges.includes(Privileges.BULK_ALARM_UPDATE)
    );
  }

  @computed get canDeleteBulkAlarm() {
    return (
      this.admin || this.userRole === Enums.USER_ROLE_PARTNER || this.privileges.includes(Privileges.BULK_ALARM_DELETE)
    );
  }

  @computed get canLabelFaults() {
    return this.user?.fault_labeller_id != null;
  }

  verifyMfa = flow(function* (mfaToken) {
    this.startLoading();
    try {
      const result = yield Api.verifyMfa(mfaToken);
      if (result.success) {
        this.setToken(result.token);
        this.error = null;
        this.stopLoading();

        // Init stores
        setAuthToken(result.token);
        setReactQueryAPIToken(result.token);
        this.rootStore.refresh();
        this.rootStore.notificationStore.addNotification("Authenticated successfully", "good");
        return true;
      }
    } catch (error) {
      this.error = "Incorrect MFA token";
      this.rootStore.notificationStore.addNotification("Incorrect MFA Token", "bad");
      this.stopLoading();
    }
  });

  getMfaQrCode = flow(function* () {
    this.startLoading();
    try {
      const uri = yield Api.getMfaQrCode();
      this.stopLoading();
      return uri;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error retrieving MFA QrCode: ${error}`, "bad");
    }
    this.stopLoading();
  });

  logIn = flow(function* (username, password) {
    if (!username || !password) {
      this.error = "Please enter your username and password";
      return;
    }

    this.startLoading();
    try {
      const data = yield Api.logIn(username, password);
      setAuthToken(data.token);
      setReactQueryAPIToken(data.token);
      if (!doesPropExist(data.user, "mfa_enabled") || data.user.mfa_enabled === false) {
        this.setToken(data.token);
        // Init stores
        this.rootStore.refresh();
        this.rootStore.notificationStore.addNotification("Logged in successfully", "good");
      }

      this.error = null;
      this.user = data.user;
      this.stopLoading();

      return data;
    } catch (error) {
      if (typeof error === "string") {
        this.error = error;
      } else {
        this.error = error.message;
        this.loginMetadata = error.metadata;
      }
      this.stopLoading();
    }
  });

  @action logOut(message, resetState = true, redirectURL = true) {
    // Don't log out if we already have
    if (!this.loggedIn) {
      return;
    }

    // Kill session
    try {
      Api.logOut();
    } catch (error) {
      // Ignore error
    }

    // Redirect if required
    if (redirectURL) {
      this.rootStore.routerStore.push("/");
    }

    // Reset state if required
    if (resetState) {
      this.rootStore.reset(message);
    }
  }

  @action setToken(token) {
    this.token = token;
  }

  refreshSelf = flow(function* () {
    this.startLoading();
    try {
      const data = yield Api.getCurrentUser();
      this.user = data.user;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting user: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateSelf = flow(function* (password, newPassword, email, phone, preferences, mfaEnabled) {
    this.startLoading();
    try {
      yield Api.updateSelf(password, newPassword, email, phone, preferences, mfaEnabled);
      yield this.refreshSelf();
      this.rootStore.notificationStore.addNotification("Settings updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating settings: ${error}`, "bad");
    }
    this.stopLoading();
  });

  disableMFA = flow(function* () {
    this.startLoading();
    try {
      yield Api.disableMFA();
      yield this.refreshSelf();
      this.rootStore.notificationStore.addNotification("MFA settings updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification("Error updating MFA settings", "bad");
    }
    this.stopLoading();
  });
}
