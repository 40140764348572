import TemplateSubscription from "./TemplateSubscription";
import {TemplateType} from "sensoteq-core/enumerations";

export default class AnalysisGroupSubscription extends TemplateSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      templateType: TemplateType.ANALYSIS,
    };
  }
}
