import {computed} from "mobx";
import MultiPointSubscription from "./MultiPointSubscription";

export default class MultiTimeDomainDataSubscription extends MultiPointSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      getSubscribableStore: (x) => x.timeDomainDataStore,
    };
  }

  extractData(extractFn) {
    let data = [];
    this.subscriptions.forEach((sub) => {
      let subData = extractFn(sub);
      if (subData != null) {
        data.push(subData[0]);
      }
    });

    return this.enrichTitles(data);
  }

  @computed get validData() {
    return this.subscriptions.some((x) => x.validData);
  }

  @computed get accelerationData() {
    return this.extractData((x) => x.accelerationData);
  }

  @computed get velocityData() {
    return this.extractData((x) => x.velocityData);
  }

  @computed get peakToPeak() {
    return this.subscriptions.map((x) => {
      return {id: x.cleansedParams?.key?.p_id, value: x.peakToPeak};
    });
  }

  @computed get envelopeRMS() {
    return this.subscriptions.map((x) => {
      return {id: x.cleansedParams?.key?.p_id, value: x.envelopeAccRMS};
    });
  }

  @computed get envelopePeak() {
    return this.subscriptions.map((x) => {
      return {id: x.cleansedParams?.key?.p_id, value: x.envelopePeak};
    });
  }

  @computed get rpm() {
    return this.subscriptions.map((x) => {
      return {id: x.cleansedParams?.key?.p_id, value: x.accelerationRPMValue};
    });
  }

  @computed get twfCrestFactor() {
    return this.subscriptions.map((x) => {
      return {id: x.cleansedParams?.key?.p_id, value: x.crestFactor};
    });
  }

  @computed get displacementData() {
    return this.extractData((x) => x.displacementData);
  }

  @computed get timeData() {
    return this.extractData((x) => x.timeData);
  }

  @computed get velocityTimeData() {
    return this.extractData((x) => x.velocityTimeData);
  }

  @computed get displacementTimeData() {
    return this.extractData((x) => x.displacementTimeData);
  }
}
