module.exports.ENVELOPE_METHOD = {
  HILBERT: "hilbert_transform",
};

module.exports.ENVELOPE_BAND_PASS_LABELS = {
  RANGE_5_100_HZ: "5-100Hz",
  RANGE_50_1000_HZ: "50-1000Hz",
  RANGE_1800_3000_HZ: "1800-3000Hz",
  RANGE_500_10000_HZ: "500-10000Hz",
};

module.exports.ENVELOPE_BAND_PASS_VALUE_LOOKUP = {
  [module.exports.ENVELOPE_BAND_PASS_LABELS.RANGE_5_100_HZ]: {low: 5, high: 100},
  [module.exports.ENVELOPE_BAND_PASS_LABELS.RANGE_50_1000_HZ]: {low: 50, high: 1000},
  [module.exports.ENVELOPE_BAND_PASS_LABELS.RANGE_1800_3000_HZ]: {low: 1800, high: 3000},
  [module.exports.ENVELOPE_BAND_PASS_LABELS.RANGE_500_10000_HZ]: {low: 500, high: 10000},
};
