import React from "react";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

import {asField} from "../lib/mobx-form";
import useDebouncer from "../../../apps/analytix/src/components/hooks/useDebouncer";
import Label from "./Label";

function SearchInput(props) {
  const {
    search,
    extractResults,
    extractText,
    extractResultText,
    label,
    field,
    fieldState,
    fieldApi,
    placeholder,
    extractIdentifier,
    getApi,
  } = props;
  const blockNextClick = React.useRef(false);
  const [searchString, setSearchString] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState([]);
  const [showResults, setShowResults] = React.useState(false);
  const unmounted = React.useRef(false);
  const text = searchString ?? "";

  // Debouncer to retrieve data
  const debouncer = useDebouncer(search, (data) => {
    if (unmounted.current) {
      return;
    }
    setSearchResults(extractResults(data));
  });

  // Callback to handle clicking on the page to close the search results
  const onClose = React.useCallback(() => {
    if (blockNextClick.current) {
      blockNextClick.current = false;
    } else {
      setShowResults(false);
      document.removeEventListener("click", onClose);
    }
  }, []);

  // Callback to handle opening the search results when clicking on input
  const onOpen = React.useCallback(() => {
    if (!showResults) {
      setShowResults(true);
      document.addEventListener("click", onClose);
    } else {
      blockNextClick.current = true;
    }
  }, [showResults, onClose]);

  // Pass up api to control field if required
  React.useEffect(() => {
    if (!getApi) {
      return;
    }
    getApi({
      reset: () => {
        onClose();
        setSearchString(null);
        fieldApi.setValue(null);
        setSearchResults([]);
      },
    });
  }, [getApi, onClose, fieldApi]);

  // Remove click handler on close
  React.useEffect(() => {
    return () => {
      unmounted.current = true;
      document.removeEventListener("click", onClose);
    };
  }, []);

  // Callback to change search string
  const onChange = React.useCallback(
    (e) => {
      let value = e.target.value;
      if (value === "") {
        value = undefined;
        setSearchResults([]);
      }
      setSearchString(value);
      onOpen();
      if (fieldState.value != null) {
        fieldApi.setValue(null);
        props.onChange && props.onChange(null);
      }
    },
    [fieldState.value, fieldApi, props.onChange, onOpen],
  );

  // Keep searching as we type
  React.useEffect(() => {
    // Do nothing if no search string
    if (!searchString) {
      debouncer.cancel();
      return;
    }

    // Retrieve bearings
    debouncer.invoke(searchString);
  }, [searchString]);

  // Callback to handle clicking result
  const onClickResult = React.useCallback(
    (item) => {
      fieldApi.setValue(item);
      setSearchString(extractText(item));
      blockNextClick.current = false;
      onClose();
      props.onChange && props.onChange(item);
    },
    [fieldApi, extractText, props.onChange, onClose],
  );

  return (
    <Container>
      {label && <Label htmlFor={field}>{label}</Label>}
      {fieldState.error && <Label error>{fieldState.error}</Label>}
      <StyledInput
        id={field}
        value={text}
        spellCheck={false}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="off"
        onClick={onOpen}
        error={!!fieldState.error}
        userDisabled={fieldState.disabled}
        readOnly={fieldState.disabled}
      />
      {searchResults.length > 0 && showResults && (
        <ResultContainer>
          {searchResults.map((result, idx) => (
            <Result
              key={idx}
              onClick={() => onClickResult(result)}
              active={fieldState.value && extractIdentifier(result) === extractIdentifier(fieldState.value)}
            >
              {extractResultText(result)}
            </Result>
          ))}
        </ResultContainer>
      )}
    </Container>
  );
}

SearchInput.propTypes = {
  search: PropTypes.func.isRequired,
  extractResults: PropTypes.func,
  extractText: PropTypes.func,
  extractResultText: PropTypes.func,
  extractIdentifier: PropTypes.func,
  label: PropTypes.string,
  field: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  getApi: PropTypes.func,
};
SearchInput.defaultProps = {
  extractResults: (x) => x,
  extractText: (x) => x,
  extractResultText: (x) => x,
  extractIdentifier: (x) => x,
};

export default asField(SearchInput);

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
`;

const StyledInput = styled.input`
  padding: ${(props) => props.theme.forms.padding};
  border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  border-radius: ${(props) => props.theme.forms.borderRadius};
  background-color: ${(props) => props.theme.colours.inputBackground};

  ${(props) =>
    props.userDisabled &&
    css`
      background-color: ${(props) => props.theme.colours.contentBackgroundAlt};
      color: ${(props) => props.theme.colours.contentLight};
    `}

  &::placeholder {
    color: ${(props) => props.theme.colours.contentLight};
  }

  &:focus {
    border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.primaryHighlight};
    outline: none;
    z-index: 2;
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${(props) => props.theme.colours.bad};
    `}
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-height: 20rem;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colours.contentBackground};
  border-radius: ${(props) => props.theme.forms.borderRadius};
  box-shadow: ${(props) => props.theme.forms.heavyBoxShadow};
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 100%;
`;

export const Result = styled.div`
  flex: 0 0 auto;
  padding: ${(props) => props.theme.forms.padding};

  &:hover {
    ${(props) =>
      !props.active &&
      css`
        background-color: ${(props) => props.theme.colours.primaryBackground};
        cursor: pointer;
      `}
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.colours.primary};
      color: white;
    `}
`;
