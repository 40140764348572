let enums = require("../sensoteq-core/enumerations");
let dbEnums = require("../sensoteq-core/cloud/db-enums");
let safeTimer = require("../sensoteq-core/safe-timer");
let logging = require("./core-logging");
const GRAVITY = enums.GRAVITY;
const VALID_TIME_DIFFERENCE_MS = 20000;
const SENSOR_ID_LENGTH = 6;

const OLD_STRING = "sensoteq-receiver";
const THERMAL_STRING = "box-";

module.exports.getHomeDirectory = function () {
  return process.env[process.platform == "win32" ? "USERPROFILE" : "HOME"];
};

module.exports.getDateStringFormat = function (date) {
  let today = date;
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  let hour = today.getHours();
  let min = today.getMinutes();
  let sec = today.getSeconds();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (hour < 10) {
    hour = "0" + hour;
  }
  if (min < 10) {
    min = "0" + min;
  }
  if (sec < 10) {
    sec = "0" + sec;
  }
  return dd + "/" + mm + "/" + yyyy + " " + hour + ":" + min + ":" + sec + "GMT";
};

module.exports.dateLog = function (string) {
  let today = "[" + this.getDateNow() + "] ";
  // eslint-disable-next-line no-console
  logging.LOG(today + string);
};

module.exports.getDateNow = function () {
  return this.getDateStringFormat(new Date());
};

module.exports.getParameter = function (string, object) {
  if (typeof string !== "string") {
    return null;
  }
  let lcString = string.toLowerCase();
  if (string in object) {
    return object[string];
  } else if (lcString in object) {
    return object[lcString];
  }
  return null;
};

module.exports.getEpochSeconds = function () {
  return Math.round(Date.now() / 1000);
};

module.exports.getEpochMilliseconds = function () {
  return Date.now();
};

// particle doesn't cleanse their addresses to remove things such as port numbers etc
module.exports.cleanseIpAddress = function (address) {
  let firstDigit = address.match(/\d/);
  let idxDigit = address.indexOf(firstDigit);

  if (idxDigit < address.length) {
    address = idxDigit === -1 ? address : address.substring(idxDigit, address.length);

    let idxColon = address.indexOf(":");
    address = idxColon === -1 ? address : address.substring(0, idxColon);
  } else {
    module.exports.dateLog("ERROR: IP_ADDRESS: " + address + " does not contain any digits.");
  }

  return address;
};

module.exports.getSensorIDFromInt = function (sensorInt) {
  let hexId = sensorInt.toString(16);
  if (hexId.length !== 6) {
    // hex string should be 6 characters, if not add zero
    hexId = "0" + hexId;
  }
  return hexId;
};

module.exports.roundTwoDecimal = function (number) {
  return Math.round(number * 100) / 100;
};

module.exports.roundToDecimal = function (number, points) {
  let multiplier = Math.pow(10, points);
  return Math.round(number * multiplier) / multiplier;
};

module.exports.getCurrentTimestamp = (timeType, minusFactor, seed = null) => {
  if (minusFactor == null) {
    minusFactor = 0;
  }
  if (timeType === enums.MachTimeType.HOUR) {
    return module.exports.getHourTimestamp(minusFactor, seed);
  }
  if (timeType === enums.MachTimeType.DAY) {
    return module.exports.getDayTimestamp(minusFactor, seed);
  }
  if (timeType === enums.MachTimeType.MONTH) {
    return module.exports.getMonthTimestamp(minusFactor, seed);
  }
  return null;
};

module.exports.getMonthTimestamp = function (minusFactor, seed = null) {
  if (minusFactor == null) minusFactor = 0;
  let date = seed == null ? new Date() : new Date(seed);
  return new Date(date.getFullYear(), date.getMonth() - minusFactor, 1).getTime();
};

module.exports.getDayTimestamp = function (minusFactor, seed = null) {
  if (minusFactor == null) minusFactor = 0;
  let date = seed == null ? new Date() : new Date(seed);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - minusFactor).getTime();
};

module.exports.convertTimestampToDaily = function (timestamp) {
  let date = new Date(timestamp);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
};

module.exports.minusDaysFromTimestamp = function (timestamp, number) {
  let date = new Date(timestamp);
  const newDate = date.setDate(date.getDate() - number);
  return newDate;
};

module.exports.getHourTimestamp = function (minusFactor, seed = null) {
  if (minusFactor == null) minusFactor = 0;
  let date = seed == null ? new Date() : new Date(seed);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() - minusFactor).getTime();
};

module.exports.getEvenHourTimestamp = function () {
  let date = new Date();

  // isn't an even hour, return the hour timestamp with 1 removed
  if (date.getHours() % 2 !== 0) {
    return module.exports.getHourTimestamp(1);
  }

  return module.exports.getHourTimestamp();
};

module.exports.isPeakEntryBad = function (peakEntry) {
  return peakEntry.amplitude < 0 || peakEntry.frequency > 1000 || peakEntry.frequency < 0;
};

module.exports.isStrengthBad = function (strength) {
  return strength < 0 || strength > 100;
};

module.exports.isAmbientTemperatureBad = function (temperature) {
  return temperature < -50 || temperature > 150;
};

module.exports.isAmbientHumidityBad = function (humidity) {
  return humidity < -10 || humidity > 110;
};

module.exports.isReceiverDataBad = function (strength, temperature, humidity) {
  return (
    module.exports.isStrengthBad(strength) ||
    module.exports.isAmbientTemperatureBad(temperature) ||
    module.exports.isAmbientHumidityBad(humidity)
  );
};

module.exports.getBaseTime = function () {
  return Math.floor(new Date(new Date("January 1, 2017, 00:00:00")).getTime() / 1000 / 60 / 60);
};

module.exports.convertTimeID = function (timeID) {
  timeID = parseInt("0x" + timeID);
  return (timeID + module.exports.getBaseTime()) * 1000 * 60 * 60;
};

module.exports.isSensorIDValid = function (sensorID) {
  if (sensorID == null) {
    return false;
  }

  if (sensorID.substr(0, 7) === enums.SENSOR_ID_PREFIX) {
    sensorID = sensorID.substr(7);
  }

  // sensor ID is too short or too long, string should be 6 hex digits
  // if the first character of the sensor ID is
  if (sensorID.length !== SENSOR_ID_LENGTH) {
    return false;
  }

  let regEx = /[0-9A-Fa-f]{6}/g;
  if (!regEx.test(sensorID)) {
    return false;
  }

  let timestamp = module.exports.convertTimeID(sensorID.substr(0, 4));
  return module.exports.isDataTimeValid(timestamp);
};

module.exports.isSensorDataBad = function (sensorID, temperature) {
  return !module.exports.isSensorIDValid(sensorID) || temperature < -50 || temperature > 200;
};

module.exports.isPeakToPeakBad = function (peakToPeak) {
  let isValid = true;
  function recurse(obj, objKey) {
    for (let key in obj) {
      if (!obj.hasOwnProperty(key)) {
        continue;
      }

      let property = obj[key];
      if (typeof property === "object") {
        isValid = isValid && recurse(property, key);
      } else if (typeof property === "number") {
        // 0G to 16G is delta of a force sensor, if it is a delta number or a delta array
        if (key.indexOf("delta") !== -1 || (objKey != null && objKey.indexOf("delta") !== -1)) {
          isValid = isValid && property >= 0 && property <= 16000;
        }
        // other properties must be -8G to 8G
        else {
          isValid = isValid && property >= -8000 && property <= 8000;
        }

        // end recursion if its not valid anymore, no point searching onwards
        if (!isValid) {
          return isValid;
        }
      }
    }
    return isValid;
  }

  // not as this is whether valid or not
  return !recurse(peakToPeak);
};

module.exports.countElementOccurence = function (array, element) {
  let count = 0;
  for (let i = 0; i < array.length; ++i) {
    if (array[i] === element) {
      count++;
    }
  }
  return count;
};

module.exports.buildDeviceSensorKey = function (deviceName, sensorID) {
  return sensorID != null ? deviceName + "/" + sensorID : deviceName;
};

module.exports.buildSiteImageKey = function (siteName, imageName) {
  return siteName != null ? siteName + "/" + imageName : imageName;
};

module.exports.getDeviceAndSensorFromKey = function (deviceKey) {
  if (deviceKey.indexOf("sensor") === -1 || deviceKey.indexOf("/") === -1) {
    return {deviceName: deviceKey, sensorID: null};
  } else {
    let split = deviceKey.split("/");
    return {deviceName: split[0], sensorID: split[1]};
  }
};

module.exports.generateOnlineEvent = function (online, type) {
  return {event: type, new_state: online ? "online" : "offline"};
};

module.exports.isTimestampInSeconds = function (timestamp) {
  let date = new Date(timestamp);
  // none of our timestamps should be before the century change
  return date.getFullYear() < 2000;
};

module.exports.makeMilliseconds = function (timestampSecs) {
  if (module.exports.isTimestampInSeconds(timestampSecs)) {
    return timestampSecs * 1000;
  } else {
    return timestampSecs;
  }
};

module.exports.isDataTimeValid = function (timestamp) {
  let timestampMs = timestamp;
  if (module.exports.isTimestampInSeconds(timestamp)) {
    timestampMs = timestamp * 1000;
  }

  let date = new Date(timestampMs);
  let currentDate = new Date();
  // before the company was created, can't be a valid timestamp, or after current date, not possible
  return !(date.getTime() > currentDate.getTime() + VALID_TIME_DIFFERENCE_MS || date.getFullYear() < 2016);
};

module.exports.cleanseObject = function (properties, obj) {
  function cleanse(innerObj) {
    let newObj = {};
    properties.forEach((property) => {
      newObj[property] = innerObj[property];
    });
    return newObj;
  }
  if (obj instanceof Array) {
    let newArray = [];
    obj.forEach((element) => {
      newArray.push(cleanse(element));
    });
    return newArray;
  } else {
    return cleanse(properties, obj);
  }
};

module.exports.getBaseSensorID = function (sensorID) {
  let sensorStr = "sensor_";
  if (sensorID == null) {
    return sensorID;
  }
  if (sensorID.indexOf(sensorStr) !== -1) {
    sensorID = sensorID.substr(sensorID.indexOf(sensorStr) + sensorStr.length);
  }
  return sensorID;
};

module.exports.isAdmin = function (user) {
  return (user != null && user["accessLevel"] === 1) || (user != null && user["admin"] === true);
};

module.exports.isInstaller = function (user) {
  return (user != null && user["accessLevel"] === 2) || user["installer"] === true;
};

module.exports.isPartner = function (user) {
  return (
    user != null &&
    (user[enums.PresentableDataTypes.PARTNER_TYPE] === enums.PresentableDataTypes.PARTNER ||
      user["partnerType"] === enums.PresentableDataTypes.PARTNER)
  );
};

module.exports.isManager = function (user) {
  //Depending if we're reading it from token or from userDetails, the partner_type is different
  //Will need a refactor to make it the same everywhere
  return (
    user != null &&
    (user[enums.PresentableDataTypes.PARTNER_TYPE] === enums.PresentableDataTypes.COMPANY_MANAGER ||
      user["partnerType"] === enums.PresentableDataTypes.COMPANY_MANAGER)
  );
};

module.exports.getAxisData = function (timeDomainData, axis) {
  return timeDomainData[axis] instanceof Array
    ? timeDomainData[axis].slice()
    : timeDomainData[axis]["values"] instanceof Array
    ? timeDomainData[axis]["values"].slice()
    : null;
};

// optionally if null strings are useful then allowNull can be set
module.exports.isValidString = function (str, allowNull = enums.AllowNull.NO) {
  if (allowNull && str == null) {
    return true;
  }
  return str != null && typeof str === "string";
};

module.exports.isValidNumber = function (num) {
  return num != null && !isNaN(parseFloat(num));
};

module.exports.clone = function (a) {
  return JSON.parse(JSON.stringify(a));
};

module.exports.resolver = function (obj, path) {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : undefined;
  }, obj || self);
};

module.exports.radiansToDegrees = function (rad) {
  return (rad * 180) / Math.PI;
};

module.exports.degreesToRadians = function (deg) {
  return (deg / 180) * Math.PI;
};

// note this function should only be used in error cases, it is not efficient!
module.exports.stringify = function (obj) {
  if (typeof obj === "string") {
    return obj;
  }
  return obj == null ? "" : obj.toString() === "[object Object]" ? JSON.stringify(obj) : obj.toString();
};

module.exports.isOffline = function (lastSeenMs) {
  return module.exports.getEpochMilliseconds() - lastSeenMs > enums.MAX_LAST_TIME_SEEN_ONLINE;
};

module.exports.getResolution = function (samplingFreq) {
  return samplingFreq === enums.HIGH_RES_SAMPLING_FREQ_HZ
    ? enums.TimeDomainResolution.HIGH
    : enums.TimeDomainResolution.LOW;
};

module.exports.isRecordComplete = function (recordTimestamp, lastBlockNumber) {
  return (
    module.exports.getEpochMilliseconds() - recordTimestamp > enums.HOUR_IN_MILLIS * 2 ||
    (lastBlockNumber != null && lastBlockNumber === enums.FULL_TIME_DOMAIN_SET)
  );
};

module.exports.toBool = function (property) {
  if (typeof property === "boolean") {
    return property;
  }

  if (typeof property === "string") {
    return property.toLocaleLowerCase() === "true";
  } else {
    return !!property;
  }
};

module.exports.boolToNum = function (number) {
  return module.exports.toBool(number) ? 1 : 0;
};

module.exports.optionalString = function (property) {
  if (property == null || typeof property !== "string" || property === "" || property.length === 0) {
    return null;
  }
  return property;
};

module.exports.isBadSwVersion = function (swVersion) {
  return swVersion == null || swVersion.length !== 7;
};

module.exports.splitSensorCSV = function (sensorCSV) {
  let finalList = [];

  let sensors = sensorCSV.split(enums.SEPARATOR);
  // there was no separators but the result isn't empty, one sensor
  if (sensors.length === 1 && sensorCSV.length === 0) {
    sensors = [];
  }

  sensors.forEach((sensor) => {
    finalList.push("sensor_" + sensor);
  });

  // has a byte attached to each sensor
  if (sensors != null && sensors.length !== 0 && sensors[0].indexOf(enums.INTERNAL_SEPARATOR) !== -1) {
    let newList = [];
    for (let sensorID of finalList) {
      let elements = sensorID.split(enums.INTERNAL_SEPARATOR);
      newList.push({
        [enums.PresentableDataTypes.SENSOR_ID]: elements[0],
        [enums.PresentableDataTypes.LATEST_RSSI]: parseInt(elements[1], 16),
      });
    }
    finalList = newList;
  }

  return finalList;
};

module.exports.addSensorIfNeeded = function (sensorID) {
  if (sensorID.substring(0, 7) === "sensor_") {
    return sensorID;
  } else {
    return "sensor_" + sensorID;
  }
};

module.exports.buildResolutionKey = function (deviceName, sensorID, resolution) {
  return module.exports.buildDeviceSensorKey(deviceName, sensorID) + `/${resolution}`;
};

module.exports.buildFullEntryID = function (deviceName, sensorID, resolution, axis) {
  return module.exports.buildResolutionKey(deviceName, sensorID, resolution) + `/${axis}`;
};

module.exports.buildFullMachineEntryID = function (pointID, resolution = null, axis = null) {
  let id = pointID;
  if (resolution != null) {
    id += `/${resolution}` + (axis != null ? `/${axis}` : "");
  }
  return id;
};

module.exports.isValidAxis = function (axis) {
  if (axis == null) {
    return axis;
  }

  for (let axisKey in enums.AxisTypes) {
    if (enums.AxisTypes.hasOwnProperty(axisKey) && enums.AxisTypes[axisKey] === axis) {
      return axis;
    }
  }
  throw {error: "Invalid axis provided, must be 'x', 'y' or 'z'."};
};

module.exports.isValidResolution = function (resolution) {
  if (resolution == null) {
    return resolution;
  }

  for (let resolutionKey in enums.TimeDomainResolution) {
    if (
      enums.TimeDomainResolution.hasOwnProperty(resolutionKey) &&
      enums.TimeDomainResolution[resolutionKey] === resolution
    ) {
      return resolution;
    }
  }
  throw {error: "Invalid resolution provided, must be 'high' or 'low'."};
};

module.exports.isGatewayName = function (name) {
  return name.indexOf(OLD_STRING) !== -1 || name.indexOf(THERMAL_STRING) !== -1;
};

module.exports.isThermalGateway = function (name) {
  return name.indexOf(THERMAL_STRING) !== -1;
};

module.exports.breakPointIDKey = function (key) {
  // only one string, must be a point ID
  if (key == null || key.indexOf("/") === -1) {
    return {pointID: key};
  }

  let strings = key.split("/");
  let returnObj = {};

  if (module.exports.isGatewayName(key)) {
    // go back to old mechanism to break it up
    returnObj = module.exports.breakOldDeviceKeys(key, false);
    // convert to a pointID
    returnObj.pointID = returnObj.deviceName + (returnObj.sensorID != null ? `/${returnObj.sensorID}` : "");
    // remove the deviceName and sensorID
    delete returnObj.deviceName;
    delete returnObj.sensorID;
    return returnObj;
  }

  if (strings[0] != null) {
    returnObj.pointID = strings[0];
  }
  if (strings[1] != null) {
    returnObj.resolution = strings[1];
  }
  if (strings[2] != null) {
    returnObj.axis = strings[2];
  }
  return returnObj;
};

module.exports.breakOldDeviceKeys = function (key) {
  // only one string, must be a device name
  if (key == null || key.indexOf("/") === -1) {
    return {deviceName: key};
  }
  let strings = key.split("/");
  let returnObj = {};

  if (strings[0] != null) {
    returnObj.deviceName = strings[0];
  }
  if (strings[1] != null) {
    returnObj.sensorID = strings[1];
  }
  if (strings[2] != null) {
    returnObj.resolution = strings[2];
  }
  if (strings[3] != null) {
    returnObj.axis = strings[3];
  }
  return returnObj;
};

module.exports.normalizePort = function (val) {
  let port = parseInt(val, 10);
  if (isNaN(port)) {
    // named pipe
    return val;
  }
  if (port >= 0) {
    // port number
    return port;
  }
  return false;
};

module.exports.getPreviousPowerOf2 = function (number) {
  number = number | (number >> 1);
  number = number | (number >> 2);
  number = number | (number >> 4);
  number = number | (number >> 8);
  number = number | (number >> 16);
  return number - (number >> 1);
};

module.exports.getNextPowerOf2 = function (number) {
  return module.exports.getPreviousPowerOf2(number) * 2;
};

module.exports.sortTimeIndexedData = function (data, key, oldestFirst = true) {
  data.sort((a, b) => {
    if (oldestFirst) {
      return a[key] - b[key];
    } else {
      return b[key] - a[key];
    }
  });
  return data;
};

module.exports.isRemoteDebug = function () {
  return typeof v8debug === "object" || /--debug|--inspect/.test(process.execArgv.join(" "));
};

module.exports.isIntegerArray = function (array) {
  for (let el of array) {
    if (el % 1 !== 0) {
      return false;
    }
  }
  return true;
};

module.exports.getAxisStrategy = function (sensorId) {
  const hex = sensorId.substr(7, 4);
  const hours = parseInt(hex, 16);
  const cutoffHours = Math.floor((new Date("2018-07-17").getTime() - new Date("2017-01-01").getTime()) / (1000 * 3600));
  return hours > cutoffHours ? enums.AxisStrategies.X_UP : enums.AxisStrategies.Y_UP;
};

module.exports.flatMap = function (arr, func) {
  let arrays = arr.map((x) => {
    return func(x);
  });
  return arrays.reduce((x, y) => {
    return y.concat(x);
  }, []);
};

module.exports.flatArray = function (arr) {
  return [].concat.apply([], arr);
};

module.exports.timeout = function (time) {
  return new Promise((resolve) => {
    safeTimer.setTimeout(() => {
      resolve();
    }, time);
  });
};

module.exports.promiseWithTimeout = (promise, timeoutMs) => {
  let timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      reject({error: enums.ErrorMessages.TIMEOUT});
    }, timeoutMs);
  });
  return Promise.race([promise, timeout]);
};

module.exports.breakSensorID = function (sensorID) {
  let timeID = sensorID.substring(sensorID.length - 6, sensorID.length - 2);
  // convert to integer from hex, then convert back to string for dynamo
  let count = parseInt(sensorID.substring(sensorID.length - 2, sensorID.length), 16);
  return {timeID, count};
};

module.exports.capitaliseSentence = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

module.exports.union = function (array1, array2) {
  let a = array1.concat(array2);
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
};

module.exports.difference = function (array, toDiff) {
  if (typeof array[0] === "object") {
    array = array.map((el) => module.exports.stringify(el));
    toDiff = toDiff.map((el) => module.exports.stringify(el));
  }

  let finalArray = [];
  for (let el of array) {
    if (toDiff.indexOf(el) === -1) {
      finalArray.push(el);
    }
  }
  return finalArray;
};

module.exports.mapToArray = function (map) {
  if (typeof map !== "object") {
    return [map];
  }

  let array = [];
  for (let key in map) {
    if (!map.hasOwnProperty(key)) continue;
    array.push(map[key]);
  }
  return array;
};

module.exports.buildMachinePointKey = function (pointID, resolution = null, axis = null) {
  let str = pointID;
  if (resolution != null) {
    str += "/" + (axis != null ? resolution + "/" + axis : resolution);
  }
  return str;
};

module.exports.removeNull = function (obj) {
  if (obj == null || typeof obj !== "object") return obj;

  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;
    if (typeof obj[key] === "object") {
      obj[key] = module.exports.removeNull(obj[key]);
    } else if (obj[key] == null || obj[key] === "") {
      delete obj[key];
    }
  }

  return obj;
};

module.exports.lowercase = function (str, trim = true) {
  return typeof str === "string" ? (trim ? str.trim().toLowerCase() : str.toLowerCase()) : str;
};

module.exports.uniqueInArray = function (array) {
  return [...new Set(array)];
};

module.exports.validateEmail = (email) => {
  // eslint-disable-next-line
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

module.exports.settleAllPromises = (promises) => {
  return new Promise((resolve) => {
    if (promises == null || promises.length === 0) {
      resolve({error: [], success: []});
    }
    const result = (promise) => {
      return promise
        .then((result) => ({success: true, result: result}))
        .catch((error) => ({success: false, result: error}));
    };
    Promise.all(promises.map(result)).then((allResults) => {
      let success = [],
        error = [];
      for (let el of allResults) {
        if (el.success) {
          success.push(el.result);
        } else {
          error.push(el.result);
        }
      }
      resolve({error, success});
    });
  });
};

module.exports.doesContainsNaN = (obj) => {
  if (obj == null) {
    return false;
  }
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue;
    }
    if (typeof obj[key] === "object" && module.exports.doesContainsNaN(obj[key])) {
      return true;
    } else if (isNaN(obj[key])) {
      return true;
    }
  }
  return false;
};

module.exports.removeNaN = (obj) => {
  if (obj == null) {
    return;
  }
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue;
    }
    if (typeof obj[key] === "object") {
      obj[key] = module.exports.removeNaN(obj[key]);
    }
    if (isNaN(obj[key])) {
      obj[key] = 0;
    }
  }
};

module.exports.areObjectsEqual = (objA, objB) => {
  return module.exports.stringify(objA) === module.exports.stringify(objB);
};

module.exports.isDeviceFiltered = (device) => {
  return (
    device != null &&
    device[dbEnums.GatewayTableInfo.GATEWAY_FILTER_LIST] != null &&
    device[dbEnums.GatewayTableInfo.GATEWAY_FILTER_LIST].length !== 0
  );
};

module.exports.fixTimestampedOrder = (data, oldestAtZero = true) => {
  if (data == null || !(data instanceof Array) || data.length < 2) {
    return data;
  }
  if (oldestAtZero && data[0][dbEnums.MiscTableInfo.SORT_KEY] > data[1][dbEnums.MiscTableInfo.SORT_KEY]) {
    data.reverse();
  }
  if (!oldestAtZero && data[0][dbEnums.MiscTableInfo.SORT_KEY] < data[1][dbEnums.MiscTableInfo.SORT_KEY]) {
    data.reverse();
  }
  return data;
};

module.exports.cacheRetrieveAndUpdate = async (
  cache,
  key,
  getFunc,
  cacheOptions = enums.CacheOptions.STANDARD,
  stdReturn = {},
) => {
  if (typeof cache !== "object" || typeof getFunc !== "function") {
    throw {error: "cache must be a cache object and getFunc must be a function to retrieve the data from database."};
  }
  if (cacheOptions !== enums.CacheOptions.NEVER_USE_CACHE) {
    let cachedInfo = cache.get(key);
    if (cachedInfo != null) {
      return cachedInfo;
    }
  }

  if (cacheOptions === enums.CacheOptions.ONLY_USE_CACHE) {
    return stdReturn;
  }

  try {
    let info = await getFunc(key);
    if (info != null) {
      cache.put(key, info);
    }
    // if there is no info we need to return stdReturn, but don't put that into the cache (null entries shouldn't
    // be entered as it will stop re-attempting retrieval until TTL. Also stops db streams from updating as the
    // entry is null in the cache.
    return module.exports.clone(info == null ? stdReturn : info);
  } catch (err) {
    throw {error: "Unable to retrieve information from cache: " + module.exports.stringify(err)};
  }
};

module.exports.defer = function (timeout = 0) {
  let res = null,
    rej = null;

  let promise = new Promise((resolve, reject) => {
    let timer = null;
    if (timeout && timeout !== 0) {
      timer = setTimeout(() => {
        reject({error: "Timeout occurred."});
      }, timeout);
    }
    res = () => {
      if (timer) {
        clearTimeout(timer);
      }
      resolve();
    };
    rej = reject;
  });

  promise.resolve = res;
  promise.reject = rej;

  return promise;
};

// this is pseudo random - do not use this for anything cryptographic
module.exports.generateRandomString = (length) => {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

module.exports.buildChiEventTarget = (company, assetLabel = null) => {
  if (assetLabel != null) {
    return `${company}/${assetLabel}`;
  } else {
    return company;
  }
};

module.exports.breakChiEventTarget = (target) => {
  if (typeof target !== "string") {
    return null;
  }

  // Target for Chi event comes in the form of company/asset_label
  const firstDividerIndex = target.indexOf("/");
  return {company: target.substring(0, firstDividerIndex), assetLabel: target.substring(firstDividerIndex + 1)};
};

module.exports.isValidChiData = (data, fftSize) => {
  if (data == null || typeof data !== "object") {
    return false;
  }
  for (let axis of enums.AxisArray) {
    if (data[axis] == null || !(data[axis] instanceof Array) || data[axis].length !== fftSize) {
      return false;
    }
  }
  return true;
};

module.exports.clearSpecialProps = (objs) => {
  if (objs == null) {
    return objs;
  }
  let specialProps = [dbEnums.SensorTableInfo.SENSOR_INFO_STATE_IDX];
  function removePropIfRequired(obj) {
    for (let prop of specialProps) {
      if (obj != null && obj[prop] != null) {
        delete obj[prop];
      }
    }
  }
  if (objs instanceof Array) {
    for (let obj of objs) {
      removePropIfRequired(obj);
    }
  } else if (typeof objs === "object") {
    removePropIfRequired(objs);
  }
  return objs;
};

module.exports.velocityToDisplacement = (velocity, frequency) => {
  return velocity / (Math.PI * frequency);
};

module.exports.displacementToVelocity = (displacement, frequency) => {
  return Math.PI * frequency * displacement;
};

module.exports.velocityToAcceleration = (velocity, frequency) => {
  return (2 * Math.PI * frequency * velocity) / (GRAVITY * 1000);
};
