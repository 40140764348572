import {observable, computed, flow} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";
import {processNewRawFFTData, calculateSpectrumVelocityRSS} from "services/MathService";
import cache from "services/CacheService";
import {
  convertTimeDomainData,
  DomainConversionOptions,
  filterSignal,
  calculatePeakToPeak,
  findMaxPeak,
  FilteringOptions,
} from "sensoteq-core/calculations";
import {ENVELOPE_BAND_PASS_VALUE_LOOKUP} from "sensoteq-core/signal-processing/constants-envelope";
import {filterBandSignal} from "sensoteq-core/calculations";

export default class TimeDomainDataSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      pointId: null,
      getWindowOption: () => this.rootStore.uiStore.timeDomainDataConfig.windowOption,
      getAxis: () => this.rootStore.uiStore.timeDomainDataConfig.axis,
      getKey: () => null,
      getFilterOption: () => null,
      getFilterCutoff: () => null,
      getBandPassHighFilter: () => null,
      getBandPassLowFilter: () => null,
      getFMin: () => null,
      getFMax: () => null,
    };
  }
  getParsedParams(params) {
    return {
      windowOption: params.getWindowOption(),
      axis: params.getAxis(),
      key: params.getKey(params.pointId),
      filterOption: params.getFilterOption(),
      filterCutoff: params.getFilterCutoff(),
      bandPassHighFilter: params.getBandPassHighFilter(),
      bandPassLowFilter: params.getBandPassLowFilter(),
      fMin: params.getFMin(),
      fMax: params.getFMax(),
    };
  }
  getRequiredParamKeys() {
    return ["key"];
  }

  @observable.shallow _data;

  getData = flow(function* ({key}) {
    if (!key) {
      // Wipe data if no key - don't want to leave wrong data visible
      this._data = null;
      return;
    }

    // Don't refetch data for the same key
    if (key.entry_id === this.key?.entry_id) {
      return;
    }

    this.startLoading();
    try {
      let params = [Api.getTimeDomainSet, key.entry_id];
      if (key.version && key.version === 2) {
        params = [Api.getTimeDomainSetV2, key.entry_id, key.max_blocks];
      }

      const data = yield cache(...params);
      this._data = {
        key,
        data: data.time_domain ? data.time_domain.data : null,
      };
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting time domain set: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get sensorInfo() {
    return this.rootStore.sensorStore.getSensorByPointId(this.params.pointId);
  }

  @computed get axis() {
    const hvaAxis = this.params.axis;
    if (!this.sensorInfo) {
      return null;
    } else {
      return this.sensorInfo.getXYZAxis(hvaAxis);
    }
  }

  @computed get isValidPass() {
    return this.params.filterOption != null && this.params.filterCutoff != null && this.params.filterCutoff >= 5;
  }

  @computed get isValidBandLowPass() {
    return (
      this.params.filterOption === 2 &&
      this.params.bandPassLowFilter != null &&
      this.params.bandPassLowFilter >= 5 &&
      (this.params.bandPassHighFilter === null || this.params.bandPassHighFilter <= 5)
    );
  }

  @computed get isValidBandHighPass() {
    return (
      this.params.filterOption === 2 &&
      this.params.bandPassHighFilter != null &&
      this.params.bandPassHighFilter >= 5 &&
      (this.params.bandPassLowFilter === null || this.params.bandPassLowFilter <= 5)
    );
  }

  @computed get isValidBandPass() {
    return (
      this.params.filterOption === 2 &&
      this.params.bandPassLowFilter != null &&
      this.params.bandPassLowFilter >= 5 &&
      this.params.bandPassHighFilter != null &&
      this.params.bandPassHighFilter >= 5
    );
  }

  @computed get signal() {
    if (!this._data?.data) {
      return null;
    }
    if (this.isValidPass) {
      return filterSignal(
        this.params.filterOption,
        this._data.data,
        this.params.filterCutoff,
        this.key.sampling_frequency,
      );
    } else if (this.isValidBandLowPass) {
      return filterSignal(
        FilteringOptions.LOW_PASS,
        this._data.data,
        this.params.bandPassLowFilter,
        this.key.sampling_frequency,
      );
    } else if (this.isValidBandHighPass) {
      return filterSignal(
        FilteringOptions.HIGH_PASS,
        this._data.data,
        this.params.bandPassHighFilter,
        this.key.sampling_frequency,
      );
    } else if (this.isValidBandPass) {
      return filterBandSignal(
        this._data.data,
        this.params.bandPassLowFilter,
        this.params.bandPassHighFilter,
        this.key.sampling_frequency,
      );
    } else {
      return this._data.data;
    }
  }

  @computed get data() {
    if (!this._data) {
      return null;
    }

    return processNewRawFFTData(this.signal, {
      window: this.params.windowOption,
      samplingFrequency: this.key.sampling_frequency,
      fMin: this.params.fMin ?? 5,
      fMax: this.params.fMax ?? 10000,
    });
  }

  @computed get envelope() {
    if (!this._data || this.envelopeOptions.enabled === false) {
      return null;
    }

    return processNewRawFFTData(
      this.signal,
      {
        window: this.params.windowOption,
        samplingFrequency: this.key.sampling_frequency,
        fMin: this.params.fMin ?? 5,
        fMax: this.params.fMin ?? 10000,
      },
      this.envelopeOptions,
    );
  }

  @computed get validData() {
    return this.data != null;
  }

  @computed get envelopeOptions() {
    const envelopConfig = this.rootStore.uiStore.envelopeConfig;
    return {
      enabled: envelopConfig.enabled,
      envelopeMethod: envelopConfig.method,
      ...ENVELOPE_BAND_PASS_VALUE_LOOKUP[envelopConfig.bandPassFilter],
      lowPassFilter: envelopConfig.lowPassFilter,
    };
  }

  _getDataObject() {
    return this.envelopeOptions?.enabled ? this.envelope : this.data;
  }

  @computed get accelerationData() {
    return [{data: this._getDataObject()?.accelerationData}];
  }

  @computed get velocityData() {
    return [{data: this._getDataObject()?.velocityData}];
  }

  @computed get displacementData() {
    return [{data: this._getDataObject()?.displacementData}];
  }

  @computed get timeData() {
    return this.envelopeOptions?.enabled ? [{data: this.envelope?.timeData}] : [{data: this.data?.timeData}];
  }

  @computed get velocityTimeData() {
    return [
      {
        data: convertTimeDomainData(this.data?.timeData, DomainConversionOptions.VELOCITY_DOMAIN),
      },
    ];
  }

  @computed get displacementTimeData() {
    return [
      {
        data: convertTimeDomainData(this.data?.timeData, DomainConversionOptions.DISPLACEMENT_DOMAIN),
      },
    ];
  }

  @computed get displacementPeakToPeakValue() {
    return calculatePeakToPeak(this.displacementTimeData[0].data || [], (x) => x.value);
  }

  @computed get accelerationRPMValue() {
    let maxPeak = findMaxPeak(this.accelerationData[0].data || []);
    return maxPeak.frequency * 60;
  }

  @computed get accelerationRMS() {
    return this.data?.accelerationRMS;
  }

  @computed get envelopeAccRMS() {
    return this.envelope?.accelerationRMS;
  }

  @computed get velocityRMS() {
    if (!this._data) {
      return null;
    }

    let fMax = this.rootStore.preferenceStore.calculationPreferences?.spectrumVelocityRSSFmax ?? null;
    if (fMax === "full") fMax = null;

    return calculateSpectrumVelocityRSS(this.signal, {
      samplingFrequency: this.key.sampling_frequency,
      fMax,
      windowOption: this.params.windowOption,
    });
  }

  @computed get peakToPeak() {
    return this.data?.peakToPeak;
  }

  @computed get crestFactor() {
    return this.data?.crestFactor;
  }

  @computed get key() {
    return this._data?.key;
  }
}
