import {observable, computed, action} from "mobx";
import dayjs from "dayjs";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import {ModelOptions, Ranges} from "routes/cde-tests/cde-screen-types";
import {TIME_OPTION_CUSTOM} from "sensoteq-react-core/constants/enums";
import {CDETestTimes} from "constants/enums";
import {velocityToAcceleration} from "../services/MathService";
import {MeasuringPointPositions} from "sensoteq-core/enumerations";

export default class CDETestSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      testId: null,
    };
  }
  getParsedParams(params) {
    return {
      test: this.rootStore.cdeTestsStore.getTest(params.testId),
    };
  }

  @observable dataSub;
  @observable bearingDataSub;

  @action getData({test}) {
    if (!test) {
      if (this.dataSub) {
        this.dataSub.cancel();
        this.dataSub = null;
      }
      if (this.bearingDataSub) {
        this.bearingDataSub.cancel();
        this.bearingDataSub = null;
      }
      return;
    }

    const machineId = test.metadata.machine_id;

    // Create new screen sub
    if (!this.dataSub || this.dataSub.params.machineId !== machineId) {
      // Clear previous sub
      if (this.dataSub) {
        this.dataSub.cancel();
      }

      // Create new sub
      this.dataSub = this.rootStore.screenDataStore.subscribe(machineId, {recreate: true});
      this.dataSub.update({
        machineId,
        subParams: {
          getTimeOption: () => TIME_OPTION_CUSTOM,
          getTimeRange: () => ({
            from: this.startTime,
            to: this.endTime,
          }),
        },
      });
    }

    // Create new bearing data sub
    if (!this.bearingDataSub || this.bearingDataSub.params.machineId !== machineId) {
      // Clear previous sub
      if (this.bearingDataSub) {
        this.bearingDataSub.cancel();
      }

      // Create new sub
      this.bearingDataSub = this.rootStore.machineDataStore.subscribe(`${machineId}-bearings`, {recreate: true});
      this.bearingDataSub.update({
        machineId,
        positions: this.bearingPositions,
        subParams: {
          getTimeOption: () => TIME_OPTION_CUSTOM,
          getTimeRange: () => ({
            from: this.startTime.subtract(15, "minute"),
            to: this.endTime.add(15, "minute"),
          }),
        },
      });
    }
  }

  @computed get test() {
    return this.rootStore.cdeTestsStore.getTest(this.params.testId);
  }

  @computed get projectTitle() {
    if (!this.test) {
      return null;
    }
    return `${this.test.metadata.project_number}${
      this.test.metadata.project_name ? ` (${this.test.metadata.project_name})` : ""
    }`;
  }

  @computed get screenTitle() {
    if (!this.test) {
      return null;
    }
    const modelOptions = ModelOptions[this.test.metadata.screen_range] || [];
    const model = modelOptions.find((x) => x.value === this.test.metadata.screen_model);
    return model ? `${model.label} #${this.test.metadata.screen_number}` : "Unknown model";
  }

  @computed get startTime() {
    return dayjs(this.test?.timestamp);
  }

  @computed get endTime() {
    if (this.test?.metadata.timestamp_end) {
      return dayjs(this.test?.metadata.timestamp_end);
    }
    return this.startTime.add(8, "hour");
  }

  @computed get duration() {
    const duration = Math.ceil((this.endTime.valueOf() - this.startTime.valueOf()) / (1000 * 60));
    const hours = Math.floor(duration / 60);
    if (hours === 0) {
      return `${duration} minutes`;
    }
    const minutes = duration - hours * 60;
    return `${hours} hours${minutes > 0 ? ` ${minutes} minutes` : ""}`;
  }

  @computed get oneHourTestTargetTime() {
    return this.startTime.add(1, "hour");
  }

  @computed get finalTestTargetTime() {
    return this.endTime;
  }

  @computed get bearingPositions() {
    if (this.test?.metadata.screen_range === Ranges.P_LINE) {
      return [MeasuringPointPositions.LEFT_BEARING, MeasuringPointPositions.RIGHT_BEARING];
    }
    return [MeasuringPointPositions.UPPER_BEARING, MeasuringPointPositions.LOWER_BEARING];
  }

  getTestData(sub, testTime, position, extractData, extractDate) {
    const pointSub = sub?.getSubscriptionByPosition(position);
    if (!pointSub) {
      return null;
    }
    const data = extractData(pointSub);
    if (!data.length) {
      return null;
    }

    // Find nearest set if one hour
    if (testTime === CDETestTimes.ONE_HOUR) {
      let bestSet = null;
      let min = Number.MAX_SAFE_INTEGER;
      data.forEach((set) => {
        const diff = Math.abs(extractDate(set) - this.oneHourTestTargetTime);
        if (diff < min) {
          min = diff;
          bestSet = set;
        }
      });

      // Return nothing if we didn't find any data within a 15 minute window
      if (min > 1000 * 60 * 15) {
        return null;
      }

      return bestSet;
    }

    // Otherwise use last set
    else {
      return data[data.length - 1];
    }
  }

  getEllipseSet(testTime, position) {
    return this.getTestData(
      this.dataSub,
      testTime,
      position,
      (x) => x.validEllipseSets,
      (x) => x.timestamp,
    );
  }

  getBearingTemperature(testTime, position) {
    return this.getTestData(
      this.bearingDataSub,
      testTime,
      position,
      (x) => x.temperatureData[0].data,
      (x) => x.date.valueOf(),
    );
  }

  getBearingRMS(testTime, position) {
    return this.getTestData(
      this.bearingDataSub,
      testTime,
      position,
      (x) => x.accelerationRMSData[0].data,
      (x) => x.date.valueOf(),
    );
  }

  @computed get largestAvgSideBump() {
    // Get average for each position and see which is largest
    let largestAvgSideBump = null;
    this.dataSub.params.positions.forEach((position) => {
      const avgSideBump = this.dataSub?.calculateScreenValue(
        (x) => velocityToAcceleration(x.accelerationData.deflection, x.accelerationData.frequency),
        [position],
        true,
      );
      if (avgSideBump && (largestAvgSideBump == null || avgSideBump > largestAvgSideBump)) {
        largestAvgSideBump = avgSideBump;
      }
    });
    return largestAvgSideBump;
  }

  calculateAverageFeedDelta(selectProperty) {
    return this.dataSub?.calculateDelta(MeasuringPointPositions.LEFT_FEED, selectProperty, true);
  }

  calculateAverageDischargeDelta(selectProperty) {
    return this.dataSub?.calculateDelta(MeasuringPointPositions.LEFT_DISCHARGE, selectProperty, true);
  }

  @computed get passThreshold() {
    if (!this.test) {
      return 15;
    }
    return this.test.metadata.screen_model === "d1_63" ? 22 : 15;
  }

  @computed get testPassed() {
    const deltas = [
      this.calculateAverageFeedDelta((x) => x.displacementData.strokeLength),
      this.calculateAverageFeedDelta((x) => x.displacementData.deltaX),
      this.calculateAverageFeedDelta((x) => x.displacementData.deltaY),
      this.calculateAverageDischargeDelta((x) => x.displacementData.strokeLength),
      this.calculateAverageDischargeDelta((x) => x.displacementData.deltaX),
      this.calculateAverageDischargeDelta((x) => x.displacementData.deltaY),
    ];
    let deltaPassed = true;
    deltas.forEach((value) => {
      if (value == null || value >= this.passThreshold) {
        deltaPassed = false;
      }
    });
    const sideBumpPassed = this.largestAvgSideBump != null && this.largestAvgSideBump < 0.2;
    return deltaPassed && sideBumpPassed;
  }
}
