import {observable, flow} from "mobx";

import LoadableStore from "sensoteq-react-core/models/LoadableStore";
import Api from "sensoteq-react-core/services/api";

export default class ApiDocsStore extends LoadableStore {
  @observable apiData;

  loadData = flow(function* () {
    this.startLoading();
    try {
      const data = yield Api.getAPIDocsData();
      this.apiData = data;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error loading API documentation data: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
