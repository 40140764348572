import {SensorType} from "./sensor.js";
import type {Sensor} from "./sensor.js";

export const OrientationOption = {
  SIDE: "side",
  TOP: "top",
  AXIAL: "axial",
} as const;
export type Orientation = (typeof OrientationOption)[keyof typeof OrientationOption];

export const AxisOption = {
  X: "x",
  Y: "y",
  Z: "z",
} as const;
export type Axis = (typeof AxisOption)[keyof typeof AxisOption];

export const DirectionOption = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
  AXIAL: "axial",
} as const;
export type Direction = (typeof DirectionOption)[keyof typeof DirectionOption];
export type DirectionShort = "h" | "v" | "a";

interface Opts {
  orientation: Orientation;
  sensorType: Sensor;
  strategy: "Y_UP" | "X_UP";
}
export function deriveDirection(opts: Opts & {axis: Axis}): Direction {
  if (opts.sensorType === SensorType.KPX || opts.strategy === "Y_UP") {
    return deriveDirectionForKappaXAndYStrategy(opts.axis, opts.orientation);
  }

  return deriveDirectionForStrategyX(opts.axis, opts.orientation);
}

function deriveDirectionForKappaXAndYStrategy(axis: Axis, orientation: Orientation): Direction {
  if (orientation === OrientationOption.TOP) {
    if (axis === AxisOption.X) return DirectionOption.HORIZONTAL;
    if (axis === AxisOption.Y) return DirectionOption.AXIAL;
    return DirectionOption.VERTICAL;
  }
  if (orientation === OrientationOption.AXIAL) {
    if (axis === AxisOption.X) return DirectionOption.HORIZONTAL;
    if (axis === AxisOption.Y) return DirectionOption.VERTICAL;
    return DirectionOption.AXIAL;
  }

  if (axis === AxisOption.X) return DirectionOption.AXIAL;
  if (axis === AxisOption.Y) return DirectionOption.VERTICAL;
  return DirectionOption.HORIZONTAL;
}

function deriveDirectionForStrategyX(axis: Axis, orientation: Orientation): Direction {
  if (orientation === OrientationOption.AXIAL) {
    if (axis === AxisOption.X) return DirectionOption.VERTICAL;
    if (axis === AxisOption.Y) return DirectionOption.HORIZONTAL;
    return DirectionOption.AXIAL;
  }

  if (orientation === OrientationOption.TOP) {
    if (axis === AxisOption.X) return DirectionOption.AXIAL;
    if (axis === AxisOption.Y) return DirectionOption.HORIZONTAL;
    return DirectionOption.VERTICAL;
  }

  if (axis === AxisOption.X) return DirectionOption.VERTICAL;
  if (axis === AxisOption.Y) return DirectionOption.AXIAL;
  return DirectionOption.HORIZONTAL;
}

export function deriveAxis(opts: Opts & {direction: Direction}): Axis {
  if (opts.sensorType === SensorType.KPX || opts.strategy === "Y_UP") {
    return deriveAxisForKappaXAndYStrategy(opts.direction, opts.orientation);
  }

  return deriveAxisForStrategyX(opts.direction, opts.orientation);
}

function deriveAxisForKappaXAndYStrategy(direction: Direction, orientation: Orientation): Axis {
  if (orientation === OrientationOption.AXIAL) {
    if (direction === DirectionOption.HORIZONTAL) return AxisOption.X;
    if (direction === DirectionOption.VERTICAL) return AxisOption.Y;
    return AxisOption.Z;
  }

  if (orientation === OrientationOption.TOP) {
    if (direction === DirectionOption.HORIZONTAL) return AxisOption.X;
    if (direction === DirectionOption.VERTICAL) return AxisOption.Z;
    return AxisOption.Y;
  }

  if (direction === DirectionOption.HORIZONTAL) return AxisOption.Z;
  if (direction === DirectionOption.VERTICAL) return AxisOption.Y;
  return AxisOption.X;
}

function deriveAxisForStrategyX(direction: Direction, orientation: Orientation): Axis {
  if (orientation === OrientationOption.AXIAL) {
    if (direction === DirectionOption.HORIZONTAL) return AxisOption.Y;
    if (direction === DirectionOption.VERTICAL) return AxisOption.X;
    return AxisOption.Z;
  }

  if (orientation === OrientationOption.TOP) {
    if (direction === DirectionOption.HORIZONTAL) return AxisOption.Y;
    if (direction === DirectionOption.VERTICAL) return AxisOption.Z;
    return AxisOption.X;
  }

  if (direction === DirectionOption.HORIZONTAL) return AxisOption.Z;
  if (direction === DirectionOption.VERTICAL) return AxisOption.X;
  return AxisOption.Y;
}

export function getShortDirection(direction: Direction): DirectionShort {
  if (direction === "axial") return "a";
  if (direction === "vertical") return "v";
  return "h";
}

export function getFullDirection(direction: DirectionShort): Direction {
  if (direction === "a") return "axial";
  if (direction === "v") return "vertical";
  return "horizontal";
}
