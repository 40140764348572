import {observable, computed, flow} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class EventSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      target: null,
    };
  }
  getRequiredParamKeys() {
    return ["target"];
  }

  @observable.shallow _events;

  @computed get events() {
    if (!this._events) {
      return [];
    }
    return this._events.slice();
  }

  getData = flow(function* ({target}) {
    if (!target) {
      return;
    }

    this.startLoading();
    try {
      const results = yield Api.getEvents(target);
      this._events = results.events;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting events: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get continuousDataEvents() {
    const dates = this.rootStore.uiStore.continuousDataDates;
    const from = dates.from.valueOf();
    const to = dates.to.valueOf();
    return this.events.filter((x) => x.timestamp >= from && x.timestamp <= to);
  }
}
