import React from "react";

function useCloseOnClick(containerRef, onOpen, onClose) {
  const visible = React.useRef(false);
  // Same property, just stored in state to trigger updates
  const [isVisible, setIsVisible] = React.useState(false);

  // Callback to toggle visible state
  const changeVisibleState = React.useCallback(
    (willBeVisible) => {
      if (!visible.current && willBeVisible) {
        onOpen && onOpen();
      } else if (visible.current && !willBeVisible) {
        onClose && onClose();
      }
      visible.current = willBeVisible;
      setIsVisible(willBeVisible);
    },
    [onOpen, onClose],
  );

  // Callback to handle document clicks
  const onDocumentClick = React.useCallback((event) => {
    const {target} = event;
    const container = containerRef.current;
    let parent = target;
    while (container && container !== parent && parent !== document.body && parent.parentNode !== null) {
      parent = parent.parentNode;
    }

    if (parent === container) {
      return;
    }

    if (!visible.current) {
      return;
    }

    close();
  }, []);

  // Callback to handle close event
  const close = React.useCallback(() => {
    changeVisibleState(false);
    setTimeout(() => {
      document.removeEventListener("click", onDocumentClick);
      document.removeEventListener("touchstart", onDocumentClick);
    }, 150);
  }, []);

  // Callback to handle open event
  const open = React.useCallback(() => {
    changeVisibleState(true);
    setTimeout(() => {
      document.addEventListener("click", onDocumentClick);
      document.addEventListener("touchstart", onDocumentClick);
    }, 1);
  }, []);

  React.useEffect(() => {
    return () => {
      document.removeEventListener("click", onDocumentClick);
      document.removeEventListener("touchstart", onDocumentClick);
    };
  }, []);

  return {
    open,
    close,
    isOpen: isVisible,
  };
}

export default useCloseOnClick;
