import LoadingAnimation from "sensoteq-react-core/components/LoadingAnimation";
import * as Styled from "./LoadingPlaceholder.styles";

export default function LoadingPlaceholder() {
  return (
    <Styled.Page>
      <Styled.TopBar />
      <Styled.Content>
        <LoadingAnimation showBackground={false} />
      </Styled.Content>
    </Styled.Page>
  );
}
