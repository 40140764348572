import {lazy} from "react";

import {createRoute} from "sensoteq-react-core/lib/mobx-state-router";
import {mapContinuousDataConfigQueryToState, mapContinuousDataConfigStateToQuery} from "routes/utils";

const NotFoundPage = lazy(() => import("routes/not-found/NotFoundPage"));
const ReportsPage = lazy(() => import("routes/reports/ReportsPage"));
const SettingsPage = lazy(() => import("routes/settings/SettingsPage"));
const AlarmsPage = lazy(() => import("routes/alarms/AlarmsPage"));
const ForgotPasswordPage = lazy(() => import("routes/forgot-password/ForgotPasswordPage"));
const ResetPasswordPage = lazy(() => import("routes/reset-password/ResetPasswordPage"));
const AnalysisPage = lazy(() => import("routes/analysis/AnalysisPage"));
const SitePage = lazy(() => import("routes/site/SitePage"));
const PointPage = lazy(() => import("routes/point/PointPage"));
const LoginPage = lazy(() => import("routes/login/LoginPage"));
const MapPage = lazy(() => import("routes/map/MapPage"));
const MachinePage = lazy(() => import("routes/machine/MachinePage"));
const ApiDocsPage = lazy(() => import("routes/api-docs/ApiDocsPage"));
const SupportCenterPage = lazy(() => import("routes/support-center/SupportCenterPage"));
const UserGuidePage = lazy(() => import("routes/user-guide/UserGuidePage"));
const NeighbourhoodWatchPage = lazy(() => import("routes/neighbourhood-watch/NeighbourhoodWatchPage"));
const CDETestsPage = lazy(() => import("routes/cde-tests/CDETestsPage"));
const ThermalPointPage = lazy(() => import("routes/thermal-point/ThermalPointPage"));
const BespokePage = lazy(() => import("routes/bespoke/BespokePage"));
const ChiPage = lazy(() => import("routes/chi/ChiPage"));
const FaultLabellingPage = lazy(() => import("routes/fault-labelling/FaultLabellingPage"));

const SitePageV2 = lazy(async () => {
  return {default: (await import("routes/v2/site/SitePage")).SitePage};
});
const MachinePageV2 = lazy(() => import("routes/v2/machine/MachinePage"));

export default {
  LoginPage: createRoute({
    path: "/login",
    component: LoginPage,
    mapStateToQuery: (rootStore) => ({
      redirect: rootStore.uiStore.loginRedirect,
    }),
    mapQueryToState: (rootStore, queryParams) => {
      rootStore.uiStore.setLoginRedirect(queryParams.redirect);
    },
    hookParams: {
      requireLogout: true,
    },
  }),

  ForgotPasswordPage: createRoute({
    path: "/forgot",
    component: ForgotPasswordPage,
    hookParams: {
      requireLogout: true,
    },
  }),

  ResetPasswordPage: createRoute({
    path: "/reset",
    component: ResetPasswordPage,
    mapQueryToState: (rootStore, queryParams) => {
      rootStore.uiStore.setPasswordResetToken(queryParams.token);
    },
    hookParams: {
      requireLogout: true,
    },
  }),

  NotFoundPage: createRoute({
    path: "/404",
    component: NotFoundPage,
    hookParams: {
      requireLogin: true,
    },
  }),

  MapPage: createRoute({
    path: "/map",
    component: MapPage,
    hookParams: {
      requireLogin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  ChiPage: createRoute({
    path: "/chi",
    component: ChiPage,
    hookParams: {
      requireLogin: true,
      requireMFA: true,
    },
  }),

  SitePage: createRoute({
    path: "/site/:site/:tab?",
    component: SitePage,
    hookParams: {
      requireLogin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  V2SitePage: createRoute({
    path: "/v2/site/:site/:tab?",
    component: SitePageV2,
    hookParams: {
      requireLogin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  PointPage: createRoute({
    path: "/point/:point/:tab?",
    component: PointPage,
    hookParams: {
      requireLogin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  ThermalPointPage: createRoute({
    path: "/thermal/:point/:tab?",
    component: ThermalPointPage,
    hookParams: {
      requireLogin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  MachinePage: createRoute({
    path: "/machine/:machine/:tab?",
    component: MachinePage,
    hookParams: {
      requireLogin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  V2MachinePage: createRoute({
    path: "/v2/machine/:machine/overview",
    component: MachinePageV2,
    mapQueryToState: mapContinuousDataConfigQueryToState,
    mapStateToQuery: mapContinuousDataConfigStateToQuery,
    hookParams: {
      requireLogin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),
  V2MachineAutoanalysisPage: createRoute({
    path: "/v2/machine/:machine/diagnostix/:faultId?",
    component: MachinePageV2,
    hookParams: {
      requireLogin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  AlarmsPage: createRoute({
    path: "/alarms/:tab?/:site?",
    component: AlarmsPage,
    hookParams: {
      requireLogin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  NeighbourhoodWatchPage: createRoute({
    path: "/neighbourhood-watch/:tab?/:type?",
    component: NeighbourhoodWatchPage,
    hookParams: {
      requireLogin: true,
      requireCompany: "Sensoteq",
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  ReportsPage: createRoute({
    path: "/reports/:tab?/:name?/:version?",
    component: ReportsPage,
    hookParams: {
      requireLogin: true,
      requireMFA: true,
    },
  }),

  SettingsPage: createRoute({
    path: "/settings/:tab?",
    component: SettingsPage,
    hookParams: {
      requireLogin: true,
    },
  }),

  AnalysisPage: createRoute({
    path: "/analysis",
    component: AnalysisPage,
    hookParams: {
      requireLogin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  ApiDocsPage: createRoute({
    path: "/api-docs",
    component: ApiDocsPage,
    hookParams: {
      requireLogin: true,
      requireApiAccess: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  SupportCenterPage: createRoute({
    path: "/support-center",
    component: SupportCenterPage,
    hookParams: {
      requireLogin: true,
      requireMFA: true,
    },
  }),

  UserGuidePage: createRoute({
    path: "/user-guide",
    component: UserGuidePage,
    hookParams: {
      requireLogin: true,
      requireMFA: true,
    },
  }),

  CDETestsPage: createRoute({
    path: "/cde-tests/:tab?",
    component: CDETestsPage,
    hookParams: {
      requireLogin: true,
      requireCompany: "CDE Global",
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  BespokePage: createRoute({
    path: "/bespoke",
    component: BespokePage,
    hookParams: {
      requireLogin: true,
      requireAdmin: true,
      requireIsNotChiOnlyUser: true,
      requireMFA: true,
    },
  }),

  FaultLabellingPage: createRoute({
    path: "/fault-labelling",
    component: FaultLabellingPage,
    hookParams: {
      requireLogin: true,
      requireCanLabelFaults: true,
      requireMFA: true,
    },
  }),
};
