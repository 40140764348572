import {css} from "styled-components";

import {Colors} from "components/layout/Branding";
import {DESIGN_FONT_SIZE} from "constants/enums";
import {getKey} from "sensoteq-react-core/constants/environment-config";

export default function (assign) {
  assign("fonts", () => ({
    feature: '"Montserrat", sans-serif',
    default: '"Roboto", sans-serif',
  }));

  assign("dimensions", () => ({
    // General layout dimensions
    mainMenuWidth: 21, // Width of main menu
    mainMenuCollapsedWidth: 7, // Width of main menu when collapsed
    topBarHeight: 6.4, // Height of top bar
    topBarMinWidth: 30, // Minimum width of top bar before it looks weird
    minWidth: 36, // Min width of HMI

    // Dimensions for page content components
    minColWidth: 32, // Minimum content column width
    maxColWidth: 40, // Maximum content column width
    hMargin: 2, // Horizontal page margin
    vMargin: 2, // Vertical page margin
    notificationWidth: 34, // Width of notifications
    hPagePadding: 0.5, // Additional padding around page content

    // V2 Site Overview Layout
    titleBarHeight: 7.5,

    // Minimum width of content before reverting to mobile styles
    get contentMobileBreakPoint() {
      return this.minColWidth + 2 * this.hMargin;
    },

    // Minimum browser width before reverting to mobile styles
    get mobileBreakPoint() {
      return this.contentMobileBreakPoint + Math.max(this.mainMenuWidth, this.topBarMinWidth);
    },
  }));

  assign("mixins", (theme) => ({
    mobile: (styles) => css`
      @media (max-width: ${theme.dimensions.mobileBreakPoint * DESIGN_FONT_SIZE}px) {
        ${styles};
      }
    `,

    desktop: (styles) => css`
      @media (min-width: ${theme.dimensions.mobileBreakPoint * DESIGN_FONT_SIZE + 1}px) {
        ${styles};
      }
    `,

    textShadow: (colour) => css`
      text-shadow:
        -0.1rem -0.1rem 0 ${colour},
        -0.1rem 0.1rem 0 ${colour},
        0.1rem -0.1rem 0 ${colour},
        0.1rem 0.1rem 0 ${colour};
    `,

    isIOS: (styles) => css`
      @supports (-webkit-overflow-scrolling: touch) {
        ${styles};
      }
    `,

    maxWidth: (width, styles) => css`
      @media (max-width: ${width}px) {
        ${styles};
      }
    `,
  }));

  assign("branding", () => ({
    companyLogo: false,
    companyName: false,
    companyLogoOverrideURL: null,
    analytixColor: Colors.WhiteRed,
  }));

  assign("colours", (theme) => ({
    mapBackground: "#d4dadc",
    topBarBackground: "white",
    mainMenuBackground: `hsl(${theme.hues.default}, 15%, 20%)`,
    mainMenuHighlight: theme.colours.accent,
    mainMenuText: `hsla(${theme.hues.default}, 16%, 60%)`,
    mainMenuTextOnHover: "white",
    mainMenuTextOnActive: "white",
  }));

  assign("misc", () => ({
    tileURL: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
    tileFilter: "brightness(0.9) contrast(1.3) saturate(1.5)",
    userGuideURL: getKey("analytix_user_guide"),
    chiUserGuideURL: "http://assets.sensoteq.com/20022_01_User_Guide.pdf",
  }));
}
