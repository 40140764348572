import styled, {css} from "styled-components";

export const LoadingContainer = styled.div`
  position: relative;
  border-radius: ${(props) => props.theme.forms.borderRadius};
  box-shadow: ${(props) => props.theme.forms.boxShadow};
  background-color: ${(props) => props.theme.colours.contentBackground};
`;

export const Container = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  border-radius: ${(props) => props.theme.forms.borderRadius};
  box-shadow: ${(props) => props.theme.forms.boxShadow};
  position: relative;

  ${(props) =>
    props.grow &&
    css`
      flex: 1 0 auto;
    `}

  ${(props) =>
    props.sticky &&
    css`
      position: fixed;
      height: calc(100% - ${(props) => props.theme.dimensions.topBarHeight + props.theme.dimensions.vMargin * 2}rem);
      width: ${(props) => props.theme.dimensions.maxColWidth}rem;
    `}
`;

export const TitleContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-top-left-radius: ${(props) => props.theme.forms.borderRadius};
  border-top-right-radius: ${(props) => props.theme.forms.borderRadius};
  flex-wrap: wrap;
  padding: 0 ${(props) => props.theme.dimensions.hPadding}rem 0 0;
  overflow: hidden;

  ${(props) =>
    !props.padded &&
    css`
      padding-bottom: ${(props) => props.theme.dimensions.vPadding}rem;
    `}

  ${(props) =>
    props.border &&
    css`
      border-bottom: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
    `}
  
  >* {
    padding: ${(props) => props.theme.dimensions.vPadding}rem 0 0 ${(props) => props.theme.dimensions.hPadding}rem;
  }
`;

export const TitleText = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  width: 0;
  min-width: 16rem;
`;

export const Title = styled.div`
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: ${(props) => props.theme.fontSizes.verySmall};
  align-items: center;
  font-weight: 700;
`;

export const Subtitle = styled.div`
  flex: 0 1 auto;
  color: ${(props) => props.theme.colours.contentLight};
  margin-top: 0.3rem;
`;

export const TitleActions = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  margin-top: -0.5rem;
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  flex: 0 1 auto;
  flex-wrap: wrap;

  > * {
    margin-right: 0.8rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const TitleIcons = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0 0 0 0.8rem;

  ${(props) =>
    props.theme.mixins.mobile(css`
      display: none;
    `)};
`;

export const Body = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: ${(props) => props.theme.dimensions.vPadding}rem ${(props) => props.theme.dimensions.hPadding}rem;

  > * {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    !props.padded &&
    css`
      padding: 0;
    `}
`;

export const FullScreenWrapper = styled.div`
  position: absolute;
  width: calc(100% - 2 * ${(props) => props.theme.dimensions.hMargin}rem);
  height: calc(100% - ${(props) => props.theme.dimensions.topBarHeight + 2 * props.theme.dimensions.vMargin}rem);
  left: 0;
  top: ${(props) => props.theme.dimensions.topBarHeight}rem;
  background-color: rgba(0, 0, 0, 0.1);
  padding: ${(props) => `${props.theme.dimensions.vMargin}rem ${props.theme.dimensions.hMargin}rem`};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  z-index: 999;

  ${Container} {
    flex: 1 1 auto !important;
  }
`;

export const ZeroHeightWrapper = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  min-height: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  ${(props) =>
    props.padded &&
    css`
      margin: 0 ${(props) => -1 * props.theme.dimensions.hPadding}rem;
      padding: 0 ${(props) => props.theme.dimensions.hPadding}rem;
    `}

  >div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1 1 auto;
    height: 0;
  }

  ${(props) =>
    props.theme.mixins.mobile(css`
      > div {
        height: auto;
      }
    `)}
`;
