import {flow, computed, observable} from "mobx";

import AlarmSubscription from "models/AlarmSubscription";
import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import Api from "sensoteq-react-core/services/api";
import {AlarmTargetTypes, TemplateType, SensorOrientation} from "sensoteq-core/enumerations";
import {AlarmPropertyLookupMap} from "sensoteq-react-core/constants/lookups";

export default class AlarmStore extends SubscribableStore {
  @observable.shallow _templates;

  constructor(rootStore) {
    super(rootStore, AlarmSubscription);
  }

  @computed get templates() {
    return this._templates ?? [];
  }

  parseAlarmTarget(target, targetType = this.getAlarmTargetType(target)) {
    const {pointStore, sensorStore} = this.rootStore;
    let deviceName, pointId, machineId, orientation, sensorType;

    if (targetType === AlarmTargetTypes.DEVICE) {
      deviceName = target;
    } else if (targetType === AlarmTargetTypes.MEASURING_POINT) {
      pointId = target;
      const point = pointStore.getTestPoint(target);
      if (point) {
        machineId = point.machine_id;
        const sensor = sensorStore.getSensorByPointId(target);
        if (sensor) {
          orientation = sensor.orientation ?? SensorOrientation.SIDE;
          sensorType = sensor.type;
        }
      }
    } else if (targetType === AlarmTargetTypes.MACHINE) {
      machineId = target;
    }

    return {
      type: targetType || "Unknown",
      deviceName,
      machineId,
      pointId,
      orientation,
      sensorType,
    };
  }

  getAlarmTargetType(target) {
    if (target == null) {
      return null;
    }
    const {pointStore, machineStore} = this.rootStore;

    if (target.indexOf("sensoteq-receiver") !== -1) {
      return AlarmTargetTypes.DEVICE;
    } else if (pointStore.pointExists(target)) {
      return AlarmTargetTypes.MEASURING_POINT;
    } else if (machineStore.machineExists(target)) {
      return AlarmTargetTypes.MACHINE;
    }

    return null;
  }

  async createAlarm(type, site, target, email, sms, users, property, operator, triggerStrategy, values, targetType) {
    this.startLoading();
    try {
      const result = await Api.createAlarm(
        type,
        site,
        target,
        email,
        sms,
        users,
        property,
        operator,
        triggerStrategy,
        values,
        targetType,
      );
      await this.sync(result.alarm_id);
      this.rootStore.notificationStore.addNotification("Alarm created successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error creating alarm: ${error}`, "bad");
    }
    this.stopLoading();
  }

  async retrieve(alarmId) {
    try {
      const data = await Api.getAlarms(undefined, undefined, alarmId);
      if (data.alarms.length) {
        return data.alarms[0];
      } else {
        return null;
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting alarm: ${error}`, "bad");
    }
  }

  async updateAlarm(alarmId, name, operator, triggerStrategy, values, siteName) {
    this.startLoading();
    try {
      await Api.updateAlarm(alarmId, name, operator, triggerStrategy, values, siteName);
      await this.sync(alarmId);
      this.rootStore.notificationStore.addNotification("Alarm updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating alarm: ${error}`, "bad");
    }
    this.stopLoading();
  }

  async updateAlarmNotification(
    alarmId,
    sms,
    email,
    usernamesToRemoveFromNotifications,
    usernamesToAddToNotifications,
    siteName,
  ) {
    this.startLoading();
    try {
      await Api.updateAlarmNotification(
        alarmId,
        sms,
        email,
        usernamesToRemoveFromNotifications,
        usernamesToAddToNotifications,
        siteName,
      );
      await this.sync(alarmId);
      this.rootStore.notificationStore.addNotification("Alarm updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating alarm: ${error}`, "bad");
    }
    this.stopLoading();
  }

  async deleteAlarm(alarmId, siteName) {
    this.startLoading();
    try {
      await Api.deleteAlarm(alarmId, siteName);
      await this.sync(alarmId);
      this.rootStore.notificationStore.addNotification("Alarm deleted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting alarm: ${error}`, "bad");
    }
    this.stopLoading();
  }

  async bulkUpdateAlarmLevels(siteName, targets, properties, percentage) {
    this.startLoading();
    try {
      await Api.bulkUpdateAlarmLevels(siteName, targets, properties, percentage);
      await this.refreshSubscriptions();
      this.rootStore.notificationStore.addNotification("Alarm levels updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating alarm levels: ${error}`, "bad");
    }
    this.stopLoading();
  }

  async bulkUpdateAlarmNotifications(siteName, targets, usersToAdd, usersToRemove, sendEmail, sendSms, properties) {
    this.startLoading();
    try {
      await Api.bulkUpdateAlarmNotifications(
        siteName,
        targets,
        usersToAdd,
        usersToRemove,
        sendEmail,
        sendSms,
        properties,
      );
      await this.refreshSubscriptions();
      this.rootStore.notificationStore.addNotification("Alarm notification settings updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating alarm notification settings: ${error}`, "bad");
    }
    this.stopLoading();
  }

  async bulkDeleteAlarms(siteName, targets, properties) {
    this.startLoading();
    try {
      await Api.bulkDeleteAlarms(siteName, targets, properties);
      await this.refreshSubscriptions();
      this.rootStore.notificationStore.addNotification("Alarms deleted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting alarms: ${error}`, "bad");
    }
    this.stopLoading();
  }

  loadTemplates = flow(function* () {
    this.startLoading();
    try {
      const templates = yield Api.getTemplates(TemplateType.ALARM);
      this._templates = templates.templates;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error loading alarm templates: ${error}`, "bad");
    }
    this.stopLoading();
  });

  runTemplate = flow(function* (template, measuringPointIds, usernames, siteName) {
    this.startLoading();
    try {
      yield Api.runTemplate(TemplateType.ALARM, {
        ...template,
        m_point_ids: measuringPointIds,
        usernames,
        site_name: siteName,
        target_type: AlarmTargetTypes.MEASURING_POINT,
      });
      this.rootStore.notificationStore.addNotification("Alarms created successfully", "good");
    } catch (error) {
      const {pointStore, machineStore} = this.rootStore;

      const numberOfFailedCreatedAlarms = error.alarmsFailedToCreate.length;
      let alarmAtPoint = "";
      for (let i = 0; i < numberOfFailedCreatedAlarms; i++) {
        let isLastObject = i === numberOfFailedCreatedAlarms - 1;

        // Retrieve property, measuring point and machine information.
        let alarmPropertyLabel = AlarmPropertyLookupMap[error.alarmsFailedToCreate[i].property];

        let measuringPointID = error.alarmsFailedToCreate[i].pointID;
        let measuringPoint = pointStore.getTestPoint(measuringPointID);
        let measuringPointName = measuringPoint.name;

        let machineID = measuringPoint.machine_id;
        let machine = machineStore.getMachine(machineID);
        let machineName = machine.name;

        // Identifying the alarm at the corresponding measuring point.
        alarmAtPoint += alarmPropertyLabel + " @ " + machineName + " ➔ " + measuringPointName;

        // Adding appropriate punctuation.
        alarmAtPoint += isLastObject ? "." : ", ";
      }
      this.rootStore.notificationStore.addNotification(
        `Error creating alarms: ${error.errorMessage} ${alarmAtPoint}`,
        "bad",
      );
    }
    this.stopLoading();
  });
}
