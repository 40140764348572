import React, {useEffect, useMemo} from "react";
import {observer} from "mobx-react";
import {useRootStore} from "stores";
import {useIsTablet} from "components/hooks/useBreakpoints";
import {ReportDock} from "components/layout/ReportDock";
import matchPath from "sensoteq-react-core/lib/mobx-state-router/matchPath";
import appRoutes from "routes/app-routes";
import RouterStore from "stores/RouterStore";
import UIStore from "stores/UIStore";

const typedMatchPath = matchPath as (
  pathname: string,
  options?: Record<string, unknown>,
) => {path: string; url: string; isExact: boolean; params: Record<string, string>} | null;

// TODO: Add types for routes
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const {SitePage, MachinePage, PointPage, V2SitePage, V2MachinePage} = appRoutes;
const PERMITTED_ROUTES = [SitePage, MachinePage, PointPage, V2SitePage, V2MachinePage];

const isRouteActive = (path = "", exact = false) => {
  const match = typedMatchPath(location.pathname, {
    path,
    exact,
  });
  return match != null;
};

const useIsReportDockVisible = () => {
  const {routerStore, uiStore} = useRootStore() as unknown as {routerStore: RouterStore; uiStore: UIStore};

  const isReportDockVisible = useMemo(() => {
    if (!routerStore.state.location.pathname) return;
    return PERMITTED_ROUTES.some((route: {path: string}) => isRouteActive(route.path));
  }, [routerStore.state.location.pathname]);

  useEffect(() => {
    if (!isReportDockVisible) {
      uiStore.setShowDockedReportEditor(false);
    }
  }, [isReportDockVisible, uiStore]);

  return isReportDockVisible;
};

const ReportWrapper = observer(({children}: {children: React.ReactNode | React.ReactNode[]}) => {
  const isTablet = useIsTablet();
  const isVisible = useIsReportDockVisible();

  return (
    <div className="relative flex flex-row flex-nowrap h-full w-full">
      {children}
      {!isTablet && isVisible && <ReportDock />}
    </div>
  );
});

export {useIsReportDockVisible, ReportWrapper};
