import {observable, computed, flow} from "mobx";

import Api from "sensoteq-react-core/services/api";
import LoadableStore from "sensoteq-react-core/models/LoadableStore";
import {MeasuringPointPositionLookupMap, MeasuringPointStepLookupMap} from "sensoteq-react-core/constants/lookups";
import {sortArrayByProperty} from "sensoteq-react-core/services/utils";

export default class MachineStore extends LoadableStore {
  @observable.shallow _machines;

  @computed get machines() {
    if (!this._machines) {
      return [];
    }
    const {preferenceStore} = this.rootStore;
    let machines = this._machines.map((machine, idx) => {
      // Enrich test points
      let testPoints = (machine.test_points || []).map((point, idx) => {
        let label =
          point.name ??
          `${MeasuringPointStepLookupMap[point.step]} - ${MeasuringPointPositionLookupMap[point.position]}`;

        // Name obscuring
        if (preferenceStore.obscureNames) {
          label = `Point ${idx + 1}`;
        }

        return {
          ...point,
          label,
        };
      });
      sortArrayByProperty(testPoints, "name");

      // Enrich thermal points
      let thermalPoints = (machine.thermal_points || []).map((point) => {
        return {
          ...point,
          label: point.name,
        };
      });
      sortArrayByProperty(thermalPoints, "name");

      // Enrich machine
      let enrichedMachine = {
        ...machine,
        label: machine.name,
        sensors: machine.sensors || [],
        devices: machine.devices || [],
        test_points: testPoints,
        thermal_points: thermalPoints,
        all_points: sortArrayByProperty(testPoints.concat(thermalPoints), "name"),
      };

      // Name obscuring
      if (preferenceStore.obscureNames) {
        enrichedMachine.label = `Machine ${idx + 1}`;
      }

      return enrichedMachine;
    });
    sortArrayByProperty(machines, "name");
    return machines;
  }

  @computed get _reverseLookupMap() {
    // Allow raw access fake machine
    let lookupMap = {};
    this.machines.forEach((machine) => {
      lookupMap[machine.machine_id] = machine;
    });

    return lookupMap;
  }

  @computed get _deviceIdReverseLookupMap() {
    let lookupMap = {};
    this.machines.forEach((machine) => {
      machine.devices.forEach((device) => {
        machine.sensors.forEach((sensor) => {
          lookupMap[`${device.name}/${sensor.sensor_id}`] = machine.machine_id;
        });
      });
    });
    return lookupMap;
  }

  machineExists(machineId) {
    return this.getMachine(machineId) != null;
  }

  getMachine(machineId) {
    return this._reverseLookupMap[machineId];
  }

  getMachineByDevice(deviceName, sensorId) {
    const id = this._deviceIdReverseLookupMap[`${deviceName}/${sensorId}`];
    if (id == null) {
      return null;
    }
    return this.getMachine(id);
  }

  getPointsForMachine(machineId) {
    if (machineId == null) {
      return [];
    }
    const machineInfo = this.machines.find((x) => x.machine_id === machineId);
    if (machineInfo) {
      return machineInfo.test_points;
    }
    return [];
  }

  loadMachines = flow(function* (showLoading = true) {
    this.startFetching();
    if (showLoading) {
      this.startLoading();
    }
    try {
      const machines = yield Api.getMachines();
      this._machines = machines.machines;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting machines: ${error}`, "bad");
    }
    if (showLoading) {
      this.stopLoading();
    }
    this.stopFetching();
  });

  refreshMachine = flow(function* (machineId, siteName = this.getMachine(machineId).site_name) {
    try {
      const data = yield Api.getMachine(siteName, machineId);
      const newMachine = data.machines[0];
      const index = this._machines.findIndex((x) => x.machine_id === machineId);
      if (newMachine) {
        if (index === -1) {
          this._machines.push(newMachine);
        } else {
          this._machines[index] = newMachine;
        }
      } else if (index !== -1) {
        this._machines.splice(index, 1);
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error refreshing machine: ${error}`, "bad");
    }
  });

  createMachine = flow(function* (siteName, name, machine_type_id, machine_subtype_id, machine_subtype_diagram_id) {
    this.startLoading();
    try {
      const result = yield Api.createMachine(
        name,
        siteName,
        machine_type_id,
        machine_subtype_id,
        machine_subtype_diagram_id,
      );
      this.rootStore.notificationStore.addNotification("Machine created successfully", "good");
      yield this.refreshMachine(result.machine_id, siteName);
      return result;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error creating machine: ${error}`, "bad");
    } finally {
      this.stopLoading();
    }
  });

  deleteMachine = flow(function* (siteName, machineId) {
    this.startLoading();
    try {
      yield Api.deleteMachine(siteName, machineId);
      this.rootStore.notificationStore.addNotification("Machine deleted successfully", "good");
      this.refreshMachine(machineId, siteName);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting machine: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateMachine = flow(
    function* (
      siteName,
      machineId,
      name,
      machine_type_id,
      machine_subtype_id,
      machine_subtype_diagram_id,
      updateBands,
      notes,
    ) {
      this.startLoading();
      try {
        yield Api.updateMachine(
          siteName,
          machineId,
          name,
          machine_type_id,
          machine_subtype_id,
          machine_subtype_diagram_id,
          updateBands,
          notes,
        );
        this.rootStore.notificationStore.addNotification("Machine updated successfully", "good");
        this.refreshMachine(machineId, siteName);
      } catch (error) {
        this.rootStore.notificationStore.addNotification(`Error updating machine: ${error}`, "bad");
      }
      this.stopLoading();
    },
  );

  updateMachineMetadata = flow(function* (machineId, metadata) {
    this.startLoading();
    try {
      yield Api.updateMachineMetadata(machineId, metadata);
      this.rootStore.notificationStore.addNotification("Machine metadata updated successfully", "good");
      this.refreshMachine(machineId);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating machine metadata: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateMachineLocations = flow(function* (machineId, locations) {
    this.startLoading();
    try {
      yield Api.updateMachineLocations(machineId, locations);
      this.rootStore.notificationStore.addNotification("Machine locations updated successfully", "good");
      this.refreshMachine(machineId);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating machine locations: ${error}`, "bad");
    }
    this.stopLoading();
  });

  resetMachinePowerBands = flow(function* (machineId) {
    this.startLoading();
    try {
      yield Api.resetMachinePowerBands(machineId);
      this.rootStore.notificationStore.addNotification("Machine power bands reset successfully", "good");
      this.refreshMachine(machineId);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error resetting machine power bands: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
