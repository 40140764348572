import {observable, flow, computed} from "mobx";

import Api from "sensoteq-react-core/services/api";
import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import BluetoothDataSet from "./BluetoothDataSet";
import cache from "services/CacheService";

export default class ChiDataSetSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      dataId: null,
      session: null,
      metadata: {
        frequency: 409.6,
      },
    };
  }
  getRequiredParamKeys() {
    return ["dataId"];
  }

  @observable.shallow data;

  @computed get dataSet() {
    if (!this.data) {
      return null;
    }
    return new BluetoothDataSet(this.data, this.params.metadata.frequency, this.params.session.fft_size, {
      hammerTrigger: 50,
      hammerRemovePointsAtStart: 8,
      hammerSamples: 1024,
      hammerMinFrequency: 5,
      hammerMaxFrequency: this.params.metadata.frequency === 409.6 ? 50 : 250,
      hammerSNR: 1,
    });
  }

  getData = flow(function* ({dataId}) {
    if (!dataId) {
      return null;
    }
    this.startLoading();
    try {
      const data = yield cache(Api.getChiDataSet, dataId);
      this.data = data.chi_data;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting Chi data set: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
