import styled, {css} from "styled-components";

import {ContentRow} from "./ContentRow.styles";

export const ContentColumn = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  max-width: ${(props) => props.maxWidth ?? props.theme.dimensions.maxColWidth}rem;
  min-width: ${(props) => props.minWidth ?? props.theme.dimensions.minColWidth}rem;
  position: relative;

  > * {
    margin: ${(props) => props.theme.dimensions.vMargin}rem 0 0 ${(props) => props.theme.dimensions.hMargin}rem;

    &${ContentRow} {
      margin: 0;
    }
  }

  ${(props) =>
    props.shrink &&
    css`
      min-width: 0;
      flex: 0 1 auto;
    `}

  ${(props) =>
    props.grow &&
    css`
      max-width: none;
    `}
  
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props.wide &&
    css`
      max-width: ${(props) => props.theme.dimensions.maxColWidth * 2}rem;
    `}
  
  ${(props) =>
    props.theme.mixins.mobile(css`
      max-width: none;
      min-width: ${(props) => props.theme.dimensions.minColWidth}rem;
    `)}
`;
