import React, {Suspense} from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react";

import {useRouteInfo, useRouterStore} from "./index";
import matchPath from "./matchPath";
import LoadingAnimation from "../../components/LoadingAnimation";
import Route from "./Route";

const Switch = ({
  routes,
  children,
  force,
  fallback = (
    <LoadingAnimation
      absolute={false}
      showBackground={false}
    />
  ),
}) => {
  const routerStore = useRouterStore();
  const pathname = routerStore.state.location.pathname;
  const renderComponent = React.useRef(null);
  const previousPathname = React.useRef(null);
  const currentRoute = useRouteInfo();

  // Memoize children and shorthand route props into children route array
  const childRoutes = React.useMemo(() => {
    let shorthandRoutes = [];
    if (routes) {
      Object.values(routes).forEach((route) => {
        shorthandRoutes.push(
          <Route
            key={route.path}
            route={route}
          />,
        );
      });
    }
    return [...shorthandRoutes, ...React.Children.toArray(children)];
  }, [children, routes]);

  // Be as lazy as possible by only evaluating route matches when the pathname changes
  if (renderComponent.current == null || previousPathname.current !== pathname || force) {
    previousPathname.current = pathname;
    for (let i = 0; i < childRoutes.length; i++) {
      const {path, exact} = childRoutes[i].props;
      const route = childRoutes[i].props.route ?? currentRoute;
      const match = matchPath(pathname, {
        path: path || route.path,
        exact: !!exact,
      });

      if (match != null) {
        // Decode match params
        let decodedMatchParams = {};
        if (match?.params) {
          Object.entries(match.params).forEach(([param, value]) => {
            if (value != null) {
              try {
                const decryptedParam = routerStore.decode(value, route, param, routerStore.rootStore);
                if (!(decryptedParam == null || decryptedParam === "")) {
                  decodedMatchParams[param] = decryptedParam;
                }
              } catch (error) {
                // Swallow
              }
            }
          });
        }

        // Render this component if we match
        renderComponent.current = React.cloneElement(childRoutes[i], {matchParams: decodedMatchParams});
        break;
      }
    }
  }

  return <Suspense fallback={fallback}>{renderComponent.current}</Suspense>;
};

Switch.propTypes = {
  fallback: PropTypes.element,
  routes: PropTypes.object,
};

export default observer(Switch);
