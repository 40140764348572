import {computed} from "mobx";

import MultiDataSubscription from "./MultiDataSubscription";

export default class MultiAlarmEventSubscription extends MultiDataSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      getSubscribableStore: (x) => x.alarmEventStore,
      identifyingParam: "siteName",
    };
  }

  @computed get sites() {
    return this.identifiers;
  }
}
