import {lazy} from "react";

import {createRoute} from "sensoteq-react-core/lib/mobx-state-router";

const ReportOpenTab = lazy(() => import("routes/reports/open-tab/ReportOpenTab"));
const ReportEditTab = lazy(() => import("routes/reports/edit-tab/ReportEditTab"));

export default {
  ReportEditTab: createRoute({
    path: "/reports/edit/:name?/:version?",
    component: ReportEditTab,
    mapStateToQuery: (rootStore) => ({
      companyId: rootStore.reportStore.companyId,
    }),
    mapQueryToState: (rootStore, queryParams) => {
      rootStore.reportStore.setCompanyId(queryParams.companyId);
    },
    hookParams: {
      requireLogin: true,
    },
  }),

  ReportOpenTab: createRoute({
    path: "/reports/open",
    component: ReportOpenTab,
    hookParams: {
      requireLogin: true,
    },
  }),
};
