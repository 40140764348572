import html2pdf from "html2pdf.js";

// Orientation options for generated PDF's
export const Orientations = {
  PORTRAIT: "portrait",
  LANDSCAPE: "landscape",
};

// Function to manipulate the pageSize prop of a html2pdf worker
function manipulatePageSize(pageSize, factor) {
  pageSize.width *= factor;
  pageSize.height *= factor;
  pageSize.inner.width *= factor;
  pageSize.inner.height *= factor;
  pageSize.inner.px.width *= factor;
  pageSize.inner.px.height *= factor;
}

// Converts an HTML string or an array of HTML pages into a PDF document and downloads it
export function htmlToPdf(markup = "", opts = {}) {
  return new Promise((resolve) => {
    // Sanity check title
    let fileName = opts.fileName || "file.pdf";
    if (!fileName.endsWith(".pdf")) {
      fileName += ".pdf";
    }

    // Config
    const options = {
      margin: opts.margin ?? 60,
      filename: fileName,
      image: {type: "jpeg", quality: 0.95},
      html2canvas: {dpi: 192, scale: 2, useCORS: true},
      jsPDF: {orientation: opts.orientation ?? Orientations.PORTRAIT, unit: "pt", format: "a4"},
      pagebreak: {mode: "css", avoid: ["img", "tr", "p"]},

      // Custom params
      htmlScale: opts.htmlScale ?? 1,
      progressCallback: opts.progressCallback ?? (() => {}),
    };

    // Function to add a PDF page to a html2pdf worker chain
    const addPage = (page, worker) => {
      return worker
        .set(options)
        .from(page)
        .then(() => {
          manipulatePageSize(worker.prop.pageSize, options.htmlScale);
        })
        .to("canvas")
        .get("canvas")
        .then((canvas) => {
          // Original dimensions of content
          const originalWidth = Math.round(parseInt(canvas.style.width) / options.htmlScale);
          const originalHeight = Math.round(parseInt(canvas.style.height) / options.htmlScale);

          // Create new canvas, sized exactly for 1 page
          const newWidth = originalWidth;
          const newHeight = Math.floor(newWidth * worker.prop.pageSize.inner.ratio);
          const newCanvas = document.createElement("canvas");
          newCanvas.width = newWidth * options.html2canvas.scale;
          newCanvas.height = newHeight * options.html2canvas.scale;
          newCanvas.style.width = `${newWidth}px`;
          newCanvas.style.height = `${newHeight}px`;

          // Draw original canvas, scaled appropriately
          const drawnWidth = Math.min(originalWidth * options.html2canvas.scale, newCanvas.width);
          const drawnHeight = Math.min(originalHeight * options.html2canvas.scale, newCanvas.height);
          const ctx = newCanvas.getContext("2d");
          ctx.drawImage(canvas, 0, 0, drawnWidth, drawnHeight);

          // Replace existing canvas with new canvas
          worker.prop.canvas = newCanvas;

          // Revert pageSize prop manipulation
          manipulatePageSize(worker.prop.pageSize, 1 / options.htmlScale);
        })
        .to("pdf");
    };

    // If markup is a raw string, split into pages
    new Promise((resolve) => {
      if (typeof markup === "string") {
        splitHtmlIntoPages(markup, options).then(resolve);
      } else if (Array.isArray(markup)) {
        resolve(markup);
      } else {
        throw "markup must be either an HTML string or an array of HTML pages";
      }
    }).then((pages) => {
      // Replacements
      for (let i = 0; i < pages.length; i++) {
        // Make selects work by manually setting "selected" property on correct option
        pages[i] = pages[i].replace(/data-selected="true"/g, "selected");
      }

      // Generate each page individually
      options.progressCallback(1, pages.length);

      // Create html2pdf worker
      let worker = addPage(pages[0], html2pdf());

      // Add other pages
      pages.slice(1).forEach((page, pageIdx) => {
        worker = worker.get("pdf").then((pdf) => {
          pdf.addPage();
          options.progressCallback(pageIdx + 2, pages.length);
        });
        worker = addPage(page, worker);
      });

      // Add footer
      worker = worker.get("pdf").then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(150);
          pdf.text(
            `Page ${i} of ${totalPages}`,
            pdf.internal.pageSize.getWidth() - options.margin,
            pdf.internal.pageSize.getHeight() - options.margin + 20,
            "right",
          );
          pdf.text(options.filename, options.margin, pdf.internal.pageSize.getHeight() - options.margin + 20);
        }
      });

      // Save PDF
      worker.save().then(resolve);
    });
  });
}

// Splits a single DOM tree into one fully structured and cloned DOM tree per page
function splitHtmlIntoPages(markup, options) {
  return new Promise((resolve) => {
    let worker = html2pdf()
      .set(options)
      .from(markup)
      .then(() => {
        manipulatePageSize(worker.prop.pageSize, options.htmlScale);
      })
      .to("canvas")
      .get("canvas")
      .get("container")
      .then((container) => {
        let pages = [];
        let page = "";

        // Prefixes and suffixes are the current element tags which need to be opened or closed at the current point
        // in the DOM tree
        let prefixes = [];
        let suffixes = [];

        // Inserts the current element opening tags to resume the current DOM tree on a new page
        const resumeTree = () => {
          page += prefixes.join("");
        };

        // Inserts the current element closing tags to close the current DOM tree on the current page
        const closeTree = () => {
          page += suffixes.slice().reverse().join("");
        };

        // Adds a new element to the current DOM tree
        const openElement = (element) => {
          let prefix = `<${element.tagName.toLowerCase()} `;
          for (let i = 0; i < element.attributes.length; i++) {
            prefix += `${element.attributes[i].nodeName}='${element.attributes[i].nodeValue}' `;
          }
          prefix += `>`;
          const suffix = `</${element.tagName.toLowerCase()}>`;
          prefixes.push(prefix);
          suffixes.push(suffix);
          page += prefix;
        };

        // Removes the latest element from the DOM tree
        const closeElement = () => {
          prefixes.splice(-1);
          page += suffixes.splice(-1);
        };

        // Recursively processes children on a DOM node and keeps track of tree depth
        const processChildren = (element) => {
          // Check for line breaks or normal element
          if (element.tagName === "DIV" && element.innerHTML === "") {
            // Close the current DOM tree and resume it on a new page
            closeTree();
            pages.push(page);
            page = "";
            resumeTree();
          } else {
            // Check if this element has child nodes
            if (element.childNodes && element.childNodes.length) {
              // Recurse through each child
              openElement(element);
              element.childNodes.forEach((child) => processChildren(child));
              closeElement(element);
            } else {
              // Otherwise add this element to the current page HTML
              if (element.nodeType === Node.TEXT_NODE) {
                page += element.textContent;
              } else {
                page += element.outerHTML;
              }
            }
          }
        };

        // Process pages
        const innerElement = container.children[0];
        processChildren(innerElement);

        // Add last page content which was after the last page break
        pages.push(page);
        resolve(pages);
      });
  });
}
