import {observable, action, flow, computed} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";
import {PresentableDataTypes} from "sensoteq-core/enumerations";
import * as Enums from "constants/enums";

export default class UserDataSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      username: null,
    };
  }

  @observable data;

  getData = flow(function* ({username}) {
    if (!username) {
      return;
    }
    this.startLoading();
    try {
      const data = yield Api.getUserDetails(username);
      this.data = data.user;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting user data: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get enabledSites() {
    return (this.data?.sites ?? [])
      .map((x) => this.rootStore.siteStore.getSite(x))
      .filter((x) => x != null)
      .map((x) => ({
        label: x.display_name,
        value: x.site_name,
      }));
  }

  @computed get _enabledSiteLookupMap() {
    let map = {};
    this.enabledSites.forEach((site) => {
      map[site.value] = true;
    });
    return map;
  }

  @computed get disabledSites() {
    return this.rootStore.siteStore.sites
      .filter((x) => !this._enabledSiteLookupMap[x.site_name])
      .map((x) => ({
        label: x.display_name,
        value: x.site_name,
      }));
  }

  @computed get privilegeLevel() {
    if (this.data?.admin) {
      return Enums.PRIVILEGE_LEVEL_ADMIN;
    }
    if (this.data?.partner_type === PresentableDataTypes.PARTNER) {
      return Enums.PRIVILEGE_LEVEL_PARTNER;
    }
    if (this.data?.installer) {
      return Enums.PRIVILEGE_LEVEL_INSTALLER;
    }
    return Enums.PRIVILEGE_LEVEL_USER;
  }

  @action sync(username, data) {
    if (username === this.params.username) {
      this.data = data;
    }
  }
}
