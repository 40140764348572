import styled from "styled-components";

export default styled.div`
  flex: ${(props) => (props.auto ? "1 1 auto" : "1 1 0")} !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
`;
