import {when} from "mobx";

import {sortArrayByProperty} from "sensoteq-react-core/services/utils";
import {capitalise} from "services/Utils";

export default function (rootStore, machineId, timeOption) {
  const {machineStore} = rootStore;
  const machine = machineStore.getMachine(machineId);
  return new Promise((resolve) => {
    const sub = rootStore.alarmEventStore.subscribe(machine.site_name, {recreate: true});
    sub.update({
      siteName: machine.site_name,
      getTimeOption: () => timeOption.value,
    });
    when(
      () => !sub.initialLoad,
      () => {
        const status = sub.getMachineStatus(machineId);
        sub.cancel();
        resolve(renderMachineAlarmLog(rootStore, machineId, status, timeOption));
      },
    );
  });
}

export function renderMachineAlarmLog(rootStore, machineId, status, timeOption) {
  const {themeStore, pointStore, machineStore, siteStore} = rootStore;
  const machine = machineStore.getMachine(machineId);
  const site = siteStore.getSite(machine.site_name);

  let colour = themeStore.theme.colours.good;
  let statusTitle = "OK";
  if (status.hasCritical) {
    colour = themeStore.theme.colours.bad;
    statusTitle = "Critical issue";
  } else if (status.hasWarning) {
    colour = themeStore.theme.colours.warning;
    statusTitle = "Anomaly detected";
  }

  // Group alarms by count
  let allEvents = status.criticalEvents.concat(status.warningEvents);
  let uniqueEvents = [];
  let countMap = {};
  allEvents.forEach((event) => {
    if (countMap[event.alarm_id] == null) {
      countMap[event.alarm_id] = 1;
      uniqueEvents.push(event);
    } else {
      countMap[event.alarm_id]++;
    }
  });
  uniqueEvents = uniqueEvents.map((event) => ({
    ...event,
    count: countMap[event.alarm_id],
  }));
  sortArrayByProperty(uniqueEvents, "count", true);

  return (
    `<table style="width: 100%;">` +
    `<tbody>` +
    `<tr>` +
    `<td colspan="4" style="width: 100%; background-color: ${colour};">` +
    `<strong>` +
    `<span style="color: white;">` +
    `${site.display_name} - ${machine.label} - ${statusTitle}` +
    `</span>` +
    `</strong>` +
    `</td>` +
    `</tr>` +
    // List events
    (status.hasCritical || status.hasWarning
      ? `<tr>` +
        `<td style="width: 10%; background-color: #ddd;">` +
        `<strong>Type</strong>` +
        `</td>` +
        `<td style="width: 25%; background-color: #ddd;">` +
        `<strong>Measuring Point</strong>` +
        `</td>` +
        `<td style="width: 53%; background-color: #ddd;">` +
        `<strong>Description</strong>` +
        `</td>` +
        `<td style="width: 12%; background-color: #ddd;">` +
        `<strong>${timeOption.value.toUpperCase()} Count</strong>` +
        `</td>` +
        `</tr>` +
        uniqueEvents
          .map((event) => {
            const point = pointStore.getTestPoint(event.parsedTarget.pointId);
            if (!point) {
              return "";
            }
            return (
              `<tr>` +
              `<td style="width: 10%;">` +
              `<strong>` +
              `<span style="color: ${themeStore.theme.colours[event.data.type === "warning" ? "warning" : "bad"]}">` +
              `${capitalise(event.data.type)}` +
              `</span>` +
              `</strong>` +
              `</td>` +
              `<td style="width: 25%;">` +
              `${point.label}` +
              `</td>` +
              `<td style="width: 53%;">` +
              `${event.condition}` +
              `</td>` +
              `<td style="width: 12%;">` +
              `${event.count}` +
              `</td>` +
              `</tr>`
            );
          })
          .join("")
      : // No events
        `<tr>` + `<td>` + `No alarms triggered in the ${timeOption.label.toLowerCase()}.` + `</td>` + `</tr>`) +
    `</tbody>` +
    `</table>` +
    `<p></p>`
  );
}
