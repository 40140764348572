import {observable, computed, action} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";

export default class MultiDataSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      identifiers: null,
      identifyingParam: null,
      getSubscribableStore: null,
      subParams: null,
    };
  }
  getRequiredParamKeys() {
    return ["identifiers", "identifyingParam", "subParams", "getSubscribableStore"];
  }

  @observable.shallow subscriptions = [];

  getSetTitle() {
    throw "getSetTitle should be overridden";
  }

  @action getData({identifiers, identifyingParam, subParams, getSubscribableStore}) {
    let newSubs = [];

    // Check points
    if (identifiers) {
      identifiers.forEach((identifier) => {
        // If we already have a sub for this point, keep it
        const sub = this.subscriptionLookupMap[identifier];
        if (sub != null && !this.config.recreate) {
          newSubs.push(sub.sub);
          this.subscriptions[sub.index] = null;
        }

        // Otherwise create a new one
        else {
          const store = getSubscribableStore(this.rootStore);
          const newSub = store.subscribe(`${this.config.id}-${identifier}`, {
            recreate: this.config.recreate,
          });
          newSub.update({
            [identifyingParam]: identifier,
            ...(subParams || {}),
          });
          newSubs.push(newSub);
        }
      });
    }

    // Cancel all unused subs
    this.subscriptions.forEach((sub) => {
      if (sub != null) {
        sub.cancel();
      }
    });

    // Set new subs
    this.subscriptions = newSubs;
  }

  @computed get loading() {
    return this.subscriptions.some((x) => x.loading);
  }

  @computed get initialLoad() {
    return this.subscriptions.some((x) => x.initialLoad);
  }

  @computed get identifiers() {
    if (!this.params.identifiers) {
      return [];
    }
    return this.params.identifiers;
  }

  @computed get subscriptionLookupMap() {
    let map = {};
    if (!this.params.identifyingParam) {
      return map;
    }
    this.subscriptions.forEach((sub, idx) => {
      if (sub == null) {
        return;
      }
      map[sub.params[this.params.identifyingParam]] = {
        sub,
        index: idx,
      };
    });
    return map;
  }

  @computed get setTitles() {
    return this.identifiers.map((identifier) => this.getSetTitle(identifier));
  }

  @computed get getIdentifiers() {
    return this.identifiers.map((identifier) => identifier);
  }

  @computed get setPositions() {
    return this.identifiers.map((identifier) => this.getSetPosition(identifier));
  }

  getSubscription(identifier) {
    return this.subscriptionLookupMap[identifier]?.sub;
  }

  enrichTitles(data) {
    return data.map((set, idx) => ({
      ...set,
      title: this.setTitles[idx],
      id: this.getIdentifiers[idx],
    }));
  }

  enrichPositions(data) {
    return data.map((set, idx) => ({
      ...set,
      position: this.setPositions[idx],
    }));
  }
}
