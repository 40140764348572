import {ConversionError} from "../errors.js";
import type {ObjectValues} from "../types.js";

export const TemperatureOption = {
  CELCIUS: "°C",
  FAHRENHEIT: "°F",
} as const;

function celciusToFahrenheit(value: number) {
  return value * 1.8 + 32;
}

function fahrenheitToCelcius(value: number) {
  return (value - 32) / 1.8;
}

export function convertTemperture(value: number, from: string, to: string) {
  if (from === to) return value;
  if (from === "°C" && to === "°F") return celciusToFahrenheit(value);
  if (from === "°F" && to === "°C") return fahrenheitToCelcius(value);

  throw new ConversionError("temperature", from, to, value);
}

// Reimplement this mechanism if there is a smarter mechanism for generically converting between
// temperature units. Maybe using kelvins and an inbetween.
export const TemperatureStubRatio = {
  [TemperatureOption.CELCIUS]: 0,
  [TemperatureOption.FAHRENHEIT]: 0,
};

export type Temperature = ObjectValues<typeof TemperatureOption>;
