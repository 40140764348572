import React from "react";
import {observer} from "mobx-react";

import {Link} from "sensoteq-react-core/lib/mobx-state-router";
import * as Styled from "./MainMenuLink.styles";
import {useRootStore} from "stores";
import Icon from "components/common/Icon";

const MainMenuLink = (props) => {
  const {routerStore, uiStore} = useRootStore();
  const {text, to, route, icon, iconLibrary, otherRoutes, external, hideMobile} = props;

  let active = route ? routerStore.isRouteActive(route) : false;
  if (!active && otherRoutes) {
    otherRoutes.forEach((route) => {
      if (routerStore.isRouteActive(route)) {
        active = true;
      }
    });
  }

  // Callback to call mobile menu when clicking a link
  const onClick = React.useCallback(() => {
    uiStore.setMainMenuVisibleMobile(false);
    props.onClick && props.onClick();
  }, [props.onClick]);

  return (
    <Link
      shallow
      external={external}
      onClick={onClick}
      to={external ? to : route}
    >
      <Styled.Link
        active={active}
        collapsed={uiStore.mainMenuCollapsed}
        hideMobile={hideMobile}
      >
        <Styled.ActiveBlock />
        <Styled.IconContainer collapsed={uiStore.mainMenuCollapsed}>
          {Array.isArray(icon) ? (
            <span className="fa-layers static">
              {icon.map((_icon, idx) => (
                <Icon
                  key={`${_icon.name}-${idx}`}
                  library={iconLibrary}
                  name={_icon.name}
                  style={_icon.style}
                  transform={_icon.transform}
                />
              ))}
            </span>
          ) : (
            <Icon
              library={iconLibrary}
              name={icon}
            />
          )}
        </Styled.IconContainer>
        {!uiStore.mainMenuCollapsed && <span>{text}</span>}
      </Styled.Link>
    </Link>
  );
};

MainMenuLink.IconLibraries = Icon.Libraries;

export default observer(MainMenuLink);
