import {useState, useRef, useEffect} from "react";
import TopBarDropdown from "components/layout/TopBarDropdown";
import ActionIcon from "components/common/ActionIcon";
import ToggleV2 from "./ToggleV2";

const ProfileDropdown = ({dropdownItems}) => {
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && visible) {
        setVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, visible]);

  const toDropdown = dropdownItems;

  const onItemClick = (dropdownItem) => {
    setVisible(false);
    dropdownItem.onClick();
  };

  return (
    <div
      ref={wrapperRef}
      className={`flex flex-row justify-start items-center h-full md:relative`}
    >
      <ActionIcon
        tooltip="Profile"
        icon="person"
        onClick={() => setVisible(!visible)}
      />

      <TopBarDropdown
        visible={visible}
        width="36rem"
        title="Profile Options"
        actions={[{label: "Close", onClick: () => setVisible(false)}]}
        align="right"
        padded={false}
      >
        <div>
          {toDropdown.map((dropdownItem) => {
            if (dropdownItem.type == "button")
              return (
                <button
                  className={`flex flex-row justify-start items-center
                  py-[1.2rem] px-[2.6rem] flex-auto w-full
                  odd:bg-content-background-alt odd:border-y-[0.1rem]
                  odd:border-y-border last:pb-[2rem] last:border-b-0`}
                  key={dropdownItem.key}
                  onClick={() => onItemClick(dropdownItem)}
                >
                  <i className="material-icons mr-[1rem]">{dropdownItem.icon}</i>
                  <div
                    className={`hover:cursor-pointer hover:text-primary-highlight
                  last:ml-[1rem]  last:font-bold last:font-small last:text-content-light`}
                  >
                    {dropdownItem.text}
                  </div>
                </button>
              );
            if (dropdownItem.type == "toggle")
              return (
                <div
                  className={`flex flex-row justify-start items-center
                py-[1.2rem] px-[2.6rem] flex-auto w-full
                odd:bg-content-background-alt odd:border-y-[0.1rem]
                odd:border-y-border last:pb-[2rem] last:border-b-0`}
                  key={dropdownItem.key}
                  onClick={() => setVisible(false)}
                >
                  <ToggleV2 />
                </div>
              );
          })}
        </div>
      </TopBarDropdown>
    </div>
  );
};

export default ProfileDropdown;
