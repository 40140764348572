import {useEffect} from "react";
import {observer} from "mobx-react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import {Redirect, Switch} from "sensoteq-react-core/lib/mobx-state-router";
import LoadingAnimation from "sensoteq-react-core/components/LoadingAnimation";
import LoadingPlaceholder from "components/common/LoadingPlaceholder";
import MainMenu from "components/layout/MainMenu";
import NotificationPane from "components/layout/NotificationPane";
import Routes from "routes/app-routes";
import ServerChanger from "components/layout/ServerChanger";
import {useRootStore} from "stores";
import PopupContainer from "sensoteq-react-core/components/PopupContainer";
import * as Styled from "./App.styles";
import {StyleSheetManager} from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import {createRouter, RouterProvider} from "@tanstack/react-router";
import {ReportWrapper} from "../ReportWrapper";

// Import the generated route tree
import {routeTree} from "../routeTree.gen";
import {PostHogProvider, usePostHog} from "posthog-js/react";
import {Toaster} from "@/components/ui/toaster";

const queryClient = new QueryClient();

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

// Create a new router instance
const router = createRouter({routeTree});

function App() {
  const rootStore = useRootStore();
  const posthog = usePostHog();
  const {uiStore, userStore} = rootStore;
  const {loggedIn, isChiOnlyUser, username} = userStore;
  const rootPage = loggedIn && isChiOnlyUser ? Routes.ChiPage : Routes.MapPage;

  useEffect(() => {
    try {
      posthog.identify(username, {
        ...(isChiOnlyUser && {
          isChiOnlyUser: true,
        }),
      });
    } catch (err) {
      console.error("Posthog was unable to identify user");
    }
  }, [username, isChiOnlyUser]);

  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <QueryClientProvider client={queryClient}>
        <StyleSheetManager
          shouldForwardProp={(propName, elementToBeRendered) =>
            typeof elementToBeRendered === "string" ? isPropValid(propName) : true
          }
        >
          <ServerChanger />
          <Styled.Container className="flex-auto overflow-hidden">
            {loggedIn && <MainMenu />}
            <Styled.Body>
              <Toaster />
              <PopupContainer>
                {/* Overlay over whole page when mobile main menu is visible and popped out */}
                {loggedIn && uiStore.mainMenuVisibleMobile && (
                  <Styled.MobileOverlay onClick={() => uiStore.setMainMenuVisibleMobile(false)} />
                )}
                {/* Wait until initial content has loaded before rendering page */}
                {loggedIn && rootStore.initialLoad && <LoadingPlaceholder />}

                {/* Render routes when not logged in, or after initial loading */}
                {(!loggedIn || (loggedIn && !rootStore.initialLoad)) && (
                  <ReportWrapper>
                    <Switch
                      routes={Routes}
                      fallback={loggedIn ? <LoadingPlaceholder /> : <LoadingAnimation />}
                    >
                      {/* Redirect root requests to map page when logged in */}
                      <Redirect
                        exact
                        to={rootPage}
                      />

                      <RouterProvider router={router} />
                    </Switch>
                  </ReportWrapper>
                )}
              </PopupContainer>
            </Styled.Body>

            {/* Live notifications component */}
            <NotificationPane />
          </Styled.Container>
        </StyleSheetManager>
      </QueryClientProvider>
    </PostHogProvider>
  );
}

export default observer(App);
