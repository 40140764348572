import {when} from "mobx";

import {renderMachineAlarmLog} from "./buildMachineAlarmLog";

export default async function (rootStore, siteName, timeOption) {
  const {siteStore} = rootStore;
  return new Promise((resolve) => {
    const sub = rootStore.alarmEventStore.subscribe(siteName, {recreate: true});
    sub.update({
      siteName,
      getTimeOption: () => timeOption,
    });
    when(
      () => !sub.initialLoad,
      () => {
        const site = siteStore.getSite(siteName);
        const machineStatuses = site.machines
          .map((machine) => ({
            status: sub.getMachineStatus(machine.machine_id),
            machine,
          }))
          .sort((a, b) => {
            const criticalityA = a.status.criticality;
            const criticalityB = b.status.criticality;
            if (criticalityA === criticalityB) {
              return 0;
            }
            return criticalityA > criticalityB ? -1 : 1;
          });
        sub.cancel();
        resolve(
          machineStatuses
            .map((x) => renderMachineAlarmLog(rootStore, x.machine.machine_id, x.status, timeOption))
            .join(""),
        );
      },
    );
  });
}
