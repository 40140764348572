import enums from "../../sensoteq-core/enumerations";

const defaultEnvironment = "sensoteq";

export const getKey = (keyId) => {
  if (configOptions[enums.ENVIRONMENT_BRANDING] && configOptions[enums.ENVIRONMENT_BRANDING][`${keyId}`]) {
    return configOptions[enums.ENVIRONMENT_BRANDING][`${keyId}`];
  }
  return configOptions[defaultEnvironment][`${keyId}`];
};

let configOptions = {
  sensoteq: {
    title: "Analytix by Sensoteq",
    domain: "sensoteq.com",
    login_media: "https://s3.eu-west-1.amazonaws.com/assets.sensoteq.com/hmi/login-rf30.m4v",
    sensor_icon: "https://s3.eu-west-1.amazonaws.com/assets.sensoteq.com/hmi/sensor.png",
    default_logo: "https://s3.eu-west-1.amazonaws.com/assets.sensoteq.com/hmi/branding/sensoteq/default-logo.png",
    display_name: "Sensoteq",
    default_theme: "BlackRed",
    theme_assets: {
      BlackRed:
        "https://s3.eu-west-1.amazonaws.com/assets.sensoteq.com/hmi/branding/sensoteq/analytix-black-wordmark.svg",
      WhiteRed:
        "https://s3.eu-west-1.amazonaws.com/assets.sensoteq.com/hmi/branding/sensoteq/analytix-white-wordmark.svg",
      WhiteBlack:
        "https://s3.eu-west-1.amazonaws.com/assets.sensoteq.com/hmi/branding/sensoteq/analytix-white-black-400-alt.png",
    },
    login_branding_values: {
      hue: 355,
      saturation: 67,
      lightness: 48,
      alpha: 0.8,
      logo_size: 2.6,
      text_margin_left: 0.9,
      product_width: 8.85,
    },
    logo_primary: "https://s3.eu-west-1.amazonaws.com/assets.sensoteq.com/hmi/branding/sensoteq/logo-primary.png",
    privacy_policy: "https://www.sensoteq.com/legal/privacy-policy",
    analytix_user_guide: "http://assets.sensoteq.com/DOC-00156_HMI_User_Guide.pdf",
    api_endpoint: "https://api.sensoteq.com/api/",
  },
  sandbox: {
    login_media: "https://s3.eu-west-1.amazonaws.com/sandbox-assets.sensoteq.com/hmi/login-rf30.m4v",
    sensor_icon: "https://s3.eu-west-1.amazonaws.com/sandbox-assets.sensoteq.com/hmi/sensor.png",
    default_logo: "https://s3.eu-west-1.amazonaws.com/sandbox-assets.sensoteq.com/hmi/branding/default-logo.png",
    logo_primary: "https://s3.eu-west-1.amazonaws.com/sandbox-assets.sensoteq.com/hmi/branding/logo-primary.png",
    theme_assets: {
      BlackRed:
        "https://s3.eu-west-1.amazonaws.com/sandbox-assets.sensoteq.com/hmi/branding/analytix-black-wordmark.svg",
      WhiteRed:
        "https://s3.eu-west-1.amazonaws.com/sandbox-assets.sensoteq.com/hmi/branding/analytix-white-wordmark.svg",
      WhiteBlack:
        "https://s3.eu-west-1.amazonaws.com/sandbox-assets.sensoteq.com/hmi/branding/analytix-white-black-400-alt.png",
    },
  },
  haver: {
    title: "Haver & Boecker HMI",
    domain: "haverniagara.com.br",
    login_media: "https://s3.eu-west-1.amazonaws.com/haver-assets.sensoteq.com/hmi/branding/login-screen/login1080.jpg",
    sensor_icon: "https://s3.eu-west-1.amazonaws.com/haver-assets.sensoteq.com/hmi/branding/sensor-haver.png",
    default_logo: "https://s3.eu-west-1.amazonaws.com/haver-assets.sensoteq.com/hmi/branding/logos/default-logo.png",
    display_name: "Haver & Boecker",
    default_theme: "LogoTextBlue",
    theme_assets: {
      LogoTextWhite:
        "https://s3.eu-west-1.amazonaws.com/haver-assets.sensoteq.com/hmi/branding/text-logos/white-logo-text.png",
      LogoTextBlue:
        "https://s3.eu-west-1.amazonaws.com/haver-assets.sensoteq.com/hmi/branding/text-logos/blue-logo-text.png",
    },
    login_branding_values: {
      hue: 223,
      saturation: 30,
      lightness: 16,
      alpha: 0.6,
      logo_size: 3.5,
      text_margin_left: 1.25,
      product_width: 10.5,
    },
    logo_primary: "https://s3.eu-west-1.amazonaws.com/haver-assets.sensoteq.com/hmi/branding/logos/blue-logo.png",
    logo_secondary: "https://s3.eu-west-1.amazonaws.com/haver-assets.sensoteq.com/hmi/branding/logos/white-logo.png",
    analytix_user_guide:
      "https://haverboecker.canto.global/pdfviewer/viewer/viewer.html?share=share%2Calbum%2CIV988&column=document&id=r9ucfjklup6m18hi6nsupopu6h&suffix=pdf&print=1",
    privacy_policy: "https://www.haverboecker.com/en/privacy-policy",
    api_endpoint: "https://api.pulsecmdata.haverniagara.com.br/api/",
  },
};
