import type {ObjectValues} from "../types.js";

export const AngleOption = {
  DEGREES: "deg",
  RADIANS: "radians",
} as const;

export const AngleRatio = {
  [AngleOption.DEGREES]: 1,
  [AngleOption.RADIANS]: 0.017453292519943295,
};

export type Angle = ObjectValues<typeof AngleOption>;
