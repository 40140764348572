import {observable, flow, computed} from "mobx";

import LoadableStore from "sensoteq-react-core/models/LoadableStore";
import Api from "sensoteq-react-core/services/api";

export default class PartnerStore extends LoadableStore {
  @observable _partners;

  @computed get partners() {
    if (!this._partners) {
      return [];
    }
    return this._partners;
  }

  getPartner(partnerId) {
    return this.partners.find(({partner_group_id}) => partner_group_id === partnerId);
  }

  @computed get partnerOptions() {
    return this.partners.map((x) => ({
      label: x.display_name,
      value: x.partner_group_id,
    }));
  }

  loadPartners = flow(function* () {
    this.startLoading();
    try {
      const partners = yield Api.getPartnerList();
      this._partners = partners.partner_groups;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting partners: ${error}`, "bad");
    }
    this.stopLoading();
  });

  createPartner = flow(function* (name, primary_company, sites, companies) {
    this.startLoading();
    try {
      yield Api.createPartner(name, primary_company, sites, companies);
      this.loadPartners();
      this.rootStore.notificationStore.addNotification("Partner created successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error creating company: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updatePartner = flow(function* (id, sites, companies, display_name) {
    this.startLoading();
    try {
      yield Api.updatePartner(id, sites, companies, display_name);
      this.loadPartners();
      this.rootStore.notificationStore.addNotification("Partner updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating company: ${error}`, "bad");
    }
    this.stopLoading();
  });

  deletePartner = flow(function* (id) {
    this.startLoading();
    try {
      yield Api.deletePartner(id);
      this.loadPartners();
      this.rootStore.notificationStore.addNotification("Partner deleted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting company: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
