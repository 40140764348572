import {computed, observable, when, action} from "mobx";

/**
 * Exposes 3 properties to observe the state of the store:
 * initiated   : marked true once the first loading commences
 * initialLoad : marked true during the first loading period
 * loading     : marked true during all loading periods
 *
 * Exposes 2 methods to control the loading state of the store:
 * startLoading : call before all async loading operations
 * stopLoading  : call after all async loading operations
 *
 * Multiple calls to start and stop simultaneously are fully supported.
 */
export default class Loadable {
  @observable _initialLoad = true;
  @observable _loadingCount = 0;
  @observable _initiated = false;
  @observable _fetching = false;

  initialise() {
    this.handleDisposal(() =>
      when(
        () => this.loading,
        () => (this._initiated = true),
      ),
    );
    this.handleDisposal(() =>
      when(
        () => this.initiated && !this.loading,
        () => (this._initialLoad = false),
      ),
    );
  }

  handleDisposal() {
    throw "A loadable instance requires a disposal handler!";
  }

  @computed get loading() {
    return !!this._loadingCount;
  }

  @computed get fetching() {
    return this._fetching;
  }

  @computed get initialLoad() {
    return this._initialLoad;
  }

  @computed get initiated() {
    return this._initiated;
  }

  @action startLoading() {
    this.startFetching();
    this._loadingCount++;
  }

  @action stopLoading() {
    this._loadingCount--;
    if (this._loadingCount <= 0) {
      this.stopFetching();
    }
  }

  @action startFetching() {
    this._fetching = true;
  }

  @action stopFetching() {
    this._fetching = false;
  }

  @action reset() {
    this._initialLoad = true;
    this._initiated = false;
    this._fetching = false;
    this.initialise();
  }
}
