import {observer} from "mobx-react";
import PropTypes from "prop-types";

import * as Styled from "./TopBarDropdown.styles";
import React from "react";

type Action = {
  label: string;
  desktop?: boolean;
  mobile?: boolean;
  onClick: () => void;
};

export type TopBarDropdownProps = {
  title?: string;
  actions?: Action[];
  visible?: boolean;
  visibleMobile?: boolean;
  align?: "left" | "right";
  width?: string;
  style?: React.CSSProperties;
  padded?: boolean;
  onScrollOffsetChange?: (scrollOffset: number) => void;
  children?: React.ReactNode;
  onTouchStart?: (event: React.TouchEvent<HTMLDivElement>) => void;
  // [key: string]: any; // For any other props you may want to pass
};

const TopBarDropdown = (props: TopBarDropdownProps) => {
  const {
    title,
    actions,
    children,
    visible,
    align,
    width,
    style,
    padded,
    onScrollOffsetChange,
    onTouchStart,
    ...otherProps
  } = props;
  const visibleMobile = props.visibleMobile == null ? visible : props.visibleMobile;
  const headerVisible = title != null || (actions && actions.findIndex((x) => x.desktop === true) !== -1);
  const headerVisibleMobile = title != null || (actions && actions.findIndex((x) => x.mobile === true) !== -1);
  const onScroll =
    onScrollOffsetChange == null
      ? null
      : (e: React.UIEvent<HTMLDivElement>) => onScrollOffsetChange((e.target as HTMLDivElement).scrollTop);

  return (
    <Styled.Wrapper
      // @ts-ignore todo remove once styles type issue resolved
      visible={visible}
      visibleMobile={visibleMobile}
      align={align}
      style={{width, ...(style || {})}}
      onTouchStart={onTouchStart}
      {...otherProps}
    >
      <Styled.Container>
        <Styled.Header
          // @ts-ignore
          visible={headerVisible}
          visibleMobile={headerVisibleMobile}
        >
          <Styled.Title>{title}</Styled.Title>
          {actions && actions.length && (
            <Styled.Actions>
              {actions.map(({label, desktop = true, mobile = true, onClick}) => (
                <Styled.Action
                  key={label}
                  // @ts-ignore
                  desktop={desktop}
                  mobile={mobile}
                  onClick={onClick}
                >
                  {label}
                </Styled.Action>
              ))}
            </Styled.Actions>
          )}
        </Styled.Header>
        <Styled.Body
          padded={padded}
          // @ts-ignore
          onScroll={onScroll}
          headerVisible={headerVisible}
          headerVisibleMobile={headerVisibleMobile}
        >
          {children}
        </Styled.Body>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

TopBarDropdown.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
  visible: PropTypes.bool,
  visibleMobile: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right"]),
  width: PropTypes.string,
  padded: PropTypes.bool,
};
TopBarDropdown.defaultProps = {
  visible: false,
  align: "left",
  padded: true,
};

export default observer(TopBarDropdown);
