import {observable, action, flow, computed} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class GatewayHardwareFilterListSubscription extends DataSubscription {
  config = {
    ...super.config,
    autoRefresh: false,
  };
  getDefaultParams() {
    return {
      gatewayName: null,
    };
  }

  @observable _filterList;

  getData = flow(function* ({gatewayName}) {
    if (!gatewayName) {
      return;
    }
    this.startLoading();
    try {
      const hardwareFilterList = yield Api.getHardwareFilterList(gatewayName);
      this._filterList = hardwareFilterList.sensors;
    } catch (error) {
      this._filterList = null;
      this.rootStore.notificationStore.addNotification(
        `Communication with ${gatewayName} failed! It may be offline`,
        "bad",
      );
    }
    this.stopLoading();
  });

  @computed get hardwareFilterList() {
    return this._filterList;
  }

  @action sync(gatewayName, filterList) {
    if (gatewayName === this.params.gatewayName) {
      this._filterList = filterList;
    }
  }
}
