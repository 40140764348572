import {observable, flow, computed, runInAction, reaction} from "mobx";
import dayjs from "dayjs";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";
import {TIME_OPTION_LATEST} from "sensoteq-react-core/constants/enums";

export default class ThermalImageSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      pointId: null,
      getTimeOption: () => TIME_OPTION_LATEST,
      getTimestamp: () => dayjs(),
    };
  }
  getParsedParams(params) {
    return {
      timeOption: params.getTimeOption(),
      timestamp: params.getTimestamp(),
    };
  }

  @observable _url;
  @observable _fetchedUrl;
  @observable _previousUrl;
  @observable _hasImageError;

  constructor(...params) {
    super(...params);

    // Preload URL when it changes
    this.disposeWithSubscription(() =>
      reaction(
        () => this._url,
        (url) => {
          if (!url) {
            return;
          }
          this.startLoading();
          const img = new Image();
          img.onload = () => {
            runInAction(() => {
              this._previousUrl = this._fetchedUrl;
              this._fetchedUrl = url;
              this._hasImageError = false;
            });
            this.stopLoading();
          };
          img.onerror = () => {
            runInAction(() => {
              this._hasImageError = true;
            });
            this.stopLoading();
          };
          img.src = url;
        },
      ),
    );
  }

  getData = flow(function* ({pointId, timeOption, timestamp}) {
    if (!pointId) {
      return;
    }
    this.startLoading();
    try {
      if (timeOption === TIME_OPTION_LATEST) {
        const data = yield Api.getLatestThermalImage(pointId);
        this._url = `${data.url}&nocache=${Math.random()}`;
      } else {
        const data = yield Api.getHistoricalThermalImage(pointId, timestamp.valueOf());
        this._url = `${data.url}&nocache=${Math.random()}`;
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting thermal image: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get latestUrl() {
    return this._fetchedUrl;
  }

  @computed get previousUrl() {
    if (!this._previousUrl) {
      return this.latestUrl;
    }
    return this._previousUrl;
  }

  @computed get hasImageError() {
    return this._hasImageError;
  }
}
