import {flow, observable, computed} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class ChiDataSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      eventId: null,
    };
  }

  @observable.shallow _keys;
  @observable session;

  @computed get keys() {
    return this._keys?.slice().reverse() ?? [];
  }

  @computed get assetLabel() {
    // Target comes in the format of COMPANY/ASSET_LABEL
    const firstDivider = this.session?.target?.indexOf("/");
    return firstDivider !== -1 ? this.session?.target?.substring(firstDivider + 1) : null;
  }

  getData = flow(function* ({eventId}) {
    if (!eventId) {
      return;
    }
    this.startLoading();
    try {
      const event = yield Api.getEvent(eventId);
      const dataList = yield Api.getChiDataList(eventId);
      this.session = event.event;
      this._keys = dataList.keys;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting chi data: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateSession = flow(function* (eventId, name) {
    this.startLoading();
    try {
      yield Api.updateChiSession(eventId, name);
      this.session.name = name;
      this.rootStore.notificationStore.addNotification("Chi session updated successfully");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating Chi session: ${error}`, "bad");
    }
    this.stopLoading();
  });

  async deleteSession(eventId) {
    this.startLoading();
    try {
      await Api.deleteChiSession(eventId);
      this.rootStore.notificationStore.addNotification("Chi session deleted successfully");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting Chi session: ${error}`, "bad");
    }
    this.stopLoading();
  }
}
