import React from "react";

import {PopupContext} from "./PopupContext";

const PopupContainer = (props) => {
  const {children} = props;
  const id = React.useRef(`popup-container-${Math.random().toString().substr(2)}`);
  return (
    <PopupContext.Provider value={id.current}>
      {children}
      <div id={id.current} />
    </PopupContext.Provider>
  );
};
export default PopupContainer;
