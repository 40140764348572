import {flow} from "mobx";

import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import GatewayHardwareFilterListSubscription from "models/GatewayHardwareFilterListSubscription";
import Api from "sensoteq-react-core/services/api";

export default class GatewayHardwareFilterListStore extends SubscribableStore {
  constructor(rootStore) {
    super(rootStore, GatewayHardwareFilterListSubscription);
  }

  async retrieve(gatewayName) {
    const hardwareFilterList = await Api.getHardwareFilterList(gatewayName);
    return hardwareFilterList.sensors;
  }

  updateHardwareFilterList = flow(function* (gatewayName) {
    this.startLoading();
    try {
      yield Api.updateHardwareFilterList(gatewayName);
      this.rootStore.notificationStore.addNotification("Hardware filter list updated successfully", "good");
      yield this.sync(gatewayName);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating hardware filter list: ${error}`, "bad");
    }
    this.stopLoading();
  });

  clearHardwareFilterList = flow(function* (gatewayName) {
    this.startLoading();
    try {
      yield Api.clearHardwareFilterList(gatewayName);
      this.rootStore.notificationStore.addNotification("Hardware filter cleared successfully", "good");
      yield this.sync(gatewayName);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error clearing hardware filter list: ${error}`, "bad");
    }
    this.stopLoading();
  });

  repairFilterList = flow(function* (gatewayName) {
    this.startLoading();
    try {
      yield Api.repairFilterList(gatewayName);
      this.rootStore.notificationStore.addNotification("Filter list has been repaired successfully", "good");

      // Update both filter lists
      yield this.sync(gatewayName);
      yield this.rootStore.gatewayFilterListStore.sync(gatewayName);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error repairing filter list: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
