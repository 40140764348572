import {observable, computed, flow} from "mobx";
import dayjs from "dayjs";

import * as Enums from "constants/enums";
import Api from "sensoteq-react-core/services/api";
import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import {deepGet} from "services/Utils";
import {calculateEllipseData, calculateEllipseStrokeRatio, VibrationDomains} from "sensoteq-core/calculations";
import {AxisStrategies} from "sensoteq-core/enumerations";
import {
  displacementToAcceleration,
  displacementToVelocity,
  velocityToAcceleration,
  velocityToDisplacement,
} from "../services/MathService";

function mapRssiDbm(machData) {
  const rssiDbm = machData.data.info_data?.rssi_dbm;
  const rssi = machData.data.info_data?.rssi;

  if (rssiDbm !== undefined) {
    machData.data.info_data.rssi = rssiDbm;
    return machData;
  }
  if (rssi === undefined) return machData;

  if (typeof rssi === "number") {
    machData.data.info_data.rssi = rssi - 146;
    return machData;
  }
  const {avg, max, min, total, total_entries} = machData.data.info_data.rssi;
  const updated = {
    ...machData.data.info_data.rssi,
    avg: avg - 146,
    min: min - 146,
    max: max - 146,
    total: total + total_entries * -146,
  };
  machData.data.info_data.rssi = updated;
  return machData;
}

export default class PointDataSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      pointId: null,
      timeRange: null,
      name: null,
      getEllipseTime: null,
      setEllipseTime: null,
      getTimeOption: () => this.rootStore.uiStore.continuousDataConfig.timeOption,
      getTimeRange: () => this.rootStore.uiStore.continuousDataDates,
      getHistoricalDataCount: () => this.rootStore.uiStore.historicalDataCount,
      getLatestDataCount: () => this.rootStore.uiStore.latestDataCount,
      getAxis: () => this.rootStore.uiStore.continuousDataConfig.axis,
      getRotationAdjustment: () => this.rootStore.uiStore.ellipseDataConfig.rotationAdjustment,
      getFlipStrokePlots: () => this.rootStore.preferenceStore.flipStrokePlots,
    };
  }
  getParsedParams(params) {
    return {
      latestDataCount: params.getLatestDataCount(),
      historicalDataCount: params.getHistoricalDataCount(),
      timeOption: params.getTimeOption(),
      from: params.getTimeRange().from,
      to: params.getTimeRange().to,
    };
  }

  _dateDisplayFns = {
    raw: (date) => date.format("Do MMM, HH:mm"),
    hour: (date) => date.format("Do MMM, hA"),
    day: (date) => date.format("Do MMM"),
    month: (date) => date.format("MMMM YYYY"),
  };

  @observable.shallow _data;
  @observable.shallow _historicDataSeries;
  @observable _averagedType = "raw";

  getData = flow(function* ({
    timeOption,
    from,
    to,
    pointId,
    latestDataCount,
    historicalDataCount,
    getEllipseTime,
    setEllipseTime,
    timeRange,
  }) {
    if (!pointId) {
      return;
    }

    this.startLoading();
    try {
      if (timeRange) {
        const data = yield Api.getHistoricalPointData(pointId, from.valueOf(), to.valueOf(), historicalDataCount);
        const historicDataSeries = yield Api.getHistoricalPointDataSeries(
          pointId,
          from.valueOf(),
          to.valueOf(),
          historicalDataCount,
          timeRange,
        );

        this._data = data.averaged_data.map((machData) => mapRssiDbm(machData));
        this._historicDataSeries = historicDataSeries;
        this._averagedType = data.averaged_type;

        // Only reset ellipse time if the current requested time is not on the same day as we are on
        if (setEllipseTime && getEllipseTime && !from.isSame(getEllipseTime(), "day")) {
          setEllipseTime(from);
        }
      } else if (timeOption === Enums.TIME_OPTION_LATEST) {
        const data = yield Api.getPointData(pointId, latestDataCount);
        this._data = data.point_data[pointId].map((machData) => mapRssiDbm(machData));
        this._averagedType = "raw";
        this._historicDataSeries = null;
        // Reset ellipse time as latest data can change
        // Use the "to" parameter as that is the current time
        if (setEllipseTime) {
          setEllipseTime(to);
        }
      } else {
        const data = yield Api.getHistoricalPointData(pointId, from.valueOf(), to.valueOf(), historicalDataCount);
        this._data = data.averaged_data.map((machData) => mapRssiDbm(machData));
        this._averagedType = data.averaged_type;
        this._historicDataSeries = null;

        // Only reset ellipse time if the current requested time is not on the same day as we are on
        if (setEllipseTime && getEllipseTime && !from.isSame(getEllipseTime(), "day")) {
          setEllipseTime(from);
        }
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting sensor data: ${error}`, "bad");
    }
    this.stopLoading();
  });

  getChannelDataForIndex = function (index, offset = null, scalingFactor = null, reverseBooleanValues = null) {
    let data = this.extractData(["data", "sensor_channel_data", `channel_${index}`], "channelData");
    if (offset) {
      data[0].data.forEach((entry) => {
        entry["channelData"] = entry["channelData"] + parseFloat(offset);
      });
    }
    if (scalingFactor) {
      data[0].data.forEach((entry) => {
        entry["channelData"] = entry["channelData"] * parseFloat(scalingFactor);
      });
    }
    if (reverseBooleanValues) {
      data[0].data.forEach((entry) => {
        // Changes 0 -> 1 and 1 -> 0
        entry["channelData"] = (entry["channelData"] + 1) % 2;
      });
    }
    return data;
  };

  @computed get axis() {
    const hvaAxis = this.params.getAxis();
    if (!this.sensorInfo) {
      return Enums.X_AXIS;
    } else {
      return this.sensorInfo.getXYZAxis(hvaAxis);
    }
  }

  @computed get sensorInfo() {
    return this.rootStore.sensorStore.getSensorByPointId(this.params.pointId);
  }

  @computed get _strategy() {
    return this.sensorInfo ? this.sensorInfo.strategy : AxisStrategies.X_UP;
  }

  @computed get _sensorData() {
    if (!this._data) {
      return [];
    }
    return this._data;
  }

  @computed get _sensorHistoricDataSeries() {
    if (!this._historicDataSeries) {
      return [];
    }
    return this._historicDataSeries;
  }

  @computed get validData() {
    return this._sensorData.length !== 0;
  }

  @computed get thermalDataBox() {
    let boxList = [];
    this._sensorData.forEach((entry) => {
      const mbox = entry.data?.boxes_data;

      if (mbox && Array.isArray(mbox)) {
        mbox.forEach((box) => {
          if (!boxList[box.box]) {
            boxList[box.box] = [
              {
                data: [],
                title: "Minimum",
              },
              {
                data: [],
                title: "Maximum",
              },
            ];
          }

          boxList[box.box][0]["data"].push({
            temperature: box.minTemp,
            date: dayjs(entry.timestamp),
          });

          boxList[box.box][1]["data"].push({
            temperature: box.maxTemp,
            date: dayjs(entry.timestamp),
          });
        });
      }
    });

    return boxList;
  }

  @computed get thermalData() {
    let minData = [],
      avgData = [],
      maxData = [];
    if (this.dataAveraged) {
      // Extract max max, average average and min min
      this._sensorData.forEach((entry) => {
        const mbox = entry.data?.mbox?.["1"];
        if (!mbox) {
          return;
        }
        minData.push({
          temperature: mbox.minTemp.min,
          date: dayjs(entry.timestamp),
        });
        avgData.push({
          temperature: mbox.avgTemp.avg,
          date: dayjs(entry.timestamp),
        });
        maxData.push({
          temperature: mbox.maxTemp.max,
          date: dayjs(entry.timestamp),
        });
      });
    } else {
      this._sensorData.forEach((entry) => {
        const mbox = entry.data?.mbox?.["1"];
        if (!mbox || mbox.minTemp == null) {
          return;
        }
        minData.push({
          temperature: mbox.minTemp,
          coords: [mbox.minX, mbox.minY],
          date: dayjs(entry.timestamp),
        });
        avgData.push({
          temperature: mbox.avgTemp,
          date: dayjs(entry.timestamp),
        });
        maxData.push({
          temperature: mbox.maxTemp,
          coords: [mbox.maxX, mbox.maxY],
          date: dayjs(entry.timestamp),
        });
      });
    }
    return [
      {
        data: minData,
        title: "Minimum",
      },
      {
        data: avgData,
        title: "Average",
      },
      {
        data: maxData,
        title: "Maximum",
      },
    ];
  }

  @computed get temperatureData() {
    return this.extractData(["data", "info_data", "temperature"], "temperature");
  }

  @computed get voltageData() {
    return this.extractData(["data", "info_data", "voltage"], "voltage");
  }

  @computed get validityData() {
    return this.extractData(["data", "info_data", "validity"], "validity");
  }

  @computed get rssiData() {
    return this.extractData(["data", "info_data", "rssi"], "rssi");
  }

  @computed get tickCountData() {
    return this.extractData(["data", "info_data", "tick_count"], "tick_count");
  }

  @computed get signalStrengthData() {
    return this.extractData(["data", "gateway_data", "signal_strength"], "signal");
  }

  @computed get signalQualityData() {
    return this.extractData(["data", "gateway_data", "signal_quality"], "quality");
  }

  @computed get ambientTemperatureData() {
    return this.extractData(["data", "gateway_data", "temperature"], "temperature");
  }

  @computed get humidityData() {
    return this.extractData(["data", "gateway_data", "humidity"], "humidity");
  }

  @computed get deltaTemperatureData() {
    return this.extractData(["data", "info_data", "temp_delta"], "temperature");
  }

  @computed get accelerationRMSData() {
    return this.extractData(["data", "axis_rms", this.axis], "rms");
  }

  @computed get velocityRMSData() {
    return this.extractData(["data", "axis_vel_rms", this.axis], "rms");
  }

  @computed get peakToPeakData() {
    return this.extractData(["data", "axis_peak", this.axis], "amplitude");
  }

  @computed get hasSignalQualityData() {
    return this.signalQualityData && this.signalQualityData.length > 0 && this.signalQualityData[0].data.length > 0;
  }

  @computed get totalAccelerationRMSData() {
    if (!this.sensorInfo) {
      return [];
    }
    const x = this.extractData(["data", "axis_rms", "x"], "rms");
    const y = this.extractData(["data", "axis_rms", "y"], "rms");
    const z = this.extractData(["data", "axis_rms", "z"], "rms");
    return x.map((set, setIdx) => ({
      ...set,
      data: set.data.map((x, entryIdx) => ({
        ...x,
        rms: x.rms + y[setIdx].data[entryIdx].rms + z[setIdx].data[entryIdx].rms,
      })),
    }));
  }

  @computed get axisDeltaData() {
    if (!this.sensorInfo) {
      return [];
    }
    return this.extractData(["data", "axis_deltas", this.axis, "max_delta"], "value", [
      "data",
      "axis_deltas",
      this.axis,
      "delta",
    ]);
  }

  // X axis domains
  @computed get ellipseXDisplacementData() {
    return this.extractData(["data", "screen_data", "displacement_x"], "value");
  }
  @computed get ellipseXVelocityData() {
    let xVel = this.extractData(["data", "screen_data", "velocity_x"], "value");
    return this.convertDomainIfRequired(xVel, displacementToVelocity, this.ellipseXDisplacementData);
  }
  @computed get ellipseXAccelerationData() {
    let xAcc = this.extractData(["data", "screen_data", "acceleration_x"], "value");
    return this.convertDomainIfRequired(xAcc, displacementToAcceleration, this.ellipseXDisplacementData);
  }

  // Y axis domains
  @computed get ellipseYDisplacementData() {
    return this.extractData(["data", "screen_data", "displacement_y"], "value");
  }
  @computed get ellipseYVelocityData() {
    let yVel = this.extractData(["data", "screen_data", "velocity_y"], "value");
    return this.convertDomainIfRequired(yVel, displacementToVelocity, this.ellipseYDisplacementData);
  }
  @computed get ellipseYAccelerationData() {
    let yAcc = this.extractData(["data", "screen_data", "acceleration_y"], "value");
    return this.convertDomainIfRequired(yAcc, displacementToAcceleration, this.ellipseYDisplacementData);
  }

  // Z axis domains
  @computed get ellipseZDisplacementData() {
    let zDisp = this.extractData(["data", "screen_data", "displacement_z"], "value");
    return this.convertDomainIfRequired(zDisp, velocityToDisplacement, this.ellipseZVelocityData);
  }
  @computed get ellipseZVelocityData() {
    return this.extractData(["data", "screen_data", ["velocity_z", "deflection"]], "value");
  }
  @computed get ellipseZAccelerationData() {
    let zAcc = this.extractData(["data", "screen_data", "acceleration_z"], "value");
    return this.convertDomainIfRequired(zAcc, velocityToAcceleration, this.ellipseZVelocityData);
  }

  @computed get ellipseStrokeAngleData() {
    return this.extractData(["data", "screen_data", "stroke_angle"], "value");
  }

  @computed get ellipseRotationData() {
    return this.extractData(["data", "screen_data", "rotation"], "value");
  }

  @computed get ellipseRpmData() {
    return this.extractData(["data", "screen_data", "rpm"], "value");
  }

  @computed get ellipseStrokeLengthData() {
    return this.extractData(["data", "screen_data", "stroke_length"], "value");
  }

  @computed get ellipseStrokeRatioData() {
    return this.extractData(["data", "screen_data", "stroke_ratio"], "value");
  }

  @computed get ellipseStrokeLengthAndRPMData() {
    return this.extractMultipleDataKeys([
      {outputKey: "stroke_length", path: ["data", "screen_data", "stroke_length"]},
      {outputKey: "rpm", path: ["data", "screen_data", "rpm"]},
    ]);
  }

  @computed get ellipsePhaseData() {
    return this.extractData(["data", "phase_data", "phase"], "phase");
  }

  @computed get ellipsePhaseAngleData() {
    return this.extractData(["data", "screen_data", "phase_angle"], "phase");
  }

  @computed get ellipsePhaseMaxXPeakData() {
    return this.extractData(["data", "phase_data", "x_samples", "max"], "value");
  }

  @computed get ellipsePhaseMinXPeakData() {
    return this.extractData(["data", "phase_data", "x_samples", "min"], "value");
  }

  @computed get ellipsePhaseMaxYPeakData() {
    return this.extractData(["data", "phase_data", "y_samples", "max"], "value");
  }

  @computed get ellipsePhaseMinYPeakData() {
    return this.extractData(["data", "phase_data", "y_samples", "min"], "value");
  }

  @computed get ellipsePhaseMaxZPeakData() {
    return this.extractData(["data", "phase_data", "z_samples", "max"], "value");
  }

  @computed get ellipsePhaseMinZPeakData() {
    return this.extractData(["data", "phase_data", "z_samples", "min"], "value");
  }

  @computed get ellipsePhasePeakDifferenceData() {
    let data = [{data: []}];
    const maxIdx = this.dataAveraged ? 2 : 0;
    const minIdx = 0;
    for (let i = 0; i < this.ellipsePhaseMaxXPeakData[maxIdx].data.length; i++) {
      data[0].data.push({
        value:
          this.ellipsePhaseMaxXPeakData[maxIdx].data[i].value -
          this.ellipsePhaseMinXPeakData[minIdx].data[i].value +
          (this.ellipsePhaseMaxYPeakData[maxIdx].data[i].value - this.ellipsePhaseMinYPeakData[minIdx].data[i].value) +
          (this.ellipsePhaseMaxZPeakData[maxIdx].data[i].value - this.ellipsePhaseMinZPeakData[minIdx].data[i].value),
        date: this.ellipsePhaseMaxXPeakData[maxIdx].data[i].date,
      });
    }
    return data;
  }

  @computed get screenUptimeData() {
    let data = [];
    let previousItem = null;
    const initialData = this.ellipsePhasePeakDifferenceData[0].data;
    for (let i = 0; i < initialData.length; i++) {
      const item = {
        date: initialData[i].date,
        running: initialData[i].value > 2000,
      };

      if (previousItem && previousItem.running !== item.running) {
        data.push({
          date: previousItem.date,
          running: !previousItem.running,
        });
      }
      data.push(item);
      previousItem = item;
    }

    return [
      {
        data: data,
      },
    ];
  }

  @computed get validEllipseSets() {
    let sets = [];
    const rotationAdjustment = this.params.getRotationAdjustment();
    const flip = this.params.getFlipStrokePlots();
    this._sensorData.forEach((entry) => {
      // Check if each set is valid by attempting to calculate an ellipse
      const {x_axis_peaks, y_axis_peaks, z_axis_peaks, phase_data} = entry.data ?? {};
      const displacementData = calculateEllipseData(
        x_axis_peaks,
        y_axis_peaks,
        z_axis_peaks,
        phase_data,
        rotationAdjustment,
        this._strategy,
        VibrationDomains.DISPLACEMENT,
        flip,
      );
      const accelerationData = calculateEllipseData(
        x_axis_peaks,
        y_axis_peaks,
        z_axis_peaks,
        phase_data,
        rotationAdjustment,
        this._strategy,
        VibrationDomains.ACCELERATION,
        flip,
      );
      if (displacementData != null && accelerationData != null) {
        const {coordinates, strokeLength, strokeAngle} = displacementData;
        displacementData.strokeRatio = calculateEllipseStrokeRatio(coordinates, strokeLength, strokeAngle);
        sets.push({
          timestamp: entry.timestamp,
          displacementData,
          accelerationData,
          index: sets.length,
        });
      }
    });
    return sets;
  }

  validEllipseSetsHistoricData(entry) {
    let sets = [];
    const rotationAdjustment = this.params.getRotationAdjustment();
    const flip = this.params.getFlipStrokePlots();

    entry.forEach((value) => {
      // Check if each set is valid by attempting to calculate an ellipse
      const {x_axis_peaks, y_axis_peaks, z_axis_peaks, phase_data} = value.data ?? {};
      const displacementData = calculateEllipseData(
        x_axis_peaks,
        y_axis_peaks,
        z_axis_peaks,
        phase_data,
        rotationAdjustment,
        this._strategy,
        VibrationDomains.DISPLACEMENT,
        flip,
      );

      if (displacementData != null) {
        sets.push({
          timestamp: value.timestamp,
          displacementData,
          index: sets.length,
        });
      }
    });

    return sets;
  }

  @computed get ellipseOptions() {
    return this.validEllipseSets.map((set) => ({
      label: dayjs(set.timestamp).format("HH:mm"),
      value: set.timestamp,
      index: set.index,
    }));
  }

  @computed get ellipseData() {
    if (!this.ellipseOptions.length) {
      return null;
    }

    // Find best set matching the target time
    const target = (this.params.getEllipseTime?.() || dayjs()).valueOf();
    let bestSet = null;
    if (this.params.getEllipseTime) {
      let min = Number.MAX_SAFE_INTEGER;
      this.validEllipseSets.forEach((set) => {
        const diff = Math.abs(set.timestamp - target);
        if (diff < min) {
          min = diff;
          bestSet = set;
        }
      });
    }
    return bestSet;
  }

  @computed get ellipseHistoricDataSeries() {
    if (!this.ellipseOptions.length) {
      return null;
    }

    let bestSetArray = [];
    let bestSet = null;

    if (this.params.getEllipseTime) {
      this._sensorHistoricDataSeries.forEach((entry) => {
        this.validEllipseSetsHistoricData(entry).forEach((set) => {
          bestSet = set;
        });

        bestSetArray.push(bestSet);
        bestSet = null;
      });
    }

    return bestSetArray;
  }

  @computed get dataAveraged() {
    return this._averagedType !== "raw";
  }

  @computed get dateDisplayFn() {
    return this._dateDisplayFns[this._averagedType] || this._dateDisplayFns["raw"];
  }

  extractData(rawDataPath, valueKey, averagedDataPath = rawDataPath) {
    if (this.dataAveraged) {
      let minData = [],
        avgData = [],
        maxData = [];
      this._sensorData.forEach((entry) => {
        const value = deepGet(entry, averagedDataPath);
        if (value == null) {
          return;
        }
        minData.push({
          [valueKey]: value.min,
          date: dayjs(entry.timestamp),
        });
        avgData.push({
          [valueKey]: value.avg,
          date: dayjs(entry.timestamp),
        });
        maxData.push({
          [valueKey]: value.max,
          date: dayjs(entry.timestamp),
        });
      });
      return [
        {
          data: minData,
          title: "Minimum",
        },
        {
          data: avgData,
          title: "Average",
        },
        {
          data: maxData,
          title: "Maximum",
        },
      ];
    } else {
      let data = [];
      this._sensorData.forEach((entry) => {
        const value = deepGet(entry, rawDataPath);
        if (value == null) {
          return;
        }
        data.push({
          [valueKey]: value,
          date: dayjs(entry.timestamp),
        });
      });
      return [{data}];
    }
  }

  extractMultipleDataKeys(extractionList, averagedExtractionList = extractionList) {
    const extractionListLength = extractionList.length;
    if (this.dataAveraged) {
      const minData = [];
      const avgData = [];
      const maxData = [];

      for (let i = 0, len = this._sensorData.length; i < len; i++) {
        const entry = this._sensorData[i];
        const values = {min: {}, avg: {}, max: {}};

        let nullKeyValueCount = 0;
        for (const extraction of averagedExtractionList) {
          const value = deepGet(entry, extraction.path);
          if (value == null) {
            nullKeyValueCount += 1;
            continue;
          }

          values.min[extraction.outputKey] = value.min;
          values.avg[extraction.outputKey] = value.avg;
          values.max[extraction.outputKey] = value.max;
        }

        if (nullKeyValueCount === extractionListLength) continue;
        const date = dayjs(entry.timestamp);

        minData.push({...values.min, date});
        avgData.push({...values.avg, date});
        maxData.push({...values.max, date});
      }

      return [
        {
          data: minData,
          title: "Minimum",
        },
        {
          data: avgData,
          title: "Average",
        },
        {
          data: maxData,
          title: "Maximum",
        },
      ];
    } else {
      const data = [];

      for (let i = 0, len = this._sensorData.length; i < len; i++) {
        const entry = this._sensorData[i];
        const values = {};

        let nullKeyValueCount = 0;
        for (const extraction of extractionList) {
          const value = deepGet(entry, extraction.path);
          if (value == null) {
            nullKeyValueCount += 1;
            continue;
          }
          values[extraction.outputKey] = value;
        }

        if (nullKeyValueCount === extractionListLength) continue;
        data.push({
          ...values,
          date: dayjs(entry.timestamp),
        });
      }

      return [{data}];
    }
  }

  convertDomainIfRequired(data, conversionFunction, conversionData = null) {
    const newData = [];
    for (let i = 0; i < this.ellipseRpmData.length; i++) {
      const rpmSet = this.ellipseRpmData[i];
      const calcData = data[i];
      let newSet = [];
      for (let x = 0; x < rpmSet.data.length; x++) {
        let value = null;
        const foundCalcData = calcData.data.find((entry) => entry.date.isSame(rpmSet.data[x].date));
        if (foundCalcData) {
          value = foundCalcData.value;
        } else if (conversionData) {
          const foundConversionData = conversionData[i].data.find((entry) => entry.date.isSame(rpmSet.data[x].date));
          if (!foundConversionData) {
            // Couldn't find either a calc'd or convertable entry.
            continue;
          }

          value = conversionFunction(foundConversionData.value, rpmSet.data[x].value / 60);
        } else {
          // No calc entry and no conversion data supplied.
          continue;
        }

        newSet.push({
          date: rpmSet.data[x].date,
          value,
        });
      }
      newData.push({
        title: rpmSet.title,
        data: newSet,
      });
    }
    return newData;
  }
}
