import Loadable from "./Loadable";

export default class LoadableStore extends Loadable {
  rootStore;

  constructor(rootStore) {
    super();
    this.rootStore = rootStore;
    this.initialise();
  }

  handleDisposal(createSub) {
    this.rootStore.disposeWithRootStore(createSub());
  }
}
