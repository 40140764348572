import {computed, action} from "mobx";
import MultiDataSubscription from "./MultiDataSubscription";
import {smartRound} from "sensoteq-core/calculations";
import {GRAVITY} from "sensoteq-core/enumerations";

export default class MultiChiDataSetSubscription extends MultiDataSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      identifyingParam: "dataId",
      getSubscribableStore: (x) => x.chiDataSetStore,
      keys: [],
    };
  }

  @computed get keyLookupMap() {
    let map = {};
    this.params.keys.forEach((key) => {
      map[key.data_id] = key;
    });
    return map;
  }

  @computed get meanValues() {
    let totalDisplacementAmplitude = 0;
    let totalAccelerationAmplitude = 0;
    let totalFrequency = 0;
    let validSets = 0;

    this.subscriptions.forEach((sub) => {
      if (!sub || !sub.dataSet || !sub.dataSet.ellipseData) {
        return;
      }

      const {displacementData, accelerationData} = sub.dataSet.ellipseData;
      totalDisplacementAmplitude += displacementData.strokeLength;
      totalAccelerationAmplitude += accelerationData.strokeLength;
      totalFrequency += displacementData.frequency;
      validSets++;
    });

    if (validSets > 0) {
      totalDisplacementAmplitude /= validSets;
      totalAccelerationAmplitude /= validSets;
      totalFrequency /= validSets;
    }

    return {
      strokeLength: totalDisplacementAmplitude,
      mainG: totalAccelerationAmplitude,
      frequency: totalFrequency,
    };
  }

  calculateAverageEllipseData(sensorId) {
    if (this.initialLoad || !this.subscriptions.length) {
      return null;
    }
    const subscriptions = this.subscriptions.filter((x) => this.keyLookupMap[x.params.dataId].sensor_id === sensorId);
    if (!subscriptions.length) {
      return null;
    }
    let averageData = {};
    const ellipseKeys = [
      "deltaX",
      "deltaY",
      "deltaZ",
      "rotation",
      "frequency",
      "phaseAngle",
      "deflection",
      "strokeAngle",
      "strokeLength",
    ];
    const keyMap = {
      displacementData: ellipseKeys,
      accelerationData: ellipseKeys,
      velocityData: ellipseKeys,
      deflectionData: ["acceleration", "velocity", "displacement"],
      deflection1xRPMPeak: ["acceleration", "velocity", "displacement"],
    };
    Object.entries(keyMap).forEach(([section, sectionKeys]) => {
      averageData[section] = {};
      sectionKeys.forEach((key) => {
        averageData[section][key] = 0;
      });
      subscriptions.forEach((sub) => {
        sectionKeys.forEach((key) => {
          averageData[section][key] += sub.dataSet.ellipseData[section][key];
        });
      });
      sectionKeys.forEach((key) => {
        averageData[section][key] /= subscriptions.length;
      });
    });
    return averageData;
  }

  @computed get averageEllipseData() {
    if (this.initialLoad || !this.subscriptions.length) {
      return null;
    }
    let sensorMap = {};
    this.subscriptions.forEach((sub) => {
      const key = this.keyLookupMap[sub.params.dataId];
      const sensorId = key.sensor_id;
      if (sensorMap[sensorId] == null) {
        sensorMap[sensorId] = {
          ...this.calculateAverageEllipseData(sensorId),
          sensorId,
          position: key.position,
        };
      }
    });
    return Object.values(sensorMap);
  }

  @computed get session() {
    return this.params.subParams.session;
  }

  @action compareStats = (sub1, sub2, getValue) => {
    if (!sub1 || !sub2 || !sub1.data || !sub2.data) {
      return "-";
    }

    return smartRound(100 * Math.abs((getValue(sub1) - getValue(sub2)) / ((getValue(sub1) + getValue(sub2)) / 2)));
  };

  @action compareSensors = (sub1, sub2) => {
    return {
      g: this.compareStats(sub1, sub2, (x) => x.data.accelerationData.strokeLength),
      x: this.compareStats(sub1, sub2, (x) => x.data.accelerationData.deltaX),
      y: this.compareStats(sub1, sub2, (x) => x.data.accelerationData.deltaY),
      z: this.compareStats(
        sub1,
        sub2,
        (x) =>
          (2 * Math.PI * x.data.accelerationData.frequency * x.data.accelerationData.deflection) / (GRAVITY * 1000),
      ),
    };
  };

  getAllOfValue(selectValue, lfSub, ldSub, rfSub, rdSub) {
    if (!lfSub || !ldSub || !rfSub || !rdSub || !lfSub.data || !ldSub.data || !rfSub.data || !rdSub.data) {
      return null;
    }
    return {
      lf: selectValue(lfSub),
      ld: selectValue(ldSub),
      rf: selectValue(rfSub),
      rd: selectValue(rdSub),
    };
  }

  getGValues(lfSub, ldSub, rfSub, rdSub) {
    return this.getAllOfValue((x) => x.data.accelerationData.strokeLength, lfSub, ldSub, rfSub, rdSub);
  }

  getXValues(lfSub, ldSub, rfSub, rdSub) {
    return this.getAllOfValue((x) => x.data.accelerationData.deltaX, lfSub, ldSub, rfSub, rdSub);
  }

  getYValues(lfSub, ldSub, rfSub, rdSub) {
    return this.getAllOfValue((x) => x.data.accelerationData.deltaY, lfSub, ldSub, rfSub, rdSub);
  }

  getZValues(lfSub, ldSub, rfSub, rdSub) {
    return this.getAllOfValue(
      (x) => (2 * Math.PI * x.data.accelerationData.frequency * x.data.accelerationData.deflection) / (GRAVITY * 1000),
      lfSub,
      ldSub,
      rfSub,
      rdSub,
    );
  }

  roll(lfSub, ldSub, rfSub, rdSub) {
    const gValues = this.getGValues(lfSub, ldSub, rfSub, rdSub);
    if (!gValues) {
      return "-";
    }
    return smartRound(Math.abs(100 * ((gValues.lf + gValues.ld) / (gValues.rf + gValues.rd) - 1)));
  }

  pitch(lfSub, ldSub, rfSub, rdSub) {
    const gValues = this.getGValues(lfSub, ldSub, rfSub, rdSub);
    if (!gValues) {
      return "-";
    }
    return smartRound(Math.abs(100 * ((gValues.lf + gValues.rf) / (gValues.ld + gValues.rd) - 1)));
  }

  getTwistValue(values) {
    if (!values) {
      return "-";
    }
    const average = (values.ld + values.rd + values.lf + values.rf) / 4;
    return smartRound(100 * Math.abs((values.ld - values.rd - (values.lf - values.rf)) / average));
  }

  twist(lfSub, ldSub, rfSub, rdSub) {
    return {
      g: this.getTwistValue(this.getGValues(lfSub, ldSub, rfSub, rdSub)),
      x: this.getTwistValue(this.getXValues(lfSub, ldSub, rfSub, rdSub)),
      y: this.getTwistValue(this.getYValues(lfSub, ldSub, rfSub, rdSub)),
      z: this.getTwistValue(this.getZValues(lfSub, ldSub, rfSub, rdSub)),
    };
  }
}
