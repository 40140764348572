import {action, computed, flow, observable} from "mobx";
import _ from "lodash";

import Api from "sensoteq-react-core/services/api";
import LoadableStore from "sensoteq-react-core/models/LoadableStore";
import Curves from "sensoteq-react-graphs/curves";
import hashSum from "services/HashSum";

export default class PreferenceStore extends LoadableStore {
  Units = {
    // Acceleration
    INCHES_PER_SECOND_SQUARED: "in/s²",
    G: "g",
    MG: "mg",
    // Velocity
    INCHES_PER_SECOND: "in/s",
    MM_PER_SECOND: "mm/s",
    // Displacement
    MILS: "mils",
    MICRONS: "microns",
    MM: "mm",
    INCHES: "in",
    // Frequency
    HERTZ: "Hz",
    CPM: "CPM",
    // Temperature
    CELSIUS: "°C",
    FAHRENHEIT: "°F",
    // Misc
    PERCENTAGE: "%",
    MV: "mV",
    // Angle
    DEGREES: "deg",
    RADIANS: "radians",
    // Deflection (technically not a unit, but it fits best here)
    RSS: "FFT RSS",
    PEAK: "Peak FFT amplitude",
    PEAK_AT_RUNNING_SPEED: "1xRPM FFT Peak",

    LB: "lb",
    GRAM: "g",
  };

  UnitsByPreferenceKey = {
    acceleration: [
      {
        unit: this.Units.G,
        measurementSystem: "metric",
      },
      {
        unit: this.Units.MG,
        measurementSystem: "metric",
      },
      {
        unit: this.Units.INCHES_PER_SECOND_SQUARED,
        measurementSystem: "imperial",
      },
    ],
    velocity: [
      {
        unit: this.Units.MM_PER_SECOND,
        measurementSystem: "metric",
      },
      {
        unit: this.Units.INCHES_PER_SECOND,
        measurementSystem: "imperial",
      },
    ],
    displacement: [
      {unit: this.Units.MICRONS, measurementSystem: "metric"},
      {unit: this.Units.MILS, measurementSystem: "metric"},
    ],
    strokeDisplacement: [
      {unit: this.Units.MM, measurementSystem: "metric"},
      {
        unit: this.Units.INCHES,
        measurementSystem: "imperial",
      },
    ],
    frequency: [
      {unit: this.Units.HERTZ, measurementSystem: "metric"},
      {unit: this.Units.CPM, measurementSystem: null},
    ],
    temperature: [
      {unit: this.Units.CELSIUS, measurementSystem: "metric"},
      {unit: this.Units.FAHRENHEIT, measurementSystem: "imperial"},
    ],
    angle: [
      {unit: this.Units.DEGREES, measurementSystem: "metric"},
      {unit: this.Units.RADIANS, measurementSystem: "imperial"},
    ],
    weight: [
      {unit: this.Units.GRAM, measurementSystem: "metric"},
      {unit: this.Units.LB, measurementSystem: "imperial"},
    ],
  };

  @computed get preferences() {
    const user = this.rootStore.userStore.user;
    if (!user) {
      return null;
    }
    return user.preferences;
  }

  @computed get unitPreferences() {
    if (!this.preferences) {
      return null;
    }
    return this.preferences.units;
  }

  @computed get calculationPreferences() {
    if (!this.preferences) return null;
    return this.preferences.calculations;
  }

  @computed get graphPreferences() {
    if (!this.preferences) {
      return null;
    }
    return this.preferences.graphs;
  }

  @computed get machineAnalysisMetricsPreferences() {
    if (!this.preferences) {
      return {};
    }
    return this.preferences.machineAnalysisMetrics || {};
  }

  @computed get trendingOverviewPreferences() {
    if (!this.preferences) {
      return {};
    }
    return this.preferences.trendingOverview || {};
  }

  @computed get miscPreferences() {
    if (!this.preferences) {
      return null;
    }
    return this.preferences.misc;
  }

  @computed get themePreferences() {
    if (!this.preferences) {
      return {};
    }
    return this.preferences.theme || {};
  }

  @computed get themePreferencesHash() {
    return hashSum(this.themePreferences);
  }

  tryGetMiscPreference(preference, fallback) {
    if (!this.miscPreferences) {
      return fallback;
    }
    return this.miscPreferences[preference] ?? fallback;
  }

  tryGetUnitPreference(domain, fallback = undefined) {
    if (!this.unitPreferences) {
      return fallback;
    }
    return this.unitPreferences[domain] ?? fallback;
  }

  tryGetGraphPreference(preference, fallback = false) {
    if (!this.graphPreferences) {
      return fallback;
    }
    return this.graphPreferences[preference] ?? fallback;
  }

  tryGetTrendingOverviewPreference(preference, fallback) {
    if (!this.trendingOverviewPreferences) {
      return fallback;
    }
    return this.trendingOverviewPreferences[preference] ?? fallback;
  }

  @computed get trendingOverviewOverall() {
    if (!this.preferences) {
      return {};
    }
    return this.tryGetTrendingOverviewPreference("overall", []);
  }

  @computed get trendingOverviewPower() {
    if (!this.preferences) {
      return {};
    }
    return this.tryGetTrendingOverviewPreference("power", []);
  }

  @computed get accelerationUnits() {
    return this.tryGetUnitPreference("acceleration");
  }

  @computed get velocityUnits() {
    return this.tryGetUnitPreference("velocity");
  }

  @computed get displacementUnits() {
    return this.tryGetUnitPreference("displacement");
  }

  @computed get temperatureUnits() {
    return this.tryGetUnitPreference("temperature");
  }

  @computed get frequencyUnits() {
    return this.tryGetUnitPreference("frequency");
  }

  @computed get strokeDisplacementUnits() {
    return this.tryGetUnitPreference("strokeDisplacement");
  }

  @computed get angleUnits() {
    return this.tryGetUnitPreference("angle");
  }

  @computed get deflectionUnits() {
    return this.tryGetUnitPreference("deflection");
  }

  @computed get useInchesPerSecondSquared() {
    return this.accelerationUnits === this.Units.INCHES_PER_SECOND_SQUARED;
  }

  @computed get useMG() {
    return this.accelerationUnits === this.Units.MG;
  }

  @computed get useInchesPerSecond() {
    return this.velocityUnits === this.Units.INCHES_PER_SECOND;
  }

  @computed get useMils() {
    return this.displacementUnits === this.Units.MILS;
  }

  @computed get useFahrenheit() {
    return this.temperatureUnits === this.Units.FAHRENHEIT;
  }

  @computed get useCPM() {
    return this.frequencyUnits === this.Units.CPM;
  }

  @computed get useInches() {
    return this.strokeDisplacementUnits === this.Units.INCHES;
  }

  @computed get useDegrees() {
    return this.angleUnits === this.Units.DEGREES;
  }

  @computed get useRSS() {
    return this.deflectionUnits === this.Units.RSS;
  }

  @computed get usePeak1xRPM() {
    return this.deflectionUnits === this.Units.PEAK_AT_RUNNING_SPEED;
  }

  @computed get highlightMissingData() {
    return this.tryGetGraphPreference("missingData");
  }

  @computed get autoscaleIfAlarms() {
    return this.tryGetGraphPreference("autoscale");
  }

  @computed get lineGraphThickness() {
    return this.tryGetGraphPreference("lineThickness", 2);
  }

  @computed get vibrationLineGraphThickness() {
    return this.tryGetGraphPreference("vibrationLineThickness", 1);
  }

  @computed get showPowerBands() {
    return this.tryGetGraphPreference("powerBands");
  }

  @computed get lineGraphCurve() {
    // If smoothing is enabled then let the graph decide what curve to use
    return this.tryGetGraphPreference("smooth") ? undefined : Curves.Linear;
  }

  @computed get flipStrokePlots() {
    return this.tryGetGraphPreference("flipStroke");
  }

  @computed get hideOfflineSites() {
    return this.tryGetMiscPreference("hideOfflineSites", false);
  }

  @computed get showAlarmNotifications() {
    return this.tryGetMiscPreference("showAlarmNotifications", false);
  }

  @computed get obscureNames() {
    return this.tryGetMiscPreference("obscureNames", false);
  }

  @computed get ignoreLongTermOfflinePoints() {
    return this.tryGetMiscPreference("ignoreLongTermOfflinePoints", false);
  }

  @computed get ignoreLongTermOfflinePointsDayThreshold() {
    try {
      return parseInt(this.tryGetMiscPreference("ignoreLongTermOfflinePointsDayThreshold", 365));
    } catch (e) {
      console.debug("Error parsing ignoreLongTermOfflinePointsDayThreshold", e);
      return 365;
    }
  }

  @computed get neighbourhoodWatchSites() {
    return this.tryGetMiscPreference("neighbourhoodWatchSites", []);
  }

  @computed get neighbourhoodWatchFlags() {
    return this.tryGetMiscPreference("neighbourhoodWatchFlags", []);
  }

  @computed get uiScale() {
    return this.themePreferences.uiScale || 1;
  }

  @computed get useBearingDefectLines() {
    return this.tryGetGraphPreference("bearingDefectLines", false);
  }

  @computed get syncGraphs() {
    return this.tryGetGraphPreference("sync", false);
  }

  @computed get sortAssetsByStatus() {
    return this.tryGetMiscPreference("sortByStatus", false);
  }

  @computed get releasePostDate() {
    const {releasePostDate} = this.preferences;
    return releasePostDate ? new Date(releasePostDate) : new Date(0);
  }

  @computed get layout() {
    return this.tryGetMiscPreference("layout", "v1");
  }

  updateUnitPreferences(preferences) {
    this._updatePreferences({
      ...this.preferences,
      units: preferences,
    });
  }

  updateCalculationPreferences(preferences) {
    this._updatePreferences({
      ...this.preferences,
      calculations: preferences,
    });
  }

  updateGraphPreferences(preferences) {
    this._updatePreferences({
      ...this.preferences,
      graphs: preferences,
    });
  }

  updateMiscPreferences(preferences) {
    return this._updatePreferences({
      ...this.preferences,
      misc: preferences,
    });
  }

  updateLayoutPreferences(layout) {
    return this.updateMiscPreferences({
      ...this.miscPreferences,
      layout,
    });
  }

  updateThemePreferences(preferences) {
    // When removing a custom hue from preferences revert CSS variable back to default prior to page-reload.
    if (!_.isEqual(preferences?.hues, this.preferences?.theme?.hues)) {
      Object.keys(preferences?.hues).forEach((hue) => {
        if (this.preferences?.theme?.hues?.[hue] && !preferences?.hues?.[hue]) {
          const rootSelector = document.querySelector(":root");
          const defaultHue = rootSelector.style.getPropertyValue(`--hue-default-${hue}`);
          rootSelector.style.setProperty(`--hue-${hue}`, defaultHue);
        }
      });
    }
    this._updatePreferences({
      ...this.preferences,
      theme: preferences,
    });
  }

  updateMachineAnalysisMetricsPreferences(preferences) {
    return this._updatePreferences({
      ...this.preferences,
      machineAnalysisMetrics: preferences,
    });
  }

  updateTrendingOverviewPreferences(preferences) {
    return this._updatePreferences({
      ...this.preferences,
      trendingOverview: preferences,
    });
  }

  updateRunningSpeedReferencePreferences(preferences) {
    const isRunningSpeedReferenceArray = Array.isArray(this.preferences.runningSpeedReference);

    let updatedRunningSpeedReference;

    if (isRunningSpeedReferenceArray) {
      const index = this.preferences.runningSpeedReference.findIndex((ref) => ref.sensorId === preferences.sensorId);

      if (index !== -1) {
        updatedRunningSpeedReference = this.preferences.runningSpeedReference.map((item, idx) =>
          idx === index ? {...item, ...preferences} : item,
        );
      } else {
        updatedRunningSpeedReference = [...this.preferences.runningSpeedReference, preferences];
      }
    } else {
      updatedRunningSpeedReference = [preferences];
    }

    const updatedPreferences = {
      ...this.preferences,
      runningSpeedReference: updatedRunningSpeedReference,
    };

    return this._updatePreferences(updatedPreferences);
  }

  updateReleasePostDate = flow(function* (releasePostDate) {
    try {
      yield Api.updatePreferences({
        ...this.preferences,
        releasePostDate,
      });
      yield this.rootStore.userStore.refreshSelf();
    } catch (error) {
      // This update happens in the background, not from an obvious user interaction
      // The functionality is non-critical so don't notify user on error
    }
  });

  _updatePreferences = flow(function* (preferences) {
    this.startLoading();
    try {
      yield Api.updatePreferences(preferences);
      yield this.rootStore.userStore.refreshSelf();
      this.rootStore.notificationStore.addNotification("Preferences updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating preferences: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
