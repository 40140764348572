export default function (themeStore, additionalInfo) {
  const title = additionalInfo.title
    ? `<h3 style="padding-left: 12px; padding-bottom: 8px; text-transform: uppercase; font-size: 12px; font-weight: 700">${additionalInfo.title}</h3>`
    : "";
  const displayData = additionalInfo.data.map((data) => ({
    ...data,
    values: Array.isArray(data.value) ? data.value : [data.value],
  }));

  const maxColSpan = Math.max(...displayData.map((data) => data.values.length));
  const labelStyles = `padding-left: 12px; border: none; width: 40%; text-transform: uppercase; color: ${themeStore.theme.colours.contentLight}`;
  const valueStyles = `color: inherit; border: none`;

  const getRowStyle = (index) => {
    const bgColor =
      index % 2 === 0 ? themeStore.theme.colours.contentBackgroundAlt : themeStore.theme.colours.contentBackground;
    const color = themeStore.theme.colours.content;
    return `background-color: ${bgColor}; height: 36px; border: none; color: ${color}`;
  };

  return (
    title +
    `<table style="width: 100%;">` +
    `<tbody>` +
    displayData
      .map((data, index) => {
        const colSpan = maxColSpan - data.values.length + 1;
        const valueCells = data.values
          .map((value) => `<td style="${valueStyles}" colSpan="${colSpan}">${value}</td>`)
          .join("");
        return `<tr style="${getRowStyle(index)}"><td style="${labelStyles}">${data.label}</td>${valueCells}</tr>`;
      })
      .join("") +
    `</tbody>` +
    `</table>` +
    `<p></p>`
  );
}
