import {observable, reaction, computed, flow} from "mobx";
import dayjs from "dayjs";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import cache from "services/CacheService";
import Api from "sensoteq-react-core/services/api";
import BluetoothDataSet from "./BluetoothDataSet";

export default class BluetoothDataSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      sensorId: null,
      getTime: () => this.rootStore.uiStore.bluetoothDataConfig.time,
      getKeyIndex: () => this.rootStore.uiStore.bluetoothDataConfig.keyIndex,
      getMode: () => this.rootStore.uiStore.bluetoothDataConfig.mode,
      getSettings: () => this.rootStore.uiStore.bluetoothDataConfig.settings,
    };
  }
  getParsedParams(params) {
    return {
      time: params.getTime(),
    };
  }
  getRequiredParamKeys() {
    return ["sensorId", "time"];
  }

  @observable.shallow _keyList;
  @observable.shallow _data;

  constructor(rootStore) {
    super(rootStore);

    // Pull new data sets when key changes
    this.disposeWithSubscription(() =>
      reaction(
        () => this.key,
        (key) => this.getSet(key),
      ),
    );
  }

  getData = flow(function* ({sensorId, time}) {
    if (sensorId == null) {
      return;
    }
    this.startLoading();
    try {
      const data = yield Api.getBleKeyList(sensorId, time.valueOf(), dayjs(time).endOf("day").valueOf());
      this._keyList = data.keys;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting bluetooth keys: ${error}`, "bad");
    }
    this.stopLoading();
  });

  getSet = flow(function* (key) {
    if (!key) {
      return;
    }
    this.startLoading();
    try {
      const data = yield cache(Api.getBleSet, key.data_id);
      this._data = {
        ...key,
        data: data.data.data,
      };
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting bluetooth set: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get key() {
    if (!this.keyList.length) {
      return null;
    }
    const keyIndex = this.params.getKeyIndex();
    if (keyIndex < 0 || keyIndex >= this.keyList.length) {
      return this.keyList[0];
    }
    return this.keyList[keyIndex];
  }

  @computed get keyId() {
    return this.key ? this.key.data_id : null;
  }

  @computed get keyList() {
    if (!this._keyList) {
      return [];
    }
    const mode = this.params.getMode();
    return this._keyList.filter((x) => x.mode === mode).reverse();
  }

  @computed get keyOptions() {
    return this.keyList.map((key) => ({
      label: dayjs(key.timestamp).format("HH:mm:ss"),
      value: key.data_id,
    }));
  }

  @computed get dataSet() {
    if (!this.key) {
      return null;
    }
    const settings = this.params.getSettings();
    return new BluetoothDataSet(this._data, this.key.sampling_frequency, this.key.fft_size, settings);
  }
}
