let INTERVALS = [];
let TIMERS = [];

module.exports.setInterval = (func, ms) => {
  let interval = setInterval(func, ms);
  INTERVALS.push(interval);
  return interval;
};

module.exports.setTimeout = (func, ms) => {
  let timeout = setTimeout(() => {
    module.exports.clearTimeout(timeout);
    func();
  }, ms);
  TIMERS.push(timeout);
  return timeout;
};

module.exports.clearInterval = (interval) => {
  let idx = INTERVALS.indexOf(interval);
  if (idx !== -1) {
    clearInterval(INTERVALS[idx]);
    INTERVALS.splice(idx, 1);
  }
};

module.exports.clearTimeout = (timeoutID) => {
  let idx = TIMERS.indexOf(timeoutID);
  if (idx !== -1) {
    clearTimeout(TIMERS[idx]);
    TIMERS.splice(idx, 1);
  }
};

module.exports.cleanup = () => {
  for (let interval of INTERVALS) {
    clearInterval(interval);
  }
  for (let timeout of TIMERS) {
    clearTimeout(timeout);
  }
};
