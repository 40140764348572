import React from "react";
import styled from "styled-components";
import Select, {components} from "react-select";
import ReactTooltip from "react-tooltip";
import {asField} from "../lib/mobx-form";
import Label from "./Label";

function OptionLayout(props) {
  return (
    <components.Option {...props}>
      <div>{props.data.label}</div>
      {props.data.description && <div>{props.data.description}</div>}
    </components.Option>
  );
}

function ExtendedSelect(props) {
  const {onChange, forwardedRef, label, field, tooltip, placeholder, options, fieldState, fieldApi, theme, searchable} =
    props;
  const {setValue} = fieldApi;
  const {value, disabled, error} = fieldState;

  const styles = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.colours.inputBackground,
      color: theme.forms.optionColor,
      marginTop: 0,
      marginBottom: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      ":active": {},
      backgroundColor: state.isFocused ? theme.colours.primaryHighlight : theme.colours.inputBackground,
      color: state.isFocused ? theme.forms.optionHoverColor : theme.forms.optionColor,
    }),
    control: (_, state) => ({
      backgroundColor: theme.colours.inputBackground,
      color: theme.colours.content,
      borderColor: state.isFocused ? theme.colours.primaryHighlight : theme.colours.border,
      boxShadow: "0 !important",
      borderStyle: "solid",
      borderWidth: theme.forms.borderWidth,
      borderRadius: theme.forms.borderRadius,
      lineHeight: "1.5rem",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      maxHeight: "35px",
      boxSizing: "border-box",
      cursor: searchable ? "text" : "default",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colours.content,
    }),
    indicatorSeparator: () => ({}),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: "35px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      maxHeight: "35px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.forms.optionColor,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.forms.optionColor,
    }),
  };

  let dataOptions = options;
  if (options.length > 0 && !(options[0] instanceof Object)) {
    dataOptions = options.map((option) => ({value: option, label: option}));
  }
  const matchedValue = dataOptions.filter((option) => option.value === value);
  const tooltipProps = tooltip ? {"data-tip": true, "data-for": `select-${label}`, "data-effect": "solid"} : {};

  return (
    <Container {...tooltipProps}>
      {label && <Label htmlFor={field}>{label}</Label>}
      {error && (
        <Label
          error
          htmlFor={field}
        >
          {error}
        </Label>
      )}
      <Wrapper>
        <Select
          components={{Option: OptionLayout}}
          styles={styles}
          options={dataOptions}
          ref={forwardedRef}
          value={matchedValue}
          error={!!error}
          disabled={disabled}
          userDisabled={disabled}
          placeholder={placeholder}
          isSearchable={searchable ?? false}
          onChange={(data) => {
            setValue(data.value);
            if (onChange) {
              onChange(data.value);
            }
          }}
        />
        {tooltip && (
          <ReactTooltip
            id={`select-${label}`}
            place="top"
          >
            {tooltip}
          </ReactTooltip>
        )}
      </Wrapper>
    </Container>
  );
}

export default asField(ExtendedSelect, "");

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
