import PropTypes from "prop-types";
import {observer} from "mobx-react";

import * as Styled from "./Branding.styles";
import {Link} from "sensoteq-react-core/lib/mobx-state-router";
import {HASH} from "../../hash";
import {useRootStore} from "stores";
import * as envConfig from "sensoteq-react-core/constants/environment-config";

// Create keyValue Colors object like { BlackRed: "BlackRed", WhiteRed: "WhiteRed"}
export const Colors = Object.keys(envConfig.getKey("theme_assets")).reduce((accumulator, color) => {
  return {...accumulator, [color]: color};
}, {});

function Branding(props) {
  const {size} = props;
  const {userStore, themeStore} = useRootStore();
  const {companyLogoURL, company} = userStore;
  const {theme, defaultThemeColor, textLogos, logos, title} = themeStore;
  const color = userStore.loggedIn ? theme.branding.analytixColor : defaultThemeColor;
  const analytixLogo = textLogos[color];
  const showAnalytix = !(theme.branding.companyLogo || theme.branding.companyLogoOverrideURL);

  // Logo
  let logo = logos.primary;
  // Allows different logo colours for specific environments
  if (logos.secondary && userStore.loggedIn) {
    logo = logos.secondary;
  }

  if (theme.branding.companyLogo) {
    logo = companyLogoURL;
  }
  if (theme.branding.companyLogoOverrideURL) {
    logo = theme.branding.companyLogoOverrideURL;
  }

  return (
    <Link
      shallow
      to="/"
    >
      <Styled.Container title={`${title}} #${HASH}`}>
        <Styled.Logo
          src={logo}
          alt="Company"
          size={size}
        />
        <Styled.Text size={size}>
          <Styled.Company
            analytix={showAnalytix}
            color={color}
            size={size}
          >
            {theme.branding.companyLogo ? company.display_name : ""}
          </Styled.Company>
          {showAnalytix && (
            <Styled.Product
              src={analytixLogo}
              size={size}
            />
          )}
        </Styled.Text>
      </Styled.Container>
    </Link>
  );
}

Branding.propTypes = {
  size: PropTypes.number,
};
Branding.defaultProps = {
  size: 2,
};

Branding.Colors = Colors;

export default observer(Branding);
