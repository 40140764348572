import {computed} from "mobx";

import LoadableStore from "sensoteq-react-core/models/LoadableStore";

export default class NeighbourhoodWatchStore extends LoadableStore {
  @computed get flags() {
    return this.rootStore.preferenceStore.neighbourhoodWatchFlags;
  }

  @computed get initialLoad() {
    return this.rootStore.preferenceStore.initialLoad;
  }

  @computed get loading() {
    return this.rootStore.preferenceStore.loading || this.rootStore.userStore.loading;
  }

  async saveFlags(flags) {
    await this.rootStore.preferenceStore.updateMiscPreferences({
      ...this.rootStore.preferenceStore.miscPreferences,
      neighbourhoodWatchFlags: flags,
    });
    await this.rootStore.userStore.refreshSelf();
  }

  async addFlag(reason, siteName, machineId, pointId) {
    await this.saveFlags([
      ...this.flags,
      {
        reason,
        siteName,
        machineId,
        pointId,
        timestamp: Date.now(),
      },
    ]);
  }

  async deleteFlag(flag) {
    if (!flag) {
      return;
    }
    const newFlags = this.flags.filter((x) => x.timestamp !== flag.timestamp);
    await this.saveFlags(newFlags);
  }

  async updateFlag(flag) {
    if (!flag) {
      return;
    }
    const index = this.flags.findIndex((x) => x.timestamp === flag.timestamp);
    if (index === -1) {
      return;
    }
    let newFlags = this.flags.slice();
    newFlags[index] = flag;
    await this.saveFlags(newFlags);
  }
}
