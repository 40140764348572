import {autorun, action, isObservable} from "mobx";
import dayjs from "dayjs";

export default function createPersistor({prefix, config, rootStore}) {
  let subscriptions = [];
  const getStorageKey = (store, prop) => `${prefix}-${store}-${prop}`;
  const removeItem = (store, prop) => localStorage.removeItem(getStorageKey(store, prop));
  const getItem = (store, prop) => localStorage.getItem(getStorageKey(store, prop));
  const setItem = (store, prop, value) => localStorage.setItem(getStorageKey(store, prop), value);
  const getKeys = (store, whitelist = [], blacklist = [], immune = []) => {
    if (whitelist.length) {
      return whitelist.filter((x) => immune.indexOf(x) === -1);
    }
    const fullBlackList = blacklist.concat(immune).concat(["rootStore"]);
    let keys = [];
    // eslint-disable-next-line
    for (const key in rootStore[store]) {
      if (Object.prototype.hasOwnProperty.call(rootStore[store], key) || isObservable(rootStore[store][key])) {
        if (fullBlackList.indexOf(key) === -1) {
          keys.push(key);
        }
      }
    }
    return keys;
  };
  const rehydrationHelper = (key, value) => {
    if (typeof value === "string") {
      const regexp = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\dZ$/.exec(value);
      if (regexp) {
        return dayjs(value);
      }
    }
    return value;
  };

  const persistor = {
    // Purges all persisted keys from local storage
    purge: () => {
      // Remove items from local storage, ignoring the blacklist if forcing
      config.forEach(({store, whitelist, blacklist = [], immune = []}) => {
        const keys = getKeys(store, whitelist, blacklist, immune);
        keys.forEach((prop) => {
          removeItem(store, prop);
        });
      });
    },

    // Rehydrates mobx stores with their equivalent values stored
    // in local storage
    rehydrate: action(() => {
      try {
        config.forEach(({store, whitelist, blacklist}) => {
          const keys = getKeys(store, whitelist, blacklist);
          keys.forEach((prop) => {
            const stored = getItem(store, prop);
            if (stored != null) {
              rootStore[store][prop] = JSON.parse(stored, rehydrationHelper);
            }
          });
        });
      } catch (error) {
        persistor.purge();
      }
    }),

    // Persists selected mobx store values in local storage
    persist: () => {
      subscriptions.forEach((cancelSubscription) => cancelSubscription());
      config.forEach(({store, whitelist, blacklist}) => {
        const keys = getKeys(store, whitelist, blacklist);
        keys.forEach((prop) => {
          subscriptions.push(
            autorun(() => {
              const value = rootStore[store][prop];
              if (value == null) {
                removeItem(store, prop);
              } else {
                const json = JSON.stringify(value);
                setItem(store, prop, json);
              }
            }),
          );
        });
      });
    },

    stopPersisting: () => {
      subscriptions.forEach((cancelSubscription) => cancelSubscription());
    },
  };

  // Add reference to persistor from rootStore
  rootStore.persistor = persistor;

  return persistor;
}
