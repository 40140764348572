import React from "react";
import PropTypes from "prop-types";
import autobind from "class-autobind-decorator";
import styled, {css} from "styled-components";

import {asField} from "../lib/mobx-form";
import * as Enums from "../../../apps/analytix/src/constants/enums";
import Label from "./Label";

@autobind
class Slider extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    theme: PropTypes.string,
    onChange: PropTypes.func,
  };
  static defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    onChange: null,
  };

  onChange(e) {
    const value = parseFloat(e.target.value);
    this.props.fieldApi.setValue(value);
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    const {max, min, label, step, theme, fieldState} = this.props;
    const {disabled} = fieldState;
    const value = fieldState.value ?? min;

    return (
      <Container sliderTheme={theme}>
        <SliderRow>
          <Label>{label}</Label>
          <Value>{value}</Value>
        </SliderRow>
        <input
          disabled={disabled}
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={this.onChange}
          name={this.props.field}
        />
      </Container>
    );
  }
}

Slider.Themes = Enums.StatusThemes;
export default asField(Slider);

const TrackStyles = css`
  width: 100%;
  height: 0.8rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.colours.background};
  border-radius: 0.4rem;
  box-shadow: inset 0 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.25);
`;

const ThumbStyles = css`
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: -0.5rem;
  border: none;

  &:focus {
    outline: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  * {
    user-select: none;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    outline: none;
    border: none;
    background-color: ${(props) => props.theme.colours.contentBackground};
  }

  input::-webkit-slider-runnable-track {
    ${TrackStyles}
  }
  input::-moz-range-track {
    ${TrackStyles}
  }

  input::-webkit-slider-thumb {
    ${ThumbStyles}
  }
  input::-moz-range-thumb {
    ${ThumbStyles}
  }

  ${(props) =>
    props.sliderTheme === Enums.StatusThemes.WARNING &&
    css`
      input::-webkit-slider-runnable-track {
        background-color: ${(props) => props.theme.colours.warning};
      }
      input::-moz-range-track {
        background-color: ${(props) => props.theme.colours.warning};
      }
    `}

  ${(props) =>
    props.sliderTheme === Enums.StatusThemes.BAD &&
    css`
      input::-webkit-slider-runnable-track {
        background-color: ${(props) => props.theme.colours.bad};
      }
      input::-moz-range-track {
        background-color: ${(props) => props.theme.colours.bad};
      }
    `}
`;

const SliderRow = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`;

const Value = styled.div`
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSizes.verySmall};
  margin-bottom: 0.5rem;
`;
