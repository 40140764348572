import {observable, computed, action, flow, runInAction} from "mobx";
import dayjs from "dayjs";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";
import {AlarmGroups, checkAlarmInGroup} from "sensoteq-core/enumerations";
import {revertAlarmSummaryString} from "sensoteq-core/cloud/alarm-summary";
import {AlarmTargetTypes} from "sensoteq-core/enumerations";
import * as Enums from "constants/enums";
import {deriveAlarmSummary, deriveDisplayValue, getDisplayValue, oldDisplayCondition} from "@sensoteq/core";

const TIME_OPTIONS = {
  [Enums.TIME_OPTION_LATEST]: [1, "week"],
  [Enums.TIME_OPTION_1D]: [1, "day"],
  [Enums.TIME_OPTION_1W]: [1, "week"],
  [Enums.TIME_OPTION_1M]: [1, "month"],
  [Enums.TIME_OPTION_6M]: [6, "months"],
  [Enums.TIME_OPTION_1Y]: [1, "year"],
  [Enums.TIME_OPTION_5Y]: [5, "year"],
  [Enums.TIME_OPTION_CUSTOM]: [5, "year"],
};

export default class AlarmEventSubscription extends DataSubscription {
  constructor(rootStore, config) {
    super(rootStore, {
      ...config,
      refreshDebounce: 30000,
    });
  }
  getDefaultParams() {
    return {
      getTimeOption: () => Enums.TIME_OPTION_1W,
      siteName: null,
      targetType: AlarmTargetTypes.SITE,
    };
  }
  getParsedParams(params) {
    return {
      timeOption: params.getTimeOption(),
    };
  }

  getRequiredParamKeys() {
    return [];
  }

  @observable.shallow _events = [];

  getData = flow(function* ({siteName, pointId, timeOption, targetType}) {
    this.startLoading();
    const subtractPeriod = TIME_OPTIONS[timeOption] || TIME_OPTIONS[Enums.TIME_OPTION_1W];
    try {
      const from = dayjs()
        .subtract(...subtractPeriod)
        .valueOf();

      const targetTypeParam = pointId ? AlarmTargetTypes.MEASURING_POINT : targetType;
      let target = pointId ? pointId : siteName;

      if (targetTypeParam === AlarmTargetTypes.LOGGED_IN_USER) {
        target = null;
      } else {
        if (!target) {
          this.stopLoading();
          return;
        }
      }

      const events = yield Api.getAlarmHistory(target, targetTypeParam, from, dayjs().valueOf());
      this._events = events.history;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting alarm events: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get events() {
    if (!this._events) {
      return [];
    }
    let enrichedEvents = [];
    this._events.forEach((event) => {
      const parsedTarget = this.rootStore.alarmStore.parseAlarmTarget(event.target);
      const preferences = this.rootStore.preferenceStore.unitPreferences;

      let condition = oldDisplayCondition(event.condition);
      let displayValue = getDisplayValue(event.value);
      if (event.characteristics) {
        const {severity, bounds, property, operator, direction} = event.characteristics;
        condition = deriveAlarmSummary(severity, bounds, property, operator, preferences, direction);
        displayValue = deriveDisplayValue(event.value, property, preferences);
      }

      enrichedEvents.push({
        ...event,
        data: {
          ...revertAlarmSummaryString(event.condition, parsedTarget.orientation, parsedTarget.sensorType),
          ...event.characteristics,
        },
        parsedTarget,
        condition,
        displayValue,
      });
    });
    return enrichedEvents;
  }

  @computed get _eventMappings() {
    let siteMappings = {};
    let machineMappings = {};
    let gatewaySpecificMappings = {};
    let pointMappings = {};
    let mappings = [siteMappings, machineMappings, pointMappings, gatewaySpecificMappings];

    // Process events by site and device ID
    this.events.forEach((event) => {
      const site = event.site_name;
      const {deviceName, machineId, pointId} = event.parsedTarget;
      const identifiers = [site, machineId, pointId, deviceName];

      // Process all possible identifiers
      for (let i = 0; i < identifiers.length; i++) {
        const identifier = identifiers[i];
        if (identifier != null) {
          const mapping = mappings[i];
          if (!mapping[identifier]) {
            mapping[identifier] = new DeviceStatus();
          }
          mapping[identifier].addEvent(event);
        }
      }
    });

    return {
      siteMappings,
      machineMappings,
      gatewayMappings: gatewaySpecificMappings,
      pointMappings,
    };
  }

  // Gets the alarm status of all sites
  getAllSitesStatus() {
    return new DeviceStatus(this.events).status;
  }

  // Gets a device status from _eventMappings
  _getStatus(mapping, key) {
    return this._eventMappings[mapping][key] || new DeviceStatus();
  }

  // Gets the alarm status of a site
  _getSiteStatus(site) {
    return this._getStatus("siteMappings", site);
  }
  getSiteStatus(site) {
    return this._getSiteStatus(site).status;
  }
  getLiveSiteStatus(site) {
    return this._getSiteStatus(site).liveStatus;
  }

  // Gets the alarm status of a machine
  _getMachineStatus(machine) {
    return this._getStatus("machineMappings", machine);
  }
  getMachineStatus(machine) {
    return this._getMachineStatus(machine).status;
  }
  getLiveMachineStatus(machine) {
    return this._getMachineStatus(machine).liveStatus;
  }

  // Gets the alarm status of a gateway
  _getGatewayStatus(gateway) {
    return this._getStatus("gatewayMappings", gateway);
  }
  getGatewayStatus(gateway) {
    return this._getGatewayStatus(gateway).status;
  }
  getLiveGatewayStatus(gateway) {
    return this._getGatewayStatus(gateway).liveStatus;
  }

  // Gets the alarm status of a point
  _getPointStatus(pointId) {
    return this._getStatus("pointMappings", pointId);
  }
  getPointStatus(pointId) {
    return this._getPointStatus(pointId).status;
  }
  getLivePointStatus(pointId) {
    return this._getPointStatus(pointId).liveStatus;
  }

  // Gets the events for an alarm by its ID
  _getAlarmStatus(id) {
    return new DeviceStatus(this.events.filter((x) => x.alarm_id === id));
  }
  getAlarmStatus(id) {
    return this._getAlarmStatus(id).status;
  }
  getLiveAlarmStatus(id) {
    return this._getAlarmStatus(id).liveStatus;
  }
}

class DeviceStatus {
  @observable.shallow events;

  constructor(events = []) {
    runInAction(() => {
      this.events = events;
    });
  }

  @action addEvent(event) {
    this.events.push(event);
  }

  @computed get status() {
    return new AlarmStatus(this.events);
  }

  @computed get liveEvents() {
    return this.events.filter((x) => x.timestamp >= dayjs().valueOf() - 12 * 60 * 60 * 1000);
  }

  @computed get liveStatus() {
    return new AlarmStatus(this.liveEvents);
  }

  @action merge(otherStatus) {
    const events = this.events.concat(otherStatus.events).sort(({timestamp: timestampA}, {timestamp: timestampB}) => {
      if (timestampA === timestampB) {
        return 0;
      }
      return timestampA > timestampB ? 1 : -1;
    });
    return new DeviceStatus(events);
  }
}

export class AlarmStatus {
  @observable.shallow events = [];

  constructor(events = []) {
    runInAction(() => {
      this.events = events;
    });
  }

  @computed get warningEvents() {
    return this.events.filter((event) => event.data && event.data.type === "warning");
  }

  @computed get criticalEvents() {
    return this.events.filter((event) => event.data && event.data.type === "critical");
  }

  @computed get onlineStatusEvents() {
    return this.events.filter((event) => event.data && event.data.type === "online_status");
  }

  @computed get hasWarning() {
    return !!this.warningEvents.length;
  }

  @computed get hasCritical() {
    return !!this.criticalEvents.length;
  }

  @computed get criticality() {
    return this.criticalEvents.length * 1000000 + this.warningEvents.length * 1000 + this.onlineStatusEvents.length;
  }

  @action merge(otherStatus) {
    const events = this.events.concat(otherStatus.events).sort(({timestamp: timestampA}, {timestamp: timestampB}) => {
      if (timestampA === timestampB) {
        return 0;
      }
      return timestampA > timestampB ? 1 : -1;
    });
    return new AlarmStatus(events);
  }

  getTemperatureStatus() {
    return new AlarmStatus(this.events.filter((x) => checkAlarmInGroup(x, AlarmGroups.SENSOR_ENVIRO)));
  }

  getVibrationStatus() {
    return new AlarmStatus(this.events.filter((x) => checkAlarmInGroup(x, AlarmGroups.SPECTRUM_BASED)));
  }
}
