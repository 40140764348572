import {observable, computed, flow} from "mobx";
import dayjs from "dayjs";
import {isMobile} from "react-device-detect";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";
import {processNewRawFFTData} from "services/MathService";
import {isEnvelopingSupported} from "sensoteq-core/signal-processing/utils";

export default class WaterfallDataSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      pointId: null,
      getEnvelope: () => this.rootStore.uiStore.waterfallDataConfig.envelope,
      getTimeRange: () => this.rootStore.uiStore.continuousDataDates,
      getResolution: () => this.rootStore.uiStore.continuousDataConfig.resolution,
      getCount: () => this.rootStore.uiStore.waterfallDataConfig.count,
      getRms: () => this.rootStore.uiStore.waterfallDataConfig.rms,
      getAxis: () => this.rootStore.uiStore.continuousDataConfig.axis,
      getWindowOption: () => this.rootStore.uiStore.timeDomainDataConfig.windowOption,
      getFMax: () => this.rootStore.uiStore.waterfallDataConfig.fMax,
    };
  }
  getParsedParams(params) {
    return {
      resolution: params.getResolution(),
      count: params.getCount(),
      envelope: params.getEnvelope(),
      rms: params.getRms(),
      axis: this.getXYZAxis(params.pointId, params.getAxis()),
      from: params.getTimeRange().from,
      to: params.getTimeRange().to,
      multi: params.getTimeRange().multi,
      fMax: params.getFMax(),
    };
  }
  getRequiredParamKeys() {
    return ["pointId", "from", "to", "resolution", "axis", "count", "rms", "multi"];
  }

  @observable.shallow _data;

  getData = flow(function* ({pointId, from, to, resolution, axis, count, rms, multi}) {
    if (!pointId || !axis) {
      return;
    }
    this.startLoading();
    try {
      if (this.isMultipleTimeSelections(multi)) {
        yield this.getWaterfallDataForMultipleTimeSelections(pointId, resolution, axis, count, rms, multi);
      } else {
        const data = yield Api.getWaterfall(pointId, from.valueOf(), to.valueOf(), resolution, axis, count, rms);
        this._data = data.waterfall;
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting waterfall data: ${error}`, "bad");
    }
    this.stopLoading();
  });

  isMultipleTimeSelections(multi) {
    return multi?.length > 0;
  }

  getWaterfallDataForMultipleTimeSelections = flow(function* (pointId, resolution, axis, count, rms, multi) {
    const datesSelected = multi.map((x) => {
      return {from: x.from.valueOf(), to: x.to.valueOf()};
    });

    const data = yield Api.getWaterfallMulti(pointId, datesSelected, resolution, axis, count, rms);
    this._data = data.waterfalls;
  });

  getXYZAxis(pointId, hvaAxis) {
    const sensor = this.rootStore.sensorStore.getSensorByPointId(pointId);
    if (!sensor) {
      return null;
    } else {
      return sensor.getXYZAxis(hvaAxis);
    }
  }

  @computed get _processedData() {
    if (!this._data) {
      return [];
    }

    const envelopeOptions = this.params.envelope ? {enabled: true} : null;
    return this._data.reduce((sets, set) => {
      // Enveloping is enabled but only return sets that support it.
      if (envelopeOptions?.enabled && isEnvelopingSupported(set.sampling_frequency) === false) {
        return sets;
      }

      const processedSet = {
        ...processNewRawFFTData(
          set.data,
          {
            window: this.params.getWindowOption(),
            samplingFrequency: set.sampling_frequency,
            fMax: this.params.fMax,
          },
          envelopeOptions,
        ),
        date: dayjs(set.timestamp),
      };

      sets.push(processedSet);
      return sets;
    }, []);
  }

  _extractSet(selectSet) {
    return this._processedData
      .map((entry) => ({
        data: selectSet(entry),
        title: entry.date.format(isMobile ? "Do MMM" : "Do MMM YYYY, HH:mm"),
      }))
      .reverse();
  }

  @computed get hasEnvelopableDataSet() {
    const dataSets = this._data;
    for (const set of dataSets) {
      if (isEnvelopingSupported(set.sampling_frequency)) {
        return true;
      }
    }
    return false;
  }

  @computed get accelerationData() {
    return this._extractSet((x) => x.accelerationData);
  }

  @computed get velocityData() {
    return this._extractSet((x) => x.velocityData);
  }

  @computed get displacementData() {
    return this._extractSet((x) => x.displacementData);
  }

  @computed get validData() {
    return !!this._data?.length;
  }
}
