export default function (assign) {
  assign("hues", () => ({
    default: 210,
    primary: 210,
    background: 210,
    good: 140,
    bad: 360,
    warning: 48,
    info: 210,
  }));

  assign("colours", (theme) => ({
    // Primary accent
    accent: "#fb002b",

    // Primary accent colours
    primary: `hsl(${theme.hues.primary}, 52%, 48%)`,
    primaryHighlight: `hsl(${theme.hues.primary}, 60%, 60%)`,
    primaryBackground: `hsla(${theme.hues.primary}, 90%, 50%, 0.12)`,

    // Background colours
    background: `hsl(${theme.hues.primary}, 10%, 96%)`,
    border: `hsla(${theme.hues.primary}, 30%, 50%, 0.15)`,
    contentBackground: "white",
    contentBackgroundAlt: "rgba(16, 32, 64, 0.035)",
    inputBackground: "white",
    loadingAnimation: `hsl(${theme.hues.primary}, 60%, 60%)`,

    // Text colours
    content: "black",
    contentLight: "#768994",
    contentPlaceholder: `hsl(${theme.hues.primary}, 30%, 86%)`,

    // Good colours
    good: `hsl(${theme.hues.good}, 60%, 40%)`,
    goodBg: `hsl(${theme.hues.good}, 50%, 94%)`,
    goodBorder: `hsl(${theme.hues.good}, 50%, 86%)`,

    // Bad colours
    bad: `hsl(${theme.hues.bad}, 55%, 48%)`,
    badBg: `hsl(${theme.hues.bad}, 67%, 96%)`,
    badBorder: `hsl(${theme.hues.bad}, 82%, 94%)`,

    // Warning colours
    warning: `hsl(${theme.hues.warning}, 100%, 45%)`,
    warningBg: `hsl(${theme.hues.warning}, 90%, 95%)`,
    warningBorder: `hsl(${theme.hues.warning}, 90%, 88%)`,

    // Info colours
    info: `hsl(${theme.hues.info}, 50%, 50%)`,
    infoBg: `hsl(${theme.hues.info}, 60%, 96%)`,
    infoBorder: `hsl(${theme.hues.info}, 60%, 92%)`,

    lightSeparator: "hsl(240, 4%, 91%)",

    // Offline colours
    offline: `#aaa`,
    offlineBg: "#f4f4f4",
    offlineBorder: "#ddd",

    // Official Sensoteq red colour
    sensoteqRed: "#fb002b",
  }));

  assign("graphs", (theme) => ({
    background: theme.colours.contentBackground,
    backgroundHighlight: theme.colours.primaryBackground,
    text: theme.colours.content,
    textLight: theme.colours.contentLight,
    primary: theme.colours.primary,
    buttonBackground: "#888",
    buttonText: "#fff",
    gridLines: "#f2f4f8",
  }));

  assign("forms", (theme) => ({
    // Buttons
    buttonBackground: theme.colours.primaryBackground,
    buttonBorder: `0.1rem solid ${theme.colours.primaryBackground}`,
    buttonFontWeight: 700,
    buttonColour: theme.colours.primary,

    // General
    borderWidth: "0.1rem",
    padding: "0.9rem 1.2rem",
    borderRadius: "0.4rem",
    boxShadow: "0 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.0745)",
    heavyBoxShadow: "0 0 1.2rem 0 rgba(0, 0, 0, 0.2)",

    optionColor: "black",
    optionHoverColor: "white",
  }));

  assign("fontSizes", () => ({
    huge: "3.2rem",
    veryLarge: "2.2rem",
    large: "1.9rem",
    slightlyLarge: "1.6rem",
    medium: "1.4rem",
    small: "1.3rem",
    verySmall: "1.2rem",
    tiny: "1.1rem",
  }));

  assign("dimensions", () => ({
    hPadding: 2.6,
    vPadding: 2,
  }));

  assign("misc", () => ({
    mainMenuBoxShadow: "none",
  }));
}
