/**
 * Merges themes to create a final theme.
 * @param themes an array of theme creator functions
 * @returns the merged final theme
 */
export default function (themes) {
  let keyOrder = [];
  let themeKeyMap = {};

  // Callback to extract key and additions associated with this theme
  const assignThemeKey = (themeIndex, key, assignKey) => {
    if (keyOrder.indexOf(key) === -1) {
      keyOrder.push(key);
    }
    themeKeyMap[`theme-${themeIndex}`][key] = assignKey;
  };

  // Extract all keys and additions from each theme
  themes.forEach((createTheme, idx) => {
    if (createTheme == null) {
      return;
    }
    themeKeyMap[`theme-${idx}`] = {};
    const assign = (key, assignKey) => assignThemeKey(idx, key, assignKey);
    createTheme(assign);
  });

  // Process each key in order, overriding by each newer theme
  let theme = {};
  keyOrder.forEach((key) => {
    theme[key] = {};
    for (let i = 0; i < themes.length; i++) {
      if (themeKeyMap[`theme-${i}`] == null) {
        continue;
      }
      const assignKey = themeKeyMap[`theme-${i}`][key];
      if (!assignKey) {
        continue;
      }
      const keyAssignments = assignKey(theme);
      if (keyAssignments == null) {
        continue;
      }
      Object.entries(keyAssignments).forEach(([assignment, value]) => {
        theme[key][assignment] = value;
      });
    }
  });

  return theme;
}
