import styled, {css} from "styled-components";

export const LastBorder = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 0.4rem;
  user-select: none;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 2.2rem;
    color: ${(props) => props.color ?? props.theme.colours.contentLight};

    &:hover {
      color: ${(props) => props.theme.colours.primaryHighlight};
      cursor: pointer;
    }
  }

  i.material-icons {
    font-size: 1.4rem !important;
    background-color: ${(props) => props.theme.colours.contentLight} !important;
    color: ${(props) => props.theme.colours.contentBackground} !important;
    border-radius: 0.2rem;
    position: absolute;
    font-weight: bold;

    &:hover {
      background-color: ${(props) => props.theme.colours.primaryHighlight} !important;
    }
  }

  i.material-icons,
  p {
    &:hover {
      cursor: pointer;
    }
  }

  ${(props) =>
    props.match &&
    css`
      i.material-icons {
        background-color: ${(props) => props.theme.colours.content} !important;
      }

      p {
        color: ${(props) => props.color ?? props.theme.colours.content};
        font-weight: bold;
      }
    `}
`;

export const Container = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-left: ${(props) => props.theme.forms.borderWidth} dashed ${(props) => props.theme.colours.contentLight};
  padding: 0.8rem 0 0 1.4rem;

  ${(props) =>
    props.level > 0 &&
    css`
      margin-left: 0.8rem;
    `}

  >${Header} {
    > ${LastBorder} {
      display: none;
    }
  }

  &:last-child {
    border-left-color: transparent;

    > ${Header} {
      > ${LastBorder} {
        display: block;
        position: absolute;
        left: calc(-1.4rem - ${(props) => props.theme.forms.borderWidth});
        height: calc(50% + 0.8rem);
        top: -0.8rem;
        border-left: ${(props) => props.theme.forms.borderWidth} dashed ${(props) => props.theme.colours.contentLight};
      }
    }
  }
`;

export const Spacer = styled.div`
  position: absolute;
  display: block;
  border-top: ${(props) => props.theme.forms.borderWidth} dashed ${(props) => props.theme.colours.contentLight};
  left: -1.2rem;
  width: 0.9rem;

  ${(props) =>
    !props.canExpand &&
    css`
      left: -1.2rem;
      width: 2.8rem;
    `}
`;
