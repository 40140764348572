import {observable, action, computed} from "mobx";
import autobind from "class-autobind-decorator";

@autobind
export default class FormStore {
  @observable fields = [];
  @observable values = {};
  @observable errors = {};
  @observable hash = this.generateHash();
  @observable disabled = false;
  initialValues = {};
  fieldPropertyPathCache = {};
  unmounted = false;

  /**
   * A lookup map of all field properties
   */
  @computed get fieldPropertyMap() {
    let map = {};
    this.fields.forEach((field) => {
      map[field.property] = field;
    });
    return map;
  }

  /**
   * Flag for whether the form is currently in a valid state
   * @returns {boolean}
   */
  @computed get valid() {
    return Object.keys(this.errors).length === 0;
  }

  constructor(initialValues = {}) {
    this.setInitialValues(initialValues);
    this.setValues({...initialValues});
  }

  /**
   * Sets the initial values which new fields will use to determine their initial value
   * @param initialValues
   */
  @action setInitialValues(initialValues = {}) {
    this.initialValues = initialValues;
  }

  /**
   * Private function to generate a hash string
   * @returns {string}
   */
  generateHash() {
    return Math.random().toString(32).substr(2);
  }

  /**
   * Private function to update the form hash. Used to notify observers of changes.
   */
  @action updateHash() {
    this.hash = this.generateHash();
  }

  /**
   * Checks if this form contains a field by the specified property name
   */
  hasField(property) {
    return property != null && this.fieldPropertyMap[property] != null;
  }

  /**
   * Returns the value of a form field
   * @param property the property path (a.b.c) of the field
   * @returns {*}
   */
  getValue(property) {
    return this.deepGet(this.values, property);
  }

  /**
   * Returns the error of a form field
   * @param property the property path (a.b.c) of the field
   * @returns {*}
   */
  getError(property) {
    return this.errors[property];
  }

  /**
   * Private function to validate the value of a form field
   * @param property the property path (a.b.c) of the field
   * @param value the value to validate
   * @returns {string|null} an optional error message if validation fails
   */
  validateField(property, value) {
    const field = this.fieldPropertyMap[property];
    if (!field || !field.validate) {
      return null;
    }
    const error = field.validate(value, this.values);
    return error || null;
  }

  /**
   * Sets the form to disabled, which can be observed by all fields
   * @param disabled whether the form is disabled
   */
  @action setDisabled(disabled) {
    this.disabled = disabled;
  }

  /**
   * Registers a new field to this form. Called by fields when mounting.
   * @param property the property path (a.b.c) of the field
   * @param validate an optional validation function
   * @param defaultValue the default value of this field if the value is unset
   */
  @action registerField(property, validate, defaultValue) {
    if (property == null) {
      return;
    }
    this.fields.push({
      property: property,
      validate,
    });
    this.setValue(property, this.deepGet(this.initialValues, property) ?? defaultValue);
  }

  /**
   * Unregisters a field from this form. Called by fields when unmounting.
   * @param property the property path (a.b.c) of the field
   */
  @action unregisterField(property) {
    if (property == null || this.unmounted) {
      return;
    }
    const index = this.fields.findIndex((x) => x.property === property);
    this.fields.splice(index, 1);
    delete this.errors[property];
    this.deleteProperty(property);
  }

  /**
   * Sets the validator function for a field
   * @param property the property path (a.b.c) of the field
   * @param validate the validation function
   */
  @action setValidator(property, validate) {
    if (!this.hasField(property)) {
      return;
    }
    const field = this.fieldPropertyMap[property];
    if (field && validate !== field.validate) {
      field.validate = validate;
      if (this.getValue(field.property) != null) {
        this.setError(field.property, this.validateField(field.property, this.getValue(field.property)));
      }
    }
  }

  /**
   * Sets the value of a form field
   * @param property the property path (a.b.c) of the field
   * @param value
   */
  @action setValue(property, value) {
    if (!this.hasField(property)) {
      return;
    }
    const valueToSet = value == null || value === "" ? undefined : value;
    this.deepSet(this.values, property, valueToSet);
    this.updateHash();
  }

  /**
   * Sets all values of this form to the specified model. This will clear any fields not specified.
   * @param values the new model to set
   */
  @action setValues(values = {}) {
    this.values = values;
    this.errors = {};
    this.updateHash();
  }

  /**
   * Deeply clears a field value from the form model.
   * @param property the property path (a.b.c) of the field
   */
  @action deleteProperty(property) {
    this.deepDelete(this.values, property);
    this.updateHash();
  }

  /**
   * Sets the error value of a field
   * @param property the property path (a.b.c) of the field
   * @param error
   */
  @action setError(property, error) {
    if (!this.hasField(property)) {
      return;
    }
    if (error != null) {
      this.errors[property] = error;
    } else {
      delete this.errors[property];
    }
  }

  /**
   * Manually validates the model of the form against all field validators
   */
  @action validate() {
    this.fields.forEach((field) => {
      this.setError(field.property, this.validateField(field.property, this.getValue(field.property)));
    });
  }

  /**
   * Marks the form as unmounted so no further actions will take place
   */
  @action unmount() {
    this.unmounted = true;
  }

  /**
   * Strips all values from the model that are not part of a registered field
   */
  @action cleanseValues() {
    const values = this.values;
    this.values = {};
    this.fields.forEach((field) => {
      this.setValue(field.property, this.deepGet(values, field.property));
    });
    this.errors = {};
  }

  /**
   * Splits a property path into each identifying step
   */
  getPropertyPaths(property) {
    // Splits by object notation first
    let objectPaths = property.includes(".") ? property.split(".") : [property];

    // Split by array notation
    let paths = [];
    objectPaths.forEach((path) => {
      const bracketIdx = path.indexOf("[");
      if (bracketIdx === -1) {
        paths.push(path);
        return;
      }
      const arrayProperty = path.slice(0, bracketIdx);
      const endBracketIdx = path.indexOf("]");
      paths.push(arrayProperty);
      paths.push(parseInt(path.slice(bracketIdx + 1, endBracketIdx)));
    });

    return paths;
  }

  /**
   * Retrieves and caches the property path of a property
   */
  getFieldPropertyPath(property) {
    const cachedValue = this.fieldPropertyPathCache[property];
    if (cachedValue) {
      return cachedValue;
    }
    this.fieldPropertyPathCache[property] = this.getPropertyPaths(property);
    return this.fieldPropertyPathCache[property];
  }

  /**
   * Counts the number of valid children in an object or array
   */
  getFieldPropertyCount(object) {
    if (object == null) {
      return 0;
    }
    if (Array.isArray(object)) {
      return object.filter((x) => x !== undefined).length;
    } else {
      return Object.keys(object).length;
    }
  }

  /**
   * Deeply retrieves a value
   */
  deepGet(object, property) {
    const paths = this.getFieldPropertyPath(property);
    let currentObj = object;
    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      if (typeof path === "number") {
        if (path >= currentObj.length) {
          return null;
        }
      } else if (currentObj[path] == null) {
        return null;
      }
      currentObj = currentObj[path];
    }
    return currentObj;
  }

  /**
   * Deeply sets a property
   */
  deepSet(object, property, value) {
    const paths = this.getFieldPropertyPath(property);

    // Check nested steps exist
    let currentObj = object;
    for (let i = 0; i < paths.length - 1; i++) {
      const path = paths[i];
      if (currentObj[path] == null) {
        if (typeof paths[i + 1] === "number") {
          currentObj[path] = [];
        } else {
          currentObj[path] = {};
        }
      }
      currentObj = currentObj[path];
    }

    // Pad arrays if required
    if (Array.isArray(currentObj)) {
      const targetIdx = parseInt(paths[paths.length - 1]);
      if (currentObj.length <= targetIdx) {
        for (let i = 0; i < targetIdx - currentObj.length + 1; i++) {
          currentObj.push(undefined);
        }
      }
    }

    // Set leaf property
    currentObj[paths[paths.length - 1]] = value;
  }

  /**
   * Deeply deletes a property and cleanses the tree of empty objects
   */
  deepDelete(object, property) {
    const paths = this.getFieldPropertyPath(property);

    // Function to deeply retrieve a value
    const getPropertyLevel = (depth) => {
      let currentObj = object;
      for (let i = 0; i <= depth; i++) {
        if (currentObj == null) {
          return null;
        }
        currentObj = currentObj[paths[i]];
      }
      return currentObj;
    };

    // Function to deeply delete a value
    const deletePropertyLevel = (depth) => {
      let currentObj = object;
      for (let i = 0; i < depth; i++) {
        if (currentObj == null) {
          return null;
        }
        currentObj = currentObj[paths[i]];
      }
      if (currentObj == null) {
        return;
      }
      if (Array.isArray(currentObj)) {
        currentObj[paths[depth]] = undefined;
      } else {
        delete currentObj[paths[depth]];
      }
    };

    // Delete leaf node
    deletePropertyLevel(paths.length - 1);

    // Recursively clear empty objects
    for (let i = paths.length - 2; i >= 0; i--) {
      const currentObj = getPropertyLevel(i);
      if (this.getFieldPropertyCount(currentObj) === 0) {
        deletePropertyLevel(i);
      }
    }
  }

  /**
   * Resets the forms value to it's initial value
   */
  @action reset() {
    this.setValues(this.initialValues);
  }
}
