import React from "react";
import PropTypes from "prop-types";
import {reaction, toJS} from "mobx";
import {observer} from "mobx-react";

import FormStore from "./FormStore";
import {FormStoreContext, FormApiContext, FormStateContext} from "./context";

function Form(props) {
  const {initialValues, onSubmit, onChange, onMount, getApi, children, disabled} = props;
  const firstRender = React.useRef(true);
  const formStore = React.useRef(null);

  // Create store on initial render
  if (!formStore.current) {
    formStore.current = new FormStore(initialValues);
  }

  // Keep initial values up to date
  React.useEffect(() => {
    formStore.current.setInitialValues(initialValues);
  }, [initialValues]);

  // Create form API
  const formApi = React.useMemo(() => {
    return {
      submit: () => {
        if (disabled) {
          return;
        }
        formStore.current.validate();
        if (formStore.current.valid) {
          onSubmit && onSubmit(toJS(formStore.current.values));
        }
      },
      reset: () => {
        formStore.current.reset();
      },
      isValid: () => {
        formStore.current.validate();
        return formStore.current.valid;
      },
      getValue: (field) => {
        return formStore.current.getValue(field);
      },
      getValues: () => {
        return formStore.current.values;
      },
      getError: (field) => {
        return formStore.current.getError(field);
      },
      getErrors: () => {
        return formStore.current.errors;
      },
      setValue: (field, value) => {
        const error = formStore.current.validateField(field, value);
        formStore.current.setError(field, error ?? null);
        formStore.current.setValue(field, value);
      },
      setValues: (values) => {
        formStore.current.setValues(values);
        formStore.current.validate();
      },
      mergeValues: (values) => {
        formStore.current.mergeValues(values);
        formStore.current.validate();
      },
    };
  }, [onSubmit, disabled]);

  // Create form state
  const formState = React.useMemo(() => {
    return {
      disabled: !!disabled,
      valid: formStore.current.valid,
    };
  }, [disabled, formStore.current.valid]);

  // First render
  if (firstRender.current) {
    getApi && getApi(formApi);
    firstRender.current = false;
  }

  // Callback to handle native form submit events
  const onNativeSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      formApi.submit();
    },
    [formApi],
  );

  // Pass api up if required
  React.useEffect(() => {
    getApi && getApi(formApi);
  }, [getApi]);

  // Mount and unmount
  React.useEffect(() => {
    // Clean the model after first render
    formStore.current.cleanseValues();

    return () => {
      formStore.current.unmount();
    };
  }, []);

  // Set disabled state
  React.useEffect(() => {
    formStore.current.setDisabled(disabled);
  }, [disabled]);

  // On change handler
  React.useEffect(() => {
    if (onChange) {
      return reaction(
        () => formStore.current.hash,
        () => onChange({values: formStore.current.values, valid: formStore.current.valid}),
      );
    }
  }, [onChange]);

  // On mount handler
  React.useEffect(() => {
    if (onMount) {
      onMount({values: formStore.current.values});
    }
  }, []);

  return (
    <FormStoreContext.Provider value={formStore.current}>
      <FormApiContext.Provider value={formApi}>
        <FormStateContext.Provider value={formState}>
          {children({
            onNativeSubmit: onNativeSubmit,
            render: (x) => (typeof x === "function" ? x({values: formStore.current.values}) : x),
          })}
        </FormStateContext.Provider>
      </FormApiContext.Provider>
    </FormStoreContext.Provider>
  );
}

Form.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  getApi: PropTypes.func,
  onChange: PropTypes.func,
  onMount: PropTypes.func,
  disabled: PropTypes.bool,
};

export default observer(Form);
