const {MeasuringPointPositions, MeasuringPointSteps} = require("../enumerations");
const {MachineTypeBase} = require("../machines/machine-types");
const {generateValueLabelListFromMap} = require("../utilities/object");

const STANDARD_MEASURING_POINT_POSITIONS_MAP = new Map([
  [MeasuringPointPositions.DRIVE_END, "DE / IB"],
  [MeasuringPointPositions.NON_DRIVE_END, "NDE / ODE / OB"],
  [MeasuringPointPositions.HOUSING, "Housing"],
  [MeasuringPointPositions.SUPPORT, "Support"],
  [MeasuringPointPositions.OTHER, "Other"],
]);

const OSCILLATING_NON_MOTOR_MEASURING_POINT_POSITIONS_MAP = new Map([
  [MeasuringPointPositions.LEFT_FEED, "Left feed"],
  [MeasuringPointPositions.LEFT_FEED_MIDDLE, "Left feed middle"],
  [MeasuringPointPositions.LEFT_MIDDLE, "Left middle"],
  [MeasuringPointPositions.LEFT_DISCHARGE_MIDDLE, "Left discharge middle"],
  [MeasuringPointPositions.LEFT_DISCHARGE, "Left discharge"],
  [MeasuringPointPositions.RIGHT_FEED, "Right feed"],
  [MeasuringPointPositions.RIGHT_FEED_MIDDLE, "Right feed middle"],
  [MeasuringPointPositions.RIGHT_MIDDLE, "Right middle"],
  [MeasuringPointPositions.RIGHT_DISCHARGE_MIDDLE, "Right discharge middle"],
  [MeasuringPointPositions.RIGHT_DISCHARGE, "Right discharge"],
  [MeasuringPointPositions.UPPER_BEARING, "Upper bearing"],
  [MeasuringPointPositions.LOWER_BEARING, "Lower bearing"],
  [MeasuringPointPositions.RIGHT_BEARING, "Right bearing"],
  [MeasuringPointPositions.LEFT_BEARING, "Left bearing"],
  [MeasuringPointPositions.OTHER, "Other"],
]);

const INTERMEDIARY_MEASURING_POINT_POSITIONS_MAP = new Map([
  [MeasuringPointPositions.SUPPORT, "Support"],
  [MeasuringPointPositions.SHAFT_1_DRIVE_END, "Shaft 1 - Drive end"],
  [MeasuringPointPositions.SHAFT_1_NON_DRIVE_END, "Shaft 1 - Non-drive end"],
  [MeasuringPointPositions.SHAFT_2_DRIVE_END, "Shaft 2 - Drive end"],
  [MeasuringPointPositions.SHAFT_2_NON_DRIVE_END, "Shaft 2 - Non-drive end"],
  [MeasuringPointPositions.SHAFT_3_DRIVE_END, "Shaft 3 - Drive end"],
  [MeasuringPointPositions.SHAFT_3_NON_DRIVE_END, "Shaft 3 - Non-drive end"],
  [MeasuringPointPositions.SHAFT_4_DRIVE_END, "Shaft 4 - Drive end"],
  [MeasuringPointPositions.SHAFT_4_NON_DRIVE_END, "Shaft 4 - Non-drive end"],
  [MeasuringPointPositions.SHAFT_5_DRIVE_END, "Shaft 5 - Drive end"],
  [MeasuringPointPositions.SHAFT_5_NON_DRIVE_END, "Shaft 5 - Non-drive end"],
  [MeasuringPointPositions.SHAFT_6_DRIVE_END, "Shaft 6 - Drive end"],
  [MeasuringPointPositions.SHAFT_6_NON_DRIVE_END, "Shaft 6 - Non-drive end"],
  [MeasuringPointPositions.SHAFT_7_DRIVE_END, "Shaft 7 - Drive end"],
  [MeasuringPointPositions.SHAFT_7_NON_DRIVE_END, "Shaft 7 - Non-drive end"],
  [MeasuringPointPositions.SHAFT_8_DRIVE_END, "Shaft 8 - Drive end"],
  [MeasuringPointPositions.SHAFT_8_NON_DRIVE_END, "Shaft 8 - Non-drive end"],
  [MeasuringPointPositions.OTHER, "Other"],
]);

const STANDARD_MEASURING_POINT_POSITION_OPTIONS = generateValueLabelListFromMap(STANDARD_MEASURING_POINT_POSITIONS_MAP);
const OSCILLATING_NON_MOTOR_MEASURING_POINT_POSITION_OPTIONS = generateValueLabelListFromMap(
  OSCILLATING_NON_MOTOR_MEASURING_POINT_POSITIONS_MAP,
);
const INTERMEDIARY_MEASURING_POINT_POSITION_OPTIONS = generateValueLabelListFromMap(
  INTERMEDIARY_MEASURING_POINT_POSITIONS_MAP,
);

module.exports.getSupportedStepPositions = (machineBase, step) => {
  if (machineBase === MachineTypeBase.OSCILLATING_PATTERN && step !== MeasuringPointSteps.MOTOR) {
    return OSCILLATING_NON_MOTOR_MEASURING_POINT_POSITIONS_MAP;
  }
  if (step === MeasuringPointSteps.INTERMEDIARY) {
    return INTERMEDIARY_MEASURING_POINT_POSITIONS_MAP;
  }

  return STANDARD_MEASURING_POINT_POSITIONS_MAP;
};

module.exports.validateStepPositions = (machineBase, step, positions) => {
  const positionOptions = module.exports.getSupportedStepPositions(machineBase, step);
  const invalidPositions = [];
  for (const position of positions) {
    const isPresent = positionOptions.has(position);
    if (!isPresent) {
      invalidPositions.push(position);
    }
  }

  return {valid: invalidPositions.length === 0, invalidPositions};
};

module.exports.getSupportedStepPositionOptionsList = (machineBase, step) => {
  if (machineBase === MachineTypeBase.OSCILLATING_PATTERN && step !== MeasuringPointSteps.MOTOR) {
    return OSCILLATING_NON_MOTOR_MEASURING_POINT_POSITION_OPTIONS;
  }
  if (step === MeasuringPointSteps.INTERMEDIARY) {
    return INTERMEDIARY_MEASURING_POINT_POSITION_OPTIONS;
  }

  return STANDARD_MEASURING_POINT_POSITION_OPTIONS;
};
