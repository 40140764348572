import {flow, computed, observable} from "mobx";
import dayjs from "dayjs";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";
import * as Enums from "constants/enums";
import {EnvelopeOptions} from "sensoteq-react-core/constants/options";
import {findPowerBandByName, getPowerBandsInOrder} from "sensoteq-core/power-bands/power-bands";
import {doesPropExist} from "sensoteq-core/utilities/object";

export default class TimeDomainKeySubscription extends DataSubscription {
  getDefaultParams() {
    return {
      pointId: null,
      getResolution: () => this.rootStore.uiStore.continuousDataConfig.resolution,
      getTimeRange: () => this.rootStore.uiStore.continuousDataDates,
      getTimeOption: () => this.rootStore.uiStore.continuousDataConfig.timeOption,
      getAxis: () => this.rootStore.uiStore.continuousDataConfig.axis,
    };
  }
  getParsedParams(params) {
    return {
      resolution: params.getResolution(),
      timeOption: params.getTimeOption(),
      from: params.getTimeRange().from,
      to: params.getTimeRange().to,
      axis: this.getXYZAxis(params.pointId, params.getAxis()),
    };
  }
  getRequiredParamKeys() {
    return ["pointId", "from", "to", "resolution", "timeOption", "axis"];
  }

  @observable.shallow _data;

  getData = flow(function* ({pointId, from, to, resolution, timeOption, axis}) {
    if (!pointId) {
      return;
    }
    this.startLoading();
    try {
      if (timeOption === Enums.TIME_OPTION_LATEST) {
        const data = yield Api.getLatestTimeDomainKey(pointId, resolution, axis);
        this._data = data.latest.current ? [data.latest.current] : [];
        this.nextTimestamp = data.latest.next?.expected_completion;
      } else {
        const data = yield Api.getTimeDomainKeyList(pointId, from.valueOf(), to.valueOf(), resolution, axis);
        this._data = data.keys;
        this.nextTimestamp = undefined;
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting time domain key data: ${error}`, "bad");
    }
    this.stopLoading();
  });

  getXYZAxis(pointId, hvaAxis) {
    if (!hvaAxis) {
      return undefined;
    }
    const sensor = this.rootStore.sensorStore.getSensorByPointId(pointId);
    return sensor?.getXYZAxis(hvaAxis) ?? undefined;
  }

  @computed get sensorInfo() {
    return this.rootStore.sensorStore.getSensorByPointId(this.params.pointId);
  }

  @computed get keys() {
    return this._data || [];
  }

  @computed get powerData() {
    return this._buildPowerBandData("power_data");
  }

  @computed get measuredRPMPowerData() {
    return this._buildPowerBandData("measured_rpm_power_data");
  }

  _buildPowerBandData(powerDataKey) {
    const seriesData = {};
    getPowerBandsInOrder().forEach((band) => {
      seriesData[band.label] = [];
    });

    this.keys.forEach((entry) => {
      const powerData = entry[powerDataKey];
      if (powerData == null) return;

      for (const [key, value] of Object.entries(powerData)) {
        const data = {power: value, date: dayjs(entry.timestamp)};
        const band = findPowerBandByName(key);
        if (band == null || !Object.prototype.hasOwnProperty.call(seriesData, band.label)) {
          // Unknown power band key so just skip value
          continue;
        }

        seriesData[band.label].push(data);
      }
    });

    return Object.entries(seriesData).map(([label, set]) => {
      return {
        data: set,
        title: label,
      };
    });
  }

  @computed get magnetometerRunningSpeed() {
    const seriesData = {x: [], y: [], z: []};
    this.keys.forEach((entry) => {
      if (entry.magnetometer_speed == null) {
        return;
      }

      const dataEntry = {x: null, y: null, z: null};
      for (const [key, value] of Object.entries(entry.magnetometer_speed)) {
        const data = {value: value, date: dayjs(entry.timestamp)};
        if (doesPropExist(dataEntry, key)) {
          dataEntry[key] = data;
        }
      }

      // Discard values if they are all zero. This generally means that the sensor
      // doesn't have the magnetometer feature but will still transmit 0 values.
      if (
        dataEntry.x != null &&
        dataEntry.x.value === 0 &&
        dataEntry.y != null &&
        dataEntry.y.value === 0 &&
        dataEntry.z != null &&
        dataEntry.z.value === 0
      ) {
        return;
      }

      seriesData.x.push(dataEntry.x);
      seriesData.y.push(dataEntry.y);
      seriesData.z.push(dataEntry.z);
    });
    return Object.entries(seriesData)
      .filter(([, dataEntries]) => dataEntries.length > 0)
      .map(([label, dataEntries]) => {
        return {
          data: dataEntries,
          title: label.toUpperCase(),
        };
      });
  }

  @computed get magnetometerAmplitude() {
    const seriesData = {x: [], y: [], z: []};
    this.keys.forEach((entry) => {
      if (entry.magnetometer_amplitude == null) {
        return;
      }

      for (const [key, value] of Object.entries(entry.magnetometer_amplitude)) {
        const data = {value: value, date: dayjs(entry.timestamp)};
        seriesData[key].push(data);
      }
    });
    return Object.entries(seriesData)
      .filter(([, dataEntries]) => dataEntries.length > 0)
      .map(([label, dataEntries]) => {
        return {
          data: dataEntries,
          title: label.toUpperCase(),
        };
      });
  }

  @computed get accelerationRmsData() {
    return this._getCalculation("acc_rms", "rms");
  }

  @computed get velocityRmsData() {
    return this._getCalculation("vel_rms", "rms");
  }

  @computed get crestFactorData() {
    return this._getCalculation("crest_factor", "crestFactor");
  }

  @computed get peakToPeakData() {
    return this._getCalculation("peak_to_peak", "value");
  }

  @computed get tdAccelerationPeak() {
    return this._getCalculation("td_acc_peak", "value");
  }

  @computed get measuredRPMData() {
    return this._getKeyValues("measured_rpm");
  }

  @computed get envelopeRMSData() {
    return this._getEnvelopeProperty("envelope_acc_rms", "value");
  }

  @computed get envelopeTWFPeakData() {
    return this._getEnvelopeProperty("envelope_twf_peak");
  }

  _getKeyValues(property, name = property, transform = (x) => x) {
    if (!this._data) return [];

    const data = [];
    for (const tdKey of this.keys) {
      if (tdKey[property] == null) continue;
      data.push({[name]: transform(tdKey[property]), date: dayjs(tdKey.timestamp)});
    }

    return [{data}];
  }

  _getCalculation(property, name, transform = (x) => x) {
    if (!this._data) {
      return [];
    }
    let data = [];
    this.keys.forEach((key) => {
      if (key.calcs_data == null || key.calcs_data[property] == null) {
        return;
      }
      data.push({
        [name]: transform(key.calcs_data[property]),
        date: dayjs(key.timestamp),
      });
    });
    return [
      {
        data,
      },
    ];
  }

  _getEnvelopeProperty(property, alternativePropertyName = property, transform = (x) => x) {
    if (!this._data) {
      return [];
    }

    const seriesData = {};
    const envelopesKey = "envelopes";
    this.keys.forEach((key) => {
      if (key.calcs_data == null || key.calcs_data[envelopesKey] == null) {
        return;
      }

      key.calcs_data[envelopesKey].forEach((envelope) => {
        // To account for an update to the variable key for envelopeRMS from "value" to "envelope_RMS" we allow multiple
        // property names, however if this is left unspecified, it defaults to the same as the first property name
        const propertyValue = envelope[property] ?? envelope[alternativePropertyName];
        if (propertyValue == null) {
          return;
        }
        const data = {
          [property]: transform(propertyValue),
          date: dayjs(key.timestamp),
        };
        const envelopeMethod = envelope.config.envelopeMethod;
        if (Object.prototype.hasOwnProperty.call(seriesData, envelopeMethod)) {
          seriesData[envelopeMethod].push(data);
        } else {
          seriesData[envelopeMethod] = [data];
        }
      });
    });

    return Object.entries(seriesData).map(([method, dataEntry]) => {
      const option = EnvelopeOptions.find((option) => option.value === method);
      const label = option == null ? "Unknown" : option.label;
      return {
        data: dataEntry,
        title: label,
      };
    });
  }
}
