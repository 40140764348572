import React from "react";
import autobind from "class-autobind-decorator";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

import {asField} from "../lib/mobx-form";
import Label from "./Label";

@autobind
class HuePicker extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    alignTop: PropTypes.bool,
  };
  state = {
    open: false,
    alignLeft: true,
  };
  blockNextClick = false;
  inputRef = React.createRef();

  componentDidMount() {
    this.inputRef.current.value = this.props.fieldState.value ?? "";
  }

  onChange(hue) {
    this.updateHue(hue);
    this.inputRef.current.value = hue;
    this.close();
  }

  updateHue(hue) {
    const {onChange, fieldApi} = this.props;
    const {setValue} = fieldApi;
    setValue(hue);
    if (onChange) {
      onChange(hue);
    }
  }

  onDocumentClick() {
    if (this.blockNextClick) {
      this.blockNextClick = false;
      return;
    }
    this.close();
  }

  open() {
    this.blockNextClick = false;
    document.addEventListener("click", this.onDocumentClick);
    this.setState({
      open: true,
    });
  }

  close() {
    document.removeEventListener("click", this.onDocumentClick);
    this.setState({
      open: false,
    });
  }

  blockClose() {
    this.blockNextClick = true;
  }

  onType(e) {
    this.updateHue(e.target.value);
  }

  render() {
    const {label, field, fieldState, alignTop, placeholder} = this.props;
    const {open, alignLeft} = this.state;
    const {error, disabled, value} = fieldState;

    let colours = [];
    for (let i = 0; i <= 360; i++) {
      colours.push(
        <div
          key={i}
          style={{backgroundColor: `hsl(${i}, 60%, 50%)`}}
          onClick={() => this.onChange(i)}
        />,
      );
    }

    return (
      <Wrapper>
        {label && <Label htmlFor={field}>{label}</Label>}
        {error && (
          <Label
            error
            htmlFor={field}
          >
            {error}
          </Label>
        )}
        <Container onClick={disabled ? null : this.open}>
          {value != null && !isNaN(value) && <Colour hue={value} />}
          <Input
            ref={this.inputRef}
            placeholder={placeholder}
            onChange={this.onType}
          />
        </Container>
        {open && (
          <Picker
            ref={this.pickerRef}
            onClick={this.blockClose}
            alignLeft={alignLeft}
            alignTop={alignTop}
          >
            <ColourBar>{colours}</ColourBar>
          </Picker>
        )}
      </Wrapper>
    );
  }
}

const hueValidator = (value) => {
  if (value == null) {
    return null;
  }
  if (isNaN(value) || value < 0 || value > 360) {
    return "Please enter a humber between 0 and 360";
  }
  return null;
};

export default asField(HuePicker, null, hueValidator);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  position: relative;
`;

const Container = styled.div`
  padding: ${(props) => props.theme.forms.padding};
  padding-top: 0;
  padding-bottom: 0;
  border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  border-radius: ${(props) => props.theme.forms.borderRadius};
  background-color: ${(props) => props.theme.colours.inputBackground};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Colour = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${(props) => `hsl(${props.hue}, 60%, 50%)`};
  margin-right: 0.8rem;
`;

const Picker = styled.div`
  z-index: 999;
  position: absolute;
  right: 0;
  left: auto;
  top: calc(100% + 0.8rem);
  border-radius: ${(props) => props.theme.forms.borderRadius}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1.5rem;
  background-color: ${(props) => props.theme.colours.contentBackground};
  box-shadow: ${(props) => props.theme.forms.heavyBoxShadow};
  
  ${(props) =>
    props.alignTop &&
    css`
      top: calc(0% - 0.8rem);
      transform: translateY(-100%);
    `}
  
  ${(props) =>
    props.alignLeft &&
    css`
      left: 0;
      right: auto;
    `}
`;

const ColourBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  height: 1.5rem;

  &:hover {
    cursor: pointer;
  }

  > * {
    width: 0.1rem;
    display: block;
  }
`;

const Input = styled.input`
  padding: ${(props) => props.theme.forms.padding};
  padding-left: 0;
  padding-right: 0;
  border: none;
  color: ${(props) => props.theme.colours.content};
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.colours.inputBackground};

  &::placeholder {
    color: ${(props) => props.theme.colours.contentLight};
  }
`;
