import {computed, observable, action, runInAction} from "mobx";
import dayjs from "dayjs";

import {
  calculateEllipseData,
  calculateFFT,
  calculatePhaseData,
  calculateTriAxialHammerData,
  convertFFT,
  DomainConversionOptions,
  findMaxPeak,
  rollingAverage,
  VibrationDomains,
  WindowingOptions,
  calculateTimeDomainData,
  convertTimeDomainData,
  NyquistOptions,
  calculateRMS,
  findFrequencyValue,
  rollingAverageDetrend,
  removeTimeWaveformDuplicates,
  applyAmplitudeHannCorrection,
} from "sensoteq-core/calculations";
import {AxisStrategies} from "sensoteq-core/enumerations";
import {ServiceAppModeLookupMap} from "sensoteq-react-core/constants/lookups";

export default class BluetoothDataSet {
  @observable data;
  @observable metadata;
  @observable hammerTestConfig;
  @observable _samplingFrequency;
  @observable _sampleSize;

  constructor(data, samplingFrequency, sampleSize, hammerTestConfig) {
    runInAction(() => {
      this.data = data?.data;
      this.metadata = {
        sensorId: data?.entry_key,
        timestamp: data?.timestamp,
        mode: data?.mode,
      };
      this._samplingFrequency = samplingFrequency;
      this._sampleSize = sampleSize;
      this.hammerTestConfig = hammerTestConfig;
    });
  }

  _removeTimeWaveformDuplicates = true;

  @action setData(data) {
    this.data = data;
  }

  @action setRemoveTimeWaveformDuplicates(state) {
    this._removeTimeWaveformDuplicates = state;
  }

  @action setSamplingFrequency(samplingFrequency) {
    this._samplingFrequency = samplingFrequency;
  }

  @action setHammerTestConfig(config) {
    this.hammerTestConfig = config;
  }

  @computed get sampleSize() {
    return this._sampleSize ?? this.xSamples.length;
  }

  @computed get samplingFrequency() {
    return this._samplingFrequency ?? 409.6;
  }

  @computed get validData() {
    return this.xSamples.length > 0;
  }

  @computed get validEllipseData() {
    return this.ellipseData?.displacementData != null;
  }

  @computed get validHammerData() {
    return this.hammerResult != null;
  }

  @computed get title() {
    const {sensorId, timestamp, mode} = this.metadata ?? {};
    return `${sensorId} - ${dayjs(timestamp).format("YYYY-MM-DD HH-mm-ss")} - ${ServiceAppModeLookupMap[mode] ?? ""}`;
  }

  extractSamples(axis) {
    if (!this.data) {
      return [];
    }
    return this.data[axis];
  }

  @computed get xSamples() {
    return this.extractSamples("x");
  }

  @computed get ySamples() {
    return this.extractSamples("y");
  }

  @computed get zSamples() {
    return this.extractSamples("z");
  }

  extractTimeDomainData(axis) {
    let samples = this[`${axis}Samples`];
    if (!samples) {
      return [];
    }

    if (this._removeTimeWaveformDuplicates) {
      samples = removeTimeWaveformDuplicates(samples);
    }
    return calculateTimeDomainData(samples, this.samplingFrequency);
  }

  @computed get accelerationTimeWaveform() {
    return [
      {
        data: this.extractTimeDomainData("x"),
        title: "X",
      },
      {
        data: this.extractTimeDomainData("y"),
        title: "Y",
      },
      {
        data: this.extractTimeDomainData("z"),
        title: "Z",
      },
    ];
  }

  @computed get velocityTimeWaveform() {
    return [
      {
        data: convertTimeDomainData(
          this.accelerationTimeWaveform[0].data,
          DomainConversionOptions.VELOCITY_DOMAIN,
          ...this.detrendingParameters,
        ),
        title: "X",
      },
      {
        data: convertTimeDomainData(
          this.accelerationTimeWaveform[1].data,
          DomainConversionOptions.VELOCITY_DOMAIN,
          ...this.detrendingParameters,
        ),
        title: "Y",
      },
      {
        data: convertTimeDomainData(
          this.accelerationTimeWaveform[2].data,
          DomainConversionOptions.VELOCITY_DOMAIN,
          ...this.detrendingParameters,
        ),
        title: "Z",
      },
    ];
  }

  @computed get displacementTimeWaveform() {
    return [
      {
        data: convertTimeDomainData(
          this.accelerationTimeWaveform[0].data,
          DomainConversionOptions.DISPLACEMENT_DOMAIN,
          ...this.detrendingParameters,
        ),
        title: "X",
      },
      {
        data: convertTimeDomainData(
          this.accelerationTimeWaveform[1].data,
          DomainConversionOptions.DISPLACEMENT_DOMAIN,
          ...this.detrendingParameters,
        ),
        title: "Y",
      },
      {
        data: convertTimeDomainData(
          this.accelerationTimeWaveform[2].data,
          DomainConversionOptions.DISPLACEMENT_DOMAIN,
          ...this.detrendingParameters,
        ),
        title: "Z",
      },
    ];
  }

  @computed get accelerationFFT() {
    return [
      {
        data: calculateFFT(
          this.xSamples,
          this.samplingFrequency,
          WindowingOptions.HANN_WINDOW,
          this.sampleSize,
          5,
          NyquistOptions.NYQUIST_256,
          0,
          true,
        ),
        title: "X",
      },
      {
        data: calculateFFT(
          this.ySamples,
          this.samplingFrequency,
          WindowingOptions.HANN_WINDOW,
          this.sampleSize,
          5,
          NyquistOptions.NYQUIST_256,
          0,
          true,
        ),
        title: "Y",
      },
      {
        data: calculateFFT(
          this.zSamples,
          this.samplingFrequency,
          WindowingOptions.HANN_WINDOW,
          this.sampleSize,
          5,
          NyquistOptions.NYQUIST_256,
          0,
          true,
        ),
        title: "Z",
      },
    ];
  }

  @computed get velocityFFT() {
    return [
      {
        data: convertFFT(this.accelerationFFT[0].data, DomainConversionOptions.VELOCITY_DOMAIN),
        title: "X",
      },
      {
        data: convertFFT(this.accelerationFFT[1].data, DomainConversionOptions.VELOCITY_DOMAIN),
        title: "Y",
      },
      {
        data: convertFFT(this.accelerationFFT[2].data, DomainConversionOptions.VELOCITY_DOMAIN),
        title: "Z",
      },
    ];
  }

  @computed get displacementFFT() {
    return [
      {
        data: convertFFT(this.accelerationFFT[0].data, DomainConversionOptions.DISPLACEMENT_DOMAIN),
        title: "X",
      },
      {
        data: convertFFT(this.accelerationFFT[1].data, DomainConversionOptions.DISPLACEMENT_DOMAIN),
        title: "Y",
      },
      {
        data: convertFFT(this.accelerationFFT[2].data, DomainConversionOptions.DISPLACEMENT_DOMAIN),
        title: "Z",
      },
    ];
  }

  @computed get hammerResult() {
    if (!this.data) {
      return null;
    }
    return calculateTriAxialHammerData(
      this.xSamples,
      this.ySamples,
      this.zSamples,
      this.hammerTestConfig.hammerTrigger,
      this.samplingFrequency,
      this.hammerTestConfig.hammerRemovePointsAtStart,
      this.hammerTestConfig.hammerSamples,
      this.sampleSize,
      this.hammerTestConfig.hammerMinFrequency,
      this.hammerTestConfig.hammerMaxFrequency,
      this.hammerTestConfig.hammerSNR,
    );
  }

  @computed get phaseData() {
    if (!this.xSamples.length) {
      return null;
    }
    return calculatePhaseData(rollingAverage(this.xSamples), rollingAverage(this.ySamples));
  }

  @computed get ellipseData() {
    if (!this.phaseData) {
      return null;
    }
    let xMaxPeak = findMaxPeak(this.velocityFFT[0].data);
    let yMaxPeak = findMaxPeak(this.velocityFFT[1].data);
    let zMaxPeak = findMaxPeak(this.velocityFFT[2].data);

    // Correct these peak values again for amplitude
    xMaxPeak = {
      frequency: xMaxPeak.frequency,
      amplitude: (xMaxPeak.value * 2) / 1.63,
    };
    yMaxPeak = {
      frequency: yMaxPeak.frequency,
      amplitude: (yMaxPeak.value * 2) / 1.63,
    };
    zMaxPeak = {
      frequency: zMaxPeak.frequency,
      amplitude: (zMaxPeak.value * 2) / 1.63,
    };

    const displacementData = calculateEllipseData(
      [xMaxPeak],
      [yMaxPeak],
      [zMaxPeak],
      this.phaseData,
      true,
      AxisStrategies.Y_UP,
      VibrationDomains.DISPLACEMENT,
    );
    const velocityData = calculateEllipseData(
      [xMaxPeak],
      [yMaxPeak],
      [zMaxPeak],
      this.phaseData,
      true,
      AxisStrategies.Y_UP,
      VibrationDomains.VELOCITY,
    );
    const accelerationData = calculateEllipseData(
      [xMaxPeak],
      [yMaxPeak],
      [zMaxPeak],
      this.phaseData,
      true,
      AxisStrategies.Y_UP,
      VibrationDomains.ACCELERATION,
    );

    // Calculate deflection as a RSS
    return {
      accelerationData,
      velocityData,
      displacementData,
      deflectionData: !displacementData?.frequency
        ? {}
        : {
            acceleration: calculateRMS(
              applyAmplitudeHannCorrection(this.accelerationFFT[2].data, true),
              (x) => x.value,
            ),
            velocity: calculateRMS(applyAmplitudeHannCorrection(this.velocityFFT[2].data, true), (x) => x.value),
            displacement:
              calculateRMS(applyAmplitudeHannCorrection(this.displacementFFT[2].data, true), (x) => x.value) / 1000,
          },
      deflection1xRPMPeak: !displacementData?.frequency
        ? {}
        : {
            acceleration: (findFrequencyValue(this.accelerationFFT[2].data, displacementData.frequency) * 2) / 1.63,
            velocity: (findFrequencyValue(this.velocityFFT[2].data, displacementData.frequency) * 2) / 1.63,
            displacement:
              (findFrequencyValue(this.displacementFFT[2].data, displacementData.frequency) * 2) / 1.63 / 1000,
          },
    };
  }

  computeRawPlot(waveformData) {
    if (!this.validData) {
      return null;
    }
    const {phaseAngle, strokeAngle} = this.ellipseData.accelerationData;
    const xSamples = waveformData[0].data.map((x) => x.value);
    const ySamples = waveformData[1].data.map((x) => x.value);
    const zSamples = waveformData[2].data.map((x) => x.value);
    const factor = (strokeAngle >= 0 && phaseAngle < 180) || (strokeAngle < 0 && phaseAngle > 180) ? -1 : 1;

    let xyData = [],
      xzData = [],
      yzData = [];
    for (let i = 0; i < xSamples.length; i++) {
      xyData.push({
        a: factor * xSamples[i],
        b: ySamples[i],
      });
      xzData.push({
        a: xSamples[i],
        b: zSamples[i],
      });
      yzData.push({
        a: ySamples[i],
        b: zSamples[i],
      });
    }

    return [
      {
        data: xyData,
        title: "Y vs X",
      },
      {
        data: xzData,
        title: "Z vs X",
      },
      {
        data: yzData,
        title: "Z vs Y",
      },
    ];
  }

  @computed get rawAccelerationPlot() {
    this._removeTimeWaveformDuplicates = false;
    const plotData = this.computeRawPlot(this.accelerationTimeWaveform);
    this._removeTimeWaveformDuplicates = true;
    return plotData;
  }

  @computed get rawVelocityPlot() {
    this._removeTimeWaveformDuplicates = false;
    const plotData = this.computeRawPlot(this.velocityTimeWaveform);
    this._removeTimeWaveformDuplicates = true;
    return plotData;
  }

  @computed get rawDisplacementPlot() {
    this._removeTimeWaveformDuplicates = false;
    const plotData = this.computeRawPlot(this.displacementTimeWaveform);
    this._removeTimeWaveformDuplicates = true;
    return plotData;
  }

  @computed get detrendingParameters() {
    const samplingFrequency = this.samplingFrequency;
    const detrendingFunc = rollingAverageDetrend;

    if (samplingFrequency == null || samplingFrequency <= 409.6) {
      return [detrendingFunc, [50]];
    }

    if (samplingFrequency > 409.6 && samplingFrequency <= 1600) {
      return [detrendingFunc, [50]];
    }

    return [detrendingFunc, [50]];
  }
}
