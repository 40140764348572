import LoadingPlaceholder from "components/common/LoadingPlaceholder";
import * as Styled from "./Page.styles";
import type {PropsWithChildren} from "react";

type Props = PropsWithChildren<{
  loading?: boolean;
  className?: string | undefined;
  id?: string | undefined;
}>;
const Page = ({loading = false, children, className, id}: Props) => {
  if (loading) {
    return <LoadingPlaceholder />;
  }
  return (
    <Styled.Container
      id={id}
      className={`bg-background ${className}`}
    >
      {children}
      <div id="fullscreen" />
    </Styled.Container>
  );
};

export default Page;
