import {computed} from "mobx";

import MultiDataSubscription from "./MultiDataSubscription";

export default class MultiPointSubscription extends MultiDataSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      identifyingParam: "pointId",
    };
  }

  @computed get points() {
    return this.identifiers;
  }

  getSetTitle(pointId) {
    const point = this.rootStore.pointStore.getTestPoint(pointId);
    if (!point) {
      return "Unknown point";
    }
    const machine = this.rootStore.machineStore.getMachine(point.machine_id);
    if (!machine) {
      return `Unknown machine - ${point.label}`;
    }
    return `${machine.label} - ${point.label}`;
  }

  getSetPosition(pointId) {
    const point = this.rootStore.pointStore.getTestPoint(pointId);
    if (!point) return null;
    return point.position;
  }
}
