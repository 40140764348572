import PropTypes from "prop-types";

import Button from "sensoteq-react-core/forms/Button";
import * as Styled from "./EmptyState.styles";
import Icon from "./Icon";

export const IconSizes = {
  Small: "small",
  Medium: "medium",
  Large: "large",
};

const EmptyState = ({
  title,
  text,
  icon,
  iconLibrary,
  buttonText,
  buttonIcon,
  buttonOnClick,
  iconSize = IconSizes.Small,
  background = false,
  style,
  minHeight,
  padded = true,
}) => {
  return (
    <Styled.Container
      style={style}
      minHeight={minHeight}
      padded={padded}
    >
      <Styled.Content background={background}>
        {icon && (
          <Styled.Icon
            iconSize={iconSize}
            lessPadding={!!text}
          >
            <Icon
              name={icon}
              library={iconLibrary}
            />
          </Styled.Icon>
        )}
        {title && <Styled.Title>{title}</Styled.Title>}
        {text && <Styled.Text>{text}</Styled.Text>}
        {buttonText && (
          <Styled.ButtonContainer>
            <Button
              text={buttonText}
              icon={buttonIcon}
              onClick={buttonOnClick}
            />
          </Styled.ButtonContainer>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

EmptyState.IconSizes = IconSizes;
EmptyState.IconLibraries = Icon.Libraries;
EmptyState.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonOnClick: PropTypes.func,
  iconSize: PropTypes.oneOf([IconSizes.Small, IconSizes.Medium, IconSizes.Large]),
  background: PropTypes.bool,
  style: PropTypes.object,
  minHeight: PropTypes.number,
  padded: PropTypes.bool,
  iconLibrary: PropTypes.string,
};

export default EmptyState;
