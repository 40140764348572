import {observable, reaction, computed, action} from "mobx";
import dayjs from "dayjs";

import Loadable from "./Loadable";

export default class DataSubscription extends Loadable {
  rootStore;

  // Observable subscription state
  @observable state = {
    // Number of subscribers to this subscription
    subscribers: 0,

    // Last timestamp someone subscribed to this subscription
    lastSubscriptionTime: null,

    // Creators and disposers for any addition subscriptions which are tied to this one
    subCreators: [],
    subDisposers: [],

    // Primary disposer to destroy subscription
    disposer: null,
  };

  // Overrideable subscription config
  config = {
    // Whether or not refresh this subscription when someone subscribes to it
    autoRefresh: true,

    // Debounce interval between refreshing this subscription due to new subscribers
    refreshDebounce: 5000,

    // Whether the subscriber specified to create this subscription from scratch
    recreate: false,

    // The ID of this subscription
    id: null,
  };

  // Params are comprised of default params and user set, observable params
  getDefaultParams() {
    return null;
  }
  @observable _params = {};

  // Optionally parse params to enrich them.
  // This is frequently used to execute getter functions on observable variables
  getParsedParams() {
    return {};
  }

  // Optionally cleans params to only those needed for data fetching.
  // This avoids re-fetching data when an unnecessary param changes.
  // Empty array is treated as all required
  getRequiredParamKeys() {
    return [];
  }

  // Computed params getter which triggers a data fetch.
  @computed get params() {
    const params = {
      ...(this.getDefaultParams() || {}),
      ...(this._params || {}),
    };
    return {
      ...params,
      ...(this.getParsedParams(params) || {}),
    };
  }

  // Comparison function for checking param changes
  paramIsSame(a, b) {
    // Optimistic check
    if (a === b) {
      return true;
    }
    // Dayjs objects
    if (dayjs.isDayjs(a) && dayjs.isDayjs(b)) {
      return a.isSame(b);
    }
    return false;
  }

  // Computed instance of cleansed params to be passed to data fetching implementation.
  // This is aggressively cached to avoid fetching new data wherever possible.
  _cachedParams;
  @computed get cleansedParams() {
    let keys = this.getRequiredParamKeys();
    if (!keys || keys.length === 0) {
      keys = Object.keys(this.params);
    }

    // Be lazy by avoiding updating data unless params of interest have changed
    let needsUpdate = false;
    let cleansedParams = {};
    keys.forEach((key) => {
      cleansedParams[key] = this.params[key];
    });

    // Always permit updates if there's nothing cached
    if (this._cachedParams == null) {
      needsUpdate = true;
    }

    // Or if one of the params is different
    else {
      for (let i = 0; i < keys.length; i++) {
        if (!this.paramIsSame(cleansedParams[keys[i]], this._cachedParams[keys[i]])) {
          needsUpdate = true;
          break;
        }
      }
    }

    // Cache and return new params if required
    if (needsUpdate) {
      this._cachedParams = cleansedParams;
      return cleansedParams;
    } else {
      return this._cachedParams;
    }
  }

  constructor(rootStore, config = {}) {
    super();
    this.rootStore = rootStore;

    // Enrich config
    this.config = {
      ...(this.config || {}),
      ...config,
    };

    // Initialise loadable superclass
    this.initialise();

    // Start subscription
    this.start();
  }

  // Computed flag for whether this subscription is active
  @computed get active() {
    return this.state.subscribers > 0;
  }

  // Handles disposal of other subscriptions tied to this one
  @action disposeWithSubscription(createSub) {
    this.state.subCreators.push(createSub);
    this.state.subDisposers.push(createSub());
  }

  // Inform loadable superclass how to dispose of subscriptions
  handleDisposal(createSub) {
    this.disposeWithSubscription(createSub);
  }

  // Implementation to fetch data based on current params
  getData() {
    throw "getData should be overridden";
  }

  // Implementation to sync an item between all instances of a subscription type
  sync() {
    throw "sync should be overridden";
  }

  // Updates params, avoiding triggering a data fetch if possible
  @action update(params = {}, replaceAllParams = false) {
    if (replaceAllParams) {
      this._params = params;
    } else {
      Object.keys(params).forEach((param) => {
        if (this._params[param] !== params[param]) {
          this._params[param] = params[param];
        }
      });
    }
  }

  // Forces a refresh of this subscription
  async refresh() {
    return this.getData(this.cleansedParams);
  }

  // Starts the subscription, or adds a subscriber if already started
  @action start() {
    const needsRefreshed =
      this.state.lastSubscriptionTime == null ||
      Date.now() - this.state.lastSubscriptionTime > this.config.refreshDebounce;

    // If we have other subscribers, just refresh data
    if (this.state.subscribers++) {
      if (this.config.autoRefresh && needsRefreshed) {
        this.refresh();
      }
    } else {
      // Otherwise create disposer and fire immediately if enough time has passed since the last update
      this._cachedParams = null;
      this.state.disposer = reaction(
        () => this.cleansedParams,
        (params) => this.getData(params),
        {
          fireImmediately: true,
        },
      );

      // Create other subs
      this.state.subCreators.forEach((createSub) => this.state.subDisposers.push(createSub()));
    }

    // Update time if fresh data was pulled
    if (needsRefreshed) {
      this.state.lastSubscriptionTime = Date.now();
    }
  }

  // Removes a subscriber from this subscription, or stops it if there's only 1 subscriber left
  @action cancel() {
    if (--this.state.subscribers) {
      return;
    }

    // Stop subscription
    this.state.disposer();

    // Wipe other subs
    this.state.subDisposers.forEach((disposer) => disposer());
    this.state.subDisposers = [];

    // By clearing observed params, we ensure that we will not pull data for old values whenever recreating the sub
    this.update({}, true);

    // Force cleanup by getting data with null params
    this.getData({});
  }
}
