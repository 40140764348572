const dsp = require("../libs/dsp");

const hilbertFunction = (twf) => {
  const twfLength = twf.length;
  const twfHalfLength = twfLength / 2;

  const processedFreq = new Float64Array(twfLength);
  processedFreq[0] = twf[0];
  processedFreq[twfHalfLength] = twf[twfHalfLength];

  for (let i = 1; i < twfHalfLength; ++i) {
    processedFreq[i] = twf[i] * 2;
  }

  for (let i = twfHalfLength + 1; i < twfLength; ++i) {
    processedFreq[i] = 0;
  }

  return processedFreq;
};

function Hilbert() {}
Hilbert.prototype.process = function (twfData, samplingFrequency) {
  const fft = new dsp.FFT(twfData.length, samplingFrequency);
  fft.forward(twfData);
  const real = fft.real;
  const imag = fft.imag;

  const resultReal = hilbertFunction(real);
  const resultImag = hilbertFunction(imag);

  const inverse = fft.inverse(resultReal, resultImag);
  return inverse.map((value) => Math.abs(value));
};

module.exports.Hilbert = Hilbert;
