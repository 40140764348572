// Make certain params more readable
const readableParamNames = ["site"];

// Hide certain params in obscuration mode
const hiddenParamNames = ["site", "name"];

export function encode(str, route, param, rootStore) {
  let encoded = encodeURIComponent(str).toString();

  // Encode
  if (readableParamNames.indexOf(param) !== -1) {
    encoded = encoded.replace(/%20/g, ".");
  }

  // Obscure
  if (rootStore.preferenceStore.obscureNames && hiddenParamNames.indexOf(param) !== -1) {
    encoded = encodeURIComponent(btoa(encoded));
  }

  return encoded;
}

// Decode route params
export function decode(str, route, param, rootStore) {
  let decoded = decodeURIComponent(str);

  // De-obscure
  if (rootStore.preferenceStore.obscureNames && hiddenParamNames.indexOf(param) !== -1) {
    try {
      decoded = atob(decoded);
    } catch (error) {
      // Ignore
    }
  }

  // Decode
  if (readableParamNames.indexOf(param) !== -1) {
    decoded = decoded.replace(/\./g, "%20");
  }

  return decodeURIComponent(decoded);
}
