import {lazy} from "react";

import {
  createRoute,
  parseInt,
  parseBool,
  parseDate,
  parseOneOf,
  serialiseBool,
  serialiseDate,
  trySet,
} from "sensoteq-react-core/lib/mobx-state-router";
import * as Enums from "constants/enums";
import {
  mapContinuousDataConfigQueryToState,
  mapContinuousDataConfigStateToQuery,
  mapTimeDomainDataConfigStateToQuery,
  mapTimeDomainDataQueryToState,
} from "../utils";

const PointOverviewTab = lazy(() => import("routes/point/overview-tab/PointOverviewTab"));
const PointDiagnosticsTab = lazy(() => import("routes/point/diagnostics-tab/PointDiagnosticsTab"));
const PointEnvironmentTab = lazy(() => import("routes/point/environment-tab/PointEnvironmentTab"));
const PointPeakToPeakTab = lazy(() => import("routes/point/peak-to-peak-tab/PointPeakToPeakTab"));
const PointConfigTab = lazy(() => import("routes/point/config-tab/PointConfigTab"));
const PointNodeChannelsConfigTab = lazy(() => import("routes/point/config-tab/PointNodeChannelsConfigTab"));
const PointKappaXConfigTab = lazy(async () => {
  return {default: (await import("routes/point/config-tab/PointKappaXConfigTab")).PointKappaXConfigTab};
});
const PointEllipseTab = lazy(() => import("routes/point/ellipse-tab/PointEllipseTab"));
const PointVibrationSpectrumTab = lazy(() => import("routes/point/vibration-spectrum-tab/PointVibrationSpectrumTab"));
const PointTimePlotTab = lazy(() => import("routes/point/vibration-spectrum-tab/PointTimePlotTab"));
const PointVibrationSpectrumWaterfallTab = lazy(
  () => import("routes/point/vibration-spectrum-waterfall-tab/PointVibrationSpectrumWaterfallTab"),
);
const PointVibrationTab = lazy(() => import("routes/point/vibration-tab/PointVibrationTab"));
const PointEllipseTrendingTab = lazy(() => import("routes/point/ellipse-trending-tab/PointEllipseTrendingTab"));
const PointVibrationSpectrumTrendingTab = lazy(
  () => import("routes/point/vibration-spectrum-trending-tab/PointVibrationSpectrumTrendingTab"),
);
const PointVibrationSpectrumTrendingOverviewTab = lazy(
  () => import("routes/point/vibration-spectrum-trending-overview-tab/PointVibrationSpectrumTrendingOverviewTab"),
);
const NodeChannelsGraphsTab = lazy(() => import("routes/point/node-channels-graphs-tab/NodeChannelsGraphsTab"));

export default {
  PointOverViewTab: createRoute({
    path: `/point/:point/${Enums.POINT_OVERVIEW_TAB}`,
    component: PointOverviewTab,
    hookParams: {
      requireLogin: true,
    },
  }),

  PointDiagnosticsTab: createRoute({
    path: `/point/:point/${Enums.POINT_DIAGNOSTICS_TAB}`,
    component: PointDiagnosticsTab,
    mapStateToQuery: mapContinuousDataConfigStateToQuery,
    mapQueryToState: mapContinuousDataConfigQueryToState,
    hookParams: {
      requireLogin: true,
    },
  }),

  PointEnvironmentTab: createRoute({
    path: `/point/:point/${Enums.POINT_ENVIRONMENT_TAB}`,
    component: PointEnvironmentTab,
    mapStateToQuery: mapContinuousDataConfigStateToQuery,
    mapQueryToState: mapContinuousDataConfigQueryToState,
    hookParams: {
      requireLogin: true,
    },
  }),

  PointPeakToPeakTab: createRoute({
    path: `/point/:point/${Enums.POINT_PEAK_TO_PEAK_TAB}`,
    component: PointPeakToPeakTab,
    mapStateToQuery: mapContinuousDataConfigStateToQuery,
    mapQueryToState: mapContinuousDataConfigQueryToState,
    hookParams: {
      requireLogin: true,
    },
  }),

  PointVibrationTab: createRoute({
    path: `/point/:point/${Enums.POINT_VIBRATION_TAB}`,
    component: PointVibrationTab,
    mapStateToQuery: mapContinuousDataConfigStateToQuery,
    mapQueryToState: mapContinuousDataConfigQueryToState,
    hookParams: {
      requireLogin: true,
    },
  }),

  PointVibrationSpectrumTrendingTab: createRoute({
    path: `/point/:point/${Enums.POINT_VIBRATION_SPECTRUM_TRENDING_TAB}`,
    component: PointVibrationSpectrumTrendingTab,
    mapStateToQuery: mapContinuousDataConfigStateToQuery,
    mapQueryToState: mapContinuousDataConfigQueryToState,
    hookParams: {
      requireLogin: true,
    },
  }),

  PointVibrationSpectrumTrendingOverviewTab: createRoute({
    path: `/point/:point/${Enums.POINT_VIBRATION_SPECTRUM_TRENDING_OVERVIEW_TAB}`,
    component: PointVibrationSpectrumTrendingOverviewTab,
    mapStateToQuery: mapContinuousDataConfigStateToQuery,
    mapQueryToState: mapContinuousDataConfigQueryToState,
    hookParams: {
      requireLogin: true,
    },
  }),

  NodeChannelsGraphsTab: createRoute({
    path: `/point/:point/${Enums.NODE_CHANNELS_TAB}`,
    component: NodeChannelsGraphsTab,
    mapStateToQuery: mapContinuousDataConfigStateToQuery,
    mapQueryToState: mapContinuousDataConfigQueryToState,
    hookParams: {
      requireLogin: true,
    },
  }),

  PointEllipseTab: createRoute({
    path: `/point/:point/${Enums.POINT_ELLIPSE_TAB}`,
    component: PointEllipseTab,
    mapStateToQuery: (rootStore) => {
      const {ellipseDataConfig} = rootStore.uiStore;
      return {
        ...mapContinuousDataConfigStateToQuery(rootStore),
        time: serialiseDate(ellipseDataConfig.time),
        cmp: ellipseDataConfig.comparisonPoint,
        ra: serialiseBool(ellipseDataConfig.rotationAdjustment),
      };
    },
    mapQueryToState: (rootStore, queryParams) => {
      const {time, cmp, ra} = queryParams;
      const {uiStore} = rootStore;
      mapContinuousDataConfigQueryToState(rootStore, queryParams);
      trySet(time, (x) => uiStore.setEllipseDataTime(parseDate(x, uiStore.ellipseDataConfig.time)));
      trySet(cmp, (x) => uiStore.setEllipseDataComparisonPoint(x));
      trySet(ra, (x) => uiStore.setEllipseDataRotationAdjustment(parseBool(x)));
    },
    hookParams: {
      requireLogin: true,
    },
  }),

  PointEllipseTrendingTab: createRoute({
    path: `/point/:point/${Enums.POINT_ELLIPSE_TRENDING_TAB}`,
    component: PointEllipseTrendingTab,
    mapStateToQuery: (rootStore) => {
      const {pointEllipseTrendingDataConfig} = rootStore.uiStore;
      return {
        ...mapContinuousDataConfigStateToQuery(rootStore),
        xd: pointEllipseTrendingDataConfig.xDomain,
        yd: pointEllipseTrendingDataConfig.yDomain,
        zd: pointEllipseTrendingDataConfig.zDomain,
      };
    },
    mapQueryToState: (rootStore, queryParams) => {
      const {xd, yd, zd} = queryParams;
      const {uiStore} = rootStore;
      mapContinuousDataConfigQueryToState(rootStore, queryParams);
      trySet(xd, (x) => uiStore.setPointEllipseTrendingDataXDomain(x));
      trySet(yd, (x) => uiStore.setPointEllipseTrendingDataYDomain(x));
      trySet(zd, (x) => uiStore.setPointEllipseTrendingDataZDomain(x));
    },
    hookParams: {
      requireLogin: true,
    },
  }),

  PointVibrationSpectrumTab: createRoute({
    path: `/point/:point/${Enums.POINT_VIBRATION_SPECTRUM_TAB}`,
    component: PointVibrationSpectrumTab,
    mapStateToQuery: (rootStore) => {
      const {timeDomainDataConfig} = rootStore.uiStore;
      return {
        ...mapTimeDomainDataConfigStateToQuery(rootStore),
        kt: serialiseDate(timeDomainDataConfig.keyTime),
      };
    },
    mapQueryToState: (rootStore, queryParams) => {
      const {kt} = queryParams;
      const {uiStore} = rootStore;
      mapTimeDomainDataQueryToState(rootStore, queryParams);
      trySet(kt, (x) => uiStore.setTimeDomainDataKeyTime(parseDate(x, uiStore.timeDomainDataConfig.keyTime)));
    },
    hookParams: {
      requireLogin: true,
    },
  }),

  PointTimePlotTab: createRoute({
    path: `/point/:point/${Enums.POINT_TIME_PLOT_TAB}`,
    component: PointTimePlotTab,
    mapStateToQuery: (rootStore) => {
      const {timeDomainDataConfig} = rootStore.uiStore;
      return {
        ...mapTimeDomainDataConfigStateToQuery(rootStore),
        kt: serialiseDate(timeDomainDataConfig.keyTime),
      };
    },
    mapQueryToState: (rootStore, queryParams) => {
      const {kt} = queryParams;
      const {uiStore} = rootStore;
      mapTimeDomainDataQueryToState(rootStore, queryParams);
      trySet(kt, (x) => uiStore.setTimeDomainDataKeyTime(parseDate(x, uiStore.timeDomainDataConfig.keyTime)));
    },
    hookParams: {
      requireLogin: true,
    },
  }),

  PointVibrationSpectrumWaterfallTab: createRoute({
    path: `/point/:point/${Enums.POINT_VIBRATION_SPECTRUM_WATERFALL_TAB}`,
    component: PointVibrationSpectrumWaterfallTab,
    mapStateToQuery: (rootStore) => {
      const {timeDomainDataConfig, waterfallDataConfig, continuousDataConfig} = rootStore.uiStore;
      if (waterfallDataConfig.envelope && waterfallDataConfig.domain !== Enums.ACCELERATION_DOMAIN) {
        rootStore.uiStore.setWaterfallDataDomain(Enums.ACCELERATION_DOMAIN);
      }
      if (waterfallDataConfig.envelope && continuousDataConfig.resolution === Enums.HIGH_RES) {
        rootStore.uiStore.setWaterfallDataEnvelopeOption(false);
      }
      return {
        ...mapContinuousDataConfigStateToQuery(rootStore),
        wo: timeDomainDataConfig.windowOption.toLowerCase(),
        count: waterfallDataConfig.count,
        domain: waterfallDataConfig.domain,
        rms: waterfallDataConfig.rms,
        ampl: waterfallDataConfig.amplitudeOption,
        envelope: serialiseBool(waterfallDataConfig.envelope),
        fmax: waterfallDataConfig.fMax,
      };
    },
    mapQueryToState: (rootStore, queryParams) => {
      const {wo, count, domain, rms, ampl, envelope, fmax} = queryParams;
      const {uiStore} = rootStore;
      mapContinuousDataConfigQueryToState(rootStore, queryParams);
      trySet(count, (x) =>
        uiStore.setWaterfallDataCount(parseOneOf(x, [10, 20, 30, 40, 50, 60, 70, 80, 90, 100], parseInt)),
      );
      trySet(domain, (x) => uiStore.setWaterfallDataDomain(parseOneOf(x, Enums.DOMAINS)));
      trySet(rms, (x) => uiStore.setWaterfallDataRms(parseInt(x)));
      trySet(wo, (x) => uiStore.setTimeDomainDataWindowOption(parseOneOf(x, Enums.WINDOWS, (x) => x.toUpperCase())));
      trySet(ampl, (x) => uiStore.setWaterfallDataAmplitudeOption(parseOneOf(x, Object.values(Enums.AmplitudeOption))));
      trySet(envelope, (x) => uiStore.setWaterfallDataEnvelopeOption(parseBool(x)));
      trySet(fmax, (x) => uiStore.setWaterfallDataFMax(parseInt(x)));
    },
    hookParams: {
      requireLogin: true,
    },
  }),

  PointConfigTab: createRoute({
    path: `/point/:point/${Enums.POINT_CONFIG_TAB}`,
    component: PointConfigTab,
    hookParams: {
      requireLogin: true,
    },
  }),

  PointKappaXConfigTab: createRoute({
    path: `/point/:point/${Enums.POINT_KAPPA_X_CONFIG_TAB}`,
    component: PointKappaXConfigTab,
    hookParams: {
      requireLogin: true,
    },
  }),

  PointNodeChannelsConfigTab: createRoute({
    path: `/point/:point/${Enums.POINT_NODE_CHANNELS_CONFIG_TAB}`,
    component: PointNodeChannelsConfigTab,
    hookParams: {
      requireLogin: true,
    },
  }),
};
