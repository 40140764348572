import {WindowingOptions} from "sensoteq-core/calculations";
import {
  ServiceAppModes,
  NodeChannelConfig as CoreNodeChannelConfig,
  MeasuringPointTypes,
  SensorTypes,
  PresentableDataTypes,
} from "sensoteq-core/enumerations";
import {measureScrollBarWidth} from "sensoteq-react-core/services/utils";
export * from "sensoteq-react-core/constants/enums";

export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const IS_MOCK = process.env.TEST_ENV === "mock";
export const BROWSER_DEFAULT_FONT_SIZE =
  (parseFloat(window.getComputedStyle(document.body).getPropertyValue("font-size") || 0) * 100) / 62.5 || 16;
export const DESIGN_FONT_SIZE = 10;
export const SCROLL_BAR_WIDTH = measureScrollBarWidth();

export const POINT_OVERVIEW_TAB = "overview";
export const POINT_DIAGNOSTICS_TAB = "diagnostics";
export const POINT_ENVIRONMENT_TAB = "environment";
export const POINT_ELLIPSE_TAB = "ellipse";
export const POINT_PEAK_TO_PEAK_TAB = "peak-to-peak";
export const POINT_VIBRATION_SPECTRUM_TAB = "vibration-spectrum";
export const POINT_VIBRATION_SPECTRUM_WATERFALL_TAB = "vibration-spectrum-waterfall";
export const POINT_TIME_PLOT_TAB = "time-plot";
export const POINT_VIBRATION_SPECTRUM_HEATMAP_TAB = "vibration-spectrum-heatmap";
export const POINT_VIBRATION_SPECTRUM_TRENDING_TAB = "vibration-spectrum-trending";
export const POINT_VIBRATION_SPECTRUM_TRENDING_OVERVIEW_TAB = "vibration-spectrum-trending-overview";
export const NODE_CHANNELS_TAB = "node-channels-overview-tab";
export const POINT_CONFIG_TAB = "config";
export const POINT_KAPPA_X_CONFIG_TAB = "kappa-x-config";
export const POINT_NODE_CHANNELS_CONFIG_TAB = "node-channels-config";
export const POINT_VIBRATION_TAB = "vibration";
export const POINT_ELLIPSE_TRENDING_TAB = "ellipse-trending";

export const THERMAL_OVERVIEW_TAB = "overview";

export const THERMAL_IMAGE_VIEW = "thermal-image-view";
export const THERMAL_IMAGE_EDIT = "thermal-image-edit";
export const THERMAL_IMAGE_TRENDING = "thermal-image-trending";

export const MACHINE_ORBIT_REPORT_TAB = "orbit-report";
export const MACHINE_ENVIRONMENT_TAB = "environment";
export const MACHINE_ELLIPSE_TRENDING_TAB = "ellipse-trending";
export const MACHINE_OVERVIEW_TAB = "overview";
export const MACHINE_THERMAL_ZONE_TAB = "thermal-zone";
export const MACHINE_CONFIG_TAB = "config";
export const MACHINE_SCREEN_DASHBOARD_TAB = "screen-dashboard";
export const MACHINE_REPLACEMENTS_TAB = "replacements";

// Syntron
export const MACHINE_LINER_WEAR_TAB = "liner-wear";

// V2 Enums
export const MACHINE_OVERVIEW_DATA_TAB = "data";
export const MACHINE_OVERVIEW_ANALYSIS_TAB = "analysis";
export const SCREEN_SENSOR_BEARING_VIEW = "bearing";
export const SCREEN_SENSOR_STRUCTURE_VIEW = "structure";

export const SITE_OVERVIEW_TAB = "overview";
export const SITE_OVERVIEW_TAB_V2 = "overviewV2";
export const SITE_MAP_TAB = "map";

export const SETTINGS_SITES_TAB = "sites";
export const SETTINGS_MACHINES_TAB = "machines";
export const SETTINGS_MACHINE_TYPES_TAB = "machine-types";
export const SETTINGS_GATEWAYS_TAB = "gateways";
export const SETTINGS_PREFERENCES_TAB = "preferences";
export const SETTINGS_SENSORS_TAB = "sensors";
export const SETTINGS_USERS_TAB = "users";
export const SETTINGS_SESSIONS_TAB = "sessions";
export const SETTINGS_ACCOUNT_TAB = "account";
export const SETTINGS_COMPANIES_TAB = "companies";
export const SETTINGS_REPORTS_TAB = "reports";
export const SETTINGS_PARTNERS_TAB = "partners";

export const ALARMS_HISTORY_DAYS_30 = 30;
export const ALARMS_HISTORY_DAYS_7 = 7;
export const ALARMS_STATUS_TAB = "status";
export const ALARMS_LIST_TAB = "list";
export const ALARMS_NEW_TAB = "new";
export const ALARMS_DETAILS_TAB = "details";
export const ALARMS_BULK_TAB = "bulk";
export const ALARMS_TEMPLATES_TAB = "templates";

export const NEIGHBOURHOOD_WATCH_DASHBOARD_TAB = "dashboard";
export const NEIGHBOURHOOD_WATCH_ASSIGNMENT_TAB = "assignment";

export const CDE_TESTS_OPEN_TAB = "open";
export const CDE_TESTS_VIEW_TAB = "view";

export const SERVICE_OPEN_TAB = "open";
export const SERVICE_ONLINE_TAB = "online";
export const SERVICE_CSV_TAB = "csv";

export const CHI_OPEN_TAB = "open";
export const CHI_VIEW_TAB = "view";

export const TEMPERATURE_VIEW = "TEMPERATURE_VIEW";
export const DELTA_TEMPERATURE_VIEW = "DELTA_TEMPERATURE_VIEW";
export const GATEWAY_TEMPERATURE_VIEW = "GATEWAY_TEMPERATURE_VIEW";
export const GATEWAY_SIGNAL_STRENGTH_VIEW = "GATEWAY_SIGNAL_STRENGTH_VIEW";
export const HUMIDITY_VIEW = "HUMIDITY_VIEW";
export const VOLTAGE_VIEW = "VOLTAGE_VIEW";
export const ACCELERATION_RMS_VIEW = "ACCELERATION_RMS_VIEW";
export const OA_VELOCITY_RMS_VIEW = "OA_VELOCITY_RMS_VIEW";
export const OA_ACCELERATION_RMS_VIEW = "OA_ACCELERATION_RMS_VIEW";
export const VELOCITY_RMS_VIEW = "VELOCITY_RMS_VIEW";
export const TOTAL_RMS_VIEW = "TOTAL_RMS_VIEW";
export const RF_VALIDITY_VIEW = "RF_VALIDITY_VIEW";
export const SPECTRUM_VIEW = "SPECTRUM_VIEW";
export const PEAK_TO_PEAK_VIEW = "PEAK_TO_PEAK_VIEW";
export const SHORT_PEAK_TO_PEAK_VIEW = "SHORT_PEAK_TO_PEAK_VIEW";
export const POWER_BAND_VIEW = "PEAK_BAND_VIEW";
export const STROKE_LENGTH_AND_RPM_VIEW = "STROKE_LENGTH_AND_RPM_VIEW";
export const TIME_WAVEFORM_VIEW = "TIME_WAVEFORM_VIEW";
export const CREST_FACTOR_VIEW = "CREST_FACTOR_VIEW";
export const TWF_ACCELERATION_PEAK_VIEW = "TWF_ACCELERATION_PEAK_VIEW";
export const ENVELOPE_RMS_VIEW = "ENVELOPE_RMS_VIEW";
export const ENVELOPE_PEAK_VIEW = "ENVELOPE_PEAK_VIEW";
export const MAGNETOMETER_SUPPLY_FREQUENCY_VIEW = "MAGNETOMETER_SUPPLY_FREQUENCY_VIEW";
export const MAGNETOMETER_AMPLITUDE_VIEW = "MAGNETOMETER_AMPLITUDE_VIEW";
export const RPM_VIEW = "RPM_VIEW";

export const TIME_DOMAIN_VIEWS = [
  PEAK_TO_PEAK_VIEW,
  TWF_ACCELERATION_PEAK_VIEW,
  CREST_FACTOR_VIEW,
  ENVELOPE_RMS_VIEW,
  ENVELOPE_PEAK_VIEW,
  RPM_VIEW,
  OA_ACCELERATION_RMS_VIEW,
  OA_VELOCITY_RMS_VIEW,
  POWER_BAND_VIEW,
];

export const ANALYSIS_VIEWS = [
  PEAK_TO_PEAK_VIEW,
  TWF_ACCELERATION_PEAK_VIEW,
  POWER_BAND_VIEW,
  OA_ACCELERATION_RMS_VIEW,
  OA_VELOCITY_RMS_VIEW,
  CREST_FACTOR_VIEW,
  ENVELOPE_RMS_VIEW,
  ENVELOPE_PEAK_VIEW,
  RPM_VIEW,
  SPECTRUM_VIEW,
  TIME_WAVEFORM_VIEW,
  STROKE_LENGTH_AND_RPM_VIEW,
  ACCELERATION_RMS_VIEW,
  VELOCITY_RMS_VIEW,
  SHORT_PEAK_TO_PEAK_VIEW,
  TEMPERATURE_VIEW,
  DELTA_TEMPERATURE_VIEW,
  GATEWAY_TEMPERATURE_VIEW,
  HUMIDITY_VIEW,
  GATEWAY_SIGNAL_STRENGTH_VIEW,
  VOLTAGE_VIEW,
  RF_VALIDITY_VIEW,
];

// V2 Data Views
export const MACHINE_DATA_VIEWS = {
  ACCELERATION_VIEW: "acc",
  VELOCITY_VIEW: "vel",
  POWER_BAND_VIEW: "power",
  ENVELOPE_RMS_VIEW: "env_rms",
  ENVELOPE_PEAK_VIEW: "env_peak",
  PEAK_TO_PEAK_VIEW: "peak_to_peak",
  RPM_VIEW: "rpm",
  CREST_FACTOR_VIEW: "crest_factor",
  MAIN_G_VIEW: "main_g",
  STROKE_ANGLE_VIEW: "stroke_angle",
  STROKE_LENGTH_VIEW: "stroke_length",
  X_DISPLACEMENT_VIEW: "x_disp",
  Y_DISPLACEMENT_VIEW: "y_disp",
  Z_DISPLACEMENT_VIEW: "z_disp",
  TEMPERATURE_VIEW: "temp",
};

export const WINDOWS = [
  WindowingOptions.NONE,
  WindowingOptions.HANN_WINDOW,
  WindowingOptions.SQUARE_AND_HANN_WINDOW,
  WindowingOptions.SQUARE_WINDOW,
];

export const SERVICE_APP_MODES = [
  ServiceAppModes.VIBRATION_ANALYSIS,
  ServiceAppModes.STROKE_ANALYSIS,
  ServiceAppModes.HAMMER_TEST,
];

export const TIME_1_HOUR = 1000 * 60 * 60;
export const TIME_1_DAY = TIME_1_HOUR * 24;
export const TIME_1_WEEK = TIME_1_DAY * 7;
export const TIME_1_MONTH = TIME_1_DAY * 30;

export const FlagReasons = {
  OFFLINE: "offline",
  RF: "rf",
  BATTERY: "battery",
  OTHER: "other",
  ALARMS: "alarms",
};

export const StatusThemes = {
  GOOD: "good",
  BAD: "bad",
  WARNING: "warning",
  OFFLINE: "offline",
  INFO: "info",
};

export const NeighbourhoodWatchDashboardTypes = {
  ALL: "all",
  ASSIGNED: "assigned",
};

export const CDETestTimes = {
  ONE_HOUR: "ONE_HOUR",
  FINAL: "FINAL",
};

export const CDETestStates = {
  FINISHED: "finished",
  APPROVED: "approved",
  FLAGGED: "flagged",
};

export const WaterfallDisplay = {
  GRAPH: "graph",
  HEAT_MAP: "heat_map",
};

export const USER_ROLE_ADMIN = PresentableDataTypes.ADMIN;
export const USER_ROLE_PARTNER = PresentableDataTypes.PARTNER;
export const USER_ROLE_MANAGER = PresentableDataTypes.COMPANY_MANAGER;
export const USER_ROLE_INSTALLER = PresentableDataTypes.INSTALLER;
export const USER_ROLE_FAULT_LABELLER = PresentableDataTypes.FAULT_LABELLER;
export const USER_ROLE_USER = "user";
export const USER_ROLES = [USER_ROLE_USER, USER_ROLE_INSTALLER, USER_ROLE_MANAGER, USER_ROLE_PARTNER, USER_ROLE_ADMIN];

export const USER_ROLE_TITLES = {
  [USER_ROLE_ADMIN]: "Admin",
  [USER_ROLE_PARTNER]: "Partner",
  [USER_ROLE_MANAGER]: "Manager",
  [USER_ROLE_INSTALLER]: "Installer",
};

export const SensorRepositories = {
  STM: "Sensoteq/sensoteq-stm-sensor",
  INNOVATEUK: "Sensoteq/innovateuk-sensor",
  WIRED_NODE: "Sensoteq/sensoteq-receiver",
  //WIRELESS_NODE: "?",
};

export function repositoryLookup(sensorType) {
  switch (sensorType) {
    case SensorTypes.KPX:
      return SensorRepositories.INNOVATEUK;
    case MeasuringPointTypes.WIRED_NODE:
      return SensorRepositories.WIRED_NODE;
    default: {
      return SensorRepositories.STM;
    }
  }
}

export const HumanReadableNodeType = {
  [MeasuringPointTypes.WIRED_NODE]: "Wired Node",
  [MeasuringPointTypes.WIRELESS_NODE]: "Wireless Node",
};

export const NodeChannelConfig = CoreNodeChannelConfig;

export const NodeChannelSensingElements = {
  [NodeChannelConfig.SensingElement.SIXTEEN_BIT]: "4-20mA/5-10v",
  [NodeChannelConfig.SensingElement.BOOLEAN]: "Volt Free",
};

export const NodeChannelSensorTypes = {
  [NodeChannelConfig.SensorType.OIL_QUALITY]: "Oil Quality",
  [NodeChannelConfig.SensorType.CURRENT_MEASUREMENT]: "Current Measurement",
  [NodeChannelConfig.SensorType.GENERIC]: "Generic",
};

export const NodeChannelMeasurementTypes = {
  [NodeChannelConfig.MeasurementType.OIL_PARTICLE_FINE]: "Oil Particle Fine",
  [NodeChannelConfig.MeasurementType.OIL_PARTICLE_COARSE]: "Oil Particle Coarse",
  [NodeChannelConfig.MeasurementType.TEMPERATURE]: "Temperature",
  [NodeChannelConfig.MeasurementType.ERROR]: "Error",
  [NodeChannelConfig.MeasurementType.PHASE_A_BLACK_CURRENT]: "Phase A Black - Current",
  [NodeChannelConfig.MeasurementType.PHASE_B_RED_CURRENT]: "Phase B Red - Current",
  [NodeChannelConfig.MeasurementType.PHASE_C_BLUE_CURRENT]: "Phase C Blue - Current",
  [NodeChannelConfig.MeasurementType.GENERIC]: "Generic",
};

export const NodeChannelUnits = {
  [NodeChannelConfig.Unit.PERCENT]: "%",
  [NodeChannelConfig.Unit.DEGC]: "Deg. C",
  [NodeChannelConfig.Unit.ERRVAL]: "Error",
  [NodeChannelConfig.Unit.AMPS]: "Amps",
  [NodeChannelConfig.Unit.DATA]: "Data",
};

export const NodeChannelBooleans = {
  [NodeChannelConfig.BooleanOptions.ONE_ON]: "1=ON, 0=OFF",
  [NodeChannelConfig.BooleanOptions.ONE_OFF]: "0=ON, 1=OFF",
};

export const MachineTypeOptions = {
  TYPE: "Type",
  SUBTYPE: "Subtype",
  SUBTYPE_DIAGRAM: "Subtype Diagram",
};

export const DefaultStatusIndicatorLevels = {
  voltage: {
    min: 3500,
    max: 3700,
  },
  rfValidity: {
    min: 80,
    max: 100,
  },
};

export const RPMType = {
  MAX: "max",
  MEASURED: "measured",
};

export const AmplitudeOption = {
  RMS: "rms",
  PEAK: "peak",
};

export const DataTypeOptions = {
  SHORT_INTERVAL: "short interval",
  LONG_INTERVAL: "long interval",
  DIAGNOSTICS: "diagnostics",
  STRUCTURE: "structure",
};

// display_name of Machine SubType that supports Feeder config
// Syntron customer has some unique calculations and config, the subt-type
// is used to restrict access
export const SYNTRON_FEEDER_MACHINE_SUBTYPE = "Syntron Feeder";
