import {createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: ${(props) => props.theme.state.fontSizePercentage}%;
    min-width: ${(props) => (props.theme.dimensions.minWidth * props.theme.state.fontSizePercentage) / 100}rem;
    overflow-x: auto;
    overflow-y: hidden;
  }

  // Default fonts
  body {
    overflow: hidden;
    font-size: ${(props) => props.theme.fontSizes.medium};
    font-family: "Roboto", sans-serif;
  }
  
  // Default colours
  body, input, select, textarea, a {
    color: ${(props) => props.theme.colours.content};
  }
  
  i {
    font-size: 1.6rem;
    user-select: none;
  }
  
  // Material icons
  i.material-icons {
    font-size: 2rem;
    user-select: none;
    font-family: "Material Icons", sans-serif;
  }
  
  // HTML default styles
  h1 {
    font-size: 3.2rem;
  }
  h2 {
    font-size: 2.4rem;
    font-weight: 500;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 500;
  }
  
  // Scrollbars
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 1.5rem;
  }
   
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
  }
   
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colours.contentPlaceholder};
  }
  
  // Override leaflet tooltips
  .leaflet-tooltip {
    font-weight: 500;
    font-family: Roboto, sans-serif;
    font-size: 1.4rem;
    color: white !important;
    background-color: ${(props) => props.theme.colours.primary} !important;
    padding: 0.4rem 0.6rem !important;
    border: none !important;
    box-shadow: ${(props) => props.theme.forms.boxShadow} !important;
  }
  .leaflet-tooltip-top::before {
    display: none;
  }
  
  // Override react tooltips
  .__react_component_tooltip.show {
    opacity: 1 !important;
    z-index: 999;
    padding: 1rem 1.4rem;
    border-radius: ${(props) => props.theme.forms.borderRadius};
    font-size: 1.4rem;
    color: white !important;
  
    @media (max-width: 600px) {
      display: none;
    }
  }
  
  // Restyle leaflet attribution
  .leaflet-control-attribution {
    background: none !important;
    color: #888 !important;
    font-size: 1rem !important;
  
    * {
      color: #888 !important;
      font-size: 1rem !important;
      opacity: 1;
    }
  }
  
  // Override HTML2PDF
  .html2pdf__overlay {
    z-index: -1000 !important;
  }
`;
