export const Ranges = {
  D_LINE: "dline",
  F_LINE: "fline",
  H_LINE: "hline",
  P_LINE: "pline",
  WELDED: "welded",
};

export const RangeOptions = [
  {
    value: Ranges.D_LINE,
    label: "D-Line",
  },
  {
    value: Ranges.F_LINE,
    label: "F-Line",
  },
  {
    value: Ranges.H_LINE,
    label: "H-Line",
  },
  {
    value: Ranges.P_LINE,
    label: "P-Line",
  },
  {
    value: Ranges.WELDED,
    label: "Welded",
  },
];

export const ModelOptions = {
  [Ranges.D_LINE]: [
    {
      value: "d1_43",
      label: "D1-43",
    },
    {
      value: "d1_63",
      label: "D1-63",
    },
    {
      value: "d1_64",
      label: "D1-64",
    },
    {
      value: "d1_65",
      label: "D1-65",
    },
  ],
  [Ranges.F_LINE]: [
    {
      value: "f1_64",
      label: "F1-64",
    },
  ],
  [Ranges.H_LINE]: [
    {
      value: "h2_60",
      label: "H2-60",
    },
    {
      value: "h3_42",
      label: "H3-42",
    },
    {
      value: "h3_45",
      label: "H3-45",
    },
  ],
  [Ranges.P_LINE]: [
    {
      value: "p1_36",
      label: "P1-36",
    },
    {
      value: "p2_75",
      label: "P2-75",
    },
    {
      value: "p2_75r",
      label: "P2-75R",
    },
    {
      value: "p2_90",
      label: "P2-90",
    },
    {
      value: "p2_108",
      label: "P2-108",
    },
    {
      value: "p3_75",
      label: "P3-75",
    },
    {
      value: "p3_108",
      label: "P3-108",
    },
  ],
  [Ranges.WELDED]: [
    {
      value: "d43",
      label: "D43",
    },
    {
      value: "ds64",
      label: "DS64",
    },
    {
      value: "ds65",
      label: "DS65",
    },
  ],
};
