import {useEffect, useMemo, useState} from "react";
import {useRootStore} from "../../stores";
import {Toggle} from "components/Toggle";

const ENABLED_PATHNAMES = ["/v2", "/site", "/machine"];

const ToggleV2 = () => {
  // @ts-expect-error Stores are not typed
  const {preferenceStore, configStore} = useRootStore();
  const [loading, setLoading] = useState(false);
  const [layout, setLayout] = useState(preferenceStore.layout || "v1");
  const isEnabled = useMemo(() => {
    const currentPath = window.location.pathname;
    return configStore.isLayoutV2Available && ENABLED_PATHNAMES.some((pathname) => currentPath.startsWith(pathname));
  }, [window.location.pathname, configStore.isLayoutV2Available]);

  useEffect(() => {
    setLayout(preferenceStore.layout);
  }, [preferenceStore.layout]);

  const toggleLayout = async () => {
    const updatedLayout = preferenceStore.layout === "v1" ? "v2" : "v1";
    setLayout(updatedLayout);
    setLoading(true);
    await preferenceStore.updateLayoutPreferences(updatedLayout);
    setLoading(false);
  };

  return (
    isEnabled && (
      <div className="mx-3">
        <Toggle
          label="New Layout"
          onChange={toggleLayout}
          isOn={layout === "v2"}
          disabled={loading}
          title="Instantly understand your site and machine health with our new high level overviews."
        />
      </div>
    )
  );
};

export default ToggleV2;
