import {computed} from "mobx";

import MultiDataSubscription from "./MultiDataSubscription";

export default class MultiCriticalitySubscription extends MultiDataSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      getSubscribableStore: (x) => x.criticalityStore,
      identifyingParam: "target",
    };
  }

  @computed get sites() {
    return this.identifiers;
  }

  getSetTitle(siteName) {
    const site = this.rootStore.siteStore.getSite(siteName);
    if (!site) {
      return "Unknown site";
    }
    return site.display_name;
  }

  @computed get criticalityData() {
    return this.enrichTitles(
      this.subscriptions.map((sub) => {
        return sub.criticalityData?.[0] ?? {};
      }),
    ).filter((x) => x.data != null);
  }

  @computed get criticalityScore() {
    return this.subscriptions.map((sub) => {
      return sub.currentWeekHighestScore;
    });
  }

  @computed get weeklyDeltas() {
    return this.identifiers.map((x) => {
      return {
        siteName: x,
        delta: this.getSubscription(x).weeklyDelta,
      };
    });
  }
}
