import styled, {css, keyframes} from "styled-components";

export const ActiveBlock = styled.div`
  position: absolute;
  right: 0;
  width: 0.8rem;
  background-color: ${(props) => props.theme.colours.mainMenuHighlight};
  height: calc(100% - 0.4rem);
  display: none;
  border-top-left-radius: ${(props) => props.theme.forms.borderRadius};
  border-bottom-left-radius: ${(props) => props.theme.forms.borderRadius};
  top: 50%;
  transform: translateY(-50%);
`;

export const Link = styled.a`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding: 0 2rem;
  height: 3.8rem;
  text-decoration: none;
  position: relative;

  i {
    font-size: 2rem;
  }

  i,
  span {
    color: ${(props) => props.theme.colours.mainMenuText};
    transition: color 0.1s ease;
  }

  span {
    font-size: ${(props) => props.theme.fontSizes.tiny};
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    margin-left: 4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: rgba(0, 0, 0, 0.1);

      ${ActiveBlock} {
        display: block;
      }

      i,
      span {
        color: ${(props) => props.theme.colours.mainMenuTextOnActive};
      }
    `}

  &:hover {
    cursor: pointer;

    i,
    span,
    svg {
      color: ${(props) => props.theme.colours.mainMenuTextOnHover};
    }
  }

  ${(props) =>
    props.collapsed &&
    css`
      padding: 0;
      justify-content: center;
      overflow: hidden;
    `}

  ${(props) =>
    props.hideMobile &&
    props.theme.mixins.mobile(css`
      display: none;
    `)}
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.theme.dimensions.mainMenuCollapsedWidth}rem;
  position: absolute;
  left: 0;
  margin: 0;

  ${(props) =>
    !props.collapsed &&
    css`
      margin-left: -0.5rem;
    `}
`;

export const highlight = keyframes`
  50% {
    opacity: 0.4;
  }
`;

export const Highlight = styled(Link)`
  i,
  span {
    ${(props) =>
      props.highlight &&
      css`
        color: ${(props) => props.theme.colours.warning};
      `}
  }
  ${(props) =>
    props.highlight &&
    css`
      animation: ${highlight} 3.5s linear 3;
    `}
`;
