import {lazy} from "react";

import {createRoute} from "sensoteq-react-core/lib/mobx-state-router";
import * as Enums from "constants/enums";

const SitesTab = lazy(() => import("routes/settings/sites-tab/SitesTab"));
const MachinesTab = lazy(() => import("routes/settings/machines-tab/MachinesTab"));
const MachineTypesTab = lazy(() => import("routes/settings/machine-types-tab/MachineTypesTab"));
const GatewaysTab = lazy(() => import("routes/settings/gateways-tab/GatewaysTab"));
const SensorsTab = lazy(() => import("routes/settings/sensors-tab/SensorsTab"));
const UsersTab = lazy(() => import("routes/settings/users-tab/UsersTab"));
const SessionsTab = lazy(() => import("routes/settings/sessions-tab/SessionsTab"));
const PreferencesTab = lazy(() => import("routes/settings/preferences-tab/PreferencesTab"));
const AccountTab = lazy(() => import("routes/settings/account-tab/AccountTab"));
const CompaniesTab = lazy(() => import("routes/settings/companies-tab/CompaniesTab"));
const PartnersTab = lazy(() => import("routes/settings/partners-tab/PartnersTab"));
const ReportsTab = lazy(() => import("routes/settings/reports-tab/ReportsTab"));
const OverviewTab = lazy(() => import("routes/settings/overview-tab/OverviewTab"));

export default {
  SettingsAccountTab: createRoute({
    path: `/settings/${Enums.SETTINGS_ACCOUNT_TAB}`,
    component: AccountTab,
    hookParams: {
      requireLogin: true,
    },
  }),

  SettingsUsersTab: createRoute({
    path: `/settings/${Enums.SETTINGS_USERS_TAB}/:username?`,
    component: UsersTab,
    hookParams: {
      requireLogin: true,
      requiredRole: Enums.USER_ROLE_PARTNER,
      requireMFA: true,
    },
  }),

  SettingsSessionsTab: createRoute({
    path: `/settings/${Enums.SETTINGS_SESSIONS_TAB}`,
    component: SessionsTab,
    hookParams: {
      requireLogin: true,
      requireAdmin: true,
      requireMFA: true,
    },
  }),

  SettingsSitesTab: createRoute({
    path: `/settings/${Enums.SETTINGS_SITES_TAB}/:site?`,
    component: SitesTab,
    hookParams: {
      requireLogin: true,
      requiredRole: Enums.USER_ROLE_INSTALLER,
      requireMFA: true,
    },
  }),

  SettingsMachinesTab: createRoute({
    path: `/settings/${Enums.SETTINGS_MACHINES_TAB}/:machine?`,
    component: MachinesTab,
    hookParams: {
      requireLogin: true,
      requiredRole: Enums.USER_ROLE_PARTNER,
      requireMFA: true,
    },
  }),

  SettingsMachineTypesTab: createRoute({
    path: `/settings/${Enums.SETTINGS_MACHINE_TYPES_TAB}/:type?/:subtype?/:subtypeDiagram?`,
    component: MachineTypesTab,
    mapQueryToState: () => {},
    hookParams: {
      requireLogin: true,
      requireAdmin: true,
      requireMFA: true,
    },
  }),

  SettingsSensorsTab: createRoute({
    path: `/settings/${Enums.SETTINGS_SENSORS_TAB}/:sensor?`,
    component: SensorsTab,
    hookParams: {
      requireLogin: true,
      requireAdmin: true,
      requireMFA: true,
    },
  }),

  SettingsGatewaysTab: createRoute({
    path: `/settings/${Enums.SETTINGS_GATEWAYS_TAB}/:gateway?`,
    component: GatewaysTab,
    hookParams: {
      requireLogin: true,
      requiredRole: Enums.USER_ROLE_PARTNER,
      requireMFA: true,
    },
  }),

  SettingsPreferencesTab: createRoute({
    path: `/settings/${Enums.SETTINGS_PREFERENCES_TAB}`,
    component: PreferencesTab,
    hookParams: {
      requireLogin: true,
      requireMFA: true,
    },
  }),

  SettingsCompaniesTab: createRoute({
    path: `/settings/${Enums.SETTINGS_COMPANIES_TAB}/:company?`,
    component: CompaniesTab,
    hookParams: {
      requireLogin: true,
      requiredRole: Enums.USER_ROLE_PARTNER,
      requireMFA: true,
    },
  }),

  SettingsPartnersTab: createRoute({
    path: `/settings/${Enums.SETTINGS_PARTNERS_TAB}/:partner?`,
    component: PartnersTab,
    hookParams: {
      requireLogin: true,
      requiredRole: Enums.USER_ROLE_ADMIN,
      requireMFA: true,
    },
  }),

  SettingsReportsTab: createRoute({
    path: `/settings/${Enums.SETTINGS_REPORTS_TAB}`,
    component: ReportsTab,
    hookParams: {
      requireLogin: true,
      requireAdmin: true,
      requireMFA: true,
    },
  }),
  SettingsOverviewTab: createRoute({
    path: `/settings`,
    component: OverviewTab,
    hookParams: {
      requireLogin: true,
      requireMFA: true,
    },
  }),
};
