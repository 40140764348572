import {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {autorun, observable, action, reaction} from "mobx";
import {useRootStore} from "../../stores";

const MAX_NOTIFICATIONS = 20;
const NOTIFICATION_TIMEOUT_MS = 8000;

function classNames(...classes) {
  return classes.filter((c) => c).join(" ");
}

const isAlarmNotification = (notificationType) =>
  notificationType === "alarm_warning" || notificationType === "alarm_critical";

const NotificationPane = () => {
  const {notificationStore} = useRootStore();
  const [notifications, setNotifications] = useState(
    Array(MAX_NOTIFICATIONS)
      .fill()
      .map(() => new Notification()),
  );
  const deleteNotification = useCallback(
    (id) => {
      let notification = notifications.find((notification) => notification.id == id);
      notification.hide();
      setTimeout(() => {
        notification.removeContent();
        setNotifications((prevState) => [...prevState.filter((not) => not.id != notification.id), notification]);
      }, 200);
    },
    [notifications],
  );

  const showNotification = useCallback(
    (notification) => {
      // Add notification
      let addedId = null;
      notifications.every((not, idx) => {
        if (!not.message) {
          let icon = "info_outline";
          if (notification.type === "good") {
            icon = "check";
          } else if (notification.type === "warning") {
            icon = "warning";
          } else if (notification.type === "bad") {
            icon = "error_outline";
          }
          not.show(notification.message, notification.type, icon);
          addedId = not.id;
          return false;
        }
        if (idx + 1 >= MAX_NOTIFICATIONS) return false;
        return true;
      });

      // Set timeout to hide
      if (addedId && notification.timeout) {
        setTimeout(() => deleteNotification(addedId), NOTIFICATION_TIMEOUT_MS);
      }
    },
    [notifications, deleteNotification],
  );

  const processNotifications = useCallback(
    (notifications) => {
      notifications.forEach((notification) => {
        showNotification(notification);
        notificationStore.markNotificationRead(notification.id);
      });
    },
    [notificationStore, showNotification],
  );

  useEffect(() => {
    const visibleNotifications = () => notifications.filter((notification) => notification.visible);
    const unreadDisposer = autorun(() => {
      processNotifications(notificationStore.unreadNotifications);
    });
    const clearDisposer = reaction(
      () => notificationStore.notifications,
      (storeNotifications) => {
        visibleNotifications().forEach((notification) => {
          // Remove notification if it doesnt exist in the store
          if (storeNotifications.findIndex((x) => x.id === notification.id) === -1) {
            deleteNotification(notification.id);
          }
        });
      },
    );
    return () => {
      unreadDisposer();
      clearDisposer();
    };
  }, [
    notifications,
    notificationStore.unreadNotifications,
    notificationStore.notifications,
    deleteNotification,
    processNotifications,
  ]);
  const hasNotifications = notifications.some((n) => n.visible);

  return (
    <div
      className={classNames(
        "absolute sm:px-4 flex overflow-hidden justify-start items-center sm:items-end content-end xs:right-0 sm:right-8 top-[6.4rem] sm:top-36 sm:mr-[-1rem] flex-col w-full sm:max-w-[33rem]",
        hasNotifications && "z-[999]",
      )}
    >
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className={classNames(
            "overflow-hidden ease-in-out duration-200 opacity-0 flex items-center items-content justify-start p-0 w-0 h-0 sm:rounded-md grow-0 shrink-0 shadow",
            notification.visible && "mt-4 opacity-100 pr-4 w-[33rem] h-auto sm:w-full",
            isAlarmNotification(notification.type) && "bg-white text-black",
            notification.type === "info" && "bg-[color:var(--colour-info)] text-white",
            notification.type === "good" && "bg-[color:var(--colour-good)] text-white",
            notification.type === "warning" && "bg-[color:var(--colour-warning)] text-white",
            notification.type === "bad" && "bg-[color:var(--colour-bad)] text-white",
          )}
        >
          {notification.icon && (
            <i
              className={classNames(
                "material-icons",
                "flex justify-center items-center content-center text-white/75 px-4 self-stretch text-4xl",
                !isAlarmNotification(notification.type) && "bg-black/10",
                notification.type === "alarm_critical" && "bg-[color:var(--colour-bad)]",
                notification.type === "alarm_warning" && "bg-[color:var(--colour-warning)]",
              )}
            >
              {notification.icon}
            </i>
          )}
          <span
            className={classNames(
              "p-4 text-ellipsis overflow-hidden font-medium flex-auto",
              isAlarmNotification(notification.type) && "text-black",
            )}
          >
            {notification.message}
          </span>
          <i
            role="button"
            onClick={() => deleteNotification(notification.id)}
            onKeyDown={() => deleteNotification(notification.id)}
            tabIndex={0}
            className="material-icons cursor-pointer"
            title="Dismiss"
          >
            close
          </i>
        </div>
      ))}
    </div>
  );
};

export default observer(NotificationPane);

class Notification {
  id = Math.random().toString(32).substring(2);
  message = null;
  type = "";
  icon = "";

  // Visibility controls updates
  @observable visible = false;
  @observable hidden = false;

  @action hide() {
    this.hidden = true;
  }

  @action removeContent() {
    this.hidden = false;
    this.visible = false;
    this.message = null;
    this.type = "";
    this.icon = "";
  }

  @action show(message, type, icon) {
    this.visible = true;
    this.hidden = false;
    this.message = message;
    this.type = type;
    this.icon = icon;
  }
}
