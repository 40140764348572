function isNull(value) {
  if (value == null || value === "") {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  return false;
}

function getValue(values, field) {
  const openIndex = field.indexOf("[");
  if (openIndex === -1) {
    return values[field];
  }
  const index = parseInt(field.substr(openIndex + 1, field.indexOf("]")));
  const value = values[field.substr(0, openIndex)];
  return Array.isArray(value) ? value[index] : null;
}

// Ensures the value is not empty
const Required = (value) => {
  return isNull(value) ? "Required" : undefined;
};

// Ensures an array or string is a minimum length
const RequiredLength = (length) => (value) => {
  if (typeof value === "string") {
    return value.length >= length ? null : `At least ${length} character(s) required`;
  } else if (typeof value === "object") {
    return value != null && value.length >= length ? null : `At least ${length} item(s) required`;
  }
};

// Ensures the value is a valid phone number
const Phone = (value) => {
  let valid = true;
  if (value) {
    const regex = /^(\+?((\([0-9]+\))*[0-9\- ]*)*[0-9]+)$/;
    if (!value.trim || !regex.test(value.trim())) {
      valid = false;
    }
  }
  return valid ? undefined : "Please enter a valid phone number";
};

// Ensures the value is numerical
const Number = (value) => {
  let valid = true;
  if (!isNull(value)) {
    if (isNaN(value) || isNaN(parseFloat(value))) {
      valid = false;
    }
  }
  return valid ? undefined : "Please enter a number";
};

// Ensures the value matches that of another field
const Match = (otherField, error = "Fields must match") => {
  return (value, values) => {
    return value === getValue(values, otherField) || (isNull(value) && isNull(getValue(values, otherField)))
      ? undefined
      : error;
  };
};

// Ensures the value conforms to multiple other validations
const All = (...validations) => {
  return (value, values) => {
    let error;
    for (let i = 0; i < validations.length; i++) {
      if (validations[i] != null) {
        error = validations[i](value, values);
      }
      if (error) {
        return error;
      }
    }
    return undefined;
  };
};

export default {
  Required,
  RequiredLength,
  RequiredNumber: All(Required, Number),
  Phone,
  Number,
  Match,
  All,
};
