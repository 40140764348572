import React from "react";
import autobind from "class-autobind-decorator";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

import {asField} from "../lib/mobx-form";
import Label from "./Label";

@autobind
class Toggle extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    label: PropTypes.string,
    onToggle: PropTypes.func,
    subtext: PropTypes.string,
    stopFieldPropagate: PropTypes.bool,
  };

  onClick() {
    const {fieldState, fieldApi} = this.props;
    if (fieldState.disabled) {
      return;
    }

    const nextValue = !fieldState.value;
    if (!this.props.stopFieldPropagate) {
      fieldApi.setValue(nextValue);
    }

    if (this.props.onToggle) {
      this.props.onToggle(nextValue);
    }
  }

  render() {
    const {fieldState} = this.props;
    const {text, label, subtext} = this.props;
    const {value} = fieldState;

    return (
      <Wrapper>
        {label && <Label>{label}</Label>}
        <Container onClick={this.onClick}>
          {text && <Text>{text}</Text>}
          {text && <Line />}
          <SwitchWrapper>
            <Switch>
              <Input
                checked={value}
                type="checkbox"
                disabled
              />
              <Slider checked={value}>&nbsp;</Slider>
            </Switch>
          </SwitchWrapper>
        </Container>
        {subtext && (
          <Container onClick={this.onClick}>
            <Subtext>{subtext}</Subtext>
          </Container>
        )}
      </Wrapper>
    );
  }
}

export default asField(Toggle, false);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const Text = styled.div`
  flex: 0 1 auto;
  margin-right: 0.5rem;
  padding: ${(props) => props.theme.forms.padding};
  padding-left: 0;
  padding-right: 0;
  border: ${(props) => props.theme.forms.borderWidth} solid transparent;
`;

const Subtext = styled.div`
  flex: 0 1 auto;
  color: ${(props) => props.theme.colours.contentLight};
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSizes.tiny};
  margin-top: -5px;
  padding-left: 0.9px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer !important;

    ${Text} {
      color: ${(props) => props.theme.colours.primaryHighlight};
    }
  }
`;

const Line = styled.div`
  flex: 1 1 auto;
  display: block;
  border-bottom: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  margin: 0 1rem 0 0.5rem;
`;

const SwitchWrapper = styled.div`
  flex: 0 0 auto;
  text-align: right;
  margin-bottom: -0.2rem;
`;

const Switch = styled.div`
  width: 4.1rem;
  height: 2.1rem;
  display: inline-block;
  position: relative;
`;

const Input = styled.input`
  display: none;

  &:checked + .slider {
    background-color: ${(props) => props.theme.colours.primary};
  }
`;

const Slider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #aaa;
  transition: all ease 0.4s;
  border-radius: 2rem;

  &:before {
    position: absolute;
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    left: 0.3rem;
    bottom: 0.3rem;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  ${(props) =>
    props.checked &&
    css`
      background-color: ${(props) => props.theme.colours.primary};

      &:before {
        transform: translateX(2rem);
      }
    `}
`;
