import {useEffect, useState, forwardRef} from "react";
import PropTypes from "prop-types";
import {createPortal} from "react-dom";

import LoadingAnimation from "sensoteq-react-core/components/LoadingAnimation";
import {Button} from "sensoteq-react-core/forms";
import * as Styled from "./Content.styles";

const Content = forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    loading,
    loadingOverlay,
    titleContent,
    allowFullScreen,
    padded,
    grow,
    sticky,
    style,
    ignoreChildrenHeight,
    border,
    bodyStyle,
    onFullScreenChange,
    enableFullScreen = false,
  } = props;
  let {children} = props;

  const [fullScreen, setFullScreen] = useState(enableFullScreen);
  let lastHeight = 0;

  const onToggleFullScreen = () => {
    setFullScreen(!fullScreen);
    if (onFullScreenChange) onFullScreenChange?.(!fullScreen);
  };

  useEffect(() => {
    if (!loading) {
      const el = ref?.current;
      lastHeight = el ? el.offsetHeight - 2 : 0;
    }
  }, [loading]);

  if (ignoreChildrenHeight) {
    children = (
      <Styled.ZeroHeightWrapper padded={padded}>
        <div>{children}</div>
      </Styled.ZeroHeightWrapper>
    );
  }

  let content;
  if (loading) {
    content = (
      <Styled.LoadingContainer style={{height: lastHeight ? `${lastHeight}px` : "10rem"}}>
        <LoadingAnimation />
      </Styled.LoadingContainer>
    );
  } else {
    content = (
      <Styled.Container
        ref={ref}
        grow={grow}
        sticky={sticky}
        style={style}
        className="bg-content-background"
      >
        {title && (
          <Styled.TitleContainer
            padded={padded}
            border={border}
          >
            <Styled.TitleText>
              <Styled.Title>{title}</Styled.Title>
              {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
            </Styled.TitleText>
            <Styled.TitleActions>
              {titleContent && <Styled.TitleContent>{titleContent}</Styled.TitleContent>}
              {allowFullScreen && (
                <Styled.TitleIcons>
                  <Button
                    icon={fullScreen ? "fullscreen_exit" : "fullscreen"}
                    onClick={onToggleFullScreen}
                    tooltip={fullScreen ? "Exit full-screen" : "Go full-screen"}
                  />
                </Styled.TitleIcons>
              )}
            </Styled.TitleActions>
          </Styled.TitleContainer>
        )}
        <Styled.Body
          padded={padded}
          style={bodyStyle}
        >
          {children}
        </Styled.Body>
        {loadingOverlay && <LoadingAnimation />}
      </Styled.Container>
    );
  }

  // Optionally render full screen
  if (fullScreen) {
    return createPortal(
      <Styled.FullScreenWrapper>{content}</Styled.FullScreenWrapper>,
      document.getElementById("fullscreen"),
    );
  } else {
    return content;
  }
});

Content.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  titleContent: PropTypes.any,
  loading: PropTypes.bool,
  loadingOverlay: PropTypes.bool,
  allowFullScreen: PropTypes.bool,
  padded: PropTypes.bool,
  grow: PropTypes.bool,
  sticky: PropTypes.bool,
  style: PropTypes.any,
  ignoreChildrenHeight: PropTypes.bool,
  border: PropTypes.bool,
  bodyStyle: PropTypes.any,
};
Content.defaultProps = {
  loading: false,
  loadingOverlay: false,
  allowFullScreen: false,
  padded: true,
  grow: false,
  sticky: false,
  ignoreChildrenHeight: false,
  border: false,
};

export default Content;
