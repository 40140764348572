import {observable, flow, computed} from "mobx";
import "whatwg-fetch";

import LoadableStore from "sensoteq-react-core/models/LoadableStore";

const BASE_URL = "https://sensoteq.com/wp-json/wp/v2";

export default class WPStore extends LoadableStore {
  @observable _posts;
  @observable _tags;

  loadPosts = flow(function* () {
    this.startLoading();
    this._tags = yield window.fetch(`${BASE_URL}/tags?slug=release`).then((response) => response.json());

    const tagIds = this._tags.map((t) => t.id);
    const postParams = tagIds.length ? `&tags=${tagIds.join(",")}` : "";
    this._posts = yield window
      .fetch(`${BASE_URL}/posts?orderby=date&order=desc${postParams}`)
      .then((response) => response.json());

    this.stopLoading();
  });

  @computed get posts() {
    if (!this._posts) {
      return [];
    }
    return this._posts;
  }

  @computed get latestPostDate() {
    const date = this.posts.length ? this.posts[0].date : null;
    return date ? new Date(date) : null;
  }

  @computed get latestPostLink() {
    return this.posts.length ? this.posts[0].link : null;
  }
}
