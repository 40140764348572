import React from "react";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

import {asField} from "../lib/mobx-form";
import Label from "./Label";

const TextArea = (props) => {
  const {fieldState, fieldApi, onChange, forwardedRef, label, field, placeholder, maxHeight, fillSpace} = props;
  const {value, error, disabled} = fieldState;
  const {setValue} = fieldApi;
  const sizeRef = React.useRef(null);

  return (
    <Wrapper fillSpace={fillSpace}>
      {label && <Label htmlFor={field}>{label}</Label>}
      {error && <ErrorLabel htmlFor={field}>{error}</ErrorLabel>}
      <Container fillSpace={fillSpace}>
        <StyledTextArea
          fillSpace={fillSpace}
          ref={forwardedRef}
          onChange={(e) => {
            const value = e.target.value;
            setValue(value);
            if (sizeRef.current) {
              sizeRef.current.innerHTML = value + "\n";
            }
            if (onChange) {
              onChange(value);
            }
          }}
          placeholder={placeholder}
          value={value}
          userDisabled={disabled}
          readOnly={disabled}
          error={!!fieldState.error}
          spellCheck={false}
        />
        {!fillSpace && (
          <ResizeWatcher
            ref={sizeRef}
            maxHeight={maxHeight}
          >
            {value + "\n"}
          </ResizeWatcher>
        )}
      </Container>
    </Wrapper>
  );
};
TextArea.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  maxHeight: PropTypes.number,
  fillSpace: PropTypes.bool,
};
TextArea.defaultProps = {
  placeholder: "...",
  fillSpace: false,
};

export default asField(TextArea, "");

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  ${(props) =>
    props.fillSpace &&
    css`
      flex: 1 1 auto;
    `}
`;

const ErrorLabel = styled.label`
  display: block;
  color: ${(props) => props.theme.colours.bad};
  margin-bottom: 0.5rem;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  ${(props) =>
    props.fillSpace &&
    css`
      flex: 1 1 auto;
    `}
`;

const StyledTextArea = styled.textarea`
  width: calc(100%);
  border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  border-radius: ${(props) => props.theme.forms.borderRadius};
  height: 100%;
  position: absolute;
  resize: none;
  white-space: pre-wrap;
  min-height: 8rem;
  box-sizing: border-box;
  padding: ${(props) => props.theme.forms.padding};
  background-color: ${(props) => props.theme.colours.inputBackground};
  overflow: auto;

  ${(props) =>
    props.fillSpace &&
    css`
      flex: 1 1 auto;
      position: relative;
    `}

  ${(props) =>
    props.userDisabled &&
    css`
      background-color: ${(props) => props.theme.colours.contentBackgroundAlt};
      color: ${(props) => props.theme.colours.contentLight};

      &::placeholder {
        color: ${(props) => props.theme.colours.contentLight};
      }
    `}
  
  ${(props) =>
    !props.userDisabled &&
    css`
      &:focus {
        border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.primaryHighlight};
        outline: none;
      }
    `}
  
  ${(props) =>
    props.error &&
    css`
      border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.bad} !important;
    `}
`;

const ResizeWatcher = styled.div`
  visibility: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-height: 8rem;
  box-sizing: border-box;
  padding: ${(props) => props.theme.forms.padding};
  border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  border-radius: ${(props) => props.theme.forms.borderRadius};
  overflow: hidden;
  width: 100%;

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${(props) => props.maxHeight}rem;
    `}
`;
