import React from "react";

function useDebouncer(retrieve, callback, interval = 250) {
  const [debouncer, setDebouncer] = React.useState({
    invoke: () => {},
    cancel: () => {},
  });

  React.useEffect(() => {
    let db = {
      retrieve,
      callback,
      timeout: null,
    };
    db.createTimeout = (id) => {
      db.timeout = setTimeout(async () => {
        db.jobId = Math.random();
        const jobId = db.jobId;
        const data = await retrieve(id);
        if (db.jobId !== jobId) {
          return;
        }
        callback(data);
      }, interval);
    };
    db.invoke = (id) => {
      if (db.timeout != null) {
        clearTimeout(db.timeout);
      }
      db.createTimeout(id);
    };
    db.cancel = () => {
      clearTimeout(db.timeout);
      db.jobId = Math.random();
    };
    setDebouncer(db);
  }, []);

  return debouncer;
}

export default useDebouncer;
