import {observable, computed, flow} from "mobx";

import CriticalitySubscription from "models/CriticalitySubscription";
import Api from "sensoteq-react-core/services/api";
import LoadableStore from "sensoteq-react-core/models/LoadableStore";

export default class AlarmReportStore extends LoadableStore {
  @observable _latestSiteAlarmReports;

  constructor(rootStore) {
    super(rootStore, CriticalitySubscription);
  }

  loadLatestSiteAlarmReports = flow(function* () {
    this.startLoading();
    try {
      const reports = yield Api.getLatestSiteAlarmReports();
      this._latestSiteAlarmReports = reports.scores;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting site alarm reports: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get latestSiteAlarmReports() {
    return this._latestSiteAlarmReports ?? {};
  }

  getLatestSiteAlarmReport(site) {
    return (
      this.latestSiteAlarmReports[site]?.score ?? {
        critical: 0,
        online_status: 0,
        warning: 0,
      }
    );
  }
}
