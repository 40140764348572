import AppRoutes from "routes/app-routes";
import SettingsPageRoutes from "routes/settings/settings-routes";
import {USER_ROLES, IS_PRODUCTION} from "constants/enums";

export const loginHook = (rootStore, routeContext, hookParams) => {
  const {loggedIn, user, isChiOnlyUser} = rootStore.userStore;
  const {requireLogin, requireLogout} = hookParams;
  if (!loggedIn && requireLogin) {
    let pathname = routeContext.location.pathname;
    if (pathname === "/" || pathname === AppRoutes.NotFoundPage.path) {
      pathname = undefined;
    } else {
      pathname += routeContext.location.search;
    }
    rootStore.uiStore.setLoginRedirect(pathname);
    return rootStore.routerStore.createUrl(AppRoutes.LoginPage);
  } else if (loggedIn && requireLogout) {
    if (rootStore.uiStore.loginRedirect) {
      return rootStore.uiStore.loginRedirect;
    }

    if (isChiOnlyUser) {
      return rootStore.routerStore.createUrl(AppRoutes.ChiPage);
    }

    // If user has access to exactly one site, take them directly to SitePage
    const {sites = []} = user ?? {};
    if (sites.length === 1) {
      return rootStore.routerStore.createUrl(AppRoutes.SitePage, {site: sites[0]});
    }

    return rootStore.routerStore.createUrl(AppRoutes.MapPage);
  }

  return null;
};

export const adminHook = (rootStore, routeContext, hookParams) => {
  const {admin} = rootStore.userStore;
  const {requireAdmin} = hookParams;
  if (!admin && requireAdmin) {
    return rootStore.routerStore.createUrl(AppRoutes.NotFoundPage);
  }
  return null;
};

export const roleHook = (rootStore, routeContext, hookParams) => {
  const {userRole} = rootStore.userStore;
  const {requiredRole} = hookParams;
  const hasPermission = USER_ROLES.indexOf(userRole) >= USER_ROLES.indexOf(requiredRole);

  if (!hasPermission && requiredRole) {
    return rootStore.routerStore.createUrl(AppRoutes.NotFoundPage);
  }
  return null;
};

export const accessControlHook = (rootStore, routeContext, hookParams) => {
  const {userRole, privileges} = rootStore.userStore;
  const {accessControl} = hookParams;
  if (accessControl) {
    const {role, privilege} = accessControl;
    const hasPermission = USER_ROLES.indexOf(userRole) >= USER_ROLES.indexOf(role) || privileges.includes(privilege);

    if (!hasPermission && (accessControl.role || accessControl.privilege)) {
      return rootStore.routerStore.createUrl(AppRoutes.NotFoundPage);
    }
  }
  return null;
};

export const apiAccessHook = (rootStore, routeContext, hookParams) => {
  const {allowAPIKeys} = rootStore.userStore;
  const {requireApiAccess} = hookParams;
  if (!allowAPIKeys && requireApiAccess) {
    return rootStore.routerStore.createUrl(AppRoutes.NotFoundPage);
  }
  return null;
};

export const chiOnlyUserHook = (rootStore, routeContext, hookParams) => {
  const {isChiOnlyUser} = rootStore.userStore;
  const {requireIsNotChiOnlyUser} = hookParams;
  if (isChiOnlyUser && requireIsNotChiOnlyUser) {
    return rootStore.routerStore.createUrl(AppRoutes.NotFoundPage);
  }
  return null;
};

export const canLabelFautsHook = (rootStore, routeContext, hookParams) => {
  const {canLabelFaults} = rootStore.userStore;
  const {requireCanLabelFaults} = hookParams;
  if (!canLabelFaults && requireCanLabelFaults) {
    return rootStore.routerStore.createUrl(AppRoutes.NotFoundPage);
  }
};

export const buildEnvironmentHook = (rootStore, routeContext, hookParams) => {
  const {requireDevEnvironment, requireProdEnvironment} = hookParams;
  if ((requireDevEnvironment && IS_PRODUCTION) || (requireProdEnvironment && !IS_PRODUCTION)) {
    return rootStore.routerStore.createUrl(AppRoutes.NotFoundPage);
  }
  return null;
};

export const companyHook = (rootStore, routeContext, hookParams) => {
  const {company, admin} = rootStore.userStore;
  const {requireCompany} = hookParams;
  if (requireCompany && !admin && company.company_id !== requireCompany) {
    return rootStore.routerStore.createUrl(AppRoutes.NotFoundPage);
  }
  return null;
};

export const mfaHook = (rootStore, routeContext, hookParams) => {
  const {admin, isMfaEnabled} = rootStore.userStore;
  const {isMfaForced} = rootStore.configStore;
  const {requireMFA} = hookParams;
  if (requireMFA && !isMfaForced) {
    return null;
  }

  if (requireMFA && admin && !isMfaEnabled) {
    return rootStore.routerStore.createUrl(SettingsPageRoutes.SettingsAccountTab);
  }
  return null;
};
