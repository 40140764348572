import {DomainOptions, MeasurementDomain} from "./convert.js";
import {
  Angle,
  Frequency,
  Temperature,
  Acceleration,
  Displacement,
  Velocity,
  AccelerationOption,
  VelocityOption,
  DisplacementOption,
  TemperatureOption,
  AngleOption,
  FrequencyOption,
  MassOption,
  Mass,
} from "./conversions/index.js";
import {ObjectValues} from "./types.js";

const DEFAULT_VELOCITY: Velocity = "mm/s";
const DEFAULT_ACCELERATION: Acceleration = "mg";
const DEFAULT_DISPLACEMENT: Displacement = "microns";
const DEFAULT_ANGLE: Angle = "deg";
const DEFAULT_FREQUENCY: Frequency = "Hz";
const DEFAULT_TEMPERATURE: Temperature = "°C";
const DEFAULT_MASS: Mass = "g";

export const UnitSystemDefault = {
  [MeasurementDomain.VELOCITY]: DEFAULT_VELOCITY,
  [MeasurementDomain.ACCELERATION]: DEFAULT_ACCELERATION,
  [MeasurementDomain.DISPLACEMENT]: DEFAULT_DISPLACEMENT,
  [MeasurementDomain.FREQUENCY]: DEFAULT_FREQUENCY,
  [MeasurementDomain.ANGLE]: DEFAULT_ANGLE,
  [MeasurementDomain.TEMPERATURE]: DEFAULT_TEMPERATURE,
  [MeasurementDomain.MASS]: DEFAULT_MASS,
} as const;

export const UnitPreferenceKeyOption = {
  VELOCITY: "velocity",
  ACCELERATION: "acceleration",
  DISPLACEMENT: "displacement",
  STROKE_DISPLACEMENT: "strokeDisplacement",
  FREQUENCY: "frequency",
  TEMPERATURE: "temperature",
  ANGLE: "angle",
  MASS: "mass",
} as const;

export interface UnitPreferenceKeyMapping extends DomainOptions {
  [UnitPreferenceKeyOption.STROKE_DISPLACEMENT]: DomainOptions["displacement"];
}
export type UnitPreferenceKey = ObjectValues<typeof UnitPreferenceKeyOption>;

export const UnitPreferenceKeyOptionDefault = {
  [UnitPreferenceKeyOption.VELOCITY]: {unit: VelocityOption.MILLIMETRES_PER_SECOND, domain: MeasurementDomain.VELOCITY},
  [UnitPreferenceKeyOption.ACCELERATION]: {unit: AccelerationOption.MILLI_G, domain: MeasurementDomain.ACCELERATION},
  [UnitPreferenceKeyOption.DISPLACEMENT]: {unit: DisplacementOption.MICRONS, domain: MeasurementDomain.DISPLACEMENT},
  [UnitPreferenceKeyOption.STROKE_DISPLACEMENT]: {
    unit: DisplacementOption.MILLIMETRE,
    domain: MeasurementDomain.DISPLACEMENT,
  },
  [UnitPreferenceKeyOption.TEMPERATURE]: {unit: TemperatureOption.CELCIUS, domain: MeasurementDomain.TEMPERATURE},
  [UnitPreferenceKeyOption.ANGLE]: {unit: AngleOption.DEGREES, domain: MeasurementDomain.ANGLE},
  [UnitPreferenceKeyOption.FREQUENCY]: {unit: FrequencyOption.HERTZ, domain: MeasurementDomain.FREQUENCY},
  [UnitPreferenceKeyOption.MASS]: {unit: MassOption.GRAM, domain: MeasurementDomain.MASS},
} as const;
