import {observable, computed, flow} from "mobx";

import Api from "sensoteq-react-core/services/api";
import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import DeviceLookupSubscription from "models/DeviceLookupSubscription";
import {GatewayVersions} from "constants/versions";
import enums from "sensoteq-core/enumerations";

export default class GatewayStore extends SubscribableStore {
  @observable.shallow _devices = [];

  constructor(rootStore) {
    super(rootStore, DeviceLookupSubscription);
  }

  @computed get devices() {
    // Enrich with sensors
    return this._devices
      .slice()
      .sort(({name: nameA}, {name: nameB}) => {
        if (nameA === nameB) {
          return 0;
        }
        return nameA > nameB ? 1 : -1;
      })
      .map((gateway) => {
        // Enrich with software info
        let softwareSha, particleSha, fixedFrequency;
        if (gateway.build_info && gateway.build_info !== "N/A") {
          const split = gateway.build_info.split("-");
          softwareSha = split[0];
          particleSha = split[1];
          fixedFrequency = GatewayVersions[softwareSha.toUpperCase()] * 1000000;
        }
        return {
          ...gateway,
          softwareSha,
          particleSha,
          frequency: gateway.frequency ?? fixedFrequency,
          supportsFrequencyChange: gateway.frequency != null,
        };
      });
  }

  unallocatedDevicesByCompany(company) {
    let devices = this.devices.slice();

    if (this.rootStore.userStore.admin) {
      devices = devices.filter((device) => !device.owner_id && !device.owner_type);
    } else if (this.rootStore.userStore.isPartnerUser && this.rootStore.userStore.user.partner_group) {
      const {primary_company} = this.rootStore.userStore.user.partner_group;
      devices =
        company === primary_company
          ? []
          : devices.filter(
              (device) => device.owner_id === primary_company && device.owner_type === enums.OwnerTypeOptions.COMPANY,
            );
    } else {
      devices = devices.filter(
        (device) =>
          device.owner_id === this.rootStore.userStore.user.company &&
          device.owner_type === enums.OwnerTypeOptions.COMPANY,
      );
    }

    return devices;
  }

  unallocatedDevicesBySite() {
    let devices = this.devices.slice();

    if (this.rootStore.userStore.admin) {
      devices = devices.filter((device) => !device.owner_id && !device.owner_type);
    } else if (this.rootStore.userStore.isPartnerUser && this.rootStore.userStore.user.partner_group) {
      const {primary_company, companies} = this.rootStore.userStore.user.partner_group;
      const companyList = [...companies, primary_company];

      devices = devices.filter(
        (device) => companyList.includes(device.owner_id) && device.owner_type === enums.OwnerTypeOptions.COMPANY,
      );
    } else {
      devices = devices.filter(
        (device) =>
          device.owner_id === this.rootStore.userStore.company.company_id &&
          device.owner_type === enums.OwnerTypeOptions.COMPANY,
      );
    }
    return devices;
  }

  allocatedDevicesBySite(siteName) {
    let devices = this.devices.slice();

    devices = devices.filter(
      (device) => device.owner_id === siteName && device.owner_type === enums.OwnerTypeOptions.SITE,
    );

    return devices;
  }

  @computed get _reverseLookupMap() {
    // Allow raw access gateway
    let lookupMap = {};
    this.devices.forEach((device) => {
      lookupMap[device.name] = device;
    });
    return lookupMap;
  }

  deviceExists(name) {
    return this.getDevice(name) != null;
  }

  getDevice(name) {
    return this._reverseLookupMap[name];
  }

  getSensorsForDevice(device) {
    if (device == null) {
      return [];
    }
    const devicesInfo = this.devices.find((x) => x.name === device);
    if (devicesInfo) {
      return devicesInfo.sensors;
    }
    return [];
  }

  allocatedDevicesForCompany(company) {
    let devices = this.devices.slice();
    let allocatedDevices = [];

    devices.forEach((device) => {
      if (company == device?.owner_id) allocatedDevices.push(device);
    });

    return allocatedDevices;
  }

  loadDevices = flow(function* (showLoading = true) {
    if (showLoading) {
      this.startLoading();
    }
    try {
      const devices = yield Api.getDevices();
      this._devices = devices.devices;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting devices: ${error}`, "bad");
    }
    if (showLoading) {
      this.stopLoading();
    }
  });

  refreshDevice = flow(function* (deviceName) {
    this.startLoading();
    try {
      const device = yield Api.getDevice(deviceName);
      const idx = this._devices.findIndex((x) => x.name === deviceName);
      if (idx !== -1) {
        this._devices[idx] = device.device;
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting device: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateDeviceFrequency = flow(function* (deviceName, frequency) {
    this.startLoading();
    try {
      yield Api.updateDeviceFrequency(deviceName, frequency);
      this.rootStore.notificationStore.addNotification("Frequency updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating frequency: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateRFProtocol = flow(function* (gatewayID, protocol) {
    this.startLoading();
    try {
      yield Api.updateGatewayRFProtocol(gatewayID, protocol);
      this.rootStore.notificationStore.addNotification("RF protocol updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(
        `Error updating RF protocol: ${error.message ?? "Server was unable to process request"}`,
        "bad",
      );
    }
    this.stopLoading();
  });

  requestRFProtocol = flow(function* (gatewayID) {
    this.startLoading();
    try {
      const {rf_protocol} = yield Api.requestGatewayRFProtocol(gatewayID);
      this.stopLoading();
      return rf_protocol;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(
        `Error requesting RF protocol: ${error.message ?? "Server was unable to process request"}`,
        "bad",
      );
    }
    this.stopLoading();
  });

  updateGateway = flow(function* (values) {
    this.startLoading();
    try {
      yield Api.updateGateway(values);
      this.rootStore.notificationStore.addNotification("Gateway updated successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating gateway: ${error}`, "bad");
    }
    this.stopLoading();
  });

  restartDevice = flow(function* (deviceName) {
    this.startLoading();
    try {
      yield Api.restartDevice(deviceName);
      this.rootStore.notificationStore.addNotification("Device restarted successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error restarting device: ${error}`, "bad");
    }
    this.stopLoading();
  });

  animateDeviceLED = flow(function* (gatewayName) {
    this.startLoading();
    try {
      yield Api.animateDeviceLED(gatewayName);
      this.rootStore.notificationStore.addNotification("Request sent successfully", "good");
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error animating device LED: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
