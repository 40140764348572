import React from "react";
import PropTypes from "prop-types";
import styled, {css, keyframes} from "styled-components";

const LoadingAnimation = ({showText, text, showBackground, absolute}) => {
  return (
    <Container absolute={absolute}>
      <Background1 showBackground={showBackground} />
      <Background2 />
      <Content>
        {showText && <Text>{text}</Text>}
        <BounceContainer>
          <BounceDot dot={1} />
          <BounceDot dot={2} />
          <BounceDot dot={3} />
        </BounceContainer>
      </Content>
    </Container>
  );
};

LoadingAnimation.propTypes = {
  showText: PropTypes.bool,
  text: PropTypes.string,
  showBackground: PropTypes.bool,
  absolute: PropTypes.bool,
};

LoadingAnimation.defaultProps = {
  showText: false,
  text: "Loading",
  showBackground: true,
  absolute: true,
};

const Container = styled.article`
  text-align: center;
  flex: 1 1 auto;
  align-self: stretch;
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  padding: 0 !important;
  margin: 0 !important;

  ${(props) =>
    props.absolute &&
    css`
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    `}
`;

const Background1 = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -2;

  ${(props) =>
    props.showBackground &&
    css`
      background-color: ${(props) => props.theme.colours.contentBackground};
    `}
`;

const Background2 = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -1;
`;

const Content = styled.div`
  flex: 0 1 auto;
`;

const Text = styled.div`
  flex: 0 1 auto;
  color: ${(props) => props.theme.colours.loadingAnimation};
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
  display: block;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
`;

const BounceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

const BounceDot = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${(props) => props.theme.colours.loadingAnimation};
  border-radius: 100%;
  display: inline-block;
  animation: ${bounce} 1.2s infinite ease-in-out both;
  margin-right: 0.2rem;

  ${(props) =>
    props.dot === 1 &&
    css`
      animation-delay: -0.32s;
    `}

  ${(props) =>
    props.dot === 2 &&
    css`
      animation-delay: -0.16s;
    `}
  
  ${(props) =>
    props.dot === 3 &&
    css`
      margin-right: 0;
    `}
`;

export default LoadingAnimation;
