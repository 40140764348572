import styled from "styled-components";

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  min-width: ${(props) => props.theme.dimensions.contentMobileBreakPoint}rem;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;

  &#V2Page {
    height: calc(100vh - ${(props) => props.theme.fontSizes.medium});
  }
`;
