import React from "react";
import {action} from "mobx";
import PropTypes from "prop-types";
import {useLocalStore, observer, useAsObservableSource} from "mobx-react";
import hoistNonReactStatics from "hoist-non-react-statics";

import {useFormStore} from "./context";

function asField(Component, defaultValue = null, forcedValidator) {
  function FieldComponent(props) {
    const formStore = useFormStore();
    const {field} = props;
    const {initialValue, disabled, ...otherProps} = props;
    const observableProps = useAsObservableSource({disabled});
    const registered = React.useState(false);

    // Create validator function
    const validate = React.useMemo(() => {
      return (...params) => {
        let error;
        if (forcedValidator) {
          error = forcedValidator(...params);
        }
        if (!error && props.validate) {
          error = props.validate(...params);
        }
        return error;
      };
    }, [forcedValidator, props.validate]);

    // Register and unregister
    React.useEffect(() => {
      action(() => {
        formStore.registerField(field, validate, defaultValue);
        if (initialValue != null) {
          formStore.setValue(field, initialValue);
        }
        registered.current = true;
      })();
      return () => {
        formStore.unregisterField(field);
      };
    }, [field]);

    // Keep validator up to date
    React.useEffect(() => {
      formStore.setValidator(field, validate);
    }, [field, validate]);

    // Create the field state
    const state = useLocalStore(() => ({
      get fieldState() {
        let value = formStore.getValue(field) ?? defaultValue;

        // Upon mounting, set value ot initial value if one has been specified and the field has yet to be registered
        if (!registered.current && initialValue != null) {
          value = initialValue;
        }

        return {
          value,
          error: formStore.getError(field) ?? null,
          disabled: formStore.disabled || observableProps.disabled,
        };
      },
    }));

    // Create the field API
    const fieldApi = React.useMemo(() => {
      return {
        setValue: (value) => {
          const error = formStore.validateField(field, value);
          formStore.setError(field, error ?? null);
          formStore.setValue(field, (value === "" ? null : value) ?? null);
        },
        setError: (error) => {
          formStore.setError(field, error ?? null);
        },
      };
    }, [field]);

    // Sanity check
    if (!Component) {
      return null;
    }

    return (
      <Component
        {...otherProps}
        fieldState={state.fieldState}
        fieldApi={fieldApi}
      />
    );
  }

  FieldComponent.propTypes = {
    field: PropTypes.string.isRequired,
    validate: PropTypes.func,
    initialValue: PropTypes.any,
    disabled: PropTypes.bool,
  };

  hoistNonReactStatics(FieldComponent, Component);
  return observer(FieldComponent);
}

export default asField;
