import {computed} from "mobx";
import dayjs from "dayjs";
import {MeasuringPointPositions} from "sensoteq-core/enumerations";
import {velocityToAcceleration} from "services/MathService";
import MachineDataSubscription from "./MachineDataSubscription";

export default class ScreenDataSubscription extends MachineDataSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      positions: [
        MeasuringPointPositions.LEFT_DISCHARGE,
        MeasuringPointPositions.RIGHT_DISCHARGE,
        MeasuringPointPositions.LEFT_FEED,
        MeasuringPointPositions.RIGHT_FEED,
        MeasuringPointPositions.LEFT_MIDDLE,
        MeasuringPointPositions.RIGHT_MIDDLE,
        MeasuringPointPositions.LEFT_DISCHARGE_MIDDLE,
        MeasuringPointPositions.RIGHT_DISCHARGE_MIDDLE,
        MeasuringPointPositions.LEFT_FEED_MIDDLE,
        MeasuringPointPositions.RIGHT_FEED_MIDDLE,
      ],
    };
  }

  // Average determines whether all valid ellipse sets are used or just the current ellipse set
  calculateScreenValue(selectValue, positions = this.getDefaultParams().positions, average = true) {
    let total = 0;
    let count = 0;
    positions.forEach((position) => {
      const sub = this.getSubscriptionByPosition(position);
      if (sub) {
        let inputSets = [];
        if (average) {
          // Ignore first and last set in case there's weird values from startup or shutdown
          inputSets = sub.validEllipseSets;
          if (inputSets.length >= 5) {
            inputSets = inputSets.slice(1, inputSets.length - 1);
          }
        } else if (sub.ellipseData) {
          inputSets = [sub.ellipseData];
        }
        for (let i = 0; i < inputSets.length; i++) {
          total += selectValue(inputSets[i]);
          count++;
        }
      }
    });
    if (count === 0) {
      return null;
    }
    return total / count;
  }

  calculateScreenAverageTimeLine(selectValue, positions = this.getDefaultParams().positions) {
    let positionValueSet = {};
    positions.forEach((position) => {
      if (!["LEFT_FEED", "RIGHT_FEED", "LEFT_DISCHARGE", "RIGHT_DISCHARGE"].includes(position)) return;
      const sub = this.getSubscriptionByPosition(position);
      if (sub) {
        const inputSets =
          sub.validEllipseSets.length >= 5
            ? sub.validEllipseSets.slice(1, sub.validEllipseSets.length - 1)
            : sub.validEllipseSets;
        inputSets
          .filter((s) => {
            const lastMonth = dayjs().subtract(1, "month");
            return dayjs(s.timestamp).isAfter(lastMonth);
          })
          .forEach((s) => {
            const ts = dayjs(s.timestamp).format("YYYY-MM-DD");
            if (positionValueSet[ts]) positionValueSet[ts].push(selectValue(s));
            else positionValueSet[ts] = [selectValue(s)];
          });
      }
    });
    return Object.entries(positionValueSet).map(([k, v]) => ({
      timestamp: dayjs(k).unix(),
      value: Math.round(v.reduce((a, b) => a + b, 0) / v.length) * 60,
    }));
  }

  @computed get averageG() {
    return this.calculateScreenValue((x) => x.accelerationData.strokeLength);
  }

  @computed get averageStroke() {
    return this.calculateScreenValue((x) => x.displacementData.strokeLength);
  }

  @computed get averageFrequency() {
    return this.calculateScreenValue((x) => x.displacementData.frequency);
  }

  @computed get averageSideBump() {
    return this.calculateScreenValue((x) =>
      velocityToAcceleration(x.accelerationData.deflection, x.accelerationData.frequency),
    );
  }

  @computed get averageFrequencyTimeline() {
    return this.calculateScreenAverageTimeLine((x) => x.displacementData.frequency);
  }

  calculatePercentageDifference(value1, value2) {
    if (value1 == null || value2 == null) {
      return null;
    }
    const max = Math.max(value1, value2);
    const min = Math.min(value1, value2);
    if (min === 0) {
      return max === 0 ? 0 : 100;
    }
    return (max / min - 1) * 100;
  }

  getOppositePosition(position) {
    return {
      [MeasuringPointPositions.RIGHT_FEED]: MeasuringPointPositions.LEFT_FEED,
      [MeasuringPointPositions.LEFT_FEED]: MeasuringPointPositions.RIGHT_FEED,
      [MeasuringPointPositions.RIGHT_DISCHARGE]: MeasuringPointPositions.LEFT_DISCHARGE,
      [MeasuringPointPositions.LEFT_DISCHARGE]: MeasuringPointPositions.RIGHT_DISCHARGE,
      [MeasuringPointPositions.LEFT_MIDDLE]: MeasuringPointPositions.RIGHT_MIDDLE,
      [MeasuringPointPositions.RIGHT_MIDDLE]: MeasuringPointPositions.LEFT_MIDDLE,
      [MeasuringPointPositions.LEFT_MIDDLE]: MeasuringPointPositions.RIGHT_FEED_MIDDLE,
      [MeasuringPointPositions.RIGHT_MIDDLE]: MeasuringPointPositions.LEFT_FEED_MIDDLE,
      [MeasuringPointPositions.LEFT_MIDDLE]: MeasuringPointPositions.RIGHT_DISCHARGE_MIDDLE,
      [MeasuringPointPositions.RIGHT_MIDDLE]: MeasuringPointPositions.LEFT_DISCHARGE_MIDDLE,
    }[position];
  }

  calculateDelta(position, selectProperty = (x) => x.accelerationData.strokeLength, average = false) {
    const set1 = this.calculateScreenValue(selectProperty, [position], average);
    const set2 = this.calculateScreenValue(selectProperty, [this.getOppositePosition(position)], average);
    return this.calculatePercentageDifference(set1, set2);
  }
}
