export function copyToClipBoard(text) {
  const el = document.createElement("textarea");
  el.value = text;
  el.setAttribute("readonly", "");
  el.style.opacity = "0";
  el.style.position = "absolute";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

export function sortArrayByProperty(list, property, reverse = false) {
  if (!list || !list.length) return list;
  return list.sort((a, b) => {
    let aProp = a[property];
    let bProp = b[property];
    if (aProp > bProp) return reverse ? -1 : 1;
    return aProp < bProp ? (reverse ? 1 : -1) : 0;
  });
}

export function measureScrollBarWidth() {
  const scrollDiv = document.createElement("div");
  scrollDiv.className = "scrollbar-measure";
  scrollDiv.style.width = "10rem";
  scrollDiv.style.height = "10rem";
  scrollDiv.style.overflow = "scroll";
  scrollDiv.style.position = "absolute";
  scrollDiv.style.opacity = "0";
  scrollDiv.style.top = "-10rem";
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
}
