import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const MultiDayjsPicker = ({
  format,
  disabled,
  selectedDates,
  onChangeDates,
  controlled = false,
  alignTop = false,
  border = true,
  placeholder = "Choose a date",
  allowNull = true,
}) => {
  const dayNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const [monthOffset, setMonthOffset] = React.useState(0);
  const [alignLeft, setAlignLeft] = React.useState(true);
  const [selectedDate, setSelectedDate] = React.useState();
  const inputRef = React.createRef();
  const pickerRef = React.createRef();
  const containerRef = React.useRef();
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target) && open) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, open, setOpen]);

  // Selected date, either controlled or uncontrolled
  const selected = React.useMemo(() => {
    const date = controlled ? selectedDates : selectedDate;
    return allowNull ? date : date || dayjs();
  }, [controlled, selectedDates, selectedDate]);

  // The date currently being shown by the calendar, which can be shifted by months
  const viewDate = React.useMemo(() => {
    return (selected[selected.length - 1] || dayjs()).add(monthOffset, "month");
  }, [selected, monthOffset]);

  // After every paint
  React.useLayoutEffect(() => {
    // Check we are on screen
    if (pickerRef.current) {
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      const leftOffset = inputRef.current.getBoundingClientRect().left;
      const shouldAlignLeft =
        inputRef.current.offsetWidth > pickerRef.current.offsetWidth || leftOffset < viewportWidth / 2;
      if (shouldAlignLeft !== alignLeft) {
        setAlignLeft(shouldAlignLeft);
      }
    }
  });

  // Memoize computation of calendar model
  const calendarModel = React.useMemo(() => {
    const selectedDate = selected;
    let model = {
      viewDate,
      selectedDate,
      calendar: [],
    };
    for (let i = 0; i < 7; i++) {
      model.calendar.push([]);
    }

    // Find previous sunday as start of calendar
    let calendarStart = viewDate.startOf("month");
    while (calendarStart.day() !== 1) {
      calendarStart = calendarStart.subtract(1, "day");
    }
    model.calendarStart = calendarStart;

    // Find last saturday as end of calendar
    let calendarEnd = viewDate.endOf("month");
    while (calendarEnd.day() !== 0) {
      calendarEnd = calendarEnd.add(1, "day");
    }
    model.calendarEnd = calendarEnd;

    // Find length of calendar
    const calendarLength = calendarEnd.diff(calendarStart, "day");

    // Now continue until we are finished with the month and reach a saturday
    const today = dayjs();
    for (let i = 0, day = 0; i <= calendarLength; i++, day = (day + 1) % 7) {
      const currentDate = calendarStart.add(i, "day");

      model.calendar[day].push({
        date: currentDate,
        today: currentDate.isSameDay(today),
        currentMonth: currentDate.isSame(viewDate, "month"),
        selected: selectedDate && selectedDate.some((date) => date.isSameDay(currentDate)),
        offset: i,
      });
    }

    return model;
  }, [selected, viewDate]);

  // Function to change the date, either controlled or uncontrolled
  const onChange = React.useCallback(
    (date) => {
      const update = controlled ? onChangeDates : setSelectedDate;
      update && update(date);
    },
    [controlled],
  );

  // Callback to change the day of the month
  const changeDay = React.useCallback(
    (date) => {
      let newDate = dayjs().set("year", date.year()).set("month", date.month()).set("date", date.date());
      newDate = newDate.startOf("day");

      onChange(newDate);
    },
    [selected, onChange],
  );

  // Callback to change the month offset currently displayed by the calendar
  const changeViewMonth = React.useCallback((monthOffset) => {
    setMonthOffset((x) => x + monthOffset);
  }, []);

  // Callback to clear date
  const onClear = React.useCallback(
    (e) => {
      e.stopPropagation();
      onChange(null);
    },
    [onChange],
  );

  // Callback to set the date to today
  const setToToday = React.useCallback(
    (e) => {
      e.stopPropagation();
      onChange(dayjs());
    },
    [onChange],
  );

  // Render datepicker if open
  const datePicker = (
    <div className={`flex grow-0 shrink-0 basis-auto flex-col justify-start items-stretch`}>
      <div className={`flex grow-0 shrink-0 basis-auto flex-row justify-between items-center`}>
        <i
          className={`material-icons p-[0.8rem] my-[-1.5rem] mx-[-0.8rem] hover:text-primary-highlight hover:cursor-pointer`}
          onClick={() => changeViewMonth(-1)}
        >
          keyboard_arrow_left
        </i>
        <div className={`flex-auto text-center font-bold text-veryLarge`}>{`${viewDate.format(
          "MMMM",
        )}, ${viewDate.format("YYYY")}`}</div>
        <i
          className={`material-icons p-[0.8rem] my-[-1.5rem] mx-[-0.8rem] hover:bg-primary-highlight hover:cursor-pointer`}
          onClick={() => changeViewMonth(1)}
        >
          keyboard_arrow_right
        </i>
      </div>
      <div className={`flex grow-1 shrink-0 basis-auto flex-row justify-between items-stretch`}>
        {calendarModel.calendar.map((dayNumbers, dayIdx) => {
          return (
            <div
              className={`flex grow-1 shrink-0 basis-auto flex-col justify-start items-center mr-[0.8rem] last:mr-0`}
              key={dayIdx}
            >
              <div className={`font-bold w-[2.8rem] h-[2.8rem] flex flex-row justify-center items-center mt-[1.5rem]`}>
                {dayNames[dayIdx]}
              </div>
              {dayNumbers.map((x) => (
                <div
                  className={`opacity-50 w-[2.8rem] h-[2.8rem] flex flex-col justify-center items-center rounded-full
                mt-[0.8rem] 
                hover:bg-primary-highlight hover:text-white hover:cursor-pointer
                ${x.currentMonth ? "text-content !opacity-100" : ""}
                ${x.today ? "text-primary font-bold" : ""}
                ${x.selected ? "bg-primary text-white font-bold" : ""}`}
                  key={x.date.valueOf()}
                  onClick={() => changeDay(x.date)}
                >
                  {x.date.date()}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div
      ref={containerRef}
      className={`relative flex flex-row justify-start items-center`}
    >
      <div
        className={`flex flex-auto flex-row py-[0.9rem] px-[1.2rem] border-solid
      border-transparent rounded-md bg-input-background whitespace-nowrap text-ellipsis leading-6
      justify-between items-center select-none !border-0 hover:cursor-pointer
      ${disabled ? "bg-content-background-alt text-content-light" : ""}
      ${border ? "border-border" : "bg-content-background shadow-box-shadow"}
      ${open ? "open" : "closed"}`}
        ref={inputRef}
        onClick={disabled ? null : () => setOpen(!open)}
      >
        {selected && selected.length === 1 ? (
          <>{selected[0].format(format || "MMMM Do YYYY")}</>
        ) : selected.length === 0 ? (
          <div className={`opacity-50 select-none !border-0`}>{placeholder}</div>
        ) : (
          <>Multiple Selection</>
        )}
      </div>
      {open && (
        <div
          className={`z-[999] absolute right-0 left-auto top-[calc(100%+1.2rem)] 
        rounded-lg p-[1.5rem] bg-content-background shadow-lg
        ${alignTop ? "top-[calc(0%-0.8rem)] transform -translate-y-full" : ""}
        ${alignLeft ? "left-0 right-auto" : ""}`}
          ref={pickerRef}
        >
          <div className={`flex flex-row justify-start items-stretch`}>{datePicker}</div>

          <div className={`flex flex-row justify-between mt-6 mr-2 mb-0 ml-2`}>
            <div
              className={`self-end text-content-light hover:text-primary-highlight hover:cursor-pointer`}
              onClick={setToToday}
            >
              Today
            </div>
            {selected && allowNull && <div onClick={onClear}>Clear</div>}
          </div>
        </div>
      )}
    </div>
  );
};

MultiDayjsPicker.propTypes = {
  selectedDates: PropTypes.array,
  onChangeDates: PropTypes.func,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  alignTop: PropTypes.bool,
  onOpen: PropTypes.func,
  border: PropTypes.bool,
  placeholder: PropTypes.string,
  controlled: PropTypes.bool,
  allowNull: PropTypes.bool,
};

export default MultiDayjsPicker;
