const {ENVELOPE_METHOD} = require("./constants-envelope");
const {Hilbert} = require("./envelope-hilbert");
const {CalcCascades, IirFilter} = require("fili");

function SignalAnalysis(signal, samplingFrequency) {
  this.data = new Float64Array(signal.slice(0));
  this.samplingFrequency = samplingFrequency;
}

SignalAnalysis.prototype.signal = function () {
  return Array.from(this.data);
};

SignalAnalysis.prototype.rectify = function () {
  const rectifiedSignal = this.data.map((value) => Math.abs(value));
  this.data = rectifiedSignal;
  return this;
};

SignalAnalysis.prototype.envelope = function (type) {
  let envelopeMethod;
  switch (type) {
    case ENVELOPE_METHOD.HILBERT: {
      envelopeMethod = new Hilbert();
    }
    default: {
      envelopeMethod = new Hilbert();
    }
  }

  this.data = envelopeMethod.process(this.data, this.samplingFrequency);
  return this;
};

SignalAnalysis.prototype.lowpass = function (params) {
  const filteredSignal = this.data.slice(0);
  const iirFilterParams = new CalcCascades().lowpass({
    order: params.order || 1,
    characteristic: params.characteristic || "butterworth",
    Fs: this.samplingFrequency,
    Fc: params.cutoff,
  });
  const iirFilter = new IirFilter(iirFilterParams);
  this.data = iirFilter.multiStep(filteredSignal);

  return this;
};

SignalAnalysis.prototype.highpass = function (params) {
  const filteredSignal = this.data.slice(0);
  const iirFilterParams = new CalcCascades().highpass({
    order: params.order || 1,
    characteristic: params.characteristic || "butterworth",
    Fs: this.samplingFrequency,
    Fc: params.cutoff,
  });
  const iirFilter = new IirFilter(iirFilterParams);
  this.data = iirFilter.multiStep(filteredSignal);

  return this;
};

SignalAnalysis.prototype.bandpass = function (params) {
  let filterParams = {order: 12, characteristic: "butterworth"};
  filterParams = {...filterParams, ...params};

  filterParams.cutoff = params.low;
  this.highpass(filterParams);

  filterParams.cutoff = params.high;
  this.lowpass(filterParams);

  return this;
};

module.exports.SignalAnalysis = SignalAnalysis;
