import {observable, computed, flow} from "mobx";

import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import CriticalitySubscription from "models/CriticalitySubscription";
import Api from "sensoteq-react-core/services/api";

export default class CriticalityStore extends SubscribableStore {
  @observable _latestSiteCriticalities;

  constructor(rootStore) {
    super(rootStore, CriticalitySubscription);
  }

  loadLatestSiteCriticalities = flow(function* () {
    this.startLoading();
    try {
      const criticalities = yield Api.getLatestSiteCriticalities();
      this._latestSiteCriticalities = criticalities.scores;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting site criticalities: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @computed get latestSiteCriticalityMap() {
    return this._latestSiteCriticalities ?? {};
  }

  getLatestSiteCriticality(site) {
    return this.latestSiteCriticalityMap[site] ?? 0;
  }
}
