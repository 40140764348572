import React from "react";
import type {PropsWithChildren} from "react";

import * as Styled from "./ContentColumn.styles";

type ContentColumnProps = {
  maxWidth?: number;
  grow?: boolean;
};

type MergedReactNodeProps = React.ReactNode & {grow?: boolean};

const ContentColumn = ({children, ...otherProps}: PropsWithChildren<ContentColumnProps>) => {
  return (
    <Styled.ContentColumn {...otherProps}>
      {React.Children.map(children, (child) => {
        if (child == null) {
          return null;
        }
        const _child: MergedReactNodeProps = child;
        if (React.isValidElement(_child)) {
          return React.cloneElement(_child, {
            grow: !!_child.props?.grow,
          });
        }
      })}
    </Styled.ContentColumn>
  );
};

export {ContentColumn};
