import styled from "styled-components";

export const GraphImage = styled.div`
  padding: 0.8rem 1.5rem;
  border: 0.2rem solid transparent;
  border-radius: ${(props) => props.theme.forms.borderRadius};

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  span {
    font-size: ${(props) => props.theme.fontSizes.small};
    text-transform: uppercase;
    color: ${(props) => props.theme.colours.content};
    font-weight: bold;
  }

  i {
    color: ${(props) => props.theme.colours.contentLight};

    &:hover {
      color: ${(props) => props.theme.colours.primaryHighlight};
    }
  }

  img {
    width: 100%;
    cursor: pointer;
  }

  &:hover {
    color: ${(props) => props.theme.colours.primaryHighlight};
    border-color: ${(props) => props.theme.colours.primaryHighlight};
    cursor: pointer;
  }
`;

export const LoadingOverlay = styled.div`
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colours.background};
`;

export const PDFLoadingContainer = styled.div`
  height: 8rem;
  position: relative;
`;

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .fr-document {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    &.fr-fullscreen {
      background-color: white;
    }

    > div {
      border-radius: 0 !important;
    }

    .fr-toolbar {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      box-shadow: ${(props) => props.theme.forms.boxShadow};
      z-index: 2 !important;
      padding-right: ${(props) => props.theme.dimensions.hMargin}rem;
      background-clip: border-box;
      background-color: ${(props) => props.theme.colours.contentBackground} !important;

      .fr-popup {
        background-color: ${(props) => props.theme.colours.contentBackground} !important;
        color: ${(props) => props.theme.colours.content} !important;

        .fr-tabs {
          background-color: ${(props) => props.theme.colours.background} !important;

          svg {
            margin: 8px 11px !important;
          }

          .fr-btn {
            background-color: transparent !important;

            &.fr-active {
              background-color: ${(props) => props.theme.colours.contentBackground} !important;
            }
          }
        }

        .fr-image-upload-layer:hover {
          background-color: ${(props) => props.theme.colours.contentBackgroundAlt};
        }

        .fr-input-line {
          label {
            color: ${(props) => props.theme.colours.content} !important;
            background-color: ${(props) => props.theme.colours.contentBackground} !important;
          }
        }

        button:hover {
          background-color: ${(props) => props.theme.colours.contentBackgroundAlt} !important;
        }

        input[type="text"] {
          padding: ${(props) => props.theme.forms.padding} !important;
          border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border} !important;
          border-radius: ${(props) => props.theme.forms.borderRadius} !important;
          background-color: ${(props) => props.theme.colours.contentBackground} !important;
          color: ${(props) => props.theme.colours.content} !important;

          &:focus {
            border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.primaryHighlight} !important;
            outline: none;
          }
        }
      }

      .fr-newline {
        display: none;
      }

      .fr-more-toolbar {
        bottom: 0;
        background-color: ${(props) => props.theme.colours.contentBackgroundAlt} !important;

        .fr-btn {
          background-color: transparent !important;
        }
      }

      .fr-dropdown-menu {
        background-color: ${(props) => props.theme.colours.contentBackground} !important;

        * {
          color: ${(props) => props.theme.colours.content} !important;
        }

        path {
          fill: ${(props) => props.theme.colours.content} !important;
        }

        a {
          &:hover {
            background-color: ${(props) => props.theme.colours.contentBackgroundAlt} !important;
          }

          &.fr-active {
            background-color: ${(props) => props.theme.colours.contentBackgroundAlt} !important;
          }
        }
      }

      .fr-btn-grp {
        margin-left: ${(props) => props.theme.dimensions.hMargin}rem !important;
        margin-right: 0 !important;
      }

      .fr-btn {
        margin-left: 1px !important;
        margin-right: 1px !important;
        border: none !important;
        background-color: ${(props) => props.theme.colours.contentBackground} !important;

        path {
          fill: ${(props) => props.theme.colours.content} !important;
        }

        * {
          color: ${(props) => props.theme.colours.content} !important;
        }

        > svg {
          margin-left: 4px !important;
          margin-right: 4px !important;
        }

        &.fr-active {
          path {
            fill: ${(props) => props.theme.colours.primary} !important;
          }

          * {
            color: ${(props) => props.theme.colours.primary} !important;
          }
        }

        &.fr-dropdown {
          svg {
            margin-left: 3px !important;
            margin-right: 11px !important;
          }

          &.fr-active {
            // Dropdown styles when open
            background-color: ${(props) => props.theme.colours.contentBackgroundAlt} !important;
            fill: ${(props) => props.theme.colours.content} !important;

            &:after {
              // Dropdown icons
              border-bottom-color: ${(props) => props.theme.colours.content} !important;
            }
          }

          &:after {
            // Dropdown icons
            border-top-color: ${(props) => props.theme.colours.content} !important;
          }
        }

        &.fr-open,
        &.fr-expanded {
          background-color: ${(props) => props.theme.colours.contentBackgroundAlt} !important;
        }

        &:hover,
        &.fr-btn-hover {
          background-color: ${(props) => props.theme.colours.contentBackgroundAlt} !important;
        }
      }

      i.material-icons {
        font-size: 24px !important;
      }
    }

    .fr-wrapper {
      flex: 1 1 auto;
      height: 0;
      overflow-y: auto;
      background: ${(props) => props.theme.colours.background} !important;
      border: none !important;
      z-index: 1 !important;
      padding-bottom: 0 !important;

      > div[style*="z-index:9999;width:100%;position:relative"] {
        opacity: 0 !important;
        pointer-events: none !important;
      }

      .fr-placeholder {
        display: none !important;
      }

      @media (max-width: 1100px) {
        padding-top: 30px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        min-width: 0 !important;
      }
    }

    .second-toolbar {
      display: none;
    }

    @media (max-width: 1100px) {
      min-width: 0 !important;
    }
  }
`;
