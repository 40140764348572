import React from "react";
import PropTypes from "prop-types";

import * as Styled from "./StatusPopup.styles";
import Popup from "sensoteq-react-core/components/Popup";
import StatusIcon from "./StatusIcon";
import * as Enums from "constants/enums";

const StatusPopup = (props) => {
  const {initiallyOpen, theme, items, children, style, border, wide} = props;
  const hasItems = items && items.length > 0;
  const [open, setOpen] = React.useState(initiallyOpen);

  // Callback to toggle opening the dropdown
  const toggle = React.useCallback(() => {
    setOpen((open) => !open);
  }, []);

  return (
    <Styled.Container
      style={style}
      border={border}
      userTheme={theme}
    >
      <Styled.ColourBlock userTheme={theme} />
      <Styled.Content border={border}>
        <Styled.Title
          onClick={hasItems ? toggle : null}
          smallIcon
        >
          <StatusIcon theme={theme} />
          <Styled.Text userTheme={theme}>{children}</Styled.Text>
          {hasItems && (
            <Styled.Icon
              userTheme={theme}
              className="material-icons"
            >
              filter_none
            </Styled.Icon>
          )}
        </Styled.Title>
        {hasItems && open && (
          <Popup
            title={children}
            hideCancelButton
            confirmText="Close"
            onConfirm={toggle}
            onCancel={toggle}
            wide={wide}
          >
            <Styled.Items userTheme={theme}>
              {items.map((item, idx) => (
                <Styled.Item
                  key={idx}
                  userTheme={theme}
                >
                  {item}
                </Styled.Item>
              ))}
            </Styled.Items>
          </Popup>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

StatusPopup.Themes = Enums.StatusThemes;
StatusPopup.propTypes = {
  items: PropTypes.array,
  theme: PropTypes.string,
  initiallyOpen: PropTypes.bool,
  border: PropTypes.bool,
  wide: PropTypes.bool,
};
StatusPopup.defaultProps = {
  theme: Enums.StatusThemes.INFO,
  border: true,
  wide: false,
};

export default StatusPopup;
