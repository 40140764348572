import React from "react";
import autobind from "class-autobind-decorator";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {HASH} from "./hash";
import {RootStore, StoreContext} from "./stores";
import {pageview as PageViewGA, set as SetGA} from "react-ga";
import {RouterStore} from "sensoteq-react-core/lib/mobx-state-router";
import {decode, encode} from "routes/encoding";
import {
  accessControlHook,
  adminHook,
  roleHook,
  companyHook,
  loginHook,
  buildEnvironmentHook,
  apiAccessHook,
  chiOnlyUserHook,
  canLabelFautsHook,
  mfaHook,
} from "routes/hooks";
import createPersistor from "sensoteq-react-core/lib/mobx-persist";
import {setAuthToken, setBlockNotifications, setOnLogOut} from "sensoteq-react-core/services/api";
import {IS_PRODUCTION} from "constants/enums";
import {setReactQueryAPIToken} from "services/ReactQuery";
import {configureFetch} from "./data/fetch";

@autobind
@observer
export default class StoreWrapper extends React.Component {
  @observable.ref rootStore;
  postResetMessage;

  constructor(props) {
    super(props);
    this.createRootStore();
    this.initialiseStore();
  }

  componentDidMount() {
    // Check for version update
    // Previously we were checking the hash saved to the localstore and comparing to the current hash
    // Then we were resetting the application and forcing a re-login
    // We have now removed this as we feel it's unnecessary and a bad user experience but may be re-introduced
    // should we make major changes to our stores

    this.rootStore.localStore.setHash(HASH);

    // Update data periodically
    const updateData = () => {
      if (this.rootStore.userStore.loggedIn) {
        this.rootStore.refresh(false);
      }
    };
    setInterval(() => updateData(), 60000);
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState !== "hidden") {
        updateData();
      }
    });
  }

  componentDidUpdate() {
    if (this.postResetMessage) {
      this.rootStore.notificationStore.addNotification(this.postResetMessage, "good");
      this.postResetMessage = null;
    }
  }

  @action createRootStore() {
    let rootStore = new RootStore();

    // Attach router store from mobx-state-router
    const onNavigation = !IS_PRODUCTION
      ? null
      : () => {
          SetGA({
            page: window.location.pathname + window.location.search,
          });
          PageViewGA(window.location.pathname + window.location.search);
        };
    rootStore.routerStore = new RouterStore(rootStore, {
      encode,
      decode,
      hooks: [
        loginHook,
        adminHook,
        roleHook,
        companyHook,
        accessControlHook,
        buildEnvironmentHook,
        apiAccessHook,
        chiOnlyUserHook,
        canLabelFautsHook,
        mfaHook,
      ],
      onNavigation,
    });

    // Attach reset option
    rootStore.reset = action((message) => {
      rootStore.destructor();
      rootStore.persistor.stopPersisting();
      rootStore.persistor.purge();
      this.postResetMessage = message;
      this.createRootStore();
      this.initialiseStore();
    });

    // Persist in local storage
    const persistor = createPersistor({
      prefix: "sensoteq-mobx",
      rootStore: rootStore,
      config: [
        {
          store: "userStore",
          whitelist: ["user", "token"],
        },
        {
          store: "localStore",
          whitelist: ["hash", "preferences"],
          immune: ["preferences"],
        },
        {
          store: "reportStore",
          whitelist: ["images"],
        },
        {
          store: "uiStore",
          whitelist: ["mainMenuCollapsed"],
        },
      ],
    });
    persistor.rehydrate();
    persistor.persist();

    // Initial state bootstrap
    try {
      const {userStore} = rootStore;
      const {token} = userStore;

      // Init services
      setAuthToken(token);
      setReactQueryAPIToken(token);
      configureFetch({
        onRequestError: () => {
          rootStore.notificationStore.addNotification("Something went wrong trying to contact the server", "bad");
          rootStore.notificationStore.blockNotifications(5000);
        },
        onResponseError: (ctx) => {
          if (ctx.response.status === 500) {
            rootStore.notificationStore.addNotification("There was a server error", "bad");
            rootStore.notificationStore.blockNotifications(5000);
            return;
          }

          if (ctx.response.status === 401) {
            userStore.logOut("Your session has expired, please log in again", true, false);
            rootStore.notificationStore.blockNotifications(2000);
            return;
          }

          const forceLogout = ctx.response._data?.force_logout ?? false;
          if (forceLogout) {
            userStore.logOut("You were logged out, please log in again", true, false);
            rootStore.notificationStore.blockNotifications(2000);
          }
        },
      });
      setOnLogOut((message) => {
        rootStore.notificationStore.blockNotifications(2000);
        return userStore.logOut(message, true, false);
      });
      setBlockNotifications(() => rootStore.notificationStore.blockNotifications(2000));
    } catch (error) {
      // Catch any errors here and try to fix them by clearing local storage and reloading the page
      persistor.purge();
      window.location.reload(true);
    }

    this.rootStore = rootStore;
  }

  initialiseStore() {
    if (this.rootStore.userStore.loggedIn) {
      this.rootStore.refresh();
      this.rootStore.userStore.refreshSelf();
    }
    this.rootStore.configStore.loadServerConfig();
  }

  render() {
    return <StoreContext.Provider value={this.rootStore}>{this.props.children}</StoreContext.Provider>;
  }
}
