import {computed, flow, observable} from "mobx";

import LoadableStore from "sensoteq-react-core/models/LoadableStore";
import Api from "sensoteq-react-core/services/api";

export default class PointStore extends LoadableStore {
  @observable _options;

  constructor(rootStore) {
    super(rootStore);
  }

  @computed get testPoints() {
    const {machineStore} = this.rootStore;
    let testPoints = [];
    machineStore.machines.forEach((machine) => {
      testPoints = testPoints.concat(machine.test_points || []);
    });
    return testPoints;
  }

  @computed get thermalPoints() {
    const {machineStore} = this.rootStore;
    let testPoints = [];
    machineStore.machines.forEach((machine) => {
      testPoints = testPoints.concat(machine.thermal_points || []);
    });
    return testPoints;
  }

  @computed get _reverseLookupMap() {
    let lookupMap = [];
    this.testPoints.concat(this.thermalPoints).forEach((testPoint) => {
      if (testPoint.point_id) {
        lookupMap[testPoint.point_id] = testPoint;
      }
    });
    return lookupMap;
  }

  @computed get _sensorIDReverseLookupMap() {
    let lookupMap = [];
    this.testPoints.forEach((testPoint) => {
      if (!testPoint.disabled) {
        lookupMap[testPoint.sensor_id] = testPoint;
      }
    });
    return lookupMap;
  }

  getTestPoint(pointId) {
    return this._reverseLookupMap[pointId];
  }

  getTestPointBySensorID(sensorId) {
    return this._sensorIDReverseLookupMap[sensorId];
  }

  pointExists(pointId) {
    return this.getTestPoint(pointId) != null;
  }

  createTestPoint = flow(function* (machineId, siteName, type, name, deviceName, sensorId, step, position, channels) {
    this.startLoading();
    try {
      yield Api.createMeasuringPoint(machineId, siteName, type, name, deviceName, sensorId, step, position, channels);
      this.rootStore.notificationStore.addNotification("Measuring point created successfully", "good");
      this.rootStore.machineStore.refreshMachine(machineId, siteName);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error creating measuring point: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateTestPoint = flow(
    function* (pointId, machineId, siteName, type, name, deviceName, sensorId, step, position, channels, notes) {
      this.startLoading();
      try {
        yield Api.updateMeasuringPoint(
          pointId,
          machineId,
          siteName,
          type,
          name,
          deviceName,
          sensorId,
          step,
          position,
          channels,
          notes,
        );
        this.rootStore.notificationStore.addNotification("Measuring point updated successfully", "good");

        // Update new machine if changed
        const point = this.getTestPoint(pointId);
        if (point.machine_id !== machineId) {
          yield this.rootStore.machineStore.refreshMachine(machineId, siteName);
        }

        // Update old machine
        yield this.rootStore.machineStore.refreshMachine(point.machine_id, siteName);
      } catch (error) {
        this.rootStore.notificationStore.addNotification(`Error updating measuring point: ${error}`, "bad");
      }
      this.stopLoading();
    },
  );

  deleteTestPoint = flow(function* (pointId) {
    this.startLoading();
    try {
      const point = this.getTestPoint(pointId);
      yield Api.deleteMeasuringPoint(pointId);
      this.rootStore.notificationStore.addNotification("Measuring point deleted successfully", "good");
      this.rootStore.machineStore.refreshMachine(point.machine_id, point.site_name);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting measuring point: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateTestPointState = flow(function* (pointId, disabled) {
    this.startLoading();
    try {
      const point = this.getTestPoint(pointId);
      yield Api.updateMeasuringPointState(pointId, disabled);
      this.rootStore.notificationStore.addNotification(
        `Measuring point ${disabled ? "disabled" : "enabled"} successfully`,
        "good",
      );
      this.rootStore.machineStore.refreshMachine(point.machine_id, point.site_name);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(
        `Error ${disabled ? "disabling" : "enabling"} measuring point: ${error}`,
        "bad",
      );
    }
    this.stopLoading();
  });

  updateTestPointMetadata = flow(function* (pointId, metadata) {
    this.startLoading();
    try {
      yield Api.updateMeasuringPointMetadata(pointId, metadata);
      this.rootStore.notificationStore.addNotification(`Measuring point metadata updated successfully`, "good");
      const point = this.getTestPoint(pointId);
      if (point) {
        this.rootStore.machineStore.refreshMachine(point.machine_id, point.site_name);
      }
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating measuring point metadata: ${error}`, "bad");
    }
    this.stopLoading();
  });

  replaceSensor = flow(
    function* (pointId, sensorId, deviceName, updateSoftwareFilter, updateHardwareFilter, persistEmpty) {
      this.startLoading();
      try {
        const point = this.getTestPoint(pointId);
        yield Api.replaceSensor(
          pointId,
          sensorId,
          deviceName,
          updateSoftwareFilter,
          updateHardwareFilter,
          persistEmpty,
        );
        this.rootStore.notificationStore.addNotification("Hardware edited successfully", "good");
        yield this.rootStore.machineStore.refreshMachine(point.machine_id, point.site_name);
      } catch (error) {
        this.rootStore.notificationStore.addNotification(`Error editing hardware: ${error}`, "bad");
      }
      this.stopLoading();
    },
  );

  updateTestPointConfig = flow(function* (pointId, orientation, rpm, metadata) {
    this.startLoading();
    try {
      const point = this.getTestPoint(pointId);
      if (!point) {
        this.rootStore.notificationStore.addNotification(`That measuring point ID does not exist`, "bad");
        return;
      }
      let promises = [];
      if (orientation != null) {
        promises.push(Api.setSensorOrientation(point.sensor_id, orientation));
      }
      if (rpm != null) {
        promises.push(Api.setSensorRPM(point.sensor_id, rpm));
      }
      if (metadata != null) {
        promises.push(Api.updateMeasuringPointMetadata(pointId, metadata));
      }
      if (!promises.length) {
        return;
      }
      yield Promise.all(promises);
      this.rootStore.notificationStore.addNotification(`Measuring point config updated successfully`, "good");
      this.rootStore.machineStore.refreshMachine(point.machine_id, point.site_name);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating measuring point config: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
