import PropTypes from "prop-types";
import React from "react";
import {reaction, action} from "mobx";
import {observer} from "mobx-react";

import {useRouterStore} from "./index";
import Redirect from "./Redirect";
import RouteContext from "./route-context";

const Route = (props) => {
  const {route, matchParams} = props;
  const routerStore = useRouterStore();
  const lastSearchReaction = React.useRef(null);
  const lastSearchGenerated = React.useRef(`?${window.location.href.split("?")[1]}`);
  const [firstRender, setFirstRender] = React.useState(true);

  // Check hook permission to access this route.
  // If permission is denied then we shouldn't update any state.
  // This must be checked first as we need to call all hooks regardless.
  const redirect = routerStore.processHooks(route.hookParams);

  // Update route params whenever match params change
  React.useEffect(
    action(() => {
      if (redirect == null) {
        Object.keys(route.params).forEach((param) => {
          route.state.params[param] = matchParams[param] ?? null;
        });
      }
    }),
    [matchParams],
  );

  // Update state from query on mount
  React.useEffect(
    action(() => {
      setFirstRender(false);

      // On the initial render, immediately update the route and query params from the URL
      if (redirect == null) {
        if (route.mapQueryToState) {
          route.mapQueryToState(routerStore.rootStore, routerStore.state.queryParams);
        }
      }
    }),
    [],
  );

  // Keep query params in sync with state
  React.useEffect(() => {
    if (route.mapStateToQuery) {
      return reaction(
        () => routerStore.createUrl(route, route.state.params),
        (url) => {
          // Skip if the state change is not applicable to this route.
          // This can happen if we haven't fully unmounted yet and another route has updated query params
          if (!routerStore.isRouteActive(route)) {
            return;
          }

          // Create the new URL that would reflect the latest state changes
          lastSearchGenerated.current = `?${url.split("?")[1]}`;

          // Skip if the newly generated URL matches one that was reacted to by us already.
          // ie if the user updated the URL manually, then we reacted by updating state, and now
          // we are seeing the corresponding state changes from that action which has already been handled
          if (lastSearchGenerated.current === lastSearchReaction.current) {
            return;
          } else {
            // Otherwise if this is a programmatic URL update, we can reset the last reaction URL
            // because we aren't in a loop.
            // If we don't unset this, then we will never be able to to update the URL back to whatever the last
            // reacted to URL was, ie the initial load URL
            lastSearchReaction.current = null;
          }

          // Otherwise this is an action caused by a state change and not by a user, so we should
          // update the query string to reflect the new state
          if (route.redirectNextUpdate) {
            delete route.redirectNextUpdate;
            routerStore.replace(url);
          } else {
            routerStore.push(url);
          }
        },
      );
    }
  }, []);

  // Keep state in sync with query params
  React.useEffect(() => {
    if (route.mapQueryToState) {
      return reaction(
        () => routerStore.state.queryParams,
        action((params) => {
          // Skip if the query string change is not applicable to this route.
          // This can happen if we haven't fully unmounted yet and another route has updated query params
          if (!routerStore.isRouteActive(route)) {
            return;
          }

          // Skip if the query params were already generated by us, IE not a manual action of the user
          if (window.location.search === lastSearchGenerated.current) {
            return;
          }

          // Otherwise this change in query params was not a result of us, but an action by the user.
          // We need to update state to keep in sync
          lastSearchReaction.current = window.location.search;
          route.mapQueryToState(routerStore.rootStore, params);
        }),
      );
    }
  }, []);

  // Callback to update the current query params by redirecting
  const replaceQueryParams = React.useCallback(
    action((storeUpdate) => {
      route.redirectNextUpdate = true;
      storeUpdate(routerStore.rootStore);
    }),
    [],
  );

  // Callback to update the current route params
  const pushRouteParams = React.useCallback((routeParamChanges = {}, queryOverrides) => {
    routerStore.push(routerStore.createUrl(route, {...route.state.params, ...routeParamChanges}, queryOverrides));
  }, []);

  // Callback to update the current route params and redirect
  const replaceRouteParams = React.useCallback((routeParamChanges = {}) => {
    routerStore.replace(routerStore.createUrl(route, {...route.state.params, ...routeParamChanges}));
  }, []);

  // Now that all hooks have been called we can redirect if required by hooks
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  // Render nothing until state is ready
  if (firstRender) {
    return null;
  }

  // Create render component
  const Component = route.component;
  return (
    <RouteContext.Provider value={route}>
      <Component
        routeParams={route.state.params}
        replaceQueryParams={replaceQueryParams}
        replaceRouteParams={replaceRouteParams}
        pushRouteParams={pushRouteParams}
      />
    </RouteContext.Provider>
  );
};

Route.propTypes = {
  exact: PropTypes.bool,
  route: PropTypes.object,
};
Route.defaultProps = {
  type: "route",
};

export default observer(Route);
