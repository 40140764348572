import {flow, computed, observable} from "mobx";
import dayjs from "dayjs";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class MachineUptimeEventSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      machineId: null,
      getTimeRange: () => this.rootStore.uiStore.continuousDataDates,
    };
  }
  getParsedParams(params) {
    return {
      from: params.getTimeRange().from,
      to: params.getTimeRange().to,
    };
  }

  @observable.shallow _data;

  getData = flow(function* ({machineId, from, to}) {
    if (!machineId) {
      return;
    }
    this.startLoading();
    try {
      const data = yield Api.getMachineUptimeEvents(machineId, from.valueOf(), to.valueOf());
      this._data = data;
    } catch (error) {
      // Temporarily disable user-facing error reporting until cloud changes are released
      // this.rootStore.notificationStore.addNotification(`Error getting machine uptime: ${error}`, "bad");
      console.warn("Api.getMachineUptimeEvents is not available");
      console.error(error);
    }
    this.stopLoading();
  });

  @computed get summary() {
    if (!this._data) {
      return null;
    }
    return this._data.summary;
  }

  @computed get data() {
    if (!this._data) {
      return [];
    }
    return [
      {
        data: this._data.map((entry) => ({
          uptime: entry.on_percentage,
          date: dayjs(entry.timestamp),
        })),
      },
    ];
  }
}
