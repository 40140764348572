import type {ObjectValues} from "../types.js";

export const AccelerationOption = {
  MILLI_G: "mg",
  INCHES_PER_SECOND_SQUARED: "in/s²",
  G: "g",
} as const;

export const AccelerationRatio = {
  [AccelerationOption.MILLI_G]: 1,
  [AccelerationOption.INCHES_PER_SECOND_SQUARED]: 0.38608858267,
  [AccelerationOption.G]: 0.001,
};

export type Acceleration = ObjectValues<typeof AccelerationOption>;
