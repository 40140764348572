import {flow} from "mobx";

import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import Api from "sensoteq-react-core/services/api";

export default class TemplateStore extends SubscribableStore {
  templateType;

  constructor(rootStore, subscriptionType, templateType) {
    super(rootStore, subscriptionType);
    this.templateType = templateType;
  }

  createTemplate = flow(function* (name, template, company) {
    this.startLoading();
    try {
      const result = yield Api.createTemplate(this.templateType, name, template, company, false);
      this.rootStore.notificationStore.addNotification("Template created successfully", "good");
      yield this.sync(result.template_id);
      this.stopLoading();
      return result.template_id;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error creating template: ${error}`, "bad");
    }
    this.stopLoading();
  });

  deleteTemplate = flow(function* (templateId) {
    this.startLoading();
    try {
      yield Api.deleteTemplate(this.templateType, templateId);
      this.rootStore.notificationStore.addNotification("Template deleted successfully", "good");
      yield this.sync(templateId);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting template: ${error}`, "bad");
    }
    this.stopLoading();
  });

  updateTemplate = flow(function* (templateId, name, template) {
    this.startLoading();
    try {
      yield Api.updateTemplate(this.templateType, templateId, name, template);
      this.rootStore.notificationStore.addNotification("Template updated successfully", "good");
      yield this.sync(templateId);
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating template: ${error}`, "bad");
    }
    this.stopLoading();
  });

  async retrieve(templateId) {
    try {
      const result = await Api.getTemplates(this.templateType, undefined, templateId);
      return result.templates[0];
    } catch (error) {
      return null;
    }
  }
}
