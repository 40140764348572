import {observable, flow, computed, action} from "mobx";

import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class AlarmSubscription extends DataSubscription {
  getDefaultParams() {
    return {
      siteName: null,
      target: null,
      alarmId: null,
    };
  }

  @observable.shallow _alarms = [];

  getData = flow(function* ({siteName, target, alarmId}) {
    if (!siteName && !target && !alarmId) {
      return;
    }
    this.startLoading();
    try {
      const data = yield Api.getAlarms(siteName, target, alarmId);
      this._alarms = data.alarms;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error getting alarms: ${error}`, "bad");
    }
    this.stopLoading();
  });

  @action sync(id, item) {
    // Deletion
    if (!item) {
      const index = this._reverseAlarmIndexLookupMap[id];
      if (index != null) {
        this._alarms.splice(index, 1);
      }
      return;
    }

    // Check if a match
    const {siteName, alarmId, target} = this.params;
    const match =
      (!alarmId || alarmId === id) && (!siteName || siteName === item.site_name) && (!target || target === item.target);
    if (!match) {
      return;
    }

    // Check if update or additional
    const index = this._reverseAlarmIndexLookupMap[id];
    const update = index != null;
    if (update) {
      this._alarms[index] = item;
    } else {
      this._alarms.push(item);
    }
  }

  @computed get alarms() {
    if (!this._alarms) {
      return [];
    }
    return this._alarms
      .filter((x) => x.is_active)
      .map((alarm) => ({
        ...alarm,
        parsedTarget: this.rootStore.alarmStore.parseAlarmTarget(alarm.target, alarm.target_type),
      }));
  }

  @computed get _reverseAlarmLookupMap() {
    let map = {};
    this.alarms.forEach((alarm) => {
      map[alarm.alarm_id] = alarm;
    });
    return map;
  }

  @computed get _reverseAlarmIndexLookupMap() {
    let map = {};
    this.alarms.forEach((alarm, idx) => {
      map[alarm.alarm_id] = idx;
    });
    return map;
  }

  getAlarm(id) {
    return this._reverseAlarmLookupMap[id];
  }
}
