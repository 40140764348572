import React from "react";
import autobind from "class-autobind-decorator";
import styled, {css} from "styled-components";

import {asField} from "../lib/mobx-form";
import Button from "./Button";
import Label from "./Label";

@autobind
class ListPicker extends React.Component {
  state = {
    set1Filter: "",
    set2Filter: "",
    timestamp: 0,
    selectedItem: null,
  };

  static Validations = {
    RequiredLength: (length) => (value) => {
      return value?.set2?.length >= length ? null : `At least ${length} item(s) required`;
    },
    MaxLength: (length) => (value) => {
      return value?.set2?.length > length ? `At most ${length} item(s) allowed` : null;
    },
    Required: (value) => ListPicker.Validations.RequiredLength(1)(value),
  };

  onChange(set1, set2) {
    if (this.props.fieldState.disabled) {
      return;
    }
    this.setState({
      selectedItem: null,
      timestamp: 0,
    });
    const payload = {set1, set2};
    this.props.fieldApi.setValue(payload);
    if (this.props.onChange) {
      this.props.onChange(payload);
    }
  }

  allLeftToRight() {
    const {set1, set2} = this.props.fieldState.value;
    this.onChange([], set2.concat(set1));
  }

  allRightToLeft() {
    const {set1, set2} = this.props.fieldState.value;
    this.onChange(set1.concat(set2), []);
  }

  leftToRight() {
    const {set1, set2} = this.props.fieldState.value;

    // Sanity checks
    if (!this.state.selectedItem) {
      return;
    }
    if (set2.findIndex((x) => this.getValue(x) === this.getValue(this.state.selectedItem)) !== -1) return;

    // Left
    let left = set1.filter((x) => {
      return this.getValue(x) !== this.getValue(this.state.selectedItem);
    });

    // Right
    let right = set2.concat([this.state.selectedItem]);

    this.onChange(left, right);
  }

  rightToLeft() {
    const {set1, set2} = this.props.fieldState.value;

    // Sanity checks
    if (!this.state.selectedItem) {
      return;
    }
    if (set1.findIndex((x) => this.getValue(x) === this.getValue(this.state.selectedItem)) !== -1) return;

    // Left
    let left = set1.concat([this.state.selectedItem]);

    // Right
    let right = set2.filter((x) => {
      return this.getValue(x) !== this.getValue(this.state.selectedItem);
    });

    this.onChange(left, right);
  }

  select(item, side) {
    if (this.props.fieldState.disabled) {
      return;
    }
    if (this.getValue(item) === this.getValue(this.state.selectedItem) && Date.now() - this.state.timestamp < 500) {
      // Double clicks move items across
      if (side === "left") {
        this.leftToRight();
      } else {
        this.rightToLeft();
      }
    } else {
      // Otherwise just select item
      this.setState({
        selectedItem: item,
        timestamp: Date.now(),
      });
    }
  }

  onFilterSet1(e) {
    this.setState({
      set1Filter: e.target.value,
    });
  }

  onFilterSet2(e) {
    this.setState({
      set2Filter: e.target.value,
    });
  }

  getFilteredSet(set, filter = "") {
    let filteredSet = set ?? [];
    const searchString = filter.trim().toLowerCase();

    // Filter by search string if specified
    if (searchString) {
      filteredSet = filteredSet.filter((x) => this.getLabel(x).toLowerCase().includes(searchString));
    }

    return filteredSet;
  }

  getLabel(item) {
    if (item == null) return null;
    return typeof item === "object" ? item.label : item;
  }

  getValue(item) {
    if (item == null) return null;
    return typeof item === "object" ? item.value : item;
  }

  render() {
    const {label1, label2, fieldState} = this.props;
    const {value, disabled, error} = fieldState;
    const {set1Filter, set2Filter} = this.state;
    let {set1, set2} = value;

    // Filter sets
    set1 = this.getFilteredSet(set1, set1Filter);
    set2 = this.getFilteredSet(set2, set2Filter);

    return (
      <Wrapper>
        <Container>
          <Set>
            {label1 && <Label>{label1}</Label>}
            <FilterInput
              onChange={this.onFilterSet1}
              placeholder="Filter..."
            />
            <Items>
              {set1Filter && !set1.length && <Item notFound>No matches!</Item>}
              {set1.map((x) => {
                return (
                  <Item
                    active={this.getValue(this.state.selectedItem) === this.getValue(x)}
                    onClick={() => this.select(x, "left")}
                    key={this.getValue(x)}
                  >
                    {this.getLabel(x)}
                  </Item>
                );
              })}
            </Items>
          </Set>
          {!disabled && (
            <Controls>
              <Button
                text=">>"
                onClick={this.allLeftToRight}
              />
              <Button
                text=">"
                onClick={this.leftToRight}
              />
              <Button
                text="<"
                onClick={this.rightToLeft}
              />
              <Button
                text="<<"
                onClick={this.allRightToLeft}
              />
            </Controls>
          )}
          <Set>
            {label2 && <Label>{label2}</Label>}
            {error && <Label error>{error}</Label>}
            <FilterInput
              onChange={this.onFilterSet2}
              placeholder="Filter..."
              error={!!error}
            />
            <Items error={!!error}>
              {set2Filter && !set2.length && <Item notFound>No matches!</Item>}
              {set2.map((x) => {
                return (
                  <Item
                    active={this.getValue(this.state.selectedItem) === this.getValue(x)}
                    onClick={() => this.select(x, "right")}
                    key={this.getValue(x)}
                  >
                    {this.getLabel(x)}
                  </Item>
                );
              })}
            </Items>
          </Set>
        </Container>
      </Wrapper>
    );
  }
}

export default asField(ListPicker, {set1: [], set2: []});

const Wrapper = styled.div`
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 0 auto;
`;

const Set = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 0;
  width: 0;
  overflow: hidden;
`;

const Controls = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 2rem 1.5rem 0 1.5rem;

  button {
    padding: 1rem 1.2rem;
    margin-top: 0.2rem;

    span {
      font-weight: bold;
    }
  }
`;

const FilterInput = styled.input`
  padding: ${(props) => props.theme.forms.padding};
  border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  background-color: ${(props) => props.theme.colours.inputBackground};
  border-top-left-radius: ${(props) => props.theme.forms.borderRadius};
  border-top-right-radius: ${(props) => props.theme.forms.borderRadius};

  ${(props) =>
    props.error &&
    css`
      border-top: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.bad};
      border-left: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.bad};
      border-right: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.bad};
    `}

  &:focus {
    border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.primaryHighlight} !important;
    outline: none;
  }
`;

const Items = styled.div`
  flex: 1 0 auto;
  list-style: none;
  border: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.border};
  border-top: 0;
  border-bottom-left-radius: ${(props) => props.theme.forms.borderRadius};
  border-bottom-right-radius: ${(props) => props.theme.forms.borderRadius};
  overflow-y: auto;
  overflow-x: hidden;
  height: 12.8rem;
  user-select: none;
  background-color: ${(props) => props.theme.colours.inputBackground};

  ${(props) =>
    props.error &&
    css`
      border-left: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.bad};
      border-right: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.bad};
      border-bottom: ${(props) => props.theme.forms.borderWidth} solid ${(props) => props.theme.colours.bad};
    `}
`;

const Item = styled.div`
  padding: 0.4rem 1rem;

  ${(props) =>
    props.notFound &&
    css`
      margin-top: 0.4rem;
      color: ${(props) => props.theme.colours.contentLight};
    `}

  ${(props) =>
    !props.notFound &&
    css`
      ${(props) =>
        props.active &&
        css`
          background-color: ${(props) => props.theme.colours.primary};
          color: white;
        `}

      &:hover {
        background-color: ${(props) => props.theme.colours.primaryHighlight};
        color: white;
        cursor: pointer;
      }

      &.selected {
        background-color: ${(props) => props.theme.colours.primary};
        color: white;
      }
    `}
`;
