import {computed} from "mobx";

import mergeThemes from "sensoteq-react-core/themes/merge-themes";
import createDefaultTheme from "sensoteq-react-core/themes/default-theme";
import createDarkTheme from "styles/themes/dark";
import createHaverTheme from "styles/themes/haver";
import createHMITheme from "styles/themes/hmi";
import enums from "sensoteq-core/enumerations";
import * as envConfig from "sensoteq-react-core/constants/environment-config";

import {BROWSER_DEFAULT_FONT_SIZE, DESIGN_FONT_SIZE, SCROLL_BAR_WIDTH} from "constants/enums";

export default class ThemeStore {
  rootStore;
  themes = {
    Light: "Light",
    Dark: "Dark",
  };
  themeMap = {
    [this.themes.Light]: null,
    [this.themes.Dark]: createDarkTheme,
  };
  assets;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.assets = this.themeAssets;
  }

  get themeOptions() {
    return Object.values(this.themes);
  }

  get themeAssets() {
    let colors = {};
    let textLogos = {};
    const themeAssets = envConfig.getKey("theme_assets");
    for (const key in themeAssets) {
      colors[key] = key;
      textLogos[key] = themeAssets[key];
    }
    return {colors, textLogos};
  }

  @computed get colorOptions() {
    return this.assets.colors;
  }

  @computed get textLogos() {
    return this.assets.textLogos;
  }

  @computed get defaultThemeColor() {
    return this.assets.colors[envConfig.getKey("default_theme")];
  }

  @computed get title() {
    return envConfig.getKey("title");
  }

  @computed get loginBackgroundMedia() {
    return envConfig.getKey("login_media");
  }

  @computed get logos() {
    return {
      primary: envConfig.getKey("logo_primary"),
      secondary: envConfig.getKey("logo_secondary") ?? null,
    };
  }

  @computed get fontSizePercentage() {
    return 62.5 * this.rootStore.preferenceStore.uiScale;
  }

  @computed get fontSize() {
    return (this.fontSizePercentage / 100) * BROWSER_DEFAULT_FONT_SIZE;
  }

  @computed get printScale() {
    return this.fontSize / DESIGN_FONT_SIZE;
  }

  @computed get createUserTheme() {
    // Use default if not logged in
    const {userStore, preferenceStore} = this.rootStore;
    if (!userStore.loggedIn) {
      return null;
    }

    // Otherwise return custom theme
    const userTheme = preferenceStore.themePreferences.userTheme;
    if (!userTheme) {
      return null;
    }
    const bodyEl = document.body;
    if (userTheme.toLowerCase() === "dark") {
      bodyEl.classList.remove("theme-default");
      bodyEl.classList.add("theme-dark");
    }

    if (userTheme.toLowerCase() === "light") {
      bodyEl.classList.remove("theme-dark");
      bodyEl.classList.add("theme-default");
    }

    return this.themeMap[userTheme];
  }

  @computed get createStateTheme() {
    return (assign) => {
      assign("state", (theme) => ({
        currentMainMenuWidth: this.rootStore.uiStore.mainMenuCollapsed
          ? theme.dimensions.mainMenuCollapsedWidth
          : theme.dimensions.mainMenuWidth,
        fontSizePercentage: this.fontSizePercentage,
        remToPx: this.remToPx.bind(this),
        pxToRem: this.pxToRem.bind(this),
        ptToRem: this.ptToRem.bind(this),
        scrollBarWidth: SCROLL_BAR_WIDTH,
      }));
    };
  }

  @computed get createPreferenceTheme() {
    const preferences = this.rootStore.preferenceStore.themePreferences;
    if (preferences == null) {
      return null;
    }

    // Override default hues with user's preference if logged in
    if (!this.rootStore.userStore.loggedIn) {
      const bodyEl = document.body;
      bodyEl.classList.remove("theme-dark");
      bodyEl.classList.add("theme-default");
    } else {
      if (preferences?.hues) {
        const rootSelector = document.querySelector(":root");
        const darkThemeSelector = document.querySelector(".theme-dark");
        const {hues} = preferences;
        Object.keys(hues).forEach((hue) => {
          rootSelector.style.setProperty(`--hue-${hue}`, hues[hue]);
          if (darkThemeSelector) {
            darkThemeSelector.style.setProperty(`--hue-${hue}`, hues[hue]);
          }
        });
      }
    }

    return (assign) => {
      Object.keys(preferences).forEach((key) => {
        assign(key, () => preferences[key]);
      });
    };
  }

  @computed get getReskinTheme() {
    // Switch statement to allow other reskin themes to be added if needed
    switch (enums.ENVIRONMENT_BRANDING) {
      case "sensoteq" || "sandbox":
        return null;
      case "haver":
        return createHaverTheme;
      default:
        return null;
    }
  }

  @computed get theme() {
    return mergeThemes([
      createDefaultTheme,
      createHMITheme,
      this.getReskinTheme,
      this.createStateTheme,
      this.createUserTheme,
      this.createPreferenceTheme,
    ]);
  }

  @computed get lightTheme() {
    return mergeThemes([
      createDefaultTheme,
      createHMITheme,
      this.getReskinTheme,
      this.createStateTheme,
      this.createPreferenceTheme,
    ]);
  }

  @computed get darkTheme() {
    return mergeThemes([
      createDefaultTheme,
      createHMITheme,
      this.getReskinTheme,
      this.createStateTheme,
      this.themeMap.Dark,
      this.createPreferenceTheme,
    ]);
  }

  remToPx(rem) {
    let inputString = `${rem}`;
    if (inputString.includes("rem")) {
      inputString = inputString.split("rem")[0];
    }
    return parseFloat(inputString) * this.fontSize;
  }

  pxToRem(px) {
    let inputString = `${px}`;
    if (inputString.includes("px")) {
      inputString = inputString.split("px")[0];
    }
    return parseFloat(inputString) / this.fontSize;
  }

  ptToRem(pt) {
    let inputString = `${pt}`;
    if (inputString.includes("pt")) {
      inputString = inputString.split("pt")[0];
    }
    const px = parseFloat(inputString) * 1.333;
    return this.pxToRem(px);
  }
}
