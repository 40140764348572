let dbEnums = require("./cloud/db-enums");

const SensorTypes = {
  HIGH_G_MOTOR: "high_g_motor",
  LOW_G_MOTOR: "low_g_motor",
  LOW_G_RMS_MOTOR: "low_g_rms_motor",
  LOW_G_HIGH_MOTOR: "low_g_high_res_motor",
  LOW_G_AUTO_MOTOR: "low_g_auto_motor",
  HIGH_G_SCREEN: "high_g_screen",
  HIGH_G_SCREEN_TWF: "high_g_screen_twf",
  LOW_G_SCREEN: "low_g_screen",
  THERMAL: "thermal",
  VULCO_MONITORING_SENSOR: "vulco_monitoring_sensor",
  KPX: "kappa_x",
};

const MOTOR_TYPE_SENSORS = [
  SensorTypes.LOW_G_MOTOR,
  SensorTypes.LOW_G_RMS_MOTOR,
  SensorTypes.LOW_G_AUTO_MOTOR,
  SensorTypes.LOW_G_HIGH_MOTOR,
  SensorTypes.LOW_G_SCREEN,
  SensorTypes.KPX,
];

function isSensorMotorType(type) {
  return MOTOR_TYPE_SENSORS.includes(type);
}

function isScreenStructureSensor(type) {
  return [SensorTypes.HIGH_G_SCREEN, SensorTypes.HIGH_G_SCREEN_TWF].includes(type);
}

function isSensorTimeSynced(type) {
  return [SensorTypes.KPX, SensorTypes.LOW_G_AUTO_MOTOR].includes(type);
}

function isSensorAutoScaleCapable(type) {
  return [SensorTypes.KPX, SensorTypes.LOW_G_AUTO_MOTOR].includes(type);
}

const AlarmProperties = {
  AMBIENT_TEMPERATURE: "ambient_temperature",
  HUMIDITY: "humidity",
  WIFI_STRENGTH: "wifi_strength",
  SIGNAL_QUALITY: "signal_quality",
  MACHINE_TEMPERATURE: "machine_temperature",
  MACHINE_MINUS_AMBIENT: "machine_minus_ambient_temperature",
  VOLTAGE: "voltage",
  VALIDITY: "validity",
  PEAK_TO_PEAK_X: "peak_to_peak_x",
  PEAK_TO_PEAK_Y: "peak_to_peak_y",
  PEAK_TO_PEAK_Z: "peak_to_peak_z",
  // screen types
  STROKE_LENGTH: "stroke_length",
  STROKE_ANGLE: "stroke_angle",
  STROKE_RATIO: "stroke_ratio",
  RPM: "rpm",

  X_VELOCITY: "velocity_x",
  Y_VELOCITY: "velocity_y",
  Z_VELOCITY: "velocity_z",

  X_DISPLACEMENT: "displacement_x",
  Y_DISPLACEMENT: "displacement_y",
  Z_DISPLACEMENT: "displacement_z",
  X_ACCELERATION: "acceleration_x",
  Y_ACCELERATION: "acceleration_y",
  Z_ACCELERATION: "acceleration_z",
  PHASE_ANGLE: "phase_angle",
  DIFF_DISPLACEMENT: "displacement_diff",

  // acceleration RMS every minute or every 3 minutes depending on firmware
  AXIS_RMS_X: "axis_rms_x",
  AXIS_RMS_Y: "axis_rms_y",
  AXIS_RMS_Z: "axis_rms_z",

  // velocity RMS every 3 minutes
  AXIS_VEL_RMS_X: "axis_vel_rms_x",
  AXIS_VEL_RMS_Y: "axis_vel_rms_y",
  AXIS_VEL_RMS_Z: "axis_vel_rms_z",

  // peak to peak every 3 minutes
  AXIS_PEAK_X: "axis_peak_x",
  AXIS_PEAK_Y: "axis_peak_y",
  AXIS_PEAK_Z: "axis_peak_z",

  // calculated from velocity FFT, if an RPM is available
  VIB_POWER_X_H: "vib_power_x_high",
  VIB_POWER_Y_H: "vib_power_y_high",
  VIB_POWER_Z_H: "vib_power_z_high",
  VIB_POWER_X_L: "vib_power_x_low",
  VIB_POWER_Y_L: "vib_power_y_low",
  VIB_POWER_Z_L: "vib_power_z_low",

  // calculated from the time domain, acceleration
  ACC_RMS_X_H: "calc_acc_rms_x_high",
  ACC_RMS_Y_H: "calc_acc_rms_y_high",
  ACC_RMS_Z_H: "calc_acc_rms_z_high",
  ACC_RMS_X_L: "calc_acc_rms_x_low",
  ACC_RMS_Y_L: "calc_acc_rms_y_low",
  ACC_RMS_Z_L: "calc_acc_rms_z_low",

  // calculated from integrated acceleration FFT, velocity
  VEL_RMS_X_H: "calc_vel_rms_x_high",
  VEL_RMS_Y_H: "calc_vel_rms_y_high",
  VEL_RMS_Z_H: "calc_vel_rms_z_high",
  VEL_RMS_X_L: "calc_vel_rms_x_low",
  VEL_RMS_Y_L: "calc_vel_rms_y_low",
  VEL_RMS_Z_L: "calc_vel_rms_z_low",

  // calculated from integrated velocity FFT, displacement
  DISP_RMS_X_H: "calc_disp_rms_x_high",
  DISP_RMS_Y_H: "calc_disp_rms_y_high",
  DISP_RMS_Z_H: "calc_disp_rms_z_high",
  DISP_RMS_X_L: "calc_disp_rms_x_low",
  DISP_RMS_Y_L: "calc_disp_rms_y_low",
  DISP_RMS_Z_L: "calc_disp_rms_z_low",

  // calculated form the acceleration RMS
  CREST_FACTOR_X_H: "calc_crest_x_high",
  CREST_FACTOR_Y_H: "calc_crest_y_high",
  CREST_FACTOR_Z_H: "calc_crest_z_high",
  CREST_FACTOR_X_L: "calc_crest_x_low",
  CREST_FACTOR_Y_L: "calc_crest_y_low",
  CREST_FACTOR_Z_L: "calc_crest_z_low",

  // calculated from the hilbert enveloped signal acceleration RMS
  ENVELOPE_HILBERT_ACC_RMS_X_L: "envelope_hilbert_acc_rms_x_low",
  ENVELOPE_HILBERT_ACC_RMS_Y_L: "envelope_hilbert_acc_rms_y_low",
  ENVELOPE_HILBERT_ACC_RMS_Z_L: "envelope_hilbert_acc_rms_z_low",

  // calculated from the hilbert enveloped signal acceleration peak
  ENVELOPE_HILBERT_ACC_PEAK_X_L: "envelope_hilbert_peak_acc_x_low",
  ENVELOPE_HILBERT_ACC_PEAK_Y_L: "envelope_hilbert_peak_acc_y_low",
  ENVELOPE_HILBERT_ACC_PEAK_Z_L: "envelope_hilbert_peak_acc_z_low",

  // calculated peak to peak
  PEAK_TD_X_H: "peak_td_x_high",
  PEAK_TD_Y_H: "peak_td_y_high",
  PEAK_TD_Z_H: "peak_td_z_high",
  PEAK_TD_X_L: "peak_td_x_low",
  PEAK_TD_Y_L: "peak_td_y_low",
  PEAK_TD_Z_L: "peak_td_z_low",

  // calculated time domain peak value
  // NOTE: unfortunate naming here peak to peak above came before these. The above should have been named
  // peak_to_peak
  PEAK_VALUE_ACC_TD_X_H: "peak_value_acc_td_x_high",
  PEAK_VALUE_ACC_TD_Y_H: "peak_value_acc_td_y_high",
  PEAK_VALUE_ACC_TD_Z_H: "peak_value_acc_td_z_high",
  PEAK_VALUE_ACC_TD_X_L: "peak_value_acc_td_x_low",
  PEAK_VALUE_ACC_TD_Y_L: "peak_value_acc_td_y_low",
  PEAK_VALUE_ACC_TD_Z_L: "peak_value_acc_td_z_low",

  // Thermal
  THERMAL_ABSOLUTE: "absolute",
  THERMAL_DELTA: "delta",
  THERMAL_LOSS_OF_CAMERA_COMS: "loss_of_camera_coms",
  THERMAL_LOSS_OF_MEASURING_POINTS: "lost_measuring_point",
  THERMAL_LOSS_OF_SYSTEM: "lost_system",

  TIME_WAVEFORM_VALIDITY: "time_waveform_validity",
};

/*********************
 *  ALARM GROUPINGS  *
 *********************/

let merge = (...args) => {
  let final = [];
  // an array was input rather than a set of parameters
  if (args.length === 1 && args[0] instanceof Array) {
    args = args[0];
  }
  args.forEach((arg) => {
    if (arg instanceof Array) {
      final = [...new Set([...final, ...arg])];
    } else if (final.indexOf(arg) === -1) {
      final.push(arg);
    }
  });
  return final;
};
let AlarmGroups = {};
// shorthand, just to simplify matters
const AP = AlarmProperties;
const AG = AlarmGroups;
AlarmGroups.AXIS_RMS = [AP.AXIS_RMS_X, AP.AXIS_RMS_Y, AP.AXIS_RMS_Z];
AlarmGroups.AXIS_VEL_RMS = [AP.AXIS_VEL_RMS_X, AP.AXIS_VEL_RMS_Y, AP.AXIS_VEL_RMS_Z];
AlarmGroups.AXIS_PEAK = [AP.AXIS_PEAK_X, AP.AXIS_PEAK_Y, AP.AXIS_PEAK_Z];
AlarmGroups.SENSOR_ENVIRO = [AP.MACHINE_MINUS_AMBIENT, AP.MACHINE_TEMPERATURE];
AlarmGroups.GATEWAY_ENVIRO = [AP.AMBIENT_TEMPERATURE, AP.HUMIDITY];
AlarmGroups.ENVIRONMENT = merge(AG.SENSOR_ENVIRO, AG.GATEWAY_ENVIRO);
AlarmGroups.SENSOR_DIAG = [AP.VOLTAGE, AP.VALIDITY];
AlarmGroups.GATEWAY_DIAG = [AP.WIFI_STRENGTH, AP.SIGNAL_QUALITY];
AlarmGroups.DIAGNOSTIC = merge(AG.SENSOR_DIAG, AG.GATEWAY_DIAG);
AlarmGroups.PEAK_FORCE = [AP.PEAK_TO_PEAK_X, AP.PEAK_TO_PEAK_Y, AP.PEAK_TO_PEAK_Z];
AlarmGroups.PERIODIC_DATA = merge(AG.AXIS_RMS, AG.AXIS_VEL_RMS, AG.AXIS_PEAK);
AlarmGroups.SENSOR_AXIS_DATA = merge(AG.PERIODIC_DATA, AG.PEAK_FORCE);
AlarmGroups.RPM = [AP.RPM];
AlarmGroups.X_DISPLACEMENT = [AP.X_DISPLACEMENT];
AlarmGroups.Y_DISPLACEMENT = [AP.Y_DISPLACEMENT];
AlarmGroups.Z_DISPLACEMENT = [AP.Z_DISPLACEMENT];
AlarmGroups.SCREEN_DATA = [
  AP.STROKE_LENGTH,
  AP.STROKE_ANGLE,
  AP.STROKE_RATIO,
  AP.RPM,
  AP.X_VELOCITY,
  AP.Y_VELOCITY,
  AP.Z_VELOCITY,
  AP.X_DISPLACEMENT,
  AP.Y_DISPLACEMENT,
  AP.Z_DISPLACEMENT,
  AP.X_ACCELERATION,
  AP.Y_ACCELERATION,
  AP.Z_ACCELERATION,
  AP.PHASE_ANGLE,
  AP.DIFF_DISPLACEMENT,
];
AlarmGroups.DIFF_DATA = [AP.DIFF_DISPLACEMENT];
AlarmGroups.THERMAL_DATA = [
  AP.THERMAL_ABSOLUTE,
  AP.THERMAL_DELTA,
  AP.THERMAL_LOSS_OF_CAMERA_COMS,
  AP.THERMAL_LOSS_OF_MEASURING_POINTS,
  AP.THERMAL_LOSS_OF_SYSTEM,
];

AlarmGroups.TIME_WAVEFORM_VALIDITY = [AlarmProperties.TIME_WAVEFORM_VALIDITY];

AlarmGroups.VIB_POWER_X = [AP.VIB_POWER_X_H, AP.VIB_POWER_X_L];
AlarmGroups.VIB_POWER_Y = [AP.VIB_POWER_Y_H, AP.VIB_POWER_Y_L];
AlarmGroups.VIB_POWER_Z = [AP.VIB_POWER_Z_H, AP.VIB_POWER_Z_L];
AlarmGroups.VIB_POWER_H = [AP.VIB_POWER_X_H, AP.VIB_POWER_Y_H, AP.VIB_POWER_Z_H];
AlarmGroups.VIB_POWER_L = [AP.VIB_POWER_X_L, AP.VIB_POWER_Y_L, AP.VIB_POWER_Z_L];
AlarmGroups.ACC_RMS_X = [AP.ACC_RMS_X_H, AP.ACC_RMS_X_L];

AlarmGroups.VIB_POWER = merge(AG.VIB_POWER_H, AG.VIB_POWER_L);
AlarmGroups.ACC_RMS_X = [AP.ACC_RMS_X_H, AP.ACC_RMS_X_L];
AlarmGroups.ACC_RMS_Y = [AP.ACC_RMS_Y_H, AP.ACC_RMS_Y_L];
AlarmGroups.ACC_RMS_Z = [AP.ACC_RMS_Z_H, AP.ACC_RMS_Z_L];
AlarmGroups.ACC_RMS_H = [AP.ACC_RMS_X_H, AP.ACC_RMS_Y_H, AP.ACC_RMS_Z_H];
AlarmGroups.ACC_RMS_L = [AP.ACC_RMS_X_L, AP.ACC_RMS_Y_L, AP.ACC_RMS_Z_L];
AlarmGroups.ACC_RMS = merge(AG.ACC_RMS_H, AG.ACC_RMS_L);

AlarmGroups.VEL_RMS_X = [AP.VEL_RMS_X_H, AP.VEL_RMS_X_L];
AlarmGroups.VEL_RMS_Y = [AP.VEL_RMS_Y_H, AP.VEL_RMS_Y_L];
AlarmGroups.VEL_RMS_Z = [AP.VEL_RMS_Z_H, AP.VEL_RMS_Z_L];
AlarmGroups.VEL_RMS_H = [AP.VEL_RMS_X_H, AP.VEL_RMS_Y_H, AP.VEL_RMS_Z_H];
AlarmGroups.VEL_RMS_L = [AP.VEL_RMS_X_L, AP.VEL_RMS_Y_L, AP.VEL_RMS_Z_L];
AlarmGroups.VEL_RMS = merge(AG.VEL_RMS_H, AG.VEL_RMS_L);

AlarmGroups.DISP_RMS_X = [AP.DISP_RMS_X_H, AP.DISP_RMS_X_L];
AlarmGroups.DISP_RMS_Y = [AP.DISP_RMS_Y_H, AP.DISP_RMS_Y_L];
AlarmGroups.DISP_RMS_Z = [AP.DISP_RMS_Z_H, AP.DISP_RMS_Z_L];
AlarmGroups.DISP_RMS_H = [AP.DISP_RMS_X_H, AP.DISP_RMS_Y_H, AP.DISP_RMS_Z_H];
AlarmGroups.DISP_RMS_L = [AP.DISP_RMS_X_L, AP.DISP_RMS_Y_L, AP.DISP_RMS_Z_L];
AlarmGroups.DISP_RMS = merge(AG.DISP_RMS_H, AG.DISP_RMS_L);

AlarmGroups.CREST_FACTOR_X = [AP.CREST_FACTOR_X_H, AP.CREST_FACTOR_X_L];
AlarmGroups.CREST_FACTOR_Y = [AP.CREST_FACTOR_Y_H, AP.CREST_FACTOR_Y_L];
AlarmGroups.CREST_FACTOR_Z = [AP.CREST_FACTOR_Z_H, AP.CREST_FACTOR_Z_L];
AlarmGroups.CREST_FACTOR_H = [AP.CREST_FACTOR_X_H, AP.CREST_FACTOR_Y_H, AP.CREST_FACTOR_Z_H];
AlarmGroups.CREST_FACTOR_L = [AP.CREST_FACTOR_X_L, AP.CREST_FACTOR_Y_L, AP.CREST_FACTOR_Z_L];
AlarmGroups.CREST_FACTOR = merge(AG.CREST_FACTOR_H, AG.CREST_FACTOR_L);

AlarmGroups.ENVELOPE_HILBERT_ACC_RMS_X = [AP.ENVELOPE_HILBERT_ACC_PEAK_X_L];
AlarmGroups.ENVELOPE_HILBERT_ACC_RMS_Y = [AP.ENVELOPE_HILBERT_ACC_PEAK_Y_L];
AlarmGroups.ENVELOPE_HILBERT_ACC_RMS_Z = [AP.ENVELOPE_HILBERT_ACC_PEAK_Z_L];
AlarmGroups.ENVELOPE_HILBERT_ACC_RMS = [
  AP.ENVELOPE_HILBERT_ACC_RMS_X_L,
  AP.ENVELOPE_HILBERT_ACC_RMS_Y_L,
  AP.ENVELOPE_HILBERT_ACC_RMS_Z_L,
];
AlarmGroups.ENVELOPE_HILBERT_ACC_PEAK_X = [AP.ENVELOPE_HILBERT_ACC_PEAK_X_L];
AlarmGroups.ENVELOPE_HILBERT_ACC_PEAK_Y = [AP.ENVELOPE_HILBERT_ACC_PEAK_Y_L];
AlarmGroups.ENVELOPE_HILBERT_ACC_PEAK_Z = [AP.ENVELOPE_HILBERT_ACC_PEAK_Z_L];
AlarmGroups.ENVELOPE_HILBERT_ACC_PEAK = [
  AP.ENVELOPE_HILBERT_ACC_PEAK_X_L,
  AP.ENVELOPE_HILBERT_ACC_PEAK_Y_L,
  AP.ENVELOPE_HILBERT_ACC_PEAK_Z_L,
];

AlarmGroups.PEAK_TD_X = [AP.PEAK_TD_X_H, AP.PEAK_TD_X_L];
AlarmGroups.PEAK_TD_Y = [AP.PEAK_TD_Y_H, AP.PEAK_TD_Y_L];
AlarmGroups.PEAK_TD_Z = [AP.PEAK_TD_Z_H, AP.PEAK_TD_Z_L];
AlarmGroups.PEAK_TD_H = [AP.PEAK_TD_X_H, AP.PEAK_TD_Y_H, AP.PEAK_TD_Z_H];
AlarmGroups.PEAK_TD_L = [AP.PEAK_TD_X_L, AP.PEAK_TD_Y_L, AP.PEAK_TD_Z_L];
AlarmGroups.PEAK_TD = merge(AG.PEAK_TD_H, AG.PEAK_TD_L);

AlarmGroups.PEAK_VALUE_ACC_TD_X = [AP.PEAK_VALUE_ACC_TD_X_H, AP.PEAK_VALUE_ACC_TD_X_L];
AlarmGroups.PEAK_VALUE_ACC_TD_Y = [AP.PEAK_VALUE_ACC_TD_Y_H, AP.PEAK_VALUE_ACC_TD_Y_L];
AlarmGroups.PEAK_VALUE_ACC_TD_Z = [AP.PEAK_VALUE_ACC_TD_Z_H, AP.PEAK_VALUE_ACC_TD_Z_L];
AlarmGroups.PEAK_VALUE_ACC_TD_H = [AP.PEAK_VALUE_ACC_TD_X_H, AP.PEAK_VALUE_ACC_TD_Y_H, AP.PEAK_VALUE_ACC_TD_Z_H];
AlarmGroups.PEAK_VALUE_ACC_TD_L = [AP.PEAK_VALUE_ACC_TD_X_L, AP.PEAK_VALUE_ACC_TD_Y_L, AP.PEAK_VALUE_ACC_TD_Z_L];
AlarmGroups.PEAK_VALUE_ACC_TD = merge(AG.PEAK_VALUE_ACC_TD_H, AG.PEAK_VALUE_ACC_TD_L);

AlarmGroups.HIGH_RES = merge(
  AG.VIB_POWER_H,
  AG.ACC_RMS_H,
  AG.VEL_RMS_H,
  AG.DISP_RMS_H,
  AG.CREST_FACTOR_H,
  AG.PEAK_TD_H,
  AG.PEAK_VALUE_ACC_TD_H,
);
AlarmGroups.LOW_RES = merge(
  AG.VIB_POWER_L,
  AG.ACC_RMS_L,
  AG.VEL_RMS_L,
  AG.DISP_RMS_L,
  AG.CREST_FACTOR_L,
  AG.PEAK_TD_L,
  AG.PEAK_VALUE_ACC_TD_L,
  AG.ENVELOPE_HILBERT_ACC_RMS,
  AG.ENVELOPE_HILBERT_ACC_PEAK,
);
AlarmGroups.X_AXIS = merge(
  AP.PEAK_TO_PEAK_X,
  AP.AXIS_RMS_X,
  AG.VIB_POWER_X,
  AG.ACC_RMS_X,
  AG.VEL_RMS_X,
  AG.DISP_RMS_X,
  AG.CREST_FACTOR_X,
  AG.PEAK_TD_X,
  AG.PEAK_VALUE_ACC_TD_X,
  AP.AXIS_VEL_RMS_X,
  AP.AXIS_PEAK_X,
  AP.ENVELOPE_HILBERT_ACC_RMS_X_L,
  AP.ENVELOPE_HILBERT_ACC_PEAK_X_L,
);
AlarmGroups.Y_AXIS = merge(
  AP.PEAK_TO_PEAK_Y,
  AP.AXIS_RMS_Y,
  AG.VIB_POWER_Y,
  AG.ACC_RMS_Y,
  AG.VEL_RMS_Y,
  AG.DISP_RMS_Y,
  AG.CREST_FACTOR_Y,
  AG.PEAK_TD_Y,
  AG.PEAK_VALUE_ACC_TD_Y,
  AP.AXIS_VEL_RMS_Y,
  AP.AXIS_PEAK_Y,
  AP.ENVELOPE_HILBERT_ACC_RMS_Y_L,
  AP.ENVELOPE_HILBERT_ACC_PEAK_Y_L,
);
AlarmGroups.Z_AXIS = merge(
  AP.PEAK_TO_PEAK_Z,
  AP.AXIS_RMS_Z,
  AG.VIB_POWER_Z,
  AG.ACC_RMS_Z,
  AG.VEL_RMS_Z,
  AG.DISP_RMS_Z,
  AG.CREST_FACTOR_Z,
  AG.PEAK_TD_Z,
  AG.PEAK_VALUE_ACC_TD_Z,
  AP.AXIS_VEL_RMS_Z,
  AP.AXIS_PEAK_Z,
  AP.ENVELOPE_HILBERT_ACC_RMS_Z_L,
  AP.ENVELOPE_HILBERT_ACC_PEAK_Z_L,
);

AlarmGroups.TD_CALCS = merge(
  AG.ACC_RMS,
  AG.VEL_RMS,
  AG.DISP_RMS,
  AG.CREST_FACTOR,
  AG.PEAK_TD,
  AG.PEAK_VALUE_ACC_TD,
  AG.ENVELOPE_HILBERT_ACC_RMS,
  AG.ENVELOPE_HILBERT_ACC_PEAK,
  AG.TIME_WAVEFORM_VALIDITY,
);
AlarmGroups.SPECTRUM_BASED = merge(AG.TD_CALCS, AG.VIB_POWER);
AlarmGroups.SENSOR_PROPERTIES = merge(
  AG.TD_CALCS,
  AG.VIB_POWER,
  AG.SENSOR_AXIS_DATA,
  AG.SCREEN_DATA,
  AG.PEAK_FORCE,
  AG.ENVIRONMENT,
  AP.VOLTAGE,
  AP.VALIDITY,
);
AlarmGroups.RECV_PROPERTIES = merge(AP.AMBIENT_TEMPERATURE, AP.HUMIDITY, AP.WIFI_STRENGTH, AP.SIGNAL_QUALITY);
AlarmGroups.NUMBER_PROPERTIES = merge(
  AG.TD_CALCS,
  AG.SENSOR_AXIS_DATA,
  AG.SCREEN_DATA,
  AG.PEAK_FORCE,
  AG.ENVIRONMENT,
  AG.RECV_PROPERTIES,
  AG.DIAGNOSTIC,
  AG.TIME_WAVEFORM_VALIDITY,
);
AlarmGroups.ALL_PROPERTIES = merge(AG.NUMBER_PROPERTIES, AG.VIB_POWER);
AlarmGroups.CANNOT_BASELINE = merge(AG.VIB_POWER);
/*************************
 *  END ALARM GROUPINGS  *
 *************************/

function isAlarmForProperty(alarm, propertyList) {
  if (alarm == null || alarm["data"] == null) {
    return false;
  }
  if (!(propertyList instanceof Array)) {
    propertyList = [propertyList];
  }
  let property = alarm["data"]["property"];
  return propertyList.indexOf(property) !== -1;
}

const SchemaInfo = {
  MEASURING_POINTS: "m_point_ids",
  MACHINES: "machine_ids",
  ALARMS: "alarms",
  MACHINE_TYPE: "machine_type",
  MEASURING_POINT_TYPE: "m_point_type",
  ANALYSIS_POINTS: "points",
  PERCENTAGE: "percentage",
  WINDOW: "window",
  MINIMUM_VALUE: "min",
  MAXIMUM_VALUE: "max",
};

const AxisTypes = {
  AXIS_X: "x",
  AXIS_Y: "y",
  AXIS_Z: "z",
};

const TimeDomainResolution = {
  HIGH: "high",
  LOW: "low",
};

const TIME_DOMAIN_PER_PAYLOAD = 32;
const MAX_PAYLOAD_SIZE_BYTES = TIME_DOMAIN_PER_PAYLOAD * 2;
const HOUR_IN_MILLIS = 3600000;
const DAY_IN_MILLIS = 3600000 * 24;
const MONTH_IN_MILLIS = 3600000 * 24 * 30;

const TimeDomainAxisSize = {
  TIME_DOMAIN_BASIC: 1024,
  TIME_DOMAIN_HIGH_RES: 4096,
  TIME_DOMAIN_8192_HZ: 8192,
  TIME_DOMAIN_16384_HZ: 16384,
};

const TimeDomainMaxBlocks = {
  TIME_DOMAIN_BASIC: TimeDomainAxisSize.TIME_DOMAIN_BASIC / TIME_DOMAIN_PER_PAYLOAD,
  TIME_DOMAIN_HIGH_RES: TimeDomainAxisSize.TIME_DOMAIN_HIGH_RES / TIME_DOMAIN_PER_PAYLOAD,
  TIME_DOMAIN_16384_HZ: TimeDomainAxisSize.TIME_DOMAIN_16384_HZ / TIME_DOMAIN_PER_PAYLOAD,
  TIME_DOMAIN_8192_HZ: TimeDomainAxisSize.TIME_DOMAIN_8192_HZ / TIME_DOMAIN_PER_PAYLOAD,
};

// eventually we will do away with the concept of target versus real, as it is simply confusing (only use real)
const TimeDomainSamplingFrequency = {
  TIME_DOMAIN_BASIC_LOW: {target: 6000, real: 5641.44},
  TIME_DOMAIN_BASIC_HIGH: {target: 1000, real: 687.21},
  TIME_DOMAIN_HIGH_RES_LOW: {target: 6400, real: 6539},
  TIME_DOMAIN_HIGH_RES_HIGH: {target: 1393, real: 1394},
  TIME_DOMAIN_25600HZ_LOW: {target: 25600, real: 25600},
  TIME_DOMAIN_12800HZ_LOW: {target: 12800, real: 12800},
  TIME_DOMAIN_6400HZ_HIGH: {target: 6400, real: 6400},
  TIME_DOMAIN_1024HZ: {target: 1024, real: 1024},
};

// alias for easier use
const Axis = AxisTypes;
const Resolution = TimeDomainResolution;
const TDSize = {
  OLD: TimeDomainAxisSize.TIME_DOMAIN_BASIC,
  NEW: TimeDomainAxisSize.TIME_DOMAIN_HIGH_RES,
  HZ_16384: TimeDomainAxisSize.TIME_DOMAIN_16384_HZ,
  HZ_8192: TimeDomainAxisSize.TIME_DOMAIN_8192_HZ,
};

const SensorOrientation = {
  SIDE: "side",
  TOP: "top",
  AXIAL: "axial",
};

const SensorOrientationArray = [SensorOrientation.SIDE, SensorOrientation.TOP, SensorOrientation.AXIAL];

const VibrationDirection = {
  AXIAL: {name: "Axial", short: "AX"},
  HORIZONTAL: {name: "Horizontal", short: "HZ"},
  VERTICAL: {name: "Vertical", short: "VT"},
};

let TDOtherDataType = {
  ACC_RMS: "acc_rms",
  VEL_RMS: "vel_rms",
  OLD_VEL_RMS: "old_vel_rms",
  PEAK: "peak",
};

let VibInputDataUnit = {
  MG: "mg",
  ACCELEROMETER: "accelerometer",
};

function buildTDTypeObj(
  val,
  key,
  axis,
  size,
  resolution,
  otherDataType,
  sync = false,
  vibInputDataUnit,
  extendedPayload = false,
  syncAxis = AxisTypes.AXIS_X,
) {
  return {
    val: val,
    key: key,
    axis: axis,
    size: size,
    sync: sync,
    accRms: otherDataType === TDOtherDataType.ACC_RMS,
    velRms: otherDataType === TDOtherDataType.VEL_RMS,
    oldVelRms: otherDataType === TDOtherDataType.OLD_VEL_RMS,
    peak: otherDataType === TDOtherDataType.PEAK,
    resolution: resolution,
    vibInputDataUnit: vibInputDataUnit,
    extendedPayload: extendedPayload,
    syncAxis: syncAxis,
  };
}

module.exports.DEFAULT_GO_BACK = 100;
module.exports.SENSOR_CHECK_INTERVAL_SECS = 604800; // 1 week timeout of sensors (if not seen)
module.exports.MAXIMUM_DATA_CACHE_STORAGE = 400;
module.exports.VOLTAGE_MULTIPLIER = 23;
module.exports.TEMPERATURE_DIVIDER = 100;
module.exports.AMBIENT_DIVIDER = 10;
module.exports.OLD_VEL_RMS_DIVIDER = 200;
module.exports.VEL_RMS_DIVIDER = 100;

// environment variables
module.exports.IS_IN_PRODUCTION = process.env.SENSOTEQ_ENVIRONMENT === "PRODUCTION";
module.exports.FAST_SESSION_UPDATE = process.env.FAST_SESSION_UPDATE === "ENABLED";
module.exports.USE_TEST_MODE = process.env.USE_TEST_MODE === "ENABLED";
// we will always default to use local if nothing is specified
module.exports.USE_LOCAL = (() => {
  let local = true;
  if (process.env.SENSOTEQ_ENVIRONMENT === "PRODUCTION") local = false;
  if (process.env.USE_LOCAL === "ENABLED" || process.env.USE_TEST_MODE === "ENABLED") local = true;
  return local;
})();
module.exports.DISABLE_STREAM = process.env.DISABLE_STREAM === "ENABLED" || module.exports.USE_LOCAL;
module.exports.LONG_SESSIONS = process.env.LONG_SESSIONS === "ENABLED";

module.exports.BROKEN_HUMIDITY_FACTOR = 40;
module.exports.DEFAULT_CONT_NUMBER = 1;
module.exports.TIME_DOMAIN_ENTRY_COUNT = 1024;
module.exports.NEW_TD_ENTRY_COUNT = 4096;
module.exports.MAX_PAYLOAD_SIZE_BYTES = MAX_PAYLOAD_SIZE_BYTES;
module.exports.TIME_DOMAIN_PER_PAYLOAD = TIME_DOMAIN_PER_PAYLOAD;
module.exports.FULL_TIME_DOMAIN_SET = 32 * 3;
module.exports.STD_CACHE_TTL = 60 * 1000 * 5;
module.exports.SHORT_CACHE_TTL = 60 * 1000;
module.exports.STD_WRITE_CACHE_TTL = 60 * 1000;

module.exports.HUMIDITY_BROKEN_LIMIT = 70;
module.exports.HOUR_IN_MILLIS = HOUR_IN_MILLIS;
module.exports.DAY_IN_MILLIS = DAY_IN_MILLIS;
module.exports.MONTH_IN_MILLIS = MONTH_IN_MILLIS;
module.exports.ROUGH_INTERVAL_MILLIS = 90000;
module.exports.SESSION_MAX_TTL = module.exports.LONG_SESSIONS ? DAY_IN_MILLIS * 30 : DAY_IN_MILLIS;
module.exports.INVALID_DATA_RATE = -1;
module.exports.INVALID_RMS = -1;
module.exports.INVALID_BLOCK_NO = -1;
module.exports.TIME_DOMAIN_COMPLETION_OFFSET = 1.2;
module.exports.MAX_DATA_RATE_MS = 80000;
module.exports.MAX_FAST_DATA_RATE_MS = 30000;
module.exports.MIN_DATA_RATE_MS = 10000;
module.exports.MAX_MIN_DATA_COUNT = 30;
module.exports.RECEIVER_OFFLINE_TIME_SEC = 600;
module.exports.MAX_PHASE_XY_PEAKS = 4;
module.exports.MAX_PHASE_Z_PEAKS = 2;
module.exports.MAX_LAST_TIME_SEEN_ONLINE = 3600000;
module.exports.MAX_LAST_TIME_SEEN_ONLINE_GATEWAY = 900000;
module.exports.HIGH_RES_SAMPLING_FREQ_HZ = 1000;
module.exports.LOW_RES_SAMPLING_FREQ_HZ = 6000;
module.exports.TRUE_HIGH_RES_SAMPLING_FREQ_HZ = 687.21;
module.exports.TRUE_LOW_RES_SAMPLING_FREQ_HZ = 5641.44;
module.exports.PEAK_TO_PEAK_FULL_RECEPTION_DAY = 270;
module.exports.MG_DELTA_THRESHOLD_FOR_ON = 1000;
module.exports.MG_DELTA_THRESHOLD_FOR_SCREEN_ON = 2000;
module.exports.SEARCH_WINDOW_SIZE_MS = 3600000 * 2;
module.exports.MAX_SEARCH_DEPTH = 5;
module.exports.GRAVITY = 9.80665;
// for getting current we may want one being worked on; or the last complete; getting 2 allows either
module.exports.TIME_DOMAIN_LATEST_LOOKUP_LIMIT = 2;
// allows retrieving all records for a day and working out locally which record suits best
module.exports.TIME_DOMAIN_HISTORICAL_LOOKUP_LIMIT = 288;
module.exports.AVG_MAX_LOOKUP_LIMIT = 8000;
module.exports.ALARM_LOOKUP_LIMIT = 3000;
module.exports.HEALTH_CHECK_LOOKUP_LIMIT = 3000;
module.exports.USER_LIMIT = 5000;
module.exports.COMPANY_LIMIT = 1000;
module.exports.PARTNER_LIMIT = 1000;
module.exports.GATEWAY_LIMIT = 2000;
module.exports.POWER_LOOKUP_LIMIT = 1000;
module.exports.OTHER_CALCS_LOOKUP_LIMIT = 1000;
module.exports.RESTART_LOOKUP_LIMIT = 200;
module.exports.EVENT_LOOKUP_LIMIT = 1000;
module.exports.EVENT_LOOKUP_BY_ID_LIMIT = 1;
module.exports.MACHINES_LIMIT = 500;
module.exports.MACHINE_TYPES_LIMIT = 1000;
module.exports.BEARINGS_LIMIT = 20000;
module.exports.MEASURING_POINTS_LIMIT = 500 * 3;
module.exports.MEASURING_POINT_GET_LIMIT = 100;
module.exports.MEASURING_POINT_SCAN_LIMIT = 20000;
module.exports.MACHINE_SCAN_LIMIT = 5000;
module.exports.MACHINE_LOOKUP_LIMIT = 100;
module.exports.TIME_DOMAIN_KEY_LOOKUP_LIMIT = 1200;
module.exports.SESSION_LIMIT = 500;
module.exports.SCAN_SESSION_LIMIT = 5000;
module.exports.ALARM_REPORT_LIMIT = 500;
module.exports.CRITICALITY_LIMIT = 3000;
module.exports.TEMPLATING_LIMIT = 500;
module.exports.SENSOR_INFO_LIMIT = 40000;
module.exports.STATE_EVENT_LIMIT = 3000;
module.exports.SITE_LIMIT = 3000;
module.exports.CHI_OBJ_LIMIT = 200;
module.exports.CHI_KEY_LIMIT = 5000;
module.exports.ALTERATION_LIMIT = 2000;
module.exports.OTHER_HW_LIMIT = 1000;
module.exports.SITE_IMAGE_LIMIT = 3000;
module.exports.MAX_SITE_IMAGE_RECORDS = 200;
module.exports.DEFAULT_EXPIRY_TIME_S3_SECONDS = 900;
module.exports.NOT_FOUND_ERROR = "no entry found";
module.exports.RMS_MG_IS_RUNNING = 100;
module.exports.MIN_RPM_RANGE = 610;
// highest possible motor RPM for AC induction
module.exports.MAX_RPM_RANGE = 2600;
module.exports.DEVICE_TIMEOUT_MS = 20000;
module.exports.SEPARATOR = ",";
module.exports.INTERNAL_SEPARATOR = " =";
module.exports.DEFAULT_COUNT = 10;
module.exports.DEFAULT_RMS = 100;
module.exports.TIME_BEFORE_ALARM_HMI_MS = HOUR_IN_MILLIS * 16;
module.exports.TIME_AFTER_ALARM_HMI_MS = HOUR_IN_MILLIS * 6;
module.exports.INVALID_DEVICE_SENSOR = "N/A";
module.exports.TIME_DOMAIN_PERCENTAGE_TO_HIDE = 90;
module.exports.ALARM_NOT_ACTIVE = 0;
module.exports.ALARM_ACTIVE = 1;
module.exports.SENSOR_DEVICE_PREFIX = "sensoteq-receiver-";
module.exports.SENSOR_ID_PREFIX = "sensor_";

module.exports.DATA_PROCESSOR_DEBUG_DIR = "debug";
module.exports.DATA_PROCESSOR_DEBUG_FILE = "debug-points.json";

module.exports.TimeDomainAxisSize = TimeDomainAxisSize;
module.exports.TimeDomainMaxBlocks = TimeDomainMaxBlocks;
module.exports.TimeDomainSamplingFrequency = TimeDomainSamplingFrequency;
module.exports.SchemaInfo = SchemaInfo;
// TODO: replace references to this throughout the system so that it can be fully removed from enums
module.exports.DatabaseInfo = dbEnums.DatabaseInfo;
module.exports.DatabaseIndexes = dbEnums.DatabaseIndexes;
module.exports.MachTimeType = dbEnums.MachTimeType;
module.exports.IndexValues = dbEnums.IndexValues;

module.exports.NO_NAME = "no_name";
module.exports.NO_TYPE = "no_type";

module.exports.ElasticSearchEnum = {
  DEFAULT_INDEX: "sensoteq-data",
  TIMESTAMP_FIELD: "date",
  DEVICE_NAME: "device_name",
  POINT_ID: "point_id",
};

module.exports.ParticleEventEnum = {
  PARTICLE_EVENT_SIGNAL_STRENGTH: "SignalStrength",
  PARTICLE_EVENT_AMBIENT_TEMPERATURE: "TempSensors",
  PARTICLE_EVENT_STATUS: "spark/status",
  PARTICLE_EVENT_SENSOR_DATA: "SensorData",
  PARTICLE_EVENT_BLE_PEAKS: "BLEPeakData",
  /* Gen2 protocol */
  PARTICLE_EVENT_START_DATA: "ReceiverStartData",
  PARTICLE_EVENT_CONT_DATA: "ReceiverContinuationData",
  PARTICLE_EVENT_STARTUP: "Startup",
  PARTICLE_EVENT_RESTART: "RestartReason",
  PARTICLE_EVENT_SETTINGS: "InitialSettings",
  PARTICLE_EVENT_SETTINGS_JSON: "InitialSettingsJSON",
};

module.exports.SensorStatus = {
  SENSOR_STATUS_OLD_NORMAL: {val: 0x00, key: "normal"},
  SENSOR_STATUS_STARTUP_MODE: {val: 0x01, key: "startup"},
  SENSOR_STATUS_NORMAL_MODE: {val: 0x02, key: "normal"},
};

module.exports.SensorDataType = {
  SENSOR_DATA_TYPE_AXIS_X: {val: 0, key: "axisXData"},
  SENSOR_DATA_TYPE_AXIS_Y: {val: 1, key: "axisYData"},
  SENSOR_DATA_TYPE_AXIS_Z: {val: 2, key: "axisZData"},
  SENSOR_DATA_TYPE_INFO: {val: 4, key: "infoData"},
  SENSOR_DATA_TYPE_CALCULATED: {val: -1, key: "calculatedData"},
};

const HIGH = Resolution.HIGH;
const LOW = Resolution.LOW;

const S_OLD = TDSize.OLD;
const S_NEW = TDSize.NEW;

const X = Axis.AXIS_X;
const Y = Axis.AXIS_Y;
const Z = AxisTypes.AXIS_Z;

const TDExt = TDOtherDataType;
const SYNCED = true;

const EXTRA_6_BYTES_PAYLOAD = true;

module.exports.Gen2DataType = {
  OLD_AXIS_PEAKS_TOP_5_2G: {val: 1, key: "OldTop5AxisPeaks2G"},
  AXIS_PEAKS_TOP_5_2G: {val: 2, key: "Top5AxisPeaks2G"},
  AXIS_PEAKS_TOP_5_4G: {val: 3, key: "Top5AxisPeaks4G"},
  AXIS_PEAKS_TOP_5_8G: {val: 4, key: "Top5AxisPeaks8G"},
  AXIS_PEAKS_TOP_5_16G: {val: 5, key: "Top5AxisPeaks16G"},
  E_MAG_X: {
    ...buildTDTypeObj(6, "EMagX", X, 8192, LOW, TDExt.ACC_RMS, SYNCED),
    samplingFrequency: 204.8,
    maxBlocks: 8192 / 32,
  },
  E_MAG_Y: {
    ...buildTDTypeObj(7, "EMagY", Y, 4096, LOW, TDExt.ACC_RMS, SYNCED),
    samplingFrequency: 204.8,
    maxBlocks: 4096 / 32,
  },
  E_MAG_Z: {
    ...buildTDTypeObj(8, "EMagZ", Z, 1024, LOW, TDExt.ACC_RMS, SYNCED),
    samplingFrequency: 204.8,
    maxBlocks: 1024 / 32,
  },
  PEAK_TO_PEAK: {val: 9, key: "PeakToPeak"},
  // PhaseAndPeaksWithPeakFix was a new type added with the release of Tau-E came with a fix for the peak to peak
  // min and max values in the phase payload. To delinate the fix a new type was needed. 10 was an unused data type.
  // https://github.com/Sensoteq/sensoteq-stm-sensor/commit/0865e7a227a083c18c813d617ee0c637489191a7
  // This type is PhaseAndPeaksFixed extended with another fix which is why I went with this name.
  PHASE_AND_PEAKS_FIXED_WITH_PEAK_FIX: {val: 10, key: "PhaseAndPeaksFixedWithPeakFix"},
  PHASE_AND_PEAKS: {val: 11, key: "PhaseAndPeaks"},
  TD_X_HIGH: buildTDTypeObj(12, "TimeDomainXHigh", X, S_OLD, HIGH),
  TD_Y_HIGH: buildTDTypeObj(13, "TimeDomainYHigh", Y, S_OLD, HIGH),
  TD_Z_HIGH: buildTDTypeObj(14, "TimeDomainZHigh", Z, S_OLD, HIGH),
  TD_X_RMS: buildTDTypeObj(15, "TimeDomainXRms", X, S_OLD, LOW, TDExt.ACC_RMS),
  TD_Y_RMS: buildTDTypeObj(16, "TimeDomainYRms", Y, S_OLD, LOW, TDExt.ACC_RMS),
  TD_Z_RMS: buildTDTypeObj(17, "TimeDomainZRms", Z, S_OLD, LOW, TDExt.ACC_RMS),
  TD_X_HIGH_RMS: buildTDTypeObj(18, "TimeDomainXHighRms", X, S_OLD, HIGH, TDExt.ACC_RMS),
  TD_Y_HIGH_RMS: buildTDTypeObj(19, "TimeDomainYHighRms", Y, S_OLD, HIGH, TDExt.ACC_RMS),
  TD_Z_HIGH_RMS: buildTDTypeObj(20, "TimeDomainZHighRms", Z, S_OLD, HIGH, TDExt.ACC_RMS),
  SW_VERSION: {val: 21, key: "SoftwareVersion"},
  TD_4096_ACC_LOW_X: buildTDTypeObj(22, "TimeDomain4096LowX", X, S_NEW, LOW, TDExt.ACC_RMS),
  TD_4096_ACC_LOW_Y: buildTDTypeObj(23, "TimeDomain4096LowY", Y, S_NEW, LOW, TDExt.ACC_RMS),
  TD_4096_ACC_LOW_Z: buildTDTypeObj(24, "TimeDomain4096LowZ", Z, S_NEW, LOW, TDExt.ACC_RMS),
  TD_4096_ACC_HIGH_X: buildTDTypeObj(25, "TimeDomain4096HighX", X, S_NEW, HIGH, TDExt.ACC_RMS),
  TD_4096_ACC_HIGH_Y: buildTDTypeObj(26, "TimeDomain4096HighY", Y, S_NEW, HIGH, TDExt.ACC_RMS),
  TD_4096_ACC_HIGH_Z: buildTDTypeObj(27, "TimeDomain4096HighZ", Z, S_NEW, HIGH, TDExt.ACC_RMS),
  TD_4096_LOW_NO_RMS_X: buildTDTypeObj(28, "TimeDomain4096LowNoRmsX", X, S_NEW, LOW),
  TD_4096_LOW_NO_RMS_Y: buildTDTypeObj(29, "TimeDomain4096LowNoRmsY", Y, S_NEW, LOW),
  TD_4096_LOW_NO_RMS_Z: buildTDTypeObj(30, "TimeDomain4096LowNoRmsZ", Z, S_NEW, LOW),
  TD_4096_HIGH_NO_RMS_X: buildTDTypeObj(31, "TimeDomain4096HighNoRmsX", X, S_NEW, HIGH),
  TD_4096_HIGH_NO_RMS_Y: buildTDTypeObj(32, "TimeDomain4096HighNoRmsY", Y, S_NEW, HIGH),
  TD_4096_HIGH_NO_RMS_Z: buildTDTypeObj(33, "TimeDomain4096HighNoRmsZ", Z, S_NEW, HIGH),
  SLEEPING: {val: 34, key: "Sleeping"},
  TD_SYNC_ACC_LOW_X: buildTDTypeObj(35, "TimeDomainSyncAccLowX", X, S_NEW, LOW, TDExt.ACC_RMS, SYNCED),
  TD_SYNC_ACC_LOW_Y: buildTDTypeObj(36, "TimeDomainSyncAccLowY", Y, S_NEW, LOW, TDExt.ACC_RMS, SYNCED),
  TD_SYNC_ACC_LOW_Z: buildTDTypeObj(37, "TimeDomainSyncAccLowZ", Z, S_NEW, LOW, TDExt.ACC_RMS, SYNCED),
  TD_SYNC_ACC_HIGH_X: buildTDTypeObj(38, "TimeDomainSyncAccHighX", X, S_NEW, HIGH, TDExt.ACC_RMS, SYNCED),
  TD_SYNC_ACC_HIGH_Y: buildTDTypeObj(39, "TimeDomainSyncAccHighY", Y, S_NEW, HIGH, TDExt.ACC_RMS, SYNCED),
  TD_SYNC_ACC_HIGH_Z: buildTDTypeObj(40, "TimeDomainSyncAccHighZ", Z, S_NEW, HIGH, TDExt.ACC_RMS, SYNCED),
  TD_SYNC_VEL_LOW_X: buildTDTypeObj(41, "TimeDomainSyncVelLowX", X, S_NEW, LOW, TDExt.OLD_VEL_RMS, SYNCED),
  TD_SYNC_VEL_LOW_Y: buildTDTypeObj(42, "TimeDomainSyncVelLowY", Y, S_NEW, LOW, TDExt.OLD_VEL_RMS, SYNCED),
  TD_SYNC_VEL_LOW_Z: buildTDTypeObj(43, "TimeDomainSyncVelLowZ", Z, S_NEW, LOW, TDExt.OLD_VEL_RMS, SYNCED),
  TD_SYNC_VEL_HIGH_X: buildTDTypeObj(44, "TimeDomainSyncVelHighX", X, S_NEW, HIGH, TDExt.OLD_VEL_RMS, SYNCED),
  TD_SYNC_VEL_HIGH_Y: buildTDTypeObj(45, "TimeDomainSyncVelHighY", Y, S_NEW, HIGH, TDExt.OLD_VEL_RMS, SYNCED),
  TD_SYNC_VEL_HIGH_Z: buildTDTypeObj(46, "TimeDomainSyncVelHighZ", Z, S_NEW, HIGH, TDExt.OLD_VEL_RMS, SYNCED),
  TD_SYNC_PEAK_LOW_X: buildTDTypeObj(47, "TimeDomainSyncPeakLowX", X, S_NEW, LOW, TDExt.PEAK, SYNCED),
  TD_SYNC_PEAK_LOW_Y: buildTDTypeObj(48, "TimeDomainSyncPeakLowY", Y, S_NEW, LOW, TDExt.PEAK, SYNCED),
  TD_SYNC_PEAK_LOW_Z: buildTDTypeObj(49, "TimeDomainSyncPeakLowZ", Z, S_NEW, LOW, TDExt.PEAK, SYNCED),
  TD_SYNC_PEAK_HIGH_X: buildTDTypeObj(50, "TimeDomainSyncPeakHighX", X, S_NEW, HIGH, TDExt.PEAK, SYNCED),
  TD_SYNC_PEAK_HIGH_Y: buildTDTypeObj(51, "TimeDomainSyncPeakHighY", Y, S_NEW, HIGH, TDExt.PEAK, SYNCED),
  TD_SYNC_PEAK_HIGH_Z: buildTDTypeObj(52, "TimeDomainSyncPeakHighZ", Z, S_NEW, HIGH, TDExt.PEAK, SYNCED),
  PHASE_AND_PEAKS_FIXED: {val: 53, key: "PhaseAndPeaksFixed"},
  TD_SYNC_VEL_FIX_LOW_X: buildTDTypeObj(54, "TimeDomainSyncVelFixLowX", X, S_NEW, LOW, TDExt.VEL_RMS, SYNCED),
  TD_SYNC_VEL_FIX_LOW_Y: buildTDTypeObj(55, "TimeDomainSyncVelFixLowY", Y, S_NEW, LOW, TDExt.VEL_RMS, SYNCED),
  TD_SYNC_VEL_FIX_LOW_Z: buildTDTypeObj(56, "TimeDomainSyncVelFixLowZ", Z, S_NEW, LOW, TDExt.VEL_RMS, SYNCED),
  TD_SYNC_VEL_FIX_HIGH_X: buildTDTypeObj(57, "TimeDomainSyncVelFixHighX", X, S_NEW, HIGH, TDExt.VEL_RMS, SYNCED),
  TD_SYNC_VEL_FIX_HIGH_Y: buildTDTypeObj(58, "TimeDomainSyncVelFixHighY", Y, S_NEW, HIGH, TDExt.VEL_RMS, SYNCED),
  TD_SYNC_VEL_FIX_HIGH_Z: buildTDTypeObj(59, "TimeDomainSyncVelFixHighZ", Z, S_NEW, HIGH, TDExt.VEL_RMS, SYNCED),

  // Tau TWF Acceleration types
  SCREEN_TD_SYNC_LOW_X: buildTDTypeObj(61, "ScreenTimeDomainSyncLowX", X, S_NEW, LOW, TDExt.ACC_RMS, SYNCED),
  SCREEN_TD_SYNC_LOW_Y: buildTDTypeObj(62, "ScreenTimeDomainSyncLowY", Y, S_NEW, LOW, TDExt.ACC_RMS, SYNCED),
  SCREEN_TD_SYNC_LOW_Z: buildTDTypeObj(63, "ScreenTimeDomainSyncLowZ", Z, S_NEW, LOW, TDExt.ACC_RMS, SYNCED),

  // 10 kHz data types
  TD_16384_SYNC_ACC_LOW_X: buildTDTypeObj(
    70,
    "TimeDomain16384SyncAccLowX",
    X,
    TDSize.HZ_16384,
    LOW,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_ACC_LOW_Y: buildTDTypeObj(
    71,
    "TimeDomain8192SyncAccLowY",
    Y,
    TDSize.HZ_8192,
    LOW,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_ACC_LOW_Z: buildTDTypeObj(
    72,
    "TimeDomain8192SyncAccLowZ",
    Z,
    TDSize.HZ_8192,
    LOW,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  TD_16384_SYNC_VEL_LOW_X: buildTDTypeObj(
    73,
    "TimeDomain16384SyncVelLowX",
    X,
    TDSize.HZ_16384,
    LOW,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_VEL_LOW_Y: buildTDTypeObj(
    74,
    "TimeDomain8192SyncVelLowY",
    Y,
    TDSize.HZ_8192,
    LOW,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_VEL_LOW_Z: buildTDTypeObj(
    75,
    "TimeDomain8192SyncVelLowZ",
    Z,
    TDSize.HZ_8192,
    LOW,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  TD_16384_SYNC_PEAK_LOW_X: buildTDTypeObj(
    76,
    "TimeDomain16384yncPeakLowX",
    X,
    TDSize.HZ_16384,
    LOW,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_PEAK_LOW_Y: buildTDTypeObj(
    77,
    "TimeDomain8192SyncPeakLowY",
    Y,
    TDSize.HZ_8192,
    LOW,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_PEAK_LOW_Z: buildTDTypeObj(
    78,
    "TimeDomain8192SyncPeakLowZ",
    Z,
    TDSize.HZ_8192,
    LOW,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  TD_8192_SYNC_ACC_HIGH_X: buildTDTypeObj(
    79,
    "TimeDomain8192SyncAccHighX",
    X,
    TDSize.HZ_8192,
    HIGH,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_ACC_HIGH_Y: buildTDTypeObj(
    80,
    "TimeDomain8192SyncAccHighY",
    Y,
    TDSize.HZ_8192,
    HIGH,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_ACC_HIGH_Z: buildTDTypeObj(
    81,
    "TimeDomain8192SyncAccHighZ",
    Z,
    TDSize.HZ_8192,
    HIGH,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  TD_8192_SYNC_VEL_HIGH_X: buildTDTypeObj(
    82,
    "TimeDomain8192SyncVelHighX",
    X,
    TDSize.HZ_8192,
    HIGH,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_VEL_HIGH_Y: buildTDTypeObj(
    83,
    "TimeDomain8192SyncVelHighY",
    Y,
    TDSize.HZ_8192,
    HIGH,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_VEL_HIGH_Z: buildTDTypeObj(
    84,
    "TimeDomain8192SyncVelHighZ",
    Z,
    TDSize.HZ_8192,
    HIGH,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  TD_8192_SYNC_PEAK_HIGH_X: buildTDTypeObj(
    85,
    "TimeDomain8192SyncPeakHighX",
    X,
    TDSize.HZ_8192,
    HIGH,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_PEAK_HIGH_Y: buildTDTypeObj(
    86,
    "TimeDomain8192SyncPeakHighY",
    Y,
    TDSize.HZ_8192,
    HIGH,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_PEAK_HIGH_Z: buildTDTypeObj(
    87,
    "TimeDomain8192SyncPeakHighZ",
    Z,
    TDSize.HZ_8192,
    HIGH,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    !EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  // 10 kHz data types with increased packet size
  TD_16384_SYNC_ACC_LOW_X_EXTRA_BYTES: buildTDTypeObj(
    88,
    "TimeDomain16384SyncAccLowXSampleTime",
    X,
    TDSize.HZ_16384,
    LOW,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_ACC_LOW_Y_EXTRA_BYTES: buildTDTypeObj(
    89,
    "TimeDomain8192SyncAccLowYSampleTime",
    Y,
    TDSize.HZ_8192,
    LOW,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_ACC_LOW_Z_EXTRA_BYTES: buildTDTypeObj(
    90,
    "TimeDomain8192SyncAccLowZSampleTime",
    Z,
    TDSize.HZ_8192,
    LOW,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  TD_16384_SYNC_VEL_LOW_X_EXTRA_BYTES: buildTDTypeObj(
    91,
    "TimeDomain16384SyncVelLowXSampleTime",
    X,
    TDSize.HZ_16384,
    LOW,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_VEL_LOW_Y_EXTRA_BYTES: buildTDTypeObj(
    92,
    "TimeDomain8192SyncVelLowYSampleTime",
    Y,
    TDSize.HZ_8192,
    LOW,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_VEL_LOW_Z_EXTRA_BYTES: buildTDTypeObj(
    93,
    "TimeDomain8192SyncVelLowZSampleTime",
    Z,
    TDSize.HZ_8192,
    LOW,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  TD_16384_SYNC_PEAK_LOW_X_EXTRA_BYTES: buildTDTypeObj(
    94,
    "TimeDomain16384SyncPeakLowXSampleTime",
    X,
    TDSize.HZ_16384,
    LOW,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_PEAK_LOW_Y_EXTRA_BYTES: buildTDTypeObj(
    95,
    "TimeDomain8192SyncPeakLowYSampleTime",
    Y,
    TDSize.HZ_8192,
    LOW,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_PEAK_LOW_Z_EXTRA_BYTES: buildTDTypeObj(
    96,
    "TimeDomain8192SyncPeakLowZSampleTime",
    Z,
    TDSize.HZ_8192,
    LOW,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  TD_8192_SYNC_ACC_HIGH_X_EXTRA_BYTES: buildTDTypeObj(
    97,
    "TimeDomain8192SyncAccHighXSampleTime",
    X,
    TDSize.HZ_8192,
    HIGH,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_ACC_HIGH_Y_EXTRA_BYTES: buildTDTypeObj(
    98,
    "TimeDomain8192SyncAccHighYSampleTime",
    Y,
    TDSize.HZ_8192,
    HIGH,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_ACC_HIGH_Z_EXTRA_BYTES: buildTDTypeObj(
    99,
    "TimeDomain8192SyncAccHighZSampleTime",
    Z,
    TDSize.HZ_8192,
    HIGH,
    TDExt.ACC_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  TD_8192_SYNC_VEL_HIGH_X_EXTRA_BYTES: buildTDTypeObj(
    100,
    "TimeDomain8192SyncVelHighXSampleTime",
    X,
    TDSize.HZ_8192,
    HIGH,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_VEL_HIGH_Y_EXTRA_BYTES: buildTDTypeObj(
    101,
    "TimeDomain8192SyncVelHighYSampleTime",
    Y,
    TDSize.HZ_8192,
    HIGH,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_VEL_HIGH_Z_EXTRA_BYTES: buildTDTypeObj(
    102,
    "TimeDomain8192SyncVelHighZSampleTime",
    Z,
    TDSize.HZ_8192,
    HIGH,
    TDExt.VEL_RMS,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  TD_8192_SYNC_PEAK_HIGH_X_EXTRA_BYTES: buildTDTypeObj(
    103,
    "TimeDomain8192SyncPeakHighXSampleTime",
    X,
    TDSize.HZ_8192,
    HIGH,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_PEAK_HIGH_Y_EXTRA_BYTES: buildTDTypeObj(
    104,
    "TimeDomain8192SyncPeakHighYSampleTime",
    Y,
    TDSize.HZ_8192,
    HIGH,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),
  TD_8192_SYNC_PEAK_HIGH_Z_EXTRA_BYTES: buildTDTypeObj(
    105,
    "TimeDomain8192SyncPeakHighZSampleTime",
    Z,
    TDSize.HZ_8192,
    HIGH,
    TDExt.PEAK,
    SYNCED,
    VibInputDataUnit.ACCELEROMETER,
    EXTRA_6_BYTES_PAYLOAD,
    AxisTypes.AXIS_Z,
  ),

  STARTUP_EXTRA_BYTES: {val: 106, key: "Startup", extendedPayload: EXTRA_6_BYTES_PAYLOAD},
  SW_VERSION_EXTRA_BYTES: {val: 107, key: "SoftwareVersion", extendedPayload: EXTRA_6_BYTES_PAYLOAD},
  SLEEPING_EXTRA_BYTES: {val: 108, key: "Sleeping", extendedPayload: EXTRA_6_BYTES_PAYLOAD},

  DEFAULT: {val: -1, key: "Default"},
};

module.exports.TimeDomainResolution = TimeDomainResolution;
module.exports.AxisTypes = AxisTypes;
module.exports.AxisArray = [AxisTypes.AXIS_X, AxisTypes.AXIS_Y, AxisTypes.AXIS_Z];

module.exports.ScaleBits = {
  SCALE_BIT_2G: {val: 0x00, scale: 16, max: 2000, key: "scale_2g"},
  SCALE_BIT_4G: {val: 0x40, scale: 32, max: 4000, key: "scale_4g"},
  SCALE_BIT_8G: {val: 0x80, scale: 64, max: 8000, key: "scale_8g"},
  SCALE_BIT_16G: {val: 0xc0, scale: 128, max: 16000, key: "scale_16g"},
};

module.exports.MachineDirection = VibrationDirection;
module.exports.SensorOrientation = SensorOrientation;
module.exports.SensorOrientationArray = SensorOrientationArray;

module.exports.isAxisType = function (axisStr) {
  for (let axisTypeKey in AxisTypes) {
    if (AxisTypes.hasOwnProperty(axisTypeKey) && AxisTypes[axisTypeKey] === axisStr) {
      return true;
    }
  }
  return false;
};

module.exports.lookupType = function (enumeration, val, byKey) {
  let returnVal = null;
  Object.keys(enumeration).forEach(function (key) {
    if (!byKey && enumeration[key].val === val) {
      returnVal = enumeration[key].key;
    } else if (byKey && enumeration[key].key === val) {
      returnVal = enumeration[key].val;
    }
  });
  return returnVal;
};

module.exports.isInEnum = function (enumeration, value) {
  if (value == null || enumeration == null) {
    return false;
  }
  for (let enumKey of Object.values(enumeration)) {
    if (enumKey === value) {
      return true;
    }
  }
  return false;
};

module.exports.enumToList = function toCsv(obj) {
  let str = "";
  let first = true;
  for (let elKey in obj) {
    if (first) {
      first = false;
    } else {
      str += ", ";
    }
    if (obj.hasOwnProperty(elKey)) {
      str += obj[elKey];
    }
  }
  return str;
};

module.exports.isOldSensorStructure = function (type) {
  return type === module.exports.Gen2DataType.OLD_AXIS_PEAKS_TOP_5_2G.val;
};

module.exports.isPeakType = function (type) {
  return (
    type >= module.exports.Gen2DataType.OLD_AXIS_PEAKS_TOP_5_2G.val &&
    type <= module.exports.Gen2DataType.AXIS_PEAKS_TOP_5_16G.val
  );
};

function getDataTypeByVal(type) {
  for (let typeKey in module.exports.Gen2DataType) {
    if (module.exports.Gen2DataType.hasOwnProperty(typeKey) && module.exports.Gen2DataType[typeKey].val === type) {
      return module.exports.Gen2DataType[typeKey];
    }
  }
  return null;
}

module.exports.lookupTypeProperty = function (type, key) {
  const properties = getDataTypeByVal(type);
  return properties?.[key];
};

module.exports.isHighResTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.resolution === Resolution.HIGH;
};

module.exports.isLowResTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.resolution === Resolution.LOW;
};

module.exports.isTimeDomainXType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.axis === Axis.AXIS_X;
};

module.exports.isTimeDomainYType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.axis === Axis.AXIS_Y;
};

module.exports.isTimeDomainZType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.axis === Axis.AXIS_Z;
};

module.exports.isTDAccRmsType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.accRms;
};

module.exports.isTDOldVelRmsType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.oldVelRms;
};

module.exports.isTDVelRmsType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.velRms;
};

module.exports.isTDPeakType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.peak;
};

module.exports.isAccelerometerDataType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.vibInputDataUnit === VibInputDataUnit.ACCELEROMETER;
};

module.exports.hasExtraDataType = function (type) {
  return (
    module.exports.isTDAccRmsType(type) ||
    module.exports.isTDOldVelRmsType(type) ||
    module.exports.isTDPeakType(type) ||
    module.exports.isTDVelRmsType(type)
  );
};

module.exports.is4096LowTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return (
    dataType != null &&
    dataType.resolution === Resolution.LOW &&
    dataType.size === TimeDomainAxisSize.TIME_DOMAIN_HIGH_RES
  );
};

module.exports.is4096HighTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return (
    dataType != null &&
    dataType.resolution === Resolution.HIGH &&
    dataType.size === TimeDomainAxisSize.TIME_DOMAIN_HIGH_RES
  );
};

module.exports.is4096NoRmsTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.size === TDSize.NEW && !dataType.accRms && !dataType.velRms && !dataType.peak;
};

module.exports.is4096TimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.size === TDSize.NEW;
};

module.exports.is16384TimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.size === TDSize.HZ_16384;
};

module.exports.is16384LowTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return (
    dataType != null &&
    dataType.resolution === Resolution.LOW &&
    dataType.size === TimeDomainAxisSize.TIME_DOMAIN_16384_HZ
  );
};

module.exports.is16384HighTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return (
    dataType != null &&
    dataType.resolution === Resolution.HIGH &&
    dataType.size === TimeDomainAxisSize.TIME_DOMAIN_16384_HZ
  );
};

module.exports.is8192TimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.size === TDSize.HZ_8192;
};

module.exports.is8192LowTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return (
    dataType != null &&
    dataType.resolution === Resolution.LOW &&
    dataType.size === TimeDomainAxisSize.TIME_DOMAIN_8192_HZ
  );
};

module.exports.is8192HighTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return (
    dataType != null &&
    dataType.resolution === Resolution.HIGH &&
    dataType.size === TimeDomainAxisSize.TIME_DOMAIN_8192_HZ
  );
};

module.exports.isSyncTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.sync;
};

module.exports.isTimeDomainType = function (type) {
  let dataType = getDataTypeByVal(type);
  return (
    dataType != null &&
    dataType.hasOwnProperty("resolution") &&
    dataType.hasOwnProperty("size") &&
    dataType.hasOwnProperty("axis")
  );
};

module.exports.isTauTimeWaveformType = function (type) {
  return [
    module.exports.Gen2DataType.SCREEN_TD_SYNC_LOW_X.val,
    module.exports.Gen2DataType.SCREEN_TD_SYNC_LOW_Y.val,
    module.exports.Gen2DataType.SCREEN_TD_SYNC_LOW_Z.val,
  ].includes(type);
};

module.exports.getTypeSyncAxis = function (type) {
  let dataType = getDataTypeByVal(type);
  const defaultSyncAxis = AxisTypes.AXIS_X;
  if (dataType == null) {
    return defaultSyncAxis;
  }
  if (!dataType.hasOwnProperty("syncAxis")) {
    return defaultSyncAxis;
  }

  return dataType["syncAxis"];
};

module.exports.isSwVersionType = function (type) {
  return (
    type === module.exports.Gen2DataType.SW_VERSION.val ||
    type === module.exports.Gen2DataType.SW_VERSION_EXTRA_BYTES.val
  );
};

module.exports.isPeakToPeakType = function (type) {
  return type === module.exports.Gen2DataType.PEAK_TO_PEAK.val;
};

module.exports.isPhaseAndPeaksType = function (type) {
  return module.exports.isPhaseType(type) || module.exports.isPhaseFixedType(type);
};

module.exports.isPhaseType = function (type) {
  return type === module.exports.Gen2DataType.PHASE_AND_PEAKS.val;
};

module.exports.isPhaseFixedType = function (type) {
  return (
    type === module.exports.Gen2DataType.PHASE_AND_PEAKS_FIXED.val ||
    type === module.exports.Gen2DataType.PHASE_AND_PEAKS_FIXED_WITH_PEAK_FIX.val
  );
};

module.exports.isPhaseAndPeaksWithPeakFixType = function (type) {
  return type === module.exports.Gen2DataType.PHASE_AND_PEAKS_FIXED_WITH_PEAK_FIX.val;
};

module.exports.isSleepType = function (type) {
  return (
    type === module.exports.Gen2DataType.SLEEPING.val || type === module.exports.Gen2DataType.SLEEPING_EXTRA_BYTES.val
  );
};

module.exports.isExtendedPayloadType = function (type) {
  let dataType = getDataTypeByVal(type);
  return dataType != null && dataType.hasOwnProperty("extendedPayload") && dataType.extendedPayload === true;
};

module.exports.peakCountForType = function (typeString) {
  if (
    typeString === module.exports.Gen2DataType.AXIS_PEAKS_TOP_5_2G.key ||
    typeString === module.exports.Gen2DataType.AXIS_PEAKS_TOP_5_4G.key ||
    typeString === module.exports.Gen2DataType.AXIS_PEAKS_TOP_5_8G.key ||
    typeString === module.exports.Gen2DataType.AXIS_PEAKS_TOP_5_16G.key
  ) {
    return 5;
  }

  // default case, return 5
  return 5;
};

module.exports.SamplingFreqOptions = {
  REAL: "real",
  TARGET: "target",
};

module.exports.TimeDomainState = {
  PROCESSING: "processing",
  COMPLETE: "complete",
};

module.exports.ConversionProperties = ["device_id", ":device_id", "entry_id", ":entry_id", "sensor_id", ":sensor_id"];

module.exports.PresentableDataTypes = {
  // from raw endpoints
  X_AXIS: "x_axis_data", // frequency, 0 high, 0 low
  Y_AXIS: "y_axis_data", // frequency, high, low
  Z_AXIS: "z_axis_data", // frequency, high, low
  INFO: "info_data", // mode, raw_temperature, vbatt, software (major, patch, swVerf)
  MAX_MIN_DIFF: "max_min_difference",
  PHASE_AMP_Y: "y_phase_amplitude",

  // From calculated endpoints
  CALCULATED: "calculated_data",
  X_DISPLACEMENT: "displacement_x",
  Y_DISPLACEMENT: "displacement_y",
  Z_DISPLACEMENT: "displacement_z",
  PHASE_ANGLE: "phase_angle",
  STROKE_ANGLE: "stroke_angle",
  STROKE_RATIO: "stroke_ratio",
  ROTATIONAL_SPEED: "rotational_speed",
  TEMPERATURE: "temperature",
  TEMP_DELTA: "temp_delta",
  RAW_TEMPERATURE: "raw_temperature",
  VBATT: "vbatt",
  MODE: "mode",
  TYPE: "data_type",
  VALIDITY: "validity",
  STROKE_LENGTH: "stroke_length",
  ROTATION: "rotation",
  VOLTAGE: "voltage",
  DEFLECTION: "deflection",
  X_VELOCITY: "velocity_x",
  Y_VELOCITY: "velocity_y",
  Z_VELOCITY: "velocity_z",
  X_ACCELERATION: "acceleration_x",
  Y_ACCELERATION: "acceleration_y",
  Z_ACCELERATION: "acceleration_z",
  DIFF_DISPLACEMENT: "displacement_diff",

  // receiver data
  RECEIVER_TEMP: "temperature",
  HUMIDITY: "humidity",
  SIGNAL_STRENGTH: "signal_strength",
  SIGNAL_QUALITY: "signal_quality",

  // location data
  COUNTRY_CODE: "country_code",
  COUNTRY_NAME: "country",
  TIMEZONE: "timezone",
  CITY_NAME: "city_name",
  LAT_AND_LONG: "lat_long",
  ACCURACY: "accuracy",

  // device data
  LOCATION: "location",
  DEVICE_NAME: "name",
  DEVICE_ONLINE: "online",
  LAST_SEEN: "last_seen",
  LOCATION_NAME: "name",
  GATEWAY_ID: "gateway_id",
  OWNER_ID: "owner_id",
  OWNER_TYPE: "owner_type",
  GATEWAYS_TO_ALLOCATE: "gateways_to_allocate",
  GATEWAYS_TO_UNALLOCATE: "gateways_to_unallocate",
  IN_USE: "in_use",

  // gen2 data
  AXIS_X_PEAKS: "x_axis_peaks",
  AXIS_Y_PEAKS: "y_axis_peaks",
  AXIS_Z_PEAKS: "z_axis_peaks",
  FREQUENCY: "frequency",
  AMPLITUDE: "amplitude",
  VELOCITY: "velocity",
  DISPLACEMENT: "displacement",
  HIGH: "high",
  LOW: "low",
  RMS: "rms",
  CREST_FACTOR: "crest_factor",
  PEAK_TO_PEAK: "peak_to_peak",
  TD_ACC_PEAK: "td_acc_peak",
  ENVELOPES: "envelopes",
  CONFIG: "config",
  ENVELOPE_METHOD: "envelopeMethod",
  ENVELOPE_ACC_RMS: "envelope_acc_rms",
  ENVELOPE_TWF_PEAK: "envelope_twf_peak",
  STATUS: "status",
  RESERVED: "reserved",
  SW_VERSION: "sw_version",
  SOFTWARE: "software",
  SENSOR_ID: "sensor_id",
  BLOCK_NUMBER: "block_number",
  POINT_SENSOR_TYPE: "sensor_type",
  CREATE_HARDWARE: "create_hardware",
  CHANNELS: "channels",

  // gen2 envelopes
  RECEIVER_DATA: "receiver_data",
  SENSOR_DATA: "sensor_data",
  AVERAGED_DATA: "averaged_data",
  POINT_DATA: "point_data",

  // cloud API
  ENTRY_COUNT: "entry_count",
  TIME_DOMAIN_DATA: "values",
  DATA_RATE: "data_rate",
  TIMESTAMP: "timestamp",
  TIMESTAMP_END: "timestamp_end",
  TIME_RANGE: "time_range",
  SAMPLE_FREQ: "sampling_frequency",
  FIRST_BLOCK_NO: "first_block_number",
  LAST_BLOCK_NO: "last_block_number",
  EXP_COMPLETION: "expected_completion",
  STATE: "state",
  SENSOR_MODE: "mode",
  STARTED_AT: "started_at",

  // peak to peak data
  AXIS_DELTAS: "axis_deltas",
  PEAK_DELTA: "delta",
  PEAK_MAX: "max",
  PEAK_MIN: "min",
  MAX_DELTA: "max_delta",

  // user preferences
  PREFERENCES: "preferences",
  SENSOR_RENAMES: "sensor_renames",
  RECEIVER_RENAMES: "receiver_renames",
  APPEND: "append",
  REMOVE: "remove",
  PRIVILEGES: "privileges",

  AVERAGED_TYPE: "averaged_type",

  // alarms
  ALARM_ID: "alarm_id",
  ALARM_SITE: "site_name",
  ALARM_NAME: "name",
  ALARM_DEVICE: "device_name",
  ALARM_SEND_EMAIL: "send_email",
  ALARM_SEND_SMS: "send_sms",
  ALARM_SENSOR: "sensor_id",
  ALARM_TYPE: "type",
  ALARM_CREATOR: "creator",
  ALARM_IS_ACTIVE: "is_active",
  ALARM_OFF_TIME: "inactive_timestamp",
  ALARM_PROPERTY: "property",
  ALARM_VALUES: "values",
  ALARM_OPERATOR: "operator",
  ALARM_TRIGGER_STRATEGY: "trigger_strategy",
  ALARM_USERNAMES: "usernames",
  ALARM_USERNAMES_TO_REMOVE: "usernames_to_remove",
  ALARM_USERNAMES_TO_ADD: "usernames_to_add",
  ALARM_TARGET: "target",
  ALARM_TARGET_TYPE: "target_type",
  ALARM_TARGETS: "targets",
  ALARM_DATA: "data",
  ALARM_INACTIVE: "include_inactive",

  SENSOR_LIST: "sensors",
  SENSOR_DEVICE: "device_name",
  MAC_ADDRESS: "mac_address",

  SENSOR_TYPE: "type",
  DESCRIPTION: "description",
  SENSOR_CONFIG: "config",
  SENSOR_CONFIG_HISTORY: "config_history",
  SENSOR_FIRMWARE_VERSION: "firmware_version",

  PHASE_DATA: "phase_data",
  PHASE: "phase",
  AVERAGE: "avg",
  MINIMUM: "min",
  MAXIMUM: "max",
  STD_DEV: "std_dev",
  AXIS_X_SAMPLES: "x_samples",
  AXIS_Y_SAMPLES: "y_samples",
  AXIS_Z_SAMPLES: "z_samples",

  SENSORS: "sensors",
  CREATED_AT: "created_at",

  ORIENTATION: "orientation",
  RPM: "rpm",
  DATA: "data",
  CURRENT_TYPE: "current_type",

  ON_PERCENTAGE: "on_percentage",
  OFF_PERCENTAGE: "off_percentage",
  MISSED_PACKETS: "missed_packets",

  SITE_NAME: "site_name",
  COORDINATES: "coordinates",
  ADDRESS: "address",
  DEVICES: "devices",
  LOCATIONS: "locations",
  DISPLAY_NAME: "display_name",
  SITEMAP_URL: "sitemap_url",
  NOTES: "notes",

  USERNAME: "username",
  COMPANY: "company",
  PARTNER_GROUP: "partner_group",
  PARTNER_TYPE: "partner_type",
  FULL_COMPANY: "full_company",
  USER: "user",
  ADMIN: "admin",
  PARTNER: "partner",
  MANAGER: "manager",
  COMPANY_MANAGER: "company_manager",
  INSTALLER: "installer",
  FAULT_LABELLER: "fault_labeller",
  VIEW_ONLY: "view_only",
  SITES: "sites",
  PRIMARY_COMPANY: "primary_company",
  COMPANIES: "companies",
  API_KEY_ID: "api_key_id",
  TOKEN: "token",
  WELCOME_EMAIL_TYPE: "welcome_email_type",

  NAME: "name",
  LOCATION_TYPE: "type",
  LOCATION_EXTRA: "extra",

  RESOLUTION: "resolution",
  AXIS: "axis",
  POWER_BANDS: "bands",
  CRITICAL_LEVEL: "critical",
  WARNING_LEVEL: "warning",

  USERNAMES: "usernames",

  RESTART_REASON: "restart_reason",
  LATEST_RESTART: "latest_restart",
  BUILD_INFO: "build_info",
  UNCLAIMED: "unclaimed",

  EVENT_MACHINE: "machine_id",
  EVENT_NAME: "name",
  EVENT_DESC: "description",
  EVENT_METADATA: "metadata",
  EVENT_ID: "event_id",
  EVENT_CREATED_BY: "created_by",
  EVENT_COMPANY: "company",

  REPORTS: "reports",
  REPORT_NAME: "name",
  REPORT_VERSION: "version",
  REPORT_CONTENT: "content",
  LAST_UPDATED: "last_updated",
  REPORT_EDITED_BY: "last_edited_by",
  REPORT_CREATED_BY: "created_by",

  MACHINE_NAME: "name",
  MACHINE_TYPE: "type",
  MACHINE_SUBTYPE: "subtype",
  MACHINE_LOCATIONS: "locations",
  MACHINE_ID: "machine_id",
  MACHINE_POSITION: "position",
  MACHINES: "machines",
  MACHINE_ONLINE: "online",
  MACHINE_BASE: "machine_base",
  OLD_MACH_POINTS: "test_points",
  THERMAL_POINTS: "thermal_points",
  MACH_POINTS: "points",
  POINT_STEP: "step",
  OA_LEVELS: "oa_levels",

  MACHINE_TYPE_ID: "machine_type_id",
  MACHINE_TYPE_CATEGORY: "category",
  MACHINE_TYPE_PARENT_ID: "parent_id",
  MACHINE_SUBTYPE_ID: "machine_subtype_id",
  MACHINE_SUBTYPE_DIAGRAM_ID: "machine_subtype_diagram_id",

  STATUS_INDICATOR_LEVELS: "status_indicator_levels",

  NOTCH_FILTER: "notch_filter",
  NOTCH_WIDTH: "width",
  NOTCH_FREQUENCY: "frequency",

  AXIS_RMS: "axis_rms",
  AXIS_VEL_RMS: "axis_vel_rms",
  AXIS_PEAK: "axis_peak",
  SOFTWARE_SHA: "software_sha",
  SCALE: "scale",
  TIME_DOMAIN_SCALE: "time_domain_scale",
  SLEEPING: "sleeping",

  NEW_SENSOR_ID: "new_sensor_id",
  OLD_SENSOR_ID: "old_sensor_id",
  OLD_DEVICE_NAME: "old_device_name",
  SENSOR_MOVING: "moving",
  REAL_SENSOR_ID: "real_sensor_id",
  DISABLED: "disabled",

  SCREEN_DATA: "screen_data",

  IMAGE_ID: "image_id",
  MIMETYPE: "mimetype",
  IMAGE_WIDTH: "width",
  IMAGE_HEIGHT: "height",
  IMAGE_LIST: "image_list",
  IMAGE_URL: "url",
  BASE_SITE: "site_name",
  BASE_NAME: "image_name",
  EXPIRY: "expiry",

  PASSWORD: "password",
  NEW_PASSWORD: "new_password",
  UNITS: "units",
  EMAIL: "email",
  PHONE: "phone",
  ALLOW_API_KEY: "allow_api_key",
  MFA: "mfa",
  MFA_ENABLED: "mfa_enabled",
  MFA_SECRET: "mfa_secret",
  CHI_USER: "chi_user",

  RESET_TOKEN: "token",
  COMPANY_LOGO: "company_logo",
  FIRST_LOGIN: "first_login",

  KEYS: "keys",
  ITEMS: "items",

  ACC_RMS: "acc_rms",
  VEL_RMS: "vel_rms",
  DISP_RMS: "disp_rms",
  IS_RUNNING: "is_running",

  RX_DIAGNOSTICS: "rx_diagnostics",
  TOTAL_PACKETS: "total_packets",
  TEN_MIN_PACKETS: "ten_min_packets",
  TOTAL_DISCARDED: "total_discarded",
  TEN_MIN_DISCARDED: "ten_min_discarded",
  TOTAL_FILTERED: "total_filtered",
  TEN_MIN_FILTERED: "ten_min_filtered",
  AVG_SPACING: "avg_spacing",
  MIN_SPACING: "minimum_spacing",
  MIN_SPACING_ID: "min_spacing_id",
  TOTAL_BAD_CRC: "total_bad_crc",

  TX_DIAGNOSTICS: "tx_diagnostics",
  AVG_VALIDITY: "avg_validity",
  TEN_MIN_VALIDITY: "ten_min_validity",
  AVG_RSSI: "avg_rssi",
  TEN_MIN_RSSI: "ten_min_rssi",

  FILTERED_SENSORS: "filtered_ids",
  LATEST_RSSI: "latest_rssi",

  POINT_ID: "point_id",

  MACHINE: "machine",
  POINT: "point",

  FORCE_LOGOUT: "force_logout",

  USER_SESSIONS: "sessions",
  SESSION_TOKEN: "token",
  SESSION_EXPIRED: "expired",
  TIME_ACTIVE: "time_active",
  API_KEYS: "apikeys",

  OTHER_CALCS: "other_calcs",

  GATEWAY_DATA: "gateway_data",
  GATEWAY_TYPE: "gateway_type",

  POINT_IDS: "point_ids",

  UPDATE_SW_FILTER: "update_sw_filter",
  UPDATE_HW_FILTER: "update_hw_filter",

  SENSOR_IDS: "sensor_ids",
  POINT_DISABLED: "disabled",

  BEARING_ID: "bearing_id",
  BEARING_MANUFACTURER: "manufacturer",
  BEARING_NUMBER: "number",
  BEARING_TYPE: "type",
  BEARING_BPFI: "bpfi",
  BEARING_BPFO: "bpfo",
  BEARINF_BSF: "bsf",
  BEARING_FTF: "ftf",
  BEARING_ACCESS_LEVEL: "access_required",

  GO_BACK: "go_back",
  APPROX_COUNT: "approx_count",
  ENTRY_ID: "entry_id",
  COUNT: "count",
  MAX_BLOCKS: "max_blocks",

  TEMPLATE_NAME: "name",
  TEMPLATE: "template",
  TEMPLATE_COMPANY: "company",
  TEMPLATE_TYPE: "type",
  FULL_SCHEMA: "full",
  TEMPLATE_ID: "template_id",
  TEMPLATE_GLOBAL: "global",
  TEMPLATES: "templates",
  SCHEMA: "schema",

  ADD_USERS: "user_add",
  REMOVE_USERS: "user_remove",
  PROPERTIES: "properties",
  TIME_WAVEFORM_VALIDITY: "time_waveform_validity",
  PERCENTAGE: "percentage",
  METADATA: "metadata",
  UPDATE_BANDS: "update_bands",

  TRENDABLE: "trendable",
  TARGET: "target",
  SCORES: "scores",
  EVENTS: "events",
  EVENT_TYPE: "type",

  CAMERA_STREAM: "camera_stream",
  IMAGE_MODE: "image_mode",

  COMPANY_ID: "company_id",
  SITE_NAMES: "site_names",
  ASSET_LABEL: "asset_label",
  ASSET_LABELS: "asset_labels",

  FULL_OBJECT: "full",

  OTHER_QUEUE_TYPE: "type",

  THERMAL_APP_ID: "app_id",
  THERMAL_IMAGE: "image",
  THERMAL_BOX: "boxes",
  THERMAL_BOX_NAME: "box",
  THERMAL_TEMPERATURE: "temperature",
  THERMAL_TYPE: "type",
  THERMAL_MBOX: "mbox",
  THERMAL_MBOX_NAME: "1",
  THERMAL_AMBIENT_TEMPERATURE: "ambTemp",
  THERMAL_X_COORDINATE: "x",
  THERMAL_Y_COORDINATE: "y",

  CHI_EVENT_ID: "event_id",
  CHI_SENSOR: "sensor_id",
  CHI_POSITION: "position",
  CHI_FFT_SIZE: "fft_size",
  CHI_DATA_ID: "data_id",
  CHI_VIEWING_ACCESS: "chi_viewing_access",

  ALTERATION_TYPE: "type",
  ALTERATION_ID: "alter_id",

  LICENSE_EXPIRY: "license_expiry",
  HARDWARE_ID: "hw_id",
  HARDWARE_TYPE: "hw_type",
  HARDWARE_EXPIRED: "expired",
};

module.exports.ImageTypes = {
  JPG: ".jpg",
  GIF: ".gif",
  PNG: ".png",
};

let AllowedImageMimeTypes = {
  APNG: "image/apng",
  AVIF: "image/avif",
  GIF: "image/gif",
  JPEG: "image/jpeg",
  PNG: "image/png",
  SVG: "image/svg",
  WEBP: "image/webp",
};
module.exports.AllowedImageMimeTypes = AllowedImageMimeTypes;
module.exports.AllowedImageMimeTypesArray = [
  AllowedImageMimeTypes.APNG,
  AllowedImageMimeTypes.AVIF,
  AllowedImageMimeTypes.GIF,
  AllowedImageMimeTypes.JPEG,
  AllowedImageMimeTypes.PNG,
  AllowedImageMimeTypes.SVG,
  AllowedImageMimeTypes.WEBP,
];

module.exports.AlarmOperators = {
  LESS_THAN: "less",
  GREATER_THAN: "greater",
  BETWEEN: "between",
  EQUAL: "equal",
  OUTSIDE: "outside",
};

module.exports.OwnerTypeOptions = {
  SITE: "site",
  COMPANY: "company",
};

module.exports.AlarmTargetTypes = {
  DEVICE: "device",
  MEASURING_POINT: "measuring_point",
  MACHINE: "machine",
  SITE: "site",
  ALARM_ID: "alarm_id",
  LOGGED_IN_USER: "logged_in_user",
};

module.exports.AlarmProperties = AlarmProperties;
module.exports.AlarmGroups = AlarmGroups;

module.exports.checkAlarmPropertyInGroup = (property, group) => {
  return group != null && group.length > 0 && property != null && group.indexOf(property) !== -1;
};

module.exports.checkAlarmInGroup = (alarm, group) => {
  return group != null && group.length > 0 && isAlarmForProperty(alarm, group);
};

module.exports.getIndexForKey = function (tableName, hashKey, sortKey = null) {
  return dbEnums.getTableIndexName(tableName, hashKey, sortKey);
};

module.exports.AlarmTypes = {
  CRITICAL: "critical",
  WARNING: "warning",
  ONLINE_STATUS: "online_status",
};

module.exports.SamplingFrequencies = {
  SIX_KHZ: "6KHz",
};

module.exports.DataTypeToGet = {
  CALCULATED: "calculated",
  RAW: "raw",
};

module.exports.SensorTypes = SensorTypes;
module.exports.isSensorMotorType = isSensorMotorType;
module.exports.isSensorTimeSynced = isSensorTimeSynced;
module.exports.isScreenStructureSensor = isScreenStructureSensor;
module.exports.isSensorAutoScaleCapable = isSensorAutoScaleCapable;

module.exports.isValidSensorType = function (type) {
  for (let enumKey in SensorTypes) {
    if (SensorTypes.hasOwnProperty(enumKey) && SensorTypes[enumKey] === type) {
      return true;
    }
  }
  return false;
};

module.exports.isSensorPowerBandsCapable = function (sensorType) {
  return isSensorMotorType(sensorType);
};

module.exports.TimeOptions = {
  TIME_OPTION_1W: "1w",
};

module.exports.TimeComparators = {
  LESS_THAN: "<",
  GREATER_THAN: ">",
  EQUAL: "=",
};

module.exports.SqsEnums = {
  MESSAGE_TYPE: "message_type",
  DEVICE_NAME: "device_name",
  SENSOR_ID: "sensor_id",
  TIMESTAMP: "timestamp",
  EVENT: "event",
  EVENT_DATA: "data",
  GROUP_ID_GEN: "general", // deprecated - do not use
  GROUP_ID_EVENT: "event", // deprecated - do not use
  GROUP_ID_DATA: "data", // deprecated - do not use
  RECEIVER_STATE: "receiver_state",
  POINT_STATE: "point_state",
  NEW_STATE: "new_state",
  POINT_ID: "p_id",
  QUEUED_AT: "queued_at",
  TARGET: "target",
};

module.exports.SqsDataType = {
  STD_DATA: "point_data",
  POWER_DATA: "power_data",
  CALC_DATA: "calc_data",
  STATE_DATA: "event",
  THERM_DATA: "thermal",
  NONE: "none",
};

module.exports.SqsMessageTypes = {
  EVENT: "event",
  DATA: "data",
  DEVICE_EVENT: "device_event",
};

module.exports.SqsQueues = {
  // NEW NAMING
  ALARM_DEBUG_LIVESTREAM: "sensoteq-livestream-debug.fifo",
  ALARM_LIVESTREAM: "sensoteq-mach-livestream.fifo",
  // other queue for data which doesn't need to be fifo
  OTHER_IN_LIVESTEAM: "sensoteq-other-ingress",
  OTHER_DEBUG_LIVESTREAM: "sensoteq-other-debug",
  // queues that need to be fifo, e.g. RF data from API gateway
  VIB_IN_LIVESTREAM: "sensoteq-vib-ingress.fifo",
  VIB_DEBUG_LIVESTREAM: "sensoteq-vib-debug.fifo",
};

module.exports.LocationTypes = {
  DEVICE: "device",
  MACHINE: "machine",
};

module.exports.ExpiryReasons = {
  TIMEOUT: "timed_out",
  USER_EXPIRED: "user_expired",
  USER_PARALLEL_SESSION: "user_parallel_session",
  PASSWORD_CHANGED: "password_changed",
  ADMIN_EXPIRED: "admin_expired",
  LOGOUT: "logout",
};

module.exports.RestartReasons = {
  REASON_POWER: {val: 0, name: "power_off"},
  REASON_IWDG: {val: 1, name: "hw_watchdog"},
  REASON_APP_WD: {val: 2, name: "app_watchdog"},
  REASON_NO_WIFI: {val: 3, name: "no_network"},
  REASON_NO_RADIO: {val: 4, name: "no_radio"},
  REASON_FLASH: {val: 5, name: "sw_update"},
  REASON_BUTTON: {val: 6, name: "button_pressed"},
  REASON_BROWNOUT: {val: 7, name: "brownout"},
  REASON_USER_REQ: {val: 8, name: "user_request"},
  REASON_CEULLAR: {val: 9, name: "no_cellular"},
  REASON_ERROR: {val: 10, name: "error"},
};

module.exports.S3BucketTypes = {
  BUCKET_REPORTS: "reports",
  BUCKET_SITE_IMAGES: "site-images",
  BUCKET_LOGOS: "logos",
  BUCKET_DATA: "uploaded-data",
  BUCKET_SITEMAP: "sitemaps",
  BUCKET_MACHINE_IMAGES: "machine-images",
};

module.exports.getRestartReasonFromVal = function (val) {
  for (let reasonKey in module.exports.RestartReasons) {
    if (!module.exports.RestartReasons.hasOwnProperty(reasonKey)) {
      continue;
    }
    let reasonObj = module.exports.RestartReasons[reasonKey];
    if (reasonObj.val === val) {
      return reasonObj.name;
    }
  }

  return "unknown";
};

module.exports.DeviceFunctions = {
  ADD_TO_FILTER: "filt_add",
  CLEAR_FILTER: "filt_clear",
  REMOVE_FILTER: "filt_remove",

  STAT_CLEAR: "stat_clear",

  FREQ_CHANGE: "freq_change",
  RESTART: "restart",
};

module.exports.DeviceVariables = {
  CURRENT_FILTER: "filter",

  STAT_GET: "stat_get",
  FILTERED_SENSORS: "filt_ids",

  FREQ_GET: "freq_get",
};

module.exports.ErrorMessages = {
  DEVICE_INVALID: "Device name specified was not valid.",
  DEVICE_CONN_ERR: "Unable to connect to receiver.",
  DEVICE_NOT_AVAILABLE: "Device is not available (cannot connect).",
  DEVICE_NO_SUPPORT: "The device does not support the functionality you have requested.",

  ITEM_EXISTS_ALREADY: "The item to be created exists already.",
  ITEM_DOESNT_EXIST: "The item to be updated does not exist.",

  USER_PASSWORD_CHANGED: "Password has been changed.",
  INVALID_TOKEN: "The token supplied was not valid.",
  WRONG_PASSWORD: "Current user password supplied was not correct.",
  WRONG_UPDATE_PASS: "The current password supplied is not correct, cannot perform update.",
  NO_ACCESS: "User does not have access to resource.",
  WRONG_USER: "Wrong type of user logged in.",
  NO_USER_LOGGED_IN: "No user authorization tags supplied.",
  INTERNAL_ERROR: "Internal error.",

  QRY_STRING_INVALID: "Unable to retrieve required parameters from query string.",
  POST_BODY_INVALID: "Unable to retrieve required parameters from post body.",

  EMAIL_IN_USE: "Email address already in use.",
  RESP_INVALID: "Could not retrieve a valid response.",
  TIMEOUT: "Timed out.",
  NOT_FOUND: "Not found.",

  BEARING_NUMBER_IN_USE: "The bearing number entered is already in use.",
};

// this is not fleshed out, but common codes for issues that can occur throughout the system should be converted to use this
module.exports.ErrorCodes = {
  HW_IN_USE: 303,
};

module.exports.AxisStrategies = {
  Y_UP: "Y_UP",
  X_UP: "X_UP",
};

module.exports.Enrichment = {
  DONT_ENRICH: "dont_enrich",
  DO_ENRICH: "enrich",
};

module.exports.AllowNull = {
  NO: false,
  YES: true,
};

module.exports.UserExpiry = {
  EXPIRED: 1,
  NOT_EXPIRED: 0,
};

module.exports.CacheOptions = {
  ONLY_USE_CACHE: "cache_only",
  NEVER_USE_CACHE: "cache_never",
  STANDARD: "cache",
};

module.exports.UpdateOptions = {
  CREATE_IF_NOT_EXIST: "create",
  ERROR_IF_NOT_EXIST: "error",
};

module.exports.AUTO_EVENT_USER = "Sensoteq";
module.exports.EVENT_SENSOR_REPLACEMENT_NAME = "Sensor replacement";
module.exports.EVENT_GATEWAY_REPLACEMENT_NAME = "Gateway replacement";

module.exports.buildSensorReplacementEventDescription = (oldSensor, newSensor) => {
  return `Measuring point sensor replaced, ${oldSensor} to ${newSensor}`;
};

module.exports.buildGatewayReplacementEventDescription = (oldDevice, newDevice) => {
  return `Gateway replaced, ${oldDevice} to ${newDevice}`;
};

module.exports.ProductCode = "ANTS1002";
// -us = 433.0MHz, everything else is 433.4MHz
let SensorBarcodeTypes = {
  PMES: "pmes",
  PMES_US: "pmes-us",
  SCES: "sces",
  SCES_US: "sces-us",
  SCES_FFT: "sces-fft",
  SCES_FFT_US: "sces-fft-us",
  SCES_TWF: "sces-twf",
  WRES: "wres",
  WRES_US: "wres-us",
  FORCE: "force",
  FORCE_US: "force-us",
  PMES_REN: "pmes-ren",
  WMVM: "wmvm",
  KPX: "kappa-x",
};
module.exports.SensorBarcodeTypes = SensorBarcodeTypes;
module.exports.SensorBarcodeArray = [
  SensorBarcodeTypes.KPX,
  SensorBarcodeTypes.PMES,
  SensorBarcodeTypes.PMES_US,
  SensorBarcodeTypes.SCES,
  SensorBarcodeTypes.SCES_US,
  SensorBarcodeTypes.SCES_FFT,
  SensorBarcodeTypes.SCES_FFT_US,
  SensorBarcodeTypes.SCES_TWF,
  SensorBarcodeTypes.WRES,
  SensorBarcodeTypes.WRES_US,
  SensorBarcodeTypes.FORCE,
  SensorBarcodeTypes.FORCE_US,
  SensorBarcodeTypes.PMES_REN,
  SensorBarcodeTypes.WMVM,
];
module.exports.HumanReadableBarcodeNameFromSensorTypeMap = {
  [SensorTypes.LOW_G_HIGH_MOTOR]: "PMES",
  [SensorTypes.LOW_G_AUTO_MOTOR]: "PMES-AUTO",
  [SensorTypes.KPX]: "Kappa X",
  [SensorTypes.HIGH_G_SCREEN]: "SCES",
  [SensorTypes.LOW_G_SCREEN]: "SCES-FFT",
  [SensorTypes.HIGH_G_SCREEN_TWF]: "SCES-TWF",
  [SensorTypes.HIGH_G_MOTOR]: "FORCE",
  [SensorTypes.LOW_G_MOTOR]: "Legacy - Old PMES",
  [SensorTypes.LOW_G_RMS_MOTOR]: "Legacy - Old PMES (RMS)",
  [SensorTypes.VULCO_MONITORING_SENSOR]: "WMVM",
};

module.exports.getBarcodeInfo = (barcodeType) => {
  if (module.exports.SensorBarcodeArray.indexOf(barcodeType) === -1) {
    return null;
  }
  switch (barcodeType) {
    case SensorBarcodeTypes.PMES:
    case SensorBarcodeTypes.PMES_US:
      return {type: module.exports.SensorTypes.LOW_G_AUTO_MOTOR, code: "PM0060"};
    case SensorBarcodeTypes.KPX:
      return {type: module.exports.SensorTypes.KPX, code: "KPX-1001"};
    case SensorBarcodeTypes.WRES:
    case SensorBarcodeTypes.WRES_US:
      return {type: module.exports.SensorTypes.LOW_G_AUTO_MOTOR, code: "WR0060"};
    case SensorBarcodeTypes.SCES:
    case SensorBarcodeTypes.SCES_US:
      return {type: module.exports.SensorTypes.HIGH_G_SCREEN, code: "SC0300"};
    case SensorBarcodeTypes.SCES_FFT:
    case SensorBarcodeTypes.SCES_FFT_US:
      return {type: module.exports.SensorTypes.LOW_G_SCREEN, code: "SC0060"};
    case SensorBarcodeTypes.SCES_TWF:
      return {type: module.exports.SensorTypes.HIGH_G_SCREEN_TWF, code: "SC-TWF-0060"};
    case SensorBarcodeTypes.FORCE:
    case SensorBarcodeTypes.FORCE_US:
      return {type: module.exports.SensorTypes.HIGH_G_MOTOR, code: "FP0300"};
    case SensorBarcodeTypes.PMES_REN:
      return {type: module.exports.SensorTypes.LOW_G_AUTO_MOTOR, code: "RN0060"};
    case SensorBarcodeTypes.WMVM:
      return {type: module.exports.SensorTypes.VULCO_MONITORING_SENSOR, code: "WMVM"};
  }
  return null;
};

module.exports.TemplateType = {
  MACHINE: "machine",
  ALARM: "alarm",
  ANALYSIS: "analysis",
};

module.exports.TimeWindows = {
  // uses 2 hours of hour entries
  HOUR: "hour",
  // uses 24 hours of hour entries
  DAY: "day",
  // uses 168 hours of hour entries
  WEEK: "week",
  // uses 30 days of day entries
  MONTH: "month",
};

module.exports.ReturnType = {
  ERROR_ON_NULL: "error_null",
  ARRAY: "array",
  NULL: "null",
};

module.exports.ServiceAppModes = {
  VIBRATION_ANALYSIS: "VibrationAnalysis",
  STROKE_ANALYSIS: "StrokeAnalysis",
  HAMMER_TEST: "HammerTest",
};

module.exports.MotorTypes = {
  AC_INDUCTION: "ac_induction",
  AC_SYNCHRONOUS: "ac_synchronous",
  DC: "dc",
};

module.exports.MountingTypes = {
  FIXED: "fixed",
  ISOLATED: "isolated",
};

module.exports.MotorLineFrequencies = {
  FIFTY_HZ: "50_hz",
  SIXTY_HZ: "60_hz",
};

module.exports.MotorSCRTypes = {
  SIX_AC: "6_ac",
  THREE_DC: "3_dc",
};

module.exports.MotorPoles = {
  TWO: "2",
  FOUR: "4",
  SIX: "6",
  EIGHT: "8",
  TEN: "10",
};

module.exports.PowerUnits = {
  KW: "kw",
  HP: "hp",
};

module.exports.ScreenMotionTypes = {
  ELLIPTICAL: "elliptical",
  CIRCULAR: "circular",
  LINEAR: "linear",
};

module.exports.DeckCounts = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
};

module.exports.CouplingTypes = {
  CLOSE_COUPLED: "close_coupled",
  LONG_DRIVE_SHAFT: "long_drive_shaft",
  BELT: "belt",
  GEARBOX: "gearbox",
  GEAR: "gear",
};

module.exports.MeasuringPointSteps = {
  MOTOR: "motor",
  INTERMEDIARY: "intermediary",
  APPLICATION: "application",
  OTHER: "other",
};

module.exports.BearingConstructionTypes = {
  ROLLER: "roller",
  SLEEVE: "sleeve",
};

module.exports.MeasuringPointScreenHeights = {
  BOTTOM: "bottom",
  MIDDLE: "middle",
  TOP: "top",
};

module.exports.MeasuringPointPositions = {
  DRIVE_END: "drive_end",
  NON_DRIVE_END: "non_drive_end",
  HOUSING: "housing",
  OTHER: "other",
  SUPPORT: "support",
  SHAFT_1_DRIVE_END: "SHAFT_1_DRIVE_END",
  SHAFT_1_NON_DRIVE_END: "SHAFT_1_NON_DRIVE_END",
  SHAFT_2_DRIVE_END: "SHAFT_2_DRIVE_END",
  SHAFT_2_NON_DRIVE_END: "SHAFT_2_NON_DRIVE_END",
  SHAFT_3_DRIVE_END: "SHAFT_3_DRIVE_END",
  SHAFT_3_NON_DRIVE_END: "SHAFT_3_NON_DRIVE_END",
  SHAFT_4_DRIVE_END: "SHAFT_4_DRIVE_END",
  SHAFT_4_NON_DRIVE_END: "SHAFT_4_NON_DRIVE_END",
  SHAFT_5_DRIVE_END: "SHAFT_5_DRIVE_END",
  SHAFT_5_NON_DRIVE_END: "SHAFT_5_NON_DRIVE_END",
  SHAFT_6_DRIVE_END: "SHAFT_6_DRIVE_END",
  SHAFT_6_NON_DRIVE_END: "SHAFT_6_NON_DRIVE_END",
  SHAFT_7_DRIVE_END: "SHAFT_7_DRIVE_END",
  SHAFT_7_NON_DRIVE_END: "SHAFT_7_NON_DRIVE_END",
  SHAFT_8_DRIVE_END: "SHAFT_8_DRIVE_END",
  SHAFT_8_NON_DRIVE_END: "SHAFT_8_NON_DRIVE_END",
  LEFT_FEED: "LEFT_FEED",
  LEFT_FEED_MIDDLE: "LEFT_FEED_MIDDLE",
  LEFT_MIDDLE: "LEFT_MIDDLE",
  LEFT_DISCHARGE_MIDDLE: "LEFT_DISCHARGE_MIDDLE",
  LEFT_DISCHARGE: "LEFT_DISCHARGE",
  RIGHT_FEED: "RIGHT_FEED",
  RIGHT_FEED_MIDDLE: "RIGHT_FEED_MIDDLE",
  RIGHT_MIDDLE: "RIGHT_MIDDLE",
  RIGHT_DISCHARGE_MIDDLE: "RIGHT_DISCHARGE_MIDDLE",
  RIGHT_DISCHARGE: "RIGHT_DISCHARGE",
  UPPER_BEARING: "UPPER_BEARING",
  LOWER_BEARING: "LOWER_BEARING",
  RIGHT_BEARING: "RIGHT_BEARING",
  LEFT_BEARING: "LEFT_BEARING",
};

module.exports.toArray = (enumeration) => {
  if (typeof enumeration !== "object") {
    return [];
  }
  let array = [];
  for (let key in enumeration) {
    if (enumeration.hasOwnProperty(key)) {
      array.push(enumeration[key]);
    }
  }
  return array;
};

module.exports.MeasuringPointTypes = {
  VIBRATION: "vibration",
  THERMAL: "thermal",
  WIRED_NODE: "wired_node",
  WIRELESS_NODE: "wireless_node",
};

module.exports.THERMAL_CAMERA_STREAM_PREFIX = "stream_";

module.exports.OtherDataTypes = {
  THERMAL: "thermal",
  CHI: "chi",
};

module.exports.OtherDataTypesArray = module.exports.toArray(module.exports.OtherDataTypes);

module.exports.EventTypes = {
  CHI: "chi",
};

module.exports.STANDARD_HEALTH_CHECK_ENDPOINT = "/health/check";

// Welcome email type options
module.exports.WelcomeEmailTypes = {
  WELCOME_EMAIL: "welcome",
  WELCOME_CHI: "welcome_chi",
};

module.exports.CHI_USER_GUIDE_LINK =
  process.env.CHI_USER_GUIDE_LINK ||
  "https://sensoteq.co.uk/wp-content/uploads/2021/01/Sensoteq-Chi-User-Guide-WEB-min.pdf";

module.exports.ENVIRONMENT_BRANDING = process.env.ENVIRONMENT_BRANDING || "sensoteq";

module.exports.CHI_APP_LINK =
  process.env.CHI_APP_LINK || "https://play.google.com/store/apps/details?id=com.sensoteq.chi";

module.exports.AccessLevels = {
  USER: 0,
  ADMINISTRATOR: 1,
  INSTALLER: 2,
};

module.exports.Privileges = {
  ALARM_READ: "alarm.read",
  ALARM_CREATE: "alarm.create",
  ALARM_UPDATE: "alarm.update",
  ALARM_DELETE: "alarm.delete",
  BEARING_READ: "bearing.read",
  BEARING_CREATE: "bearing.create",
  BEARING_UPDATE: "bearing.update",
  BEARING_DELETE: "bearing.delete",
  BULK_ALARM_READ: "bulk_alarm.read",
  BULK_ALARM_CREATE: "bulk_alarm.create",
  BULK_ALARM_UPDATE: "bulk_alarm.update",
  BULK_ALARM_DELETE: "bulk_alarm.delete",
  ALARM_TEMPLATE_READ: "alarm_template.read",
  ALARM_TEMPLATE_CREATE: "alarm_template.create",
  ALARM_TEMPLATE_UPDATE: "alarm_template.update",
  ALARM_TEMPLATE_DELETE: "alarm_template.delete",
};

module.exports.SessionGetOptions = {
  EXCLUDE_API_KEYS: "EXCLUDE_API_KEYS",
  ONLY_API_KEYS: "ONLY_API_KEYS",
  ALL: "ALL",
};

const NodeChannelConfig = {
  SensingElement: {
    SIXTEEN_BIT: "SIXTEEN_BIT",
    BOOLEAN: "BOOLEAN",
  },
  SensorType: {
    OIL_QUALITY: "OIL_QUALITY",
    CURRENT_MEASUREMENT: "CURRENT_MEASUREMENT",
    GENERIC: "GENERIC",
  },
  MeasurementType: {
    OIL_PARTICLE_FINE: "OIL_PARTICLE_FINE",
    OIL_PARTICLE_COARSE: "OIL_PARTICLE_COARSE",
    TEMPERATURE: "TEMPERATURE",
    ERROR: "ERROR",
    PHASE_A_BLACK_CURRENT: "PHASE_A_BLACK_CURRENT",
    PHASE_B_RED_CURRENT: "PHASE_B_RED_CURRENT",
    PHASE_C_BLUE_CURRENT: "PHASE_C_BLUE_CURRENT",
    GENERIC: "GENERIC",
  },
  Unit: {
    PERCENT: "PERCENT",
    DEGC: "DEGC",
    ERRVAL: "ERRVAL",
    AMPS: "AMPS",
    DATA: "DATA",
  },
  BooleanOptions: {
    ONE_ON: "1=ON, 0=OFF",
    ONE_OFF: "0=ON, 1=OFF",
  },
};

// Map each Element Type to a set of sensors and each sensor to a set of models, each with a set of measurementType/Unit
NodeChannelConfig.ElementType = {
  [NodeChannelConfig.SensingElement.SIXTEEN_BIT]: {
    [NodeChannelConfig.SensorType.OIL_QUALITY]: {
      GILL4212: [
        {
          measurementType: NodeChannelConfig.MeasurementType.OIL_PARTICLE_FINE,
          unit: NodeChannelConfig.Unit.PERCENT,
        },
        {
          measurementType: NodeChannelConfig.MeasurementType.OIL_PARTICLE_COARSE,
          unit: NodeChannelConfig.Unit.PERCENT,
        },
        {
          measurementType: NodeChannelConfig.MeasurementType.TEMPERATURE,
          unit: NodeChannelConfig.Unit.DEGC,
        },
        {
          measurementType: NodeChannelConfig.MeasurementType.ERROR,
          unit: NodeChannelConfig.Unit.ERRVAL,
        },
      ],
    },
    [NodeChannelConfig.SensorType.CURRENT_MEASUREMENT]: {
      MGS_1800_0250: [
        {
          measurementType: NodeChannelConfig.MeasurementType.PHASE_A_BLACK_CURRENT,
          unit: NodeChannelConfig.Unit.AMPS,
        },
        {
          measurementType: NodeChannelConfig.MeasurementType.PHASE_B_RED_CURRENT,
          unit: NodeChannelConfig.Unit.AMPS,
        },
        {
          measurementType: NodeChannelConfig.MeasurementType.PHASE_C_BLUE_CURRENT,
          unit: NodeChannelConfig.Unit.AMPS,
        },
      ],
    },
  },
  [NodeChannelConfig.SensingElement.BOOLEAN]: {
    [NodeChannelConfig.SensorType.GENERIC]: {
      GENERIC: [
        {
          measurementType: NodeChannelConfig.MeasurementType.GENERIC,
          unit: NodeChannelConfig.Unit.DATA,
        },
      ],
    },
  },
};

module.exports.NodeChannelConfig = NodeChannelConfig;
