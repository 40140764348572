import {observable, computed, flow} from "mobx";

import Api from "sensoteq-react-core/services/api";
import {ModelOptions} from "routes/cde-tests/cde-screen-types";
import SubscribableStore from "sensoteq-react-core/models/SubscribableStore";
import CDETestSubscription from "../models/CDETestSubscription";

export default class CDETestsStore extends SubscribableStore {
  @observable _tests;

  constructor(rootStore) {
    super(rootStore, CDETestSubscription);
  }

  @computed get tests() {
    return (this._tests?.slice() || []).map((test) => {
      const finished = test.metadata.timestamp_end != null || test.timestamp < Date.now() - 1000 * 60 * 60 * 8;
      return {
        ...test,
        finished,
      };
    });
  }

  @computed get testLookupMap() {
    let map = {};
    this.tests.forEach((test) => {
      map[test.event_id] = test;
    });
    return map;
  }

  getTest(eventId) {
    return this.testLookupMap[eventId];
  }

  loadTests = flow(function* () {
    this.startLoading();
    try {
      const tests = yield Api.getEvents("CDE Global");
      this._tests = tests.events;
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error loading tests: ${error}`, "bad");
    }
    this.stopLoading();
  });

  startTest = flow(
    function* (
      machine_id,
      project_number,
      project_name,
      screen_range,
      screen_model,
      screen_number,
      screen_serial_number,
      lmd_serial_number,
      tester,
    ) {
      this.startLoading();
      try {
        const metadata = {
          machine_id,
          project_number,
          project_name,
          screen_range,
          screen_model,
          screen_number,
          screen_serial_number,
          lmd_serial_number,
          state: null,
          comments_name: tester,
        };
        const modelOptions = ModelOptions[screen_range];
        const model = modelOptions.find((x) => x.value === screen_model);
        const machine = this.rootStore.machineStore.getMachine(machine_id);
        yield Api.createEvent(
          "Start test",
          `Testing was started for ${model.label} #${screen_number} on ${machine.label}`,
          Date.now(),
          "CDE Global",
          metadata,
        );
        this.rootStore.notificationStore.addNotification("Test started successfully", "good");
        this.loadTests();
      } catch (error) {
        this.rootStore.notificationStore.addNotification(`Error starting test: ${error}`, "bad");
      }
      this.stopLoading();
    },
  );

  updateTest = flow(function* (testId, metadata) {
    this.startLoading();
    try {
      yield Api.updateEvent(testId, undefined, undefined, metadata);
      this.rootStore.notificationStore.addNotification("Test report updated successfully", "good");
      this.loadTests();
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error updating test report: ${error}`, "bad");
    }
    this.stopLoading();
  });

  stopTest = flow(function* (testId) {
    const test = this.getTest(testId);
    if (!test) {
      return;
    }
    this.startLoading();
    try {
      const metadata = {
        ...test.metadata,
        timestamp_end: Date.now(),
      };
      yield Api.updateEvent(testId, undefined, undefined, metadata);
      this.rootStore.notificationStore.addNotification("Test stopped successfully", "good");
      this.loadTests();
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error stopping test: ${error}`, "bad");
    }
    this.stopLoading();
  });

  deleteTest = flow(function* (testId) {
    this.startLoading();
    try {
      yield Api.deleteEvent(testId);
      this.rootStore.notificationStore.addNotification("Test report deleted successfully", "good");
      this.loadTests();
    } catch (error) {
      this.rootStore.notificationStore.addNotification(`Error deleting test report: ${error}`, "bad");
    }
    this.stopLoading();
  });
}
