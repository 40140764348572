const {SignalAnalysis} = require("./signal-analysis-builder");
const {ENVELOPE_METHOD} = require("./constants-envelope");

// Spectrums with a sampling frequency of 12000 or greater are support for enveloping
const ENVELOPE_SAMPLING_FREQUENCY_SUPPORT_START = 12000;

module.exports.isEnvelopingSupported = (samplingFrequency) => {
  if (samplingFrequency == null) {
    return false;
  }
  if (samplingFrequency < ENVELOPE_SAMPLING_FREQUENCY_SUPPORT_START) {
    return false;
  }

  return true;
};

module.exports.calculateEnvelope = (data, dataOptions, envelopeOptions) => {
  const defaultEnvelopeOptions = {
    envelopeMethod: ENVELOPE_METHOD.HILBERT,
    low: 500,
    high: 10000,
    lowPassFilter: 1000,
    bandpassOrder: 6,
    lowpassOrder: 6,
  };
  const options = {
    ...defaultEnvelopeOptions,
    ...(envelopeOptions || {}),
  };

  if (!dataOptions.hasOwnProperty("samplingFrequency")) {
    // Sampling frequency is required. If not supplied just return the original data
    // with the envelope config.
    return {data, config: options};
  }

  const dataOpts = {
    nyquist: 2.56,
    ...(dataOptions || {}),
  };

  const samplingFrequency = dataOpts.samplingFrequency;
  const fMax = samplingFrequency / dataOpts.nyquist;

  // Adjust the bandpass so that the high value cannot exceed the fmax.
  const bandPassFmax = options.high > fMax ? fMax : options.high;
  const builder = new SignalAnalysis(data, samplingFrequency)
    .bandpass({low: options.low, high: bandPassFmax, order: options.bandpassOrder})
    .envelope(options.envelopeMethod);

  if (options.lowPassFilter && options.lowPassFilter > 5) {
    // Adjust the lowpass so that the value cannot exceed the fmax.
    const lowPassFmax = options.lowPassFilter > fMax ? fMax : options.lowPassFilter;
    builder.lowpass({cutoff: lowPassFmax, order: options.lowpassOrder});
  }

  return {result: builder.signal(), config: options};
};