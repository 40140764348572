module.exports.MachineTypeCategory = {
  TYPE: "type",
  SUBTYPE: "subtype",
  SUBTYPE_DIAGRAM: "subtype_diagram",
};

module.exports.MachineTypeBase = {
  ROTATING_ELEMENT: "rotating_element",
  OSCILLATING_PATTERN: "oscillating_pattern",
};

module.exports.SpecialCaseMachineType = {
  VIBRATING_CENRTIFUGE_SUBTYPE: "vibrating_centrifuge_subtype",
  SCREEN: "screen_type",
};
