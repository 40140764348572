import {observable, flow, computed} from "mobx";
import DataSubscription from "sensoteq-react-core/models/DataSubscription";
import Api from "sensoteq-react-core/services/api";

export default class ThermalImageZonesSubscription extends DataSubscription {
  @observable _dataPoints;
  @observable _boxes_data;
  @observable _image;
  @observable _hasError;

  getData = flow(function* ({point_id}) {
    if (!point_id) {
      return;
    }
    this.startLoading();
    try {
      const data = yield Api.getMachinesPointThermal(point_id);
      this._dataPoints = data.points.boxes;
      this._boxes_data = data.points.boxes_data;
      this._image = data.points.image;
      this._hasError = false;
    } catch (error) {
      this._hasError = true;
    }
    this.stopLoading();
  });

  @computed get dataPoints() {
    return this._dataPoints;
  }

  @computed get boxes_data() {
    return this._boxes_data;
  }

  @computed get image() {
    return this._image;
  }

  @computed get hasError() {
    return this._hasError;
  }
}
