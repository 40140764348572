import {action, computed} from "mobx";
import {calculateLinerWearData} from "sensoteq-core/calculations";

import MultiPointDataSubscription from "./MultiPointDataSubscription";
import dayjs from "dayjs";

export default class MachineDataSubscription extends MultiPointDataSubscription {
  getDefaultParams() {
    return {
      ...super.getDefaultParams(),
      machineId: null,
      positions: null,
    };
  }

  @action update(params = {}, replaceAllParams = false) {
    let newIdentifiers = this.params.identifiers;
    if (this.params.machineId !== params.machineId) {
      let identifiers = this.rootStore.machineStore.getPointsForMachine(params.machineId);
      const positions = params.positions === undefined ? this.params.positions : params.positions;
      if (positions) {
        identifiers = identifiers.filter((x) => positions.indexOf(x.position) !== -1);
      }
      newIdentifiers = identifiers.map((x) => x.point_id);
    }
    super.update(
      {
        ...params,
        identifiers: newIdentifiers,
      },
      replaceAllParams,
    );
  }

  getSetTitle(pointId) {
    const point = this.rootStore.pointStore.getTestPoint(pointId);
    if (!point) {
      return "Unknown point";
    }
    return `${point.label}`;
  }

  @computed get positionLookupMap() {
    let map = {};
    this.identifiers.forEach((pointId) => {
      const point = this.rootStore.pointStore.getTestPoint(pointId);
      if (point?.position) {
        map[point.position] = pointId;
      }
    });
    return map;
  }

  @computed get linerWearData() {
    const machine = this.rootStore.machineStore.getMachine(this.params.machineId);
    if (!machine || !machine.metadata) return null;
    const {metadata} = machine;
    const ellipseStrokeLengthAndRPMData = super.extractData((x) => x.ellipseStrokeLengthAndRPMData);

    const averageValue = (array, value) =>
      array?.length > 0 ? array.reduce((sum, val) => sum + val[value], 0) / array.length : null;

    const groupedEllipseStrokeLengthAndRPMData = ellipseStrokeLengthAndRPMData.map((x) => ({
      ...x,
      data: Array.from(Map.groupBy(x.data, (d) => d.date.startOf("hour").format("YYYY/MM/DD:HH:mm"))).map((i) => ({
        date: i[0],
        [`${x.title.toLowerCase()}`]: {
          stroke_length: averageValue(i[1], "stroke_length"),
          rpm: averageValue(i[1], "rpm"),
          rpm2: Math.floor(i[1].reduce((sum, value) => sum + value.rpm, 0) / i[1].length / 10) * 10,
        },
      })),
    }));

    // Find most verbose/longest dataset out of all the measuring points
    let max = 0;
    let maxIndex = -1;
    groupedEllipseStrokeLengthAndRPMData.forEach((x, i) => {
      if (x.data.length > max) {
        max = x.data.length;
        maxIndex = i;
      }
    });

    // Combine measuring point datasets by matching time buckets
    let combinedEllipseStrokeLengthAndRPMData = [];
    groupedEllipseStrokeLengthAndRPMData.forEach((x, i) => {
      // Omit source 'largest' dataset to avoid duplication
      if (i === maxIndex) return;

      x.data.forEach((d) => {
        const largestDataSet = groupedEllipseStrokeLengthAndRPMData[maxIndex].data;
        const matchedDateIndex = largestDataSet.findIndex((c) => c.date === d.date);
        if (matchedDateIndex > -1) {
          const newCombinedDataBucket = {
            ...largestDataSet[matchedDateIndex],
            ...d,
          };
          const liner_wear = calculateLinerWearData({
            originalTroughWeight: metadata.feederConfiguration?.original_trough_weight,
            originalLinerWeight: metadata.feederConfiguration?.original_liner_weight,
            originalExciterWeight: metadata.feederConfiguration?.original_exciter_weight,
            originalTroughStrokeLength: metadata.feederConfiguration?.original_trough_stroke,
            originalExciterStrokeLength: metadata.feederConfiguration?.original_exciter_stroke,
            originalCombinedStrokeLength: metadata.feederConfiguration?.original_combined_stroke,
            newTroughStrokeLength: newCombinedDataBucket.trough?.stroke_length,
            newExciterStrokeLength: newCombinedDataBucket.exciter?.stroke_length,
          })?.percentLinerWear;

          if (!liner_wear) return;

          // TODO: Update RPM value with trough vs exciter vs average and if smoothing threshold should be applied
          // following Syntron response.
          combinedEllipseStrokeLengthAndRPMData.push({
            date: dayjs(d.date),
            liner_wear,
            rpm: Math.round(newCombinedDataBucket.trough.rpm),
          });
        }
      });
    });

    return combinedEllipseStrokeLengthAndRPMData;
  }

  getSubscriptionByPosition(position) {
    const pointId = this.positionLookupMap[position];
    return this.getSubscription(pointId);
  }
}
