import {http, HttpResponse} from "msw";
import {getAPI} from "services/Utils";
import {valid_password, valid_username} from "../../common";

const API_URL = getAPI();

type LoginRequest = {
  username: string;
  password: string;
};

export const loginHandler = http.post(`${API_URL}v1/user/login`, async ({request}) => {
  const body = (await request.json()) as LoginRequest;
  const pageParams = new URLSearchParams(window.location.search);
  const isFaultLabeller = pageParams.get("fault-labeller") === "true";

  if (body.username === valid_username && body.password === valid_password) {
    return HttpResponse.json(
      {
        token: "mock-token",
        user: {
          username: valid_username,
          ...(isFaultLabeller && {fault_labeller_id: "mock-fault-labeller-id"}),
        },
      },
      {status: 200},
    );
  } else {
    return HttpResponse.json(
      {
        error: "Incorrect username or password",
      },
      {status: 400},
    );
  }
});
