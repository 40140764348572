import {http, HttpResponse} from "msw";
import {setupWorker} from "msw/browser";
import {loginHandler} from "./handlers/login";
import {getAPI} from "services/Utils";

type WaitForServerParams = {
  url: string;
  limit?: number;
  interval?: number;
};

const waitForServer = async ({url, limit = 20, interval = 3000}: WaitForServerParams): Promise<void> => {
  for (let attempt = 0; attempt < limit; attempt++) {
    try {
      const response = await fetch(url);
      if (response.ok) return;
    } catch (error) {
      // Ignore errors and continue trying
    }
    await new Promise((resolve) => setTimeout(resolve, interval));
  }
  throw new Error(`Server at ${url} is not available after ${limit} attempts`);
};

const API_URL = getAPI();

export const handlers = [
  http.get(`${API_URL}v1/server/config`, () => {
    return HttpResponse.json(
      {
        forced_mfa: false,
        layout_v2: true,
      },
      {status: 200},
    );
  }),
  loginHandler,
];

export const worker = setupWorker(...handlers);

export const setupMockServer = async (IS_MOCK?: boolean, IS_CI?: boolean): Promise<void> => {
  if (IS_MOCK) {
    try {
      if (IS_CI) {
        console.log("Running in CI environment, skipping waitForServer");
      } else {
        await waitForServer({url: "http://localhost:3000"});
      }
      await worker.start({
        onUnhandledRequest: "bypass", // Add this line
      });
      console.groupCollapsed("Mock server running");
      worker.listHandlers();
      console.groupEnd();
    } catch (error) {
      console.error("Failed to start mock server:", error);
    }
  } else {
    console.log("Mock server is not enabled");
  }
};
